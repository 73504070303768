import { useState, useCallback, useRef, useEffect } from 'react';

const INITIAL_STEPS = [];
const INITIAL_STEP_NUMBER = 0;

export default ({
  steps = INITIAL_STEPS,
  initialStepNumber = INITIAL_STEP_NUMBER,
}) => {
  const [_steps] = useState(steps);
  const initialStepName = useRef(_steps?.[initialStepNumber]);
  const [currentStepName, setCurrentStepName] = useState(
    initialStepName.current,
  );
  const [visitedSteps, setVisitedSteps] = useState([]);

  const getStepIndex = useCallback(
    (stepName = currentStepName) => _steps.indexOf(stepName),
    [_steps, currentStepName],
  );

  const isValidStepName = useCallback(
    (step) => _steps.includes(step),
    [_steps],
  );

  const isValidStepNumber = useCallback(
    (stepNumber) => !!_steps?.[stepNumber],
    [_steps],
  );

  const goPrev = useCallback(() => {
    const currentStepIndex = getStepIndex();
    const prevStepIndex = currentStepIndex - 1;

    if (!isValidStepNumber(prevStepIndex)) return;

    const prevStepName = _steps?.[prevStepIndex];

    setCurrentStepName(prevStepName);
  }, [_steps, getStepIndex, isValidStepNumber, setCurrentStepName]);

  const goNext = useCallback(() => {
    const currentStepIndex = getStepIndex();
    const nextStepIndex = currentStepIndex + 1;

    if (!isValidStepNumber(nextStepIndex)) return;

    const nextStepName = _steps?.[nextStepIndex];

    setCurrentStepName(nextStepName);
  }, [_steps, getStepIndex, isValidStepNumber, setCurrentStepName]);

  const goTo = useCallback(
    (stepName) => {
      const stepIndex = getStepIndex(stepName);

      if (!isValidStepNumber(stepIndex)) return;

      const nextStepName = _steps?.[stepIndex];

      setCurrentStepName(nextStepName);
    },
    [_steps, getStepIndex, isValidStepNumber, setCurrentStepName],
  );

  const getPrevStepName = useCallback(() => {
    const stepIndex = getStepIndex(currentStepName);
    const prevStepIndex = stepIndex - 1;

    if (!isValidStepNumber(prevStepIndex)) return;

    const prevStepName = _steps?.[prevStepIndex];

    return prevStepName;
  }, [_steps, currentStepName, isValidStepNumber, getStepIndex]);

  const getNextStepName = useCallback(() => {
    const stepIndex = getStepIndex(currentStepName);
    const nextStepIndex = stepIndex + 1;

    if (!isValidStepNumber(nextStepIndex)) return;

    const nextStepName = _steps?.[nextStepIndex];

    return nextStepName;
  }, [_steps, currentStepName, isValidStepNumber, getStepIndex]);

  const isStepActive = useCallback(
    (stepName) => currentStepName === stepName,
    [currentStepName],
  );

  const isStepVisited = useCallback(
    (stepName) => visitedSteps.includes(stepName),
    [visitedSteps],
  );

  const isFirstStep = useCallback(
    () => getStepIndex(currentStepName) === 0,
    [currentStepName, getStepIndex],
  );

  const isLastStep = useCallback(
    () => getStepIndex(currentStepName) === _steps?.length - 1,
    [currentStepName, _steps?.length, getStepIndex],
  );

  const updateVisitedSteps = useCallback(() => {
    const stepAlreadyAdded = visitedSteps.includes(currentStepName);

    if (stepAlreadyAdded) return;

    setVisitedSteps([...visitedSteps, currentStepName]);
  }, [visitedSteps, setVisitedSteps, currentStepName]);

  useEffect(() => {
    updateVisitedSteps();
  }, [updateVisitedSteps]);

  return {
    steps: _steps,
    currentStepName,
    visitedSteps,
    isValidStepName,
    isValidStepNumber,
    goPrev,
    goNext,
    goTo,
    getStepIndex,
    getPrevStepName,
    getNextStepName,
    isStepActive,
    isStepVisited,
    isFirstStep,
    isLastStep,
    updateVisitedSteps,
  };
};
