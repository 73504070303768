//Note that these values need to match the values used in the REST API Request object that is sent
// so that it can be properly deserialized into the correct component type.
// They must match the PrinterComponentDefinitionRequest object.
export const componentDiscriminators = {
  //Extruders
  EXTRUDER: 'EXTRUDER',
  //Machines
  SIX_AXIS_ROBOT: 'SIX_AXIS_ROBOT',
  GANTRY: 'GANTRY',
  MACHINE: 'MACHINE',
  //Beds
  BED_RESPONSE_PROPERTY: 'bedDefinitionResponse',
  BED_TYPE: 'bedType',
  STATIC_BED_DISCRIMINATOR_VALUE: 'staticBed',
  ROTARY_BED_DISCRIMINATOR_VALUE: 'rotaryBed',
  TWO_AXIS_BED_DISCRIMINATOR_VALUE: 'twoAxisBed',
  BED: 'BED',
  //Plinths
  STATIC_BED: 'STATIC_BED',
  ROTARY_BED: 'ROTARY_BED',
  TWO_AXIS_BED: 'TWO_AXIS_BED',
  PLINTH_RESPONSE_PROPERTY: 'plinthDefinitionResponse',
  PLINTH_TYPE: 'plinthType',
  STATIC_PLINTH: 'STATIC_PLINTH',
  RAIL_PLINTH: 'RAIL_PLINTH',
  PLINTH: 'PLINTH',
  //Enclosures
  ENCLOSURE_RESPONSE_PROPERTY: 'enclosureDefinitionResponse',
  ENCLOSURE: 'ENCLOSURE',
  //ProgramTypes
  PROGRAM_TYPE: 'PROGRAM_TYPE',
};
