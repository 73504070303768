import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

/**
 * Defines the configurations possible for how a postprocessor handles the mapping of a
 * "WaitCommand" object into an instruction set.
 * @returns
 */
const PostProcessorWait = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.postprocessor.isCustomWaitHandlerEnabled.label',
        defaultMessage: 'Enable Custom Wait Handler',
      })}
      dataTestId={`printer-custom-post-processor__setting-isCustomWaitHandlerEnabled`}
      withDividerBottom
      endingCheckboxChecked={Boolean(values['isCustomWaitHandlerEnabled'])}
      endingCheckboxDirty={checkIfFieldIsDirty(
        values,
        initialValues,
        'isCustomWaitHandlerEnabled',
      )}
      onEndingCheckboxClick={() => {
        setFieldValue(
          'isCustomWaitHandlerEnabled',
          !values['isCustomWaitHandlerEnabled'],
        );
      }}
      expand={Boolean(values['isCustomWaitHandlerEnabled'])}
    >
      <FormikField
        label={intl.formatMessage({
          id: 'printers.postprocessor.customWaitInstructions.label',
          defaultMessage: 'Custom Wait Instructions',
        })}
        component={PostProcessorSetting}
        dataTestId={`printer-custom-post-processor__setting-customWaitInstructions`}
        name={'customWaitInstructions'}
        disabled={isSubmitting}
        renderAsTextArea
        descriptionLabel={intl.formatMessage({
          id: 'printers.postprocessor.customWaitInstructions.description',
        })}
      />
    </SettingsCategory>
  );
};

PostProcessorWait.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorWait;
