import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import IconButton from '@components/1-atoms/IconButton/IconButton';
import PropTypes from 'prop-types';
import {
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
} from '@utils/operatorStatus';
import { StatusIcon } from '@components/2-molecules/OperatorStatus/OperatorStatus.styled';

const StatusPropType = PropTypes.oneOf([
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
]);
const OperatorStatus = ({
  status,
  onComputeButtonClick,
  disabled,
  dataTestId = 'operator-status',
}) => {
  const intl = useIntl();

  const operatorStatus = useMemo(() => {
    switch (status) {
      case OPERATOR_HEADER_STATUS_HIGHLIGHT:
        return {
          iconName: 'done_0',
          title: intl.formatMessage({
            id: 'operator.status.computed',
            defaultMessage: 'Computed',
          }),
        };
      case OPERATOR_HEADER_STATUS_ALERT:
        return {
          iconName: 'sync_0',
          iconColor: 'primary',
          title: intl.formatMessage({
            id: 'operator.status.to_be_computed',
            defaultMessage: 'To be computed',
          }),
        };
      case OPERATOR_HEADER_STATUS_ALERT_MISS:
        return {
          iconName: 'error_0',
          title: intl.formatMessage({
            id: 'operator.status.unable_to_compute',
            defaultMessage: 'Unable to compute',
          }),
        };
      default:
        return {
          iconName: 'Dot_1',
        };
    }
  }, [intl, status]);
  const isAlert = status === OPERATOR_HEADER_STATUS_ALERT;
  const iconTestId = `${dataTestId}__status-icon ${dataTestId}__status-icon--${status}`;
  if (isAlert) {
    return (
      <IconButton
        dataTestId={iconTestId}
        {...operatorStatus}
        disabled={disabled}
        onClick={onComputeButtonClick}
      />
    );
  }
  return (
    <StatusIcon
      dataTestId={iconTestId}
      name={operatorStatus.iconName}
      status={status}
    />
  );
};

OperatorStatus.displayName = 'OperatorStatus';

OperatorStatus.propTypes = {
  status: StatusPropType,
  disabled: PropTypes.bool,
  onComputeButtonClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default OperatorStatus;
