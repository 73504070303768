import React, { useCallback, useEffect, useMemo } from 'react';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useSnackbar from '@hooks/useSnackbar';
import Field from '@components/1-atoms/Field';
import TextArea from '@components/1-atoms/TextArea';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import endpoints from '@api/endpoints';
import client from '@api/client';
import { Fields } from './SendCopyDialog.styled';

const MODAL_ID = ModalDataTypes.SEND_COPY;

const SendCopyDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { dataTestId = 'send-workflow-copy', workflow, project } = dialogData;
  const isSendingProject = !!project;

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(
            intl.formatMessage({
              id: 'sendcopy.dialog.form.email.validation.invalid',
              defaultMessage: 'Invalid email address',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'sendcopy.dialog.form.email.validation.invalid',
              defaultMessage: 'Invalid email address',
            }),
          ),
        message: Yup.string().max(
          600,
          intl.formatMessage({
            id: 'sendcopy.dialog.form.message.validation.max',
            defaultMessage: 'The message cannot be longer than 600 characters',
          }),
        ),
      }),
    [intl],
  );

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { formState, control } = form;
  const { isSubmitting } = formState;

  const onFormSubmit = useCallback(
    async (values) => {
      try {
        if (isSendingProject) {
          await client.post(
            endpoints.shareProject.replace(':workspaceId', project?.id),
            {
              receiverEmail: values.email,
              message: values.message,
            },
          );
        } else {
          await client.post(
            endpoints.shareWorkflow.replace(':workflowId', workflow?.id),
            {
              receiverEmail: values.email,
              message: values.message,
            },
          );
        }
      } finally {
        handleClose();
      }

      showSnackbar({
        text: intl.formatMessage({
          id: 'sendcopy.dialog.snackbar.success',
          defaultMessage:
            'Your request to send a copy has been submitted. You will receive a confirmation email shortly',
        }),
      });
    },
    [
      intl,
      handleClose,
      showSnackbar,
      workflow?.id,
      project?.id,
      isSendingProject,
    ],
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <form onSubmit={form.handleSubmit(onFormSubmit)}>
      <DialogPortal
        renderAsForm
        dataTestId={dataTestId}
        dialogId={MODAL_ID}
        loading={formState?.isSubmitting}
        secondaryButtonLabel={intl.formatMessage({
          id: 'general.cancel',
          defaultMessage: 'Cancel',
        })}
        primaryButtonLabel={intl.formatMessage({
          id: 'general.send',
          defaultMessage: 'Send',
        })}
      >
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={intl.formatMessage({
            id: 'sendcopy.dialog.title',
            defaultMessage: 'Send copy',
          })}
          subtitle={intl.formatMessage(
            {
              id: 'sendcopy.dialog.subtitle',
              defaultMessage:
                'Send a copy of this {type} to a contact. Please note that an Aibuild account is required for access. The contact will receive an email with instructions to copy the {type}, {name} to its Aibuild organization. The link will expire in 48 hours.',
            },
            {
              type: isSendingProject
                ? intl.formatMessage({
                    id: 'general.project',
                    defaultMessage: 'Project',
                  })
                : intl.formatMessage({
                    id: 'general.workflow',
                    defaultMessage: 'Workflow',
                  }),
              name: project?.name || workflow?.name,
            },
          )}
        />

        <Fields>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => {
              const error = fieldState.isTouched && fieldState.error;

              return (
                <Field
                  dataTestId={`${dataTestId}__email`}
                  disabled={isSubmitting}
                  label={intl.formatMessage({
                    id: 'sendcopy.dialog.form.email.label',
                    defaultMessage: 'Send via email',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sendcopy.dialog.form.email.placeholder',
                    defaultMessage: 'Email address',
                  })}
                  autoFocus
                  error={!!error}
                  supportingText={error?.message}
                  {...field}
                />
              );
            }}
          />

          <Controller
            control={control}
            name="message"
            render={({ field, fieldState }) => {
              const error = fieldState.isTouched && fieldState.error;

              return (
                <TextArea
                  dataTestId={`${dataTestId}__message`}
                  disabled={isSubmitting}
                  rows={5}
                  label={intl.formatMessage({
                    id: 'sendcopy.dialog.form.message.label',
                    defaultMessage: 'Add message',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'sendcopy.dialog.form.message.placeholder',
                    defaultMessage: '(Optional)',
                  })}
                  error={!!error}
                  supportingText={error?.message}
                  {...field}
                />
              );
            }}
          />
        </Fields>
      </DialogPortal>
    </form>
  );
};

SendCopyDialog.propTypes = {};

export default SendCopyDialog;
