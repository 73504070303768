import styled, { css } from 'styled-components';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { CHIP_VARIANT_PRIMARY } from './Chip';
import { flexCenter, when } from '@stylesheets/helpers';

export const StateLayer = styled.span`
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px solid transparent;
  visibility: hidden;
  z-index: 0;

  ${({ disabled, selected, theme: { colors } }) => css`
    ${when(
      disabled && selected,
      `
        border: 1px solid ${colors.onSurface};
        background-color: ${colors.onSurface};
        opacity: 0.12;
        visibility: visible;
      `,
    )}
  `};
`;

export const Icon = styled(SpriteSVG)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ disabled, variant, selected, theme: { colors } }) => css`
    fill: ${colors.onSurfaceVariant};

    ${when(
      selected,
      `
        fill: ${colors.onSecondaryContainer};
      `,
    )}

    ${when(
      variant === CHIP_VARIANT_PRIMARY,
      `
        fill: ${colors.primary};
      `,
    )}

    ${when(
      variant === CHIP_VARIANT_PRIMARY && selected,
      `
        fill: ${colors.onPrimary};
      `,
    )}

    ${when(
      variant === CHIP_VARIANT_PRIMARY && disabled && selected,
      `
        fill: ${colors.onSurface};
      `,
    )}
  `}
`;

export const Label = styled(Text).attrs(({ disabled, variant, selected }) => {
  const labelVariant = TEXT_VARIANT_LABEL_LARGE;
  let color = 'onSurface';

  if (selected) {
    color = 'onSecondaryContainer';
  }

  if (variant === CHIP_VARIANT_PRIMARY) {
    color = 'primary';

    if (selected) {
      color = 'onPrimary';
    }

    if (disabled && selected) {
      color = 'onSurface';
    }
  }

  return {
    variant: labelVariant,
    color,
  };
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  ${flexCenter}
  gap: 8px;
  border-radius: 8px;
  padding: 6px 16px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  ${({
    variant,
    disabled,
    selected,
    error,
    isDraggingOver,
    dirty,
    withLeadingIcon,
    withEndingIcon,
    theme: { borderRadius, colors },
  }) => css`
    border-radius: ${borderRadius.small};
    border: 1px solid ${colors.outline};

    ${when(
      withLeadingIcon,
      `
        padding-left: 8px;
      `,
    )}

    ${when(
      withEndingIcon,
      `
        padding-right: 8px;
      `,
    )}

    ${when(
      dirty,
      `
        border-color: ${colors?.caution};
      `,
    )}

    ${when(
      selected,
      `
        background-color: ${colors.secondaryContainer};
        border-color: ${colors.secondaryContainer};
      `,
    )}

    ${when(
      variant === CHIP_VARIANT_PRIMARY,
      `
        border-color: ${colors.primary};
      `,
    )}
    
    ${when(
      error,
      `
          border-color: ${colors.error};
        `,
    )}

    ${when(
      variant === CHIP_VARIANT_PRIMARY && selected,
      `
        background-color: ${colors.primary};
      `,
    )}

    ${when(
      disabled && selected,
      `
        border-color: transparent;
      `,
    )}

    ${when(
      disabled,
      `
        background-color: transparent;
        opacity: 0.38;
        pointer-events: none;
      `,
    )}

    ${when(
      isDraggingOver,
      css`
        ${StateLayer} {
          visibility: visible;
          opacity: 0.08;

          ${({ variant, selected, theme: { colors } }) => css`
            background-color: ${colors.onSurfaceVariant};

            ${when(
              selected,
              `
                background-color: ${colors.onSecondaryContainer};
              `,
            )}

            ${when(
              variant === CHIP_VARIANT_PRIMARY,
              `
                background-color: ${colors.onSurfaceVariant};
              `,
            )}

            ${when(
              variant === CHIP_VARIANT_PRIMARY && selected,
              `
                background-color: ${colors.onPrimary};
              `,
            )}
          `}
        }
      `,
    )}
  `}

  &:hover,
  &:active {
    ${StateLayer} {
      visibility: visible;
      opacity: 0.08;

      ${({ variant, selected, theme: { colors } }) => css`
        background-color: ${colors.onSurfaceVariant};

        ${when(
          selected,
          `
            background-color: ${colors.onSecondaryContainer};
          `,
        )}

        ${when(
          variant === CHIP_VARIANT_PRIMARY,
          `
            background-color: ${colors.onSurfaceVariant};
          `,
        )}

        ${when(
          variant === CHIP_VARIANT_PRIMARY && selected,
          `
            background-color: ${colors.onPrimary};
          `,
        )}
      `}
    }
  }

  &:focus:focus-visible {
    ${StateLayer} {
      visibility: visible;
      opacity: 0.12;

      ${({ variant, selected, theme: { colors } }) => css`
        background-color: ${colors.onSurfaceVariant};
        opacity: 0.12;

        ${when(
          selected,
          `
            background-color: ${colors.onSecondaryContainer};
          `,
        )}

        ${when(
          variant === CHIP_VARIANT_PRIMARY,
          `
            background-color: ${colors.onSurfaceVariant};
          `,
        )}

        ${when(
          variant === CHIP_VARIANT_PRIMARY && selected,
          `
            background-color: ${colors.onPrimary};
          `,
        )}
      `}
    }
  }
`;
