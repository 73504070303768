import {
  FETCH_ALL_WORKSPACES,
  FETCH_ALL_WORKSPACES_SUCCEEDED,
  FETCH_ALL_WORKSPACES_FAILED,
  WORKSPACE_SELECTED,
  CREATE_WORKSPACE,
  RESET_SELECTED_WORKSPACE,
  CREATE_WORKSPACE_FAILED,
  CREATE_WORKSPACE_SUCCEEDED,
  DISMISS_DELETE_WORKSPACE_DIALOG,
  SHOW_DELETE_WORKSPACE_DIALOG,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCEEDED,
  DELETE_WORKSPACE_FAILED,
  DISMISS_UPDATE_WORKSPACE_DIALOG,
  SHOW_UPDATE_WORKSPACE_DIALOG,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCEEDED,
  UPDATE_WORKSPACE_FAILED,
  STREAM_TOOLPATH_INSTRUCTIONS,
  STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
  STREAM_TOOLPATH_INSTRUCTIONS_FAILED,
  UPDATE_PROJECT_SEARCH_BAR_VALUE,
  TOKEN_EXPIRE_LOG_OUT_SUCCESS,
} from '../constants/actionTypes.js';

const initialState = {
  ui: {
    fetchesInProgress: 0,
    isDeletingWorkspace: false,
    workspaceIdToDelete: null,
    isUpdatingWorkspace: false,
    workspaceIdToUpdate: null,
    searchBarValue: '',
  },
  data: {
    workspaces: [],
    selectedWorkspace: null,
    selectedWorkflow: null,
  },
};

const workspacesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_WORKSPACES_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          workspaces: action.payload,
        },
      };
    case WORKSPACE_SELECTED:
      return {
        ...state,
        data: {
          ...state.data,
          selectedWorkspace: action.workspace,
        },
      };
    case RESET_SELECTED_WORKSPACE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedWorkspace: initialState.data.selectedWorkspace,
        },
      };
    case UPDATE_PROJECT_SEARCH_BAR_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          searchBarValue: action.payload.value,
        },
      };
    case CREATE_WORKSPACE_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCreatingWorkspace: false,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          workspaces: [...state.data.workspaces, action.payload],
        },
      };
    case STREAM_TOOLPATH_INSTRUCTIONS_FAILED:
    case STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED:
    case CREATE_WORKSPACE_FAILED:
    case FETCH_ALL_WORKSPACES_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case SHOW_UPDATE_WORKSPACE_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingWorkspace: true,
          workspaceIdToUpdate: action.workspaceIdToUpdate,
        },
      };
    case DISMISS_UPDATE_WORKSPACE_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingWorkspace: false,
          workspaceIdToUpdate: null,
        },
      };
    case UPDATE_WORKSPACE_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isUpdatingWorkspace: false,
          workspaceIdToUpdate: null,
        },
        data: {
          ...state.data,
          workspaces: state.data.workspaces.map((wrs) => {
            if (wrs.id === action.payload.workspaceId) {
              return {
                ...wrs,
                name: action.payload.name,
                publicAccess: action.payload.publicAccessible,
              };
            }
            return wrs;
          }),
        },
      };
    case UPDATE_WORKSPACE_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isUpdatingWorkspace: false,
          workspaceIdToUpdate: null,
        },
      };
    case SHOW_DELETE_WORKSPACE_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingWorkspace: true,
          workspaceIdToDelete: action.workspaceIdToDelete,
        },
      };
    case DISMISS_DELETE_WORKSPACE_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingWorkspace: false,
          workspaceIdToDelete: null,
        },
      };
    case STREAM_TOOLPATH_INSTRUCTIONS:
    case DELETE_WORKSPACE:
    case UPDATE_WORKSPACE:
    case CREATE_WORKSPACE:
    case FETCH_ALL_WORKSPACES:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case DELETE_WORKSPACE_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingWorkspace: false,
          workspaceIdToDelete: null,
        },
        data: {
          ...state.data,
          workspaces: state.data.workspaces.filter(
            (workspace) => workspace.id !== action.payload.workspaceId,
          ),
        },
      };
    case DELETE_WORKSPACE_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingWorkspace: false,
          workspaceIdToDelete: null,
        },
      };

    case TOKEN_EXPIRE_LOG_OUT_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: initialState.ui.fetchesInProgress,
        },
      };
    }

    default:
      return state;
  }
};

export default workspacesReducer;
