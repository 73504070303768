import { useCallback, useMemo } from 'react';
import moment from 'moment';
import useModal from '@hooks/useModal';
import useFileList from '@hooks/files/useFileList';
import useFileQueries from '@hooks/files/useFileQueries';
import { useIntl } from 'react-intl';
import { FILE_EXTENSION_REG_EXP } from '@constants/designs';
import { MODAL_IDS } from '@constants/modalDataTypes';

const useProjectFiles = ({
  projectId,
  action,
  useStale = false,
  onlyVisibleFiles = false,
  allowedFileTypes,
  allowListItemMoreActions = true,
  displayedInModal = false,
}) => {
  const intl = useIntl();
  const { projectFilesQuery, projectFileThumbnailsQuery } = useFileQueries({
    projectId,
    onlyVisibleFiles,
    useStale,
    fetchThumbnails: true,
  });
  const { showModal } = useModal();

  const getFileType = useCallback((fileName = '') => {
    return FILE_EXTENSION_REG_EXP.exec(fileName)?.[1]?.toLowerCase();
  }, []);

  const { getFileDropDownMenuActions } = useFileList(displayedInModal);
  const files = projectFilesQuery.data?.filter(({ visible }) => visible);
  const getFileIcon = useCallback(
    (file) => {
      const fileType = getFileType(file?.filename);
      if (fileType === 'aib') {
        return 'draft_0';
      }

      return 'mesh_0';
    },
    [getFileType],
  );

  const fileListItems = useMemo(
    () =>
      files?.map((file) => {
        const onClick = action
          ? () => action(file)
          : () => showModal(MODAL_IDS.FILE_PREVIEW, { file });

        let optionalProps = {};

        if (allowListItemMoreActions) {
          optionalProps = {
            endingIconButtonIconName: 'more_vert_0',
            endingIconButtonDropDownProps: {
              dropDownMenuItems: getFileDropDownMenuActions(file),
            },
          };
        }

        return {
          id: file?.id,
          disabled: allowedFileTypes
            ? !allowedFileTypes?.includes(getFileType(file?.filename))
            : false,
          label: file?.filename,
          thumbnailSkeleton: projectFileThumbnailsQuery.isLoading,
          thumbnailUrl:
            projectFileThumbnailsQuery.data?.thumbnailsData?.[file?.id],
          description: intl.formatMessage(
            {
              id: 'filespage.files_list.file_created_at',
              defaultMessage: 'Created: {date}',
            },
            { date: moment(file?.createdAt).format('DD/MM/YYYY') },
          ),
          leadingIconName: getFileIcon(file),
          createdAt: file?.createdAt,
          ...optionalProps,
          onClick,
        };
      }),
    [
      intl,
      files,
      getFileDropDownMenuActions,
      getFileIcon,
      action,
      showModal,
      allowedFileTypes,
      getFileType,
      allowListItemMoreActions,
      projectFileThumbnailsQuery.isLoading,
      projectFileThumbnailsQuery.data,
    ],
  );

  return {
    fileListItems,
  };
};

export default useProjectFiles;
