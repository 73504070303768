import styled, { css, keyframes } from 'styled-components';
import { flexCenterVertical, flexColumn, when } from '@stylesheets/helpers';

const topLayer = css`
  position: relative;
  z-index: 1;
`;

const topLayerHighest = css`
  position: relative;
  z-index: 2;
`;

const sidebar = css`
  height: 100%;
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level4};
  `}
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.surfaceDim};
  `}
`;

export const BackgroundLayer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.3s linear;

  ${({
    toolbarCollapsed,
    settingBarCollapsed,
    theme: { screenSize, dimensions, spacing },
  }) => css`
    width: calc(
      100% -
        ${dimensions[
          toolbarCollapsed
            ? 'editorLayoutCollapsedSideBarWidth'
            : 'editorLayoutFullSideBarWidth'
        ]?.[screenSize]} -
        ${dimensions[
          settingBarCollapsed
            ? 'editorLayoutCollapsedEditorWidth'
            : 'editorLayoutFullEditorWidth'
        ]?.[screenSize]} + ${spacing.level4} * 2
    );
    left: calc(
      ${dimensions[
          toolbarCollapsed
            ? 'editorLayoutCollapsedSideBarWidth'
            : 'editorLayoutFullSideBarWidth'
        ]?.[screenSize]} - ${spacing.level4}
    );

    .threejs {
      width: calc(
        100svw - ${dimensions.editorLayoutCollapsedSideBarWidth?.[screenSize]} -
          ${dimensions.editorLayoutCollapsedEditorWidth?.[screenSize]} +
          ${spacing.level4} * 2
      ) !important;
      heigth: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`;

export const Grid = styled.div`
  height: 100%;
  display: grid;
  transition: grid-template-columns 0.3s ease-in-out;

  ${({
    toolbarCollapsed,
    settingBarCollapsed,
    theme: { screenSize, dimensions },
  }) => css`
    grid-template-columns:
      ${dimensions[
        toolbarCollapsed
          ? 'editorLayoutCollapsedSideBarWidth'
          : 'editorLayoutFullSideBarWidth'
      ]?.[screenSize]}
      1fr
      ${dimensions[
        settingBarCollapsed
          ? 'editorLayoutCollapsedEditorWidth'
          : 'editorLayoutFullEditorWidth'
      ]?.[screenSize]};
  `}
`;

export const LeftSideBar = styled.div`
  ${sidebar}
  ${topLayerHighest}
`;

export const CenterColumn = styled.div`
  ${flexColumn}
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

export const TopBar = styled.div`
  width: 100%;
  ${flexCenterVertical}
  justify-content: center;
  ${topLayer}

  ${({ theme: { spacing } }) => css`
    padding-block: ${spacing.level6} ${spacing.level4};
  `}
`;

export const BottomBar = styled.div`
  width: 100%;
  ${flexCenterVertical}
  justify-content: center;
  ${topLayer}
  margin-top: auto;

  ${({ theme: { spacing } }) => css`
    padding-block: ${spacing.level4} ${spacing.level6};
  `}
`;

const slideOutAnimation = (toSize) => keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(${toSize});
  }
`;

const slideInAnimation = (toSize) => keyframes`
  from {
    transform: translateX(${toSize});
  }

  to {
    transform: translateX(0);
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`;

export const RightSideBar = styled.div`
  display: flex;
  justify-content: flex-end;
  ${sidebar}
  ${topLayer}
  position: relative;
`;

export const LayoutSettingBarLayer = styled.div`
  position: absolute;

  ${({
    resetAnimation,
    visible,
    animationStarted,
    slideOut,
    fadeOut,
    theme: { spacing, colors, borderRadius, screenSize, dimensions },
  }) => css`
    top: ${spacing.level4};
    right: ${spacing.level4};
    bottom: ${spacing.level4};
    left: ${spacing.level4};
    background-color: ${colors.surfaceContainerLow};
    border-radius: ${borderRadius.large};
    display: block;

    ${when(
      !animationStarted && !visible,
      `
        display: none;

        &[data-animating='true'] {
          display: none;
        }
      `,
    )}

    ${when(
      !resetAnimation && slideOut,
      css`
        animation: ${slideOutAnimation(
            dimensions.editorLayoutFullEditorWidth?.[screenSize],
          )}
          0.4s forwards;

        ${when(
          visible,
          css`
            animation: ${slideInAnimation(
                dimensions.editorLayoutFullEditorWidth?.[screenSize],
              )}
              0.4s forwards;
          `,
        )}
      `,
    )}

    ${when(
      !resetAnimation && fadeOut,
      css`
        animation: ${fadeOutAnimation} 0.3s ease-in-out forwards;

        ${when(
          visible,
          css`
            animation: ${fadeInAnimation} 0.3s ease-in-out forwards;
          `,
        )}
      `,
    )}
  `}
`;
