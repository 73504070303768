export const toolpathStages = Object.freeze({
  QUEUED: 'QUEUED',
  APPLYING_CONSTRAINTS: 'APPLYING_CONSTRAINTS',
  MINIMIZING_DEPENDENCY_GRAPH: 'MINIMIZING_DEPENDENCY_GRAPH',
  DETECTING_UNPRINTABLE_LINES: 'DETECTING_UNPRINTABLE_LINES',
  CREATING_LINES: 'CREATING_LINES',
  JOINING_LINES: 'JOINING_LINES',
  CLASSIFYING_LINES: 'CLASSIFYING_LINES',
  SEQUENCING_LINES: 'SEQUENCING_LINES',
  CREATING_MESH: 'CREATING_MESH',
  CALCULATING_CURVATURE: 'CALCULATING_CURVATURE',
  CALCULATING_CANTILEVER: 'CALCULATING_CANTILEVER',
  GENERATING_PROGRAM: 'GENERATING_PROGRAM',
  COMPLETE: 'COMPLETE',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  UNPRINTABLE_LINES: 'UNPRINTABLE_LINES',
  UNAVOIDABLE_COLLISIONS: 'UNAVOIDABLE_COLLISIONS',
  FAILED: 'FAILED',
  RUNNING_OPERATOR: 'RUNNING_OPERATOR',
  SIMULATING_BUILD: 'SIMULATING_BUILD',
});

export const toolpathStageTextByStage = {
  [toolpathStages.QUEUED]: 'Queued',
  [toolpathStages.APPLYING_CONSTRAINTS]:
    'Applying constraints and calculating dependencies...',
  [toolpathStages.MINIMIZING_DEPENDENCY_GRAPH]:
    'Minimizing dependency graph...',
  [toolpathStages.DETECTING_UNPRINTABLE_LINES]:
    'Detecting unprintable lines...',
  [toolpathStages.SEQUENCING_LINES]: 'Calculating optimal path...',
  [toolpathStages.CREATING_LINES]: 'Creating lines...',
  [toolpathStages.CREATING_MESH]: 'Creating mesh...',
  [toolpathStages.JOINING_LINES]: 'Joining lines...',
  [toolpathStages.CLASSIFYING_LINES]: 'Classifying lines...',
  [toolpathStages.CALCULATING_CURVATURE]: 'Calculating curvature...',
  [toolpathStages.CALCULATING_CANTILEVER]: 'Calculating cantilever...',
  [toolpathStages.GENERATING_PROGRAM]: 'Generating Toolpath...',
  [toolpathStages.COMPLETE]: 'Complete',
  [toolpathStages.CANCELLED]: 'Cancelled',
  [toolpathStages.CANCELLING]: 'Cancelling...',
  [toolpathStages.UNPRINTABLE_LINES]: 'Unprintable lines detected',
  [toolpathStages.UNAVOIDABLE_COLLISIONS]:
    'Unavoidable collisions between extruder and geometry',
  [toolpathStages.FAILED]: 'Failed',
  [toolpathStages.RUNNING_OPERATOR]: 'Computing operator',
  [toolpathStages.SIMULATING_BUILD]: 'Computing simulation',
};
