import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isEmpty, isEqual, isFunction, isUndefined, get, pick } from 'lodash';
import moment from 'moment';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import usePrevious from '@hooks/usePrevious';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import Search from '@components/1-atoms/Search';
import Tabs, { TABS_VARIANT_TEXT } from '@app/components/2-molecules/Tabs';
import ActionBar from '@components/1-atoms/ActionBar';
import Chip from '@components/1-atoms/Chip';
import SkeletonList from '@app/components/1-atoms/SkeletonList';
import ListItem from '@components/1-atoms/ListItem';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import { DROP_DOWN_MENU_POSITION_BOTTOM_LEFT } from '@components/2-molecules/DropDownMenu';
import ListContainer from '@components/3-organisms/ListContainer';
import {
  Wrapper,
  TabsContainer,
  SearchContainer,
  ScrollableList,
  List,
  DraggableWrapper,
  DraggableWrapperClone,
  DndPlaceholder,
} from './PageList.styled';
import { matchWithPattern, matchWithLevenshtein } from '@utils/string';
import SortingChip from '@app/components/2-molecules/SortingChip';

export const PAGE_LIST_SEARCH_IN_LABEL = 'listLabel';
export const PAGE_LIST_SEARCH_IN_DESCRIPTION = 'listDescription';

const PageList = ({
  actionChips = [],
  className,
  createItemMessagDescription,
  createItemMessageButtonIconName = 'add_0',
  createItemMessageButtonLabel,
  createItemMessageIconName,
  createItemMessageOnButtonClick,
  createItemMessageTitle,
  dataTestId = 'page-list',
  drag = {},
  headerLeadingIconButtonIconName,
  headerTitle,
  listItems = [],
  listItemVariant,
  loading,
  onHeaderLeadingIconButtonClick,
  onSearchChange,
  onSearchClear,
  onTabClick,
  searchAutoFocus,
  searchIn = [PAGE_LIST_SEARCH_IN_LABEL],
  searchPlaceholder,
  searchValue,
  skeletonLength = 3,
  sortOptions,
  tabs,
}) => {
  const intl = useIntl();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResultIds, setSearchResultIds] = useState([]);
  const isSearchControlled = !!onSearchChange;
  const [selectedSortDir, setSelectedSortDir] = useState('desc');
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions?.[0],
  );

  const previousListItems = usePrevious(listItems);
  const previousSearchQuery = usePrevious(searchQuery);
  const previousSelectedSortDir = usePrevious(selectedSortDir);
  const previousSelectedSortOption = usePrevious(selectedSortOption);
  const previousSearchResultIds = usePrevious(searchResultIds);

  const isGoupsListItems = useMemo(
    () => listItems?.some((item) => item?.group),
    [listItems],
  );

  const isSearchInLabel = searchIn?.includes(PAGE_LIST_SEARCH_IN_LABEL);
  const isSearchInDescription = searchIn?.includes(
    PAGE_LIST_SEARCH_IN_DESCRIPTION,
  );

  const listItemsToRender = useMemo(
    () =>
      searchResultIds?.reduce((acc, id) => {
        const listItem = listItems.find((item) => item.id === id);

        if (listItem) {
          acc.push(listItem);
        }

        return acc;
      }, []),
    [listItems, searchResultIds],
  );

  const groupListItemsToRender = useMemo(() => {
    if (!isGoupsListItems) return [];

    const groups = listItemsToRender.reduce((acc, item) => {
      const groupName = item?.group || 'Unknown';
      const groupExists = acc[groupName];

      if (!groupExists) {
        acc[groupName] = [];
      }

      acc[groupName].push(item);

      return acc;
    }, {});

    return groups;
  }, [isGoupsListItems, listItemsToRender]);

  const showTabs = !isEmpty(tabs);
  const hasSearchResults = !isEmpty(listItemsToRender);
  const showCreateItemMessage =
    !loading && !!createItemMessageButtonLabel && isEmpty(listItems);
  const showEmptyMessage =
    !loading &&
    !showCreateItemMessage &&
    !hasSearchResults &&
    searchQuery &&
    !isEmpty(listItems);
  const showSearch = !loading && !isEmpty(listItems) && !showCreateItemMessage;
  const showSortAction = !isEmpty(sortOptions);
  const showActionChips =
    !loading &&
    !showCreateItemMessage &&
    (showSortAction || !isEmpty(actionChips));
  const showList = !loading && hasSearchResults;
  const showRegularList = showList && !isGoupsListItems && isEmpty(drag);
  const showRegularDraggableList =
    showList && !isGoupsListItems && !isEmpty(drag);
  const showGroupList = showList && isGoupsListItems;

  const handleSearchChange = useCallback(
    (event) => {
      const query = event.target.value;

      setSearchQuery(query);
    },
    [setSearchQuery],
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const handleTabClick = useCallback(
    (tab) => {
      if (isSearchControlled) return;

      onTabClick?.(tab);
      handleSearchClear();
    },
    [isSearchControlled, onTabClick, handleSearchClear],
  );

  const isDateString = useCallback((str) => {
    return moment(str, moment.ISO_8601, true).isValid();
  }, []);

  const sortComparator = useCallback(
    (a, b) => {
      let aValue = get(a, selectedSortOption?.value);
      let bValue = get(b, selectedSortOption?.value);

      if (isEmpty(aValue) || isEmpty(bValue)) return 0;

      const compareDates = isDateString(aValue) && isDateString(bValue);

      if (compareDates) {
        if (selectedSortDir === 'asc') {
          return moment(aValue).diff(moment(bValue));
        }

        if (selectedSortDir === 'desc') {
          return moment(bValue).diff(moment(aValue));
        }
      }

      aValue = aValue?.toLowerCase();
      bValue = bValue?.toLowerCase();

      if (selectedSortDir === 'asc') {
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      }

      if (selectedSortDir === 'desc') {
        return bValue.localeCompare(aValue, undefined, { numeric: true });
      }
    },
    [isDateString, selectedSortDir, selectedSortOption?.value],
  );

  const filterListItems = useCallback(() => {
    if (isSearchControlled) return;

    const observedProps = ['id', 'label', 'description'];
    const previousListItemProps = previousListItems?.map((item) =>
      pick(item, observedProps),
    );
    const listItemProps = listItems?.map((item) => pick(item, observedProps));
    const observedPropsUpdated = !isEqual(previousListItemProps, listItemProps);
    const searchQueryIsUpdated =
      !isUndefined(previousSearchQuery) &&
      !isEqual(previousSearchQuery, searchQuery);

    const skip = !observedPropsUpdated && !searchQueryIsUpdated;

    if (skip) return;

    const filteredListItems = listItems.filter((listItem) => {
      const formattedQuery = searchQuery.toLowerCase().trim();
      const searchStrings = [];

      if (isSearchInLabel && listItem?.label) {
        searchStrings.push(listItem.label?.toLowerCase?.());
      }

      if (isSearchInDescription && listItem?.description) {
        searchStrings.push(listItem.description?.toLowerCase?.());
      }

      if (!searchStrings.length) return true;

      const matchedPatterns = !!matchWithPattern(formattedQuery, searchStrings)
        ?.length;
      const matchedLevenshtein = !!matchWithLevenshtein(
        formattedQuery,
        searchStrings,
      )?.length;
      const queryMatched = matchedPatterns || matchedLevenshtein;

      return queryMatched;
    });

    let itemIds = filteredListItems.map((listItem) => listItem?.id);

    if (showSortAction) {
      const sortedListItems = filteredListItems.sort(sortComparator);
      const sortedListItemIds = sortedListItems.map((listItem) => listItem?.id);
      itemIds = sortedListItemIds;
    }

    setSearchResultIds(itemIds);
  }, [
    showSortAction,
    isSearchControlled,
    isSearchInDescription,
    isSearchInLabel,
    listItems,
    previousListItems,
    previousSearchQuery,
    searchQuery,
    sortComparator,
  ]);

  const sortSearchResults = useCallback(() => {
    const skip =
      !showSortAction ||
      isEmpty(listItemsToRender) ||
      (isEqual(previousSearchResultIds, searchResultIds) &&
        isEqual(previousSelectedSortDir, selectedSortDir) &&
        isEqual(previousSelectedSortOption, selectedSortOption));

    if (skip) return;

    const sortedSearchResults = listItemsToRender.slice().sort(sortComparator);
    const sortedSearchResultIds = sortedSearchResults.map(
      (listItem) => listItem?.id,
    );

    setSearchResultIds(sortedSearchResultIds);
  }, [
    listItemsToRender,
    previousSearchResultIds,
    previousSelectedSortDir,
    previousSelectedSortOption,
    searchResultIds,
    selectedSortDir,
    selectedSortOption,
    showSortAction,
    sortComparator,
  ]);

  const handleSortDirSelect = useCallback(
    (sortDir) => {
      setSelectedSortDir(sortDir);
    },
    [setSelectedSortDir],
  );

  const handleSortOptionSelect = useCallback((sortOption) => {
    setSelectedSortOption(sortOption);
  }, []);

  useEffect(() => {
    filterListItems();
  }, [filterListItems]);

  useEffect(() => {
    sortSearchResults();
  }, [sortSearchResults]);

  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withCreateItemMessage={showCreateItemMessage}
    >
      {(headerTitle || headerLeadingIconButtonIconName) && (
        <PageHeader
          dataTestId={`${dataTestId}__header`}
          disabled={loading}
          size={PAGE_HEADER_VARIANT_LARGE}
          title={headerTitle}
          leadingIconButtonIconName={headerLeadingIconButtonIconName}
          onLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
        />
      )}

      {showTabs && (
        <TabsContainer>
          <Tabs
            dataTestId={`${dataTestId}__tabs`}
            disabled={loading}
            variant={TABS_VARIANT_TEXT}
            tabs={tabs}
            onTabClick={handleTabClick}
          />
        </TabsContainer>
      )}

      {showSearch && (
        <SearchContainer withMarginBottom={!showActionChips}>
          <Search
            dataTestId={`${dataTestId}__search`}
            autoFocus={searchAutoFocus}
            disabled={loading}
            placeholder={searchPlaceholder}
            value={isSearchControlled ? searchValue : searchQuery}
            onChange={isSearchControlled ? onSearchChange : handleSearchChange}
            onClear={isSearchControlled ? onSearchClear : handleSearchClear}
          />
        </SearchContainer>
      )}

      {showActionChips && (
        <ActionBar dataTestId={`${dataTestId}__action-bar`}>
          {showSortAction && (
            <SortingChip
              dataTestId={`${dataTestId}__sorting-chip`}
              onSortDirSelect={handleSortDirSelect}
              onSortOptionSelect={handleSortOptionSelect}
              selectedSortDir={selectedSortDir}
              selectedSortOption={selectedSortOption}
              sortOptions={sortOptions}
            />
          )}

          {actionChips?.map((actionChip) => (
            <Chip
              key={actionChip?.id}
              ref={actionChip?.ref}
              disabled={actionChip?.disabled || loading}
              selected={actionChip?.selected}
              endingIconName={actionChip?.endingIconName}
              selectedEndingIconName={actionChip?.selectedEndingIconName}
              label={actionChip?.label}
              selectedLeadingIconName={actionChip?.selectedLeadingIconName}
              leadingIconName={actionChip?.leadingIconName}
              onClick={actionChip?.onClick}
              variant={actionChip?.variant}
              dropDownMenuItems={actionChip?.dropDownMenuItems}
              dropDownMenuPosition={
                actionChip?.dropDownMenuPosition ||
                DROP_DOWN_MENU_POSITION_BOTTOM_LEFT
              }
              emptyDropDownStateDescription={
                actionChip?.emptyDropDownStateDescription
              }
              emptyDropDownStateIconName={
                actionChip?.emptyDropDownStateIconName
              }
              emptyDropDownStateTitle={actionChip?.emptyDropDownStateTitle}
              emptyDropDownStateLinkButtonLabel={
                actionChip?.emptyDropDownStateLinkButtonLabel
              }
              emptyDropDownStateLinkButtonPath={
                actionChip?.emptyDropDownStateLinkButtonPath
              }
              emptyDropDownStateOnLinkButtonClick={
                actionChip?.emptyDropDownStateOnLinkButtonClick
              }
            />
          ))}
        </ActionBar>
      )}

      {loading && (
        <SkeletonList
          dataTestId={`${dataTestId}__skeleton-list`}
          length={skeletonLength}
        />
      )}

      {showEmptyMessage && (
        <EmptyStateBox
          dataTestId={`${dataTestId}__empty-state`}
          iconName="search_0"
          title={intl.formatMessage({
            id: 'emptystatebox.no_result_found.title',
            defaultMessage: 'No result found',
          })}
          description={intl.formatMessage({
            id: 'emptystatebox.no_result_found.description',
            defaultMessage:
              "Try adjusting your search or filter to find what you're looking for.",
          })}
        />
      )}

      {showCreateItemMessage && (
        <EmptyStateBox
          dataTestId={`${dataTestId}__create-item`}
          iconName={createItemMessageIconName}
          title={createItemMessageTitle}
          description={createItemMessagDescription}
          primaryButtonIconName={createItemMessageButtonIconName}
          primaryButtonLabel={createItemMessageButtonLabel}
          onPrimaryButtonClick={createItemMessageOnButtonClick}
        />
      )}

      {showRegularList && (
        <ScrollableList>
          <List data-testid={`${dataTestId}__list`}>
            {listItemsToRender.map((listItem) => (
              <ListItem
                key={listItem?.id}
                variant={listItemVariant}
                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                disabled={listItem?.disabled}
                label={listItem?.label}
                description={listItem?.description}
                thumbnailSkeleton={listItem?.thumbnailSkeleton}
                thumbnailUrl={listItem?.thumbnailUrl}
                leadingIconName={listItem?.leadingIconName}
                endingIconButtonRef={listItem?.endingIconButtonRef}
                endingIconButtonIconName={listItem?.endingIconButtonIconName}
                endingIconButtonDropDownProps={
                  listItem?.endingIconButtonDropDownProps
                }
                onEndingIconButtonClick={listItem?.onEndingIconButtonClick}
                onClick={listItem?.onClick}
              />
            ))}
          </List>
        </ScrollableList>
      )}

      {showRegularDraggableList && (
        <Droppable droppableId={drag?.droppableId} isDropDisabled>
          {(provided) => (
            <ScrollableList
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <List data-testid={`${dataTestId}__list`}>
                {listItemsToRender.map((listItem, index) => (
                  <Draggable
                    key={`${drag?.droppableId}-page-list-${listItem?.id}`}
                    index={index}
                    draggableId={listItem?.draggableId || listItem?.id}
                    isDragDisabled={drag?.isDragDisabled}
                    clone
                  >
                    {(provided, snapshot) => {
                      const isDraggingOverTargetDroppable =
                        snapshot.draggingOver === drag?.droppableId;

                      return (
                        <>
                          <DraggableWrapper
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            draggingOverDroppable={
                              isDraggingOverTargetDroppable
                            }
                            dragging={snapshot.isDragging}
                            data-dragging={snapshot.isDragging}
                            style={{
                              ...provided.draggableProps.style,
                              ...(snapshot.isDropAnimating
                                ? {
                                    opacity: 0,
                                    transform: 'translate(0)',
                                    transition:
                                      'transform 0.01s cubic-bezier(.2,1,.1,1)',
                                  }
                                : {
                                    transform:
                                      provided.draggableProps.style.transform,
                                    transition:
                                      provided.draggableProps.style.transition,
                                  }),
                            }}
                          >
                            {/* list item until start dragging or when draggable element is not passed */}
                            {(!snapshot.isDragging ||
                              (snapshot.isDragging &&
                                !drag?.draggableElement)) && (
                              <ListItem
                                key={listItem?.id}
                                variant={listItemVariant}
                                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                                disabled={listItem?.disabled}
                                label={listItem?.label}
                                description={listItem?.description}
                                thumbnailSkeleton={listItem?.thumbnailSkeleton}
                                thumbnailUrl={listItem?.thumbnailUrl}
                                leadingIconName={listItem?.leadingIconName}
                                endingIconButtonRef={
                                  listItem?.endingIconButtonRef
                                }
                                endingIconButtonIconName={
                                  listItem?.endingIconButtonIconName
                                }
                                endingIconButtonDropDownProps={
                                  listItem?.endingIconButtonDropDownProps
                                }
                                onEndingIconButtonClick={
                                  listItem?.onEndingIconButtonClick
                                }
                                onClick={listItem?.onClick}
                              />
                            )}

                            {/* show draggable element when is dragging */}
                            {snapshot.isDragging &&
                              drag?.draggableElement &&
                              (isFunction(drag?.draggableElement)
                                ? drag?.draggableElement(listItem?.dragData)
                                : drag?.draggableElement)}
                          </DraggableWrapper>

                          {/* placeholder to keep list still and the dragging element is not moved from the list on drag */}
                          {snapshot.isDragging && (
                            <DraggableWrapperClone>
                              <ListItem
                                key={listItem?.id}
                                variant={listItemVariant}
                                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                                disabled={listItem?.disabled}
                                label={listItem?.label}
                                description={listItem?.description}
                                thumbnailSkeleton={listItem?.thumbnailSkeleton}
                                thumbnailUrl={listItem?.thumbnailUrl}
                                leadingIconName={listItem?.leadingIconName}
                                endingIconButtonRef={
                                  listItem?.endingIconButtonRef
                                }
                                endingIconButtonIconName={
                                  listItem?.endingIconButtonIconName
                                }
                                endingIconButtonDropDownProps={
                                  listItem?.endingIconButtonDropDownProps
                                }
                                onEndingIconButtonClick={
                                  listItem?.onEndingIconButtonClick
                                }
                                onClick={listItem?.onClick}
                              />
                            </DraggableWrapperClone>
                          )}
                        </>
                      );
                    }}
                  </Draggable>
                ))}
              </List>

              <DndPlaceholder>{provided.placeholder}</DndPlaceholder>
            </ScrollableList>
          )}
        </Droppable>
      )}

      {showGroupList && (
        <ScrollableList>
          {Object.keys(groupListItemsToRender).map((groupName) => (
            <ListContainer
              dataTestId={`${dataTestId}__group-${groupName}`}
              drag={drag}
              headerTitle={groupName}
              key={`page-list-group-${groupName}`}
              listItems={groupListItemsToRender[groupName]}
              scrollable={false}
            />
          ))}
        </ScrollableList>
      )}
    </Wrapper>
  );
};

PageList.propTypes = {
  actionChips: PropTypes.arrayOf(PropTypes.shape(Chip.propTypes)),
  className: PropTypes.string,
  createItemMessagDescription: PropTypes.string,
  createItemMessageButtonIconName:
    EmptyStateBox.propTypes.primaryButtonIconName,
  createItemMessageButtonLabel: EmptyStateBox.propTypes.primaryButtonLabel,
  createItemMessageIconName: EmptyStateBox.propTypes.iconName,
  createItemMessageOnButtonClick: EmptyStateBox.propTypes.onPrimaryButtonClick,
  createItemMessageTitle: PropTypes.string,
  dataTestId: PropTypes.string,
  drag: PropTypes.object,
  headerLeadingIconButtonIconName: PropTypes.string,
  headerTitle: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.shape(ListItem.propTypes)),
  listItemVariant: ListItem.propTypes.variant,
  loading: PropTypes.bool,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchClear: PropTypes.func,
  onTabClick: PropTypes.func,
  searchIn: PropTypes.arrayOf(
    PropTypes.oneOf([
      PAGE_LIST_SEARCH_IN_LABEL,
      PAGE_LIST_SEARCH_IN_DESCRIPTION,
    ]),
  ),
  searchPlaceholder: PropTypes.string,
  searchAutoFocus: PropTypes.bool,
  searchValue: PropTypes.string,
  skeletonLength: PropTypes.number,
  sortOptions: SortingChip.propTypes.sortOptions,
  tabs: PropTypes.array,
};

export default PageList;
