import { THEME_NAME_DARK, THEME_NAME_LIGHT } from '@stylesheets/theme';
import getIntlProvider from '@utils/getIntlProvider';

const intl = getIntlProvider();

export const themeTypes = {
  SYSTEM: {
    value: 'SYSTEM',
    label: intl.formatMessage({
      id: 'settings.account.theme.system',
      defaultMessage: 'System (auto)',
    }),
    leadingIconName: 'devices_0',
  },
  LIGHT: {
    value: THEME_NAME_LIGHT,
    label: intl.formatMessage({
      id: 'settings.account.theme.light',
      defaultMessage: 'Light',
    }),
    leadingIconName: 'light_mode_0',
  },
  DARK: {
    value: THEME_NAME_DARK,
    label: intl.formatMessage({
      id: 'settings.account.theme.dark',
      defaultMessage: 'Dark',
    }),
    leadingIconName: 'dark_mode_0',
  },
};
