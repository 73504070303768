import actionTypes from '@actions';

const initialState = {
  mfa: {
    encryptedSecret: '',
    qrCodeUrl: '',
  },
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GENERATE_MFA_CONNECTOR_KEY_REQUEST:
    case actionTypes.VALIDATE_MFA_CODE_SUCCESS: {
      return {
        ...state,
        mfa: initialState.mfa,
      };
    }

    case actionTypes.GENERATE_MFA_CONNECTOR_KEY_SUCCESS: {
      const { encryptedSecret, qrCodeUrl } = action.payload;

      return {
        ...state,
        mfa: {
          ...state.mfa,
          encryptedSecret,
          qrCodeUrl,
        },
      };
    }

    case actionTypes.VALIDATE_MFA_CODE_FAILURE:
    case actionTypes.VALIDATE_MFA_CODE_REQUEST: {
      return {
        ...state,
        mfa: {
          ...state.mfa,
        },
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
