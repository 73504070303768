import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const ContentWrapper = styled.div`
  min-height: 100%;
  ${flexColumn}
  overflow: auto;
  user-select: none;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}
`;
