import React, { useEffect, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import usePrinterList from '@hooks/printers/usePrinterList';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import { ROUTES } from '@constants/router';
import PrintersPageContent from '@components/PrintersPageContent';
import { Wrapper } from './Printers.styled';

const Printers = () => {
  const intl = useIntl();
  const history = useHistory();
  const { getPrinterDropDownMenuActions } = usePrinterList();
  const { printersQuery, allPrinterDefinitionsQuery } = usePrinterQueries();

  const isFetching =
    !printersQuery.isFetched ||
    printersQuery.isLoading ||
    !allPrinterDefinitionsQuery.isFetched ||
    allPrinterDefinitionsQuery.isLoading;

  const refetchAllPrinterDefinitions = allPrinterDefinitionsQuery.refetch;
  const refetchPrintersQuery = printersQuery.refetch;

  const printersListItems = useMemo(
    () =>
      printersQuery.data?.map((printer) => ({
        id: printer.id,
        label: printer.name,
        description: intl.formatMessage(
          {
            id: 'printerspage.printers_list.list_item.description',
            defaultMessage: 'Created: {date}',
          },
          { date: moment(printer?.createdAt || moment()).format('DD/MM/YYYY') },
        ),
        leadingIconName: 'precision_manufacturing_0',
        endingIconButtonIconName: 'more_vert_0',
        endingIconButtonDropDownProps: {
          dropDownMenuItems: getPrinterDropDownMenuActions(printer),
        },
        createdAt: printer?.createdAt,
        onClick: () =>
          history.push(
            generatePath(ROUTES.PRINTER, {
              printerId: printer?.id,
            }),
          ),
      })),
    [intl, history, printersQuery.data, getPrinterDropDownMenuActions],
  );

  useEffect(() => {
    refetchAllPrinterDefinitions();
    refetchPrintersQuery();
  }, [refetchAllPrinterDefinitions, refetchPrintersQuery]);

  return (
    <Wrapper data-testid="printers-page">
      <PrintersPageContent
        loading={isFetching}
        printersListItems={printersListItems}
      />
    </Wrapper>
  );
};

Printers.propTypes = {};

export default Printers;
