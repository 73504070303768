import { PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP } from '../constants/utilityConstants.js';

const emptyArray = [];
const emptyObject = {};

export const getAnalyticsDashboards =
  () =>
  ({ analytics }) =>
    analytics.data.dashboards;
export const getDashboardWidgets =
  (dashboardId = '') =>
  ({ analytics }) => {
    if (!dashboardId) {
      return analytics.data?.dashboardWidgets;
    }

    return analytics.data?.dashboardWidgets?.filter(
      ({ id }) => id === dashboardId,
    );
  };
export const getSelectedDashboard =
  () =>
  ({ analytics }) =>
    analytics.data.selectedDashboard;
export const getAnalyticsWidgetTemplates =
  () =>
  ({ analytics }) =>
    analytics.data.widgetTemplates;
export const getIsAnalyticsLoading =
  () =>
  ({ analytics }) =>
    !!analytics.ui.fetchesInProgress;

export const getWidget =
  (widgetId) =>
  ({ analytics }) =>
    analytics.data.dashboardWidgets.find((widget) => widget.id === widgetId);
export const getWidgetsData =
  () =>
  ({ analytics }) =>
    analytics.data.widgets_data;
export const getPrinterSensorValues =
  (serialCode) =>
  ({ analytics }) =>
    analytics.data.printerSensorValues[serialCode] || emptyObject;
export const getPrinterImagesUrl =
  (serialCode) =>
  ({ analytics }) =>
    analytics.data.printerImagesUrl[serialCode];
export const getPrinterSensorTimings =
  (serialCode) =>
  ({ analytics }) =>
    (analytics.data.printerSensorValues[serialCode] || emptyObject)[
      PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP
    ] || emptyArray;
export const getToolpathAnalyzerDataOfToolpath =
  (toolpathId) =>
  ({ analytics }) =>
    analytics.data.toolpathAnalyzerData[toolpathId];
export const getNewDashboard =
  () =>
  ({ analytics }) =>
    analytics.ui.newDashboard;
export const getNewComponent =
  () =>
  ({ analytics }) =>
    analytics.ui.newComponent;
export const getDashboardIdToDelete =
  () =>
  ({ analytics }) =>
    analytics.ui.dashboardIdToDelete;
export const getIsDeletingDashboard =
  () =>
  ({ analytics }) =>
    analytics.ui.isDeletingDashboard;
export const getIsUpdatingDashboard =
  () =>
  ({ analytics }) =>
    analytics.ui.isUpdatingDashboard;
export const getUpdatedDashboardName =
  () =>
  ({ analytics }) =>
    analytics.ui.updatedDashboardName;
export const getSelectedPrint =
  (widgetId) =>
  ({ analytics }) =>
    analytics.ui.selectedPrint[widgetId];
export const getIsCreatingDashboard =
  () =>
  ({ analytics }) =>
    analytics.ui.isCreatingDashboard;

export const getDashboardCameras =
  () =>
  ({ analytics }) =>
    analytics.data.dashboardCameras;
