import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingActionWithIconButton from '@components/2-molecules/SettingActionWithIconButton';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import { showNozzleConfig } from '@actions/printerActions';
import useNozzleList from '@hooks/nozzle/useNozzleList';
import PropTypes from 'prop-types';
import useNozzle from '@hooks/nozzle/useNozzle';

export const NozzleLibrary = ({ selectedPrinter }) => {
  const printerId = selectedPrinter.id;
  const { getNozzles } = useNozzle();
  const nozzles = getNozzles(printerId);
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { getNozzleDropDownMenuActions } = useNozzleList();

  const addNozzle = useCallback(() => {
    dispatch(showNozzleConfig({}));
  }, [dispatch]);

  const showNozzle = useCallback(
    (nozzle) => {
      dispatch(showNozzleConfig(nozzle));
    },
    [dispatch],
  );

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.category.nozzle-library.title',
        defaultMessage: 'Nozzle library',
      })}
      onHeaderClick={() => {
        setOpen(!open);
      }}
      dataTestId={`printer-nozzle__category-nozzle-library`}
      expand={open}
      withDividerBottom
    >
      {nozzles.map((nozzle) => (
        <SettingActionWithIconButton
          key={nozzle.id}
          dataTestId={`printer-nozzle__category-nozzle-library__${nozzle.name}`}
          iconButton={{
            iconName: 'more_vert_0',
            dropDownMenuItems: getNozzleDropDownMenuActions(nozzle),
          }}
          label={nozzle.name}
          chip={{
            label: intl.formatMessage({ id: 'general.edit' }),
            onClick: () => showNozzle(nozzle),
          }}
        />
      ))}

      <SettingActionRow
        dataTestId={`printer-nozzle__category-nozzle-library__add-new`}
        button={{
          iconName: 'add_0',
          onClick: addNozzle,
          children: (
            <FormattedMessage
              id="printers.category.nozzle-library.add-button"
              defaultMessage="Add new"
            />
          ),
        }}
      ></SettingActionRow>
    </SettingsCategory>
  );
};

NozzleLibrary.propTypes = {
  selectedPrinter: PropTypes.object.isRequired,
};
