import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isEmpty, isFunction, uniqueId } from 'lodash';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import PageHeader, {
  PAGE_HEADER_VARIANT_SMALL,
} from '@components/2-molecules/PageHeader';
import SkeletonList from '@app/components/1-atoms/SkeletonList';
import ListItem from '@components/1-atoms/ListItem';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import {
  Wrapper,
  ScrollableList,
  List,
  DraggableWrapper,
  DraggableWrapperClone,
  DndPlaceholder,
} from './ListContainer.styled';

const ListContainer = ({
  className,
  dataTestId = 'list-container',
  disabled,
  drag = {},
  headerEndingButtonIconName,
  headerEndingButtonTitle,
  headerOnEndingButtonClick,
  headerTitle,
  listItems = [],
  loading,
  scrollable = true,
  skeletonLength = 3,
}) => {
  const intl = useIntl();
  const listItemsIsEmpty = !listItems?.length;
  const showEmptyMessage = !loading && listItemsIsEmpty;
  const showList = !loading && !listItemsIsEmpty && isEmpty(drag);
  const showDraggableList = !loading && !listItemsIsEmpty && !isEmpty(drag);
  const uniquListIdRef = useRef(uniqueId('list-container-'));

  const headerOptionalProps = {
    endingButtonIconName: headerEndingButtonIconName,
    endingButtonTitle: headerEndingButtonTitle,
    onEndingButtonClick: headerOnEndingButtonClick,
  };

  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      scrollable={scrollable}
    >
      <PageHeader
        dataTestId={`${dataTestId}__header`}
        variant={PAGE_HEADER_VARIANT_SMALL}
        disabled={disabled || loading}
        title={headerTitle}
        {...(loading ? {} : headerOptionalProps)}
      />

      {loading && (
        <SkeletonList
          dataTestId={`${dataTestId}__skeleton-list`}
          length={skeletonLength}
        />
      )}

      {showEmptyMessage && (
        <EmptyStateBox
          dataTestId={`${dataTestId}__empty-state`}
          iconName="search_0"
          title={intl.formatMessage({
            id: 'emptystatebox.no_result_found.title',
            defaultMessage: 'No result found',
          })}
          description={intl.formatMessage({
            id: 'emptystatebox.no_result_found.description',
            defaultMessage:
              "Try adjusting your search or filter to find what you're looking for.",
          })}
        />
      )}

      {showList && (
        <ScrollableList scrollable={scrollable}>
          <List data-testid={`${dataTestId}__list`}>
            {listItems.map((listItem) => (
              <ListItem
                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                description={listItem?.description}
                disabled={listItem?.disabled}
                autoHeight={listItem?.autoHeight}
                endingIconButtonDropDownProps={
                  listItem?.endingIconButtonDropDownProps
                }
                endingIconButtonIconName={listItem?.endingIconButtonIconName}
                endingIconButtonRef={listItem?.endingIconButtonRef}
                key={listItem?.id}
                label={listItem?.label}
                leadingIconName={listItem?.leadingIconName}
                onClick={listItem?.onClick}
                onEndingIconButtonClick={listItem?.onEndingIconButtonClick}
              />
            ))}
          </List>
        </ScrollableList>
      )}

      {showDraggableList && (
        <Droppable
          droppableId={`${drag?.droppableId}-${uniquListIdRef.current}`}
          isDropDisabled
        >
          {(provided) => (
            <ScrollableList
              ref={provided.innerRef}
              {...provided.droppableProps}
              scrollable={scrollable}
            >
              <List data-testid={`${dataTestId}__list`}>
                {listItems.map((listItem, index) => (
                  <Draggable
                    key={`${drag?.droppableId}-${uniquListIdRef.current}-list-container-list-${listItem?.id}`}
                    index={index}
                    draggableId={`${
                      listItem?.draggableId || `draggableId:{${listItem?.id}}`
                    } uniqueComponentId:{${uniquListIdRef.current}}`}
                    isDragDisabled={drag?.isDragDisabled}
                    clone
                  >
                    {(provided, snapshot) => {
                      const isDraggingOverTargetDroppable =
                        snapshot.draggingOver === drag?.droppableId;

                      return (
                        <>
                          <DraggableWrapper
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            draggingOverDroppable={
                              isDraggingOverTargetDroppable
                            }
                            dragging={snapshot.isDragging}
                            data-dragging={snapshot.isDragging}
                            style={{
                              ...provided.draggableProps.style,
                              ...(snapshot.isDropAnimating
                                ? {
                                    opacity: 0,
                                    transform: 'translate(0)',
                                    transition:
                                      'transform 0.01s cubic-bezier(.2,1,.1,1)',
                                  }
                                : {
                                    transform:
                                      provided.draggableProps.style.transform,
                                    transition:
                                      provided.draggableProps.style.transition,
                                  }),
                            }}
                          >
                            {/* list item until start dragging or when draggable element is not passed */}
                            {(!snapshot.isDragging ||
                              (snapshot.isDragging &&
                                !drag?.draggableElement)) && (
                              <ListItem
                                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                                description={listItem?.description}
                                disabled={listItem?.disabled}
                                autoHeight={listItem?.autoHeight}
                                endingIconButtonDropDownProps={
                                  listItem?.endingIconButtonDropDownProps
                                }
                                endingIconButtonIconName={
                                  listItem?.endingIconButtonIconName
                                }
                                endingIconButtonRef={
                                  listItem?.endingIconButtonRef
                                }
                                label={listItem?.label}
                                leadingIconName={listItem?.leadingIconName}
                                onClick={listItem?.onClick}
                                onEndingIconButtonClick={
                                  listItem?.onEndingIconButtonClick
                                }
                              />
                            )}

                            {/* show draggable element when is dragging */}
                            {snapshot.isDragging &&
                              drag?.draggableElement &&
                              (isFunction(drag?.draggableElement)
                                ? drag?.draggableElement(
                                    listItem?.dragData,
                                    isDraggingOverTargetDroppable,
                                  )
                                : drag?.draggableElement)}
                          </DraggableWrapper>

                          {/* placeholder to keep list still and the dragging element is not moved from the list on drag */}
                          {snapshot.isDragging && (
                            <DraggableWrapperClone>
                              <ListItem
                                dataTestId={`${dataTestId}__list-item-${listItem?.id}`}
                                description={listItem?.description}
                                disabled={listItem?.disabled}
                                autoHeight={listItem?.autoHeight}
                                endingIconButtonDropDownProps={
                                  listItem?.endingIconButtonDropDownProps
                                }
                                endingIconButtonIconName={
                                  listItem?.endingIconButtonIconName
                                }
                                endingIconButtonRef={
                                  listItem?.endingIconButtonRef
                                }
                                label={listItem?.label}
                                leadingIconName={listItem?.leadingIconName}
                                onClick={listItem?.onClick}
                                onEndingIconButtonClick={
                                  listItem?.onEndingIconButtonClick
                                }
                              />
                            </DraggableWrapperClone>
                          )}
                        </>
                      );
                    }}
                  </Draggable>
                ))}
              </List>

              <DndPlaceholder>{provided.placeholder}</DndPlaceholder>
            </ScrollableList>
          )}
        </Droppable>
      )}
    </Wrapper>
  );
};

ListContainer.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  drag: PropTypes.object,
  headerEndingButtonIconName: PropTypes.string,
  headerEndingButtonTitle: PropTypes.string,
  headerOnEndingButtonClick: PropTypes.func,
  headerTitle: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.shape(ListItem.propTypes)),
  skeletonLength: PropTypes.number,
  loading: PropTypes.bool,
  scrollable: PropTypes.bool,
};

export default ListContainer;
