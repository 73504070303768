import * as THREE from 'three';
import {
  isGLBModel,
  isObject3DModel,
  getObjectDimensions,
} from '@utils/camera';
import { updateModel } from '@utils/model';
import { BOTTOM_LAYER_ELEMENTS_CONTAINER_ID } from '@routes/AuthenticatedRoutes';

const RENDERER_WIDTH = window.innerWidth;
const RENDERER_HEIGHT = window.innerHeight;

const cameraFov = 15;
const cameraAspect = window.innerWidth / window.innerHeight;

const getCameraPosition = async (object, camera) => {
  if (!object) return;

  const { center, size } = await getObjectDimensions(object);

  const fov = camera.fov * (Math.PI / 180);
  const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
  const dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
  const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
  const cameraZ = Math.max(Math.max(dx, dy), center.z);
  const distanceXY = Math.sqrt(cameraZ * cameraZ) / Math.sqrt(2);

  const cameraPosition = [
    center.x + distanceXY,
    center.y + distanceXY,
    cameraZ,
  ];

  return { cameraPosition, cameraTarget: center };
};

const createObject = async (file, fileDisplayData) => {
  if (!file || !fileDisplayData) return new THREE.Object3D();

  const model = updateModel(file, fileDisplayData, {});

  if (isObject3DModel(model)) {
    return model;
  }

  if (isGLBModel(model)) {
    const glbModel = await model
      .getLoadedModelPromise()
      .then((gltfObject) => gltfObject.scene);

    return glbModel;
  }

  return new THREE.Object3D();
};

const createCamera = (cameraPosition = [0, 0, 0]) => {
  const camera = new THREE.PerspectiveCamera(cameraFov, cameraAspect, 1, 10000);
  camera.position.set(...cameraPosition);
  camera.up.set(0, 0, 1);

  return camera;
};

const createRenderer = () => {
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    preserveDrawingBuffer: true,
  });
  renderer.setSize(RENDERER_WIDTH, RENDERER_HEIGHT);
  renderer.shadowMap.enabled = true;

  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;

  renderer.domElement.style.position = 'fixed';
  renderer.domElement.style.top = '-100vh';
  renderer.domElement.style.left = '-100vw';
  renderer.domElement.style.opacity = '0';
  renderer.domElement.style.visibility = 'hidden';

  return renderer;
};

const createSceneLight = (cameraPosition, cameraTarget) => {
  const lightGroup = new THREE.Group();

  const lightTarget = new THREE.Object3D();
  lightTarget.position.copy(cameraTarget);

  const d = 50;
  const light1 = new THREE.DirectionalLight(0xffffff, 1.3);
  light1.position.multiplyScalar(60);
  light1.castShadow = true;
  light1.shadow.mapSize.width = 2048;
  light1.shadow.mapSize.height = 2048;
  light1.shadow.camera.left = -d;
  light1.shadow.camera.right = d;
  light1.shadow.camera.top = d;
  light1.shadow.camera.bottom = -d;
  light1.shadow.camera.far = 5000;
  light1.shadow.bias = -0.0001;
  light1.position.set(...cameraPosition);
  light1.target.position.copy(cameraTarget);
  light1.target = lightTarget;
  lightGroup.add(light1);

  const light2 = new THREE.DirectionalLight(0xffffff, 1.5);
  light2.position.set(-1, 1, 1);
  lightGroup.add(light2);

  const light3 = new THREE.DirectionalLight(0xffffff, 0.6);
  light3.position.set(1, -1, 1);
  lightGroup.add(light3);

  const light4 = new THREE.DirectionalLight(0xffffff, 0.3);
  light4.position.set(1, 1, -1);
  lightGroup.add(light4);

  return lightGroup;
};

const takeFileScreenshot = async (
  file,
  fileDisplayData,
  rendererTargetElementContainerId = BOTTOM_LAYER_ELEMENTS_CONTAINER_ID,
) => {
  if (!file || !fileDisplayData) return;

  const rendererTargetContainer = document.getElementById(
    rendererTargetElementContainerId,
  );

  if (!rendererTargetContainer) return;

  // Create a scene
  const scene = new THREE.Scene();

  // Create an object
  const object = await createObject(file, fileDisplayData);

  // Create a camera
  const camera = createCamera();
  const { cameraPosition, cameraTarget } = await getCameraPosition(
    object,
    camera,
  );

  camera.position.set(...cameraPosition);
  camera.lookAt(cameraTarget);

  // Create a light
  const sceneLight = createSceneLight(cameraPosition, cameraTarget);

  scene.add(sceneLight);
  scene.add(object);

  // Create a renderer
  const renderer = createRenderer();

  rendererTargetContainer.appendChild(renderer.domElement);

  renderer.render(scene, camera);

  return new Promise((resolve) => {
    renderer.domElement.toBlob((blob) => {
      rendererTargetContainer.removeChild(renderer.domElement);

      // Remove this code when the feature is implemented
      // const fileURL = URL.createObjectURL(blob);
      // const tab = window.open();
      // tab.location.href = fileURL;
      // End of Remove this code when the feature is implemented

      resolve(blob);
    });
  });
};

export default takeFileScreenshot;
