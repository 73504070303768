import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, SideBar, Content } from './LoginLayout.styled';

const BACKGROUND_IMAGE_VARIANTS = [
  'login-variant-1.png',
  'login-variant-2.png',
  'login-variant-3.png',
  'login-variant-4.png',
  'login-variant-5.png',
  'login-variant-6.png',
  'login-variant-7.png',
  'login-variant-8.png',
  'login-variant-9.png',
  'login-variant-10.png',
];
const randomIndex = Math.floor(
  Math.random() * BACKGROUND_IMAGE_VARIANTS.length,
);
const randomImageUrl = `/img/login/${BACKGROUND_IMAGE_VARIANTS[randomIndex]}`;

const LoginLayout = ({ children, contentBackgroundImage }) => {
  return (
    <Wrapper>
      <SideBar>{children}</SideBar>

      <Content backgroundImage={contentBackgroundImage || randomImageUrl} />
    </Wrapper>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
  contentBackgroundImage: PropTypes.node,
};

export default LoginLayout;
