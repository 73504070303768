import React from 'react';
import PropTypes from 'prop-types';
import Text, {
  TEXT_VARIANT_TITLE_LARGE,
  TEXT_VARIANT_BODY_LARGE,
  TEXT_VARIANT_TITLE_SMALL,
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import Button, {
  BUTTON_VARIANT_OUTLINE,
  BUTTON_VARIANT_PRIMARY,
} from '@app/components/1-atoms/Button';
import {
  Wrapper,
  Icon,
  Actions,
  LinkButton,
  RedirectLinkButton,
} from './EmptyStateBox.styled';

export const EMPTY_STATE_BOX_VARIANT_DEFAULT = 'default';
export const EMPTY_STATE_BOX_VARIANT_COMPACT = 'compact';

const EmptyStateBox = ({
  className,
  dataTestId = 'empty-state-box',
  description,
  iconName,
  linkButtonDisabled,
  linkButtonLabel,
  linkButtonPath,
  openLinkInNewTab,
  onLinkButtonClick,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  primaryButtonIconName,
  primaryButtonLabel,
  primaryButtonLoading,
  secondaryButtonDisabled,
  secondaryButtonIconName,
  secondaryButtonLabel,
  title,
  variant = EMPTY_STATE_BOX_VARIANT_DEFAULT,
}) => {
  const isCompactVariant = variant === EMPTY_STATE_BOX_VARIANT_COMPACT;

  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      isCompactVariant={isCompactVariant}
    >
      {iconName && <Icon name={iconName} isCompactVariant={isCompactVariant} />}

      {title && (
        <Text
          dataTestId={`${dataTestId}__title`}
          variant={
            isCompactVariant
              ? TEXT_VARIANT_TITLE_SMALL
              : TEXT_VARIANT_TITLE_LARGE
          }
          color="onSurface"
        >
          {title}
        </Text>
      )}

      {description && (
        <Text
          dataTestId={`${dataTestId}__description`}
          variant={
            isCompactVariant
              ? TEXT_VARIANT_BODY_MEDIUM
              : TEXT_VARIANT_BODY_LARGE
          }
          color="onSurfaceVariant"
        >
          {description}
        </Text>
      )}

      {isCompactVariant && linkButtonLabel && (
        <Actions>
          {openLinkInNewTab ? (
            <RedirectLinkButton
              data-test-id={`${dataTestId}__link-button`}
              href={linkButtonPath}
              disabled={linkButtonDisabled}
              onClick={onLinkButtonClick}
            >
              {linkButtonLabel}
            </RedirectLinkButton>
          ) : (
            <LinkButton
              data-test-id={`${dataTestId}__link-button`}
              to={linkButtonPath || ''}
              as={linkButtonPath ? undefined : 'span'}
              disabled={linkButtonDisabled}
              onClick={onLinkButtonClick}
            >
              {linkButtonLabel}
            </LinkButton>
          )}
        </Actions>
      )}

      {!isCompactVariant && (primaryButtonLabel || secondaryButtonLabel) && (
        <Actions>
          {secondaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__secondary-button`}
              variant={BUTTON_VARIANT_OUTLINE}
              disabled={secondaryButtonDisabled}
              iconName={secondaryButtonIconName}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonLabel}
            </Button>
          )}

          {primaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__primary-button`}
              variant={BUTTON_VARIANT_PRIMARY}
              disabled={primaryButtonDisabled}
              loading={primaryButtonLoading}
              iconName={primaryButtonIconName}
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </Actions>
      )}
    </Wrapper>
  );
};

EmptyStateBox.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  description: PropTypes.string,
  iconName: PropTypes.string,
  linkButtonDisabled: PropTypes.bool,
  openLinkInNewTab: PropTypes.bool,
  linkButtonLabel: PropTypes.string,
  linkButtonPath: PropTypes.string,
  onLinkButtonClick: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonIconName: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonIconName: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    EMPTY_STATE_BOX_VARIANT_DEFAULT,
    EMPTY_STATE_BOX_VARIANT_COMPACT,
  ]),
};

export default EmptyStateBox;
