import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn};
  height: 100%;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level4};
  `}
`;
