import React, { useState, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import SettingSwitch from '@app/components/2-molecules/SettingSwitch';
import { Frame, MessageContainer } from './DownloadStreamToolpathDialog.styled';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
} from '@app/components/2-molecules/MessageBox';

const MODAL_ID = ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH;

const DownloadStreamToolpathDialog = () => {
  const intl = useIntl();
  const { hideDialog, getDialogData } = useDialog();
  const [runSafetyChecks, setRunSafetyChecks] = useState(true);
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { isStream, onConfirm } = dialogData;

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onSubmit = useCallback(() => {
    onConfirm?.(runSafetyChecks);
    handleClose();
  }, [onConfirm, handleClose, runSafetyChecks]);

  return (
    <Dialog
      dataTestId="download-stream-toolpath-dialog"
      dialogId={MODAL_ID}
      title={
        isStream
          ? intl.formatMessage({
              id: 'downloadstreamdialog.stream.title',
              defaultMessage: 'Stream toolpath',
            })
          : intl.formatMessage({
              id: 'downloadstreamdialog.download.title',
              defaultMessage: 'Download toolpath',
            })
      }
      secondaryButtonLabel={intl.formatMessage({
        id: 'general.cancel',
        defaultMessage: 'Cancel',
      })}
      confirmButtonText={intl.formatMessage({
        id: 'general.confirm',
        defaultMessage: 'Confirm',
      })}
      onSubmit={onSubmit}
      onCancel={handleClose}
    >
      <Frame>
        <SettingSwitch
          dataTestId={`download-stream-toolpath-dialog__run-safety-check`}
          label={intl.formatMessage({
            id: 'downloadstreamdialog.safetycheck.label',
            defaultMessage: 'Safety check',
          })}
          supportingText={intl.formatMessage({
            id: 'downloadstreamdialog.safetycheck.supporting',
            defaultMessage:
              'Perform a full Safety Check before proceeding.\nThe procedure may take up to 20 minutes.',
          })}
          switchProps={{
            enabled: runSafetyChecks,
            onChange: () => {
              setRunSafetyChecks(!runSafetyChecks);
            },
          }}
        />
        {!runSafetyChecks && (
          <MessageContainer>
            <MessageBox
              variant={MESSAGE_BOX_VARIANT_ERROR}
              leadingIconName="error_0"
            >
              <FormattedMessage
                id="downloadstreamdialog.safetycheck.warning"
                defaultMessage="For safe operation and to avoid collisions, it is recommended to perform a Safety Check before starting any print."
              />
            </MessageBox>
          </MessageContainer>
        )}
      </Frame>
    </Dialog>
  );
};

export default DownloadStreamToolpathDialog;
