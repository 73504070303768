export default () => {
  const calculateOperatorHiddenInputNames = (
    operator = {},
    operatorInputs = [],
    visibilityConditions = {},
    watchedFormValues = {},
    files = [],
  ) => {
    const operatorConditions = visibilityConditions?.[operator.name];

    const formValues = watchedFormValues;

    const inputNameConditions = operatorInputs.reduce((acc, input) => {
      const inputName = input.name;
      const inputValue = formValues?.[input.id] || input.value;

      return {
        ...acc,
        [inputName]: `${inputValue}`,
      };
    }, {});

    const parseCondition = (condition = '') => {
      const parsedCondition = condition.split(':');
      const conditionInputName = parsedCondition?.[0];
      let conditionValue = parsedCondition?.[1];
      let compareValueExtension = false;

      if (conditionValue?.includes('*.')) {
        compareValueExtension = true;
        conditionValue = conditionValue.replace('*.', '');
      }

      return { conditionInputName, conditionValue, compareValueExtension };
    };

    const matchCondition = (condition) => {
      const { conditionInputName, conditionValue, compareValueExtension } =
        parseCondition(condition);
      const fieldValue = inputNameConditions?.[conditionInputName];

      if (compareValueExtension) {
        const file = files.find((file) => file.id === fieldValue);

        return file?.filetype === conditionValue.toLowerCase();
      }

      return (
        typeof conditionValue !== 'undefined' && fieldValue === conditionValue
      );
    };

    const checkSomeCondition = (condition) => {
      const parsedConditions = condition.split('|').filter((c) => !!c);

      const conditionsMet = parsedConditions.some(matchCondition);

      return conditionsMet;
    };

    const checkMultiCondition = (condition) => {
      const multiCondition = condition.split('&').filter((c) => !!c);

      const conditionsMet = multiCondition.every((condition) => {
        if (condition.includes('|')) {
          return checkSomeCondition(condition);
        }

        return matchCondition(condition);
      });

      return conditionsMet;
    };

    const hiddenInputNames = Object.keys(operatorConditions).reduce(
      (acc, inputName) => {
        const conditions = operatorConditions?.[inputName] || [];

        const isVisible = conditions.every((condition) => {
          const isMultiCondition = condition.includes('&');

          if (isMultiCondition) {
            const parsedMultiCondition = condition
              .split('|')
              .filter((c) => !!c);

            return parsedMultiCondition.some(checkMultiCondition);
          }

          return checkSomeCondition(condition);
        });

        if (!isVisible) {
          return [...acc, inputName];
        }

        return acc;
      },
      [],
    );

    return hiddenInputNames;
  };

  self.onmessage = (e) => {
    const data = e.data;
    const { operators, visibilityConditions, watchedFormValues, files } = data;

    const workflowNextHiddenInputNames = operators?.reduce(
      (acc, operator) => ({
        ...acc,
        [operator?.id]: calculateOperatorHiddenInputNames(
          operator,
          operator?.values,
          visibilityConditions,
          watchedFormValues,
          files,
        ),
      }),
      {},
    );

    self.postMessage({ result: workflowNextHiddenInputNames });
  };
};
