import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const history = useHistory();
  const [modals, setModals] = useState([]);
  const [modalIDSToHideOnRouteChange, setModalIDSToHideOnRouteChange] =
    useState([]);

  const addModalIDToHideOnRouteChange = useCallback((id) => {
    setModalIDSToHideOnRouteChange((prev) => [...prev, id]);
  }, []);

  const removeModalIDToHideOnRouteChange = useCallback((id) => {
    setModalIDSToHideOnRouteChange((prev) =>
      prev.filter((modalID) => modalID !== id),
    );
  }, []);

  const addModal = useCallback(
    (modal) => {
      setModals((prevModals) => [...prevModals, modal]);
    },
    [setModals],
  );

  const removeModal = useCallback(
    (modalId) => {
      setModals((prevModals) =>
        prevModals.filter((modal) => modal.id !== modalId),
      );
    },
    [setModals],
  );

  const updateModalData = useCallback(
    (modalId = '', modalData = {}) => {
      if (!modalId) return;

      setModals((prevModals) =>
        prevModals.map((modal) => {
          if (modal.id === modalId) {
            return {
              ...modal,
              data: {
                ...(modal.data || {}),
                ...(modalData || {}),
              },
            };
          }

          return modal;
        }),
      );
    },
    [setModals],
  );

  const handleRouteChange = useCallback(() => {
    modalIDSToHideOnRouteChange.forEach((id) => {
      removeModal(id);
      removeModalIDToHideOnRouteChange(id);
    });
  }, [
    modalIDSToHideOnRouteChange,
    removeModal,
    removeModalIDToHideOnRouteChange,
  ]);

  useEffect(() => {
    const unlisten = history.listen(handleRouteChange);

    return () => {
      unlisten();
    };
  }, [history, handleRouteChange]);

  const value = useMemo(
    () => ({
      modals,
      addModal,
      removeModal,
      updateModalData,
      addModalIDToHideOnRouteChange,
    }),
    [
      modals,
      addModal,
      removeModal,
      updateModalData,
      addModalIDToHideOnRouteChange,
    ],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.any,
};

export default ModalContext;
