export const addToSessionStorage = (name = 'aisync', data = {}) => {
  const stringifiedData = JSON.stringify(data);

  sessionStorage.setItem(name, stringifiedData);
};

export const removeFromSessionStorage = (name = 'aisync') => {
  sessionStorage.removeItem(name);
};

export const getFromSessionStorage = (name = 'aisync') => {
  const data = sessionStorage.getItem(name);

  if (!data) return;

  const parsedData = JSON.parse(data);

  return parsedData;
};
