import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { TextRow } from './Text.styled';

export const Text = ({ descriptionKey, defaultMessage, fontSize, color }) => (
  <TextRow fontSize={fontSize} color={color}>
    <FormattedMessage
      id={descriptionKey}
      defaultMessage={defaultMessage || descriptionKey}
    />
  </TextRow>
);

Text.propTypes = {
  descriptionKey: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
};
