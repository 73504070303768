import { CURRENT_USER_KEY_LOCAL_STORAGE } from '../constants/utilityConstants';

export const getSettingValue = (setting, printer, modifications) =>
  modifications?.settings[setting.settingName || setting.type] ??
  printer[setting.settingName] ??
  setting.value;

export const getShouldShowSetting = (
  setting,
  selectedPrinter,
  modifications,
  defaultPrinterSettings,
) => {
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_KEY_LOCAL_STORAGE));

  if (
    setting.authorizedRoles?.length > 0 &&
    !setting.authorizedRoles?.includes(user?.role)
  )
    return false;

  if (!setting.parentValueRestrictions) return true;
  const parentSetting = defaultPrinterSettings.find(
    (defaultSetting) =>
      defaultSetting.children &&
      defaultSetting.children
        .map((child) => child.settingName)
        ?.includes(setting.settingName),
  );
  const parentSettingValue = getSettingValue(
    parentSetting,
    selectedPrinter,
    modifications,
  );
  return (
    !setting.parentValueRestrictions ||
    setting.parentValueRestrictions
      .map((restriction) => {
        if (restriction === 'TRUE' || restriction === 'FALSE') {
          return restriction.toLowerCase();
        }
        return restriction;
      })
      ?.includes(parentSettingValue.toString())
  );
};
