import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './ActionBar.styled';

const ActionBar = ({ dataTestId = 'action-bar', className, children }) => {
  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {children}
    </Wrapper>
  );
};

ActionBar.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ActionBar;
