import { Directories } from '../constants/directories';

const emptyObj = {};

export const getDesignMetadata =
  () =>
  ({ designs }) =>
    designs.designMetadata;

export const getDesignGeometryData =
  (directory) =>
  ({ designs, concepts }) => {
    const { designGeometryData = emptyObj } = (() => {
      switch (directory) {
        case Directories.files:
          return designs;
        case Directories.workflows:
          return concepts.ui;
        default:
          return emptyObj;
      }
    })();
    return designGeometryData;
  };
export const getDesignGeometryDataOfDesignId =
  (directory, designId) => (state) => {
    const designGeometryData = getDesignGeometryData(directory)(state);
    return designGeometryData[designId || ''] || '';
  };
export const getDesign =
  (directory, itemId) =>
  ({ concepts, designs }) => {
    switch (directory) {
      case Directories.files:
        return designs.designMetadata.find(({ id }) => id === itemId);
      case Directories.workflows: {
        const concept = concepts.data.concepts.find(({ id }) => id === itemId);

        const { selectedOperatorOutputId } = concepts.ui;
        if (!selectedOperatorOutputId) return null;

        const selectedOperatorOutput = (concept?.operators || [])
          .flatMap((operator) => operator.values)
          .find((value) => value.id === selectedOperatorOutputId);
        if (!selectedOperatorOutput) return null;

        return designs.designMetadata.find(
          ({ id }) => id === selectedOperatorOutput.value,
        );
      }
      default:
        return null;
    }
  };
export const getNewDesignName =
  () =>
  ({ designs }) =>
    designs.ui.newDesignName;
export const getIsUpdatingDesign =
  () =>
  ({ designs }) =>
    designs.ui.isUpdatingDesign;
export const getDesignIdToDelete =
  () =>
  ({ designs }) =>
    designs.ui.designIdToDelete;
export const getIsDeletingDesign =
  () =>
  ({ designs }) =>
    designs.ui.isDeletingDesign;
export const getIsPublishingDesign =
  () =>
  ({ designs }) =>
    designs.ui.isPublishingDesign;
export const getDesignFetchesInProgress =
  () =>
  ({ designs }) =>
    designs.fetchesInProgress;

export const getUploadProgress =
  () =>
  ({ designs }) =>
    designs?.ui?.uploadProgress;

export const getShowProgressBar =
  () =>
  ({ designs }) =>
    designs?.ui?.showProgressBar;

export const getLastFetchedDesignModelId = ({ designs }) =>
  designs.lastFetchedModelId;
