import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import withDropDownMenu from '@hoc/withDropDownMenu';
import { Wrapper, Label, Icon } from './ButtonDropDown.styled';

const ButtonDropDown = forwardRef(
  (
    {
      children,
      className,
      dataTestId = 'button-drop-down',
      dirty,
      disabled,
      endingIconName = 'arrow_drop_down_1',
      error,
      leadingElement,
      leadingIconName,
      minWidth,
      onClick,
      shrink = true,
      title,
    },
    ref,
  ) => {
    return (
      <Wrapper
        className={className}
        data-testid={dataTestId}
        dirty={dirty}
        disabled={disabled}
        error={error}
        minWidth={minWidth}
        onClick={disabled ? undefined : onClick}
        ref={ref}
        shrink={shrink}
        tabIndex={disabled ? -1 : 0}
        title={title}
        type="button"
      >
        {!leadingIconName && leadingElement && leadingElement}
        {leadingIconName && <Icon name={leadingIconName} />}

        {!isUndefined(children) && (
          <Label data-testid={`${dataTestId}__text`}>{children}</Label>
        )}

        {endingIconName && <Icon name={endingIconName} />}
      </Wrapper>
    );
  },
);

ButtonDropDown.displayName = 'ButtonDropDown';

ButtonDropDown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  endingIconName: PropTypes.string,
  error: PropTypes.bool,
  leadingElement: PropTypes.node,
  leadingIconName: PropTypes.string,
  minWidth: PropTypes.number,
  onClick: PropTypes.func,
  shrink: PropTypes.bool,
  title: PropTypes.string,
};

export default withDropDownMenu(ButtonDropDown);
