import PropTypes from 'prop-types';
import Field from '@components/1-atoms/Field';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import moment from 'moment/moment';
import {
  DayPickerWrapper,
  Wrapper,
} from '@components/1-atoms/DatePickerField/DatePickerField.styled';

const DatePickerField = ({
  dataTestId = 'date-picker-field',
  value,
  onChange,
  field, // formik property
  form, // formik property
  ...fieldProps
}) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const wrapperRef = useRef(null);

  const formValue = form?.values[field?.name];
  const dateFieldValue = formValue ? moment(formValue).toDate() : value;

  const handleClickOutside = useCallback((event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsPickerVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleDateSelect = useCallback(
    (date) => {
      if (date) {
        if (field?.name) {
          //set in formik
          onChange(field.name, moment(date).format('yyyy-MM-DD'));
        } else {
          onChange(moment(date).format('yyyy-MM-DD'));
        }
      }
      setIsPickerVisible(false);
    },
    [field, onChange],
  );

  const handleInputChange = useCallback(
    (event) => {
      const inputValue = event.target.value;
      // Allow only numeric characters
      const numericValue = inputValue.replace(/[^\d-]/g, '');
      onChange(field.name, numericValue);
    },
    [field, onChange],
  );

  const handleFieldClick = useCallback(() => {
    setIsPickerVisible(!isPickerVisible);
  }, [setIsPickerVisible, isPickerVisible]);

  return (
    <Wrapper ref={wrapperRef}>
      <Field
        dataTestId={`${dataTestId}__field`}
        endingIconName={'calendar_month_0'}
        form={form}
        field={
          field
            ? {
                ...field,
                onChange: handleInputChange,
              }
            : undefined
        }
        value={value}
        onChange={onChange}
        onOverlayClick={handleFieldClick}
        {...fieldProps}
      />
      {isPickerVisible && (
        <DayPickerWrapper>
          <DayPicker
            mode="single"
            selected={dateFieldValue}
            onSelect={handleDateSelect}
          />
        </DayPickerWrapper>
      )}
    </Wrapper>
  );
};

DatePickerField.propTypes = {
  dataTestId: PropTypes.string,
  form: PropTypes.object, // formik property
  field: PropTypes.object, // formik property
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatePickerField;
