import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  TextAreaStyled,
} from './SettingTextArea.styled.js';
import { SupportingText } from '@components/1-atoms/Field/Field.styled';
import TextArea from '@components/1-atoms/TextArea';

const SettingTextArea = ({
  fieldProps = {},
  className,
  dataTestId = 'setting-text-area',
  label,
  withIdentation,
  supportingText,
  form, // formik property
  field, // formik property
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <Text
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </Text>
        {supportingText && <SupportingText>{supportingText}</SupportingText>}
      </LeftColumn>

      <RightColumn>
        <TextAreaStyled
          field={field}
          form={form}
          dataTestId={`${dataTestId}__text-area-field`}
          rows={5}
          {...fieldProps}
        />
      </RightColumn>
    </Wrapper>
  );
};

SettingTextArea.propTypes = {
  fieldProps: PropTypes.shape(TextArea.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  withIdentation: PropTypes.bool,
  supportingText: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
};

export default SettingTextArea;
