import { extruderSettingsTypes } from './printers/extruderSettingsTypes';
import { printingBedSettingsTypes } from './printers/printingBedSettingsType';
import { plinthSettingsTypes } from './printers/plinthSettingsType';
import { UserRoles } from './userRoles';
import { ValueTypes } from './valueTypes';
import { PrinterCategories } from './printerCategories';
import { postProcessorSettingsDefinitions } from '@constants/printers/postProcessorSettingsDefinitions';
import { printerConstants as constants } from '@constants/printers/printerConstants';
// TODO: get default values from ai-sync-core DefaultSetting
export const defaultPrinterSettings = [
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Name',
    parentValueRestrictions: null,
    settingName: 'name',
    value: 'printer',
    valueType: ValueTypes.STRING_WIDE,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Serial code',
    parentValueRestrictions: null,
    settingName: 'serialCode',
    placeholder: 'XXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
    value: '',
    valueType: ValueTypes.STRING_WIDE,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOLPATH_SETTINGS,
    children: [],
    description: '',
    displayName: 'Dynamic Extrusion',
    parentValueRestrictions: null,
    settingName: 'extrusionControlDynamic',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: false,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOLPATH_SETTINGS,
    children: [],
    description: '',
    displayName: 'Synchronous Extrusion',
    parentValueRestrictions: null,
    settingName: 'extrusionControlSynchronous',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: false,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOLPATH_SETTINGS,
    children: [],
    description: '',
    displayName: 'Scan Movement Settings',
    parentValueRestrictions: null,
    settingName: 'scanSettingsListVisible',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: true,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOLPATH_SETTINGS,
    children: [],
    description: '',
    displayName: 'Print Cooling Settings',
    parentValueRestrictions: null,
    settingName: 'printCoolingSettingsListVisible',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: true,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: Object.keys(extruderSettingsTypes),
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Extruder Settings Type',
    parentValueRestrictions: null,
    settingName: 'extruderSettingsType',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 'Default',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: Object.keys(printingBedSettingsTypes),
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Printing Bed Settings Type',
    parentValueRestrictions: null,
    settingName: 'printingBedSettingsType',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 'Default',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: Object.keys(plinthSettingsTypes),
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Plinth Settings Type',
    parentValueRestrictions: null,
    settingName: 'plinthSettingsType',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 'Default',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Program Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: 'programType',
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: 'GCODE',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: Object.values(postProcessorSettingsDefinitions),
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Post Processor',
    parentValueRestrictions: null,
    settingName: constants.postProcessor,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: postProcessorSettingsDefinitions.DEFAULT.value,
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Max speed multiplier',
    parentValueRestrictions: null,
    settingName: 'maxSpeedMultiplier',
    value: '50.0',
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: 'TODO',
    displayName: 'Force setting updates during print',
    parentValueRestrictions: null,
    settingName: 'forceUpdateDuringPrint',
    value: false,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: 'TODO',
    displayName: 'Update Settings On Send Print',
    parentValueRestrictions: null,
    settingName: 'updateSettingsOnSendPrint',
    value: true,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: null,
    category: 'PRINTER_GENERAL',
    children: [],
    description: 'TODO',
    displayName: 'Gcode E-Command Relative',
    parentValueRestrictions: null,
    settingName: 'gcodeEIsRelative',
    value: false,
    valueType: ValueTypes.BOOLEAN,
  },
  {
    allowedValues: ['ROBOT', 'PRINT_BED', 'PRINT_BED_CENTRE'],
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: '',
    displayName: 'Toolpath Reference Frame',
    parentValueRestrictions: null,
    settingName: 'toolpathReferenceFrame',
    value: '',
    valueType: ValueTypes.SELECTION,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Default Base Id',
    parentValueRestrictions: null,
    settingName: 'defaultBaseId',
    value: 0,
    valueType: ValueTypes.INTEGER,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base Z-Offset (mm)',
    parentValueRestrictions: null,
    settingName: 'baseZOffset',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 1 (x)',
    parentValueRestrictions: null,
    settingName: 'baseRef1X',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 1 (y)',
    parentValueRestrictions: null,
    settingName: 'baseRef1Y',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 1 (z)',
    parentValueRestrictions: null,
    settingName: 'baseRef1Z',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 2 (x)',
    parentValueRestrictions: null,
    settingName: 'baseRef2X',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 2 (y)',
    parentValueRestrictions: null,
    settingName: 'baseRef2Y',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 2 (z)',
    parentValueRestrictions: null,
    settingName: 'baseRef2Z',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 3 (x)',
    parentValueRestrictions: null,
    settingName: 'baseRef3X',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 3 (y)',
    parentValueRestrictions: null,
    settingName: 'baseRef3Y',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Base reference 3 (z)',
    parentValueRestrictions: null,
    settingName: 'baseRef3Z',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Rotation Correction (Rx)',
    parentValueRestrictions: null,
    settingName: 'rotationCorrectionRx',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Rotation Correction (Ry)',
    parentValueRestrictions: null,
    settingName: 'rotationCorrectionRy',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_BASE,
    children: [],
    description: 'TODO',
    displayName: 'Rotation Correction (Rz)',
    parentValueRestrictions: null,
    settingName: 'rotationCorrectionRz',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool Mounting Angle (deg)',
    parentValueRestrictions: null,
    settingName: 'toolMountingAngle',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool position (x)',
    parentValueRestrictions: null,
    settingName: 'toolX',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool position (y)',
    parentValueRestrictions: null,
    settingName: 'toolY',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool position (z)',
    parentValueRestrictions: null,
    settingName: 'toolZ',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool orientation (a)',
    parentValueRestrictions: null,
    settingName: 'toolA',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool orientation (b)',
    parentValueRestrictions: null,
    settingName: 'toolB',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Tool orientation (c)',
    parentValueRestrictions: null,
    settingName: 'toolC',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'Default Tool Id',
    parentValueRestrictions: null,
    settingName: 'defaultToolId',
    value: 0,
    valueType: ValueTypes.INTEGER,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'TCP Correction (x)',
    parentValueRestrictions: null,
    settingName: 'tcpCorrectionX',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'TCP Correction (y)',
    parentValueRestrictions: null,
    settingName: 'tcpCorrectionY',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_TOOL,
    children: [],
    description: 'TODO',
    displayName: 'TCP Correction (z)',
    parentValueRestrictions: null,
    settingName: 'tcpCorrectionZ',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace origin X',
    parentValueRestrictions: null,
    settingName: 'workspaceOriginX',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace origin Y',
    parentValueRestrictions: null,
    settingName: 'workspaceOriginY',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace origin Z',
    parentValueRestrictions: null,
    settingName: 'workspaceOriginZ',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace X1',
    parentValueRestrictions: null,
    settingName: 'workspaceX1',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace X2',
    parentValueRestrictions: null,
    settingName: 'workspaceX2',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace Y1',
    parentValueRestrictions: null,
    settingName: 'workspaceY1',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace Y2',
    parentValueRestrictions: null,
    settingName: 'workspaceY2',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace Z1',
    parentValueRestrictions: null,
    settingName: 'workspaceZ1',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_WORKSPACE,
    children: [],
    description: 'TODO',
    displayName: 'Workspace Z2',
    parentValueRestrictions: null,
    settingName: 'workspaceZ2',
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
];

export const getDefaultPrinterSettingsByCategory = (
  programTypeDefinitions,
  currentUser,
) => {
  return getDefaultPrinterSettings(programTypeDefinitions, currentUser).reduce(
    (acc, setting) => ({
      ...acc,
      [setting.category]: [...(acc[setting.category] || []), setting],
    }),
    {},
  );
};

export const getDefaultPrinterSettings = (
  programTypeDefinitions,
  currentUser,
) => {
  const updateSettings = (definitions) =>
    definitions?.map(({ displayName }) => displayName);

  const updatedProgramTypeSettings = updateSettings(
    programTypeDefinitions.filter(
      ({ visibleToAll, visibleToOrganization }) =>
        (currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );

  return defaultPrinterSettings.map((setting) => {
    if (setting.settingName === constants.programType) {
      return {
        ...setting,
        allowedValues: updatedProgramTypeSettings,
      };
    }
    return setting;
  });
};
