import { Printer } from '../Printer';
import { printerConstants as constants } from '../../constants/printers/printerConstants';
import PrinterComponent from '../PrinterComponent';

/**
 * THREE.Group object containing all settings and models relevant to visualising
 * the enclosure of the printer.
 */
class Enclosure extends PrinterComponent {
  constructor(
    printerSettings,
    machineDefaults,
    enclosureType,
    enclosureDefinitions,
  ) {
    super(printerSettings, machineDefaults, constants.enclosure);

    this.enclosureType = enclosureType;
    this.enclosureSettings = enclosureDefinitions?.find(
      (item) => item.displayName == this.enclosureType,
    );
  }

  /**
   * Loads the models asynchronously and then adds them to the models group
   */
  initializeModels() {
    return new Promise((resolve) => {
      if (this.enclosureSettings.empty || this.enclosureSettings.fileKey == '')
        return resolve();

      const url = `/models/Enclosure_${this.enclosureSettings.fileKey}.glb`;
      Printer.getModel(url).then((enclosureModel) => {
        this.add(enclosureModel);
        resolve();
      });
    });
  }
}

export default Enclosure;
