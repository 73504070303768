import devConfig from '../../config/dev';
import testConfig from '../../config/test';
import prodConfig from '../../config/production';
import onpremiseConfig from '../../config/onpremise';

const getConfig = () => {
  if (TEST_ENV) {
    return testConfig;
  }

  if (ONPREMISE_ENV) {
    return onpremiseConfig;
  }

  if (PROD_ENV) {
    return prodConfig;
  }

  return devConfig;
};

const initialState = getConfig();

const modalReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
};

export default modalReducer;
