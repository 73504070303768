import {
  ContentWrapper,
  Frame,
  HorizontalLineFrame,
  Wrapper,
} from '@pages/Account/Account.styled';
import PageHeader, {
  PAGE_HEADER_VARIANT_SMALL,
} from '@components/2-molecules/PageHeader';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SettingTextField from '@components/2-molecules/SettingTextField';
import SettingActionRow from '@components/2-molecules/SettingActionRow';
import SettingInfo from '@components/2-molecules/SettingInfo';
import { logOut } from '@actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUser,
  getUserAccountPreference,
} from '@selectors/loginSelectors';
import { whenEnterButtonPressed } from '@utils/interactionEvents';
import SettingLink from '@components/2-molecules/SettingLink';
import HorizontalDivider from '@components/1-atoms/HorizontalDivider';
import SettingSwitch from '@components/2-molecules/SettingSwitch';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { themeTypes } from '@constants/accountSettings';
import { getFormattedOption } from '@components/Printers/SettingsUtils';
import useApplication from '@hooks/application/useApplication';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import {
  deactivateMFA,
  generateMFAConnectorKey,
} from '@actions/settingsActions';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import useSnackbar from '@hooks/useSnackbar';
import useAuthQueries from '@app/hooks/auth/useAuthQueries';
import useSubdomain from '@app/hooks/useSubdomain';
import { SSO_LOGIN_TYPE } from '@app/constants/auth';

const Account = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const currentUser = useSelector(getCurrentUser());
  const { cleanQueryClient } = useApplication();
  const { updateUserMutation } = useOrganizationMutations();
  const subdomain = useSubdomain();
  const { getAuthenticationConfigQuery } = useAuthQueries({ subdomain });
  const authenticationConfig = getAuthenticationConfigQuery?.data;
  const hideSecuritySettings =
    authenticationConfig?.loginType === SSO_LOGIN_TYPE;
  const userAccountTheme = useSelector(
    getUserAccountPreference('USER_ACCOUNT_THEME'),
  );
  const themeType = userAccountTheme
    ? themeTypes[userAccountTheme.toUpperCase()]
    : themeTypes.SYSTEM;
  const [displayName, setDisplayName] = useState(currentUser?.name);
  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const completeNameError = displayName.length < 3 || displayName.length > 70;

  const { updateUserPreferenceMutation } = useOrganizationMutations();

  const changeThemeType = useCallback(
    async (theme) => {
      try {
        await updateUserPreferenceMutation.mutateAsync({
          userPreference: {
            preferenceKey: 'USER_ACCOUNT_THEME',
            preferenceValue: theme.value,
          },
        });

        showSnackbar({
          text: intl.formatMessage({
            id: 'settings.preferences.account.theme',
            defaultMessage: 'Theme was successfully updated',
          }),
        });
      } catch (_) {
        //error
      }
    },
    [intl, showSnackbar, updateUserPreferenceMutation],
  );

  const getFormattedOptions = useMemo(
    () => (definitions, changeThemeType) =>
      Object.values(definitions).map((option) =>
        getFormattedOption(option, changeThemeType),
      ),
    [],
  );
  const options = getFormattedOptions(themeTypes, changeThemeType);

  const onLogoutClick = useCallback(() => {
    dispatch(
      logOut({
        onSuccessCallback: cleanQueryClient,
      }),
    );
  }, [dispatch, cleanQueryClient]);

  const onResetPasswordClick = useCallback(() => {
    showDialog(ModalDataTypes.RESET_PASSWORD);
  }, [showDialog]);

  const handleFieldChange = useCallback((value) => {
    setDisplayName(value);
  }, []);

  const handleUpdateDisplayName = useCallback(async () => {
    if (!completeNameError) {
      try {
        await updateUserMutation.mutateAsync({
          username: currentUser.username,
          id: currentUser.id,
          user: {
            editedUsername: currentUser.username,
            displayName: displayName,
            role: currentUser.role,
            organizationId: currentUser.organizationId,
            expiryDate: currentUser.expiryDate,
          },
          currentUser: {
            ...currentUser,
            name: displayName,
          },
        });
        showSnackbar({
          text: intl.formatMessage({
            id: 'settings.account.profile.full.name.snackbar.label',
            defaultMessage: 'Name was successfully updated',
          }),
        });
      } catch (_) {
        //error
      }

      return new Promise(() => {});
    }
  }, [
    showSnackbar,
    intl,
    currentUser,
    updateUserMutation,
    completeNameError,
    displayName,
  ]);

  const handleEnterButton = useCallback(() => {
    inputRef.current.blur();
  }, []);

  const handleOnSuccessMFACallback = useCallback(() => {
    showDialog(ModalDataTypes.CONNECT_MFA);
  }, [showDialog]);

  const handleMFASettingUpdate = useCallback(() => {
    if (currentUser?.mfaEnabled) {
      dispatch(deactivateMFA());

      return;
    }

    dispatch(generateMFAConnectorKey(handleOnSuccessMFACallback));
  }, [handleOnSuccessMFACallback, dispatch, currentUser?.mfaEnabled]);

  return (
    <Wrapper>
      <ContentWrapper>
        <PageHeader
          dataTestId={`settings-page__profile-header`}
          variant={PAGE_HEADER_VARIANT_SMALL}
          title={intl.formatMessage({
            id: 'settings.account.profile.title',
            defaultMessage: 'Profile',
          })}
        />
        <Frame>
          <SettingTextField
            dataTestId={`settings-page__profile__complete-name`}
            label={intl.formatMessage({
              id: 'settings.account.profile.full.name.label',
              defaultMessage: 'Full name',
            })}
            field1={{
              ref: inputRef,
              error: completeNameError,
              supportingText:
                completeNameError &&
                intl.formatMessage({
                  id: 'settings.account.profile.complete.name.error',
                  defaultMessage: 'Between 3 and 70 characters',
                }),
              value: displayName,
              onBlur: handleUpdateDisplayName,
              onKeyPress: whenEnterButtonPressed(handleEnterButton),
              onChange: (e) => handleFieldChange(e.target.value),
            }}
          />
          <SettingInfo
            dataTestId={`settings-page__profile__email-address`}
            infoIconName={''}
            label={intl.formatMessage({
              id: 'settings.account.profile.email.address.label',
              defaultMessage: 'Email address',
            })}
            infoText={currentUser?.username}
          />
          <SettingActionRow
            dataTestId={`settings-page__profile__logout`}
            button={{
              onClick: onLogoutClick,
              children: (
                <FormattedMessage
                  id="navigation.link.dropdown.logout"
                  defaultMessage="Log out"
                />
              ),
            }}
          />
        </Frame>
        {!hideSecuritySettings && (
          <>
            <PageHeader
              dataTestId={`settings-page__security-header`}
              variant={PAGE_HEADER_VARIANT_SMALL}
              title={intl.formatMessage({
                id: 'settings.account.security.title',
                defaultMessage: 'Security',
              })}
            />
            <Frame>
              <SettingLink
                dataTestId={`settings-page__security__password`}
                label={intl.formatMessage({
                  id: 'settings.account.security.password.label',
                  defaultMessage: 'Password',
                })}
                button={{
                  onClick: onResetPasswordClick,
                  children: (
                    <FormattedMessage
                      id="settings.account.security.password.link.label"
                      defaultMessage="Reset password"
                    />
                  ),
                }}
              />
              <HorizontalLineFrame>
                <HorizontalDivider middleInset />
              </HorizontalLineFrame>
              <SettingSwitch
                dataTestId={`settings-page__security__mfa`}
                label={intl.formatMessage({
                  id: 'settings.mfa.info_title',
                  defaultMessage: 'Two-factor Authentication',
                })}
                supportingText={intl.formatMessage({
                  id: 'settings.mfa.info_description',
                  defaultMessage:
                    'Two-Factor Authentication (2FA) adds an extra layer of security by requiring a second form of verification, like a one-time code from your phone, along with your password, to access your accounts.',
                })}
                switchProps={{
                  disabled: currentUser?.mfaEnforced,
                  enabled: currentUser?.mfaEnabled,
                  onChange: handleMFASettingUpdate,
                }}
              />
            </Frame>
          </>
        )}
        <PageHeader
          dataTestId={`settings-page__appearance-header`}
          variant={PAGE_HEADER_VARIANT_SMALL}
          title={intl.formatMessage({
            id: 'settings.account.appearance.title',
            defaultMessage: 'Appearance',
          })}
        />
        <Frame>
          <SettingDropDown
            label={intl.formatMessage({
              id: 'settings.account.appearance.theme.label',
              defaultMessage: 'Theme',
            })}
            dropDownField={{
              dropDownMenuItems: options,
              value: themeType.label,
              leadingIconName: themeType.leadingIconName,
              fullWidthDropDownMenu: false,
            }}
          />
        </Frame>
      </ContentWrapper>
    </Wrapper>
  );
};

Account.propTypes = {};

export default Account;
