import * as THREE from 'three';
import { Printer } from '../Printer';
import LinearRail from './LinearRail';
import { getPlinthDefinitionResponse } from './PlinthUtils';
import { s3Directories } from '../../constants/printers/s3Directories';
import { printerConstants as constants } from '@constants/printers/printerConstants';

class LinearRailCustom extends LinearRail {
  constructor(printerSettings, plinthDefinitions) {
    super(
      printerSettings,
      LinearRailCustom.getMachineDefaults(),
      'CUSTOM',
      constants.ROBOT,
      plinthDefinitions,
    );

    const plinthDefinitionResponse = getPlinthDefinitionResponse(
      this.printerSettings,
    );
    this.originValue = plinthDefinitionResponse?.originValue;
    this.isNegative =
      plinthDefinitionResponse?.endValue <
      plinthDefinitionResponse?.originValue;
  }

  static getMachineDefaults() {
    return {
      offset: {
        x: 0,
        y: 0,
        z: 0,
      },
    };
  }

  getPlinthSettingsType() {
    return '';
  }

  initializeModels() {
    const plinthDefinitionResponse = getPlinthDefinitionResponse(
      this.printerSettings,
    );
    this.height = plinthDefinitionResponse.height;

    return new Promise((resolve) => {
      if (!plinthDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = plinthDefinitionResponse;

      const modelPromises = []; // Array to hold all the promises for the robot models

      const railUrl = modelUrls[s3Directories.plinthBase];
      const platformUrl = modelUrls[s3Directories.plinthRailPlatform];

      modelPromises.push(Printer.getModel(railUrl));
      modelPromises.push(Printer.getModel(platformUrl));

      Promise.all(modelPromises).then((plinthModels) => {
        const railModel = plinthModels[0];
        const platformModel = plinthModels[1];

        this.add(railModel);
        this.rail.add(platformModel);

        resolve();
      });
    });
  }

  getPlinthHeight() {
    const plinthDefinitionResponse = getPlinthDefinitionResponse(
      this.printerSettings,
    );

    return plinthDefinitionResponse.height;
  }
}

export default LinearRailCustom;
