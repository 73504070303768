import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import usePrinter from '@hooks/printers/usePrinter';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Paper from '@components/1-atoms/Paper';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import {
  DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
} from '@components/2-molecules/DropDownMenu';
import { Content, SelectorWrapper } from './WorkflowPrinterBar.styled';
import { materialSortFunction } from '@utils/material';
import { b, br } from '@utils/render';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetMaterialSettingsUpdated,
  selectMaterialSettingsUpdated,
  setIsSubmitted,
} from '@app/reducers/workflowSlice';

const WorkflowPrinterBar = () => {
  const intl = useIntl();
  const {
    getIsWorkflowEditable,
    getSelectedWorkflow,
    updateWorkflow,
    computeWorkflow,
    updateAndComputeWorkflow: updateAndComputeWorkflowAction,
    getIsWorkflowDeprecated,
    getIsPrinterUpdateAvailable,
  } = useWorkflow();
  const { calculateWorkflowHiddenInputs } = useOperator();

  const dispatch = useDispatch();
  const materialSettingsUpdated = useSelector(selectMaterialSettingsUpdated);
  const { getPrinters, getPrinterMaterials, getPrinterNozzles } = usePrinter();
  const { showDialog, updateDialogData } = useDialog();
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormContext();

  const workflow = getSelectedWorkflow();
  const isWorkflowDeprecated = getIsWorkflowDeprecated();
  const printers = getPrinters();
  const workflowId = workflow?.id;
  const isEmptyWorkflow = isEmpty(workflow?.operators);
  const workflowPrinterId = workflow?.printerId;
  const workflowMaterialId = workflow?.materialId;
  const workflowNozzleId = workflow?.nozzleId;
  const nozzles = getPrinterNozzles(workflowPrinterId);
  const materials = getPrinterMaterials(workflowPrinterId);
  const { updateWorkflowMaterialMutation, updateWorkflowNozzleMutation } =
    useWorkflowMutations();

  const isWorkflowDisabled = !getIsWorkflowEditable(workflow);
  const isPrinterUpdateAvailable = getIsPrinterUpdateAvailable(workflow);

  const computeToContinueDialogProps = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'workflow.printer_bar.dialog.compute_to_continue.title',
        defaultMessage: 'Compute to continue',
      }),
      subtitle: intl.formatMessage(
        {
          id: 'workflow.printer_bar.dialog.compute_to_continue.description',
          defaultMessage:
            'To apply the new changes to <b>{workflowName}</b>, you must first compute the complete workflow. <br></br><br></br> Would you like to compute all?',
        },
        {
          workflowName: workflow.name,
          b,
          br,
        },
      ),
      secondaryButtonLabel: intl.formatMessage({
        id: 'general.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryButtonLabel: intl.formatMessage({
        id: 'general.compute_all',
        defaultMessage: 'Compute all',
      }),
    }),
    [intl, workflow.name],
  );

  const overrideOperatorsDialogProps = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'workflow.printer_bar.dialog.override_or_keep_changes.title',
        defaultMessage: 'Override settings',
      }),
      subtitle: intl.formatMessage(
        {
          id: 'workflow.printer_bar.dialog.override_material_or_keep_changes.description',
          defaultMessage:
            'Do you want to update the existing settings with those from the new material preferences?',
        },
        {
          br,
        },
      ),
      secondaryButtonLabel: intl.formatMessage({
        id: 'general.keep_existing',
        defaultMessage: 'Keep existing',
      }),
      primaryButtonLabel: intl.formatMessage({
        id: 'general.override',
        defaultMessage: 'Override',
      }),
    }),
    [intl],
  );

  const showErrorMessage = useCallback(() => {
    updateDialogData(ModalDataTypes.PROMPT, {
      dataTestId: 'workflow-printer-error-dialog',
      title: intl.formatMessage({
        id: 'workflow.printer_bar.dialog.unable_to_proceed.title',
        defaultMessage: 'Unable to proceed',
      }),
      subtitle: intl.formatMessage({
        id: 'workflow.printer_bar.dialog.unable_to_proceed.description',
        defaultMessage:
          'There are invalid operators preventing the workflow from being computed. Please resolve the issue and try again.',
      }),
      closeOnPrimaryActionSuccess: true,
      secondaryButtonLabel: null,
      primaryButtonLabel: intl.formatMessage({
        id: 'general.ok',
        defaultMessage: 'OK',
      }),
    });
  }, [intl, updateDialogData]);

  const updateWorkflowWithoutCompute = useCallback(
    (injectProps) =>
      handleSubmit(async (formValues) => {
        await updateWorkflow(formValues, injectProps);
      }, showErrorMessage)(),
    [handleSubmit, updateWorkflow, showErrorMessage],
  );

  const updateAndComputeWorkflow = useCallback(
    (injectProps) => {
      handleSubmit(async (formValues) => {
        if (isEmptyWorkflow) {
          await updateWorkflow(formValues, injectProps);

          return;
        }

        await updateAndComputeWorkflowAction(formValues, injectProps);
      }, showErrorMessage)();
    },
    [
      isEmptyWorkflow,
      handleSubmit,
      updateWorkflow,
      updateAndComputeWorkflowAction,
      showErrorMessage,
    ],
  );

  const handlePrinterChange = useCallback(
    (printerId) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'workflow-printer-update-dialog',
        ...computeToContinueDialogProps,
        closeOnPrimaryActionSuccess: isValid,
        onPrimaryButtonClick: () => {
          const materials =
            getPrinterMaterials(printerId)?.sort(materialSortFunction);
          const nozzles = getPrinterNozzles(printerId);

          const materialId = materials?.[0]?.id || '';
          const nozzleId = nozzles?.[0]?.id || '';

          const materialIds = materials.map((material) => material.id);
          if (materialIds.includes(workflowMaterialId)) {
            updateAndComputeWorkflow({
              printerId,
              nozzleId,
              computeAll: true,
            });
          } else {
            updateWorkflowWithoutCompute({
              printerId,
              materialId,
              nozzleId,
              computeAll: true,
            });
          }
        },
      });
    },
    [
      workflowMaterialId,
      updateAndComputeWorkflow,
      showDialog,
      getPrinterMaterials,
      getPrinterNozzles,
      computeToContinueDialogProps,
      isValid,
      updateWorkflowWithoutCompute,
    ],
  );

  const handleMaterialChange = useCallback(
    (materialId) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'workflow-material-update-dialog',
        ...computeToContinueDialogProps,
        closeOnSecondaryActionSuccess: isValid,
        closeOnPrimaryActionSuccess: false,
        onPrimaryButtonClick: () => {
          updateDialogData(ModalDataTypes.PROMPT, {
            dataTestId: 'workflow-material-update-dialog',
            ...overrideOperatorsDialogProps,
            closeOnPrimaryActionSuccess: isValid,
            onPrimaryButtonClick: async () => {
              if (!isValid) {
                showErrorMessage();

                return;
              }

              await updateWorkflowMaterialMutation.mutateAsync({
                workflowId,
                printerId: workflowPrinterId,
                materialId,
              });
            },
            onSecondaryButtonClick: () => {
              if (!isValid) {
                showErrorMessage();

                return;
              }

              updateWorkflowWithoutCompute({
                materialId,
              });
            },
          });
        },
      });
    },
    [
      updateWorkflowWithoutCompute,
      showDialog,
      updateWorkflowMaterialMutation,
      workflowId,
      workflowPrinterId,
      updateDialogData,
      computeToContinueDialogProps,
      overrideOperatorsDialogProps,
      isValid,
      showErrorMessage,
    ],
  );

  const handleNozzleChange = useCallback(
    (nozzleId) => {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'workflow-nozzle-update-dialog',
        ...computeToContinueDialogProps,
        closeOnSecondaryActionSuccess: isValid,
        closeOnPrimaryActionSuccess: false,
        onPrimaryButtonClick: () => {
          updateDialogData(ModalDataTypes.PROMPT, {
            dataTestId: 'workflow-material-update-dialog',
            ...overrideOperatorsDialogProps,
            subtitle: intl.formatMessage(
              {
                id: 'workflow.printer_bar.dialog.override_nozzle_or_keep_changes.description',
                defaultMessage:
                  'You have changed some settings associated with the new nozzle selected. <br></br> Would you like to keep these changes or override them when switching nozzle?',
              },
              {
                br,
              },
            ),
            closeOnPrimaryActionSuccess: isValid,
            onPrimaryButtonClick: async () => {
              if (!isValid) {
                showErrorMessage();

                return;
              }

              await updateWorkflowWithoutCompute({ nozzleId });

              await updateWorkflowNozzleMutation.mutateAsync({
                workflowId,
                printerId: workflowPrinterId,
                nozzleId,
              });

              dispatch(setIsSubmitted(true));

              computeWorkflow(calculateWorkflowHiddenInputs());
            },
            onSecondaryButtonClick: () => {
              if (!isValid) {
                showErrorMessage();

                return;
              }

              updateAndComputeWorkflow({
                nozzleId,
              });
            },
          });
        },
      });
    },
    [
      intl,
      updateWorkflowWithoutCompute,
      updateAndComputeWorkflow,
      computeWorkflow,
      calculateWorkflowHiddenInputs,
      showDialog,
      updateWorkflowNozzleMutation,
      workflowId,
      workflowPrinterId,
      updateDialogData,
      computeToContinueDialogProps,
      overrideOperatorsDialogProps,
      isValid,
      showErrorMessage,
      dispatch,
    ],
  );

  const selectors = useMemo(() => {
    const hasMaterials = !isEmpty(materials);
    const hasNozzles = !isEmpty(nozzles);
    const selectorsList = [
      {
        id: 'workflow-selector-printerId',
        name: 'printerId',
        options: printers,
        dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
        iconName: 'precision_manufacturing_0',
        selectedId: workflowPrinterId,
        handleUpdate: handlePrinterChange,
      },
    ];

    if (hasMaterials) {
      selectorsList.push({
        id: 'workflow-selector-materialId',
        name: 'materialId',
        options: materials,
        dropDownMenuPosition: hasNozzles
          ? DROP_DOWN_MENU_POSITION_BOTTOM_CENTER
          : DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
        iconName: 'spool_0',
        selectedId: workflowMaterialId,
        handleUpdate: handleMaterialChange,
      });
    }

    if (hasNozzles) {
      selectorsList.push({
        id: 'workflow-selector-nozzleId',
        name: 'nozzleId',
        options: nozzles,
        dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
        iconName: 'extrusion_0',
        selectedId: workflowNozzleId,
        handleUpdate: handleNozzleChange,
      });
    }

    return selectorsList;
  }, [
    printers,
    materials,
    nozzles,
    workflowPrinterId,
    workflowMaterialId,
    workflowNozzleId,
    handlePrinterChange,
    handleMaterialChange,
    handleNozzleChange,
  ]);

  useEffect(() => {
    if (materialSettingsUpdated) {
      updateAndComputeWorkflow();
      dispatch(resetMaterialSettingsUpdated());
    }
  }, [materialSettingsUpdated, dispatch, updateAndComputeWorkflow]);

  return (
    <Paper dataTestId="workflow-printer-bar">
      <Content>
        {selectors.map(
          (
            {
              id,
              name,
              options,
              dropDownMenuPosition,
              iconName,
              selectedId,
              handleUpdate,
            },
            i,
          ) => {
            const labelKey = name === 'materialId' ? 'materialName' : 'name';

            const dropDownMenuItems = options?.map((option) => ({
              label: option?.[labelKey],
              selected: selectedId === option?.id,
              onClick: () => handleUpdate?.(option?.id),
            }));

            const selectedItem = dropDownMenuItems?.find(
              (item) => !!item.selected,
            );

            return (
              <React.Fragment key={id}>
                <SelectorWrapper>
                  <ButtonDropDown
                    dataTestId={`workflow-printer-bar__button-drop-down workflow-printer-bar__button-drop-down--${name}`}
                    disabled={
                      isWorkflowDisabled ||
                      isPrinterUpdateAvailable ||
                      isWorkflowDeprecated
                    }
                    title={selectedItem?.label}
                    leadingIconName={iconName}
                    dropDownMenuItems={dropDownMenuItems}
                    dropDownMenuPosition={dropDownMenuPosition}
                  >
                    {selectedItem?.label ||
                      intl.formatMessage({
                        id: 'workflow.printer_selectors.placeholder',
                        defaultMessage: 'Select',
                      })}
                  </ButtonDropDown>
                </SelectorWrapper>

                {i < selectors.length - 1 && (
                  <VerticalDivider middleInset inlineMiddleInset />
                )}
              </React.Fragment>
            );
          },
        )}
      </Content>
    </Paper>
  );
};

WorkflowPrinterBar.propTypes = {};

export default WorkflowPrinterBar;
