export const getTemplates = ({ templates }) => templates.templates;

export const getCompletedStages = ({ templates } = {}) =>
  templates?.createTemplate?.completedStages || [];

export const getCurrentStage = ({ templates } = {}) =>
  templates?.createTemplate?.currentStage;

export const getStageData =
  (stageName = '') =>
  ({ templates } = {}) =>
    (
      templates?.createTemplate?.completedStages?.find(
        ({ name }) => name === stageName,
      ) || {}
    )?.data || {};

export const getMultiStageData =
  (stageName = '') =>
  ({ templates } = {}) =>
    (
      templates?.createTemplate?.completedStages?.filter(
        ({ name }) => name === stageName,
      ) || []
    )
      ?.map(({ data }) => data)
      ?.flat();

export const getCreateTemplateCompleted = ({ templates } = {}) =>
  templates?.createTemplate?.completed;

export const getCreateTemplateError = ({ templates } = {}) =>
  templates?.createTemplate?.error;
