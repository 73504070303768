import * as actionTypes from '@constants/actionTypes';

const initialState = {
  currentStage: {},
  completedStages: [],
  completed: false,
  error: false,
  errorMessage: null,
  redirectingToWorkflow: false,
};

const quickStartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUICK_START_PROJECT_REQUEST: {
      return {
        ...state,
        completed: initialState.completed,
        error: initialState.error,
        errorMessage: initialState.errorMessage,
        redirectingToWorkflow: true,
      };
    }

    case actionTypes.QUICK_START_PROJECT_SUCCESS: {
      return {
        ...state,
        completed: true,
      };
    }

    case actionTypes.QUICK_START_PROJECT_FAILURE: {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        redirectingToWorkflow: false,
      };
    }

    case actionTypes.QUICK_START_SET_CURRENT_STAGE: {
      return {
        ...state,
        currentStage: action.payload?.stage,
      };
    }

    case actionTypes.SET_QUICK_START_COMPLETED_STAGE: {
      return {
        ...state,
        completedStages: [...state.completedStages, action.payload?.stage],
      };
    }

    case actionTypes.RESET_QUICK_START: {
      return {
        ...initialState,
        redirectingToWorkflow: state.redirectingToWorkflow,
      };
    }

    case actionTypes.QUICK_START_REDIRECTION_COMPLETED: {
      return {
        ...state,
        redirectingToWorkflow: false,
      };
    }

    default:
      return state;
  }
};

export default quickStartReducer;
