import React, { useCallback } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import { ROUTES } from '@constants/router';
import { Wrapper } from './Files.styled';
import FilePageContent from '@components/File/FilePageContent';
import useProjectFiles from '@hooks/files/useProjectFiles';

const Files = () => {
  const intl = useIntl();
  const history = useHistory();
  const { workspaceId: projectId } = useParams();
  const { projectQuery } = useProjectQueries({ projectId });
  const { fileListItems } = useProjectFiles({ projectId });

  const project = projectQuery.data;

  const handleGoBack = useCallback(() => {
    history.push(ROUTES.PROJECTS);
  }, [history]);

  const handleTabClick = useCallback(
    (tab) => {
      const isWorkflowTab = tab?.title === 'Workflows';

      if (!isWorkflowTab) return;

      history.push(
        generatePath(ROUTES.WORKFLOWS, {
          workspaceId: projectId,
        }),
      );
    },
    [history, projectId],
  );

  return (
    <Wrapper data-testid="files-page">
      <FilePageContent
        fileListItems={fileListItems}
        projectId={projectId}
        headerTitle={project?.name || ''}
        tabs={[
          {
            id: 'files-tab-1',
            title: intl.formatMessage({
              id: 'filespage.files_list.tab_title.workflows',
              defaultMessage: 'Workflows',
            }),
          },
          {
            id: 'files-tab-2',
            title: intl.formatMessage({
              id: 'filespage.files_list.tab_title.files',
              defaultMessage: 'Files',
            }),
            active: true,
          },
        ]}
        onTabClick={handleTabClick}
        headerLeadingIconButtonIconName="arrow_back_0"
        onHeaderLeadingIconButtonClick={handleGoBack}
      />
    </Wrapper>
  );
};

Files.propTypes = {};

export default Files;
