import * as THREE from 'three';
import { printerConstants as constants } from '../../../constants/printers/printerConstants';
import SixAxisRobot from './SixAxisRobot';
import { getMachineDefinitionResponse } from '../MachineUtils';
import { robotBrandDefinitions } from '@constants/printers/robotBrandDefinitions';

/**
 * Represents a six axis robot object, which is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 *
 * This is the 'Custom' version of the six-axis robot entity, as defined by the
 * user's parameters and models. The THREE Groups objects have been set up based
 * on the DH frames of the robot - we should eventually deprecate the existing
 * SixAxisRobot.js class as it uses an inferior representation of the robot structure
 * which requires unituitive correction factors.
 */
class SixAxisRobotCustom extends SixAxisRobot {
  constructor(robotType, printerSettings) {
    super(robotType, [], printerSettings);
  }

  /**
   * Appends the initial kinematic properties setup and initializes some new properties based
   * on the robot brand.
   * @param {*} printerSettings
   */
  setPropertiesKinematicValues(printerSettings) {
    super.setPropertiesKinematicValues(printerSettings);
    this.machineDefinitionResponse = getMachineDefinitionResponse(
      this.printerSettings,
    );
    this.brand = this.machineDefinitionResponse.brand;
    const defaults = robotBrandDefinitions[this.brand];
    this.a = [];
    this.d = [];
    for (let axisNumber = 1; axisNumber <= 6; axisNumber++) {
      const aKey = 'axisA' + axisNumber;
      const dKey = 'axisD' + axisNumber;

      const aValue = this.props[aKey];
      const dValue = defaults.dInversions[axisNumber - 1] * this.props[dKey];

      this.a.push(aValue);
      this.d.push(dValue);
    }
    this.alpha = defaults.alpha;
    this.theta = defaults.theta;
    this.cannonPositionCorrections = defaults.cannonPositionCorrections;
    this.defaultHomeValue = defaults.defaultHomeValue;
  }

  /**
   * Creates THREE.Group objects to contain each individual rotating axis and adds
   * additional groups for rotation (separate groups for rotation and models allows
   * the models to have rotation offsets).
   *
   * Distal axes are added as children in a tree-structure to the proximal axes.
   */
  createJointGroups() {
    for (let partNumber = 0; partNumber <= 6; partNumber++) {
      const partGroup = new THREE.Group();
      partGroup.name = constants.axis + partNumber;

      const pivotNoRotation = new THREE.Group();
      const pivot = this.generatePivotForPart(partNumber);
      pivot.name = partGroup.name + constants.pivot;

      pivotNoRotation.add(pivot);
      pivotNoRotation.rotation.z = this.theta[partNumber];
      partGroup.add(pivotNoRotation);

      this.rotationPoints.push(pivot);

      if (partNumber > 0) {
        const parentPartNumber = partNumber - 1;
        const parentPivot = this.rotationPoints[parentPartNumber];
        parentPivot.add(partGroup);
      } else {
        this.add(partGroup);
      }

      if (partNumber === 6) {
        this.flange = pivot;
        this.flange.add(this.flangeMounting);
        this.flangeMounting.rotation.set(0, 0, this.mountingAngle);
        if (this.brand === 'Yaskawa' || this.brand === 'Fanuc') {
          //For convenience we auto rotate the flange mounting for robots whose flange has z up
          this.flangeMounting.rotateZ(Math.PI);
        }
        //This is to adjust the extruder model, as extruders are exported with z up
        this.flangeMounting.rotateY(-Math.PI / 2);
        this.flangeMounting.add(new THREE.AxesHelper(10000));
        this.flange.add(this.tcp);
      }
      this[constants.part + partNumber] = partGroup;
    }
  }

  /**
   * Returns the default value for the robot's specified axis through a combination of
   * default home position and home position corrections, which are both defined per machine.
   * @param {Num} axis axis number between 0 and 5 inclusive, representing axes 1-6
   * @returns
   */
  getDefaultHomeValue = () => {
    return this.defaultHomeValue;
  };

  /**
   * Loads the complete model of the specified robot and then iterates through each axis,
   * to add the models of each axis to the correct groups.
   * @returns a promise that is resolved when the complete model is loaded
   */
  initializeMachineGeometry() {
    return new Promise((resolve) => {
      this.initializeCustomModels(resolve);
    });
  }

  /**
   * Sequentially performs the inverse transformations applied by the proximal ->
   * distal frames when constructing the Denavit-Hartenberg frames. By performing
   * the inverse transformations we allow the user to upload their robots in their
   * assembled form. The cannonPositionCorrections parameter corrects for differences
   * in zero-positions between each brand
   * @param {*} robotModel loaded 3D model of an axis
   * @param {*} axisNumber number of the axis 0-6
   */
  addCustomAxis(robotModel, axisNumber) {
    for (let i = axisNumber; i > 1; i--) {
      robotModel.rotateZ(this.cannonPositionCorrections[i - 1]);
      robotModel.rotateX(-this.alpha[i - 1]);
      robotModel.translateOnAxis(new THREE.Vector3(-1, 0, 0), this.a[i - 2]);
      robotModel.translateZ(-this.d[i - 2]);
      robotModel.rotateZ(-this.theta[i - 1]);
    }
    if (axisNumber > 0) {
      robotModel.rotateX(-this.alpha[0]);
      robotModel.rotateZ(-this.theta[0]);
    }
    this[constants.part + axisNumber].add(robotModel);
  }

  /**
   * Creates THREE.Groups at the correct positions for rotating the child parts, i.e.
   * the links which attach at that position, i.e. the point of rotation of all joints.
   * Successive THREE.Groups are created as children of the parent group, with their position
   * offset relative to the parent dependent on the values defined in the machine geometry.
   *
   * Note that the includes steps 2, 3, and 4 of typical Denavit-Hartenberg frame generation. Step 1
   * is applied in the zero rotation step above.
   * @param {*} partNumber
   * @returns
   */
  generatePivotForPart(partNumber) {
    const pivot = new THREE.Group();
    if (partNumber > 0) {
      pivot.translateZ(this.d[partNumber - 1]);
      pivot.translateOnAxis(new THREE.Vector3(1, 0, 0), this.a[partNumber - 1]);
    }
    pivot.rotateX(this.alpha[partNumber]);
    return pivot;
  }

  /**
   * Sets the angle of a specific joint of the 6DOF robot, and applies the zero-offset
   * theta value.
   * Note: theta[2] corresponds to the rotation about axis 1.
   * @param {*} axisNumber number of axis 1 - 6?
   * @param {*} angle [rad]
   * @returns
   */
  setAngleOfAxis(axisNumber, angle) {
    const pivot = this.rotationPoints[axisNumber - 1];
    if (!pivot) return;

    angle = (angle / 360.0) * (Math.PI * 2.0);
    pivot.rotation.z = angle;
  }
}

export default SixAxisRobotCustom;
