import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';

export default function useNozzleList() {
  const intl = useIntl();
  const { showDialog } = useDialog();

  const getNozzleDropDownMenuActions = useCallback(
    (nozzle = {}) => {
      return [
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.DELETE_NOZZLE, {
              nozzle: nozzle,
            });
          },
        },
      ];
    },
    [showDialog, intl],
  );

  return {
    getNozzleDropDownMenuActions,
  };
}
