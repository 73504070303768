import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
} from '@components/2-molecules/MessageBox';
import Field from '@components/1-atoms/Field';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Wrapper } from './LoginResetPasswordEmail.styled';

const LoginResetPasswordEmail = ({
  className,
  dataTestId = 'login-page-reset-password-email',
  email,
  emailError,
  emailErrorMessage,
  emailSent,
  emailTouched,
  handleFieldBlur,
  handleFieldChange,
  handleGoBack,
  isSubmitting,
  resetFailed,
}) => {
  const intl = useIntl();
  const showEmailErrorMessage = emailTouched && emailError;

  if (emailSent) {
    return (
      <Wrapper data-testid={dataTestId} className={className}>
        <PageHeader
          dataTestId={`${dataTestId}__page-header`}
          variant={PAGE_HEADER_VARIANT_LARGE}
          title={intl.formatMessage({
            id: 'loginpage.resetpassword.checkyourinbox.title',
            defaultMessage: 'Check your inbox',
          })}
          subtitle={intl.formatMessage(
            {
              id: 'loginpage.resetpassword.checkyourinbox.subtitle',
              defaultMessage:
                'To complete setup and log in, click the verification link in the email we’ve sent to {email}.',
            },
            { email },
          )}
        />

        <Button
          dataTestId={`${dataTestId}__submit-button`}
          loading={isSubmitting}
          onClick={handleGoBack}
        >
          <FormattedMessage
            id="loginpage.resetpassword.back_to_login"
            defaultMessage="Back to Log in"
          />
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.resetpassword.title',
          defaultMessage: 'Reset your password',
        })}
        subtitle={intl.formatMessage({
          id: 'loginpage.resetpassword.subtitle',
          defaultMessage:
            "We'll email you instructions to reset your password.",
        })}
      />

      {resetFailed && (
        <MessageBox
          dataTestId={`${dataTestId}__message-box`}
          variant={MESSAGE_BOX_VARIANT_ERROR}
          leadingIconName="error_0"
        >
          <FormattedMessage
            id="loginpage.resetpassword.something_went_wrong"
            defaultMessage="Something went wrong. Please contact Aibuild to reset your password."
          />
        </MessageBox>
      )}

      <Field
        dataTestId={`${dataTestId}__email-field`}
        name="email"
        placeholder={intl.formatMessage({
          id: 'loginpage.resetpassword.email.placeholder',
          defaultMessage: 'Email',
        })}
        value={email}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        error={showEmailErrorMessage}
        supportingText={showEmailErrorMessage && emailErrorMessage}
      />

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        type="submit"
        loading={isSubmitting}
      >
        <FormattedMessage
          id="loginpage.resetpassword.reset_password"
          defaultMessage="Reset Password"
        />
      </Button>

      <Button
        dataTestId={`${dataTestId}__go-back-button`}
        disabled={isSubmitting}
        variant={BUTTON_VARIANT_TEXT}
        onClick={handleGoBack}
      >
        <FormattedMessage
          id="loginpage.resetpassword.go_back"
          defaultMessage="Go back"
        />
      </Button>
    </Wrapper>
  );
};

LoginResetPasswordEmail.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  email: PropTypes.string,
  emailError: PropTypes.bool,
  emailErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  emailSent: PropTypes.bool,
  emailTouched: PropTypes.bool,
  handleFieldBlur: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  resetFailed: PropTypes.bool,
};

export default LoginResetPasswordEmail;
