import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Divider } from './VerticalDivider.styled';

const VerticalDivider = ({
  inset,
  middleInset,
  inlineInset,
  inlineMiddleInset,
}) => {
  return (
    <Wrapper
      inset={inset}
      middleInset={middleInset}
      inlineInset={inlineInset}
      inlineMiddleInset={inlineMiddleInset}
    >
      <Divider />
    </Wrapper>
  );
};

VerticalDivider.propTypes = {
  inset: PropTypes.bool,
  middleInset: PropTypes.bool,
  inlineInset: PropTypes.bool,
  inlineMiddleInset: PropTypes.bool,
};

export default VerticalDivider;
