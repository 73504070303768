import { useQueryClient, useMutation } from '@tanstack/react-query';
import { omit } from 'lodash';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const printerMutationKeys = {
  createPrinter: ['createPrinter'],
  deletePrinter: ['deletePrinter'],
  stopPrint: ['showLoader', 'stopPrint'],
};

export default function usePrinterMutations() {
  const queryClient = useQueryClient();

  const createPrinterMutation = useMutation({
    mutationKey: printerMutationKeys.createPrinter,
    mutationFn: (printer = {}) =>
      client
        .post(
          endpoints.printers,
          omit(printer, [
            'bedKinematics',
            'baseOffsetX',
            'baseOffsetY',
            'baseOffsetZ',
          ]),
        )
        .then((res) => res.data),
    onSuccess: (addedPrinter) => {
      const updater = (data) => {
        return data ? [...data, addedPrinter] : [addedPrinter];
      };

      queryClient.setQueryData(printerQueryKeys.printers, updater);
    },
  });

  const deletePrinterMutation = useMutation({
    mutationKey: printerMutationKeys.deletePrinter,
    mutationFn: (printerId) =>
      client
        .delete(endpoints.printer.replace(':printerId', printerId))
        .then((res) => res.data),
    onSuccess: (_, mutationPrinterId) => {
      const updater = (data) => {
        return data
          ? data?.filter((printer) => printer.id !== mutationPrinterId)
          : undefined;
      };

      queryClient.setQueryData(printerQueryKeys.printers, updater);
    },
  });

  const stopPrintMutation = useMutation({
    mutationKey: printerMutationKeys.createPrinter,
    mutationFn: ({ printerId, printId }) =>
      client
        .delete(
          endpoints.printerPrint
            .replace(':printerId', printerId)
            .replace(':printId', printId),
        )
        .then((res) => res.data),
  });

  return {
    createPrinterMutation,
    deletePrinterMutation,
    stopPrintMutation,
  };
}
