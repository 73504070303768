import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { whenEnterButtonPressed } from '@utils/interactionEvents';
import {
  Wrapper,
  Content,
  StateLayer,
  OuterBox,
  CheckboxInput,
  Icon,
} from './Checkbox.styled';

export default function Checkbox({
  checked,
  className,
  dataTestId = 'checkbox',
  diffAdded,
  diffModified,
  diffRemoved,
  dirty,
  disabled,
  id,
  indeterminate,
  name = '',
  onChange,
  transparentWhenDisabled = true,
}) {
  const handleOnChange = useCallback(
    ({ enterButtonPressed } = {}) => onChange?.(!checked, enterButtonPressed),
    [checked, onChange],
  );

  const handleOnKeyPress = useCallback(() => {
    handleOnChange({ enterButtonPressed: true });
  }, [handleOnChange]);

  return (
    <Wrapper
      data-testid={dataTestId}
      disabled={disabled}
      className={className}
      transparentWhenDisabled={transparentWhenDisabled}
    >
      <Content>
        <CheckboxInput
          checked={checked}
          data-testid={`${dataTestId}__input-checkbox`}
          disabled={disabled}
          id={id}
          indeterminate={indeterminate}
          name={name}
          onChange={handleOnChange}
          onKeyDown={whenEnterButtonPressed(handleOnKeyPress)}
        />

        <StateLayer disabled={disabled} />

        <OuterBox
          checked={checked}
          diffAdded={diffAdded}
          diffModified={diffModified}
          diffRemoved={diffRemoved}
          dirty={dirty}
          disabled={disabled}
        >
          {checked && (
            <Icon
              disabled={disabled}
              name={
                indeterminate ? 'check_indeterminate_small_0' : 'check_small_0'
              }
            />
          )}
        </OuterBox>
      </Content>
    </Wrapper>
  );
}

Checkbox.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  dirty: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  onChange: PropTypes.func,
  transparentWhenDisabled: PropTypes.bool,
};
