import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import InfoRow from '@components/1-atoms/InfoRow';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Wrapper, QRContainer } from './Login2FAConnector.styled';

export const MFA_CODE_LENGTH = 6;

const Login2FAConnector = ({
  className,
  dataTestId = 'login-page-2fa-connector',
  handleGoBack,
  handleSubmit,
  isSubmitting,
  mfaSetupCode,
  qrCodeUrl,
}) => {
  const intl = useIntl();

  const handleCopySetupCode = useCallback(() => {
    navigator.clipboard.writeText(mfaSetupCode);
  }, [mfaSetupCode]);

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.2fa_connector.set_up_mfa',
          defaultMessage: 'Set up multi-factor authentication',
        })}
        subtitle={intl.formatMessage({
          id: 'loginpage.2fa_connector.scan_qr_code',
          defaultMessage:
            'Scan the QR code using an authenticator app or use the code below for manual setup.',
        })}
      />

      <QRContainer>
        <QRCodeSVG value={qrCodeUrl} size={140} includeMargin />
      </QRContainer>

      <InfoRow
        dataTestId={`${dataTestId}__mfa-setup-code`}
        label={mfaSetupCode}
        endingIconButtonIconName="content_copy_0"
        onEndingIconButtonClick={handleCopySetupCode}
      />

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        loading={isSubmitting}
        onClick={handleSubmit}
      >
        <FormattedMessage
          id="loginpage.2fa_connector.set_up_authenticator"
          defaultMessage="I set up the authenticator app"
        />
      </Button>

      <Button
        dataTestId={`${dataTestId}__go-back-button`}
        disabled={isSubmitting}
        variant={BUTTON_VARIANT_TEXT}
        onClick={handleGoBack}
      >
        <FormattedMessage
          id="loginpage.2fa_connector.go_back"
          defaultMessage="Go back"
        />
      </Button>
    </Wrapper>
  );
};

Login2FAConnector.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  handleGoBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  mfaSetupCode: PropTypes.string.isRequired,
  qrCodeUrl: PropTypes.string.isRequired,
};

export default Login2FAConnector;
