import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import { isEmpty, isFunction, uniqueId } from 'lodash';
import IconButton from '@components/1-atoms/IconButton';
import {
  Wrapper,
  LogoButton,
  Logo,
  Header,
  Body,
  PreFooter,
  Footer,
  DraggableIconButtonWrapper,
  DraggableIconButtonWrapperClone,
  DndPlaceholder,
} from './Toolbar.styled';

export const TOOLBAR_CONTENT_ALIGN_LEFT = 'left';
export const TOOLBAR_CONTENT_ALIGN_CENTER = 'center';
export const TOOLBAR_CONTENT_ALIGN_RIGHT = 'right';

const Toolbar = forwardRef(
  (
    {
      className,
      dataTestId = 'toolbar',
      logoButton,
      headerIconButtons = [],
      bodyIconButtons = [],
      footerIconButtons = [],
      preFooterIconButtons = [],
      alignContent = TOOLBAR_CONTENT_ALIGN_LEFT,
      drag,
    },
    ref,
  ) => {
    const uniqueToolbarIdRef = useRef(uniqueId('toolbar-'));

    return (
      <Wrapper
        ref={ref}
        data-testid={dataTestId}
        className={className}
        alignContent={alignContent}
      >
        {logoButton && (
          <LogoButton
            as={logoButton?.link ? NavLink : undefined}
            data-testid={`${dataTestId}__logo-button`}
            onClick={logoButton?.onClick}
            tabIndex={0}
            title={logoButton?.title}
            to={logoButton?.link}
          >
            <Logo />
          </LogoButton>
        )}

        {!isEmpty(headerIconButtons) && (
          <Header>
            {headerIconButtons.map((iconButton, index) => (
              <IconButton
                dataTestId={`${dataTestId}__header__icon-button-${index}`}
                disabled={iconButton?.disabled}
                variant={iconButton?.variant}
                iconColor={iconButton?.iconColor}
                iconName={iconButton?.iconName}
                key={iconButton?.id}
                onClick={iconButton?.onClick}
                ref={iconButton?.ref}
                title={iconButton?.title}
                tooltip={iconButton?.tooltip}
              />
            ))}
          </Header>
        )}

        {!isEmpty(bodyIconButtons) && isEmpty(drag) && (
          <Body>
            {bodyIconButtons.map((iconButton, index) => (
              <IconButton
                dataTestId={`${dataTestId}__body__icon-button-${index}`}
                disabled={iconButton?.disabled}
                variant={iconButton?.variant}
                iconColor={iconButton?.iconColor}
                iconName={iconButton?.iconName}
                key={iconButton?.id}
                onClick={iconButton?.onClick}
                ref={iconButton?.ref}
                title={iconButton?.title}
                tooltip={iconButton?.tooltip}
                selected={iconButton.selected}
              />
            ))}
          </Body>
        )}

        {!isEmpty(bodyIconButtons) && !isEmpty(drag) && (
          <Droppable
            droppableId={`${drag?.droppableId}-${uniqueToolbarIdRef.current}`}
            isDropDisabled
          >
            {(provided) => (
              <Body ref={provided.innerRef} {...provided.droppableProps}>
                {bodyIconButtons.map((iconButton, index) => (
                  <Draggable
                    key={`${drag?.droppableId}-${uniqueToolbarIdRef.current}-toolbar-${iconButton?.id}`}
                    index={index}
                    draggableId={`${
                      iconButton?.draggableId ||
                      `draggableId:{${iconButton?.id}}`
                    } uniqueComponentId:{${uniqueToolbarIdRef.current}}`}
                    isDragDisabled={drag?.isDragDisabled}
                    clone
                  >
                    {(provided, snapshot) => {
                      const isDraggingOverTargetDroppable =
                        snapshot.draggingOver === drag?.droppableId;

                      return (
                        <>
                          <DraggableIconButtonWrapper
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            tabIndex={-1}
                            ref={provided.innerRef}
                            draggingOverDroppable={
                              isDraggingOverTargetDroppable
                            }
                            dragging={snapshot.isDragging}
                            style={{
                              ...provided.draggableProps.style,
                              ...(snapshot.isDragging
                                ? {
                                    width: 'unset',
                                    height: 'unset',
                                  }
                                : {}),
                              ...(snapshot.isDropAnimating
                                ? {
                                    opacity: 0,
                                    transform: 'translate(0)',
                                    transition:
                                      'transform 0.01s cubic-bezier(.2,1,.1,1)',
                                  }
                                : {
                                    transform:
                                      provided.draggableProps.style.transform,
                                    transition:
                                      provided.draggableProps.style.transition,
                                  }),
                            }}
                          >
                            {/* icon buttton until start dragging or when draggable element is not passed */}
                            {(!snapshot.isDragging ||
                              (snapshot.isDragging &&
                                !drag?.draggableElement)) && (
                              <IconButton
                                dataTestId={`${dataTestId}__body__icon-button-${index}`}
                                disabled={iconButton?.disabled}
                                variant={iconButton?.variant}
                                iconColor={iconButton?.iconColor}
                                iconName={iconButton?.iconName}
                                key={iconButton?.id}
                                onClick={iconButton?.onClick}
                                renderAs="div"
                                ref={iconButton?.ref}
                                selected={snapshot.isDragging}
                                title={iconButton?.title}
                                tooltip={iconButton?.tooltip}
                                tabIndex={0}
                              />
                            )}

                            {/* show draggable element when is dragging */}
                            {snapshot.isDragging &&
                              drag?.draggableElement &&
                              (isFunction(drag?.draggableElement)
                                ? drag?.draggableElement(
                                    iconButton?.dragData,
                                    isDraggingOverTargetDroppable,
                                  )
                                : drag?.draggableElement)}
                          </DraggableIconButtonWrapper>

                          {/* placeholder to keep list still and the dragging element is not moved from the list on drag */}
                          {snapshot.isDragging && (
                            <DraggableIconButtonWrapperClone>
                              <IconButton
                                dataTestId={`${dataTestId}__body__icon-button-${index}-clone`}
                                disabled={iconButton?.disabled}
                                variant={iconButton?.variant}
                                iconColor={iconButton?.iconColor}
                                iconName={iconButton?.iconName}
                                key={iconButton?.id}
                                onClick={iconButton?.onClick}
                                ref={iconButton?.ref}
                                title={iconButton?.title}
                                tooltip={iconButton?.tooltip}
                              />
                            </DraggableIconButtonWrapperClone>
                          )}
                        </>
                      );
                    }}
                  </Draggable>
                ))}

                <DndPlaceholder>{provided.placeholder}</DndPlaceholder>
              </Body>
            )}
          </Droppable>
        )}

        {!isEmpty(preFooterIconButtons) && (
          <PreFooter>
            {preFooterIconButtons.map((iconButton, index) => (
              <IconButton
                dataTestId={`${dataTestId}__pre-footer__icon-button-${index}`}
                disabled={iconButton?.disabled}
                variant={iconButton?.variant}
                iconColor={iconButton?.iconColor}
                iconName={iconButton?.iconName}
                key={iconButton?.id}
                onClick={iconButton?.onClick}
                ref={iconButton?.ref}
                title={iconButton?.title}
                tooltip={iconButton?.tooltip}
              />
            ))}
          </PreFooter>
        )}

        {!isEmpty(footerIconButtons) && (
          <Footer>
            {footerIconButtons.map((iconButton, index) => (
              <IconButton
                dataTestId={`${dataTestId}__footer__icon-button-${index}`}
                disabled={iconButton?.disabled}
                variant={iconButton?.variant}
                iconColor={iconButton?.iconColor}
                iconName={iconButton?.iconName}
                key={iconButton?.id}
                onClick={iconButton?.onClick}
                ref={iconButton?.ref}
                title={iconButton?.title}
                tooltip={iconButton?.tooltip}
                submitType={iconButton?.submitType}
              />
            ))}
          </Footer>
        )}
      </Wrapper>
    );
  },
);

Toolbar.displayName = 'Toolbar';

Toolbar.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  logoButton: PropTypes.shape({
    link: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
  }),
  headerIconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      variant: PropTypes.string,
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      title: PropTypes.string,
      tooltip: PropTypes.object,
    }),
  ),
  bodyIconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      dragData: PropTypes.object,
      draggableId: PropTypes.string,
      variant: PropTypes.string,
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      title: PropTypes.string,
      tooltip: PropTypes.object,
      selected: PropTypes.bool,
    }),
  ),
  preFooterIconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      variant: PropTypes.string,
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      title: PropTypes.string,
      tooltip: PropTypes.object,
    }),
  ),
  footerIconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      variant: PropTypes.string,
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      title: PropTypes.string,
      tooltip: PropTypes.object,
      submitType: PropTypes.bool,
    }),
  ),
  alignContent: PropTypes.oneOf([
    TOOLBAR_CONTENT_ALIGN_LEFT,
    TOOLBAR_CONTENT_ALIGN_CENTER,
    TOOLBAR_CONTENT_ALIGN_RIGHT,
  ]),
  drag: PropTypes.shape({
    droppableId: PropTypes.string,
    isDragDisabled: PropTypes.bool,
    draggableElement: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  }),
};

export default Toolbar;
