import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import useDesign from '@hooks/useDesign';
import { getSimulation } from '@selectors/conceptSelectors';
import { focusCameraOnObject } from '@actions/sceneActions';

export const CAMERA_ACTIONS = {
  FOCUS: 'Center on screen',
};

export default function useCameraTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { design, designGeometryData } = useDesign();
  const simulation = useSelector(getSimulation());
  const showLines = !((!design || !designGeometryData) && !simulation.isActive);
  const disableActions = !showLines;

  const handleFocus = useCallback(() => {
    dispatch(focusCameraOnObject(true));
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        id: CAMERA_ACTIONS.FOCUS,
        disabled: disableActions,
        name: intl.formatMessage({
          id: 'toolbar.camera.focus',
          defaultMessage: CAMERA_ACTIONS.FOCUS,
        }),
        iconName: 'frame_inspect_0',
        handler: handleFocus,
      },
    ],
    [intl, disableActions, handleFocus],
  );

  return actions;
}
