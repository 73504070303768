/*
dInversions represents mistakes with the initial implementation sign of DH params parameter d.

cannonPositionCorrections represents the rotations that must be applied to each joint to move the
robot from being imported in cannon position to being at its zero-axis position
isFlangeInverted: for fanuc and yaskawa systems, flange refernece frame is rotated 180 deg about z
*/
export const robotBrandDefinitions = {
  KUKA: {
    isRobotBrand: true,
    alpha: [
      Math.PI,
      Math.PI / 2,
      0,
      Math.PI / 2,
      -Math.PI / 2,
      Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, 0, -Math.PI / 2, 0, 0, Math.PI],
    dInversions: [-1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, Math.PI / 2, -Math.PI / 2, 0, 0, 0],
    defaultHomePosition: [0, -90, 100, 0, -10, 0],
  },
  ABB: {
    isRobotBrand: true,
    alpha: [0, -Math.PI / 2, 0, -Math.PI / 2, Math.PI / 2, -Math.PI / 2, 0],
    theta: [0, 0, -Math.PI / 2, 0, 0, 0, Math.PI],
    dInversions: [1, 1, 1, 1, 1, 1],
    cannonPositionCorrections: [0, 0, 0, 0, 0, 0],
    defaultHomePosition: [0, 0, 10, 0, -10, 0],
  },
  Yaskawa: {
    isRobotBrand: true,
    alpha: [
      0,
      -Math.PI / 2,
      Math.PI,
      -Math.PI / 2,
      Math.PI / 2,
      -Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, -Math.PI / 2, 0, 0, 0, 0],
    dInversions: [1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, 0, 0, 0, 0, 0],
    defaultHomePosition: [0, 0, -10, 0, 10, 0],
    isFlangeInverted: true,
  },
  Comau: {
    isRobotBrand: true,
    alpha: [
      Math.PI,
      Math.PI / 2,
      Math.PI,
      -Math.PI / 2,
      -Math.PI / 2,
      Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, -Math.PI / 2, Math.PI / 2, 0, 0, Math.PI],
    dInversions: [-1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, 0, Math.PI / 2, 0, 0, 0],
    defaultHomePosition: [0, -0, -100, 0, -10, 0],
  },
  Fanuc: {
    alpha: [
      0,
      -Math.PI / 2,
      Math.PI,
      -Math.PI / 2,
      Math.PI / 2,
      -Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, -Math.PI / 2, 0, 0, 0, 0],
    dInversions: [1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, 0, 0, 0, 0, 0],
    defaultHomePosition: [0, 0, -10, 0, 10, 0],
    isFlangeInverted: true,
  },
  Comau_Coupled: {
    alpha: [
      Math.PI,
      Math.PI / 2,
      Math.PI,
      -Math.PI / 2,
      -Math.PI / 2,
      Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, -Math.PI / 2, Math.PI / 2, 0, 0, Math.PI],
    dInversions: [-1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, 0, Math.PI / 2, 0, 0, 0],
    defaultHomePosition: [0, -0, -100, 0, -10, 0],
  },
  Staubli: {
    alpha: [
      Math.PI,
      Math.PI / 2,
      0,
      Math.PI / 2,
      -Math.PI / 2,
      Math.PI / 2,
      Math.PI,
    ],
    theta: [0, 0, 0, -Math.PI / 2, 0, 0, Math.PI],
    dInversions: [-1, 1, 1, -1, 1, -1],
    cannonPositionCorrections: [0, Math.PI / 2, -Math.PI / 2, 0, 0, 0],
    defaultHomePosition: [0, -90, 100, 0, -10, 0],
  },
  //-----
  AiBuild: {},
  Ultimaker: {},
  WASP: {},
  re3D: {},
  Breton: {},
  Nikon: {},
  Thermwood: {},
  Fanum: {},
  Titan: {},
  Hyperion: {},
  Belotti: {},
  Formalloy: {},
  Modix: {},
  KraussMaffei: {},
  Mitsubishi: {},
  Generic: {},
};
