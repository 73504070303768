import React, { useState, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { useDispatch } from 'react-redux';
import { defaultQuaternionSettings } from '@constants/defaultQuaternionSettings';
import { changePrinterSetting } from '@actions/printerActions';
import { Category } from './Category.js';
import { useIntl } from 'react-intl';
import { printerSettings } from '@constants/printerSettings';
import PropTypes from 'prop-types';
import { defaultPrinterSettings } from '@constants/defaultPrinterSettings';

export const QuaternionSettings = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toolpathReferenceFrame = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) => setting.settingName === 'toolpathReferenceFrame',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultBaseId = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) => setting.settingName === 'defaultBaseId',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rotationCorrectionsX = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) =>
          setting.settingName === printerSettings.rotationCorrectionRx,
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rotationCorrectionsY = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) =>
          setting.settingName === printerSettings.rotationCorrectionRy,
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rotationCorrectionsZ = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) =>
          setting.settingName === printerSettings.rotationCorrectionRz,
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseRef1X = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) => setting.settingName === 'baseRef1X',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseRef1Y = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) => setting.settingName === 'baseRef1Y',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseRef1Z = useMemo(
    () =>
      defaultPrinterSettings.filter(
        (setting) => setting.settingName === 'baseRef1Z',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qReal = useMemo(
    () =>
      defaultQuaternionSettings.filter(
        (setting) => setting.settingName === 'qReal',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qX = useMemo(
    () =>
      defaultQuaternionSettings.filter(
        (setting) => setting.settingName === 'qX',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qY = useMemo(
    () =>
      defaultQuaternionSettings.filter(
        (setting) => setting.settingName === 'qY',
      )[0],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const qZ = useMemo(
    () =>
      defaultQuaternionSettings.filter(
        (setting) => setting.settingName === 'qZ',
      )[0],
  );

  const [originInitialized, setOriginInitialized] = useState(false);
  const [quatInitialized, setQuatInitialized] = useState(false);
  const [origin, setOrigin] = useState(
    new THREE.Vector3(
      props.selectedPrinter.baseRef1X,
      props.selectedPrinter.baseRef1Y,
      props.selectedPrinter.baseRef1Z,
    ),
  );
  const yPoint = new THREE.Vector3(
    props.selectedPrinter.baseRef2X,
    props.selectedPrinter.baseRef2Y,
    props.selectedPrinter.baseRef2Z,
  );
  const xPoint = new THREE.Vector3(
    props.selectedPrinter.baseRef3X,
    props.selectedPrinter.baseRef3Y,
    props.selectedPrinter.baseRef3Z,
  );
  const [quat, setQuat] = useState(
    convertThreePointsToQuaternion(origin, xPoint, yPoint),
  );

  useEffect(() => {
    setOriginInitialized(true);
    setQuatInitialized(true);
  }, []);

  useEffect(() => {
    if (originInitialized) {
      const [newXPoint, newYPoint] = calculateNewReferencePoints(origin, quat);
      updateBaseReferences(
        newXPoint,
        newYPoint,
        dispatch,
        props.selectedPrinter,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);

  /**
   * On quaternion value change, recalculate the equivalent base reference point
   * values and dispatch the new values to be updated.
   */
  useEffect(() => {
    if (quatInitialized) {
      const [newXPoint, newYPoint] = calculateNewReferencePoints(origin, quat);
      updateBaseReferences(
        newXPoint,
        newYPoint,
        dispatch,
        props.selectedPrinter,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quat]);

  /**
   * On input value change of the quaternion settings, first dispatch an update to
   * save the new input value, then construct a new Quaternion object with the new
   * input value, then set the new quaternion state.
   * @param {*} changedSetting
   * @param {*} valueString
   */
  const handleChange = (changedSetting, valueString) => {
    dispatch(
      changePrinterSetting(
        props.selectedPrinter.id,
        changedSetting,
        valueString,
      ),
    );
    const value = parseFloat(valueString);
    if (changedSetting.category === 'BASE_QUATERNION') {
      const newQuat = getUpdatedQuaternion(
        changedSetting.settingName,
        value,
        quat,
      );
      setQuat(newQuat);
    } else if (changedSetting.settingName === 'baseRef1X') {
      //need to update all XYZ values to move all points together, otherwise bed will flip. origin state needs to be tracked independently to keep track of unsavedModifications
      setOrigin(new THREE.Vector3(value, origin.y, origin.z));
    } else if (changedSetting.settingName === 'baseRef1Y') {
      setOrigin(new THREE.Vector3(origin.x, value, origin.z));
    } else if (changedSetting.settingName === 'baseRef1Z') {
      setOrigin(new THREE.Vector3(origin.x, origin.y, value));
    }
  };

  return (
    <Category
      onChange={handleChange}
      selectedPrinter={props.selectedPrinter}
      settingsInCategory={[
        defaultBaseId,
        toolpathReferenceFrame,
        rotationCorrectionsX,
        rotationCorrectionsY,
        rotationCorrectionsZ,
        baseRef1X,
        baseRef1Y,
        baseRef1Z,
        //Settings have to be assigned like this because these values cannot be stored in the printer object itself as they do not belong to the printer model
        { ...qReal, value: quat.w },
        { ...qX, value: quat.x },
        { ...qY, value: quat.y },
        { ...qZ, value: quat.z },
      ].concat(props.additionalSettings)}
      categoryTitle={intl.formatMessage({
        id: 'printers.category.baseCalibration',
        defaultMessage: 'Base Calibration',
      })}
    />
  );
};

/**
 * Returns a new quaternion object based on the previous quaternion and the newly
 * updated value.
 * @param {String} settingName
 * @param {Number} value
 * @param {THREE.Quaternion} quat
 * @returns new quaternion object
 */
const getUpdatedQuaternion = (settingName, value, quat) => {
  const newQuat = quat.clone();
  switch (settingName) {
    case 'qReal':
      newQuat.w = value;
      break;
    case 'qX':
      newQuat.x = value;
      break;
    case 'qY':
      newQuat.y = value;
      break;
    case 'qZ':
      newQuat.z = value;
      break;
  }
  return newQuat;
};

/**
 * Dispatch the equivalent re-calculated base values.
 * @param {*} newBaseXPoint
 * @param {*} newBaseYPoint
 * @param {*} dispatch
 * @param {*} printer
 */
const updateBaseReferences = (
  newBaseXPoint,
  newBaseYPoint,
  dispatch,
  printer,
) => {
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef2X' },
      newBaseYPoint.x,
    ),
  );
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef2Y' },
      newBaseYPoint.y,
    ),
  );
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef2Z' },
      newBaseYPoint.z,
    ),
  );
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef3X' },
      newBaseXPoint.x,
    ),
  );
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef3Y' },
      newBaseXPoint.y,
    ),
  );
  dispatch(
    changePrinterSetting(
      printer.id,
      { settingName: 'baseRef3Z' },
      newBaseXPoint.z,
    ),
  );
};

/**
 * Converts a set of three points that define a coordinate system into the equivalent
 * quaternion representation.
 */
export const convertThreePointsToQuaternion = (origin, xPoint, yPoint) => {
  const xDir = new THREE.Vector3();
  const yDir = new THREE.Vector3();
  const zDir = new THREE.Vector3();
  xDir.subVectors(xPoint, origin).normalize();
  yDir.subVectors(yPoint, origin).normalize();
  zDir.crossVectors(xDir, yDir).normalize();
  const rotationMatrix = new THREE.Matrix4();
  rotationMatrix.makeBasis(xDir, yDir, zDir);
  return new THREE.Quaternion().setFromRotationMatrix(rotationMatrix);
};

/**
 * Calculates and returns new values for base reference points 2 and 3, based on the
 * current origin and a supplied quaternion value.
 * @param {THREE.Vector3} origin
 * @param {THREE.Quaternion} quaternion
 * @returns
 */
const calculateNewReferencePoints = (origin, quaternion) => {
  const rotationMatrix = new THREE.Matrix4().makeRotationFromQuaternion(
    quaternion,
  );
  const xAxis = new THREE.Vector3();
  const yAxis = new THREE.Vector3();
  const zAxis = new THREE.Vector3();
  rotationMatrix.extractBasis(xAxis, yAxis, zAxis);
  const newXPoint = origin.clone().add(xAxis);
  const newYPoint = origin.clone().add(yAxis);
  return [newXPoint, newYPoint];
};

QuaternionSettings.propTypes = {
  additionalSettings: PropTypes.array,
  selectedPrinter: PropTypes.object.isRequired,
};
