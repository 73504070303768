import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  defaultExtruderProperties,
  toolTypeDefinitions,
} from '@constants/printers/toolTypeDefinitions';
import { ExtruderSettings } from './ExtruderSettings';
import { toolTypeFiles } from '@constants/printers/componentFiles';
import * as Yup from 'yup';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { printerConstants } from '@constants/printers/printerConstants';
import { extrusionTypeDefinitions } from '@constants/printers/extrusionTypeDefinitions';
import { getFormattedOption } from '@components/Printers/SettingsUtils';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { generateSingleFileUpload } from '@utils/filesUpload';

export const ToolTypeSetting = () => {
  const intl = useIntl();

  const { values, initialValues, setFieldValue } = useFormikContext();
  const toolTypeValues = values['toolTypeSetting'];

  const setValue = useMemo(
    () => (field, value) => {
      setFieldValue(`toolTypeSetting.${field}`, value);
    },
    [setFieldValue],
  );

  const renderUploadRows = () => {
    if (toolTypeValues.files) {
      return Object.keys(toolTypeValues.files).map((axisKey) => {
        const entry = toolTypeValues.files[axisKey];
        return generateSingleFileUpload(
          entry,
          'printer-components-tool-type__category-upload',
          axisKey,
          (file) => {
            setValue(`files.${axisKey}.file`, file);
            setValue(`files.${axisKey}.fileName`, file.name + new Date());
          },
          {
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `toolTypeSetting.files.${axisKey}.fileName`,
            ),
            allowedExtensions: entry.allowedExtensions,
            fileSize: entry.fileSize || 20,
          },
        );
      });
    }
  };

  const changeToolType = useCallback(() => {
    setValue(
      'files',
      Object.fromEntries(
        toolTypeFiles.map(
          ({ value, label, allowedExtensions, placeholderLabel, fileSize }) => [
            value,
            {
              label,
              file: null,
              fileName: null,
              allowedExtensions,
              placeholderLabel,
              fileSize,
            },
          ],
        ),
      ),
    );
    setValue('toolProperties', defaultExtruderProperties);
  }, [setValue]);

  const getFormattedOptions = useMemo(
    () => (definitions, changeToolType) =>
      Object.values(definitions).map((option) =>
        getFormattedOption(option, changeToolType),
      ),
    [],
  );
  const options = getFormattedOptions(toolTypeDefinitions, changeToolType);

  const renderToolRows = (toolType) => {
    return toolType.value === toolTypeDefinitions.EXTRUDER.value ? (
      <ExtruderSettings />
    ) : null;
  };

  return (
    <>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.settings.information.label',
        })}
        dataTestId={`printer-components-tool-type__category-information`}
        expand
        withDividerBottom={!!toolTypeValues.toolType}
      >
        <FormikField
          component={SettingDropDown}
          label={intl.formatMessage({
            id: 'printers.settings.type.label',
          })}
          dataTestId={`printer-components-tool-type__category-information__setting-tool-type`}
          name={'toolTypeSetting.toolType'}
          dropDownField={{
            dropDownMenuItems: options,
            fullWidthDropDownMenu: false,
            placeholder: intl.formatMessage({
              id: 'printers.settings.tool.type.placeholder',
              defaultMessage: 'Tool Type',
            }),
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              'toolTypeSetting.toolType',
            ),
          }}
        />
      </SettingsCategory>
      {toolTypeValues.toolType && (
        <>
          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.upload.title',
            })}
            dataTestId={`printer-components-tool-type__category-upload`}
            expand
            withDividerBottom
          >
            <DiagramRowComponent
              src={'/img/digitalTwin/extruder/diagram_extruder.svg'}
              description_key={
                'printers.settings.tool.diagram.description.label'
              }
              second_description_key={'printers.settings.upload.export.label'}
            />
            {renderUploadRows()}
          </SettingsCategory>

          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.tool.type.extruder.type.label',
            })}
            dataTestId={`printer-components-tool-type__category-parameters`}
            expand
          >
            {renderToolRows(toolTypeValues.toolType)}
          </SettingsCategory>
        </>
      )}
    </>
  );
};

export const ToolTypeSettingValidationSchema = Yup.object().shape({
  toolType: Yup.object().shape({
    value: Yup.string().required('Tool Type is required'),
  }),
  files: Yup.object().test(
    'areFilesValid',
    'All files must be provided',
    (files) => {
      return Object.values(files).every((entry) => entry.file !== null);
    },
  ),
  toolProperties: Yup.object().when(['toolType'], {
    is: (toolType) => toolType?.value,
    then: Yup.object().test(
      'arePropertiesValid',
      'All properties must be provided',
      (toolProperties, { parent }) => {
        const expectedProperties =
          parent.toolType.value === toolTypeDefinitions.EXTRUDER.value
            ? Object.keys(defaultExtruderProperties)
            : null;

        if (expectedProperties) {
          return expectedProperties.every((property) =>
            Object.hasOwn(toolProperties, property),
          );
        }
        return false;
      },
    ),
  }),
});

export const initDataToolType = (extruderDefinitionResponse, initialValues) => {
  const propertiesToExclude = ['modelUrls', 'name', 'id', 'toolType'];

  return {
    ...initialValues,
    toolType: toolTypeDefinitions[extruderDefinitionResponse.name],
    toolProperties: Object.keys(extruderDefinitionResponse)
      .filter((key) => !propertiesToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = extruderDefinitionResponse[key];
        if (key === printerConstants.extrusionType) {
          obj[key] = extrusionTypeDefinitions[extruderDefinitionResponse[key]];
        }
        return obj;
      }, {}),
    files: Object.fromEntries(
      toolTypeFiles.map(
        ({ value, label, allowedExtensions, placeholderLabel, fileSize }) => [
          value,
          {
            label,
            file: new File(
              [],
              extruderDefinitionResponse.modelUrls[value].fileName,
            ),
            fileName: extruderDefinitionResponse.modelUrls[value].fileName,
            allowedExtensions,
            placeholderLabel,
            fileSize,
          },
        ],
      ),
    ),
  };
};
