import { useCallback } from 'react';
import { showErrorDialog } from '@actions/errorActions';
import useApplicationMutations from '@hooks/application/useApplicationMutations';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

export default function useLeanUpon() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { generateKnowledgeCentreUrlMutation } = useApplicationMutations();

  const onLearnUponLinkClick = useCallback(
    async (link) => {
      try {
        const navigationUrl =
          await generateKnowledgeCentreUrlMutation.mutateAsync();
        if (link) {
          window.open(`${navigationUrl}&redirect_uri=${link}`);
        } else {
          window.open(navigationUrl);
        }
      } catch (_) {
        dispatch(
          showErrorDialog(
            'Error',
            intl.formatMessage({
              id: 'knowledge_centre.error_dialog_title',
              defaultMessage:
                'An error has occurred authenticating to the knowledge centre',
            }),
          ),
        );
      }
    },
    [dispatch, intl, generateKnowledgeCentreUrlMutation],
  );

  return { onLearnUponLinkClick };
}
