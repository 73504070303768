export const materialConstants = {
  MATERIAL_METAL_WIRE: 'METAL_WIRE',
  MATERIAL_METAL_POWDER: 'METAL_POWDER',
  MATERIAL_FILAMENT: 'FILAMENT',
  MATERIAL_PELLET: 'PELLET',
  MATERIAL_THERMOSET_RESIN: 'THERMOSET_RESIN',
  PELLET_FILAMENT_HEATZONESC_COUNT: 3,
  HEAT_ZONE_LIST_PROPERTY: 'heatZonesList',
  PRIORITY_MATERIALS: ['Generic PLA', 'Stainless Steel 316L'],
  ALL: 'ALL',
};

export const materialIcons = {
  METAL_WIRE: 'wire_0',
  METAL_POWDER: 'powder_0',
  FILAMENT: 'spool_0',
  PELLET: 'grain_0',
  THERMOSET_RESIN: 'resin_0',
};

export const materialTypeLabel = (material) => {
  return material
    .split('_')
    .map((word) => {
      let formattedWord = word.toLowerCase();
      formattedWord = `${formattedWord[0].toUpperCase()}${formattedWord.slice(
        1,
      )}`;

      return formattedWord;
    })
    .join(' ');
};
