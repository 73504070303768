import actionTypes from '@actions/index';

const initialState = {
  data: {
    workflowAnnotations: [],
  },
};

const workflowAnnotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WORKFLOW_ANNOTATIONS_SUCCESS: {
      if (!action.payload.length) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          workflowAnnotations: [
            {
              workflowId: action.payload[0].workflowId,
              annotations: action.payload,
            },
          ],
        },
      };
    }
    case actionTypes.CREATE_WORKFLOW_ANNOTATION_SUCCESS: {
      const { workflowId } = action.payload;
      const workflows = state.data?.workflowAnnotations || [];
      const workflow = workflows.find((it) => it.workflowId === workflowId);

      if (!workflow) {
        workflows.push({
          workflowId: workflowId,
          annotations: [action.payload],
        });
      } else {
        workflow.annotations.push(action.payload);
      }

      return {
        ...state,
        data: {
          ...state.data,
          workflowAnnotations: [...state.data.workflowAnnotations],
        },
      };
    }

    case actionTypes.DELETE_WORKFLOW_ANNOTATION_SUCCESS: {
      const { id, workflowId } = action.payload;
      const workflows = state.data?.workflowAnnotations || [];
      const workflow = workflows.find((it) => it.workflowId === workflowId);
      if (!workflow) {
        return state;
      }
      workflow.annotations = workflow.annotations.filter((it) => it.id !== id);
      return {
        ...state,
        data: {
          ...state.data,
          workflowAnnotations: [...state.data.workflowAnnotations],
        },
      };
    }

    case actionTypes.UPDATE_WORKFLOW_ANNOTATION_SUCCESS: {
      const { id, update } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          workflowAnnotations: state.data.workflowAnnotations.map(
            (workflow) => ({
              ...workflow,
              annotations: workflow.annotations.map((annotation) =>
                annotation.id === id
                  ? { ...annotation, ...update }
                  : annotation,
              ),
            }),
          ),
        },
      };
    }

    default:
      return state;
  }
};

export default workflowAnnotationReducer;
