import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  Sidebar,
  Main,
  BannerWrapper,
} from './BaseLayout.styled';
import MessageBox from '@components/2-molecules/MessageBox';

const BaseLayout = ({ banners, children }) => {
  return (
    <Wrapper style={{ height: '100%' }}>
      {banners?.map((banner) => (
        <BannerWrapper key={banner.key}>
          <MessageBox
            key={banner.key}
            leadingIconName={banner.leadingIconName}
            endingButtonTitle={banner.endingButtonTitle}
            endingButtonIconName={banner.endingButtonIconName}
            endingIconButtonIconName={banner.endingIconButtonIconName}
            onEndingButtonClick={banner.onEndingButtonClick}
            onEndingIconButtonClick={banner.onEndingIconButtonClick}
            noBorderRadius
          >
            {banner.contentText}
          </MessageBox>
        </BannerWrapper>
      ))}

      <Content>
        <Sidebar>{children?.[0] || null}</Sidebar>

        <Main>{children?.[1] || null}</Main>
      </Content>
    </Wrapper>
  );
};

BaseLayout.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default BaseLayout;
