import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { gantryComponentTypeDefinitions } from '@constants/printers/gantryComponentTypeDefinitions';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const Axis3Settings = () => {
  const intl = useIntl();
  const { values, initialValues, setFieldValue } = useFormikContext();

  const getFormattedOptions = useMemo(
    () => (definitions, currentIndex) =>
      Object.values(definitions)
        .filter((option) => !option.value.includes(`AXIS_${currentIndex + 1}`))
        .map((option) => ({
          label: option.label,
          formFieldValue: {
            label: option.label,
            value: option.value,
          },
        })),
    [],
  );

  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <FormikField
            key={`axisLink${index + 1}`}
            component={SettingTextField}
            label={intl.formatMessage({
              id: `printers.settings.machine.type.axis.${index + 1}`,
            })}
            dataTestId={`printer-components-machine-type__setting-axis3__axis-link${
              index + 1
            }`}
            name={`machineTypeSetting.machineProperties.basisVector${
              index + 1
            }X`}
            field1={{
              type: 'number',
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `machineTypeSetting.machineProperties.basisVector${index + 1}X`,
              ),
            }}
            field2={{
              type: 'number',
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `machineTypeSetting.machineProperties.basisVector${index + 1}Y`,
              ),
              value:
                values[`machineTypeSetting`][`machineProperties`][
                  `basisVector${index + 1}Y`
                ],
              onChange: (e) =>
                setFieldValue(
                  `machineTypeSetting.machineProperties.basisVector${
                    index + 1
                  }Y`,
                  e.target.value,
                ),
            }}
            field3={{
              type: 'number',
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `machineTypeSetting.machineProperties.basisVector${index + 1}Z`,
              ),
              value:
                values[`machineTypeSetting`][`machineProperties`][
                  `basisVector${index + 1}Z`
                ],
              onChange: (e) =>
                setFieldValue(
                  `machineTypeSetting.machineProperties.basisVector${
                    index + 1
                  }Z`,
                  e.target.value,
                ),
            }}
          />
        );
      })}
      {Array.from({ length: 3 }).map((_, index) => {
        const options = getFormattedOptions(
          gantryComponentTypeDefinitions,
          index,
        );

        return (
          <FormikField
            key={`axisAttachment${index + 1}`}
            component={SettingDropDown}
            label={intl.formatMessage({
              id: `printers.settings.machine.type.gantry.axisAttachment${
                index + 1
              }.label`,
            })}
            dataTestId={`printer_components_machine_type_setting_axis_3__axisAttachment${
              index + 1
            }`}
            name={`machineTypeSetting.machineProperties.axis${
              index + 1
            }Attachment`}
            dropDownField={{
              dropDownMenuItems: options,
              fullWidthDropDownMenu: false,
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `machineTypeSetting.machineProperties.axis${
                  index + 1
                }Attachment`,
              ),
            }}
          />
        );
      })}
    </>
  );
};
