import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import useProject from '@hooks/projects/useProject';
import Field from '@app/components/1-atoms/Field';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import getIntlProvider from '@app/utils/getIntlProvider';
import { Fields } from './CreateProject.styled';

const intl = getIntlProvider();

const CreateProject = ({
  dataTestId = 'create-project',
  title = intl.formatMessage({
    id: 'createproject.project.header.title',
    defaultMessage: 'Create Project',
  }),
  subtitle = intl.formatMessage({
    id: 'createproject.project.header.subtitle',
    defaultMessage:
      'Projects provide a way to manage and organise manufacturing workflows and files in one place.',
  }),
}) => {
  const intl = useIntl();
  const { getProjects } = useProject();
  const form = useFormContext();
  const { isSubmitting, isSubmitted } = form.formState;

  const projects = getProjects();

  return (
    <>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={title}
        subtitle={subtitle}
      />

      <Fields>
        <Controller
          name="projectName"
          defaultValue="Untitled project"
          rules={{
            required: intl.formatMessage({
              id: 'createproject.project.projectname.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
            minLength: {
              value: 5,
              message: intl.formatMessage({
                id: 'createproject.project.projectname.validation.min',
                defaultMessage: 'Name must be at least 5 characters long',
              }),
            },
            maxLength: {
              value: 256,
              message: intl.formatMessage({
                id: 'createproject.project.projectname.validation.max',
                defaultMessage: 'Name must be no longer than 256 characters',
              }),
            },
            validate: (value) => {
              const doesNameExist = projects?.some(
                (project) => project.name === value,
              );

              if (doesNameExist) {
                return intl.formatMessage({
                  id: 'createproject.project.projectname.validation.unique',
                  defaultMessage: 'Name must be unique',
                });
              }
            },
          }}
          render={({ field, fieldState }) => {
            const error =
              (fieldState.isTouched || isSubmitted) && fieldState.error;

            return (
              <Field
                dataTestId={`${dataTestId}__project-name-field`}
                disabled={isSubmitting}
                name="projectName"
                label={intl.formatMessage({
                  id: 'createproject.project.projectname.label',
                  defaultMessage: 'Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'createproject.project.projectname.placeholder',
                  defaultMessage: 'Untitled project',
                })}
                autoFocus
                error={!!error}
                supportingText={error?.message}
                {...field}
              />
            );
          }}
        />
      </Fields>
    </>
  );
};

CreateProject.propTypes = {
  dataTestId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default CreateProject;
