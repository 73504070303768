import * as THREE from 'three';
import { TwoAxisPositioner } from './TwoAxisPositioner';
import { Printer } from '../Printer';
import { getBedDefinitionResponse } from './BedUtils';
import { s3Directories } from '../../constants/printers/s3Directories';
import { printerConstants } from '../../constants/printers/printerConstants';
import { bedTypeDefinitions } from '../../constants/printers/printingBedDefaultProperties';

class TwoAxisPositionerCustom extends TwoAxisPositioner {
  /**
   * Represents a two-axis positioner component of the printer. This object contains
   * the meshes and parameters required to simulate this object in the scene.
   * @constructor
   * @param {Object} printerSettings - serialized printer response from the backend
   */
  constructor(printerSettings, printingBedDefinitions, plinthDefinitions) {
    super(
      printerSettings,
      TwoAxisPositionerCustom.getMachineDefaults(),
      printingBedDefinitions,
      plinthDefinitions,
    );
  }

  getBedType(printerSettings) {
    const bedDefinitionResponse = getBedDefinitionResponse(printerSettings);
    return bedTypeDefinitions[bedDefinitionResponse.bedType];
  }

  getAxis0Scale() {
    return 1;
  }

  static getMachineDefaults() {
    return {
      bedMovement: false,

      bedOffsets: { x: 0, y: 0, z: 0 },
    };
  }

  getOffset() {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );

    const { d1, a2, d3 } = bedDefinitionResponse;

    return {
      x: 0,
      y: 0,
      z: -d1 + a2 - d3,
    };
  }

  getConfiguration() {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );

    const { a1, a2, d1, d2, d3, joint1IsInverted, joint2IsInverted } =
      bedDefinitionResponse;

    const frame1Rotation = joint1IsInverted
      ? new THREE.Euler(0, -Math.PI / 2, Math.PI, 'XYZ')
      : new THREE.Euler(0, Math.PI / 2, 0, 'XYZ');

    let frame2Rotation;
    if (joint1IsInverted && joint2IsInverted) {
      frame2Rotation = new THREE.Euler(0, Math.PI / 2, 0, 'XYZ');
    } else if (joint1IsInverted) {
      frame2Rotation = new THREE.Euler(0, -Math.PI / 2, Math.PI, 'XYZ');
    } else if (joint2IsInverted) {
      frame2Rotation = new THREE.Euler(0, Math.PI / 2, Math.PI, 'XYZ');
    } else {
      frame2Rotation = new THREE.Euler(0, -Math.PI / 2, 0, 'XYZ');
    }
    const frame3Rotation = joint2IsInverted
      ? new THREE.Euler(Math.PI, 0, 0, 'XYZ')
      : new THREE.Euler(0, 0, 0, 'XYZ');

    return [
      {
        x: a1,
        y: 0,
        z: d1,
        rotationParts: frame1Rotation,
      },
      {
        x: a2,
        y: 0,
        z: joint1IsInverted ? -d2 : d2,
        rotationParts: frame2Rotation,
      },
      {
        x: 0,
        y: 0,
        z: joint2IsInverted ? -d3 : d3,
        rotationParts: frame3Rotation,
      },
    ];
  }

  /**

     * Asynchronously loads the models required for visualization of the external

     * axis and adds them to the individual axes groups.

     * @returns resolved promise on completion of loading

     */

  initializeModels() {
    return new Promise((resolve) => {
      const bedDefinitionResponse = getBedDefinitionResponse(
        this.printerSettings,
      );

      if (!bedDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = bedDefinitionResponse;

      const modelPromises = []; // Array to hold all the promises for the robot models

      const baseUrl = modelUrls[s3Directories.bedBase];
      const axis1Url = modelUrls[s3Directories.bedAxis1];
      const axis2Url = modelUrls[s3Directories.bedAxis2];

      modelPromises.push(Printer.getModel(baseUrl));
      modelPromises.push(Printer.getModel(axis1Url));
      modelPromises.push(Printer.getModel(axis2Url));

      // Wait for all models to load

      Promise.all(modelPromises).then((bedModels) => {
        for (let axisNumber = 0; axisNumber <= 2; axisNumber++) {
          const model = bedModels[axisNumber];

          this.addCustomAxis(model, axisNumber, bedDefinitionResponse);
        }

        resolve();
      });
    });
  }

  addCustomAxis(model, axisNumber, bedDefinitionResponse) {
    const { joint1IsInverted, joint2IsInverted } = bedDefinitionResponse;
    if (axisNumber == 1) {
      if (joint1IsInverted) {
        model.rotateZ(Math.PI);
        model.rotateY(Math.PI / 2);
      } else {
        model.rotateY(-Math.PI / 2);
      }
      model.children.forEach((child) => {
        child.position.z = -bedDefinitionResponse.d1;
        child.position.x = -bedDefinitionResponse.a1;
      });
    }

    if (axisNumber == 2) {
      model.children.forEach((child) => {
        child.position.z = bedDefinitionResponse.a2 - bedDefinitionResponse.d1;
      });

      if (joint2IsInverted) {
        model.rotateX(Math.PI);
      }
    }

    this[printerConstants.axis + axisNumber].add(model);
  }

  moveToHome() {
    //do nothing for now
  }

  getPrintingBedSettingsType() {
    return 'TwoAxisBedSettingsDefault';
  }
}

export default TwoAxisPositionerCustom;
