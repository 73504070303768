import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Icon, Label, RouterLink } from './Link.styled';

export const LINK_VARIANT_DEFAULT = 'default';
export const LINK_VARIANT_ERROR = 'error';

const Link = ({
  children,
  dataTestId = 'link',
  disabled,
  exact,
  external,
  leadingIconName,
  onClick,
  renderAs,
  to = '',
  variant = LINK_VARIANT_DEFAULT,
}) => {
  const errorVariant = variant === LINK_VARIANT_ERROR;

  return (
    <Wrapper
      as={renderAs || external ? 'a' : RouterLink}
      data-testid={dataTestId}
      disabled={disabled}
      to={to || ''}
      href={to || ''}
      exact={exact}
      target={external ? '_blank' : undefined}
      onClick={onClick}
    >
      {leadingIconName && (
        <Icon
          dataTestId={`${dataTestId}__icon`}
          name={leadingIconName}
          errorVariant={errorVariant}
        />
      )}

      <Label dataTestId={`${dataTestId}__label`} errorVariant={errorVariant}>
        {children}
      </Label>
    </Wrapper>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  external: PropTypes.bool,
  leadingIconName: PropTypes.string,
  onClick: PropTypes.func,
  renderAs: PropTypes.string,
  to: PropTypes.string,
  variant: PropTypes.oneOf([LINK_VARIANT_DEFAULT, LINK_VARIANT_ERROR]),
};

export default Link;
