import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { omit } from 'lodash';
import { VisualizationContextProvider } from '@contexts/VisualizationContext';
import useWorkflowSocket from '@hooks/websocket/useWorkflowSocket';
import useDialog from '@hooks/useDialog';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useWorkflowForm from '@hooks/workflows/useWorkflowForm';
import useOperator from '@hooks/operators/useOperator';
import useOperatorDrag from '@hooks/operators/useOperatorDrag';
import useFile from '@hooks/files/useFile';
import useSelectedOutputDesigns from '@hooks/files/useSelectedOutputDesigns';
import { getSimulationIsActive } from '@selectors/conceptSelectors';
import { resetComputationProgressHandler } from '@actions/computationProgressActions';
import { ModalDataTypes } from '@constants/modalDataTypes';
import EditorLayout, {
  LayoutSettingBarLayer,
} from '@components/1-atoms/Layouts/EditorLayout';
import WorkspaceVisualization from '@containers/WorkspaceVisualization';
import WorkflowToolbar from '@containers/WorkflowToolbar';
import WorkflowActionBar from '@containers/WorkflowActionBar';
import WorkflowSetting from '@containers/WorkflowSetting';
import WorkflowSettingToolbar from '@containers/WorkflowSettingToolbar';
import WorkflowPrinterBar from '@containers/WorkflowPrinterBar';
import WorkflowProgressBar from '@containers/WorkflowProgressBar';
import WorkflowToolpathSimulation from '@containers/WorkflowToolpathSimulation';
import WorkflowToolpathBar from '@containers/WorkflowToolpathBar';
import WorkflowOperatorActionBar from '@containers/WorkflowOperatorActionBar';
import MessageBox from '@app/components/2-molecules/MessageBox';
import PageLoader from '@app/components/1-atoms/PageLoader';
import getIntlProvider from '@utils/getIntlProvider';
import WorkflowCanvasSelectionInputBar from '@app/components/WorkflowCanvasSelectionInputBar';
import CanvasSelection from '@app/containers/CanvasSelection';
import { selectActiveCanvasSelectionInput } from '@app/reducers/workflowSlice';
import useQuery from '@app/hooks/useQuery';
import useDownloadStreamToolpath from '@app/hooks/operators/useDownloadStreamToolpath';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Workflow = ({ defaultSettingBarCollapsed }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [settingBarCollapsed, setSettingBarCollapsed] = useState(
    defaultSettingBarCollapsed,
  );
  const simulationIsActive = useSelector(getSimulationIsActive());
  const hideWorfklowSettingToolbarButtonsRef = useRef(
    defaultSettingBarCollapsed,
  );

  useWorkflowSocket();
  useSelectedOutputDesigns();

  const form = useWorkflowForm();
  const { isDirty, isValid } = form.formState;
  const {
    updateValuesAndComputeWorkflow,
    getSelectedWorkflow,
    getIsWorkflowComputing,
    getIsWorkflowPublic,
    getIsWorkflowAwaitingComputation,
  } = useWorkflow();
  const { isDialogShown, updateDialogData, showDialog } = useDialog();
  const query = useQuery();
  const drag = useOperatorDrag();
  const {
    getIsComputationAvailable,
    getIsToolpathOperatorSelected,
    getSelectedOperator,
  } = useOperator();
  const { removeGeometriesQuery } = useFile();
  const { downloadToolpath, streamToolpath } = useDownloadStreamToolpath();

  const workflow = getSelectedWorkflow();
  const isWorkflowComputing = getIsWorkflowComputing();
  const isWorkflowPublic = getIsWorkflowPublic();
  const isWorkflowSettingBarVisible = !settingBarCollapsed;

  const isComputationAvailable = getIsComputationAvailable(form);
  const isToolpathOperatorSelected = getIsToolpathOperatorSelected();
  const isWorkflowAwaitingComputation = getIsWorkflowAwaitingComputation();
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );

  const intl = getIntlProvider();

  const isWorkflowFormNotDirtyAndValid = useMemo(
    () => !isDirty && isValid,
    [isDirty, isValid],
  );

  const EditorTopBar = useMemo(() => {
    if (activeCanvasSelectionInput) {
      return <WorkflowCanvasSelectionInputBar />;
    }
    if (isWorkflowComputing) {
      return <WorkflowProgressBar />;
    }

    if (isToolpathOperatorSelected) {
      return <WorkflowToolpathBar />;
    }

    if (getSelectedOperator()) {
      return <WorkflowOperatorActionBar />;
    }

    if (!isWorkflowPublic) {
      return <WorkflowPrinterBar />;
    }

    return null;
  }, [
    isWorkflowPublic,
    isWorkflowComputing,
    isToolpathOperatorSelected,
    getSelectedOperator,
    activeCanvasSelectionInput,
  ]);

  const EditorBottomBar = useMemo(() => {
    if (activeCanvasSelectionInput) {
      return null;
    }
    if (simulationIsActive) {
      return <WorkflowToolpathSimulation />;
    }

    return <WorkflowActionBar />;
  }, [simulationIsActive, activeCanvasSelectionInput]);

  const handleSidebarCollapse = useCallback(() => {
    setSettingBarCollapsed(!settingBarCollapsed);
  }, [settingBarCollapsed, setSettingBarCollapsed]);

  const handleOnDragOperatorStart = useCallback(
    (result) => {
      drag?.onDragStart(result);

      const isOperatorLibraryShown = isDialogShown(
        ModalDataTypes.OPERATORS_LIBRARY,
      );

      if (isOperatorLibraryShown) {
        updateDialogData(ModalDataTypes.OPERATORS_LIBRARY, {
          hideOuterWrapper: true,
        });
      }
    },
    [drag, isDialogShown, updateDialogData],
  );

  const handleOnDragOperatorEnd = useCallback(
    (result) => {
      drag?.onDragEnd(result);

      const isOperatorLibraryShown = isDialogShown(
        ModalDataTypes.OPERATORS_LIBRARY,
      );

      if (isOperatorLibraryShown) {
        updateDialogData(ModalDataTypes.OPERATORS_LIBRARY, {
          hideOuterWrapper: false,
        });
      }
    },
    [drag, isDialogShown, updateDialogData],
  );

  const computeWorkflow = useCallback(
    async (formValues) => {
      await updateValuesAndComputeWorkflow(formValues);
    },
    [updateValuesAndComputeWorkflow],
  );

  useEffect(() => {
    setSettingBarCollapsed(false);

    // Hide the toolbar buttons when the setting bar is expanding on first load
    // delay needs to be added to prevent the buttons from showing up when the setting bar is expanding
    if (hideWorfklowSettingToolbarButtonsRef.current) {
      setTimeout(() => {
        hideWorfklowSettingToolbarButtonsRef.current = false;
      }, 300);
    }
  }, []);

  useEffect(() => {
    return () => {
      removeGeometriesQuery();
      dispatch(resetComputationProgressHandler());
    };
  }, [dispatch, removeGeometriesQuery]);

  useEffect(() => {
    if (query?.get('downloadToolpathFrom')) {
      downloadToolpath(query?.get('downloadToolpathFrom'));
      query?.delete('downloadToolpathFrom');
      history.replace({ search: undefined });
    } else if (query?.get('streamToolpathFrom')) {
      streamToolpath(query?.get('streamToolpathFrom'));
      query?.delete('streamToolpathFrom');
      history.replace({ search: undefined });
    }
  }, [query, downloadToolpath, streamToolpath, history]);

  return (
    <>
      <DragDropContext
        onDragStart={handleOnDragOperatorStart}
        onDragEnd={handleOnDragOperatorEnd}
      >
        <EditorLayout
          banner={
            isWorkflowPublic ? (
              <MessageBox
                leadingIconName="info_0"
                endingButtonTitle={intl.formatMessage({
                  id: 'workflow.public_workflow.banner.button',
                  defaultMessage: 'Duplicate workflow',
                })}
                onEndingButtonClick={() => {
                  showDialog(ModalDataTypes.CREATE_FLOW, {
                    startFromSelectWorkflow: true,
                    duplicateFlow: true,
                    selectedWorkflow: omit(workflow, [
                      'printerId',
                      'materialId',
                      'nozzleId',
                    ]),
                  });
                }}
                noBorderRadius
              >
                <FormattedMessage
                  id="workflow.public_workflow.banner.title"
                  defaultMessage="This workflow example is read-only. To make changes, please duplicate it."
                />
              </MessageBox>
            ) : null
          }
          settingBarCollapsed={settingBarCollapsed}
          backgroundLayer={
            <FormProvider {...form}>
              <VisualizationContextProvider>
                {activeCanvasSelectionInput ? (
                  <CanvasSelection form={form} />
                ) : (
                  <WorkspaceVisualization />
                )}
              </VisualizationContextProvider>
            </FormProvider>
          }
          topBar={<FormProvider {...form}>{EditorTopBar}</FormProvider>}
          bottomBar={EditorBottomBar}
        >
          <WorkflowToolbar
            isWorkflowFormNotDirtyAndValid={isWorkflowFormNotDirtyAndValid}
            settingBarCollapsed={settingBarCollapsed}
            setSettingBarCollapsed={setSettingBarCollapsed}
          />

          <form onSubmit={form.handleSubmit(computeWorkflow)}>
            <LayoutSettingBarLayer visible={settingBarCollapsed}>
              <WorkflowSettingToolbar
                handleToolbarCollapse={handleSidebarCollapse}
                hideBodyIconButtons={
                  hideWorfklowSettingToolbarButtonsRef.current
                }
                hideFooterIconButtons={
                  hideWorfklowSettingToolbarButtonsRef.current
                }
                isWorkflowComputing={isWorkflowComputing}
                isComputationAvailable={isComputationAvailable}
              />
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer
              visible={isWorkflowSettingBarVisible}
              fadeOut
            >
              <FormProvider {...form}>
                <WorkflowSetting
                  drag={drag}
                  handleSettingBarCollapse={handleSidebarCollapse}
                  isWorkflowSettingBarVisible={isWorkflowSettingBarVisible}
                />
              </FormProvider>
            </LayoutSettingBarLayer>
          </form>
        </EditorLayout>
      </DragDropContext>
      <PageLoader
        show={isWorkflowAwaitingComputation}
        title={intl.formatMessage({
          id: 'pageloader.awaiting_computation',
          defaultMessage: 'Please wait. It may take up to 2 minutes',
        })}
      />
    </>
  );
};

Workflow.propTypes = {
  defaultSettingBarCollapsed: PropTypes.bool,
};

export default Workflow;
