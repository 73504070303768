import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { clone, get, isEmpty, isObject, uniqueId } from 'lodash';
import useDropDownMenu from '@hooks/useDropDownMenu';
import DropDownMenu from '@components/2-molecules/DropDownMenu';
import DropDownMenuPortal from '@components/2-molecules/DropDownMenuPortal';
import {
  FIELD_SIZE_STANDARD,
  FIELD_SIZE_COMPACT,
} from '@components/1-atoms/Field';
import { Field } from './DropDownField.styled';

export const DROP_DOWN_FIELD_SIZE_STANDARD = FIELD_SIZE_STANDARD;
export const DROP_DOWN_FIELD_SIZE_COMPACT = FIELD_SIZE_COMPACT;

const DropDownField = ({
  className,
  dataTestId = 'drop-down-field',
  defaultValue,
  diffAdded,
  diffModified,
  diffRemoved,
  dirty,
  disabled,
  dropDownMenuItems,
  dropDownMenuPosition,
  dropDownMenuScrolledToBottom,
  emptyDropDownStateDescription,
  emptyDropDownStateIconName,
  emptyDropDownStateLinkButtonLabel,
  emptyDropDownStateLinkButtonPath,
  emptyDropDownStateOnLinkButtonClick,
  emptyDropDownStateTitle,
  error,
  field: formikField = {}, // Formik field props. Is available when component is used within a Formik.Field component
  form: formikForm = {}, // Formik field props. Is available when component is used within a Formik.Field component
  fullWidthDropDownMenu = true,
  id,
  label,
  leadingIconName,
  name,
  onBlur,
  onClick = () => {},
  onFocus,
  placeholder,
  resetFormFieldsOnSelect = [],
  size,
  supportingText,
  transparentWhenDisabled,
  value,
}) => {
  const { showDropDownMenu, dropDownMenuIsOpen, dropDownMenuId } =
    useDropDownMenu();
  const withDropDownMenu = !!dropDownMenuItems;
  const generateDropDownMenuId = useRef(uniqueId('drop-down-field-menu-'));
  const fieldInputRef = useRef(null);

  const shouldResetFieldErrorWhileOpen =
    dropDownMenuIsOpen &&
    dropDownMenuId === generateDropDownMenuId.current &&
    !isEmpty(formikForm);

  const modifiedFormikField = clone(formikField);

  if (!isEmpty(formikField)) {
    modifiedFormikField.onChange = () => {};
    modifiedFormikField.value = isObject(formikForm)
      ? get(formikForm?.values, formikField?.name)?.label || ''
      : value || '';
  }

  const dropDownIconName = useMemo(() => {
    if (dropDownMenuIsOpen) {
      return size === DROP_DOWN_FIELD_SIZE_COMPACT
        ? 'arrow_drop_down_1'
        : 'expand_less_0';
    }

    return size === DROP_DOWN_FIELD_SIZE_COMPACT
      ? 'arrow_drop_down_1'
      : 'expand_more_0';
  }, [size, dropDownMenuIsOpen]);

  const handleFieldClick = useCallback(
    (e) => {
      onClick?.(e);

      if (withDropDownMenu) {
        showDropDownMenu?.(generateDropDownMenuId.current, e.currentTarget, {
          fullWidth: fullWidthDropDownMenu,
          position: dropDownMenuPosition,
        });
      }
    },
    [
      fullWidthDropDownMenu,
      dropDownMenuPosition,
      withDropDownMenu,
      showDropDownMenu,
      onClick,
    ],
  );

  const handleOnFormSelect = useCallback(
    (formFieldValue) => {
      formikForm?.setFieldValue?.(formikField?.name, formFieldValue);

      resetFormFieldsOnSelect?.forEach((fieldName) => {
        if (formikForm?.values?.[fieldName]) {
          formikForm?.setFieldValue?.(fieldName, null);
        }
      });
    },
    [formikForm, formikField, resetFormFieldsOnSelect],
  );

  return (
    <>
      <Field
        ref={fieldInputRef}
        className={className}
        dataTestId={dataTestId}
        name={name}
        diffAdded={diffAdded}
        diffModified={diffModified}
        diffRemoved={diffRemoved}
        dirty={dirty}
        disabled={disabled}
        endingButtonIconName={dropDownIconName}
        error={error}
        id={id}
        label={label}
        leadingIconName={leadingIconName}
        onFocus={onFocus}
        onBlur={onBlur}
        onOverlayClick={handleFieldClick}
        placeholder={placeholder}
        size={size}
        supportingText={supportingText}
        transparentWhenDisabled={transparentWhenDisabled}
        defaultValue={defaultValue}
        value={value}
        field={modifiedFormikField}
        form={formikForm}
        ignoreFormikError={shouldResetFieldErrorWhileOpen}
      />

      {withDropDownMenu && (
        <DropDownMenuPortal
          menuId={generateDropDownMenuId.current}
          menuItems={dropDownMenuItems}
          menuScrollToBottom={dropDownMenuScrolledToBottom}
          onFormSelect={handleOnFormSelect}
          emptyStateIconName={emptyDropDownStateIconName}
          emptyStateLinkButtonLabel={emptyDropDownStateLinkButtonLabel}
          emptyStateLinkButtonPath={emptyDropDownStateLinkButtonPath}
          emptyStateOnLinkButtonClick={emptyDropDownStateOnLinkButtonClick}
          emptyStateTitle={emptyDropDownStateTitle}
          emptyStateDescription={emptyDropDownStateDescription}
        />
      )}
    </>
  );
};

DropDownField.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  name: PropTypes.string,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  leadingIconName: PropTypes.string,
  dropDownMenuItems: DropDownMenu.propTypes.menuItems,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  resetFormFieldsOnSelect: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf([
    DROP_DOWN_FIELD_SIZE_STANDARD,
    DROP_DOWN_FIELD_SIZE_COMPACT,
  ]),
  supportingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  transparentWhenDisabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    error: PropTypes.object,
    touched: PropTypes.object,
  }),
  fullWidthDropDownMenu: PropTypes.bool,
  dropDownMenuPosition: PropTypes.string,
  dropDownMenuScrolledToBottom: PropTypes.bool,
  emptyDropDownStateIconName: PropTypes.string,
  emptyDropDownStateLinkButtonLabel: PropTypes.string,
  emptyDropDownStateLinkButtonPath: PropTypes.string,
  emptyDropDownStateOnLinkButtonClick: PropTypes.func,
  emptyDropDownStateTitle: PropTypes.string,
  emptyDropDownStateDescription: PropTypes.string,
};

export default DropDownField;
