import styled from 'styled-components';

export const BottomLayerElements = styled.div`
  position: relative;
  z-index: -999;
`;

export const TopLayerElements = styled.div`
  position: relative;
  z-index: 3;
`;
