import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import usePrinter from '@hooks/printers/usePrinter';
import { getPrinterWithModels } from '../../utils/model';
import { Visualization } from './Visualization.js.jsx';

export const SpaceComponent = ({ selectedPrinter, children }) => {
  const [gridSize, setGridSize] = useState(100);
  const [printer, setPrinter] = useState(null);

  const { getAllPrinterDefinitions } = usePrinter();
  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  } = getAllPrinterDefinitions();

  useEffect(() => {
    getPrinterWithModels(
      selectedPrinter,
      machineDefinitions,
      extruderDefinitions,
      printingBedDefinitions,
      plinthDefinitions,
      enclosureDefinitions,
    ).then((printer) => {
      setPrinter(printer);
    });
    setGridSize(100);
  }, [
    selectedPrinter,
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
  ]);

  return (
    <Visualization
      showGrid
      gridSizeX={gridSize}
      cameraX={0}
      cameraY={-300}
      cameraZ={300}
      onlyRenderOnChange={false}
      printer={printer}
    >
      {children}
    </Visualization>
  );
};

SpaceComponent.defaultProps = {
  selectedPrinter: null,
  selectedWorkspace: null,
};

SpaceComponent.propTypes = {
  selectedPrinter: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  const { selectedPrinterId } = state.printers.ui;
  const selectedPrinter = state.printers.data.printers.find(
    ({ id }) => id === selectedPrinterId,
  );

  return { selectedPrinter };
};

export const Space = connect(mapStateToProps)(SpaceComponent);
