import actionTypes from '@actions';

const initialState = {
  currentVersion: '',
};

const versionReducer = (state = initialState, action) => {
  if (action.type === actionTypes.FETCH_VERSION_SUCCESS) {
    return {
      ...state,
      currentVersion: action.payload.version,
    };
  }
  return state;
};

export default versionReducer;
