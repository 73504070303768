import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { postProcessorGCodeDefinitions } from '@constants/printers/postProcessorSettingsDefinitions';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import PropTypes from 'prop-types';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';

const PostProcessorGCode = ({ isSubmitting }) => {
  const intl = useIntl();
  const { values, setFieldValue, initialValues } = useFormikContext();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const options = getFormattedOptions(postProcessorGCodeDefinitions);

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: 'printers.postprocessor.gcode.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-gCodeAction`}
        name={PostProcessorSettingsName.gCodeAction}
        dropDownMenuItems={options}
        disabled={isSubmitting}
      />
      <FormikField
        component={SettingCheckbox}
        dataTestId={`printer-custom-post-processor__setting-useJointPositions`}
        checkbox={{
          checked: Boolean(values[PostProcessorSettingsName.useJointPositions]),
          onChange: () => {
            setFieldValue(
              PostProcessorSettingsName.useJointPositions,
              !values[PostProcessorSettingsName.useJointPositions],
            );
          },
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            PostProcessorSettingsName.useJointPositions,
          ),
        }}
        name={PostProcessorSettingsName.useJointPositions}
        label={intl.formatMessage({
          id: 'printers.postprocessor.gcode.joints.label',
          defaultMessage: 'Use Joint Positions',
        })}
      />
    </>
  );
};

PostProcessorGCode.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorGCode;
