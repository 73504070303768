import endpoints from '@api/endpoints';
import client from '@api/client';
import { extractErrorMessage } from '@utils/requestUtils';
import { showErrorDialog } from '@actions/errorActions';

import getIntlProvider from '@app/utils/getIntlProvider';
import { put, takeEvery } from 'redux-saga/effects';
import {
  setCreateWorkflowAnnotationFailure,
  setCreateWorkflowAnnotationSuccess,
  setDeleteWorkflowAnnotationFailure,
  setDeleteWorkflowAnnotationSuccess,
  setFetchWorkflowAnnotationsFailure,
  setFetchWorkflowAnnotationsSuccess,
  setUpdateWorkflowAnnotationFailure,
  setUpdateWorkflowAnnotationSuccess,
} from '@actions/workflowAnnotationActions';
import actionTypes from '@app/actions';

const intl = getIntlProvider();

export function* createWorkflowAnnotation({ payload }) {
  const { createAnnotation, successCallback } = payload;
  const requestUrl = endpoints.workflowAnnotation;

  try {
    const response = yield client.post(requestUrl, createAnnotation);
    const workflowAnnotation = response?.data || {};

    yield put(setCreateWorkflowAnnotationSuccess(workflowAnnotation));
    successCallback?.();
  } catch (error) {
    const errorMessage =
      extractErrorMessage(error) ??
      intl.formatMessage({
        id: 'annotation.create.error_dialog',
        defaultMessage: 'Error while trying to create annotation',
      });

    yield put(showErrorDialog('Error', errorMessage));
    yield put(setCreateWorkflowAnnotationFailure());
  }
}

export function* fetchWorkflowAnnotations({ payload }) {
  const requestUrl = `${endpoints.workflowAnnotation}/workflow/${payload}`;

  try {
    const response = yield client.get(requestUrl);
    const workflowAnnotations = response?.data || [];

    yield put(setFetchWorkflowAnnotationsSuccess(workflowAnnotations));
  } catch (error) {
    const errorMessage =
      extractErrorMessage(error) ??
      intl.formatMessage({
        id: 'annotation.fetch.error_dialog',
        defaultMessage: 'Error while trying to fetch annotations',
      });

    yield put(showErrorDialog('Error', errorMessage));
    yield put(setFetchWorkflowAnnotationsFailure());
  }
}

export function* updateWorkflowAnnotation({ payload }) {
  const { id, update, successCallback } = payload;
  const requestUrl = `${endpoints.workflowAnnotation}/${id}`;

  try {
    yield client.put(requestUrl, update);

    yield put(setUpdateWorkflowAnnotationSuccess(id, update));
    successCallback?.();
  } catch (error) {
    const errorMessage =
      extractErrorMessage(error) ??
      intl.formatMessage({
        id: 'annotation.update.error_dialog',
        defaultMessage: 'Error while trying to update annotation',
      });

    yield put(showErrorDialog('Error', errorMessage));
    yield put(setUpdateWorkflowAnnotationFailure());
  }
}

export function* deleteWorkflowAnnotation({ payload }) {
  const { id, workflowId } = payload;
  const requestUrl = `${endpoints.workflowAnnotation}/${id}`;

  try {
    yield client.delete(requestUrl);
    yield put(setDeleteWorkflowAnnotationSuccess(id, workflowId));
  } catch (error) {
    const errorMessage =
      extractErrorMessage(error) ??
      intl.formatMessage({
        id: 'annotation.delete.error_dialog',
        defaultMessage: 'Error while trying to delete annotation',
      });

    yield put(showErrorDialog('Error', errorMessage));
    yield put(setDeleteWorkflowAnnotationFailure());
  }
}

export default [
  takeEvery(
    actionTypes.CREATE_WORKFLOW_ANNOTATION_REQUEST,
    createWorkflowAnnotation,
  ),
  takeEvery(
    actionTypes.FETCH_WORKFLOW_ANNOTATIONS_REQUEST,
    fetchWorkflowAnnotations,
  ),
  takeEvery(
    actionTypes.UPDATE_WORKFLOW_ANNOTATION_REQUEST,
    updateWorkflowAnnotation,
  ),
  takeEvery(
    actionTypes.DELETE_WORKFLOW_ANNOTATION_REQUEST,
    deleteWorkflowAnnotation,
  ),
];
