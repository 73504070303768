import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import Button from '@components/1-atoms/Button';
import RulesMatcher from '@components/1-atoms/RulesMatcher';
import { Wrapper, Fields } from './LoginResetPassword.styled';
import { passwordValidationRules } from '@utils/validationHelper';

const LoginResetPassword = ({
  className,
  confirmNewPassword,
  confirmNewPasswordError,
  confirmNewPasswordErrorMessage,
  confirmNewPasswordTouched,
  dataTestId = 'login-page-reset-password',
  handleFieldBlur,
  handleFieldChange,
  isSubmitting,
  newPassword,
  newPasswordError,
  newPasswordErrorMessage,
  newPasswordTouched,
  resetPasswordButtonLabel,
}) => {
  const intl = useIntl();
  const [revealNewPassword, setRevealNewPassword] = useState(false);
  const [revealConfirmNewPassword, setRevealConfirmNewPassword] =
    useState(false);

  const showNewPasswordErrorMessage = newPasswordTouched && newPasswordError;
  const showConfirmNewPasswordErrorMessage =
    confirmNewPasswordTouched && confirmNewPasswordError;

  const passwordRules = useMemo(() => {
    return (newPassword) => passwordValidationRules(intl, newPassword);
  }, [intl]);

  const onPasswordRevealClick = useCallback(
    (setter) => () => {
      setter?.((currentValue) => !currentValue);
    },
    [],
  );

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.resetpassword_newpassword.newpassword.title',
          defaultMessage: 'Create new password',
        })}
      />

      <Fields>
        <Field
          type={revealNewPassword ? 'text' : 'password'}
          dataTestId={`${dataTestId}__new-password-field`}
          name="newPassword"
          label={intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.newpassword.label',
            defaultMessage: 'New password',
          })}
          value={newPassword}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          endingButtonIconName={
            revealNewPassword ? 'visibility_on_1' : 'visibility_off_0'
          }
          onEndingButtonClick={onPasswordRevealClick(setRevealNewPassword)}
          error={showNewPasswordErrorMessage}
          supportingText={
            showNewPasswordErrorMessage && newPasswordErrorMessage
          }
        />

        <RulesMatcher
          dataTestId={`${dataTestId}__password-rules-matcher`}
          rules={passwordRules(newPassword)}
          successMessages={[
            intl.formatMessage({
              id: 'loginpage.resetpassword_newpassword.newpassword.rules.success',
              defaultMessage: 'Your password is secure',
            }),
          ]}
        />

        <Field
          type={revealConfirmNewPassword ? 'text' : 'password'}
          dataTestId={`${dataTestId}__confirm-new-password-field`}
          name="confirmNewPassword"
          label={intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.confirmnewpassword.label',
            defaultMessage: 'Confirm password',
          })}
          value={confirmNewPassword}
          onChange={handleFieldChange}
          onBlur={handleFieldBlur}
          endingButtonIconName={
            revealConfirmNewPassword ? 'visibility_on_1' : 'visibility_off_0'
          }
          onEndingButtonClick={onPasswordRevealClick(
            setRevealConfirmNewPassword,
          )}
          error={showConfirmNewPasswordErrorMessage}
          supportingText={
            showConfirmNewPasswordErrorMessage && confirmNewPasswordErrorMessage
          }
        />
      </Fields>

      {resetPasswordButtonLabel && (
        <Button
          dataTestId={`${dataTestId}__submit-button`}
          type="submit"
          loading={isSubmitting}
        >
          {resetPasswordButtonLabel}
        </Button>
      )}
    </Wrapper>
  );
};

LoginResetPassword.propTypes = {
  className: PropTypes.string,
  confirmNewPassword: PropTypes.string,
  confirmNewPasswordError: PropTypes.bool,
  confirmNewPasswordErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  confirmNewPasswordTouched: PropTypes.bool,
  dataTestId: PropTypes.string,
  handleFieldBlur: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  newPassword: PropTypes.string,
  newPasswordError: PropTypes.bool,
  newPasswordErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  newPasswordTouched: PropTypes.bool,
  resetPasswordButtonLabel: PropTypes.string,
};

export default LoginResetPassword;
