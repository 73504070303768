import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import MessageBox, {
  MESSAGE_BOX_VARIANT_SNACKBAR,
} from '@components/2-molecules/MessageBox';
import { Wrapper } from './Snackbar.styled';

export const SNACKBAR_ID = 'tooltip';
export const SNACKBAR_POSITION_TOP_LEFT = 'top left';
export const SNACKBAR_POSITION_TOP_CENTER = 'top center';
export const SNACKBAR_POSITION_TOP_RIGHT = 'top right';
export const SNACKBAR_POSITION_BOTTOM_LEFT = 'bottom left';
export const SNACKBAR_POSITION_BOTTOM_CENTER = 'bottom center';
export const SNACKBAR_POSITION_BOTTOM_RIGHT = 'bottom right';

const Snackbar = ({
  endingButtonTitle,
  endingIconButtonIconName = 'close_0',
  iconName,
  id,
  onEndingButtonClick,
  onEndingIconButtonClick,
  onMouseEnter,
  onMouseLeave,
  position = SNACKBAR_POSITION_BOTTOM_LEFT,
  show,
  text,
  variant = MESSAGE_BOX_VARIANT_SNACKBAR,
}) => {
  const wrapperRef = useRef();
  const topLeft = position === SNACKBAR_POSITION_TOP_LEFT;
  const topCenter = position === SNACKBAR_POSITION_TOP_CENTER;
  const topRight = position === SNACKBAR_POSITION_TOP_RIGHT;
  const bottomLeft = position === SNACKBAR_POSITION_BOTTOM_LEFT;
  const bottomCenter = position === SNACKBAR_POSITION_BOTTOM_CENTER;
  const bottomRight = position === SNACKBAR_POSITION_BOTTOM_RIGHT;

  return (
    <Wrapper
      bottomCenter={bottomCenter}
      bottomLeft={bottomLeft}
      bottomRight={bottomRight}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      position={position}
      ref={wrapperRef}
      show={show}
      topCenter={topCenter}
      topLeft={topLeft}
      topRight={topRight}
    >
      <MessageBox
        endingIconButtonIconName={endingIconButtonIconName}
        endingButtonTitle={endingButtonTitle}
        leadingIconName={iconName}
        onEndingButtonClick={onEndingButtonClick}
        onEndingIconButtonClick={onEndingIconButtonClick}
        variant={variant}
      >
        {text}
      </MessageBox>
    </Wrapper>
  );
};

Snackbar.propTypes = {
  endingIconButtonIconName: PropTypes.string,
  endingButtonTitle: PropTypes.string,
  iconName: PropTypes.string,
  id: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onEndingButtonClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  position: PropTypes.oneOf([
    SNACKBAR_POSITION_TOP_LEFT,
    SNACKBAR_POSITION_TOP_CENTER,
    SNACKBAR_POSITION_TOP_RIGHT,
    SNACKBAR_POSITION_BOTTOM_LEFT,
    SNACKBAR_POSITION_BOTTOM_CENTER,
    SNACKBAR_POSITION_BOTTOM_RIGHT,
  ]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  show: PropTypes.bool,
};

export default Snackbar;
