import React, { useCallback, useMemo } from 'react';
import PageList from '@components/3-organisms/PageList';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { UserRole, Wrapper } from '@pages/Users/Users.styled';
import useOrganizationList from '@hooks/organization/useOrganizationList';
import useOrganizationQueries from '@hooks/organization/useOrganizationQueries';
import moment from 'moment/moment';
import { getCurrentUser } from '@selectors/loginSelectors';
import { useSelector } from 'react-redux';
import { UserRoles } from '@constants/userRoles';
import { SETTING_TABS } from '@constants/settingTabs';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import SettingsTabs from '@components/SettingsTabs';

const Users = () => {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const currentUser = useSelector(getCurrentUser());
  const { getOrganizationDropDownMenuActions } = useOrganizationList();
  const { usersQuery } = useOrganizationQueries();

  const renderUserRoleAndExpiryDate = useMemo(() => {
    return (user, intl) =>
      `${user.role}
      ${
        user.expiryDate
          ? ` • ${intl.formatMessage({
              id: 'settings.users_list.expires.on.label',
              defaultMessage: 'Expires on',
            })}: ${moment(user.expiryDate).format('DD/MM/YY')}`
          : ''
      }`;
  }, []);

  const usersListItems = useMemo(
    () =>
      usersQuery.data?.map((user) => ({
        id: user.id,
        label: user.name,
        expiryDate: user.expiryDate
          ? new Date(user.expiryDate).toISOString()
          : new Date(0).toISOString(),
        username: user.username,
        description: (
          <>
            {user.username} <br />
            <UserRole>{renderUserRoleAndExpiryDate(user, intl)}</UserRole>
          </>
        ),
        leadingIconName: 'person_0',
        endingIconButtonIconName: 'more_vert_0',
        endingIconButtonDropDownProps: {
          dropDownMenuItems: getOrganizationDropDownMenuActions(user),
        },
        lastUpdate: user.lastUpdate
          ? new Date(user.lastUpdate).toISOString()
          : new Date(0).toISOString(),
        role: user.role,
      })),
    [
      intl,
      renderUserRoleAndExpiryDate,
      usersQuery.data,
      getOrganizationDropDownMenuActions,
    ],
  );

  const showAddUserDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_USER);
  }, [showDialog]);

  const actionChips = useMemo(() => {
    const chips = [];

    if (currentUser?.role !== UserRoles.SUPER_ADMIN) {
      return chips;
    }

    chips.push({
      id: 'users-page-add-user-chip',
      variant: CHIP_VARIANT_PRIMARY,
      dataTestId: 'users-page__create-add-user-chip',
      label: intl.formatMessage({
        id: 'settings.users_list.add_user.button',
        defaultMessage: 'Add User',
      }),
      leadingIconName: 'add_0',
      onClick: showAddUserDialog,
    });

    return chips;
  }, [intl, currentUser, showAddUserDialog]);

  const createSortOptions = useMemo(() => {
    return (intl, options) => {
      return options.map(({ id, defaultMessage, value }) => ({
        label: intl.formatMessage({
          id,
          defaultMessage,
        }),
        value,
      }));
    };
  }, []);

  const sortOptionsConfig = useMemo(
    () => [
      {
        id: 'organization.label.lastupdate',
        defaultMessage: 'Last Update',
        value: 'lastUpdate',
      },
      {
        id: 'general.name',
        defaultMessage: 'Name',
        value: 'label',
      },
      {
        id: 'organization.label.role',
        defaultMessage: 'Role',
        value: 'role',
      },
      {
        id: 'settings.users_list.sort.email.label',
        defaultMessage: 'Email',
        value: 'username',
      },
      {
        id: 'organization.label.expirationdate',
        defaultMessage: 'Expiration date',
        value: 'expiryDate',
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <PageHeader
        dataTestId={`users-list__header`}
        size={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'navigation.link.settings',
          defaultMessage: 'Settings',
        })}
      />
      <SettingsTabs activeTab={SETTING_TABS.USERS.title} />
      <PageList
        dataTestId={'users-list'}
        listItems={usersListItems}
        loading={!usersQuery.isFetched || usersQuery.isLoading}
        createItemMessageIconName="person_0"
        createItemMessageTitle={intl.formatMessage({
          id: 'settings.users_list.add_user.title',
          defaultMessage: 'No user yet',
        })}
        createItemMessagDescription={intl.formatMessage({
          id: 'settings.users_list.add_user.description',
          defaultMessage: 'Manage the setup of your users',
        })}
        createItemMessageButtonLabel={intl.formatMessage({
          id: 'settings.users_list.add_user.button',
          defaultMessage: 'Add User',
        })}
        createItemMessageButtonIconName="add_0"
        createItemMessageOnButtonClick={showAddUserDialog}
        actionChips={actionChips}
        sortOptions={createSortOptions(intl, sortOptionsConfig)}
      />
    </Wrapper>
  );
};

Users.propTypes = {};

export default Users;
