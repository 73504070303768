import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import usePrinterMutations from '@hooks/printers/usePrinterMutations';
import {
  getPrinterStatus,
  getPrintProgressFromPrinter,
} from '@selectors/printerSelectors';
import { isPrinterOnline as getIsPrinterOnline } from '@utils/isPrinterOnline';
import { PrinterStatuses } from '@constants/printerStatuses';

export default function usePrintingStatus(printerId) {
  const intl = useIntl();
  const { stopPrintMutation } = usePrinterMutations();
  const printProgress = useSelector(getPrintProgressFromPrinter(printerId));
  const printerStatus = useSelector(getPrinterStatus(printerId)) || {
    printerId,
    status: PrinterStatuses.IDLE,
    time: 0,
  };

  const isPrinterOnline = getIsPrinterOnline(printerStatus.time);
  const isPrinting = printerStatus.status === PrinterStatuses.PRINTING;
  const isPrinterNotConnected = !isPrinterOnline;

  const printingProgress = useMemo(() => {
    if (!printProgress) return 0;

    const progress =
      (printProgress.instructionsComplete /
        (printProgress.instructionsComplete +
          printProgress.instructionsRemaining)) *
      100.0;

    return Number(progress.toFixed(2));
  }, [printProgress]);
  const formattedPrintingProgress = `${printingProgress}%`;

  const printerStatusColor = useMemo(() => {
    if (isPrinterNotConnected) {
      return 'error';
    }

    if (isPrinting) {
      return 'success';
    }

    return 'caution';
  }, [isPrinterNotConnected, isPrinting]);

  const formattedPrinterStatusName = useMemo(() => {
    if (isPrinterNotConnected) {
      return intl.formatMessage({
        id: 'printer.status.not_connected',
        defaultMessage: 'Not connected',
      });
    }

    const statusName = printerStatus.status.toLowerCase();
    const formattedStatus =
      statusName.charAt(0).toUpperCase() + statusName.slice(1);

    return formattedStatus;
  }, [intl, isPrinterNotConnected, printerStatus.status]);

  const stopPrint = useCallback(() => {
    stopPrintMutation.mutate({
      printerId,
      printId: printProgress?.printId,
    });
  }, [printerId, printProgress?.printId, stopPrintMutation]);

  return {
    printerStatusColor,
    formattedPrinterStatusName,
    formattedPrintingProgress,
    isIdle: printerStatus.status === PrinterStatuses.IDLE,
    isPrinterOnline,
    isPrinterNotConnected,
    isPrinting,
    printerStatus,
    printerStatusName: printerStatus.status,
    printId: printProgress?.printId,
    printingProgress,
    printProgress,
    stopPrint,
  };
}
