import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScreenSize } from '@selectors/applicationSelectors';
import { setScreenSize } from '@actions/applicationActions';
import { SCREEN_SIZE } from '@stylesheets/screen';
import breakpoints from '@stylesheets/breakpoints';

const useScreenSizeObserver = () => {
  const dispatch = useDispatch();
  const screenSize = useSelector(getScreenSize);

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    let size = '';

    if (width < breakpoints[SCREEN_SIZE.MEDIUM]) {
      size = SCREEN_SIZE.SMALL;
    } else if (width < breakpoints[SCREEN_SIZE.LARGE]) {
      size = SCREEN_SIZE.MEDIUM;
    } else if (width < breakpoints[SCREEN_SIZE.XLARGE]) {
      size = SCREEN_SIZE.LARGE;
    } else {
      size = SCREEN_SIZE.XLARGE;
    }

    if (size === screenSize) {
      return;
    }

    dispatch(setScreenSize(size));
  }, [dispatch, screenSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the initial screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};

export default useScreenSizeObserver;
