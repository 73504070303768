export const bedTypeDefinitions = {
  STATIC_BED: {
    value: 'STATIC_BED',
    label: 'Static',
  },
  ROTARY_BED: {
    value: 'ROTARY_BED',
    label: 'Rotary',
  },
  TWO_AXIS_BED: {
    value: 'TWO_AXIS_BED',
    label: 'Two Axis',
  },
};

export const defaultStaticProperties = {
  width: '',
  length: '',
};

export const defaultRotaryProperties = {
  d1: '',
  d2: '',
  maxSpeed: '',
};

export const defaultTwoAxisProperties = {
  a1: '',
  a2: '',
  d1: '',
  d2: '',
  d3: '',
  joint1MaxSpeed: '',
  joint2MaxSpeed: '',
  joint1IsInverted: false,
  joint2IsInverted: false,
};
