import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { materialsQueryKeys } from '@hooks/materials/useMaterialQueries';

export const materialMutationKeys = {
  deleteMaterial: ['showLoader', 'deleteMaterial'],
  updateMaterial: ['updateMaterial'],
  createMaterial: ['showLoader', 'createMaterial'],
  cloneMaterial: ['showLoader', 'cloneMaterial'],
};

export default function useMaterialMutations() {
  const queryClient = useQueryClient();

  const createMaterialMutation = useMutation({
    mutationKey: materialMutationKeys.createMaterial,
    mutationFn: (material = {}) =>
      client.post(`${endpoints.materials}`, material).then((res) => res.data),
    onSuccess: (addedMaterial) => {
      const updater = (data) => {
        return data ? [...data, addedMaterial] : [addedMaterial];
      };
      queryClient.setQueryData(materialsQueryKeys.materials, updater);
    },
  });

  const updateMaterialMutation = useMutation({
    mutationKey: materialMutationKeys.updateMaterial,
    mutationFn: ({ material } = {}) =>
      client
        .put(endpoints.material.replace(':materialId', material.id), material)
        .then((res) => res.data),
    onSuccess: (_, { material }) => {
      const updater = (data) => {
        return data
          ? data.map((alreadyPresentMaterial) =>
              alreadyPresentMaterial.id === material.id
                ? {
                    ...alreadyPresentMaterial,
                    ...material,
                  }
                : alreadyPresentMaterial,
            )
          : undefined;
      };
      queryClient.setQueryData(materialsQueryKeys.materials, updater);
    },
  });

  const cloneMaterialMutation = useMutation({
    mutationKey: materialMutationKeys.createMaterial,
    mutationFn: ({ material, id } = {}) =>
      client
        .put(endpoints.cloneMaterial.replace(':materialId', id), material)
        .then((res) => res.data),
    onSuccess: (addedMaterial) => {
      const updater = (data) => {
        return data ? [...data, addedMaterial] : [addedMaterial];
      };
      queryClient.setQueryData(materialsQueryKeys.materials, updater);
    },
  });

  const deleteMaterialMutation = useMutation({
    mutationKey: materialMutationKeys.deleteMaterial,
    mutationFn: ({ id } = {}) =>
      client
        .delete(endpoints.material.replace(':materialId', id))
        .then((res) => res.data),
    onSuccess: (_, { id }) => {
      const updater = (data) => {
        return data ? data.filter((material) => material.id !== id) : [];
      };
      queryClient.setQueryData(materialsQueryKeys.materials, updater);
    },
  });

  return {
    createMaterialMutation,
    cloneMaterialMutation,
    updateMaterialMutation,
    deleteMaterialMutation,
  };
}
