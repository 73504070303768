import React from 'react';
import useModal from '@hooks/useModal';
import { ICON_BUTTON_VARIANT_PLAIN } from '@components/1-atoms/IconButton';
import ModalPortal from '@components/2-molecules/ModalPortal';
import FilePreview from '@containers/FilePreview';
import { MODAL_IDS } from '@constants/modalDataTypes';

const MODAL_ID = MODAL_IDS.FILE_PREVIEW;

export default function FilePreviewModal() {
  const { getModalData } = useModal();
  const modalData = getModalData(MODAL_ID);
  const { file } = modalData;

  return (
    <ModalPortal
      dataTestId="file-preview-modal"
      modalId={MODAL_ID}
      headerEndingIconButtonVariant={ICON_BUTTON_VARIANT_PLAIN}
      floatingHeader
      floatingFooter
      disableContentPadding
    >
      <FilePreview file={file} />
    </ModalPortal>
  );
}
