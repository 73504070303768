import { ModalDataTypes } from '@constants/modalDataTypes';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { Formik } from 'formik';
import LoginResetPassword from '@components/3-organisms/LoginResetPassword';
import React, { useCallback, useMemo } from 'react';
import useDialog from '@hooks/useDialog';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { isPasswordComplexEnough } from '@utils/validation';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';
import useOrganizationMutations from '@hooks/organization/useOrganizationMutations';
import useSnackbar from '@hooks/useSnackbar';

const MODAL_ID = ModalDataTypes.RESET_PASSWORD;

const ResetPasswordDialog = () => {
  const intl = useIntl();
  const { hideDialog } = useDialog();
  const currentUser = useSelector(getCurrentUser());
  const { showSnackbar } = useSnackbar();
  const { updateUserMutation } = useOrganizationMutations();

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    async ({ newPassword }) => {
      try {
        await updateUserMutation.mutateAsync({
          user: {
            editedUsername: currentUser.username,
            displayName: currentUser.name,
            password: newPassword,
            role: currentUser.role,
            organizationId: currentUser.organizationId,
            expiryDate: currentUser.expiryDate,
          },
          username: currentUser.username,
          id: currentUser.id,
        });
        showSnackbar({
          text: intl.formatMessage({
            id: 'loginpage.resetpassword.snackbar.label',
            defaultMessage: 'The password has been successfully reset',
          }),
        });
      } catch (_) {
        //error
      }
      hideDialog(MODAL_ID);
    },
    [intl, showSnackbar, currentUser, updateUserMutation, hideDialog],
  );

  const formInitialValues = useMemo(
    () => ({
      newPassword: '',
      confirmNewPassword: '',
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object({
      newPassword: Yup.string()
        .required(
          intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.newpassword.required',
            defaultMessage: 'New password field cannot be empty',
          }),
        )
        .test('password-complex-enough', '', (p) => isPasswordComplexEnough(p)),
      confirmNewPassword: Yup.string()
        .required(
          intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.confirmnewpassword.required',
            defaultMessage: 'Confirm password field cannot be empty',
          }),
        )
        .oneOf(
          [Yup.ref('newPassword')],
          intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.confirmnewpassword.must_match',
            defaultMessage: 'Confirm password must match new password',
          }),
        ),
    });
  }, [intl]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
      }) => (
        <DialogPortal
          renderAsForm
          dataTestId="printer-manage-visibility"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'loginpage.resetpassword_newpassword.reset_password',
            defaultMessage: 'Reset password',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <LoginResetPassword
            className="login-step"
            confirmNewPassword={values?.confirmNewPassword}
            confirmNewPasswordError={!!errors?.confirmNewPassword}
            confirmNewPasswordErrorMessage={errors?.confirmNewPassword}
            confirmNewPasswordTouched={touched?.confirmNewPassword}
            handleFieldBlur={handleBlur}
            handleFieldChange={handleChange}
            isSubmitting={isSubmitting}
            newPassword={values?.newPassword}
            newPasswordError={!!errors?.newPassword}
            newPasswordErrorMessage={errors?.newPassword}
            newPasswordTouched={touched?.newPassword}
          />
        </DialogPortal>
      )}
    </Formik>
  );
};

ResetPasswordDialog.propTypes = {};

export default ResetPasswordDialog;
