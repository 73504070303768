import { isStringBooleanIsTrue } from '@utils/dom';

export default async function enableMockServer() {
  if (!DEVELOPMENT_ENV) {
    return;
  }

  try {
    if (isStringBooleanIsTrue(process?.env?.REACT_APP_ENABLE_MOCK_SERVER)) {
      const worker = (await import('../mocks/browser')).default;

      return worker.start();
    }
  } catch (_) {
    // Error handling
  }
}
