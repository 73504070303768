const emptyArray = [];

export const getPrints =
  (printerId) =>
  ({ printers }) =>
    printers.data.prints[printerId] || emptyArray;
export const getSelectedPrint =
  () =>
  ({ printers }) =>
    printers.ui.prints.selectedPrint;

export const getIsDeletingPrint =
  () =>
  ({ printers }) =>
    printers.ui.prints.isDeletingPrint;
export const getPrintIdToDelete =
  () =>
  ({ printers }) =>
    printers.ui.prints.printIdToDelete;
export const getPrint =
  (printerId, printId) =>
  ({ printers }) =>
    (printers.data.prints[printerId] || emptyArray).find(
      (print) => print.printId === printId,
    );
