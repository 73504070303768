export const generateEvents = (events = {}) => {
  const generatedEvents = Object.keys(events).reduce((acc, eventName) => {
    if (acc[eventName]) return acc;

    const eventsList = events[eventName];

    acc[eventName] = (e) => eventsList.map((action) => action(e));

    return acc;
  }, {});

  return generatedEvents;
};

export const whenEnterButtonPressed = (cb) => (e) =>
  e && (['Enter'].includes(e.key) || ['Enter'].includes(e.code)) && cb && cb(e);

export const whenEscapeButtonPressed = (cb) => (e) =>
  e && (['Escape'].includes(e.key) || ['Escape'].includes(e.code)) && cb?.(e);

export const preventDefault = (cb) => (e) => {
  e && e.preventDefault();
  cb && cb(e);
};

export const stopPropagation = (cb) => (e) => {
  e && e.stopPropagation();
  cb && cb(e);
};

export const whenMatchPattern = (inputPattern, cb, fallbackCb) => (e) => {
  const value = e?.target?.value;

  if (!value && cb) {
    cb(e);

    return;
  }

  const regExp = RegExp(inputPattern || '');
  const validInput = value?.match(regExp);

  if (!validInput) {
    fallbackCb?.(e);

    return;
  }

  if (cb) cb(e);
};
