export const PrinterCategories = {
  PRINTER_GENERAL: 'PRINTER_GENERAL',
  PRINTER_MATERIAL: 'PRINTER_MATERIAL',
  PRINTER_ROBOT_KINEMATICS: 'PRINTER_ROBOT_KINEMATICS',
  PRINTER_ROBOT_AXIS_LIMITS: 'PRINTER_ROBOT_AXIS_LIMITS',
  PRINTER_BASE: 'PRINTER_BASE',
  PRINTER_TOOL: 'PRINTER_TOOL',
  PRINTER_WORKSPACE: 'PRINTER_WORKSPACE',
  PRINTER_ROBOT_HOME: 'PRINTER_ROBOT_HOME',
  PRINTER_TOOLPATH_SETTINGS: 'PRINTER_TOOLPATH_SETTINGS',
};

export const CategoryDisplayNames = {
  PRINTER_GENERAL: 'General Settings',
  PRINTER_ROBOT_KINEMATICS: 'Kinematics',
  PRINTER_ROBOT_HOME: 'Home Position',
  PRINTER_ROBOT_AXIS_LIMITS: 'Axis Limits',
};
