import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import client from '@api/client';
import useProject from '@hooks/projects/useProject';
import endpoints from '@api/endpoints';
import { showErrorDialog } from '@actions/errorActions';

export const fileQueryKeys = {
  projectFiles: (projectId) => ['projectFiles', projectId],
  projectFileThumbnails: (projectId) => ['projectFileThumbnails', projectId],
  file: (fileId) => ['file', fileId],
  design: (designId) => ['showLoader', 'design', designId],
  geometry: (geometryId, printerId) => [
    'showLoader',
    'geometry',
    geometryId,
    printerId,
  ],
  geometries: (geometryIds, printerId) => [
    'showLoader',
    'geometries',
    geometryIds,
    printerId,
  ],
};

export default function useFileQueries({
  projectId,
  designId,
  geometryId,
  geometryIds,
  printerId,
  useStale = false,
  onlyVisibleFiles = false,
  fetchThumbnails = false,
} = {}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const staleTime = useStale ? Infinity : undefined;
  const { getProject } = useProject();

  const projectFilesQuery = useQuery({
    queryKey: fileQueryKeys.projectFiles(projectId),
    queryFn: async () => {
      let project = getProject(projectId);

      if (!project) {
        project = await client
          .get(endpoints.workspace.replace(':workspaceId', projectId))
          .then((res) => res.data);
      }

      return client
        .get(endpoints.designs, {
          params: {
            workspace: projectId,
            organization: project?.organization,
            visible: onlyVisibleFiles ? true : undefined,
          },
        })
        .then((res) => res.data);
    },
    placeholderData: [],
    staleTime,
    enabled: !!projectId,
  });

  const projectFileThumbnailsQuery = useQuery({
    queryKey: fileQueryKeys.projectFileThumbnails(projectId),
    queryFn: () =>
      client
        .get(
          endpoints.workspaceDesignThumbnails.replace(
            ':workspaceId',
            projectId,
          ),
        )
        .then((res) => res.data),
    enabled: fetchThumbnails && !!projectId && projectFilesQuery?.isFetched,
  });

  const designQuery = useQuery({
    queryKey: fileQueryKeys.design(designId),
    queryFn: () =>
      client
        .get(endpoints.design.replace(':designId', designId))
        .then((res) => res.data),
    enabled: !!designId,
    refetchOnMount: false,
  });

  const geometryQuery = useQuery({
    queryKey: fileQueryKeys.geometry(geometryId, printerId),
    queryFn: () =>
      client
        .get(endpoints.geometry.replace(':geometryId', geometryId))
        .then((res) => res.data),
    keepPreviousData: true,
    staleTime: Infinity,
    retry: false,
    enabled: !!geometryId && !!printerId,
  });

  const geometriesQuery = useQuery({
    queryKey: fileQueryKeys.geometries(geometryIds, printerId),
    queryFn: async () => {
      try {
        const getometryRequests = geometryIds.map(
          async (geometryId) =>
            await client
              .get(endpoints.geometry.replace(':geometryId', geometryId))
              .then((res) => res.data),
        );

        const geometriesData = await Promise.all(getometryRequests);
        const serializedData = geometriesData?.reduce(
          (acc, geometry) => ({
            ...acc,
            [geometry.id]: geometry,
          }),
          {},
        );

        return serializedData;
      } catch (_) {
        dispatch(
          showErrorDialog(
            intl.formatMessage({
              id: 'fetch.operator_output.failed.title',
              defaultMessage: 'Unable to preview',
            }),
            '',
            'fetch.operator_output.failed.message',
          ),
        );
      }
    },
    initialData: {},
    enabled: !!geometryIds?.length && !!printerId,
    staleTime,
  });

  return {
    designQuery,
    projectFilesQuery,
    projectFileThumbnailsQuery,
    geometryQuery,
    geometriesQuery,
  };
}
