import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import useVersionQueries from '@hooks/version/useVersionQueries';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import {
  CURRENT_APP_VERSION_KEY,
  HIDE_WALKTHROUGH_BANNER_KEY,
} from '@constants/utilityConstants';
import { addBanner, removeBanner } from '@actions/applicationActions';
import useDialog from '@hooks/useDialog';
import { useIntl } from 'react-intl';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';

export default function useBannerNotifications() {
  const dispatch = useDispatch();
  const { computationVersionQuery, versionQuery } = useVersionQueries();
  const { showDialog } = useDialog();
  const intl = useIntl();
  const { onLearnUponLinkClick } = useLeanUpon();

  const refetchVersionQuery = computationVersionQuery.refetch;
  const refetchLatestVersionQuery = versionQuery.refetch;

  const commonBannerProps = useMemo(
    () => ({
      leadingIconName: 'info_0',
      endingIconButtonIconName: 'close_0',
    }),
    [],
  );

  useEffect(() => {
    refetchVersionQuery();
    refetchLatestVersionQuery();
  }, [refetchVersionQuery, refetchLatestVersionQuery]);

  const closeBanner = useCallback(
    (localStorageKey, localStorageData, bannerKey) => {
      addToLocalStorage(localStorageKey, localStorageData);
      dispatch(removeBanner(bannerKey));
    },
    [dispatch],
  );

  const watchWalkthroughVideo = useCallback(() => {
    showDialog(ModalDataTypes.YOUTUBE_VIDEO, {
      videoId: '10TrMvIdCfA',
    });
  }, [showDialog]);

  useEffect(() => {
    const hideWalkThroughBannerKey = getFromLocalStorage(
      HIDE_WALKTHROUGH_BANNER_KEY,
    );
    if (!hideWalkThroughBannerKey) {
      dispatch(
        addBanner({
          ...commonBannerProps,
          key: 'walkthroughBanner',
          endingButtonTitle: intl.formatMessage({
            id: 'general.banner.button.title',
            defaultMessage: 'Watch the video',
          }),
          endingButtonIconName: 'play_arrow_1',
          onEndingButtonClick: watchWalkthroughVideo,
          onEndingIconButtonClick: () =>
            closeBanner(HIDE_WALKTHROUGH_BANNER_KEY, true, 'walkthroughBanner'),
          contentText: intl.formatMessage({
            id: 'general.banner.title',
            defaultMessage:
              'Experience the next evolution of the Aibuild® platform. Watch the software demo walkthrough.',
          }),
        }),
      );
    }
  }, [dispatch, watchWalkthroughVideo, closeBanner, intl, commonBannerProps]);

  useEffect(() => {
    if (versionQuery?.data) {
      const product = versionQuery?.data;
      const releaseNoteLink = product?.releaseNoteLink;
      const version = product?.version;
      const storageVersion = getFromLocalStorage(CURRENT_APP_VERSION_KEY);
      if (
        version &&
        ((storageVersion && storageVersion !== version) || !storageVersion)
      ) {
        dispatch(
          addBanner({
            ...commonBannerProps,
            key: 'releaseNoteBanner',
            endingButtonTitle: releaseNoteLink
              ? intl.formatMessage({
                  id: 'general.release.note.banner.button.title',
                  defaultMessage: 'Learn more',
                })
              : '',
            onEndingButtonClick: () => {
              onLearnUponLinkClick(releaseNoteLink);
            },
            onEndingIconButtonClick: () => {
              closeBanner(
                CURRENT_APP_VERSION_KEY,
                version,
                'releaseNoteBanner',
              );
            },
            contentText: intl.formatMessage(
              {
                id: 'general.release.note.banner.title',
                defaultMessage:
                  '\uD83D\uDE4C A new version of Aibuild {version} is now available.',
              },
              { version: version },
            ),
          }),
        );
      }
    }
  }, [
    commonBannerProps,
    onLearnUponLinkClick,
    dispatch,
    intl,
    closeBanner,
    versionQuery?.data,
  ]);
}
