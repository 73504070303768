import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isAnyFetchInProgess } from '@selectors/fetchSelector';
import useMaterialQueries from '@hooks/materials/useMaterialQueries';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';

const MaterialLoader = ({ children }) => {
  const isFetching = useSelector(isAnyFetchInProgess);
  const { materialsQuery } = useMaterialQueries();
  const { refetch: refetchMaterialsQuery } = materialsQuery;

  useEffect(() => {
    refetchMaterialsQuery();
  }, [refetchMaterialsQuery]);

  const pageDataIsFetched = !isFetching && materialsQuery.isFetched;

  if (!pageDataIsFetched) {
    return <PageLoaderPortal show />;
  }

  return children;
};

MaterialLoader.propTypes = {
  children: PropTypes.node,
};

export default MaterialLoader;
