import * as THREE from 'three';

/**
 * Represents a visualisable element of the printer system, containing the printer
 * settings of the printer, along with default settings for that type of printer.
 */
export default class PrinterComponent extends THREE.Group {
  constructor(printerSettings, machineDefaults, name) {
    super();
    this.printerSettings = printerSettings;
    this.machineDefaults = machineDefaults;
    this.name = name;
    //currently, the machine type is what determines the defaults
    this.machineType = this.machineDefaults?.machineType;
  }
}
