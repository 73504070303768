import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import { ROUTES } from '@constants/router';
import PageHeader, {
  PAGE_HEADER_VARIANT_SMALL,
} from '@components/2-molecules/PageHeader';
import Card from '@components/2-molecules/Card';
import {
  Wrapper,
  ContentWrapper,
  SkeletonList,
} from '@containers/WorkflowExamples/WorkflowExamples.styled';
import { SKELETON_VARIANT_ONE_LINE } from '@components/1-atoms/Skeleton';

export const EXAMPLE_WORKFLOWS = {
  'Mould Design': {
    name: 'Mould Design',
    thumbnailUrl: '/img/workflow-examples/Mold Designer.png',
  },
  Repair: {
    name: 'Repair',
    thumbnailUrl: '/img/workflow-examples/Repair.png',
  },
  Drilling: {
    name: 'Drilling',
    thumbnailUrl: '/img/workflow-examples/Milling-Drilling.png',
  },
  Engraving: {
    name: 'Engraving',
    thumbnailUrl: '/img/workflow-examples/Milling-Engraving.png',
  },
  'Stock Clearing': {
    name: 'Stock Clearing',
    thumbnailUrl: '/img/workflow-examples/Milling-Stock Clearing.png',
  },
  'Planar Finishing': {
    name: 'Planar Finishing',
    thumbnailUrl: '/img/workflow-examples/Milling-Planar Finishing.png',
  },
  'Geodesic Finishing': {
    name: 'Geodesic Finishing',
    thumbnailUrl: '/img/workflow-examples/Milling-Geodesic.png',
  },
  Contouring: {
    name: 'Contouring',
    thumbnailUrl: '/img/workflow-examples/Milling-Contouring.png',
  },
  'External Axis-WAAM': {
    name: 'External Axis-WAAM',
    thumbnailUrl: '/img/workflow-examples/external-axis-waam.png',
  },
  'External Axis-DED': {
    name: 'External Axis-DED',
    thumbnailUrl: '/img/workflow-examples/external-axis-ded.png',
  },
  'Linear Rail': {
    name: 'Linear Rail',
    thumbnailUrl: '/img/workflow-examples/linear-rail.png',
  },
  Gantry: {
    name: 'Gantry',
    thumbnailUrl: '/img/workflow-examples/gantry.png',
  },
  Horizontal: {
    name: 'Horizontal',
    thumbnailUrl: '/img/workflow-examples/horizontal.png',
  },
  Angular: {
    name: 'Angular',
    thumbnailUrl: '/img/workflow-examples/angular.png',
  },
  Multiplanar: {
    name: 'Multiplanar',
    thumbnailUrl: '/img/workflow-examples/multiplanar.png',
  },
  'Non-Planar': {
    name: 'Non-Planar',
    thumbnailUrl: '/img/workflow-examples/nonplanar.png',
  },
  Cladding: {
    name: 'Cladding',
    thumbnailUrl: '/img/workflow-examples/cladding.png',
  },
  Vase: {
    name: 'Vase',
    thumbnailUrl: '/img/workflow-examples/vase.png',
  },
  Segmented: {
    name: 'Segmented',
    thumbnailUrl: '/img/workflow-examples/segmented.png',
  },
  'Slicing Path': {
    name: 'Slicing Path',
    thumbnailUrl: '/img/workflow-examples/slicing-path.png',
  },
  Radial: {
    name: 'Radial',
    thumbnailUrl: '/img/workflow-examples/radial.png',
  },
};

export const EXAMPLE_WORKFLOWS_NAMES = Object.keys(EXAMPLE_WORKFLOWS);

const WorkflowExamples = () => {
  const dataTestId = 'workflow-examples';
  const history = useHistory();
  const intl = useIntl();
  const { publicWorkflowsQuery } = useWorkflowQueries();

  const { refetch: refetchPublicWorkflowsQuery } = publicWorkflowsQuery;
  const publicWorkflows = publicWorkflowsQuery.data;

  const publicWorkflowsListItems = useMemo(() => {
    if (!publicWorkflows) return [];

    const filteredWorkflows = publicWorkflows
      .filter(({ name }) => EXAMPLE_WORKFLOWS_NAMES.includes(name?.trim()))
      .sort((leftWorkflow, rightWorkflow) => {
        return (
          EXAMPLE_WORKFLOWS_NAMES.indexOf(leftWorkflow?.name) -
          EXAMPLE_WORKFLOWS_NAMES.indexOf(rightWorkflow?.name)
        );
      });

    return filteredWorkflows?.map((workflow) => {
      return {
        id: workflow?.id,
        label: workflow?.name,
        mediaSrc: EXAMPLE_WORKFLOWS?.[workflow?.name]?.thumbnailUrl,
        onClick: () =>
          history.push(
            generatePath(ROUTES.WORKFLOW, {
              workspaceId: workflow?.workspaceId,
              itemId: workflow?.id,
            }),
          ),
      };
    });
  }, [history, publicWorkflows]);

  const showExamplesWorkflows =
    publicWorkflowsQuery.isFetched && !isEmpty(publicWorkflowsListItems);

  useEffect(() => {
    refetchPublicWorkflowsQuery();
  }, [refetchPublicWorkflowsQuery]);

  if (publicWorkflowsQuery.isLoading) {
    return (
      <SkeletonList
        dataTestId={`${dataTestId}__skeleton-list`}
        variant={SKELETON_VARIANT_ONE_LINE}
        borderRadius="large"
        length={4}
        height={216}
        withLine={false}
      />
    );
  }

  if (!showExamplesWorkflows) return null;

  return (
    <Wrapper data-testid={`${dataTestId}__container`}>
      <PageHeader
        dataTestId={`${dataTestId}__header`}
        variant={PAGE_HEADER_VARIANT_SMALL}
        title={intl.formatMessage({
          id: 'homepage.examples.title',
          defaultMessage: 'Examples',
        })}
        withMarginTop
      />

      <ContentWrapper>
        {publicWorkflowsListItems.map((listItem) => (
          <Card
            key={listItem.id}
            onClick={listItem.onClick}
            mediaSrc={
              listItem.mediaSrc || '/img/workflow-examples/horizontal.png'
            }
            mediaHeight={156}
            infoRow={{
              label: listItem.label,
            }}
            dataTestId={`${dataTestId}__card__${listItem.id}`}
          />
        ))}
      </ContentWrapper>
    </Wrapper>
  );
};

WorkflowExamples.propTypes = {};

export default WorkflowExamples;
