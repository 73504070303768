import styled, { createGlobalStyle } from 'styled-components';
import SVG from '@app/components/1-atoms/SVG';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100svh;
  ${flexColumn}
  background-color: ${({ theme }) => theme.colors.surface};
  overflow: clip;
`;

export const SVGSprite = styled(SVG).attrs({ name: 'icons-sprite' })`
  display: none;
`;

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/inter/Inter-Regular.ttf) format('ttf');
  }

  @font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/inter/Inter-Medium.ttf) format('ttf');
  }

  @font-face {
    font-family: 'Inter SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url(./fonts/inter/Inter-SemiBold.ttf) format('ttf');
  }

  #webWidget {
    left: 50% !important;
    right: unset !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  // Storybook preview container styles
  .docs-story,
  .sb-show-main.sb-main-padded {
    background-color: ${({ theme }) =>
      theme?.isDarkTheme
        ? theme.colors.surface
        : theme.colors.inverseOnSurface};
  }

  html {
    font-family: 'Inter', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track
  {
    background-color: transparent;
    border-radius: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 14px;
    background-color: ${({ theme }) => theme.colors.onSurface};
  }
`;
