import React, { useCallback, useState, useEffect, useMemo } from 'react';
import useDialog from '@hooks/useDialog';
import usePrintingStatus from '@hooks/printers/usePrintingStatus';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import EditorLayout, {
  LayoutSettingBarLayer,
} from '@components/1-atoms/Layouts/EditorLayout';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Toolbar from '@components/2-molecules/Toolbar';
import { DIALOG_VARIANT_SIDE_VIEW } from '@components/2-molecules/Dialog';
import DialogPortal from '@components/2-molecules/DialogPortal';
import Loader from '@components/2-molecules/Loader';
import { Space } from '@components/Printers/Space';
import PrinterDynamicNavigationBar from '@containers/PrinterDynamicNavigationBar';
import { useSelector } from 'react-redux';
import {
  getGeneralPrinterSettingState,
  getNozzleConfig,
  getPostProcessorConfigVisibility,
  getSelectedPrinter,
} from '@selectors/printerSelectors';
import { Nozzle } from '@components/Printers/NozzleLibrary';
import PostProcessorConfig from '@components/Printers/PostProcessorConfig';
import GeneralPrinterSetting from '@components/Printers/GeneralPrinterSetting';
import Editor from '@components/Printers/Editor';

const Printer = () => {
  const { showDialog } = useDialog();
  const [settingBarCollapsed, setSettingBarCollapsed] = useState(true);

  const nozzleConfig = useSelector(getNozzleConfig());
  const showNozzleConfig = nozzleConfig?.showNozzleConfig;
  const showPostProcessorConfig = useSelector(
    getPostProcessorConfigVisibility(),
  );
  const generalPrinterState = useSelector(getGeneralPrinterSettingState());
  const showGeneralPrinterSetting =
    generalPrinterState?.showGeneralPrinterSetting;
  const selectedPrinter = useSelector(getSelectedPrinter());
  const printerId = selectedPrinter?.id;
  const {
    isPrinting,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
    printerStatusColor,
  } = usePrintingStatus(printerId);

  usePrinterSocket();

  const handleSidebarCollapse = useCallback(() => {
    setSettingBarCollapsed(!settingBarCollapsed);
  }, [settingBarCollapsed, setSettingBarCollapsed]);

  const handleToolbarLogoClick = useCallback(() => {
    showDialog(ModalDataTypes.NAVIGATION);
  }, [showDialog]);

  const EditorTopBar = useMemo(() => {
    if (isPrinting) {
      return (
        <Loader
          endingIconButtonIconName="stop_player_1"
          endingIconButtonTitle="Stop Printing"
          iconColor={printerStatusColor}
          iconName="Dot_1"
          onEndingIconButtonClick={stopPrint}
          progress={printingProgress}
          title={formattedPrinterStatusName}
        />
      );
    }

    return (
      <Loader
        iconColor={printerStatusColor}
        iconName="Dot_1"
        title={formattedPrinterStatusName}
      />
    );
  }, [
    isPrinting,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
    printerStatusColor,
  ]);

  useEffect(() => {
    setSettingBarCollapsed(false);
  }, []);

  return (
    <>
      <EditorLayout
        backgroundLayer={<Space />}
        settingBarCollapsed={settingBarCollapsed}
        topBar={EditorTopBar}
      >
        <Toolbar
          logoButton={{
            onClick: handleToolbarLogoClick,
          }}
        />

        {selectedPrinter ? (
          <>
            <LayoutSettingBarLayer visible={settingBarCollapsed}>
              <Toolbar
                headerIconButtons={[
                  {
                    id: 0,
                    iconName: 'thumbnail_bar_0',
                    onClick: handleSidebarCollapse,
                  },
                ]}
              />
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer visible={!settingBarCollapsed} fadeOut>
              {selectedPrinter && (
                <Editor
                  handleSidebarCollapse={handleSidebarCollapse}
                  printer={selectedPrinter}
                />
              )}
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer visible={showGeneralPrinterSetting} slideOut>
              <GeneralPrinterSetting selectedPrinter={selectedPrinter} />
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer visible={showPostProcessorConfig} slideOut>
              <PostProcessorConfig />
            </LayoutSettingBarLayer>

            <LayoutSettingBarLayer visible={showNozzleConfig} slideOut>
              <Nozzle selectedPrinter={selectedPrinter} />
            </LayoutSettingBarLayer>
          </>
        ) : (
          <></>
        )}
      </EditorLayout>

      <DialogPortal
        dialogId={ModalDataTypes.NAVIGATION}
        variant={DIALOG_VARIANT_SIDE_VIEW}
        onCloseIconButtonClick={undefined}
        primaryButtonLabel={undefined}
        disableContentPadding
        closeOnClickOutside
        closeOnEscape
      >
        <PrinterDynamicNavigationBar />
      </DialogPortal>
    </>
  );
};

Printer.propTypes = {};

export default Printer;
