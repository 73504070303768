import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import React from 'react';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';

const PostProcessorABB = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <FormikField
        component={SettingCheckbox}
        dataTestId={`printer-custom-post-processor__setting-abbIsAnalogSignalEnabled`}
        checkbox={{
          checked: Boolean(
            values[PostProcessorSettingsName.abbIsAnalogSignalEnabled],
          ),
          onChange: () => {
            setFieldValue(
              PostProcessorSettingsName.abbIsAnalogSignalEnabled,
              !values[PostProcessorSettingsName.abbIsAnalogSignalEnabled],
            );
          },
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            PostProcessorSettingsName.abbIsAnalogSignalEnabled,
          ),
        }}
        name={PostProcessorSettingsName.abbIsAnalogSignalEnabled}
        label={intl.formatMessage({
          id: 'printers.postprocessor.abb.analog.label',
          defaultMessage: 'Use MoveLAO',
        })}
      />
      <FormikField
        component={SettingTextField}
        label={intl.formatMessage({
          id: 'printers.postprocessor.abb.analogName.label',
        })}
        dataTestId={`printer-custom-post-processor__setting-abbAnalogSignalName`}
        name={PostProcessorSettingsName.abbAnalogSignalName}
        field1={{
          disabled: isSubmitting,
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            PostProcessorSettingsName.abbAnalogSignalName,
          ),
        }}
      />
    </>
  );
};

PostProcessorABB.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorABB;
