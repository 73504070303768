import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, matchPath } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import useDialog from '@hooks/useDialog';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useShareQueries from '@hooks/share/useShareQueries';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { ROUTES } from '@constants/router';

const EXPIRED_SHARE_STATUS_CODE = 404;
const NOT_AUTHORIZED_STATUS_CODE = 403;

const Shared = () => {
  const intl = useIntl();
  const history = useHistory();
  const {
    params: { type, sentDataId },
  } = matchPath(history.location.pathname, {
    path: ROUTES.SHARED,
  });

  const { showDialog } = useDialog();
  const { validateShare } = useShareQueries({ type, sentDataId });
  const { getPrintersQueryOptions } = usePrinterQueries();
  const printersQuery = useQuery(getPrintersQueryOptions(true));
  const refetchPrinters = printersQuery.refetch;
  const noPrinters = printersQuery.isFetched && isEmpty(printersQuery.data);
  const { data, error, isFetched, isError } = validateShare;
  const { response } = error || {};

  const shareOriginalName = data?.originalName;
  const isProjectShare = type === 'project';
  const isWorkflowShare = type === 'workflow';
  const isDataFetched = printersQuery.isFetched && isFetched;
  const isShareValid = isDataFetched && !isError;

  const handleErrorResponse = useCallback(
    (responseStatus) => {
      const isShareExpired = responseStatus === EXPIRED_SHARE_STATUS_CODE;
      const isNotAuthorized = responseStatus === NOT_AUTHORIZED_STATUS_CODE;

      let navigationPageState = {};

      if (isShareExpired) {
        navigationPageState = {
          description: intl.formatMessage({
            id: 'sharedpage.expired.description',
            defaultMessage:
              "The page you're looking for might have been moved, deleted, or the link you followed has expired. If you believe this is an error, feel free to contact us for further assistance.",
          }),
        };
      }

      if (isNotAuthorized) {
        navigationPageState = {
          icon: 'person_0',
          title: intl.formatMessage({
            id: 'sharedpage.notauthorized.title',
            defaultMessage: 'Access Restricted',
          }),
          description: intl.formatMessage({
            id: 'sharedpage.notauthorized.description',
            defaultMessage:
              "You don't have the necessary permissions to access this page or resource. If you believe you should have access, please contact our support team for further assistance.",
          }),
        };
      }

      if (!isShareExpired && !isNotAuthorized) {
        navigationPageState = {
          icon: 'help_0',
          title: intl.formatMessage({
            id: 'sharedpage.error.title',
            defaultMessage: 'Something went wrong',
          }),
          description: intl.formatMessage({
            id: 'sharedpage.error.description',
            defaultMessage:
              "We're sorry, but it looks like something didn't go as planned. You can: refresh the page, return to the home page, or contact our support team if the issue persists.",
          }),
        };
      }

      history.push(ROUTES.NOT_FOUND, navigationPageState);
    },
    [intl, history],
  );

  useEffect(() => {
    refetchPrinters();
  }, [refetchPrinters]);

  useEffect(() => {
    if (!isDataFetched || !isError) return;

    handleErrorResponse(response?.status);
  }, [isDataFetched, isError, response?.status, handleErrorResponse]);

  useEffect(() => {
    if (!isShareValid) return;

    if (noPrinters) {
      showDialog(ModalDataTypes.PROMPT, {
        dataTestId: 'unable-to-send-copy-dialog',
        title: intl.formatMessage({
          id: 'sendcopy.dialog.template.missing_printer_error_dialog.title',
          defaultMessage: 'Missing printer',
        }),
        subtitle: intl.formatMessage({
          id: 'sendcopy.dialog.template.missing_printer_error_dialog.subtitle',
          defaultMessage:
            'Unable to proceed because no printer is configured for your organization. Please ensure a printer is set up before continuing',
        }),
        secondaryButtonLabel: '',
        primaryButtonLabel: intl.formatMessage({
          id: 'general.ok',
          defaultMessage: 'OK',
        }),
      });

      return;
    }

    if (isWorkflowShare) {
      showDialog(ModalDataTypes.CREATE_FLOW, {
        startFromSelectWorkflow: true,
        shareFlow: true,
        shareFlowData: {
          sentDataId,
        },
        handleErrorResponse,
      });

      return;
    }

    if (isProjectShare) {
      showDialog(ModalDataTypes.ADD_PROJECT, {
        shareFlow: true,
        projectName: shareOriginalName,
        sentDataId,
        handleErrorResponse,
      });
    }
  }, [
    isShareValid,
    isProjectShare,
    isWorkflowShare,
    showDialog,
    sentDataId,
    shareOriginalName,
    handleErrorResponse,
    noPrinters,
    intl,
  ]);

  return null;
};

Shared.propTypes = {};

export default Shared;
