import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import { UploadField } from '@pages/Files/Files.styled';
import PageList from '@components/3-organisms/PageList';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import { LIST_ITEM_VARIANT_THUMBNAIL } from '@components/1-atoms/ListItem';
import React, { Fragment } from 'react';
import { useFileActions } from '@hooks/files/useFileActions';
import { getAllowedExtensionsString } from '@utils/commonFunctions';
import useFileQueries from '@hooks/files/useFileQueries';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const FilePageContent = ({
  fileListItems,
  headerLeadingIconButtonIconName,
  headerTitle,
  listItemVariant = LIST_ITEM_VARIANT_THUMBNAIL,
  onHeaderLeadingIconButtonClick,
  onTabClick,
  projectId,
  tabs,
}) => {
  const {
    handleAddFile,
    uploadFileProgress,
    handleUploadFieldChange,
    uploadFieldRef,
    showUploadFileProgressBar,
    isTakingScreenshot,
  } = useFileActions({
    projectId,
  });

  const formattedUploadFileProgress = Math.ceil(uploadFileProgress);
  const allowedFileExtensionsToUpload = getAllowedExtensionsString();
  const { projectFilesQuery } = useFileQueries({
    projectId,
  });
  const intl = useIntl();

  return (
    <Fragment>
      {showUploadFileProgressBar && (
        <PageLoaderPortal progress={formattedUploadFileProgress} />
      )}

      {isTakingScreenshot && (
        <PageLoaderPortal
          title={intl.formatMessage({
            id: 'general.finalising',
            defaultMessage: 'Finalising',
          })}
        />
      )}

      <UploadField
        ref={uploadFieldRef}
        id="files-page-upload-field"
        data-testid="files-page-upload-field"
        onChange={handleUploadFieldChange}
        accept={allowedFileExtensionsToUpload}
      />
      <PageList
        loading={!projectFilesQuery?.isFetched}
        headerTitle={
          headerTitle ??
          intl.formatMessage({
            id: 'filespage.files_list.tab_title.files',
            defaultMessage: 'Files',
          })
        }
        tabs={tabs}
        onTabClick={onTabClick}
        listItems={fileListItems}
        listItemVariant={listItemVariant}
        headerLeadingIconButtonIconName={headerLeadingIconButtonIconName}
        onHeaderLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
        createItemMessageIconName="folder_0"
        createItemMessageTitle={intl.formatMessage({
          id: 'filespage.files_list.create_file_title',
          defaultMessage: 'No file yet',
        })}
        createItemMessagDescription={intl.formatMessage({
          id: 'filespage.files_list.create_file_description',
          defaultMessage: 'Save your project’s 3D files all in one place',
        })}
        createItemMessageButtonIconName="add_0"
        createItemMessageButtonLabel={intl.formatMessage({
          id: 'filespage.files_list.create_file_button',
          defaultMessage: 'Add file',
        })}
        createItemMessageOnButtonClick={handleAddFile}
        actionChips={[
          {
            id: 'files-browser-new-file-chip',
            variant: CHIP_VARIANT_PRIMARY,
            dataTestId: 'files-page__create-new-file-chip',
            label: intl.formatMessage({
              id: 'filespage.files_list.action_chips.new_file',
              defaultMessage: 'New File',
            }),
            leadingIconName: 'add_0',
            onClick: handleAddFile,
          },
        ]}
        sortOptions={[
          {
            label: intl.formatMessage({
              id: 'general.date',
              defaultMessage: 'Date',
            }),
            value: 'createdAt',
          },
          {
            label: intl.formatMessage({
              id: 'general.name',
              defaultMessage: 'Name',
            }),
            value: 'label',
          },
        ]}
      />
    </Fragment>
  );
};

FilePageContent.propTypes = {
  fileListItems: PropTypes.array.isRequired,
  headerLeadingIconButtonIconName: PropTypes.string,
  headerTitle: PropTypes.string,
  listItemVariant: PropTypes.string,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  onTabClick: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  tabs: PropTypes.array,
};

export default FilePageContent;
