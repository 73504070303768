import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId, pick, isEmpty } from 'lodash';
import { getNotifications } from '@selectors/notificationsSelector';
import { setNotifications } from '@actions/notificationsActions';

export const REMOVE_NOTIFICATION_TIMEOUT = 10000;

export const NOTIFICATION_HANDLER_PROPS = [
  'primaryButtonClickHandler',
  'secondaryButtonClickHandler',
];

export default () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const notificationRef = useRef([]);

  const removeAllNotifications = useCallback(() => {
    dispatch(setNotifications([]));
  }, [dispatch]);

  const removeNotification = useCallback(
    (id) => {
      const nextNotifications = notificationRef.current?.filter(
        (notification) => notification?.id !== id,
      );
      notificationRef.current = nextNotifications;

      dispatch(setNotifications(nextNotifications));
    },
    [dispatch],
  );

  const addNotification = useCallback(
    (notification = {}) => {
      let nextNotification = {
        id: uniqueId(),
        createdAt: Date.now(),
        ...pick(notification, {}),
      };
      let handlerProps = pick(nextNotification, NOTIFICATION_HANDLER_PROPS);

      if (!isEmpty(handlerProps)) {
        handlerProps = Object.keys(handlerProps).reduce((acc, handlerName) => {
          const passedHandler = handlerProps[handlerName];
          const modifiedHandler = () => {
            passedHandler();
            removeNotification(nextNotification.id);
            nextNotification?.onNotificationHide?.();
          };

          return {
            ...acc,
            [handlerName]: modifiedHandler,
          };
        }, {});
      }

      nextNotification = {
        ...nextNotification,
        ...handlerProps,
      };

      const nextNotifications = [nextNotification, ...notificationRef.current];
      notificationRef.current = nextNotifications;

      dispatch(setNotifications(nextNotifications));

      if (!notification?.blocked) {
        setTimeout(() => {
          removeNotification(nextNotification?.id);
          nextNotification?.onNotificationHide?.();
        }, notification?.visibilityTimeout || REMOVE_NOTIFICATION_TIMEOUT);
      }
    },
    [dispatch, removeNotification],
  );

  useEffect(() => {
    notificationRef.current = notifications;
  }, [notifications]);

  return {
    notifications,
    addNotification,
    removeNotification,
    removeAllNotifications,
  };
};
