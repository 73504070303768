import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { versionQueryKeys } from '@hooks/version/useVersionQueries';

export default function useVersion() {
  const queryClient = useQueryClient();

  const getComputationVersion = useCallback(
    () => queryClient.getQueryData(versionQueryKeys.computationVersion),
    [queryClient],
  );

  const getVersion = useCallback(
    () => queryClient.getQueryData(versionQueryKeys.version),
    [queryClient],
  );

  return {
    getVersion,
    getComputationVersion,
  };
}
