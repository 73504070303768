import React, { useCallback, useContext, useMemo } from 'react';
import { VisualizationContext } from '@contexts/VisualizationContext';
import Transform from '@containers/Visualization/Transform';
import VisualizationUtils from '@app/lib/VisualizationUtils';

/* eslint react/no-unknown-property: 0 */

export default function Objects() {
  const { objects, sceneSettings, workflowIsEditable } =
    useContext(VisualizationContext);
  const objectsList = useMemo(() => Object.values(objects), [objects]);

  const generateDimensionArrows = useCallback((object) => {
    const isLinesType = object?.userData?.isLinesType ? 'LINES' : 'MESH';
    const arrows = VisualizationUtils.generateDimensionArrows(
      object,
      isLinesType,
    );
    const boundingBox = VisualizationUtils.displayBoundingGeometry(
      object,
      isLinesType,
    );

    return (
      <>
        <primitive object={arrows} />
        <primitive object={boundingBox} />
      </>
    );
  }, []);

  return (
    <>
      {objectsList?.map((object) => (
        <group key={object?.userData?.designId}>
          {workflowIsEditable ? (
            <Transform object={object} />
          ) : (
            <primitive object={object} />
          )}

          {sceneSettings?.isShowingDimensions &&
            generateDimensionArrows(object)}
        </group>
      ))}
    </>
  );
}
