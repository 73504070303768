export const DISABLED_ORGS_FOR_TALK_TO_AI = [
  'Andritz Hydro',
  'Printerior',
  'Refik Anadol',
  'Colossus',
  'Arrival',
  'Filabot',
  'Caracol',
  'WASP',
  'Meltio',
  'Rehau',
  'Polyproducts',
  'Ford',
  'MDT',
  'Sika',
  'Patternsandmoulds',
  'Weir',
  'Recozy',
  'DLR',
  'Haddy',
  'Actvet',
  'ZAL',
  'Moolman Automotive',
  'BMW',
  'Eaton',
  'FHNW',
  'Refik-Anadol-Studio',
  'Weber-Educational',
  'Evo3D',
  'Intecro',
  'oneoffrobotics',
  'Takenaka Corporation',
  'CNC Robotics',
  'Alpha Additive',
  'Additec',
  'Eventscape',
  'FORCE Technology',
  'PROTOTYP3',
  'Normada',
  'Oregon_State_University',
  'Nedcam',
  'Tech21',
  'Total Machining Solutions',
  'ARUP',
  'Breton',
  'Loci Robotics',
  'Titan Robotics',
  'AC3D',
  'Royal NLR',
  'Centria Oy',
  'Oscar PLT',
  'DSI',
  'Airtech',
  'Boeing',
  'Hyperion Systems',
  'Dash-CAE',
  'Kuka Industries',
  'Belotti',
  'Formalloy',
  'KUKA UK',
  'Model-No',
  'Spark Industries',
  'Radius',
  'Ingersoll',
  'WYVE',
  'Fanum',
  'Strato',
  'AiBuild-deprecated-operators',
  'Mighty Buildings',
  'Hanssler',
  'Deep',
  'KraussMaffei',
  'Additive Space',
  'Duro Therm',
  'Baker Hughes',
  'Vertex Dynamix',
  'Ludwig Schleicher',
  'MSA Manufacturing',
  'Limber',
  'University of Exeter',
  'XBeam3D',
  'Ulsan-FDDF.LAB',
  'MTC',
  'EPF University',
  'Kohler',
];
