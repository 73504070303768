import React, { createContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SNACKBAR_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Snackbar';

const SnackbarContext = createContext();

const initialSnackbarIsOpen = false;
const initialSnackbarId = null;
const initialSnackbarData = {};
const initialSnackbarPosition = SNACKBAR_POSITION_BOTTOM_LEFT;

export const SnackbarProvider = ({ children }) => {
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(initialSnackbarIsOpen);
  const [snackbarId, setSnackbarId] = useState(initialSnackbarId);
  const [snackbarData, setSnackbarData] = useState(initialSnackbarData);
  const [snackbarPosition, setSnackbarPosition] = useState(
    initialSnackbarPosition,
  );

  const resetSnackbarData = useCallback(
    () => setSnackbarData(initialSnackbarData),
    [],
  );

  const updateSnackbarData = useCallback(
    (data = {}) => {
      if (!snackbarData) return;

      setSnackbarData((prevSnackbarData) => ({
        ...prevSnackbarData,
        ...(data || {}),
      }));
    },
    [snackbarData],
  );

  const resetSnackbar = useCallback(() => {
    setSnackbarIsOpen(initialSnackbarIsOpen);
    setSnackbarId(initialSnackbarId);
    setSnackbarData(initialSnackbarData);
    setSnackbarPosition(initialSnackbarPosition);
  }, [setSnackbarData, setSnackbarId, setSnackbarIsOpen, setSnackbarPosition]);

  const value = useMemo(
    () => ({
      snackbarData,
      snackbarId,
      snackbarIsOpen,
      snackbarPosition,
      resetSnackbar,
      resetSnackbarData,
      setSnackbarData,
      setSnackbarId,
      setSnackbarIsOpen,
      setSnackbarPosition,
      updateSnackbarData,
    }),
    [
      snackbarData,
      snackbarId,
      snackbarIsOpen,
      snackbarPosition,
      resetSnackbar,
      resetSnackbarData,
      setSnackbarData,
      setSnackbarId,
      setSnackbarIsOpen,
      setSnackbarPosition,
      updateSnackbarData,
    ],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.any,
};

export default SnackbarContext;
