import styled, { css } from 'styled-components';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import Text, {
  TEXT_VARIANT_LABEL_SMALL,
  TEXT_VARIANT_TITLE_SMALL,
} from '@components/1-atoms/Text';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  position: relative;
`;

export const Header = styled.div`
  min-height: 57px;
  ${flexCenterVertical}

  ${({ subcategory, onClick, theme: { colors } }) => css`
    ${when(
      subcategory,
      `
        min-height: 48px;

        svg {
          fill: ${colors.outline};
        }
      `,
    )}

    ${when(
      onClick,
      `
        cursor: pointer;
      `,
    )}
  `}
`;

export const HeaderContent = styled.div`
  ${flexCenterVertical}
  flex: 1 0 0;
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level5};
    padding: ${spacing.level4} ${spacing.level3} ${spacing.level4}
      ${spacing.level5};
  `}
`;

export const Label = styled(Text).attrs(({ subcategory }) => ({
  variant: subcategory ? TEXT_VARIANT_LABEL_SMALL : TEXT_VARIANT_TITLE_SMALL,
  color: subcategory ? 'outline' : 'onSurface',
}))`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;

  ${({ subcategory }) => css`
    ${when(
      subcategory,
      `
        text-transform: uppercase;
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  flex-shrink: 0;

  ${({ theme: { colors, spacing } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
    fill: ${colors.onSurface};
  `}
`;

export const Body = styled.div`
  ${flexColumn}
  overflow: hidden;

  ${({ expandable, expand, expanTransitionTime, maxHeight }) => css`
    transition: all ${expanTransitionTime}ms ease-in-out;

    ${when(
      expandable,
      `
        max-height: 0;
      `,
    )}

    ${when(
      expandable && expand,
      `
        max-height: ${maxHeight || 1000}px;
      `,
    )}
  `}
`;

export const BodyContent = styled.div`
  ${flexColumn}
  flex-shrink: 0;
  overflow: auto;

  ${({ theme: { spacing, colors } }) => css`
    color: ${colors.onSurface};
    padding-bottom: ${spacing.level5};
  `}
`;
