export const legacyColors = {
  colorMain1: '#191919',
  colorMain2: '#323232',
  colorMain3: '#616161',
  colorMain4: '#8D8D8D',
  colorMain5: '#C4C4C4',
  colorMain6: '#FFFFFF',
  colorBackground: '#323232',
  colorHighlight: '#00B9FF',
  colorHighlightWithTransparency: 'rgba(0, 187, 255, 0.842)',
  colorHighlightWithTransparencyDisabled: 'rgba(0, 187, 255, 0.459)',
  colorHighlight22: '#00FF80',
  colorAlert: '#D6FF00',
  coloAalertMiss: '#ff0000',
  colorBolder1: '#707070',

  // Notification
  notificationWarning: '#DDFF38',
  notificationError: '#FF7D7E',
  notificationSuccess: '#A2FF82',
  notificationInfo: '#A5E6FF',
  notificationText: '#919191',
};

export const colorsSource = {
  primary: {
    0: '#000000',
    10: '#0A006B',
    20: '#1600A8',
    30: '#312BC2',
    40: '#4B49DA',
    50: '#6564F4',
    60: '#8283FF',
    70: '#A1A2FF',
    80: '#C1C1FF',
    90: '#E2DFFF',
    95: '#F2EFFF',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  secondary: {
    0: '#000000',
    10: '#181835',
    20: '#2D2D4C',
    30: '#444463',
    40: '#5C5B7C',
    50: '#747496',
    60: '#8E8DB1',
    70: '#A9A8CD',
    80: '#C4C3E9',
    90: '#E2DFFF',
    95: '#F2EFFF',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  tertiary: {
    0: '#000000',
    10: '#360829',
    20: '#4F1F3F',
    30: '#693556',
    40: '#844C6F',
    50: '#A06488',
    60: '#BD7DA2',
    70: '#DA97BE',
    80: '#F8B2D9',
    90: '#FFD8EB',
    95: '#FFECF3',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  error: {
    0: '#000000',
    10: '#410002',
    20: '#690005',
    30: '#93000A',
    40: '#BA1A1A',
    50: '#DE3730',
    60: '#FF5449',
    70: '#FF897D',
    80: '#FFB4AB',
    90: '#FFDAD6',
    95: '#FFEDEA',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  caution: {
    0: '#000000',
    10: '#261A00',
    20: '#3F2E00',
    30: '#5B4300',
    40: '#785900',
    50: '#977100',
    60: '#B78A00',
    70: '#D8A300',
    80: '#FABD00',
    90: '#FFDF9E',
    95: '#FFEFD4',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  success: {
    0: '#000000',
    10: '#002106',
    20: '#00390E',
    30: '#005318',
    40: '#226C2D',
    50: '#3D8644',
    60: '#57A15B',
    70: '#72BC73',
    80: '#8CD88C',
    90: '#A7F5A6',
    95: '#C7FFC3',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  neutral: {
    0: '#000000',
    4: '#0D0E11',
    6: '#121316',
    10: '#1C1B1F',
    12: '#1F1F23',
    17: '#292A2D',
    20: '#313034',
    22: '#343538',
    24: '#38393C',
    30: '#47464A',
    40: '#5F5E62',
    50: '#78767A',
    60: '#929094',
    70: '#ADAAAF',
    80: '#C8C5CA',
    87: '#DBD9DD',
    90: '#E5E1E6',
    92: '#E9E7EC',
    94: '#EFEDF1',
    95: '#F3EFF4',
    96: '#F5F3F7',
    98: '#FCF8FD',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
  neutralVariant: {
    0: '#000000',
    10: '#1B1B23',
    20: '#303038',
    30: '#47464F',
    40: '#5E5D67',
    50: '#777680',
    60: '#918F9A',
    70: '#ACAAB4',
    80: '#C8C5D0',
    90: '#E4E1EC',
    95: '#F2EFFA',
    99: '#FFFBFF',
    100: '#FFFFFF',
  },
};

export const lightThemeColors = {
  // Primary
  primary: '#342EC4',
  onPrimary: '#FFFFFF',

  primaryContainer: '#5B5AEA',
  onPrimaryContainer: '#FFFFFF',

  primaryFixed: '#E2DFFF',
  primaryFixedDim: '#C1C1FF',
  onPrimaryFixed: '#0A006B',
  onPrimaryFixedVariant: '#312BC2',

  // Secondary
  secondary: '#585894',
  onSecondary: '#FFFFFF',

  secondaryContainer: '#C4C4FF',
  onSecondaryContainer: '#31316B',

  secondaryFixed: '#E2DFFF',
  secondaryFixedDim: '#C1C1FF',
  onSecondaryFixed: '#13124D',
  onSecondaryFixedVariant: '#40407B',

  // Tertiary
  tertiary: '#800C89',
  onTertiary: '#FFFFFF',

  tertiaryContainer: '#AB3EB1',
  onTertiaryContainer: '#FFFFFF',

  tertiaryFixed: '#FFD6F9',
  tertiaryFixedDim: '#FFA9FC',
  onTertiaryFixed: '#37003B',
  onTertiaryFixedVariant: '#7D0686',

  // Error
  error: '#BA1A1A',
  onError: '#FFFFFF',
  errorContainer: '#FFDAD6',
  onErrorContainer: '#410002',

  // Caution
  caution: '#D8A300',
  onCaution: '#FFFFFF',
  cautionContainer: '#FFDF9E',
  onCautionContainer: '#503A00',

  // Success
  success: '#3D8644',
  onSuccess: '#FFFFFF',
  successContainer: '#A7F5A6',
  onSuccessContainer: '#002106',

  // Surface
  onSurface: '#1B1B23',
  onSurfaceVariant: '#464555',
  outline: '#777586',
  outlineVariant: '#C7C4D7',
  surface: '#FCF8FF',
  surfaceBright: '#FFFBFF',
  surfaceContainer: '#EFECF9',
  surfaceContainerHigh: '#EAE6F3',
  surfaceContainerHighest: '#E4E1ED',
  surfaceContainerLow: '#F5F2FE',
  surfaceContainerLowest: '#FFFFFF',
  surfaceDim: '#DBD8E5',
  surfaceVariant: '#E3E0F4',

  // Inverse
  inverseOnSurface: '#F2EFFB',
  inversePrimary: '#C1C1FF',
  inverseSurface: '#302F39',
  inverseSurfaceVariant: '#464555',

  scrim: '#000000',
  shadow: '#000000',

  reverse: '#000000',
};

export const darkThemeColors = {
  // Primary
  primary: '#C1C1FF',
  onPrimary: '#1600A8',

  primaryContainer: '#413ED0',
  onPrimaryContainer: '#FCF8FF',

  primaryFixed: '#E2DFFF',
  primaryFixedDim: '#C1C1FF',
  onPrimaryFixed: '#0A006B',
  onPrimaryFixedVariant: '#312BC2',

  // Secondary
  secondary: '#C1C1FF',
  onSecondary: '#292963',

  secondaryContainer: '#363770',
  onSecondaryContainer: '#CDCCFF',

  secondaryFixed: '#E2DFFF',
  secondaryFixedDim: '#C1C1FF',
  onSecondaryFixed: '#13124D',
  onSecondaryFixedVariant: '#40407B',

  // Tertiary
  tertiary: '#FFA9FC',
  onTertiary: '#590060',

  tertiaryContainer: '#902298',
  onTertiaryContainer: '#FFFAFA',

  tertiaryFixed: '#FFD6F9',
  tertiaryFixedDim: '#FFA9FC',
  onTertiaryFixed: '#37003B',
  onTertiaryFixedVariant: '#7D0686',

  // Error
  error: '#FFB4AB',
  onError: '#690005',
  errorContainer: '#93000A',
  onErrorContainer: '#FFDAD6',

  // Caution
  caution: '#FFDF9E',
  onCaution: '#3F2E00',
  cautionContainer: '#F9BC00',
  onCautionContainer: '#463300',

  // Success
  success: '#8CD88C',
  onSuccess: '#00390E',
  successContainer: '#005318',
  onSuccessContainer: '#A7F5A6',

  // Surface
  onSurface: '#E4E1ED',
  onSurfaceVariant: '#C7C4D7',
  outline: '#908FA0',
  outlineVariant: '#464555',
  surface: '#13131B',
  surfaceBright: '#393842',
  surfaceContainer: '#1F1F27',
  surfaceContainerHigh: '#292932',
  surfaceContainerHighest: '#34343D',
  surfaceContainerLow: '#1B1B23',
  surfaceContainerLowest: '#0D0D16',
  surfaceDim: '#13131B',
  surfaceVariant: '#464555',

  // Inverse
  inverseOnSurface: '#302F39',
  inversePrimary: '#4B49DA',
  inverseSurface: '#E4E1ED',
  inverseSurfaceVariant: '#E3E0F4',

  scrim: '#000000',
  shadow: '#000000',

  reverse: '#ffffff',
};
