import React, { useCallback } from 'react';
import { CHIP_VARIANT_PRIMARY } from '@app/components/1-atoms/Chip';
import SettingAction from '@app/components/2-molecules/SettingAction';
import { useFormContext, Controller } from 'react-hook-form';
import useOperator from '@app/hooks/operators/useOperator';
import PropTypes from 'prop-types';
import SettingActionWithIconButton from '../2-molecules/SettingActionWithIconButton';
import { useIntl } from 'react-intl';
import useCanvasSelection from '@app/hooks/canvasselection/useCanvasSelection';

const CanvasSelectionChip = ({
  fieldInput,
  referencedModelFieldName,
  referencedShapeIdFieldName,
  formFieldName,
  renderDropDownField,
  disabled,
  operator,
}) => {
  const form = useFormContext();
  const { watch, setValue, register } = form;
  const intl = useIntl();
  register(referencedModelFieldName);
  register(referencedShapeIdFieldName);
  const watchReferencedModelInput = watch(referencedModelFieldName);
  const watchReferencedShapeIdField = watch(referencedShapeIdFieldName);
  const { getSelectedOperatorByValueId, getOperatorFieldOptions } =
    useOperator();
  const { getIsCanvasSelectionActiveForField, selectCanvasSelectionField } =
    useCanvasSelection();
  const canvasSelectionActive = getIsCanvasSelectionActiveForField(fieldInput);
  const canvasSelectionFieldFilled =
    watchReferencedModelInput && watchReferencedShapeIdField;

  const renderActiveChip = useCallback(() => {
    return (
      <SettingAction
        label={fieldInput?.name}
        chip={{
          label: intl.formatMessage({
            id: 'canvasselection.field.select',
            defaultMessage: 'Select',
          }),
          leadingIconName: 'arrow_selector_tool_0',
          variant: CHIP_VARIANT_PRIMARY,
          disabled,
        }}
      />
    );
  }, [fieldInput?.name, disabled, intl]);

  const renderCanvasSelectionDropDownField = useCallback(() => {
    const options = getOperatorFieldOptions(operator, fieldInput, form, false);
    let mouseSelectionOptions = options.filter(
      (o) => o.isCanvasSelectionOption,
    );
    mouseSelectionOptions = mouseSelectionOptions.filter(
      (o, i, arr) =>
        arr.findIndex((o2) => o2.operatorId === o.operatorId) === i,
    );
    const normalOptions = options.filter((o) => !o.isCanvasSelectionOption);
    if (mouseSelectionOptions.length > 0 && normalOptions[0]) {
      normalOptions[0].withDivider = true;
    }
    return renderDropDownField(
      fieldInput,
      [...mouseSelectionOptions, ...normalOptions],
      (operatorId) => selectCanvasSelectionField(fieldInput, operatorId),
    );
  }, [
    renderDropDownField,
    selectCanvasSelectionField,
    fieldInput,
    form,
    getOperatorFieldOptions,
    operator,
  ]);

  const resetSelection = useCallback(() => {
    const toReset = [
      formFieldName,
      referencedModelFieldName,
      referencedShapeIdFieldName,
    ];
    toReset.forEach((name) => {
      setValue(name, '', {
        shouldTouch: true,
        shouldDirty: true,
      });
    });
  }, [
    setValue,
    referencedModelFieldName,
    referencedShapeIdFieldName,
    formFieldName,
  ]);

  const showSelectedEntities = useCallback(() => {
    const pickedOperator = getSelectedOperatorByValueId(
      watchReferencedModelInput,
    );
    selectCanvasSelectionField(fieldInput, pickedOperator?.id);
  }, [
    selectCanvasSelectionField,
    fieldInput,
    watchReferencedModelInput,
    getSelectedOperatorByValueId,
  ]);

  const renderSelectionFilledChip = useCallback(() => {
    const numSelections = watchReferencedShapeIdField?.split(',').length ?? 0;
    return (
      <SettingActionWithIconButton
        label={fieldInput?.name}
        chip={{
          label: intl.formatMessage(
            {
              id: 'canvasselection.field.num_selections',
            },
            { numSelections },
          ),
          leadingIconName: 'arrow_selector_tool_0',
          variant: CHIP_VARIANT_PRIMARY,
          onClick: showSelectedEntities,
          disabled,
        }}
        iconButton={{
          iconName: 'close_0',
          onClick: resetSelection,
          disabled,
        }}
      />
    );
  }, [
    fieldInput?.name,
    showSelectedEntities,
    resetSelection,
    disabled,
    intl,
    watchReferencedShapeIdField,
  ]);

  return (
    <Controller
      name={formFieldName}
      render={() => {
        if (canvasSelectionActive) {
          return renderActiveChip();
        }
        if (canvasSelectionFieldFilled) {
          return renderSelectionFilledChip();
        }
        return renderCanvasSelectionDropDownField();
      }}
    />
  );
};

CanvasSelectionChip.propTypes = {
  fieldInput: PropTypes.object,
  referencedModelFieldName: PropTypes.string,
  referencedShapeIdFieldName: PropTypes.string,
  formFieldName: PropTypes.string,
  renderDropDownField: PropTypes.func,
  disabled: PropTypes.bool,
  operator: PropTypes.object,
};

export default CanvasSelectionChip;
