import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content, Icon, Text, StateLayer } from './Button.styled';

export const BUTTON_VARIANT_PRIMARY = 'primary';
export const BUTTON_VARIANT_OUTLINE = 'outline';
export const BUTTON_VARIANT_TEXT = 'text';
export const BUTTON_VARIANT_ELEVATED = 'elevated';
export const BUTTON_VARIANT_TONAL = 'tonal';
export const BUTTON_VARIANT_PLAIN = 'plain';

export default function Button({
  children,
  className,
  dataTestId = 'button',
  disabled,
  iconColor,
  iconName,
  loading,
  onClick,
  stretch = true,
  title,
  type = 'button',
  variant = BUTTON_VARIANT_PRIMARY,
}) {
  const disableActions = disabled || loading;

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      $loading={loading}
      onClick={disableActions ? undefined : onClick}
      stretch={stretch}
      tabIndex={disableActions ? -1 : 0}
      title={title}
      type={type}
      variant={variant}
    >
      <Content
        disabled={disabled}
        $loading={loading}
        variant={variant}
        withIcon={!!iconName}
      >
        <StateLayer disabled={disabled} $loading={loading} variant={variant} />

        {(loading || iconName) && (
          <Icon
            disabled={disabled}
            $loading={loading}
            name={loading ? 'progress_activity_0' : iconName}
            color={iconColor}
            variant={variant}
          />
        )}

        {!loading && (
          <Text
            data-testid={`${dataTestId}__text`}
            disabled={disabled}
            variant={variant}
          >
            {children}
          </Text>
        )}
      </Content>
    </Wrapper>
  );
}

Button.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  stretch: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf([
    BUTTON_VARIANT_PRIMARY,
    BUTTON_VARIANT_OUTLINE,
    BUTTON_VARIANT_TEXT,
    BUTTON_VARIANT_ELEVATED,
    BUTTON_VARIANT_TONAL,
    BUTTON_VARIANT_PLAIN,
  ]),
};
