export const AnalysisOperatorLegends = [
  {
    operatorName: 'ThermalSimulation',
    minValueName: 'MinimumTemperature',
    maxValueName: 'MaximumTemperature',
    outputName: 'FullTemperatureRange',
    legendTitle: 'Temperature (°C)',
    legendTitleKey: 'displaymodepanel.analysis.previouslayertemperature',
  },
  {
    operatorName: 'CurvatureAnalysis',
    minValueName: 'MinValue',
    maxValueName: 'MaxValue',
    outputName: 'CurvaturePreview',
    legendTitle: 'Curvature',
    legendTitleKey: 'displaymodepanel.analysis.curvatureanalysis',
  },
  {
    operatorName: 'CantileverAngleAnalysis',
    minValueName: 'MinValue',
    maxValueName: 'MaxValue',
    outputName: 'Result',
    legendTitle: 'Cantilever Angle',
    legendTitleKey: 'displaymodepanel.analysis.cantileverangleanalysis',
  },
  {
    operatorName: 'ThicknessAnalysis',
    minValueName: 'MinValue',
    maxValueName: 'MaxValue',
    outputName: 'Result',
    legendTitle: 'Thickness',
    legendTitleKey: 'displaymodepanel.analysis.thicknessanalysis',
  },
  {
    operatorName: 'Optimise',
    minValueName: 'Minimum',
    maxValueName: 'Maximum',
    outputName: 'AnalysisPreview',
    legendTitle: 'Optimise',
    legendTitleKey: 'displaymodepanel.analysis.optimise',
  },
  {
    operatorName: 'Edit',
    minValueName: 'CollisionFree',
    maxValueName: 'CollisionFound',
    outputName: 'CollisionMap',
    legendTitle: 'CollisionMap',
    legendTitleKey: 'displaymodepanel.analysis.edit',
  },
];
