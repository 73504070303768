import React from 'react';
import useCameraTool from '@hooks/toolBar/useCameraTool';
import useWorldTool from '@hooks/toolBar/useWorldTool';
import usePrinterTool from '@hooks/toolBar/usePrinterTool';
import usePerspectiveTool from '@hooks/toolBar/usePerspectiveTool';
import SceneActionBar from '@components/2-molecules/SceneActionBar';
import { DROP_DOWN_MENU_POSITION_TOP_RIGHT } from '@components/2-molecules/DropDownMenu';
import { Wrapper } from './WorkflowActionBar.styled';

const WorkflowActionBar = () => {
  const printerActions = usePrinterTool();
  const worldActions = useWorldTool();
  const cameraActions = useCameraTool();
  const perspectiveActions = usePerspectiveTool();

  const actions = [
    ...printerActions,
    ...worldActions,
    ...cameraActions.map((action, i) => ({ ...action, withDivider: i === 0 })),
  ].filter(({ disabled }) => !disabled);
  const perspectiveAction = perspectiveActions?.[0];

  return (
    <Wrapper>
      <SceneActionBar
        iconButtons={actions.map((action) => ({
          id: action.id,
          iconName: action.iconName,
          onClick: action.handler,
          title: action.name,
          selected: action.active,
          withDivider: action.withDivider,
        }))}
        iconButtonDropDown={
          !perspectiveAction || perspectiveAction?.disabled
            ? undefined
            : {
                id: perspectiveAction.id,
                leadingIconName: perspectiveAction.iconName,
                iconName: perspectiveAction.iconName,
                title: perspectiveAction.name,
                dropDownMenuItems: perspectiveAction.dropDownMenuItems,
                dropDownMenuPosition: DROP_DOWN_MENU_POSITION_TOP_RIGHT,
              }
        }
      />
    </Wrapper>
  );
};

WorkflowActionBar.propTypes = {};

export default WorkflowActionBar;
