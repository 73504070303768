//These must match the keys defined in the PrinterSubcomponentType enum
export const s3Directories = {
  //extruder
  extruderModel: 'MODEL',
  extruderCollisionModel: 'COLLISION_MODEL',
  //machine
  machineAxis: 'AXIS_',
  //bed
  bedBase: 'BED_BASE',
  bedAxis1: 'BED_AXIS_1',
  bedAxis2: 'BED_AXIS_2',
  //plinth
  plinthBase: 'PLINTH_BASE',
  plinthRailPlatform: 'PLINTH_RAIL_PLATFORM',
  //enclosure
  enclosureModel: 'ENCLOSURE',
};
