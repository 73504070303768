import { useMemo } from 'react';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useFile from '@hooks/files/useFile';

export default function useDesign() {
  const { getSelectedWorkflow } = useWorkflow();
  const { getSelectedOperatorOutputGeometryId } = useOperator();
  const { getProjectFile, getGeometry } = useFile();

  const workflow = getSelectedWorkflow();
  const designGeometryId = getSelectedOperatorOutputGeometryId();
  const design = getProjectFile(workflow?.workspaceId, designGeometryId);
  const designGeometryData = getGeometry(designGeometryId, workflow?.printerId);

  const designData = useMemo(
    () => ({ design, designGeometryData }),
    [design, designGeometryData],
  );

  return designData;
}
