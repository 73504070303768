import actionTypes from '@actions';
import { setFetchCompletedItem, setFetchProgressItem } from '@utils/reducer';

const initialState = {
  inProgess: [],
  completed: [],
};

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    /* Workspaces actions */
    case actionTypes.FETCH_ALL_WORKSPACES: {
      return setFetchProgressItem(state, 'workspaces');
    }

    case actionTypes.FETCH_ALL_WORKSPACES_SUCCEEDED:
    case actionTypes.FETCH_ALL_WORKSPACES_FAILED: {
      return setFetchCompletedItem(state, 'workspaces');
    }
    /* End of Workspaces actions */

    /* Concepts actions */
    case actionTypes.FETCH_ALL_CONCEPTS: {
      return setFetchProgressItem(state, 'concepts');
    }

    case actionTypes.FETCH_ALL_CONCEPTS_SUCCEEDED:
    case actionTypes.FETCH_ALL_CONCEPTS_FAILED: {
      return setFetchCompletedItem(state, 'concepts');
    }

    case actionTypes.UPGRADE_OPERATOR: {
      return setFetchProgressItem(state, 'upgradeOperators');
    }

    case actionTypes.UPGRADE_OPERATOR_SUCCEEDED:
    case actionTypes.UPGRADE_OPERATOR_FAILED: {
      return setFetchCompletedItem(state, 'upgradeOperators');
    }

    case actionTypes.ADD_OPERATOR:
    case actionTypes.ADD_OPERATOR_AT_POSITION_REQUEST: {
      return setFetchProgressItem(state, 'addOperator');
    }

    case actionTypes.ADD_OPERATOR_SUCCEEDED:
    case actionTypes.ADD_OPERATOR_FAILED:
    case actionTypes.ADD_OPERATOR_AT_POSITION_SUCCESS:
    case actionTypes.ADD_OPERATOR_AT_POSITION_FAILURE: {
      return setFetchCompletedItem(state, 'addOperator');
    }

    case actionTypes.UPDATE_CONCEPT_WITH_NOZZLE_REQUEST: {
      return setFetchProgressItem(state, 'updateConceptWithNozzle');
    }

    case actionTypes.UPDATE_CONCEPT_WITH_NOZZLE_SUCCESS:
    case actionTypes.UPDATE_CONCEPT_WITH_NOZZLE_FAILURE: {
      return setFetchCompletedItem(state, 'updateConceptWithNozzle');
    }

    case actionTypes.UPDATE_CONCEPT_WITH_MATERIAL_REQUEST: {
      return setFetchProgressItem(state, 'updateConceptWithMaterial');
    }

    case actionTypes.UPDATE_CONCEPT_WITH_MATERIAL_SUCCESS:
    case actionTypes.UPDATE_CONCEPT_WITH_MATERIAL_FAILURE: {
      return setFetchCompletedItem(state, 'updateConceptWithMaterial');
    }

    case actionTypes.UPDATE_WORKFLOW_PRINTER_CONFIG_REQUEST: {
      return setFetchProgressItem(state, 'updateWorkflowPrinterConfig');
    }

    case actionTypes.UPDATE_WORKFLOW_PRINTER_CONFIG_SUCCESS:
    case actionTypes.UPDATE_WORKFLOW_PRINTER_CONFIG_FAILURE: {
      return setFetchCompletedItem(state, 'updateWorkflowPrinterConfig');
    }

    /* End of Concepts actions */

    /* Designs actions */
    case actionTypes.FETCH_ALL_DESIGNS: {
      return setFetchProgressItem(state, 'designMetadata');
    }

    case actionTypes.DESIGNS_LOADED:
    case actionTypes.FETCH_ALL_DESIGNS_FAILED: {
      return setFetchCompletedItem(state, 'designMetadata');
    }

    case actionTypes.FETCH_CONCEPT_GEOMETRY: {
      return setFetchProgressItem(state, 'geometry');
    }

    case actionTypes.FETCH_CONCEPT_GEOMETRY_SUCCEEDED:
    case actionTypes.FETCH_CONCEPT_GEOMETRY_FAILED: {
      return setFetchCompletedItem(state, 'geometry');
    }
    /* End of Designs actions */

    /* Printers actions */
    case actionTypes.LOAD_MODEL: {
      return setFetchProgressItem(state, 'loadPrinterModel');
    }
    case actionTypes.LOAD_MODEL_SUCCEEDED: {
      return setFetchCompletedItem(state, 'loadPrinterModel');
    }

    case actionTypes.FETCH_POST_PROCESSOR_CONFIG_REQUEST: {
      return setFetchProgressItem(state, 'postProcessorConfig');
    }
    case actionTypes.FETCH_POST_PROCESSOR_CONFIG_SUCCESS:
    case actionTypes.FETCH_POST_PROCESSOR_CONFIG_FAILURE: {
      return setFetchCompletedItem(state, 'postProcessorConfig');
    }

    case actionTypes.UPDATE_POST_PROCESSOR_CONFIG_REQUEST: {
      return setFetchProgressItem(state, 'updatePostProcessorConfig');
    }
    case actionTypes.UPDATE_POST_PROCESSOR_CONFIG_SUCCESS:
    case actionTypes.UPDATE_POST_PROCESSOR_CONFIG_FAILURE: {
      return setFetchCompletedItem(state, 'updatePostProcessorConfig');
    }

    case actionTypes.UPLOAD_PRINTER_COMPONENT_REQUEST: {
      return setFetchProgressItem(state, 'uploadPrinterComponent');
    }
    case actionTypes.UPLOAD_PRINTER_COMPONENT_SUCCESS:
    case actionTypes.UPLOAD_PRINTER_COMPONENT_FAILURE: {
      return setFetchCompletedItem(state, 'uploadPrinterComponent');
    }

    case actionTypes.UPDATE_VISIBILITY_REQUEST: {
      return setFetchProgressItem(state, 'updateVisibility');
    }
    case actionTypes.UPDATE_VISIBILITY_SUCCESS:
    case actionTypes.UPDATE_VISIBILITY_FAILURE: {
      return setFetchCompletedItem(state, 'updateVisibility');
    }

    case actionTypes.FETCH_ALL_PRINTERS: {
      return setFetchProgressItem(state, 'printers');
    }

    case actionTypes.FETCH_ALL_PRINTERS_SUCCEEDED:
    case actionTypes.FETCH_ALL_PRINTERS_FAILED: {
      return setFetchCompletedItem(state, 'printers');
    }

    case actionTypes.FETCH_PRINTER_REQUEST: {
      return setFetchProgressItem(state, 'printerFetch');
    }

    case actionTypes.FETCH_PRINTER_SUCCESS:
    case actionTypes.FETCH_PRINTER_FAILURE: {
      return setFetchCompletedItem(state, 'printerFetch');
    }

    case actionTypes.FETCH_ALL_PRINTS: {
      return setFetchProgressItem(state, 'prints');
    }

    case actionTypes.FETCH_ALL_PRINTS_SUCCEEDED:
    case actionTypes.FETCH_ALL_PRINTS_FAILED: {
      return setFetchCompletedItem(state, 'prints');
    }
    /* End of Printers actions */

    /*Workflow Annotations actions */
    case actionTypes.CREATE_WORKFLOW_ANNOTATION_REQUEST: {
      return setFetchProgressItem(state, 'createWorkflowAnnotation');
    }

    case actionTypes.CREATE_WORKFLOW_ANNOTATION_SUCCESS:
    case actionTypes.CREATE_WORKFLOW_ANNOTATION_FAILURE: {
      return setFetchCompletedItem(state, 'createWorkflowAnnotation');
    }

    case actionTypes.FETCH_WORKFLOW_ANNOTATION_REQUEST: {
      return setFetchProgressItem(state, 'fetchWorkflowAnnotations');
    }
    case actionTypes.FETCH_WORKFLOW_ANNOTATION_SUCCESS:
    case actionTypes.FETCH_WORKFLOW_ANNOTATION_FAILURE: {
      return setFetchCompletedItem(state, 'fetchWorkflowAnnotations');
    }

    case actionTypes.DELETE_WORKFLOW_ANNOTATION_REQUEST: {
      return setFetchProgressItem(state, 'deleteWorkflowAnnotations');
    }
    case actionTypes.DELETE_WORKFLOW_ANNOTATION_SUCCESS:
    case actionTypes.DELETE_WORKFLOW_ANNOTATION_FAILURE: {
      return setFetchCompletedItem(state, 'deleteWorkflowAnnotations');
    }

    case actionTypes.UPDATE_WORKFLOW_ANNOTATION_REQUEST: {
      return setFetchProgressItem(state, 'updateWorkflowAnnotations');
    }
    case actionTypes.UPDATE_WORKFLOW_ANNOTATION_SUCCESS:
    case actionTypes.UPDATE_WORKFLOW_ANNOTATION_FAILURE: {
      return setFetchCompletedItem(state, 'updateWorkflowAnnotations');
    }

    /* End of Workflow Annotations actions */

    /* Default operators actions */
    case actionTypes.FETCH_DEFAULT_OPERATORS: {
      return setFetchProgressItem(state, 'defaultOperators');
    }

    case actionTypes.FETCH_DEFAULT_OPERATORS_SUCCEEDED:
    case actionTypes.FETCH_DEFAULT_OPERATORS_FAILED: {
      return setFetchCompletedItem(state, 'defaultOperators');
    }
    /* End of Default operators actions */

    /* Dashboards actions */
    case actionTypes.FETCH_ALL_DASHBOARDS: {
      return setFetchProgressItem(state, 'dashboards');
    }

    case actionTypes.DASHBOARDS_LOADED:
    case actionTypes.FETCH_ALL_DASHBOARDS_FAILED: {
      return setFetchCompletedItem(state, 'dashboards');
    }

    case actionTypes.FETCH_PRINTER_CAMERAS_REQUEST: {
      return setFetchProgressItem(state, 'printerCameras');
    }

    case actionTypes.FETCH_PRINTER_CAMERAS_SUCCESS:
    case actionTypes.FETCH_PRINTER_CAMERAS_FAILURE: {
      return setFetchCompletedItem(state, 'printerCameras');
    }
    /* End of Dashboards actions */

    /* Templates actions */
    case actionTypes.FETCH_TEMPLATES_REQUEST: {
      return setFetchProgressItem(state, 'templates');
    }

    case actionTypes.FETCH_TEMPLATES_SUCCESS:
    case actionTypes.FETCH_TEMPLATES_FAILURE: {
      return setFetchCompletedItem(state, 'templates');
    }

    case actionTypes.EXPLODE_TEMPLATE_REQUEST: {
      return setFetchProgressItem(state, 'explodeTemplate');
    }

    case actionTypes.EXPLODE_TEMPLATE_SUCCESS:
    case actionTypes.EXPLODE_TEMPLATE_FAILURE: {
      return setFetchCompletedItem(state, 'explodeTemplate');
    }
    /* End of Templates actions */

    /* Templates actions */
    case actionTypes.UPDATE_OPERATOR_ORDER: {
      return setFetchProgressItem(state, 'reorderingOperators');
    }

    case actionTypes.UPDATE_OPERATOR_ORDER_SUCCEEDED:
    case actionTypes.UPDATE_OPERATOR_ORDER_FAILED: {
      return setFetchCompletedItem(state, 'reorderingOperators');
    }
    /* End of Templates actions */

    /* GPT actions */
    case actionTypes.GPT_CONVERSATION_REQUEST: {
      return setFetchProgressItem(state, 'gptConversation');
    }

    case actionTypes.GPT_CONVERSATION_SUCCESS:
    case actionTypes.GPT_CONVERSATION_FAILURE:
    case actionTypes.GPT_RESET: {
      return setFetchCompletedItem(state, 'gptConversation');
    }

    case actionTypes.GPT_APPLY_CHANGES_REQUEST: {
      return setFetchProgressItem(state, 'gptApplyChanges');
    }

    case actionTypes.GPT_APPLY_CHANGES_SUCCESS:
    case actionTypes.GPT_APPLY_CHANGES_FAILURE: {
      return setFetchCompletedItem(state, 'gptApplyChanges');
    }

    case actionTypes.LOG_OUT_REQUEST: {
      return setFetchProgressItem(state, 'logout');
    }

    case actionTypes.LOG_OUT_SUCCESS:
    case actionTypes.LOG_OUT_FAILURE: {
      return setFetchCompletedItem(state, 'logout');
    }
    /* End of GPT actions */

    case actionTypes.TOKEN_EXPIRE_LOG_OUT_SUCCESS: {
      return initialState;
    }

    /* Settings actions */
    case actionTypes.GENERATE_MFA_CONNECTOR_KEY_REQUEST: {
      return setFetchProgressItem(state, 'generateMFAConnectorKey');
    }

    case actionTypes.GENERATE_MFA_CONNECTOR_KEY_SUCCESS:
    case actionTypes.GENERATE_MFA_CONNECTOR_KEY_FAILURE: {
      return setFetchCompletedItem(state, 'generateMFAConnectorKey');
    }

    case actionTypes.VALIDATE_MFA_CODE_REQUEST: {
      return setFetchProgressItem(state, 'validateMFACode');
    }

    case actionTypes.VALIDATE_MFA_CODE_SUCCESS:
    case actionTypes.VALIDATE_MFA_CODE_FAILURE: {
      return setFetchCompletedItem(state, 'validateMFACode');
    }

    case actionTypes.DEACTIVATE_MFA_REQUEST: {
      return setFetchProgressItem(state, 'deactivateMFA');
    }

    case actionTypes.DEACTIVATE_MFA_SUCCESS:
    case actionTypes.DEACTIVATE_MFA_FAILURE: {
      return setFetchCompletedItem(state, 'deactivateMFA');
    }
    /* End of Settings actions */

    /* Operators actions */
    case actionTypes.UPDATE_OPERATOR_TAG_REQUEST: {
      return setFetchProgressItem(state, 'updateOperatorTag');
    }

    case actionTypes.UPDATE_OPERATOR_TAG_SUCCESS:
    case actionTypes.UPDATE_OPERATOR_TAG_FAILURE: {
      return setFetchCompletedItem(state, 'updateOperatorTag');
    }
    /* End of Operators actions */

    /* Materials actions */
    case actionTypes.UPDATE_MATERIAL_REQUEST: {
      return setFetchProgressItem(state, 'updateMaterial');
    }
    case actionTypes.UPDATE_MATERIAL_SUCCESS:
    case actionTypes.UPDATE_MATERIAL_FAILURE: {
      return setFetchCompletedItem(state, 'updateMaterial');
    }

    /* End of Materials actions */
    case actionTypes.LOADING_STARTED: {
      return setFetchProgressItem(state, 'loading');
    }
    case actionTypes.LOADING_FINISHED: {
      return setFetchCompletedItem(state, 'loading');
    }

    /* Version actions */
    case actionTypes.FETCH_VERSION_REQUEST: {
      return setFetchProgressItem(state, 'version');
    }

    case actionTypes.FETCH_VERSION_SUCCESS:
    case actionTypes.FETCH_VERSION_FAILURE: {
      return setFetchCompletedItem(state, 'version');
    }
    /* End of Version actions */

    default:
      return state;
  }
};

export default fetchReducer;
