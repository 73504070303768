import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import '@stylesheets/visualization.css';
import { PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export const ThreeJsContext = createContext();

// This context provider is passed to any component requiring the context
export const ThreeJsProvider = ({ children }) => {
  const [state, setState] = useState(null);
  const [orbitControls, setOrbitControls] = useState(null);

  const contextValue = useMemo(
    () => ({
      state,
      orbitControls,
      setState,
      setOrbitControls,
    }),
    [state, orbitControls],
  );

  return (
    <ThreeJsContext.Provider value={contextValue}>
      {children}
    </ThreeJsContext.Provider>
  );
};

ThreeJsProvider.propTypes = {
  children: PropTypes.object,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Visualization = (props) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [controls, setControls] = useState(null);
  const [threeState, setThreeState] = useState(null);
  const [threeStateInitialized, setThreeStateInitialized] = useState(false);

  useEffect(() => {
    return () => {
      //delete controls;
      if (controls) controls.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { printer } = props;
  const prevState = usePrevious({ printer });

  const globalThreeState = useContext(ThreeJsContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!threeState) return;
    controls?.update?.();
    if (!threeStateInitialized || shouldUpdateObjects(prevState)) {
      setThreeStateInitialized(true);

      garbageCollectOldObjects();
      addDefaultObjects();
      if (printer) {
        threeState.scene.add(printer);
      }
      globalThreeState?.setState(threeState);
      globalThreeState?.setOrbitControls(controls);
    }
  });

  const addDefaultObjects = () => {
    if (threeState) {
      if (props.showGrid) {
        const gridSize = props.gridSizeX;
        const grid = new THREE.GridHelper(
          gridSize,
          gridSize / 2,
          new THREE.Color('#222222'),
          new THREE.Color('#111111'),
        );
        const scaleRatio = 0.02;
        let plinthHeight = 0;
        if (printer?.plinth) {
          plinthHeight = printer.plinth.height;
        }
        grid.translateZ(-plinthHeight * scaleRatio);
        grid.rotation.x = -Math.PI / 2;
        threeState.scene.add(grid);

        const axes = new THREE.AxesHelper(3);
        axes.material.depthFunc = THREE.AlwaysDepth;
        threeState.scene.add(axes);
      }
      const hemiLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 0.2);
      hemiLight.position.set(0, 0, 1);
      threeState.scene.add(hemiLight);

      const light = new THREE.SpotLight(0xeadcdc, 0.5);
      light.position.set(-50, 50, 150);
      light.castShadow = true;
      threeState.scene.add(light);

      const dirLight = new THREE.DirectionalLight(0xffffff, 0.9);
      dirLight.color.setHSL(1, 1, 0.95);
      dirLight.position.set(0.75, 0.75, 0.75);
      dirLight.position.multiplyScalar(60);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      const d = 50;
      dirLight.shadow.camera.left = -d;
      dirLight.shadow.camera.right = d;
      dirLight.shadow.camera.top = d;
      dirLight.shadow.camera.bottom = -d;
      dirLight.shadow.camera.far = 3500;
      dirLight.shadow.bias = -0.0001;
      threeState.scene.add(dirLight);

      const dirLight2 = new THREE.DirectionalLight(0xffffff, 0.6);
      dirLight2.color.setHSL(1, 1, 0.95);
      dirLight2.position.set(-0.75, -0.75, 0.75);
      dirLight2.position.multiplyScalar(60);
      dirLight2.castShadow = false;
      threeState.scene.add(dirLight2);
    }
  };

  const garbageCollectOldObjects = () => {
    if (!threeState || !threeState.scene) {
      return;
    }

    while (threeState.scene.children.length > 0) {
      const oldObject = threeState.scene.children[0];
      // Calling the .dispose() method on old objects is necessary
      // to avoid memory leaks. Simply removing them from the scene
      // doesn't actually free up the memory.
      oldObject.traverse((child) => {
        //does traverse iterate through all children of children?
        if (child.geometry) {
          child.geometry?.dispose();
          if (child.material && Array.isArray(child.material)) {
            child.material.forEach((d) => d.dispose());
          } else {
            child.material?.dispose();
          }
        }
      });
      threeState.scene.remove(threeState.scene.children[0]);
    }
  };

  const shouldUpdateObjects = (prevState) => {
    return printer?.uuid != prevState.printer?.uuid;
  };

  const hasAncestorWhichDisablesThreeJs = (element) => {
    if (!element) return false;

    for (const className of element.classList) {
      if (className === 'disable-threejs-controls') {
        return true;
      }
    }

    return hasAncestorWhichDisablesThreeJs(element.parentElement);
  };

  const initializeScene = (state) => {
    setThreeState(state);
    globalThreeState?.setState(state);
    updateCamera(state);
    addDefaultObjects();
  };

  const updateCamera = (state) => {
    const orbits = new OrbitControls(
      state.camera,
      document.getElementById('threejs-controllers-div'),
    );
    orbits.zoomSpeed = 3;
    orbits.maxDistance = 1000;
    orbits.minDistance = 0.01;
    setControls(orbits);
    globalThreeState?.setOrbitControls(orbits);
  };

  function handleMouseOrFocus(e) {
    const target = e.target;
    if (!target || !controls) return true;
    if (hasAncestorWhichDisablesThreeJs(target)) {
      controls.enabled = false;
    } else {
      controls.enabled = true;
    }
    return false;
  }

  return (
    <div
      id="threejs-controllers-div"
      className="threejs-container"
      onMouseOver={handleMouseOrFocus}
      onFocus={handleMouseOrFocus}
    >
      <Canvas
        className="threejs"
        onCreated={(state) => {
          initializeScene(state);
        }}
        shadows={true}
        gl={{
          'shadowMap.enabled': true,
          alpha: true,
        }}
      >
        <PerspectiveCamera
          makeDefault
          position-x={props.cameraX || 0}
          position-y={props.cameraY || -20}
          position-z={props.cameraZ || 20}
          up={[0, 0, 1]}
          fov={15}
          aspect={width / height}
          near={0.1}
          far={10000}
          visible={false}
          onUpdate={(self) => {
            if (!threeState) return;
            self.updateProjectionMatrix();
          }}
        />
      </Canvas>
      <div className="threejs-react-container">{props.children}</div>
    </div>
  );
};

Visualization.propTypes = {
  /* A React component to display on top of the ThreeJS canvas */
  children: PropTypes.node,
  /* Enable/disable rendering of the XY grid */
  showGrid: PropTypes.bool,
  printer: PropTypes.object,

  cameraX: PropTypes.number,
  cameraY: PropTypes.number,
  cameraZ: PropTypes.number,
  gridSizeX: PropTypes.number,
};
