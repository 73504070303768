import styled, { css } from 'styled-components';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import {
  MESSAGE_BOX_VARIANT_ERROR,
  MESSAGE_BOX_VARIANT_MESSAGE,
  MESSAGE_BOX_VARIANT_SNACKBAR,
} from './MessageBox';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  ${({
    withBorderRadius,
    withEndingIconButton,
    theme: { borderRadius, spacing },
  }) => css`
    gap: ${spacing.level2};
    padding-left: ${spacing.level5};
    padding-right: ${spacing.level3};

    ${when(
      withBorderRadius,
      `
        border-radius: ${borderRadius?.small};
      `,
    )}

    ${when(
      withEndingIconButton,
      `
        padding-right: 0;
      `,
    )}
  `}
`;

export const Content = styled.div`
  display: flex;
  padding: var(--Level-4, 12px) 0px;

  ${({ withEndingIconButton, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level4} 0px;

    ${when(
      withEndingIconButton,
      `
        max-width: 72%;
      `,
    )}
  `}
`;

export const StateLayer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.16;

  ${({ variant, theme: { colors } }) => css`
    ${when(
      variant === MESSAGE_BOX_VARIANT_ERROR,
      `
        background-color: ${colors?.error};
      `,
    )}

    ${when(
      variant === MESSAGE_BOX_VARIANT_MESSAGE,
      `
        background-color: ${colors?.primary};
      `,
    )}

    ${when(
      variant === MESSAGE_BOX_VARIANT_SNACKBAR,
      `
        background-color: ${colors?.surfaceVariant};
        opacity: 1;
        z-index: -1;
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  align-items: flex-start;
  flex-shrink: 0;

  ${({ variant, theme: { colors } }) => css`
    fill: ${colors?.onSurfaceVariant};

    ${when(
      variant === MESSAGE_BOX_VARIANT_ERROR,
      `
        fill: ${colors?.error};
      `,
    )}

    ${when(
      variant === MESSAGE_BOX_VARIANT_MESSAGE,
      `
        fill: ${colors?.primary};
      `,
    )}

    ${when(
      variant === MESSAGE_BOX_VARIANT_SNACKBAR,
      `
        fill: ${colors?.inverseSurfaceVariant};
      `,
    )}
  `}
`;

export const Label = styled(Text).attrs(({ variant: textVariant }) => {
  const variant = TEXT_VARIANT_BODY_MEDIUM;
  let color = 'onSecondaryContainer';

  if (textVariant === MESSAGE_BOX_VARIANT_ERROR) {
    color = 'onErrorContainer';
  }

  if (textVariant === MESSAGE_BOX_VARIANT_SNACKBAR) {
    color = 'inverseSurfaceVariant';
  }

  return {
    variant,
    color,
  };
})`
  place-self: center;
  white-space: break-spaces;
`;

export const EndingControls = styled.div`
  ${flexCenterVertical}
  margin-left: auto;

  ${({ theme: { spacing } }) => css`
    min-height: ${spacing.level9};
  `}
`;
