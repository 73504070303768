import React, { createContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT } from '@components/2-molecules/DropDownMenu';

const DropDownMenuContext = createContext();

const initialAnchorElement = null;
const initialDropDownMenuIsOpen = false;
const initialDropDownMenuId = null;
const initialDropDownMenuData = {};
const initialDropDownMenuStyle = {
  position: 'fixed',
  opacity: 0,
};
const initialDropDownMenuPosition = DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT;

export const DropDownMenuProvider = ({ children }) => {
  const [anchorElement, setAnchorElement] = useState(initialAnchorElement);
  const [dropDownMenuIsOpen, setDropDownMenuIsOpen] = useState(
    initialDropDownMenuIsOpen,
  );
  const [dropDownMenuId, setDropDownMenuId] = useState(initialDropDownMenuId);
  const [dropDownMenuData, setDropDownMenuData] = useState(
    initialDropDownMenuData,
  );
  const [dropDownMenuStyle, setDropDownMenuStyle] = useState(
    initialDropDownMenuStyle,
  );
  const [dropDownMenuPosition, setDropDownMenuPosition] = useState(
    initialDropDownMenuPosition,
  );

  const resetDropDownMenuData = useCallback(
    () => setDropDownMenuData(initialDropDownMenuData),
    [],
  );

  const updateDropDownMenuData = useCallback(
    (data = {}) => {
      if (!dropDownMenuData) return;

      setDropDownMenuData((prevDropDownMenuData) => ({
        ...prevDropDownMenuData,
        ...(data || {}),
      }));
    },
    [dropDownMenuData],
  );

  const resetDropDown = useCallback(() => {
    setAnchorElement(initialAnchorElement);
    setDropDownMenuIsOpen(initialDropDownMenuIsOpen);
    setDropDownMenuId(initialDropDownMenuId);
    setDropDownMenuData(initialDropDownMenuData);
    setDropDownMenuStyle(initialDropDownMenuStyle);
    setDropDownMenuPosition(initialDropDownMenuPosition);
  }, [
    setDropDownMenuData,
    setDropDownMenuId,
    setDropDownMenuIsOpen,
    setDropDownMenuStyle,
    setDropDownMenuPosition,
  ]);

  const value = useMemo(
    () => ({
      anchorElement,
      dropDownMenuData,
      dropDownMenuId,
      dropDownMenuIsOpen,
      dropDownMenuPosition,
      dropDownMenuStyle,
      resetDropDown,
      resetDropDownMenuData,
      setAnchorElement,
      setDropDownMenuData,
      setDropDownMenuId,
      setDropDownMenuIsOpen,
      setDropDownMenuPosition,
      setDropDownMenuStyle,
      updateDropDownMenuData,
    }),
    [
      anchorElement,
      dropDownMenuData,
      dropDownMenuId,
      dropDownMenuIsOpen,
      dropDownMenuPosition,
      dropDownMenuStyle,
      resetDropDown,
      resetDropDownMenuData,
      setAnchorElement,
      setDropDownMenuData,
      setDropDownMenuId,
      setDropDownMenuIsOpen,
      setDropDownMenuPosition,
      setDropDownMenuStyle,
      updateDropDownMenuData,
    ],
  );

  return (
    <DropDownMenuContext.Provider value={value}>
      {children}
    </DropDownMenuContext.Provider>
  );
};

DropDownMenuProvider.propTypes = {
  children: PropTypes.any,
};

export default DropDownMenuContext;
