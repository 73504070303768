import { useQueryClient, useMutation } from '@tanstack/react-query';
import { fileQueryKeys } from '@hooks/files/useFileQueries';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { concat } from 'lodash';

export const fileMutationKeys = {
  updateFile: ['updateFile'],
  deleteFile: ['deleteFile'],
  publishFile: ['publishFile'],
  createDesignThumbnail: ['createDesignThumbnail'],
};

export default function useFileMutations() {
  const queryClient = useQueryClient();

  const updateFileMutation = useMutation({
    mutationKey: fileMutationKeys.updateFile,
    mutationFn: (file = {}) =>
      client
        .put(
          endpoints.design
            .replace(':designId', file?.id)
            .concat(`?filename=${file?.name}`),
        )
        .then((res) => res.data),
    onSuccess: (_, fileMutation) => {
      const updater = (data) => {
        return data
          ? data?.map((file) => {
              if (file.id === fileMutation?.id) {
                return {
                  ...file,
                  filename: `${fileMutation?.name}.${fileMutation?.fileExtension}`,
                };
              }

              return file;
            })
          : undefined;
      };

      queryClient.setQueryData(
        fileQueryKeys.projectFiles(fileMutation?.projectId),
        updater,
      );
    },
  });

  const deleteFileMutation = useMutation({
    mutationKey: fileMutationKeys.deleteFile,
    mutationFn: (file) =>
      client
        .delete(endpoints.design.replace(':designId', file?.id))
        .then((res) => res.data),
    onSuccess: (_, fileMutation) => {
      const updater = (data) => {
        return data
          ? data?.filter((files) => files.id !== fileMutation?.id)
          : undefined;
      };

      queryClient.setQueryData(
        fileQueryKeys.projectFiles(fileMutation?.projectId),
        updater,
      );
    },
  });

  const publishFileMutation = useMutation({
    mutationKey: fileMutationKeys.publishFile,
    mutationFn: ({ designId, designName }) =>
      client
        .post(
          endpoints.publishDesign
            .replace(':designId', designId)
            .replace(':designName', designName),
        )
        .then((res) => res.data),
    onSuccess: (publishedFile, { projectId }) => {
      const updater = (data) => {
        return data ? concat(data, publishedFile) : undefined;
      };

      queryClient.setQueryData(fileQueryKeys.projectFiles(projectId), updater);
    },
  });

  const createDesignThumbnailMutation = useMutation({
    mutationKey: fileMutationKeys.createDesignThumbnail,
    mutationFn: ({ fileId, thumbnailBlob }) =>
      client
        .postForm(endpoints.designThumbnail.replace(':designId', fileId), {
          file: thumbnailBlob,
        })
        .then((res) => res.data),
    onSuccess: ({ signedUrl }, { fileId, projectId }) => {
      const updater = (data) => {
        return {
          ...data,
          thumbnailsData: {
            ...(data?.thumbnailsData || {}),
            [fileId]: signedUrl,
          },
        };
      };

      queryClient.setQueryData(
        fileQueryKeys.projectFileThumbnails(projectId),
        updater,
      );
    },
  });

  return {
    updateFileMutation,
    deleteFileMutation,
    publishFileMutation,
    createDesignThumbnailMutation,
  };
}
