import styled, { css } from 'styled-components';
import { Wrapper as FieldWrapper } from '@components/1-atoms/Field/Field.styled';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${({ withIdentation, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level3} ${spacing.level5};

    ${when(
      withIdentation,
      `
        padding-left: ${spacing.level7};
      `,
    )}
  `}
`;

export const LeftColumn = styled.div`
  ${flexCenterVertical}
  flex: 1;
  word-break: break-all;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level3} 0px ${spacing.level1};
  `}
`;

export const RightColumn = styled.div`
  min-width: 208px;
  ${flexCenterVertical}
  gap: 8px;

  ${({ withTwoFields, withThreeFields }) => css`
    ${when(
      withTwoFields,
      `
        ${FieldWrapper} {
          width: 100px;
        }
      `,
    )}

    ${when(
      withThreeFields,
      `
        ${FieldWrapper} {
          width: 90px;
        }
      `,
    )}
  `}
`;
