import * as actionTypes from '@constants/actionTypes';

export const openModal = (data = {}) => ({
  type: actionTypes.OPEN_MODAL,
  payload: { data },
});

export const closeModal = () => ({
  type: actionTypes.CLOSE_MODAL,
});
