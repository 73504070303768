import styled, { css } from 'styled-components';
import { Wrapper } from '@components/2-molecules/OperatorList/OperatorList.styled';
import { flexColumn, when } from '@stylesheets/helpers';

export const List = styled(Wrapper)`
  flex: 1;
  gap: 0;
`;

export const DraggableItem = styled.div`
  padding-block: 4px;
  outline: none;
  position: relative;

  ${({ isDragging }) => css`
    ${when(
      isDragging,
      `
        pointer-events: none;
      `,
    )}
  `}
`;

export const DragProvider = styled.div`
  ${({ remove }) => css`
    ${when(
      remove,
      `
        display: none;
      `,
    )}
  `}
`;

export const OperatorMessageWrapper = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level4};
    padding-top: ${spacing.level4};
    padding-inline: ${spacing.level5};
  `}
`;

export const OperatorContent = styled.div`
  ${flexColumn}
  min-height: 96px;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level5};
  `}
`;

export const OperatorFooter = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-block: ${spacing.level5};
  `}
`;
