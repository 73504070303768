import React, { useMemo, useCallback } from 'react';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useFileMutations from '@hooks/files/useFileMutations';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Field from '@components/1-atoms/Field';

const MODAL_ID = ModalDataTypes.PUBLISH_FILE;

const PublishFileDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { publishFileMutation } = useFileMutations();

  const { output, projectId } = useMemo(
    () => getDialogData(MODAL_ID),
    [getDialogData],
  );

  const formInitialValues = useMemo(
    () => ({
      name: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(
            1,
            intl.formatMessage({
              id: 'publishfile.dialog.outputName.validation.min',
              defaultMessage: 'Name must be at least 1 characters long',
            }),
          )
          .max(
            256,
            intl.formatMessage({
              id: 'publishfile.dialog.outputName.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'publishfile.dialog.outputName.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          ),
      }),
    [intl],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) => {
      const designName = values.name;
      const designId = output.value;

      return publishFileMutation
        .mutateAsync({
          projectId,
          designName,
          designId,
        })
        .then(handleClose);
    },
    [projectId, output.value, publishFileMutation, handleClose],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          dataTestId="publish-file-dialog"
          dialogId={MODAL_ID}
          title={intl.formatMessage({
            id: 'publishfile.dialog.title',
            defaultMessage: 'Publish file',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
          confirmButtonText={intl.formatMessage({
            id: 'general.publish',
            defaultMessage: 'Publish',
          })}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          loading={isSubmitting}
        >
          <FormikField
            autoFocus
            component={Field}
            dataTestId="publish-file-dialog__name-field"
            disabled={isSubmitting}
            name="name"
            placeholder={intl.formatMessage({
              id: 'publishfile.dialog.form.field.name.placeholder',
              defaultMessage: 'Name',
            })}
          />
        </Dialog>
      )}
    </Formik>
  );
};

export default PublishFileDialog;
