import { computationFailed } from '@actions/computationProgressActions';
import {
  SHOW_ERROR_DIALOG,
  DISMISS_ERROR_DIALOG,
} from '../constants/actionTypes.js';

import { trackGeometricError } from '../utils/GaTracker.js';

export const showErrorDialog = (
  title,
  message,
  messageKey,
  args,
  operatorName,
  originalMessage,
) => {
  if (operatorName) {
    trackGeometricError(operatorName, messageKey);
  }
  return {
    type: SHOW_ERROR_DIALOG,
    title: title,
    message: message,
    messageKey: messageKey,
    arguments: args,
    originalMessage,
  };
};

export const dismissErrorDialog = () => ({
  type: DISMISS_ERROR_DIALOG,
});

export const manageErrorDuringComputation = (error) => (dispatch) => {
  dispatch(computationFailed());
  manageError(error)(dispatch);
};

export const manageError = (error) => (dispatch) => {
  const originalMessage = error?.message;
  let message = error?.message;

  if (error?.operator) {
    message =
      'Operator: ' +
      error?.operator?.tag +
      '\n(' +
      error?.operator?.name +
      ')\n\n' +
      message;
  }
  if (!message) {
    message = 'Internal problem occurred';
  }

  const _arguments = error?.arguments?.map((singleError) =>
    typeof singleError === 'object' ? JSON.stringify(singleError) : singleError,
  );

  dispatch(
    showErrorDialog(
      'Error',
      message,
      error?.messageKey,
      _arguments,
      error?.operator?.name,
      originalMessage,
    ),
  );
};
