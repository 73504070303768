import styled, { css } from 'styled-components';
import FieldComponent from '@components/1-atoms/Field';
import { Input } from '@components/1-atoms/Field/Field.styled';
import { when } from '@stylesheets/helpers';

export const Field = styled(FieldComponent)`
  ${Input} {
    min-height: 56px;
    resize: none;

    ${({ resizible, theme: { spacing } }) => css`
      padding: ${spacing.level5};
      ${when(
        resizible,
        `
          resize: vertical;
        `,
      )}
    `}
  }
`;
