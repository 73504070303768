import styled, { css } from 'styled-components';
import { when, flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: 100%;
  ${flexColumn}

  ${({ inset, middleInset, theme: { spacing } }) => css`
    ${when(
      inset,
      `
        padding-left: ${spacing.level5};
      `,
    )}

    ${when(
      middleInset,
      `
        padding-inline: ${spacing.level5};
      `,
    )}
  `}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.outlineVariant};
  `}
`;
