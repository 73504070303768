export const spacing = {
  level1: '2px',
  level2: '4px',
  level3: '8px',
  level4: '12px',
  level5: '16px',
  level6: '24px',
  level7: '32px',
  level8: '40px',
  level9: '48px',
  level10: '64px',
};

export const borderRadius = {
  extrasmall: '4px',
  small: '8px',
  large: '16px',
  extralarge: '28px',
  full: '100px',
};

export default {
  spacing,
  borderRadius,
};
