import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useDesign from '@hooks/useDesign';
import useCamera from '@hooks/useCamera';
import { getSimulation } from '@selectors/conceptSelectors';
import { getCameraConfig } from '@selectors/sceneSelectors';
import { cameraPositionConfigs } from '@constants/camera';

export const PERSPECTIVE_ACTIONS = {
  PERSPECTIVE: 'Perspective',
};

export default function usePerspectiveTool() {
  const intl = useIntl();
  const selectedPerspective = useSelector(getCameraConfig);
  const { updateCamera } = useCamera();
  const { design, designGeometryData } = useDesign();
  const simulation = useSelector(getSimulation());
  const showLines = !((!design || !designGeometryData) && !simulation.isActive);
  const disableActions = !showLines;

  const handlePerspectiveSelection = useCallback(
    (option) => {
      updateCamera(option);
    },
    [updateCamera],
  );

  const actions = useMemo(
    () => [
      {
        id: PERSPECTIVE_ACTIONS.PERSPECTIVE,
        disabled: disableActions,
        name:
          selectedPerspective?.title ||
          intl.formatMessage({
            id: 'viewport_selection.placeholder',
            defaultMessage: PERSPECTIVE_ACTIONS.PERSPECTIVE,
          }),
        iconName: selectedPerspective?.iconName || 'mesh_0',
        optionHandler: handlePerspectiveSelection,
        dropDownMenuItems: cameraPositionConfigs.map((options) => ({
          id: options.id,
          label: options.title,
          leadingIconName: options.iconName,
          selected: selectedPerspective?.title === options.title,
          onClick: () => handlePerspectiveSelection(options),
        })),
      },
    ],
    [
      intl,
      disableActions,
      selectedPerspective?.title,
      selectedPerspective?.iconName,
      handlePerspectiveSelection,
    ],
  );

  return actions;
}
