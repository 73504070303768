import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

export const SPRITE_ICON_NAMES = [
  'edit_0',
  'visibility_lock_0',
  'visibility_lock_1',
  'push_pin_0',
  'send_0',
  'person_add_0',
  'push_pin_1',
  'texture_0',
  'spoke_0',
  'stop_player_1',
  'play_arrow_1',
  'play_arrow_0',
  'draft_0',
  'note_add',
  'upload_file_0',
  'open_in_new_0',
  'add_0',
  'horizontal_rule_0',
  'close_0',
  'arrow_drop_down_1',
  'done_0',
  'thumbnail_bar_0',
  'precision_manufacturing_0',
  'upload_0',
  'download_0',
  'expand_less_0',
  'expand_more_0',
  'chevron_left_0',
  'chevron_right_0',
  'mesh_0',
  'frame_inspect_0',
  'point_0',
  'more_vert_0',
  'sync_0',
  'delete_0',
  'rotate_right_0',
  'lock_open_right_0',
  'lock_0',
  'lock_1',
  'drag_pan_0',
  'settings_0',
  'tune_0',
  'toolpath_1',
  'search_0',
  'info_0',
  'hot_bed_0',
  'output_0',
  'radio_button_checked_0',
  'radio_button_unchecked_0',
  'space_dashboard_0',
  'clock_loader_60_1',
  'network_node_0',
  'discover_tune_0',
  'help_0',
  'progress_activity_0',
  'content_copy_0',
  'error_0',
  'more_horiz_0',
  'ai_on_1',
  'visibility_off_0',
  'visibility_on_1',
  'visibility_on_0',
  'folder_0',
  'menu',
  'input_0',
  'person_0',
  'home_0',
  'school_0',
  'cancel_0',
  'show_chart_0',
  'arrow_back_0',
  'arrow_forward_0',
  'shapes_0',
  'layers_0',
  'manufacturing_1',
  'format_list_bulleted_0',
  'event_list_0',
  'drag_indicator_0',
  'sort_ascending_0',
  'sort_descending_0',
  'filter_alt_0',
  'combine_1',
  'combine_0',
  'subtract_0',
  'seam_0',
  'edit_seam_0',
  'wireframe_0',
  'solid_0',
  'Gosted_0',
  'Polygon_0',
  'grid_4x4_0',
  'infill_0',
  'query_stats_0',
  'bookmark_0',
  'keyboard_command_key_0',
  'live_tv_0',
  'videocam_0',
  'category_0',
  'Direction_0',
  'left_view_0',
  'top_view_0',
  'right_view_0',
  'front_view_0',
  'back_view_0',
  'bottom_view_0',
  'vertices_0',
  'measure_0',
  'expand_all_0',
  'collapse_all_0',
  'arrow_upward_0',
  'arrow_downward_0',
  'plane_0',
  'crop_0',
  'Dot_1',
  'Dot_s_1',
  'account_tree_0',
  'link_0',
  'link_off_0',
  'check_small_0',
  'check_indeterminate_small_0',
  'spool_0',
  'language_0',
  'contrast_0',
  'extrusion_0',
  'save_0',
  'upgrade_0',
  'optimise_0',
  'pause_1',
  'nest_true_radiant_0',
  'laps_0',
  'travel_0',
  'enclosure_0',
  'view_in_ar_0',
  'area_of_work_0',
  'magic_0',
  'dark_mode_0',
  'light_mode_0',
  'label_0',
  'calendar_month_0',
  'schedule_0',
  'alarm_0',
  'grain_0',
  'resin_0',
  'powder_0',
  'devices_0',
  'cloud_download',
  'device_thermostat_0',
  'arrow_selector_tool_0',
  'target_0',
  'horizontal_0',
  'multiplanar_0',
  'waves_0',
  'cladding_0',
  'segmented_0',
  'vase_0',
  'gesture_0',
  'file_copy_0',
  'filter_list_0',
  'wire_0',
  'logo_1',
  'replay_0',
  'replay_ai_0',
  'milling_0',
  'library_add_check_0',
  'angle_0',
  'collision',
  'health_and_safety_0',
  'sticky_note_2',
  'corporate_fare',
  'tool_0',
  'mould_0',
  'open_in_full',
  'fullscreen_0',
  'key',
  'airwave',
  'view_simple_comp_0',
  'view_medium_comp_0',
  'view_high_comp_0',
  'smart_function_0',
];

const SpriteSVG = ({
  dataTestId = 'sprite-svg',
  color,
  className,
  name,
  viewBox,
}) => {
  const theme = useTheme();

  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width={viewBox ? undefined : '24'}
      height={viewBox ? undefined : '24'}
      viewBox={viewBox || '0 0 24 24'}
    >
      <use href={`#${name}`} fill={theme?.colors?.[color]} />
    </svg>
  );
};

SpriteSVG.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  name: PropTypes.oneOf(SPRITE_ICON_NAMES).isRequired,
  viewBox: PropTypes.string,
};

export default SpriteSVG;
