import styled, { css } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import Text from '@components/1-atoms/Text';
import { TABS_VARIANT_ICON } from './Tabs';
import { flexCenter, flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenterVertical}

  ${({ theme: { colors } }) => css`
    border-bottom: 1px solid ${colors.surfaceContainerHighest};
  `}
`;

export const StateLayer = styled.span`
  ${({ active, theme: { colors } }) => css`
    width: 100%;
    height: 100%;
    background: ${colors.onSurface};
    opacity: 0.08;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    ${when(
      active,
      `
        opacity: 0.12;
      `,
    )}
  `}
`;

export const Tab = styled.div`
  min-width: 120px;
  ${flexCenter}
  cursor: pointer;
  position: relative;

  ${({ disabled, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level4} ${spacing.level5};

    ${when(
      disabled,
      `
        pointer-events: none;
        opacity: 0.38;
      `,
    )}
  `}

  &:hover {
    ${StateLayer} {
      visibility: visible;
    }
  }

  &:focus:focus-visible {
    ${StateLayer} {
      visibility: visible;
      opacity: 0.12;
    }
  }
`;

export const Icon = styled(SpriteSVG)`
  ${({ active, variant, theme: { colors, spacing } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
    fill: ${colors.onSurface};

    ${when(
      variant === TABS_VARIANT_ICON && active,
      `
        fill: ${colors.primary};
      `,
    )}
  `}
`;

export const Label = styled(Text)`
  flex-shrink: 0;
`;

export const ActiveLine = styled.span`
  height: 2px;
  position: fixed;
  transition: left 0.2s ease;

  ${({ disabled, variant, theme: { colors } }) => css`
    background: ${colors.primary};

    ${when(
      variant === TABS_VARIANT_ICON,
      `
        border-radius: 2px 2px 0 0;
      `,
    )}

    ${when(
      disabled,
      `
        background: ${colors.onSurface};
        opacity: 0.38;
      `,
    )}
  `}
`;
