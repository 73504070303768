import axios from 'axios';
import config from './config';
import environments from '@utils/environment';
import { isStringBooleanIsTrue } from '@utils/dom';

const TIMEOUT = 120000;

const api = config[environments.current];

try {
  if (isStringBooleanIsTrue(process?.env?.REACT_APP_USE_TEST_ENV_SERVER)) {
    api.baseUrl = config.test.baseUrl;
    api.baseUrlws = config.test.baseUrlws;
  }
} catch (_) {
  // Error handling
}

const client = axios.create({
  baseURL: api.baseUrl,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default client;
