import styled, { css } from 'styled-components';
import PaperComponent from '@components/1-atoms/Paper';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import SliderComponent from '@components/1-atoms/Slider';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Paper = styled(PaperComponent)`
  width: min(800px, 100%);
`;

export const ActionWrapper = styled.div`
  margin-block: auto;

  ${({ theme: { spacing } }) => css`
    padding-inline: ${spacing.level2};
  `}
`;

export const SliderWrapper = styled.div`
  flex: 1;
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level2};
    padding-inline: ${spacing.level2};
  `}
`;

export const MinWidthButtonDropdown = styled(ButtonDropDown)`
  min-width: 100px;
  max-width: 140px;
`;

export const Slider = styled(SliderComponent)`
  min-width: 100px;
  flex: 1;
`;
