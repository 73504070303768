import { css } from 'styled-components';

export const flexCenterVertical = css`
  display: flex;
  align-items: center;
`;

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const flexColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const when = (condition, style, fallback) =>
  condition ? style : fallback || '';

export const setApplicationScrollable = (scrollable) => {
  const appContainer = document.getElementById('ai-sync');

  if (!appContainer) return;

  if (scrollable) {
    appContainer.style.overflow = '';

    return;
  }

  appContainer.style.overflow = 'hidden';
};

/**
 * Converts a hex colour to its RGB (0-255) equivalent
 * @param {hex} hex the hex to convert
 * @returns an object with r, g, b keys containing the RGB representation of the hex
 */
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

/**
 * Converts a hex colour to its normalised RGB (0-1) equivalent
 * @param {hex} hex the hex to convert
 * @returns an object with r, g, b keys containing the RGB representation of the hex, normalised to between 0 and 1
 */
export const hexToRgbNormalised = (hex) => {
  const rgb = hexToRgb(hex);
  return {
    r: rgb.r / 255,
    g: rgb.g / 255,
    b: rgb.b / 255,
  };
};
