import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_LABEL_SMALL } from '@components/1-atoms/Text';
import { Wrapper } from './Badge.styled';

export const BADGE_VARIANT_LABEL = 'label';
export const BADGE_VARIANT_DOT = 'dot';

const Badge = ({
  children,
  dataTestId = 'badge',
  variant = BADGE_VARIANT_LABEL,
}) => {
  return (
    <Wrapper data-testid={dataTestId} variant={variant}>
      {variant === BADGE_VARIANT_LABEL && (
        <Text
          dataTestId={`${dataTestId}__text`}
          variant={TEXT_VARIANT_LABEL_SMALL}
          color="onError"
        >
          {children}
        </Text>
      )}
    </Wrapper>
  );
};

Badge.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf([BADGE_VARIANT_DOT, BADGE_VARIANT_LABEL]),
};

export default Badge;
