import { createIntl } from 'react-intl';
import englishMessages from '@app/languages/en-GB.json';

export default (locale = 'en-GB', messages = englishMessages) => {
  const intl = createIntl({
    locale,
    messages,
  });

  return intl;
};
