import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
} from '@xyflow/react';
import { isEqual, isUndefined } from 'lodash';
import useVisualToolOperators from '@hooks/useVisualToolOperators';
import useWorkflow from '@hooks/workflows/useWorkflow';
import { getLayoutedElements } from '@utils/visualTool';
import OperatorNode from '@components/OperatorNode';
import { Wrapper } from './VisualTool.styled';

import '@xyflow/react/dist/base.css';
import usePrevious from '@app/hooks/usePrevious';

const nodeTypes = {
  operator: OperatorNode,
};

function VisualTool({ form }) {
  const { getSelectedWorkflow, getSelectedWorkflowOperators } = useWorkflow();
  const workflow = getSelectedWorkflow();
  const operators = getSelectedWorkflowOperators();

  const visualToolOperators = useVisualToolOperators({
    workflow,
    operators,
    form,
  });

  const previusVisualToolOperatorsNodes = usePrevious(
    visualToolOperators.nodes,
  );

  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    const edges = [...visualToolOperators.edges];
    const nodes = [...visualToolOperators.nodes];

    return getLayoutedElements(nodes, edges);
  }, [visualToolOperators.edges, visualToolOperators.nodes]);

  // eslint-disable-next-line no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  useEffect(() => {
    const skip =
      isUndefined(previusVisualToolOperatorsNodes) ||
      isEqual(previusVisualToolOperatorsNodes, visualToolOperators.nodes);

    if (skip) return;

    setNodes((currentNodes) =>
      currentNodes.map((currentNode, i) => {
        const nextNode = visualToolOperators.nodes?.[i];

        return {
          ...currentNode,
          zIndex: nextNode?.zIndex,
          data: nextNode?.data,
        };
      }),
    );
  }, [previusVisualToolOperatorsNodes, visualToolOperators.nodes, setNodes]);

  return (
    <Wrapper>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <Controls position="bottom-center" />
        <Background gap={16} />
      </ReactFlow>
    </Wrapper>
  );
}

VisualTool.propTypes = {
  form: PropTypes.object.isRequired,
};

export default VisualTool;
