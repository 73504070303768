import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from '@pages/Login';
import { ROUTES } from '@constants/router';
import { TopLayerElements } from '@routes/AuthenticatedRoutes/AuthenticatedRoutes.styled';
import { PAGE_LOADER_CONTAINER_ID } from '@components/2-molecules/PageLoaderPortal';
import PageLoader from '@components/1-atoms/PageLoader';
import useApplication from '@hooks/application/useApplication';
import SSOTokenCaptor from '@app/pages/SSOTokenCaptor';

export default function UnauthenticatedRoutes() {
  const { getIsApplicationLoading } = useApplication();
  const isApplicationLoading = getIsApplicationLoading();

  return (
    <>
      <Switch>
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.RESET_PASSWORD} component={Login} />
        <Route path={ROUTES.SSO_RESPONSE} component={SSOTokenCaptor} />
        <Redirect to={ROUTES.LOGIN} />
      </Switch>
      <TopLayerElements>
        <div id={PAGE_LOADER_CONTAINER_ID}>
          <PageLoader show={isApplicationLoading} />
        </div>
      </TopLayerElements>
    </>
  );
}
