import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${({ withIdentation, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level2} ${spacing.level5};

    ${when(
      withIdentation,
      `
        padding-left: ${spacing.level7};
      `,
    )}
  `}
`;

export const LeftColumn = styled.div`
  ${flexCenterVertical}
  flex: 1;
  word-break: break-all;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level4} 0px ${spacing.level3};
  `}
`;

export const RightColumn = styled.div`
  ${flexCenterVertical}
`;
