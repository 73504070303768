import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useDesign from '@hooks/useDesign';
import { FileTypes } from '@constants/fileTypes';
import {
  getIsShowingDimensions,
  getIsShowingRotations,
  getIsShowingSeams,
  getIsShowingSolid,
  getIsShowingVertices,
} from '@selectors/conceptSelectors';
import { getClippingTool } from '@selectors/toolBarSelector';
import { toogleClipTool } from '@actions/toolBarActions';
import {
  updateDimensionVisibility,
  updateRotationsVisibility,
  updateSeamsVisibility,
  toggleSolidVisibility,
  updateVerticesVisibility,
} from '@actions/conceptActions';

export const DRAFTING_ACTIONS = {
  DIMENSIONS: 'Dimensions',
  VERTICES: 'Vertices',
  ROTATIONS: 'Rotations',
  SEAMS: 'Seams',
  CLIP: 'Clip',
};

export default function useDraftingTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isShowingDimensions = useSelector(getIsShowingDimensions());
  const isShowingVertices = useSelector(getIsShowingVertices());
  const isShowingRotations = useSelector(getIsShowingRotations());
  const isShowingSeams = useSelector(getIsShowingSeams());
  const isShowingSolid = useSelector(getIsShowingSolid());
  const clippingTool = useSelector(getClippingTool);
  const { design, designGeometryData } = useDesign();
  const disableActions = !(
    designGeometryData &&
    design?.filetype === FileTypes.aib &&
    !isShowingSolid
  );
  const disableActionsSolid = !(
    designGeometryData && design?.filetype === FileTypes.aib
  );
  const disableDimensionsAction = !design || !designGeometryData;
  const disableClipAction =
    !(designGeometryData && design) || !clippingTool?.clipAvailable;

  const handleDimensions = useCallback(() => {
    dispatch(updateDimensionVisibility());
  }, [dispatch]);

  const handleVertices = useCallback(() => {
    dispatch(updateVerticesVisibility());
  }, [dispatch]);

  const handleRotations = useCallback(() => {
    dispatch(updateRotationsVisibility());
  }, [dispatch]);

  const handleSeams = useCallback(() => {
    dispatch(updateSeamsVisibility());
  }, [dispatch]);

  const handleSolid = useCallback(() => {
    dispatch(toggleSolidVisibility());
  }, [dispatch]);

  const handleClip = useCallback(() => {
    dispatch(toogleClipTool());
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.DIMENSIONS}`,
        disabled: disableDimensionsAction,
        active: isShowingDimensions,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.dimensions',
          defaultMessage: DRAFTING_ACTIONS.DIMENSIONS,
        }),
        iconName: 'measure_0',
        handler: handleDimensions,
      },
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.VERTICES}`,
        disabled: disableActions,
        active: isShowingVertices,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.vertices',
          defaultMessage: DRAFTING_ACTIONS.VERTICES,
        }),
        iconName: 'vertices_0',
        handler: handleVertices,
      },
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.ROTATIONS}`,
        disabled: disableActions,
        active: isShowingRotations,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.rotations',
          defaultMessage: DRAFTING_ACTIONS.ROTATIONS,
        }),
        iconName: 'Direction_0',
        handler: handleRotations,
      },
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.SEAMS}`,
        disabled: disableActions,
        active: isShowingSeams,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.seams',
          defaultMessage: DRAFTING_ACTIONS.SEAMS,
        }),
        iconName: 'edit_seam_0',
        handler: handleSeams,
      },
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.SOLID}`,
        disabled: disableActionsSolid,
        active: isShowingSolid,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.solid',
          defaultMessage: DRAFTING_ACTIONS.SOLID,
        }),
        iconName: 'solid_0',
        handler: handleSolid,
      },
      {
        id: `drafing-tool-${DRAFTING_ACTIONS.CLIP}`,
        disabled: disableClipAction,
        active: clippingTool?.clipIsActive,
        name: intl.formatMessage({
          id: 'displaymodepanel.text.clip',
          defaultMessage: DRAFTING_ACTIONS.CLIP,
        }),
        iconName: 'crop_0',
        isClipAction: true,
        handler: handleClip,
      },
    ],
    [
      intl,
      disableDimensionsAction,
      disableActions,
      disableActionsSolid,
      disableClipAction,
      isShowingDimensions,
      isShowingVertices,
      isShowingRotations,
      isShowingSeams,
      isShowingSolid,
      clippingTool?.clipIsActive,
      handleDimensions,
      handleVertices,
      handleRotations,
      handleSeams,
      handleSolid,
      handleClip,
    ],
  );

  return actions;
}
