import React, { useCallback } from 'react';
import { CHIP_VARIANT_PRIMARY } from '@app/components/1-atoms/Chip';
import PropTypes from 'prop-types';
import SettingActionWithIconButton from '../2-molecules/SettingActionWithIconButton';
import { useIntl } from 'react-intl';

const ReadOnlyCanvasSelectionChip = ({
  fieldInput,
  referencedShapeIdField,
  renderDropDownField,
}) => {
  const intl = useIntl();

  const renderCanvasSelectionDropDownField = useCallback(() => {
    return renderDropDownField(fieldInput);
  }, [renderDropDownField, fieldInput]);

  const renderSelectionFilledChip = useCallback(() => {
    const numSelections = referencedShapeIdField?.value?.split(',').length ?? 0;
    return (
      <SettingActionWithIconButton
        label={fieldInput?.name}
        chip={{
          label: intl.formatMessage(
            {
              id: 'canvasselection.field.num_selections',
            },
            { numSelections },
          ),
          leadingIconName: 'arrow_selector_tool_0',
          variant: CHIP_VARIANT_PRIMARY,
        }}
        iconButton={{
          iconName: 'close_0',
        }}
      />
    );
  }, [fieldInput?.name, intl, referencedShapeIdField?.value]);

  if (referencedShapeIdField?.value) {
    return renderSelectionFilledChip();
  }
  return renderCanvasSelectionDropDownField();
};

ReadOnlyCanvasSelectionChip.propTypes = {
  fieldInput: PropTypes.object,
  referencedShapeIdField: PropTypes.object,
  renderDropDownField: PropTypes.func,
};

export default ReadOnlyCanvasSelectionChip;
