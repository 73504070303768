import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import * as THREE from 'three';
import {
  OrbitControls,
  OrthographicCamera as DreiOrthographicCamera,
} from '@react-three/drei';
import usePrevious from '@hooks/usePrevious';
import { VisualizationContext } from '@contexts/VisualizationContext';
import {
  orthographicCameraDirections,
  CAMERA_FOCUS_ENABLE_TIMEOUT,
  ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION,
  ORTHOGRAPHIC_CAMERA_UP,
  ORTHOGRAPHIC_CAMERA_MAX_ZOOM,
  ORTHOGRAPHIC_CAMERA_MIN_ZOOM,
  PERSPECTIVE_CAMERA_DEFAULT_POSITION,
} from '@constants/camera';
import { getObjectDimensions, getObjectZoomInNumber } from '@utils/camera';
import VisualizationUtils from '@app/lib/VisualizationUtils';

export default function OrthographicCamera() {
  const {
    threeState,
    setCamera,
    setControls,
    cameraConfig,
    isFocusingCameraOnObject,
    focusCameraOnObject,
    updateCameraPosition,
    selectedOperatorOutputDesignId,
    simulation,
  } = useContext(VisualizationContext);
  const [position, setPosition] = useState(
    ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION,
  );
  const [target, setTarget] = useState(new THREE.Vector3(0, 0, 0));

  const cameraRef = useRef();
  const controlsRef = useRef();

  const previousIsFocusingCameraOnObject = usePrevious(
    isFocusingCameraOnObject,
  );

  const defaultCameraPosition = useMemo(
    () => ({
      left: -innerWidth / 2,
      right: innerWidth / 2,
      top: innerHeight / 2,
      bottom: -innerHeight / 2,
    }),
    [],
  );

  const cameraDirection = cameraConfig?.position;

  const getFocusObject = useCallback(() => {
    let object = threeState?.scene?.getObjectByProperty(
      'designId',
      selectedOperatorOutputDesignId,
    );

    if (simulation?.isActive) {
      const sceneChildren = simulation?.printingObject?.scene?.children || [];
      const middleIndex = Math.floor(sceneChildren.length / 2);
      object = simulation?.printingObject?.scene?.children?.at(middleIndex);
    }

    return object;
  }, [threeState, selectedOperatorOutputDesignId, simulation]);

  const getObjectSize = useCallback(
    async (object) => {
      let targetObject = object;
      const isSolidView = object?.userData?.isSolidView;

      if (!simulation?.isActive && isSolidView) {
        targetObject = VisualizationUtils.displayBoundingGeometry(
          object,
          'LINES',
        );
      }

      return await getObjectDimensions(targetObject);
    },
    [simulation?.isActive],
  );

  const adjustCamera = useCallback(async () => {
    const [dx, dy, dz] =
      orthographicCameraDirections?.[cameraDirection] ||
      ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION;
    const camera = cameraRef.current;
    const object = getFocusObject();

    const skip = !camera || !object;

    if (skip) return;

    const { center, size } = await getObjectSize(object);

    setTarget(new THREE.Vector3(center.x, center.y, center.z));

    // set the camera in respect to the object center
    setPosition([
      center.x + dx * size.x,
      center.y + dy * size.y,
      center.z + dz * size.z,
    ]);
    camera.position.set(
      center.x + dx * size.x,
      center.y + dy * size.y,
      center.z + dz * size.z,
    );
  }, [cameraDirection, getFocusObject, getObjectSize]);

  const observeFocusOnObjectUpdates = useCallback(async () => {
    const camera = cameraRef.current;
    const skip =
      !camera ||
      !(!previousIsFocusingCameraOnObject && isFocusingCameraOnObject);

    if (skip) return;

    const object = getFocusObject();

    if (!object) return;

    const isSimulationObject = object?.userData?.name?.includes(
      'simulation-travel-line-node',
    );
    const { size } = await getObjectSize(object);

    adjustCamera();
    camera.zoom = getObjectZoomInNumber(
      cameraDirection,
      size,
      isSimulationObject,
    );
    camera.updateProjectionMatrix();
  }, [
    cameraDirection,
    getFocusObject,
    getObjectSize,
    isFocusingCameraOnObject,
    previousIsFocusingCameraOnObject,
    adjustCamera,
  ]);

  const handleUpdate = useCallback(
    (self) => {
      if (!threeState) return;

      self.updateProjectionMatrix();
    },
    [threeState],
  );

  useEffect(() => {
    setCamera(cameraRef);
    setControls(controlsRef);
  }, [setCamera, setControls]);

  useEffect(() => {
    observeFocusOnObjectUpdates();
  }, [observeFocusOnObjectUpdates]);

  useEffect(() => {
    adjustCamera();
  }, [adjustCamera]);

  useEffect(() => {
    return () => {
      updateCameraPosition(PERSPECTIVE_CAMERA_DEFAULT_POSITION);
    };
  }, [updateCameraPosition]);

  useEffect(() => {
    if (isFocusingCameraOnObject) {
      setTimeout(() => focusCameraOnObject(false), CAMERA_FOCUS_ENABLE_TIMEOUT);
    }
  }, [isFocusingCameraOnObject, focusCameraOnObject]);

  return (
    <>
      <DreiOrthographicCamera
        ref={cameraRef}
        makeDefault
        position={position}
        zoom={ORTHOGRAPHIC_CAMERA_MIN_ZOOM}
        near={-1000}
        far={1000}
        up={ORTHOGRAPHIC_CAMERA_UP}
        {...defaultCameraPosition}
        visible={false}
        onUpdate={handleUpdate}
      />

      <OrbitControls
        ref={controlsRef}
        target={target}
        camera={cameraRef.current}
        zoomSpeed={3}
        maxZoom={ORTHOGRAPHIC_CAMERA_MAX_ZOOM}
        minZoom={ORTHOGRAPHIC_CAMERA_MIN_ZOOM}
        enableRotate={false}
        enableDamping={false}
      />
    </>
  );
}

OrthographicCamera.propTypes = {};
