import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import {
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
} from '@components/2-molecules/DropDownMenu';
import {
  Paper,
  ActionWrapper,
  SliderWrapper,
  MinWidthButtonDropdown,
  Slider,
} from './SceneClipBar.styled';

const SceneClipBar = ({
  className,
  clipMaxRange,
  clipMaxRangeDropDownMenuItems = [],
  clipMinRange,
  clipMinRangeDropDownMenuItems = [],
  clipMode,
  clipModeDropDownMenuItems = [],
  dataTestId = 'scene-clip-bar',
  disableResetButton = true,
  onBackIconButtonClick,
  onResetButtonClick,
  slider = {},
}) => {
  const intl = useIntl();

  return (
    <Paper dataTestId={dataTestId} className={className} direction="row">
      {onBackIconButtonClick && (
        <IconButton
          dataTestId={`${dataTestId}__back-icon-button`}
          title={intl.formatMessage({
            id: 'general.back',
            defaultMessage: 'Back',
          })}
          variant={ICON_BUTTON_VARIANT_PLAIN}
          iconName="chevron_left_0"
          onClick={onBackIconButtonClick}
        />
      )}

      <ActionWrapper>
        <ButtonDropDown
          dataTestId={`${dataTestId}__clip-mode-button-drop-down`}
          title={clipMode}
          dropDownMenuPosition={DROP_DOWN_MENU_POSITION_BOTTOM_LEFT}
          dropDownMenuItems={clipModeDropDownMenuItems}
        >
          {clipMode}
        </ButtonDropDown>
      </ActionWrapper>

      <VerticalDivider middleInset inlineMiddleInset />

      <SliderWrapper>
        <MinWidthButtonDropdown
          dataTestId={`${dataTestId}__clip-min-range-button-drop-down`}
          title={intl.formatMessage({
            id: 'scene_clip_bar.clip_mode_min_range.title',
            defaultMessage: 'Lower value',
          })}
          dropDownMenuPosition={DROP_DOWN_MENU_POSITION_BOTTOM_LEFT}
          dropDownMenuItems={clipMinRangeDropDownMenuItems}
        >
          {clipMinRange}
        </MinWidthButtonDropdown>

        <Slider {...slider} />

        <MinWidthButtonDropdown
          dataTestId={`${dataTestId}__clip-max-range-button-drop-down`}
          title={intl.formatMessage({
            id: 'scene_clip_bar.clip_mode_max_range.title',
            defaultMessage: 'Upper value',
          })}
          dropDownMenuPosition={DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT}
          dropDownMenuItems={clipMaxRangeDropDownMenuItems}
        >
          {clipMaxRange}
        </MinWidthButtonDropdown>
      </SliderWrapper>

      {onResetButtonClick && (
        <ActionWrapper>
          <Button
            disabled={disableResetButton}
            variant={BUTTON_VARIANT_TEXT}
            title={intl.formatMessage({
              id: 'scene_clip_bar.reset.title',
              defaultMessage: 'Reset values',
            })}
            onClick={onResetButtonClick}
          >
            {intl.formatMessage({
              id: 'general.reset',
              defaultMessage: 'Reset',
            })}
          </Button>
        </ActionWrapper>
      )}
    </Paper>
  );
};

SceneClipBar.propTypes = {
  className: PropTypes.string,
  clipMaxRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clipMaxRangeDropDownMenuItems: PropTypes.array,
  clipMinRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clipMinRangeDropDownMenuItems: PropTypes.array,
  clipMode: PropTypes.string,
  clipModeDropDownMenuItems: PropTypes.array,
  dataTestId: PropTypes.string,
  disableResetButton: PropTypes.bool,
  onBackIconButtonClick: PropTypes.func,
  onResetButtonClick: PropTypes.func,
  slider: PropTypes.shape(Slider.propTypes),
};

export default SceneClipBar;
