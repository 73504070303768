import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Divider } from './HorizontalDivider.styled';

const HorizontalDivider = ({ inset, middleInset }) => {
  return (
    <Wrapper inset={inset} middleInset={middleInset}>
      <Divider />
    </Wrapper>
  );
};

HorizontalDivider.propTypes = {
  inset: PropTypes.bool,
  middleInset: PropTypes.bool,
};

export default HorizontalDivider;
