import { AuthenticatedFetch } from './AuthenticatedFetch.js';
import {
  FETCH_ALL_PRINTS,
  FETCH_ALL_PRINTS_SUCCEEDED,
  FETCH_ALL_PRINTS_FAILED,
  SELECT_PRINT,
  FETCH_ALL_PRINT_DASHBOARDS,
  FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED,
  FETCH_ALL_PRINT_DASHBOARDS_FAILED,
  SHOW_DELETE_PRINT_DIALOG,
  DISMISS_DELETE_PRINT_DIALOG,
  DELETE_PRINT,
  DELETE_PRINT_SUCCEEDED,
  DELETE_PRINT_FAILED,
} from '../constants/actionTypes.js';

import { GET, DELETE } from '../constants/fetchMethods.js';

export const fetchPrints = (printerId) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'prints',
      method: GET,
      queryParams: {
        printerId: printerId,
      },
      prefetchAction: {
        type: FETCH_ALL_PRINTS,
      },
      successAction: (responseBody) => ({
        type: FETCH_ALL_PRINTS_SUCCEEDED,
        payload: responseBody,
        printerId: printerId,
      }),
      failureAction: () => ({
        type: FETCH_ALL_PRINTS_FAILED,
      }),
    }),
  );
};

export const selectPrint = (id) => ({
  type: SELECT_PRINT,
  payload: { id },
});

export const fetchPrintDashboards = (printerId, print) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: `printers/${printerId}/print/${print.printId}/dashboards`,
      method: GET,
      prefetchAction: {
        type: FETCH_ALL_PRINT_DASHBOARDS,
      },
      successAction: (responseBody) => ({
        type: FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: FETCH_ALL_PRINT_DASHBOARDS_FAILED,
      }),
    }),
  );
};

export const showDeletePrintdDialog = (printId) => ({
  type: SHOW_DELETE_PRINT_DIALOG,
  printIdToDelete: printId,
});

export const dismissDeletePrintDialog = () => ({
  type: DISMISS_DELETE_PRINT_DIALOG,
});

export const deletePrint = (printId) => (dispatch) =>
  dispatch(
    AuthenticatedFetch({
      url: `prints/${printId}`,
      method: DELETE,
      prefetchAction: {
        type: DELETE_PRINT,
      },
      successAction: (res) => {
        return {
          type: DELETE_PRINT_SUCCEEDED,
          payload: {
            printId: res.prints[0].printId,
            printerId: res.prints[0].printerId,
          },
        };
      },
      failureAction: () => ({
        type: DELETE_PRINT_FAILED,
      }),
    }),
  );
