import styled, { css } from 'styled-components';
import Text, {
  TEXT_VARIANT_BODY_LARGE,
  TEXT_VARIANT_BODY_MEDIUM,
  TEXT_VARIANT_BODY_SMALL,
  TEXT_VARIANT_HEADLINE_SMALL,
  TEXT_VARIANT_TITLE_LARGE,
  TEXT_VARIANT_TITLE_MEDIUM,
  TEXT_VARIANT_TITLE_SMALL,
  TEXT_VARIANT_DISPLAY_SMALL,
} from '@components/1-atoms/Text';
import {
  PAGE_HEADER_VARIANT_EXTRA_LARGE,
  PAGE_HEADER_VARIANT_LARGE,
  PAGE_HEADER_VARIANT_MEDIUM,
  PAGE_HEADER_VARIANT_SMALL,
} from './PageHeader';
import { flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  ${({ variant, withMarginTop, theme: { spacing } }) => css`
    gap: ${spacing.level4};

    ${when(
      variant === PAGE_HEADER_VARIANT_EXTRA_LARGE,
      `
        padding: ${spacing.level4} 0 ${spacing.level5};
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_LARGE,
      `
        padding: ${spacing.level5} 0;
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_MEDIUM,
      `
        padding: ${spacing.level2} 0;
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_SMALL,
      `
        padding: ${spacing.level4} 0;
      `,
    )}

    ${when(
      withMarginTop,
      `
        margin-top: ${spacing.level6};
      `,
    )}
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  align-items: flex-start;
  flex: 1 0 0;
  overflow: hidden;

  ${({ variant, theme: { spacing } }) => css`
    gap: ${spacing.level3};

    ${when(
      [
        PAGE_HEADER_VARIANT_LARGE,
        PAGE_HEADER_VARIANT_MEDIUM,
        PAGE_HEADER_VARIANT_SMALL,
      ].includes(variant),
      `
        gap: ${spacing.level2};
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_LARGE,
      `
        padding-block: ${spacing.level3};
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_MEDIUM,
      `
        padding-block: ${spacing.level4};
      `,
    )}

    ${when(
      variant === PAGE_HEADER_VARIANT_SMALL,
      `
        padding-block: ${spacing.level5};
      `,
    )}
  `}
`;

export const Title = styled(Text).attrs(({ variant }) => {
  const color = 'onSurface';
  let textVariant;

  if (variant === PAGE_HEADER_VARIANT_EXTRA_LARGE) {
    textVariant = TEXT_VARIANT_DISPLAY_SMALL;
  }

  if (variant === PAGE_HEADER_VARIANT_LARGE) {
    textVariant = TEXT_VARIANT_TITLE_LARGE;
  }

  if (variant === PAGE_HEADER_VARIANT_MEDIUM) {
    textVariant = TEXT_VARIANT_TITLE_MEDIUM;
  }

  if (variant === PAGE_HEADER_VARIANT_SMALL) {
    textVariant = TEXT_VARIANT_TITLE_SMALL;
  }

  return {
    variant: textVariant,
    color,
  };
})`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Subtitle = styled(Text).attrs(({ variant }) => {
  const color = 'onSurfaceVariant';
  let subtitleVariant;

  if (variant === PAGE_HEADER_VARIANT_EXTRA_LARGE) {
    subtitleVariant = TEXT_VARIANT_HEADLINE_SMALL;
  }

  if (variant === PAGE_HEADER_VARIANT_LARGE) {
    subtitleVariant = TEXT_VARIANT_BODY_LARGE;
  }

  if (variant === PAGE_HEADER_VARIANT_MEDIUM) {
    subtitleVariant = TEXT_VARIANT_BODY_MEDIUM;
  }

  if (variant === PAGE_HEADER_VARIANT_SMALL) {
    subtitleVariant = TEXT_VARIANT_BODY_SMALL;
  }

  return {
    variant: subtitleVariant,
    color,
  };
})`
  opacity: 0.76;

  b {
    font-weight: 700;
  }
`;

export const LeadingControls = styled.div`
  flex-shrink: 0;
`;

export const EndingControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level2};
  `}
`;
