import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { materialsQueryKeys } from '@hooks/materials/useMaterialQueries';

export default function useMaterial() {
  const queryClient = useQueryClient();

  const getSelectedMaterial = useCallback(
    (materialId) => {
      const materials = queryClient.getQueryData(materialsQueryKeys.materials);

      if (!materials) {
        return null;
      }

      return materials.find((material) => material.id === materialId);
    },
    [queryClient],
  );

  return {
    getSelectedMaterial,
  };
}
