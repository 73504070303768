import React from 'react';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { Field as FormikField, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const StaticSetting = () => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();

  return (
    <FormikField
      key={'static_height'}
      component={SettingTextField}
      label={intl.formatMessage({
        id: `printers.settings.plinth.parameters.height.label`,
      })}
      field1={{
        type: 'number',
        dirty: checkIfFieldIsDirty(
          values,
          initialValues,
          `plinthTypeSetting.plinthProperties.height`,
        ),
      }}
      dataTestId={`printer-components-plinth-type__category-parameters__static-height`}
      name={`plinthTypeSetting.plinthProperties.height`}
    />
  );
};
