import client from '@api/client';
import endpoints from '@api/endpoints';

export default function useCanvasSelectionFetches() {
  const canvasSelectionObjectFetch = async (designId, fileName) => {
    const result = await client.get(
      endpoints.canvasSelectionObject
        .replace(':designId', designId)
        .replace(':fileName', fileName),
      {
        responseType: 'arraybuffer',
      },
    );
    return result.data;
  };

  return {
    canvasSelectionObjectFetch,
  };
}
