import {
  DESIGNS_LOADED,
  SELECT_DESIGN,
  CREATE_DESIGN,
  FETCH_ALL_DESIGNS,
  FETCH_ALL_DESIGNS_FAILED,
  FETCH_DESIGN_GEOMETRY,
  FETCH_DESIGN_GEOMETRY_SUCCEEDED,
  FETCH_DESIGN_GEOMETRY_FAILED,
  DELETE_DESIGN,
  DELETE_DESIGN_SUCCEEDED,
  DELETE_DESIGN_FAILED,
  PUBLISH_DESIGN,
  PUBLISH_DESIGN_SUCCEEDED,
  PUBLISH_DESIGN_FAILED,
  SHOW_DELETE_DESIGN_DIALOG,
  DISMISS_DELETE_DESIGN_DIALOG,
  SHOW_PUBLISH_DESIGN_DIALOG,
  DISMISS_PUBLISH_DESIGN_DIALOG,
  CREATE_DESIGN_SUCCEEDED,
  DISMISS_ERROR_DIALOG,
  UPDATE_DESIGN_SORTING,
  NEW_DESIGN_NAME_MODIFIED,
  UPLOADING_FILE,
  UPLOAD_STOPPED,
  FETCH_CONCEPT_GEOMETRY,
  FETCH_CONCEPT_GEOMETRY_SUCCEEDED,
  FETCH_CONCEPT_GEOMETRY_FAILED,
  UPDATE_DESIGN_SUCCEEDED,
  SHOW_UPDATE_DESIGN_DIALOG,
  DISMISS_UPDATE_DESIGN_DIALOG,
  UPDATE_DESIGN_NAME_MODIFIED,
  DOWNLOAD_DESIGN,
  DOWNLOAD_DESIGN_FAILED,
  DOWNLOAD_DESIGN_SUCCEEDED,
  LOAD_MODEL,
  LOAD_MODEL_SUCCEEDED,
  RESET_DESIGN_METADATA,
} from '@constants/actionTypes';

const initialState = {
  fetchesInProgress: 0,
  lastFetchedModelId: null,
  designMetadata: [],
  designGeometryData: {},
  selectedDesignId: null,
  errorTitle: null,
  errorMessage: null,
  ui: {
    newDesignName: '',
    designIdToDelete: null,
    isDeletingDesign: false,
    isPublishingDesign: false,
    sortBy: 'Filename A-Z',
    uploadProgress: 0,
    showProgressBar: false,
    isUpdatingDesign: false,
    designIdToUpdate: null,
    updatedDesignName: null,
  },
};

const designsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DESIGNS_LOADED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        designMetadata: action.payload,
      };
    case SELECT_DESIGN:
      return {
        ...state,
        selectedDesignId: action.designId,
      };
    case FETCH_CONCEPT_GEOMETRY_SUCCEEDED:
    case FETCH_CONCEPT_GEOMETRY_FAILED:
    case FETCH_DESIGN_GEOMETRY_FAILED:
    case FETCH_ALL_DESIGNS_FAILED:
    case DOWNLOAD_DESIGN_FAILED:
    case DOWNLOAD_DESIGN_SUCCEEDED:
    case LOAD_MODEL_SUCCEEDED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
      };
    case DELETE_DESIGN:
    case FETCH_ALL_DESIGNS:
    case FETCH_CONCEPT_GEOMETRY:
    case FETCH_DESIGN_GEOMETRY:
    case PUBLISH_DESIGN:
    case DOWNLOAD_DESIGN:
    case LOAD_MODEL:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress + 1,
      };
    case FETCH_DESIGN_GEOMETRY_SUCCEEDED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        lastFetchedModelId: action.payload?.id,
        designGeometryData: {
          ...state.designGeometryData,
          [action.payload.id]: action.payload.displayData,
        },
      };
    case DELETE_DESIGN_SUCCEEDED: {
      // eslint-disable-next-line no-unused-vars
      const { [action.designId]: toDelete, ...designGeometryData } =
        state.designGeometryData;
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: { ...state.ui, isDeletingDesign: false, designIdToDelete: null },
        selectedDesignId: null,
        designMetadata: state.designMetadata.filter(
          (design) => design.id !== action.designId,
        ),
        designGeometryData,
      };
    }
    case DELETE_DESIGN_FAILED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: { ...state.ui, isDeletingDesign: false, designIdToDelete: null },
      };
    case UPDATE_DESIGN_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingDesign: false,
        },
        designMetadata: state.designMetadata.map((modification) => {
          if (modification.id === action.designId) {
            return {
              ...modification,
              filename: action.filename + '.' + modification.filetype,
            };
          }
          return modification;
        }),
      };
    case PUBLISH_DESIGN_SUCCEEDED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: {
          ...state.ui,
          isPublishingDesign: false,
          newDesignName: '',
        },
      };
    case PUBLISH_DESIGN_FAILED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: {
          ...state.ui,
          newDesignName: '',
        },
      };
    case SHOW_DELETE_DESIGN_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingDesign: true,
          designIdToDelete: action.payload.designId,
        },
      };
    case DISMISS_DELETE_DESIGN_DIALOG:
      return {
        ...state,
        ui: { ...state.ui, isDeletingDesign: false, designIdToDelete: null },
      };
    case SHOW_PUBLISH_DESIGN_DIALOG:
      return {
        ...state,
        ui: { ...state.ui, isPublishingDesign: true },
      };
    case DISMISS_PUBLISH_DESIGN_DIALOG:
      return {
        ...state,
        ui: { ...state.ui, isPublishingDesign: false },
      };
    case UPDATE_DESIGN_SORTING:
      return {
        ...state,
        ui: {
          ...state.ui,
          sortBy: action.payload.sortBy,
        },
      };
    case CREATE_DESIGN:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress + 1,
        ui: {
          ...state.ui,
          uploadProgress: 0,
          showProgressBar: true,
        },
      };
    case CREATE_DESIGN_SUCCEEDED:
      return {
        ...state,
        ...(action.design
          ? { designMetadata: [...state.designMetadata, action.design] }
          : {}),
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: {
          ...state.ui,
          uploadProgress: 0,
          showProgressBar: false,
        },
      };
    case DISMISS_ERROR_DIALOG:
      return {
        ...state,
        errorTitle: null,
        errorMessage: null,
      };
    case NEW_DESIGN_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          newDesignName: action.payload.name,
        },
      };
    case UPLOADING_FILE:
      return {
        ...state,
        ui: {
          ...state.ui,
          uploadProgress: Math.max(
            action.payload.progress,
            state.ui.uploadProgress,
          ),
          showProgressBar:
            Math.max(action.payload.progress, state.ui.uploadProgress) < 100
              ? true
              : false,
        },
      };
    case UPLOAD_STOPPED:
      return {
        ...state,
        fetchesInProgress: state.fetchesInProgress - 1,
        ui: {
          ...state.ui,
          uploadProgress: 0,
          showProgressBar: false,
        },
      };
    case SHOW_UPDATE_DESIGN_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingDesign: true,
          designIdToUpdate: action.designIdToUpdate,
          updatedDesignName: action.designCurrentName,
        },
      };
    case DISMISS_UPDATE_DESIGN_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingDesign: false,
          designIdToUpdate: null,
          updatedDesignName: null,
        },
      };
    case UPDATE_DESIGN_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatedDesignName: action.payload.name,
        },
      };

    case RESET_DESIGN_METADATA:
      return {
        ...state,
        designMetadata: initialState.designMetadata,
      };
    default:
      return state;
  }
};

export default designsReducer;
