import styled, { css } from 'styled-components';
import { flexCenter, flexCenterVertical, when } from '@stylesheets/helpers';

export const StateLayer = styled.span`
  ${({ disabled, enabled, theme: { colors } }) => css`
    width: 100%;
    height: 100%;
    border: 2px solid ${colors.onSurface};
    border-radius: inherit;
    background-color: ${colors.surfaceContainerHighest};

    ${when(
      enabled,
      `
        border-color: ${colors.primary};
        background-color: ${colors.primary};
      `,
    )}

    ${when(
      disabled,
      `
        border-color: ${colors.onSurface};
        background-color: ${colors.surfaceVariant};
        opacity: 0.12;
      `,
    )}
  `}
`;

export const Icon = styled.span`
  ${flexCenter}

  ${({ disabled, enabled, theme: { borderRadius, colors, spacing } }) => css`
    width: ${spacing.level9};
    height: ${spacing.level9};
    border-radius: ${borderRadius.full};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-8px, -50%);
    transition: all 0.1s ease-in-out;

    ${when(
      enabled,
      `
        transform: translate(12px, -50%);
      `,
    )}

    ${when(
      disabled,
      `
        opacity: 0.38;
      `,
    )}

    &:before {
      content: '';
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      border-radius: inherit;
      background-color: ${colors.onSurface};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      content: '';
      width: ${spacing.level6};
      height: ${spacing.level6};
      border-radius: inherit;
      border: 1px solid ${colors.onSurface};
      background-color: ${colors.onSurface};

      ${when(
        enabled,
        `
          border-color: transparent;
          background-color: ${colors.onPrimary};
        `,
      )}

      ${when(
        disabled,
        `
          background-color: ${colors.onSurface};
        `,
      )}
    }
  `}
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  margin: 0;

  &:focus:focus-visible ~ ${StateLayer} {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:hover,
  &:focus:focus-visible {
    & ~ ${StateLayer} {
      ${({ enabled, theme: { colors } }) => css`
        ${when(
          !enabled,
          `
            border-color: ${colors.outline};
          `,
        )}
      `}
    }

    & ~ ${Icon} {
      &:before {
        opacity: 0.08;
        visibility: visible;
      }

      &:after {
        ${({ enabled, theme: { colors } }) => css`
          ${when(
            !enabled,
            `
              background-color: ${colors.onSurfaceVariant};
              opacity: 0.8;
            `,
          )}

          ${when(
            enabled,
            `
              opacity: 0.80;
            `,
          )}
        `}
      }
    }
  }
`;

export const Wrapper = styled.label`
  width: 52px;
  height: 32px;
  ${flexCenterVertical}
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  ${({ theme: { borderRadius } }) => css`
    border-radius: ${borderRadius.full};
  `}

  ${({ disabled }) => css`
    ${when(
      disabled,
      `
        pointer-events: none;
      `,
    )}
  `}
`;
