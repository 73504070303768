import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@components/2-molecules/Loader';
import { Wrapper, LoaderWrapper } from './PageLoader.styled';

const PageLoader = ({ show, progress, title }) => {
  return (
    <Wrapper show={show}>
      <LoaderWrapper show={show}>
        <Loader progress={progress} title={title} />
      </LoaderWrapper>
    </Wrapper>
  );
};

PageLoader.propTypes = {
  show: PropTypes.bool,
  progress: PropTypes.number,
  title: PropTypes.string,
};

export default PageLoader;
