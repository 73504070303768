import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  useRef,
  useMemo,
} from 'react';
import usePrevious from '@hooks/usePrevious';
import {
  PerspectiveCamera as ThreePerspectiveCamera,
  OrbitControls,
} from '@react-three/drei';
import { VisualizationContext } from '@contexts/VisualizationContext';
import { getObjectDimensions } from '@utils/camera';
import { CAMERA_FOCUS_ENABLE_TIMEOUT } from '@constants/camera';
import VisualizationUtils from '@app/lib/VisualizationUtils';

export default function PerspectiveCamera() {
  const {
    threeState,
    setCamera,
    setControls,
    cameraPosition,
    isFocusingCameraOnObject,
    updateCameraPosition,
    focusCameraOnObject,
    selectedOperatorOutputDesignId,
    simulation,
    canvasSelectionCamera,
    setCanvasSelectionCamera,
  } = useContext(VisualizationContext);

  const initialTarget = canvasSelectionCamera?.target ?? {
    x: 0,
    y: 0,
    z: 0,
  };
  const [target, setTarget] = useState([
    initialTarget.x,
    initialTarget.y,
    initialTarget.z,
  ]);
  const [windowSize, setWindowSize] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });
  const cameraRef = useRef();
  const controlsRef = useRef();

  const position = useMemo(
    () => [
      cameraPosition?.cameraX,
      cameraPosition?.cameraY,
      cameraPosition?.cameraZ,
    ],
    [cameraPosition],
  );

  const previousIsFocusingCameraOnObject = usePrevious(
    isFocusingCameraOnObject,
  );

  const updateWindowSize = useCallback(() => {
    setWindowSize({
      width: window?.innerWidth,
      height: window?.innerHeight,
    });
  }, [setWindowSize]);

  const handleUpdate = useCallback((self) => {
    self.updateProjectionMatrix();
  }, []);

  const getFocusObject = useCallback(() => {
    let object = threeState?.scene?.getObjectByProperty(
      'designId',
      selectedOperatorOutputDesignId,
    );

    if (simulation?.isActive) {
      const sceneChildren = simulation?.printingObject?.scene?.children || [];
      const middleIndex = Math.floor(sceneChildren.length / 2);
      object = simulation?.printingObject?.scene?.children?.at(middleIndex);
    }

    return object;
  }, [threeState, selectedOperatorOutputDesignId, simulation]);

  const getObjectSize = useCallback(
    async (object) => {
      let targetObject = object;
      const isSolidView = object?.userData?.isSolidView;

      if (!simulation?.isActive && isSolidView) {
        targetObject = VisualizationUtils.displayBoundingGeometry(
          object,
          'LINES',
        );
      }

      const dimensions = await getObjectDimensions(targetObject);

      return dimensions;
    },
    [simulation?.isActive],
  );

  const observeFocusOnObjectUpdates = useCallback(async () => {
    const camera = cameraRef.current;

    const skip =
      !camera ||
      canvasSelectionCamera ||
      !(!previousIsFocusingCameraOnObject && isFocusingCameraOnObject);

    if (skip) return;

    const object = getFocusObject();

    if (!object) return;

    const isSimulationObject = object?.userData?.name?.includes(
      'simulation-travel-line-node',
    );

    const { center, size } = await getObjectSize(object);

    const offset = 2;
    const x = 0;
    const y = 0;
    const useInverseZ = false;

    // figure out how to fit the box in the view:
    // 1. figure out horizontal FOV (on non-1.0 aspects)
    // 2. figure out distance from the object in X and Y planes
    // 3. select the max distance (to fit both sides in)
    //
    // The reason is as follows:
    //
    // Imagine a bounding box (BB) is centered at (0,0,0).
    // Camera has vertical FOV (camera.fov) and horizontal FOV
    // (camera.fov scaled by aspect, see fovh below)
    //
    // Therefore if you want to put the entire object into the field of view,
    // you have to compute the distance as: z/2 (half of Z size of the BB
    // protruding towards us) plus for both X and Y size of BB you have to
    // figure out the distance created by the appropriate FOV.
    //
    // The FOV is always a triangle:
    //
    //  (size/2)
    // +--------+
    // |       /
    // |      /
    // |     /
    // | F° /
    // |   /
    // |  /
    // | /
    // |/
    //
    // F° is half of respective FOV, so to compute the distance (the length
    // of the straight line) one has to: `size/2 / Math.tan(F)`.
    //
    // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
    // the camera.fov is the vertical FOV.
    const fov = camera.fov * (Math.PI / 180);
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
    const dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
    const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
    let cameraZ = Math.max(Math.max(dx, dy), center.z);

    if (useInverseZ) cameraZ *= -1;

    // offset the camera, if desired (to avoid filling the whole canvas)
    if (offset !== 0) cameraZ *= offset;

    const finalX = x;
    const finalY = y;
    let finalZ = cameraZ;

    if (isSimulationObject) {
      finalZ += 40;
    }

    const cameraPosition = [finalX, finalY, finalZ];

    setTarget(center);
    updateCameraPosition(cameraPosition);
    camera.position.set(...cameraPosition);
  }, [
    cameraRef,
    previousIsFocusingCameraOnObject,
    isFocusingCameraOnObject,
    updateCameraPosition,
    getFocusObject,
    getObjectSize,
    canvasSelectionCamera,
  ]);

  useEffect(() => {
    setCamera(cameraRef);
    setControls(controlsRef);
  }, [setCamera, setControls]);

  useEffect(() => {
    observeFocusOnObjectUpdates();
  }, [observeFocusOnObjectUpdates]);

  useEffect(() => {
    updateWindowSize();
  }, [updateWindowSize]);

  useEffect(() => {
    if (isFocusingCameraOnObject) {
      setTimeout(() => focusCameraOnObject(false), CAMERA_FOCUS_ENABLE_TIMEOUT);
    }
  }, [isFocusingCameraOnObject, focusCameraOnObject]);

  useEffect(() => {
    if (canvasSelectionCamera && cameraRef.current) {
      const newPosition = canvasSelectionCamera.position;
      const newPositionArr = [newPosition.x, newPosition.y, newPosition.z];
      updateCameraPosition(newPositionArr);
      const newTarget = canvasSelectionCamera.target;
      const newTargetArr = [newTarget.x, newTarget.y, newTarget.z];
      setTarget(newTargetArr);
      cameraRef.current.position.set(...newPositionArr);
      setCanvasSelectionCamera(null);
    }
  }, [canvasSelectionCamera, setCanvasSelectionCamera, updateCameraPosition]);

  const aspect = (windowSize?.width || 0) / (windowSize?.height || 0);

  return (
    <>
      <ThreePerspectiveCamera
        ref={cameraRef}
        makeDefault
        position={position}
        up={[0, 0, 1]}
        fov={15}
        aspect={aspect}
        near={1}
        far={10000}
        visible={false}
        onUpdate={handleUpdate}
      />

      <OrbitControls
        ref={controlsRef}
        target={target}
        zoomSpeed={3}
        maxDistance={1000}
        minDistance={0.1}
        enableDamping={false}
      />
    </>
  );
}

PerspectiveCamera.propTypes = {};
