export const printingBedSettingsTypes = {
  Default: {
    name: 'DEFAULT',
  },
  RotaryBedSettingsDefault: {
    name: 'ROTARY_DEFAULT',
  },
  TwoAxisBedSettingsDefault: {
    name: 'TWO_AXIS_DEFAULT',
  },
  PrintingBedSettingsType2: {
    name: 'DEEP',
  },
  PrintingBedSettingsType3: {
    name: 'X25',
  },
  PrintingBedSettingsType5: {
    name: 'ADDITEC',
  },
};
