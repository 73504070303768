import styled, { css } from 'styled-components';

export const PlayerWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing.level6};
  `}

  iframe {
    width: 100%;
  }
`;
