import {
  MACHINE_DEMO,
  MACHINE_GANTRY,
  MACHINE_GANTRY_5_AXIS,
  MACHINE_SIX_AXIS_ROBOT,
  MACHINE_SIX_AXIS_ROBOT_COUPLED,
} from '../../constants/machineConstants';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators';
import { Printer } from '../Printer';
import DemoMachine from './gantries/DemoMachine';
import Gantry from './gantries/Gantry';
import Gantry5Axis from './gantries/Gantry5Axis';
import GantryCustom from './gantries/GantryCustom';
import SixAxisRobot from './robots/SixAxisRobot';
import SixAxisRobotCoupled from './robots/SixAxisRobotCoupled';
import SixAxisRobotCustom from './robots/SixAxisRobotCustom';
import { printerConstants as constants } from '@constants/printers/printerConstants';

/**
 * Parses the type of machine from the model name and printer settings.
 * @param {*} machineDefinitions machine definitions
 * @param {*} modelName model name of this printer's machine
 * @param {*} printerSettings serialised response object containing printer settings
 * @param {*} bed bed of this printer
 * @returns
 */
export function getMachine(
  machineDefinitions,
  modelName,
  printerSettings,
  bed,
) {
  const machineType = getMachineType(
    machineDefinitions,
    modelName,
    printerSettings,
  );
  return buildMachineFromType(
    machineDefinitions,
    modelName,
    printerSettings,
    machineType,
    bed,
  );
}

/**
 * Builds the correct machine object from the given machine type.
 * @param {*} machineDefinitions
 * @param {*} modelName
 * @param {*} printerSettings
 * @param {*} machineType
 * @param {*} bed
 * @returns
 */
function buildMachineFromType(
  machineDefinitions,
  modelName,
  printerSettings,
  machineType,
  bed,
) {
  if (isMachineCustom(modelName)) {
    return buildCustomMachine(modelName, printerSettings, machineType, bed);
  }
  switch (machineType) {
    case MACHINE_GANTRY:
      return new Gantry(modelName, machineDefinitions, printerSettings, bed);
    case MACHINE_GANTRY_5_AXIS:
      return new Gantry5Axis(
        modelName,
        machineDefinitions,
        printerSettings,
        bed,
      );
    case MACHINE_DEMO:
      return new DemoMachine(modelName, machineDefinitions, printerSettings);
    case MACHINE_SIX_AXIS_ROBOT_COUPLED:
      return new SixAxisRobotCoupled(
        modelName,
        machineDefinitions,
        printerSettings,
      );
    case MACHINE_SIX_AXIS_ROBOT:
    default:
      return new SixAxisRobot(modelName, machineDefinitions, printerSettings);
  }
}

function buildCustomMachine(modelName, printerSettings, machineType, bed) {
  switch (machineType) {
    case componentDiscriminators.GANTRY:
      return new GantryCustom(modelName, printerSettings, bed);
    case componentDiscriminators.SIX_AXIS_ROBOT:
    default:
      return new SixAxisRobotCustom(modelName, printerSettings);
  }
}

export function getMachineDefinitionResponse(printerSettings) {
  if (printerSettings.machineDefinitionResponse)
    return printerSettings.machineDefinitionResponse;

  return Printer.getPrinterSettingValue(
    printerSettings,
    'machineDefinitionResponse',
  );
}

/**
 * Returns the machine type. If the machine is custom, the type is parsed from
 * the response object. If it is not, it is taken from the machineDefinitions templates
 * via the model name.
 * @param {*} machineDefinitions
 * @param {*} modelName
 * @param {*} printerSettings
 * @returns
 */
function getMachineType(machineDefinitions, modelName, printerSettings) {
  if (isMachineCustom(modelName)) {
    const machineDefinitionResponse =
      getMachineDefinitionResponse(printerSettings);
    return machineDefinitionResponse.machineType;
  }
  return machineDefinitions.find(({ displayName }) => displayName == modelName)
    ?.machineType;
}

export function getMachineBrand(
  machineDefinitions,
  modelName,
  printerSettings,
) {
  if (isMachineCustom(modelName)) {
    const machineDefinitionResponse =
      getMachineDefinitionResponse(printerSettings);
    return machineDefinitionResponse.brand;
  }
  return machineDefinitions.find(({ displayName }) => displayName == modelName)
    ?.brand;
}

export function isMachineCustom(modelName) {
  return modelName === constants.CUSTOM.value;
}
