import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setCameraConfig } from '@actions/sceneActions';

export default function useCamera() {
  const dispatch = useDispatch();

  const updateCamera = useCallback(
    (cameraConfig = {}) => {
      dispatch(setCameraConfig(cameraConfig));
    },
    [dispatch],
  );

  return { updateCamera };
}
