import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const ContentWrapper = styled.div`
  height: 100%;
  ${flexColumn}
  overflow-y: clip;
  margin-bottom: 24px;
`;

export const AnalyticsGraph = styled.div`
  height: 100%;
  ${flexColumn}
  position: relative;

  .rv-xy-plot {
    flex: 1;
  }

  .rv-xy-plot__inner {
    height: 100%;
    fill: transparent;
  }

  .rv-discrete-color-legend-item.horizontal {
    ${({ theme: { colors } }) => css`
      color: ${colors.onSurface};
    `}
  }

  .rv-discrete-color-legend-item__title {
    font-size: 12px;
    font-weight: bold;

    ${({ theme: { colors } }) => css`
      color: ${colors.onSurface};
    `}
  }

  .rv-xy-plot__axis__tick__text {
    font-size: 11px;

    ${({ theme: { colors } }) => css`
      fill: ${colors.outline};
    `}
  }

  .rv-xy-plot__grid-lines__line {
    ${({ theme: { colors } }) => css`
      stroke: ${colors.outlineVariant};
    `}
  }
`;

export const AnalyticsLegend = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow-y: clip;
  overflow-x: auto;
  scrollbar-gutter: stable;
  padding-top: 30px;
  padding-bottom: 20px;

  .rv-discrete-color-legend {
    height: auto !important;
    display: grid;
    grid-template-columns: repeat(100, max-content);
    gap: 32px;
  }

  .rv-discrete-color-legend-item__color {
    display: flex;
    width: 48px;
    height: 4px;
    margin: 0 auto 8px;
  }
`;
