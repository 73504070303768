import React, { useEffect, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import useProjectList from '@hooks/projects/useProjectList';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import { ROUTES } from '@constants/router';
import ProjectsPageContent from '@components/ProjectsPageContent';
import { Wrapper } from './Projects.styled';

const Projects = () => {
  const intl = useIntl();
  const history = useHistory();
  const { getProjectDropDownMenuActions } = useProjectList();
  const { projectsQuery } = useProjectQueries();
  const { refetch: refetchProjectsQuery } = projectsQuery;

  const projectsListItems = useMemo(
    () =>
      projectsQuery.data?.map((project) => {
        const dropDownMenuItems = getProjectDropDownMenuActions(project);
        let optionalProps = {};

        if (dropDownMenuItems?.length) {
          optionalProps = {
            endingIconButtonIconName: 'more_vert_0',
            endingIconButtonDropDownProps: {
              dropDownMenuItems,
            },
          };
        }

        return {
          id: project.id,
          label: project.name,
          leadingIconName: project.publicAccess ? 'language_0' : 'category_0',
          description: intl.formatMessage(
            {
              id: 'projectspage.projects_list.list_item.description',
              defaultMessage: 'Created: {date}',
            },
            {
              date: moment(project?.createdAt || moment()).format('DD/MM/YYYY'),
            },
          ),
          createdAt: project?.createdAt,
          onClick: () =>
            history.push(
              generatePath(ROUTES.WORKFLOWS, {
                workspaceId: project?.id,
              }),
            ),
          ...optionalProps,
        };
      }),
    [intl, history, projectsQuery.data, getProjectDropDownMenuActions],
  );

  useEffect(() => {
    refetchProjectsQuery();
  }, [refetchProjectsQuery]);

  return (
    <Wrapper data-testid="projects-page">
      <ProjectsPageContent
        projectsListItems={projectsListItems}
        loading={!projectsQuery.isFetched || projectsQuery.isLoading}
      />
    </Wrapper>
  );
};

Projects.propTypes = {};

export default Projects;
