import * as THREE from 'three';
import VisualizationUtils from './VisualizationUtils';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

export default class LabeledAxesHelper extends THREE.Object3D {
  constructor({
    rotationMatrix,
    x = 0,
    y = 0,
    z = 0,
    size = 50,
    textColor = '#636363',
  }) {
    super();
    return this.getBaseCoordinateFrameVisualisation({
      rotationMatrix,
      x,
      y,
      z,
      size,
      textColor,
    });
  }

  getBaseCoordinateFrameVisualisation = ({
    rotationMatrix,
    x,
    y,
    z,
    size,
    textColor,
  }) => {
    const xAxis = new THREE.Vector3();
    const yAxis = new THREE.Vector3();
    const zAxis = new THREE.Vector3();
    rotationMatrix.extractBasis(xAxis, yAxis, zAxis);
    return this.createAxisObjectAtPoint({
      x,
      y,
      z,
      size,
      textColor,
      rotationCorrectionsMatrix: new THREE.Matrix4().identity(),
    });
  };

  createAxisObjectAtPoint = ({
    x,
    y,
    z,
    a = 0,
    b = 0,
    c = 0,
    size,
    textColor,
    rotationCorrectionsMatrix,
  }) => {
    const result = new THREE.Group();
    const axisGeometry = this.createAxisGeometryAtPoint({
      x,
      y,
      z,
      a,
      b,
      c,
      size,
      rotationCorrectionsMatrix,
    });
    const axisMaterial = new THREE.LineBasicMaterial({
      vertexColors: true,
    });
    const axisObject = new THREE.LineSegments(axisGeometry, axisMaterial);
    const textGroup = new THREE.Group();

    const textSize = 10;
    const textThickness = 1;
    VisualizationUtils.addTextToObject(
      textGroup,
      'X',
      textSize,
      textThickness,
      textColor,
      {
        x: x + size,
        y: y,
        z: z + textSize,
        rotX: -Math.PI / 2,
      },
    );
    VisualizationUtils.addTextToObject(
      textGroup,
      'Y',
      textSize,
      textThickness,
      textColor,
      {
        x: x - 0.075,
        y: y + size,
        z: z,
        rotX: Math.PI / 2,
      },
    );
    VisualizationUtils.addTextToObject(
      textGroup,
      'Z',
      textSize,
      textThickness,
      textColor,
      {
        x: x + 0.1,
        y: y,
        z: z + size + textSize,
        rotX: -Math.PI / 2,
        rotZ: -Math.PI,
      },
    );

    result.add(axisObject);
    result.add(textGroup);

    return result;
  };

  setGeometryAttributes = (geometry, position, color) => {
    geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(position, 3),
    );
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(color, 3));
  };

  createAxisGeometryAtPoint = ({
    x,
    y,
    z,
    a,
    b,
    c,
    size,
    rotationCorrectionsMatrix,
  }) => {
    if (a === null || b === null || c === null) {
      return null;
    }

    const allAxisGeometries = [];

    // Define positions and colors for each axis
    const axesData = [
      {
        position: [
          0,
          0,
          0,
          size * rotationCorrectionsMatrix.elements[0],
          size * rotationCorrectionsMatrix.elements[1],
          size * rotationCorrectionsMatrix.elements[2],
        ],
        color: [1, 0, 0, 1, 0.5, 0],
      },
      {
        position: [
          0,
          0,
          0,
          size * rotationCorrectionsMatrix.elements[4],
          size * rotationCorrectionsMatrix.elements[5],
          size * rotationCorrectionsMatrix.elements[6],
        ],
        color: [0, 1, 0, 0, 1, 0],
      },
      {
        position: [
          0,
          0,
          0,
          size * rotationCorrectionsMatrix.elements[8],
          size * rotationCorrectionsMatrix.elements[9],
          size * rotationCorrectionsMatrix.elements[10],
        ],
        color: [0, 0, 1, 0, 0.5, 1],
      },
    ];

    // Create geometries and attributes for each axis
    for (const axisData of axesData) {
      const axisGeom = new THREE.BufferGeometry();
      this.setGeometryAttributes(axisGeom, axisData.position, axisData.color);
      allAxisGeometries.push(axisGeom);
    }

    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      allAxisGeometries,
      false,
    );
    mergedGeometry.translate(x, y, z);
    return mergedGeometry;
  };
}
