import * as actionTypes from '@constants/actionTypes';

const initialState = {
  open: false,
  data: {},
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL: {
      return {
        ...state,
        open: true,
        data: action.payload.data,
      };
    }

    case actionTypes.CLOSE_MODAL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default modalReducer;
