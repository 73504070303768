export const getWorkspace =
  (workspaceId) =>
  ({ workspaces }) =>
    workspaces.data.workspaces.find(({ id }) => id === workspaceId);
export const getWorkspaces =
  () =>
  ({ workspaces }) =>
    workspaces.data.workspaces;
export const getWorkspaceIdToDelete =
  () =>
  ({ workspaces }) =>
    workspaces.ui.workspaceIdToDelete;
export const getWorkspaceToUpdate =
  () =>
  ({ workspaces }) =>
    workspaces.data.workspaces.find(
      ({ id }) => id === workspaces.ui.workspaceIdToUpdate,
    );
export const getWorkspaceIdToUpdate =
  () =>
  ({ workspaces }) =>
    workspaces.ui.workspaceIdToUpdate;
export const getIsDeletingWorkspace =
  () =>
  ({ workspaces }) =>
    workspaces.ui.isDeletingWorkspace;
export const getIsUpdatingWorkspace =
  () =>
  ({ workspaces }) =>
    workspaces.ui.isUpdatingWorkspace;
export const getSelectedWorkspace =
  () =>
  ({ workspaces }) =>
    workspaces.data.selectedWorkspace;
export const getSelectedWorkflow =
  () =>
  ({ workspaces }) =>
    workspaces.data.selectedWorkflow;
export const getSearchBarValue = ({ workspaces }) =>
  workspaces.ui.searchBarValue;
