import styled, { css } from 'styled-components';
import { SCREEN_SIZE } from '@stylesheets/screen';
import { flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;

  ${({ theme: { dimensions, colors, screenSize } }) => css`
    grid-template-columns: ${dimensions.loginSideBarWidth?.[
        screenSize || SCREEN_SIZE.XLARGE
      ]} 1fr;
    background-color: ${colors.surface};
  `}
`;

export const SideBar = styled.div`
  height: 100%;
  ${flexColumn}
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding: 0 ${spacing?.level3};
  `}
`;

export const Content = styled.div`
  ${({ backgroundImage }) => css`
    ${when(
      backgroundImage,
      `
        background-image: url(${backgroundImage});
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
      `,
    )}
  `}
`;
