import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { useSelector } from 'react-redux';
import { projectQueryKeys } from '@hooks/projects/useProjectQueries';
import { getCurrentUser } from '@selectors/loginSelectors';

export const projectMutationKeys = {
  createProject: ['createProject'],
  updateProject: ['updateProject'],
  deleteProject: ['deleteProject'],
  importProject: ['importProject'],
};

export default function useProjectMutations() {
  const queryClient = useQueryClient();
  const user = useSelector(getCurrentUser());

  const createProjectMutation = useMutation({
    mutationKey: projectMutationKeys.createProject,
    mutationFn: (projectName = '') =>
      client
        .post(endpoints.workspaces, {
          name: projectName?.trim(),
          organization: user.organizationId,
        })
        .then((res) => res.data),
    onSuccess: (addedProject) => {
      queryClient.setQueryData(projectQueryKeys.projects, (data) =>
        data ? [...data, addedProject] : [addedProject],
      );
    },
  });

  const updateProjectMutation = useMutation({
    mutationKey: projectMutationKeys.updateProject,
    mutationFn: (project = {}) =>
      client
        .put(endpoints.workspace.replace(':workspaceId', project?.id), {
          name: project?.name,
          organization: project?.organization,
          publicAccessible: project?.public,
        })
        .then((res) => res.data),
    onSuccess: (_, projectMutation) => {
      queryClient.setQueryData(projectQueryKeys.projects, (data) => {
        return data
          ? data?.map((project) => {
              if (project.id === projectMutation?.id) {
                return {
                  ...project,
                  name: projectMutation?.name,
                  publicAccess: projectMutation?.public,
                };
              }

              return project;
            })
          : undefined;
      });
    },
  });

  const deleteProjectMutation = useMutation({
    mutationKey: projectMutationKeys.deleteProject,
    mutationFn: (projectId) =>
      client
        .delete(endpoints.workspace.replace(':workspaceId', projectId))
        .then((res) => res.data),
    onSuccess: (_, projectId) => {
      queryClient.setQueryData(projectQueryKeys.projects, (data) => {
        return data
          ? data?.filter((project) => project.id !== projectId)
          : undefined;
      });
    },
  });

  const importProjectMutation = useMutation({
    mutationKey: projectMutationKeys.importProject,
    mutationFn: ({ projectName, sentDataId } = {}) =>
      client
        .post(
          endpoints.importSharedProject.replace(':workspaceId', sentDataId),
          {
            newProjectName: projectName,
          },
          {
            ignoreGlobalErrorHandler: true,
          },
        )
        .then((res) => res.data),
    onSuccess: (addedProject) => {
      if (!addedProject) return;

      queryClient.setQueryData(projectQueryKeys.projects, (data) =>
        data ? [...data, addedProject] : [addedProject],
      );
    },
  });

  return {
    createProjectMutation,
    updateProjectMutation,
    deleteProjectMutation,
    importProjectMutation,
  };
}
