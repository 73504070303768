import React, { useMemo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { isNull } from 'lodash';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';

const MODAL_ID = ModalDataTypes.PROMPT;

const PromptDialog = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { getDialogData, hideDialog } = useDialog();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const closeOnSecondaryActionSuccess =
    'closeOnSecondaryActionSuccess' in dialogData
      ? dialogData.closeOnSecondaryActionSuccess
      : true;
  const closeOnPrimaryActionSuccess =
    'closeOnPrimaryActionSuccess' in dialogData
      ? dialogData.closeOnPrimaryActionSuccess
      : true;
  const closeOnPrimaryActionError =
    'closeOnPrimaryActionError' in dialogData
      ? dialogData.closeOnPrimaryActionError
      : true;

  const handleSecondaryButtonClick = useCallback(() => {
    dialogData?.onSecondaryButtonClick?.();

    if (closeOnSecondaryActionSuccess) {
      hideDialog(MODAL_ID);
    }
  }, [dialogData, closeOnSecondaryActionSuccess, hideDialog]);

  const handlePrimaryButtonClick = useCallback(async () => {
    setLoading(true);
    try {
      await dialogData?.onPrimaryButtonClick?.();

      if (closeOnPrimaryActionSuccess) {
        await hideDialog(MODAL_ID);
      }
    } catch (_) {
      if (closeOnPrimaryActionError) {
        await hideDialog(MODAL_ID);
      }
    } finally {
      setLoading(false);
    }
  }, [
    dialogData,
    closeOnPrimaryActionSuccess,
    closeOnPrimaryActionError,
    hideDialog,
  ]);

  return (
    <DialogPortal
      dataTestId={dialogData?.dataTestId}
      dialogId={MODAL_ID}
      loading={loading}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={handleSecondaryButtonClick}
      primaryButtonLabel={
        isNull(dialogData?.primaryButtonLabel)
          ? undefined
          : dialogData?.primaryButtonLabel ??
            intl.formatMessage({
              id: 'general.confirm',
              defaultMessage: 'Confirm',
            })
      }
      secondaryButtonLabel={
        isNull(dialogData?.secondaryButtonLabel)
          ? undefined
          : dialogData?.secondaryButtonLabel ??
            intl.formatMessage({
              id: 'general.cancel',
              defaultMessage: 'Cancel',
            })
      }
    >
      {(dialogData?.title || dialogData?.subtitle) && (
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={dialogData?.title}
          subtitle={dialogData?.subtitle}
        />
      )}
    </DialogPortal>
  );
};

export default PromptDialog;
