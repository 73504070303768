import styled, { css } from 'styled-components';
import SkeletonListComponent from '@components/1-atoms/SkeletonList';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level3} 0px;
    margin-bottom: ${spacing.level6};
  `}
`;

const list = css`
  ${flexColumn};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  display: grid;
  grid-gap: 15px;
`;

export const ContentWrapper = styled.div`
  ${list}
`;

export const SkeletonList = styled(SkeletonListComponent)`
  ${list}

  ${({ theme: { spacing } }) => css`
    margin-top: ${spacing.level9};
  `}
`;
