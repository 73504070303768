import { useCallback, useEffect, useContext, useState } from 'react';
import usePrevious from '@hooks/usePrevious';
import { VisualizationContext } from '@contexts/VisualizationContext';
import GlbModel from '@utils/GlbModel';
import { printerConstants } from '@constants/printers/printerConstants';

export default function Scene() {
  const { threeState, printer, simulation, objects } =
    useContext(VisualizationContext);

  const [lastUpdatedPoint, setLastUpdatedPoint] = useState({
    layer: 0,
    index: 0,
  });

  const previousState = usePrevious({ objects, simulation, printer });

  /**
   * Returns an object containing the relevant simulation data, i.e. the current
   * step, the time of the simulation that has passed, etc.
   * @param {*} simulation
   * @returns
   */
  const getSimulationDataObject = useCallback((simulation) => {
    const simulationCurrentStepIndex = simulation.step;
    const simulationPreviousStepIndex =
      simulationCurrentStepIndex > 0 ? simulationCurrentStepIndex - 1 : 0;
    const simulationPreviousStepTime =
      simulationPreviousStepIndex == 0
        ? 0
        : simulation.printerSteps[simulationPreviousStepIndex].time;
    const simulationCurrentStepPassedTime =
      simulation.time - simulationPreviousStepTime;
    const simulationStepRatio =
      simulationCurrentStepPassedTime != 0
        ? simulationCurrentStepPassedTime /
          (simulation.printerSteps[simulationCurrentStepIndex].time -
            simulationPreviousStepTime)
        : 0;

    const simulationCurrentStep =
      simulation.printerSteps[simulationCurrentStepIndex];
    const simulationPreviousStep =
      simulation.printerSteps[simulationPreviousStepIndex];

    return {
      currentStepIndex: simulationCurrentStepIndex,
      previousStepIndex: simulationPreviousStepIndex,
      currentStepPassedTime: simulationCurrentStepPassedTime,
      stepRatio: simulationStepRatio,
      currentStep: simulationCurrentStep,
      previousStep: simulationPreviousStep,
    };
  }, []);

  const calculateLastPointPositionBasedOnRatio = useCallback(
    (
      inLayerPointXIdx,
      printingObjectCoordinates,
      layerIdx,
      simulationStepRatio,
    ) => {
      // 'coordinates' is one big array which is containing xyz values sequentially.
      // that's why we need to multiply by 3 and append 0 (for X), 1 (for Y) and 2 (for Z)
      // to finding corresponding coordinate position for that line
      const indexCur = inLayerPointXIdx;
      const indexCurX = indexCur * 3;
      const indexCurY = indexCur * 3 + 1;
      const indexCurZ = indexCur * 3 + 2;
      const valueCurX = printingObjectCoordinates[layerIdx][indexCurX];
      const valueCurY = printingObjectCoordinates[layerIdx][indexCurY];
      const valueCurZ = printingObjectCoordinates[layerIdx][indexCurZ];

      const indexPrev = indexCur - 1;
      const indexPrevX = indexPrev * 3;
      const indexPrevY = indexPrev * 3 + 1;
      const indexPrevZ = indexPrev * 3 + 2;
      const previousPointLayerIndex =
        indexCur < indexPrev ? layerIdx - 1 : layerIdx;
      let valuePrevX =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevX];
      let valuePrevY =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevY];
      let valuePrevZ =
        printingObjectCoordinates[previousPointLayerIndex][indexPrevZ];

      // if this is the first line
      if (valuePrevX === undefined) {
        valuePrevX = valueCurX;
        valuePrevY = valueCurY;
        valuePrevZ = valueCurZ;
      }

      // calculate how much line should we append to previous line to visualize
      // printing object partially
      const deltaX = (valueCurX - valuePrevX) * simulationStepRatio;
      const deltaY = (valueCurY - valuePrevY) * simulationStepRatio;
      const deltaZ = (valueCurZ - valuePrevZ) * simulationStepRatio;

      const valueNewX = valuePrevX + deltaX;
      const valueNewY = valuePrevY + deltaY;
      const valueNewZ = valuePrevZ + deltaZ;

      return {
        valueNewX: valueNewX,
        valueNewY: valueNewY,
        valueNewZ: valueNewZ,
        indexCurX: indexCurX,
        indexCurY: indexCurY,
        indexCurZ: indexCurZ,
      };
    },
    [],
  );

  /**
   * It renders the printObject until the point with index simulationCurrentStepIndex
   * @param {*} printObject
   * @param {*} simulationCurrentStepIndex
   * @returns an object containing these infos:
   *  {
   *    layerMeshContainingSimulationIdx= the buffergeometry containing the specified point
   *    inLayerPointXIdx= the point idx relative to the container layer
   *    layerIdx= idx of the layerMeshContainingSimulationIdx
   *  }
   */
  const renderObjectUntilStep = useCallback(
    (printObject, simulationCurrentStepIndex) => {
      let currentPointIdx = 0;
      let layerMeshContainingSimulationIdx = null;
      let inLayerPointXIdx = 0;
      let layerIdx = 0;

      for (const child of printObject.children) {
        const currentLayerPoints =
          child.geometry.getAttribute('position').count;

        if (
          currentPointIdx + currentLayerPoints <=
          simulationCurrentStepIndex - 1
        ) {
          layerIdx++;
          currentPointIdx += currentLayerPoints;
          child.geometry.setDrawRange(0, Infinity);
        } else {
          if (layerMeshContainingSimulationIdx != null) {
            child.geometry.setDrawRange(0, 0);
          } else {
            inLayerPointXIdx = simulationCurrentStepIndex - currentPointIdx;
            child.geometry.setDrawRange(0, inLayerPointXIdx + 1);
            layerMeshContainingSimulationIdx = child;
            currentPointIdx += simulationCurrentStepIndex;
          }
        }

        if (child.userData.name.startsWith('simulation-travel-line')) {
          child.visible = simulation.showTravelLine;
        }
      }

      return {
        layerMeshContainingSimulationIdx: layerMeshContainingSimulationIdx,
        inLayerPointXIdx: inLayerPointXIdx,
        layerIdx: layerIdx,
      };
    },
    [simulation.showTravelLine],
  );

  const restorePointOriginalPosition = useCallback(
    (printObject, printingObjectCoordinates) => {
      const { layer, index } = lastUpdatedPoint;
      const previousMesh = printObject.children[layer];

      const indexX = index;
      const indexY = indexX + 1;
      const indexZ = indexX + 2;
      const valueX = printingObjectCoordinates[layer][indexX];
      const valueY = printingObjectCoordinates[layer][indexY];
      const valueZ = printingObjectCoordinates[layer][indexZ];

      previousMesh.geometry.attributes.position.array[indexX] = valueX;
      previousMesh.geometry.attributes.position.array[indexY] = valueY;
      previousMesh.geometry.attributes.position.array[indexZ] = valueZ;

      previousMesh.geometry.attributes.position.needsUpdate = true;
      previousMesh.geometry.computeBoundingBox();
      previousMesh.geometry.computeBoundingSphere();
    },
    [lastUpdatedPoint],
  );

  /*
   * It renders the printObject based on the simulationCurrentStepIndex and the simulationStepRatio.
   * The number of simulation.steps must always match the number of points in printingObjectCoordinates.
   * The printingObjectCoordinates array is precisely the number of points * 3 because each point is composed by
   * x, y and z stored as sequential numbers in the array.
   * The printingObjectCoordinates array is divided into layer, each layer is a separate buffergeometry.
   *
   * @param {THREE.Object3D} printObject print object to be simulated printing
   * @param {integer} simulationCurrentStepIndex
   * @param {float} simulationStepRatio the percentage (from 0 to 1) of the last line to be rendered.
   *  The last line is defined as between the simulationCurrentStepIndex and simulationCurrentStepIndex-1
   */
  const renderPrintObject = useCallback(
    (printObject, simulationData) => {
      if (!simulationData.currentStep) return;
      const printingObjectCoordinates = simulation.printingObjectCoordinates;

      const { layerMeshContainingSimulationIdx, inLayerPointXIdx, layerIdx } =
        renderObjectUntilStep(printObject, simulationData.currentStepIndex);

      const {
        valueNewX,
        valueNewY,
        valueNewZ,
        indexCurX,
        indexCurY,
        indexCurZ,
      } = calculateLastPointPositionBasedOnRatio(
        inLayerPointXIdx,
        printingObjectCoordinates,
        layerIdx,
        simulationData.stepRatio,
      );

      // If users choose another simulation, they don't have to restore points.
      // It will cause crash in case previous simulation's last played time is
      //    longer than current simulation's duration.
      if (previousState?.simulation.printingObject != null) {
        restorePointOriginalPosition(printObject, printingObjectCoordinates);
      }

      setLastUpdatedPoint({ layer: layerIdx, index: indexCurX });

      //Update last point position
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurX
      ] = valueNewX;
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurY
      ] = valueNewY;
      layerMeshContainingSimulationIdx.geometry.attributes.position.array[
        indexCurZ
      ] = valueNewZ;

      layerMeshContainingSimulationIdx.geometry.attributes.position.needsUpdate = true;
      layerMeshContainingSimulationIdx.geometry.computeBoundingBox();
      layerMeshContainingSimulationIdx.geometry.computeBoundingSphere();
    },
    [
      calculateLastPointPositionBasedOnRatio,
      previousState?.simulation?.printingObject,
      simulation?.printingObjectCoordinates,
      renderObjectUntilStep,
      restorePointOriginalPosition,
      setLastUpdatedPoint,
    ],
  );

  const shouldUpdateObjects = useCallback(
    (currentObjects, nextObjects) => {
      let currentDigest = 1;
      let nextDigest = 1;

      if (printer?.uuid != previousState?.printer?.uuid) return true;

      Object.values(currentObjects.objects).forEach((value) => {
        if (value?.id) currentDigest *= value.id;
        if (value instanceof GlbModel) {
          currentDigest *= value.getHashCode();
        }
      });

      if (currentObjects.simulation) {
        currentDigest *= currentObjects.simulation.time + 1;
        currentDigest *= currentObjects.simulation.step + 1;
        currentDigest *= currentObjects.simulation.isActive + 1;
        currentDigest *= currentObjects.simulation.showTravelLine + 1;
      }

      Object.values(nextObjects.objects).forEach((value) => {
        if (value?.id) nextDigest *= value.id;
        if (value instanceof GlbModel) {
          nextDigest *= value.getHashCode();
        }
      });

      if (nextObjects.simulation) {
        nextDigest *= nextObjects.simulation.time + 1;
        nextDigest *= nextObjects.simulation.step + 1;
        nextDigest *= nextObjects.simulation.isActive + 1;
        nextDigest *= nextObjects.simulation.showTravelLine + 1;
      }

      return currentDigest !== nextDigest;
    },
    [previousState?.printer?.uuid, printer?.uuid],
  );

  const observeSimulationUpdates = useCallback(() => {
    if (!threeState) return;

    const objectsNeedsToBeUpdated =
      printer &&
      simulation?.isActive &&
      shouldUpdateObjects({ objects, simulation }, previousState);

    if (!objectsNeedsToBeUpdated) return;

    const simulationData = getSimulationDataObject(simulation);

    const printingObject = simulation.printingObject.scene;
    //-1 reflection in x is needed for some reason
    printingObject.scale.set(-1, 1, 1);
    printingObject.name = printerConstants.printingObject;

    const isPrintingObjectChanged =
      previousState.simulation?.printingObject?.scene?.uuid !=
      printingObject?.uuid;

    if (isPrintingObjectChanged) {
      //it avoids to reuse the old-model indexes with a new 3d model
      setLastUpdatedPoint({ layer: 0, index: 0 });
    }

    const { bed } = printer;

    //Demo machines do not have a bed
    const base = bed ? bed.base : null;
    const currentPrintingObject = threeState.scene.getObjectByName(
      printerConstants.printingObject,
    );
    const isPrintingObjectExist = currentPrintingObject != null;

    if (base) {
      if (!isPrintingObjectExist) {
        base.add(printingObject);
        printer.printingObject = printingObject;
      } else if (printingObject.uuid !== currentPrintingObject.uuid) {
        //is there a memory leak if dispose is not used?
        base.remove(currentPrintingObject);
        base.add(printingObject);
        printer.printingObject = printingObject;
      }
    }

    printer.simulate(simulationData);

    renderPrintObject(printingObject, simulationData);
  }, [
    threeState,
    objects,
    getSimulationDataObject,
    previousState,
    printer,
    renderPrintObject,
    shouldUpdateObjects,
    simulation,
  ]);

  useEffect(() => {
    observeSimulationUpdates();
  }, [observeSimulationUpdates]);

  return null;
}

Scene.propTypes = {};
