import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const operatorsQueryKeys = {
  defaultOperators: (workflowId) => ['defaultOperators', workflowId],
  templates: ['templates'],
  recommendedOperators: (workflowId) => [
    'showLoader',
    'recommendedOperators',
    workflowId,
  ],
};

const getInputsVisibilityConditions = (defaultOperators = []) => {
  const parsedDependencies = defaultOperators.reduce(
    (operatorsAcc, operator) => {
      const operatorName = operator.name;

      if (!operatorsAcc[operatorName]) {
        operatorsAcc[operatorName] = {};
      }

      operator.settings.forEach((setting) => {
        const { name, parents = [] } = setting;

        if (!operatorsAcc[operatorName][name]) {
          operatorsAcc[operatorName][name] = parents;
        }
      });

      return operatorsAcc;
    },
    {},
  );

  return parsedDependencies;
};

export default function useOperatorQueries({ workflowId } = {}) {
  const defaultOperatorsQuery = useQuery({
    queryKey: operatorsQueryKeys.defaultOperators(workflowId),
    queryFn: async () => {
      const defaultOperators = await client
        .get(endpoints.conceptsDefaultOperators, {
          params: {
            workflowId,
          },
        })
        .then((res) => res.data);

      return {
        defaultOperators,
        inputsVisibilityConditions:
          getInputsVisibilityConditions(defaultOperators),
        normalizedDefaultOperators: defaultOperators.reduce((acc, operator) => {
          acc[operator.name] = {
            ...operator,
            settings: operator.settings.reduce(
              (acc, setting) => ({
                ...acc,
                [setting.name]: setting,
              }),
              {},
            ),
          };

          return acc;
        }, {}),
      };
    },
    enabled: !!workflowId,
  });

  const templatesQuery = useQuery({
    queryKey: operatorsQueryKeys.templates,
    queryFn: () => client.get(endpoints.templates).then((res) => res.data),
    enabled: false,
  });

  const recommendedOperatorsQuery = useQuery({
    queryKey: operatorsQueryKeys.recommendedOperators(workflowId),
    queryFn: () =>
      client
        .get(
          endpoints.conceptRecommendedOperators.replace(
            ':conceptId',
            workflowId,
          ),
        )
        .then((res) => res.data),
    keepPreviousData: true,
    initialData: {},
    enabled: !!workflowId,
  });

  return {
    defaultOperatorsQuery,
    templatesQuery,
    recommendedOperatorsQuery,
  };
}
