import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ROUTE_MATCH_URL } from '@constants/router';
import { getIsShowingGrid } from '@selectors/conceptSelectors';
import { updateGridVisibility } from '@actions/conceptActions';

export const WORLD_ACTIONS = {
  GRID: 'Grid',
};

export default function useWorldTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { params } = useRouteMatch(ROUTE_MATCH_URL);
  const isShowingGrid = useSelector(getIsShowingGrid());
  const disableActions = params?.currentRoute !== 'workspaces';

  const handleGrid = useCallback(() => {
    dispatch(updateGridVisibility());
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        id: WORLD_ACTIONS.GRID,
        disabled: disableActions,
        active: isShowingGrid,
        name: intl.formatMessage({
          id: 'toolbar.world.grid',
          defaultMessage: WORLD_ACTIONS.GRID,
        }),
        iconName: 'grid_4x4_0',
        handler: handleGrid,
      },
    ],
    [intl, disableActions, isShowingGrid, handleGrid],
  );

  return actions;
}
