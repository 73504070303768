import styled, { css } from 'styled-components';

export const Frame = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-line;

  ${({ theme: { colors, borderRadius, spacing } }) => css`
    border-radius: ${borderRadius?.large};
    background-color: ${colors?.surfaceContainer};
    padding: ${spacing?.level4};
  `}
`;

export const MessageContainer = styled.div`
  display: flex;
  ${({ theme: { spacing } }) => css`
    padding: 0 ${spacing?.level4};
  `}
`;
