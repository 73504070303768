import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';

export default function useNozzle() {
  const queryClient = useQueryClient();

  const getNozzles = useCallback(
    (printerId) => {
      return queryClient.getQueryData(printerQueryKeys.nozzles(printerId));
    },
    [queryClient],
  );

  return {
    getNozzles,
  };
}
