import { useCallback, useRef, useState } from 'react';
import {
  FILE_EXTENSION_REG_EXP,
  MAX_UPLOAD_FILE_SIZE,
  MAX_UPLOAD_FILE_SIZE_STEP,
} from '@constants/designs';

import { useDispatch, useSelector } from 'react-redux';
import {
  getShowProgressBar,
  getUploadProgress,
} from '@selectors/designSelectors';
import client from '@api/client';
import endpoints from '@api/endpoints';
import useFileMutations from '@hooks/files/useFileMutations';
import useFile from '@hooks/files/useFile';
import useFileSocket from '@hooks/websocket/useFileSocket';
import { showErrorDialog } from '@actions/errorActions';
import { createDesign } from '@actions/designActions';
import takeFileScreenshot from '@utils/takeFileScreenshot';

export const useFileActions = ({ projectId }) => {
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
  const showUploadFileProgressBar = useSelector(getShowProgressBar());
  const uploadFileProgress = useSelector(getUploadProgress());
  const dispatch = useDispatch();
  const { addProjectFile } = useFile();
  const { createDesignThumbnailMutation } = useFileMutations();

  useFileSocket();
  const uploadFieldRef = useRef();

  const handleAddFile = useCallback(() => {
    uploadFieldRef.current.value = null;
    uploadFieldRef.current?.click();
  }, []);

  const getFileType = useCallback((fileName = '') => {
    return FILE_EXTENSION_REG_EXP.exec(fileName)?.[1]?.toLowerCase();
  }, []);

  const fetchFile = useCallback(async (fileId) => {
    const { data: file } = await client.get(
      endpoints.design.replace(':designId', fileId),
    );

    return file;
  }, []);

  const handleUploadFieldChange = useCallback(
    async ({ target }) => {
      if (!target.files) return;

      const [file] = target.files;
      const fileSize = file.size / 1024 / 1024; // in MiB
      const filetype = getFileType(file.name);
      if (
        ((filetype == 'step' || filetype == 'stp') &&
          fileSize > MAX_UPLOAD_FILE_SIZE_STEP) ||
        fileSize > MAX_UPLOAD_FILE_SIZE
      ) {
        return dispatch(
          showErrorDialog(
            'Design creation failed',
            'The file exceeds the maximum size',
          ),
        );
      }

      try {
        const createdDesign = await dispatch(
          createDesign(URL.createObjectURL(file), file.name, file, projectId),
        );

        setIsTakingScreenshot(true);

        const createdFile = await fetchFile(createdDesign.id);
        const thumbnailBlob = await takeFileScreenshot(
          createdFile,
          createdFile?.displayData,
        );

        const { signedUrl: thumbnailUrl } =
          await createDesignThumbnailMutation.mutateAsync({
            fileId: createdFile.id,
            thumbnailBlob,
            projectId,
          });

        addProjectFile(projectId, {
          ...createdDesign,
          thumbnailUrl,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsTakingScreenshot(false);
      }
    },
    [
      dispatch,
      projectId,
      getFileType,
      fetchFile,
      addProjectFile,
      createDesignThumbnailMutation,
      setIsTakingScreenshot,
    ],
  );

  return {
    getFileType,
    showUploadFileProgressBar,
    uploadFileProgress,
    uploadFieldRef,
    handleUploadFieldChange,
    handleAddFile,
    isTakingScreenshot,
  };
};
