export const asyncActionStages = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const createActionTypes = (syncActions = [], asyncActions = []) => {
  let outputActions = {};

  asyncActions.forEach((baseActionName) => {
    const nextActions = Object.values(asyncActionStages).reduce(
      (acc, asyncActionStage) => ({
        ...acc,
        [`${baseActionName}_${asyncActionStage}`]: `${baseActionName}_${asyncActionStage}`,
      }),
      {},
    );

    outputActions = {
      ...outputActions,
      ...nextActions,
    };
  });

  syncActions.forEach((actionType) => {
    outputActions = {
      ...outputActions,
      [actionType]: actionType,
    };
  });

  return outputActions;
};
