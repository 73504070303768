import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  whenEscapeButtonPressed,
  preventDefault,
} from '@utils/interactionEvents';
import NavigationHeader, {
  NAVIGATION_HEADER_VARIANT_DEFAULT,
  NAVIGATION_HEADER_VARIANT_LARGE_TITLE,
} from '@components/2-molecules/NavigationHeader';
import ActionFooter from '@components/2-molecules/ActionFooter';
import { setApplicationScrollable } from '@stylesheets/helpers';
import { Wrapper, Content } from './Modal.styled';

const Modal = ({
  children,
  className,
  closeOnEscape,
  contentId = 'dialog__content',
  dataTestId = 'modal',
  disableContentPadding,
  disabled,
  floatingFooter,
  floatingHeader,
  headerEndingIconButtonDisabled,
  headerEndingIconButtonVariant,
  headerLeadingIconButtonDisabled,
  headerTitle,
  headerZIndex,
  id,
  loading,
  noFooterBackground,
  onBackIconButtonClick,
  onClose,
  onCloseIconButtonClick,
  onPrimaryButtonClick, // This is a prop isn't executed when renderDialogAs is 'form'
  onSecondaryButtonClick,
  onSubmit, // This is a prop is executed only when renderDialogAs is 'form'
  primaryButtonDisabled,
  primaryButtonIconName,
  primaryButtonLabel,
  primaryButtonLoading,
  renderAsForm,
  secondaryButtonDisabled,
  secondaryButtonIconName,
  secondaryButtonLabel,
  show,
  withFooterDivider,
}) => {
  const wrapperRef = useRef();
  const disableActions = disabled || loading;

  const onKeyDown = useCallback(
    (e) => {
      closeOnEscape && whenEscapeButtonPressed(onClose)(e);
    },
    [closeOnEscape, onClose],
  );

  useEffect(() => {
    if (!closeOnEscape) return;

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeOnEscape, onKeyDown]);

  useEffect(() => {
    if (show) {
      wrapperRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setApplicationScrollable(false);
    } else {
      setApplicationScrollable(true);
    }

    return () => {
      setApplicationScrollable(true);
    };
  }, [show]);

  return (
    <Wrapper
      as={renderAsForm ? 'form' : undefined}
      className={className}
      data-testid={dataTestId}
      floatingFooter={floatingFooter}
      floatingHeader={floatingHeader}
      headerZIndex={headerZIndex}
      id={id}
      noFooterBackground={noFooterBackground}
      onSubmit={preventDefault(onSubmit)}
      show={show}
      tabIndex={0}
    >
      {(onBackIconButtonClick || onCloseIconButtonClick) && (
        <NavigationHeader
          dataTestId={`${dataTestId}__navigation-header`}
          disabled={disableActions}
          leadingIconButtonDisabled={
            headerLeadingIconButtonDisabled || disableActions
          }
          variant={
            !onBackIconButtonClick && headerTitle
              ? NAVIGATION_HEADER_VARIANT_LARGE_TITLE
              : NAVIGATION_HEADER_VARIANT_DEFAULT
          }
          title={headerTitle}
          zIndex={headerZIndex}
          leadingIconButtonIconName={
            onBackIconButtonClick ? 'arrow_back_0' : undefined
          }
          endingIconButtonIconName={
            onCloseIconButtonClick ? 'close_0' : undefined
          }
          endingIconButtonDisabled={
            headerEndingIconButtonDisabled || disableActions
          }
          endingIconButtonVariant={headerEndingIconButtonVariant}
          onLeadingIconButtonClick={onBackIconButtonClick}
          onEndingIconButtonClick={onCloseIconButtonClick}
        />
      )}

      <Content
        id={contentId}
        data-testid={`${dataTestId}__content`}
        disableContentPadding={disableContentPadding}
      >
        {children}
      </Content>

      {(primaryButtonLabel || secondaryButtonLabel) && (
        <ActionFooter
          dataTestId={`${dataTestId}__action-footer`}
          disabled={disabled}
          primaryButtonDisabled={primaryButtonDisabled}
          primaryButtonLoading={primaryButtonLoading || loading}
          primaryButtonLabel={primaryButtonLabel}
          primaryButtonIconName={primaryButtonIconName}
          onPrimaryButtonClick={renderAsForm ? undefined : onPrimaryButtonClick}
          secondaryButtonDisabled={secondaryButtonDisabled || disableActions}
          secondaryButtonLabel={secondaryButtonLabel}
          secondaryButtonIconName={secondaryButtonIconName}
          primaryButtonType={renderAsForm ? 'submit' : undefined}
          onSecondaryButtonClick={onSecondaryButtonClick}
          withDivider={withFooterDivider}
        />
      )}
    </Wrapper>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  contentId: PropTypes.string,
  dataTestId: PropTypes.string,
  disableContentPadding: PropTypes.bool,
  disabled: PropTypes.bool,
  floatingFooter: PropTypes.bool,
  floatingHeader: PropTypes.bool,
  headerEndingIconButtonDisabled: PropTypes.bool,
  headerEndingIconButtonVariant: PropTypes.string,
  headerLeadingIconButtonDisabled: PropTypes.bool,
  headerTitle: PropTypes.string,
  headerZIndex: PropTypes.number,
  id: PropTypes.string,
  loading: PropTypes.bool,
  noFooterBackground: PropTypes.bool,
  onBackIconButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  onCloseIconButtonClick: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonIconName: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  renderAsForm: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonIconName: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  show: PropTypes.bool,
  withFooterDivider: PropTypes.bool,
};

export default Modal;
