import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Checkbox, StateLayer, Icon } from './Switch.styled';
import { whenEnterButtonPressed } from '@utils/interactionEvents';

const Switch = ({
  id,
  className,
  dataTestId = 'switch',
  disabled,
  enabled = false,
  onChange,
}) => {
  const handleOnChange = useCallback(() => {
    onChange(!enabled);
  }, [enabled, onChange]);

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      enabled={enabled}
    >
      <Checkbox
        data-testid={`${dataTestId}__checkbox`}
        id={id}
        disabled={disabled}
        enabled={enabled}
        checked={enabled}
        onChange={handleOnChange}
        onKeyDown={whenEnterButtonPressed(handleOnChange)}
      />

      <StateLayer disabled={disabled} enabled={enabled} />

      <Icon disabled={disabled} enabled={enabled} />
    </Wrapper>
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
