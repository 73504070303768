import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  height: 48px;

  .rc-slider-rail {
    ${({ theme: { colors } }) => css`
      background-color: ${colors.surfaceContainerHighest};
    `}
  }

  .rc-slider-track,
  .rc-slider-handle {
    ${({ theme: { colors } }) => css`
      background-color: ${colors.primary};
      border-color: ${colors.primary};
    `}
  }

  .rc-slider-handle {
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-top: -8px;

    &:before {
      display: none;
      content: '';
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${({ theme: { colors } }) => css`
        background-color: ${rgba(colors.primary, 0.12)};
      `}
    }

    &:focus-visible,
    &:hover {
      ${({ theme: { colors } }) => css`
        background-color: ${colors.primary};
        border-color: ${colors.primary};
        -webkit-tap-highlight-color: none;
        box-shadow: none;
      `}

      &:before {
        display: block;
      }
    }

    &.rc-slider-handle-dragging {
      box-shadow: none;

      ${({ theme: { colors } }) => css`
        border-color: ${colors.primary};
      `}
    }
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  grid-column: 2;

  ${({ theme: { spacing } }) => css`
    padding-inline: ${spacing.level4};
  `}
`;

export const MinTitle = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
  color: 'onSurface',
})`
  min-width: 64px;

  ${({ theme: { spacing } }) => css`
    padding-inline: ${spacing.level2};
  `}
`;

export const MaxTitle = styled(MinTitle)``;
