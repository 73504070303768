import React, { useEffect, useMemo } from 'react';
import { enclosureTypeFiles } from '@constants/printers/componentFiles';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { useIntl } from 'react-intl';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { generateSingleFileUpload } from '@utils/filesUpload';

export const EnclosureSetting = () => {
  const intl = useIntl();
  const { values, initialValues, setFieldValue } = useFormikContext();
  const setValue = useMemo(
    () => (field, value) => {
      setFieldValue(`enclosureTypeSetting.${field}`, value);
    },
    [setFieldValue],
  );
  const enclosureTypeValues = values['enclosureTypeSetting'];

  useEffect(() => {
    if (Object.keys(enclosureTypeValues.files).length === 0) {
      setValue(
        'files',
        Object.fromEntries(
          enclosureTypeFiles.map(({ value, label }) => [
            value,
            {
              label,
              file: null,
              fileName: null,
            },
          ]),
        ),
      );
    }
  }, [enclosureTypeValues, setValue]);

  const renderUploadRows = () => {
    if (enclosureTypeValues.files) {
      return Object.keys(enclosureTypeValues.files).map((axisKey) => {
        const entry = enclosureTypeValues.files[axisKey];
        return generateSingleFileUpload(
          entry,
          'printer-components-enclosure-type__category-upload',
          axisKey,
          (file) => {
            setValue(`files.${axisKey}.file`, file);
            setValue(`files.${axisKey}.fileName`, file.name + new Date());
          },
          {
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `enclosureTypeSetting.files.${axisKey}.fileName`,
            ),
          },
        );
      });
    }
  };

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.settings.information.label',
      })}
      dataTestId={`printer-components-enclosure-type__category-information`}
      expand
    >
      <DiagramRowComponent
        src={'/img/digitalTwin/enclosure/diagram_enclosure.svg'}
        description_key={
          'printers.settings.enclosure.diagram.description.label'
        }
        second_description_key={'printers.settings.upload.export.label'}
      />
      {renderUploadRows()}
    </SettingsCategory>
  );
};

export const EnclosureTypeSettingValidationSchema = Yup.object().shape({
  files: Yup.object().test(
    'areFilesValid',
    'All files must be provided',
    (files) => {
      return Object.values(files).every((entry) => entry.file !== null);
    },
  ),
});

export const initDataEnclosureType = (
  enclosureDefinitionResponse,
  initialValues,
) => {
  return {
    ...initialValues,
    files: Object.fromEntries(
      enclosureTypeFiles.map(({ value, label, allowedExtensions }) => [
        value,
        {
          label,
          file: new File(
            [],
            enclosureDefinitionResponse.modelUrls[value].fileName,
          ),
          fileName: enclosureDefinitionResponse.modelUrls[value].fileName,
          allowedExtensions,
        },
      ]),
    ),
  };
};
