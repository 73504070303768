import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import useDialog from '@hooks/useDialog';
import { getSelectedOperatorOutputId } from '@selectors/conceptSelectors';
import {
  selectOperatorOutput as selectOperatorOutputAction,
  freezeOperatorOutput,
  unfreezeOperatorOutput,
} from '@actions/conceptActions';
import { ModalDataTypes } from '@constants/modalDataTypes';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingOutput from '@components/2-molecules/SettingOutput';
import SettingInfo from '@components/2-molecules/SettingInfo';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';
import { NON_GEO_OUTPUTS_COLOUR } from '@constants/utilityConstants';
import {
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
} from '@utils/operatorStatus';

const getDuration = (seconds) => {
  const h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  if (m < 10) m = '0' + m;
  let s = Math.floor((seconds % 3600) % 60);
  if (s < 10) s = '0' + s;
  return h + ':' + m + ':' + s;
};

const getWeight = (weight) => {
  let output = '';

  if (weight > 1) {
    const calculatedWeight = Math.round(weight * 100) / 100;
    output = `${calculatedWeight}kg`;
  } else {
    const calculatedWeight = Math.floor(weight * 1000);
    output = `${calculatedWeight}g`;
  }

  return output;
};

const removeColourFromString = (value) => {
  if (value.includes(NON_GEO_OUTPUTS_COLOUR)) {
    return value.replace(NON_GEO_OUTPUTS_COLOUR, '');
  }

  return value;
};

const WorkflowOperatorOutputs = ({
  disabled,
  disablePublish,
  operator,
  operatorStatus,
  computeOperator,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [expandInfoOutputs, setExpandInfoOutputs] = useState(false);
  const selectedOperatorOutputId = useSelector(getSelectedOperatorOutputId());
  const { getSelectedWorkflowProjectId, getIsWorkflowPublic } = useWorkflow();
  const {
    getOperatorOutputGroups,
    getIsOperatorOutputFrozen,
    selectOperatorOutput,
    getOperatorInputDescription,
  } = useOperator();
  const { showDialog } = useDialog();
  const selectedWorkflowProjectId = getSelectedWorkflowProjectId();
  const {
    geoOutputs,
    toolpathOutputs,
    nonGeoOutputs: infoOutputs,
  } = getOperatorOutputGroups(operator);
  const isWorkflowPublic = getIsWorkflowPublic(operator?.conceptId);
  const geometryOutputs = [...geoOutputs, ...toolpathOutputs];

  const showGeometryOutputs = !isEmpty(geometryOutputs);
  const showInfoOutputs = !isEmpty(infoOutputs) && operator.computed;
  const outputsAvailable = showGeometryOutputs || showInfoOutputs;

  const isOperatorComputable = operatorStatus === OPERATOR_HEADER_STATUS_ALERT;

  const settingsCategoryOptionalProps = useMemo(() => {
    let props = {};

    if (isOperatorComputable) {
      props = {
        ...props,
        endingButtonDisabled: disabled,
        endingButtonIconName: 'sync_0',
        endingButtonTitle: intl.formatMessage({
          id: 'workflow.operator.output.category.compute_button.title',
          defaultMessage: 'Apply new changes',
        }),
        onEndingButtonClick: computeOperator,
      };
    }

    return props;
  }, [intl, disabled, isOperatorComputable, computeOperator]);

  const getInfoOutputValue = useCallback((output) => {
    const outputValue = output?.value || '';
    const weightInfo = !!output?.name?.toLowerCase()?.includes('weight');
    const durationInfo = !!output?.name?.toLowerCase()?.includes('duration');

    if (weightInfo) return getWeight(outputValue);

    if (durationInfo) return getDuration(outputValue);

    return removeColourFromString(outputValue);
  }, []);

  const handleOutputInfoCategoryExpand = useCallback(() => {
    setExpandInfoOutputs(!expandInfoOutputs);
  }, [expandInfoOutputs]);

  const handlePublishOutput = useCallback(
    (output) => () => {
      showDialog(ModalDataTypes.PUBLISH_FILE, {
        output,
        projectId: selectedWorkflowProjectId,
      });

      dispatch(selectOperatorOutputAction(output));
    },
    [dispatch, selectedWorkflowProjectId, showDialog],
  );

  if (!outputsAvailable) return null;

  return (
    <div>
      {showGeometryOutputs && (
        <SettingsCategory
          title={intl.formatMessage({
            id: 'general.outputs',
            defaultMessage: 'Outputs',
          })}
          withDividerTop
          expand
          {...settingsCategoryOptionalProps}
        >
          {geometryOutputs.map((output) => {
            const outputIsSelected = selectedOperatorOutputId === output?.id;
            const outputIsFrozen = getIsOperatorOutputFrozen(output.id);
            let optionalGeometryOutputProps = {};

            if (output.isGeometricOutput) {
              optionalGeometryOutputProps = {
                preEndingIconButton: {
                  disabled: disabled || disablePublish,
                  iconName: 'save_0',
                  onClick: handlePublishOutput(output),
                },
                endingIconButton: {
                  disabled: disabled,
                  iconName: outputIsFrozen
                    ? 'visibility_lock_1'
                    : 'visibility_lock_0',
                  selected: outputIsFrozen,
                  title: intl.formatMessage({
                    id: outputIsFrozen
                      ? 'operator.actions.output.unlock_visibility'
                      : 'operator.actions.output.lock_visibility',
                    defaultMessage: outputIsFrozen
                      ? 'Unlock visibility'
                      : 'Lock visibility',
                  }),
                  onClick: () =>
                    outputIsFrozen
                      ? dispatch(unfreezeOperatorOutput([output.id]))
                      : dispatch(freezeOperatorOutput(output)),
                },
              };
            }

            return (
              <SettingOutput
                key={output.id}
                radioButton={{
                  disabled: !isWorkflowPublic && disabled,
                  checked: outputIsSelected,
                  value: output.id,
                  onChange: () => selectOperatorOutput(output),
                }}
                label={output.name}
                labelTooltip={{
                  tooltipAppearDelay: 200,
                  tooltipWidth: 350,
                  tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
                  tooltipOffsetRight: 64,
                  tooltipBridge: false,
                  tooltipInfoRow: {
                    label: output.name,
                    description: getOperatorInputDescription(
                      operator,
                      output.id,
                    ),
                  },
                }}
                {...optionalGeometryOutputProps}
              />
            );
          })}
        </SettingsCategory>
      )}

      {showInfoOutputs && (
        <SettingsCategory
          title={intl.formatMessage({
            id: 'general.info',
            defaultMessage: 'Info',
          })}
          expand={expandInfoOutputs}
          expanTransitionTime={200}
          onHeaderClick={handleOutputInfoCategoryExpand}
          withDividerTop
        >
          {infoOutputs.map((output) => (
            <SettingInfo
              key={output.id}
              label={output.name}
              labelTooltip={{
                tooltipAppearDelay: 200,
                tooltipWidth: 350,
                tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
                tooltipOffsetRight: 20,
                tooltipInfoRow: {
                  label: output.name,
                  description: getOperatorInputDescription(operator, output.id),
                },
              }}
              infoText={getInfoOutputValue(output) || '-'}
            />
          ))}
        </SettingsCategory>
      )}
    </div>
  );
};

WorkflowOperatorOutputs.propTypes = {
  disabled: PropTypes.bool,
  disablePublish: PropTypes.bool,
  operator: PropTypes.object,
  operatorStatus: PropTypes.oneOf([
    OPERATOR_HEADER_STATUS_HIGHLIGHT,
    OPERATOR_HEADER_STATUS_ALERT,
    OPERATOR_HEADER_STATUS_ALERT_MISS,
  ]),
  computeOperator: PropTypes.func,
};

export default WorkflowOperatorOutputs;
