import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { whenEnterButtonPressed } from '@utils/interactionEvents';
import {
  Wrapper,
  Tab,
  StateLayer,
  Icon,
  Label,
  ActiveLine,
} from './Tabs.styled';

export const TABS_VARIANT_ICON = 'icon';
export const TABS_VARIANT_TEXT = 'text';
export const TABS_VARIANT_ICON_TEXT = 'iconText';

const Tabs = ({
  disabled,
  className,
  dataTestId = 'tabs',
  onTabClick,
  tabs = [],
  variant = TABS_VARIANT_TEXT,
}) => {
  const wrapperRef = useRef();
  const activeLineRef = useRef();

  const isIconVariant = variant === TABS_VARIANT_ICON;
  const withIcon = [TABS_VARIANT_ICON, TABS_VARIANT_ICON_TEXT].includes(
    variant,
  );

  const withText = [TABS_VARIANT_TEXT, TABS_VARIANT_ICON_TEXT].includes(
    variant,
  );

  const handleOnTabClick = useCallback(
    (tab) => () => {
      onTabClick?.(tab);
    },
    [onTabClick],
  );

  const calculateActiveLanePosition = useCallback(() => {
    const tabsWrapperElement = wrapperRef.current;

    if (!tabsWrapperElement) return;

    const tabElements = tabsWrapperElement?.childNodes;

    if (!tabElements) return;

    const activeTabIndex = tabs?.findIndex?.(({ active }) => active);
    const activeTabElement =
      tabElements?.[activeTabIndex !== -1 ? activeTabIndex : 0];

    if (!activeTabElement) return;

    const activeTabElementPosition = activeTabElement.getBoundingClientRect();

    activeLineRef.current.style.width = isIconVariant
      ? '24px'
      : `${activeTabElementPosition.width}px`;
    activeLineRef.current.style.top = `${
      activeTabElement.offsetTop + activeTabElementPosition.height - 2
    }px`;
    activeLineRef.current.style.left = isIconVariant
      ? `${
          activeTabElement.offsetLeft + activeTabElementPosition.width / 2 - 12
        }px`
      : `${activeTabElement.offsetLeft}px`;
  }, [isIconVariant, tabs]);

  useEffect(() => {
    calculateActiveLanePosition();

    window.addEventListener('resize', calculateActiveLanePosition);

    return () => {
      window.removeEventListener('resize', calculateActiveLanePosition);
    };
  }, [calculateActiveLanePosition]);

  return (
    <Wrapper ref={wrapperRef} data-testid={dataTestId} className={className}>
      {tabs?.map((tab) => (
        <Tab
          data-testid={`${dataTestId}__tab-${tab?.id}`}
          key={`tabs-tab-${tab?.id}`}
          active={tab?.active}
          disabled={disabled || tab?.disabled}
          onClick={handleOnTabClick(tab)}
          onKeyDown={whenEnterButtonPressed(handleOnTabClick(tab))}
          variant={variant}
          tabIndex={disabled || tab?.disabled ? -1 : 0}
        >
          <StateLayer active={tab?.active} />

          {withIcon && (
            <Icon name="Dot_1" variant={variant} active={tab?.active}>
              {tab?.icon}
            </Icon>
          )}

          {withText && (
            <Label
              dataTestId={`${dataTestId}__tab-${tab?.id}__label`}
              variant="titleSmall"
              color="onSurface"
            >
              {tab?.title}
            </Label>
          )}
        </Tab>
      ))}

      <ActiveLine ref={activeLineRef} disabled={disabled} variant={variant} />
    </Wrapper>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      active: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  variant: PropTypes.oneOf([
    TABS_VARIANT_ICON,
    TABS_VARIANT_TEXT,
    TABS_VARIANT_ICON_TEXT,
  ]),
};

export default Tabs;
