import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Wrapper, LeftColumn, RightColumn } from './SettingLink.styled';

const SettingLink = ({
  button = {},
  className,
  dataTestId = 'setting-link',
  label,
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <Text
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </Text>
      </LeftColumn>

      <RightColumn>
        <Button
          dataTestId={`${dataTestId}__button`}
          {...button}
          variant={BUTTON_VARIANT_TEXT}
        />
      </RightColumn>
    </Wrapper>
  );
};

SettingLink.propTypes = {
  button: PropTypes.shape(Button.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  withIdentation: PropTypes.bool,
};

export default SettingLink;
