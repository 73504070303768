import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import React, { useCallback, useMemo } from 'react';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import { useIntl } from 'react-intl';
import InfoRow from '@components/1-atoms/InfoRow';
import DateFormatter from '@app/lib/DateFormatter';

const MODAL_ID = ModalDataTypes.USER_INFO;

const UserInfoDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  return (
    <DialogPortal
      renderAsForm
      dataTestId="user-info"
      dialogId={MODAL_ID}
      onSubmit={handleClose}
      primaryButtonLabel={intl.formatMessage({
        id: 'general.close',
        defaultMessage: 'Close',
      })}
    >
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={intl.formatMessage({
          id: 'userinfo.header.title',
          defaultMessage: 'More info',
        })}
      />

      <InfoRow
        label={intl.formatMessage({
          id: 'organization.label.lastupdate',
          defaultMessage: 'Last Update',
        })}
        description={
          dialogData.user.lastUpdate
            ? DateFormatter.formatIso(dialogData.user.lastUpdate)
            : '-'
        }
      />
      <InfoRow
        label={intl.formatMessage({
          id: 'organization.label.lastlogin',
          defaultMessage: 'Last Login',
        })}
        description={
          dialogData.user.lastLogin
            ? DateFormatter.formatIso(dialogData.user.lastLogin)
            : '-'
        }
      />
    </DialogPortal>
  );
};

UserInfoDialog.propTypes = {};

export default UserInfoDialog;
