const font = {
  // Display
  displayLarge: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '57px',
    lineHeight: '64px',
    fontWeight: '600',
  },
  displayMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '45px',
    lineHeight: '52px',
    fontWeight: '600',
  },
  displaySmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '44px',
    fontWeight: '600',
  },

  // Headline
  headlineLarge: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: '400',
  },
  headlineMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '36px',
    fontWeight: '400',
  },
  headlineSmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '400',
  },

  // Title
  titleLarge: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: '600',
  },
  titleMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: '600',
  },
  titleSmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontWeight: '600',
  },

  // Label
  labelLarge: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontWeight: '600',
  },
  labelMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.5px',
    fontWeight: '600',
  },
  labelSmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '11px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    fontWeight: '600',
  },

  // Body
  bodyLarge: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
  },
  bodyMedium: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
  },
  bodySmall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
  },
};

export default font;
