import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { useIntl } from 'react-intl';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const LinearSetting = () => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();

  return ['height', 'maxSpeed', 'originValue', 'endValue'].map((field) => (
    <FormikField
      key={`linear_${field}`}
      component={SettingTextField}
      label={intl.formatMessage({
        id: `printers.settings.plinth.parameters.${field}.label`,
      })}
      field1={{
        type: 'number',
        dirty: checkIfFieldIsDirty(
          values,
          initialValues,
          `plinthTypeSetting.plinthProperties.${field}`,
        ),
      }}
      dataTestId={`printer-components-plinth-type__category-parameters__linear-${field}`}
      name={`plinthTypeSetting.plinthProperties.${field}`}
    />
  ));
};
