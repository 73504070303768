import React from 'react';
import PropTypes from 'prop-types';
import EditorLayout, {
  LayoutSettingBarLayer,
} from '@components/1-atoms/Layouts/EditorLayout';
import SkeletonList from '@components/1-atoms/SkeletonList';
import Toolbar from '@components/2-molecules/Toolbar';
import SettingBar from '@components/2-molecules/SettingBar';

const LoadingEditorLayout = ({ settingBarCollapsed = true }) => {
  return (
    <EditorLayout settingBarCollapsed={settingBarCollapsed}>
      <Toolbar logoButton={{ onClick: () => {} }} />

      <>
        <LayoutSettingBarLayer visible>
          <Toolbar
            headerIconButtons={[
              {
                id: 0,
                iconName: 'thumbnail_bar_0',
              },
            ]}
          />
        </LayoutSettingBarLayer>

        <LayoutSettingBarLayer visible={!settingBarCollapsed}>
          <SettingBar>
            <SkeletonList length={3} />
          </SettingBar>
        </LayoutSettingBarLayer>
      </>
    </EditorLayout>
  );
};

LoadingEditorLayout.propTypes = {
  settingBarCollapsed: PropTypes.bool,
};

export default LoadingEditorLayout;
