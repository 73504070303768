import * as actionTypes from '@constants/actionTypes';

export const startComputation = () => ({
  type: actionTypes.START_COMPUTATION,
});

export const cancelComputation = () => ({
  type: actionTypes.CANCEL_COMPUTATION,
});

export const computationCompleted = () => ({
  type: actionTypes.COMPUTATION_COMPLETED,
});

export const computationFailed = () => ({
  type: actionTypes.COMPUTATION_FAILED,
});

export const setComputationProgressHandler = (handler = {}) => ({
  type: actionTypes.SET_COMPUTATION_PROGRESS_HANDLER,
  payload: { handler },
});

export const resetComputationProgressHandler = () => ({
  type: actionTypes.RESET_COMPUTATION_PROGRESS_HANDLER,
});
