import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@components/1-atoms/IconButton';
import { preventDefault, stopPropagation } from '@utils/interactionEvents';
import {
  Wrapper,
  LeftColumn,
  Icon,
  Content,
  Label,
  Description,
} from './InfoRow.styled';

const InfoRow = ({
  className,
  dataTestId = 'info-row',
  description,
  disabled,
  endingIconButtonDropDownProps = {},
  endingIconButtonIconName,
  endingIconButtonRef,
  label,
  leadingIconName,
  noPadding,
  onEndingIconButtonClick,
  withDivider,
}) => {
  const withEndingIconButton = !!endingIconButtonIconName;

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      noPadding={noPadding}
      withDivider={withDivider}
      withEndingIconButton={withEndingIconButton}
    >
      <LeftColumn>
        {leadingIconName && <Icon disabled={disabled} name={leadingIconName} />}

        <Content>
          <Label dataTestId={`${dataTestId}__label`} disabled={disabled}>
            {label}
          </Label>

          {description && (
            <Description
              dataTestId={`${dataTestId}__description`}
              disabled={disabled}
            >
              {description}
            </Description>
          )}
        </Content>
      </LeftColumn>

      {endingIconButtonIconName && (
        <IconButton
          ref={endingIconButtonRef}
          dataTestId={`${dataTestId}__ending-icon-button`}
          iconName={endingIconButtonIconName}
          onClick={
            disabled
              ? undefined
              : preventDefault(stopPropagation(onEndingIconButtonClick))
          }
          {...(endingIconButtonDropDownProps || {})}
        />
      )}
    </Wrapper>
  );
};

InfoRow.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  endingIconButtonDropDownProps: PropTypes.object,
  endingIconButtonIconName: PropTypes.string,
  endingIconButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  label: PropTypes.string.isRequired,
  leadingIconName: PropTypes.string,
  noPadding: PropTypes.bool,
  onClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  withDivider: PropTypes.bool,
};

export default InfoRow;
