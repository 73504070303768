import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
import moment from 'moment';
import {
  preventDefault,
  whenEnterButtonPressed,
} from '@utils/interactionEvents';
import Field from '@components/1-atoms/Field';
import {
  Wrapper,
  Conversation,
  MessageBox,
  Message,
  TypingMessage,
  Typing,
  Time,
} from './TalkToAiChatBox.styled';

export default function TalkToAiChatBox({
  chatAutoFocus,
  conversation = [],
  disabled,
  handleSend,
  hideConversation,
  hidePrompt = false,
  loading,
}) {
  const intl = useIntl();
  const [message, setMessage] = useState('');
  const conversationRef = useRef();
  const showConversation =
    !hideConversation && (loading || !!conversation?.length);

  const getTime = useCallback(
    (time = moment()) => moment(time).utc().format('HH:mm'),
    [],
  );

  const scrollConversationToLatest = useCallback(() => {
    const conversationElement = conversationRef.current;

    if (!conversationElement) return;

    conversationElement?.scrollTo({
      top: conversationElement?.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }, [conversationRef]);

  const onChange = useCallback(({ target }) => {
    setMessage(target?.value);
  }, []);

  const onSendClick = useCallback(() => {
    const formattedMessage = message.trim();

    if (!formattedMessage) return;

    if (handleSend) handleSend(message);

    setMessage('');
  }, [message, handleSend]);

  useEffect(() => {
    if (showConversation) scrollConversationToLatest();
  }, [conversation, showConversation, scrollConversationToLatest]);

  return (
    <Wrapper>
      {showConversation && (
        <Conversation ref={conversationRef}>
          {conversation?.map(({ type, data, time, error }) => (
            <MessageBox
              key={uniqueId()}
              error={error}
              answer={type === 'answer'}
            >
              <Message>{data}</Message>
              {time && <Time>{getTime(time)}</Time>}
            </MessageBox>
          ))}

          {loading && (
            <MessageBox loader={loading}>
              <TypingMessage>
                <Typing />
              </TypingMessage>
              <Time>{getTime()}</Time>
            </MessageBox>
          )}
        </Conversation>
      )}

      {!hidePrompt && (
        <Field
          disabled={disabled || loading}
          autoFocus={chatAutoFocus}
          placeholder={intl.formatMessage({
            id: 'gpt.chatbox.placeholder',
            defaultMessage: 'Write here',
          })}
          value={message}
          onChange={onChange}
          onKeyPress={whenEnterButtonPressed(preventDefault(onSendClick))}
          endingButtonIconName="send_0"
          endingButtonColor="primary"
          onEndingButtonClick={onSendClick}
        />
      )}
    </Wrapper>
  );
}

TalkToAiChatBox.propTypes = {
  chatAutoFocus: PropTypes.bool,
  conversation: PropTypes.array,
  disabled: PropTypes.bool,
  handleSend: PropTypes.func,
  hideConversation: PropTypes.bool,
  hidePrompt: PropTypes.bool,
  loading: PropTypes.bool,
};
