import styled, { css } from 'styled-components';
import {
  InputContainer,
  Input,
  LeadingIconContainer,
  EndingButtonContainer,
} from '@components/1-atoms/Field/Field.styled';
import { flexCenterVertical, flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  position: relative;

  ${InputContainer} {
    ${({ withSearchResults, theme: { borderRadius } }) => css`
      border-radius: ${borderRadius?.extralarge};

      ${when(
        withSearchResults,
        `
          border-radius: ${borderRadius?.extralarge} ${borderRadius?.extralarge} 0 0;
        `,
      )}
    `}
  }

  ${LeadingIconContainer} {
    ${({ theme: { spacing } }) => css`
      margin-left: ${spacing?.level2};
    `}
  }

  ${EndingButtonContainer} {
    ${({ theme: { spacing } }) => css`
      margin-right: ${spacing?.level2};
    `}
  }

  ${Input}:focus {
    border-color: transparent;
  }
`;

export const SearchResults = styled.ul`
  ${flexColumn}
  max-height: 400px;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: auto;
  scrollbar-gutter: stable;
  padding: 0;
  margin: 0;

  ${({ theme: { borderRadius, colors } }) => css`
    border-top: 1px solid ${colors.outline};
    border-radius: 0 0 ${borderRadius.extralarge} ${borderRadius.extralarge};
    background-color: ${colors?.surfaceContainerHighest};
  `}
`;

export const SearchResultsItem = styled.li`
  ${flexCenterVertical}
  flex-shrink: 0;
  position: relative;
  cursor: pointer;

  ${({ theme: { font, colors, spacing } }) => css`
    height: ${spacing.level9};
    ${font.bodyLarge}
    color: ${colors.onSurface};
    padding: ${spacing.level3} ${spacing.level6} ${spacing.level3}
      ${spacing.level5};
  `}

  &:last-child {
    ${({ theme: { borderRadius } }) => css`
      border-radius: 0 0 ${borderRadius.extralarge} ${borderRadius.extralarge};
    `}
  }

  &:before {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    ${({ theme: { colors } }) => css`
      background-color: ${colors?.onSurface};
    `}
  }

  &:hover:before {
    opacity: 0.08;
    visibility: visible;
  }
`;
