import actionTypes from '@actions';
import { SCREEN_SIZE } from '@stylesheets/screen';

const initialState = {
  data: {
    navigationBarHistory: {},
  },
  ui: {
    screeSize: SCREEN_SIZE.XLARGE,
    banners: [],
  },
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCREEN_SIZE:
      return {
        ...state,
        ui: {
          ...state.ui,
          screenSize: action.payload.screenSize,
        },
      };

    case actionTypes.ADD_BANNER: {
      const bannerExists = state.ui.banners.some(
        (banner) => banner.key === action.payload.banner.key,
      );
      if (bannerExists) {
        return state;
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          banners: [...state.ui.banners, action.payload.banner],
        },
      };
    }

    case actionTypes.REMOVE_BANNER:
      return {
        ...state,
        ui: {
          ...state.ui,
          banners: state.ui.banners.filter(
            (banner) => banner.key !== action.payload.bannerKey,
          ),
        },
      };

    case actionTypes.SET_NAVIGATION_BAR_HISTORY:
      return {
        ...state,
        data: {
          ...state.data,
          navigationBarHistory: action.payload.navigationBarHistory,
        },
      };

    case actionTypes.RESET_NAVIGATION_BAR_HISTORY:
      return {
        ...state,
        data: {
          ...state.data,
          navigationBarHistory: initialState.data.navigationBarHistory,
        },
      };

    default:
      return state;
  }
};

export default applicationReducer;
