import { PrinterCategories } from './printerCategories';
import { UserRoles } from './userRoles';
import { ValueTypes } from './valueTypes';

export const defaultSixAxisRobotSettings = [
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA1',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA2',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA3',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 4 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA4',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 5 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA5',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 6 A-value',
    parentValueRestrictions: null,
    settingName: 'axisA6',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD1',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD2',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD3',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 4 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD4',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 5 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD5',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 6 D-value',
    parentValueRestrictions: null,
    settingName: 'axisD6',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin1',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin2',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin3',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 4 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin4',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 5 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin5',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 6 Min Angle',
    parentValueRestrictions: null,
    settingName: 'axisMin6',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax1',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax2',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax3',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 4 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax4',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 5 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax5',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_AXIS_LIMITS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 6 Max Angle',
    parentValueRestrictions: null,
    settingName: 'axisMax6',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 1 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeA',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 2 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeB',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 3 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeC',
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 4 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeD',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 5 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeE',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_HOME,
    children: [],
    description: 'TODO',
    displayName: 'Robot Home Axis 6 Value [deg]',
    parentValueRestrictions: null,
    settingName: 'homeF',
    authorizedRoles: [UserRoles.ADMIN, UserRoles.SUPER_ADMIN],
    value: NaN,
    valueType: ValueTypes.DECIMAL,
  },
];
