import { createActionTypes } from '@utils/redux';
import * as legacyActionTypes from '@constants/actionTypes';
import syncActions from './syncActions';
import asyncActions from './asyncActions';

const generatedActions = createActionTypes(syncActions, asyncActions);

const actionTypes = {
  ...legacyActionTypes,
  ...generatedActions,
};

export default actionTypes;
