import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chip from '@components/1-atoms/Chip';
import DropDownMenu, {
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
} from '@components/2-molecules/DropDownMenu';

const SORTING_DIRECTION_ASC = 'asc';
const SORTING_DIRECTION_DESC = 'desc';

const SORTING_DIRECTION_SCHEMA = {
  [SORTING_DIRECTION_ASC]: {
    label: 'Ascending',
    leadingIconName: 'sort_ascending_0',
  },
  [SORTING_DIRECTION_DESC]: {
    label: 'Descending',
    leadingIconName: 'sort_descending_0',
  },
};

const SortingChip = ({
  sortOptions = [],
  selectedSortDir = SORTING_DIRECTION_ASC,
  selectedSortOption,
  onSortOptionSelect,
  onSortDirSelect,
  dropDownMenuPosition = DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
  ...props
}) => {
  const leadingIconName = useMemo(() => {
    const sortAsc = selectedSortDir === SORTING_DIRECTION_ASC;
    const sortDesc = selectedSortDir === SORTING_DIRECTION_DESC;

    if (sortAsc) {
      return SORTING_DIRECTION_SCHEMA[SORTING_DIRECTION_ASC]?.leadingIconName;
    }

    if (sortDesc) {
      return SORTING_DIRECTION_SCHEMA[SORTING_DIRECTION_DESC]?.leadingIconName;
    }

    return '';
  }, [selectedSortDir]);

  const dropDownMenuItems = useMemo(() => {
    const menuOptions = sortOptions?.map((sortOption) => ({
      label: sortOption?.label,
      selected: selectedSortOption?.value === sortOption?.value,
      onClick: () => onSortOptionSelect(sortOption),
    }));

    const sortDirOptions = Object.keys(SORTING_DIRECTION_SCHEMA)?.map(
      (sortDirKey, i) => {
        const sortDirOption = SORTING_DIRECTION_SCHEMA[sortDirKey];

        return {
          withDivider: i === 0,
          leadingIconName: sortDirOption?.leadingIconName,
          label: sortDirOption?.label,
          endingButton: {
            type: 'radio',
            value: sortDirKey,
            checked: selectedSortDir === sortDirKey,
            onChange: onSortDirSelect,
          },
        };
      },
    );

    return [...menuOptions, ...sortDirOptions];
  }, [
    sortOptions,
    selectedSortDir,
    selectedSortOption,
    onSortOptionSelect,
    onSortDirSelect,
  ]);

  return (
    <Chip
      label={selectedSortOption?.label}
      leadingIconName={leadingIconName}
      endingIconName="arrow_drop_down_1"
      dropDownMenuItems={dropDownMenuItems}
      dropDownMenuPosition={dropDownMenuPosition}
      {...props}
    />
  );
};

SortingChip.propTypes = {
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedSortDir: PropTypes.oneOf([
    SORTING_DIRECTION_ASC,
    SORTING_DIRECTION_DESC,
  ]),
  selectedSortOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  dropDownMenuPosition: DropDownMenu.propTypes.position,
  onSortOptionSelect: PropTypes.func,
  onSortDirSelect: PropTypes.func,
};

export default SortingChip;
