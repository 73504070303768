import styled, { css } from 'styled-components';
import { flexColumn, flexCenter } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  flex: 1 0 0;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing?.level4};
    padding-bottom: ${spacing?.level5};
  `};
`;

export const QRContainer = styled.div`
  ${flexCenter}

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing?.level5};
  `};
`;
