import * as THREE from 'three';
import SixAxisRobot from './SixAxisRobot';
import { printerConstants as constants } from '@constants/printers/printerConstants';

/**
 * Represents a six axis robot object with a 4-bar linkage coupling the 2nd and 3rd axes.
 * The overall object is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 *
 * Maintains a reference to the values of joint 2 and 3.
 */
class SixAxisRobotCoupled extends SixAxisRobot {
  constructor(robotType, machineDefinitions, printerSettings) {
    super(robotType, machineDefinitions, printerSettings);
    this.joint2Angle = this.homeB;
    this.joint3Angle = this.homeC;
  }

  /**
   * Creates THREE.Group objects to contain each individual rotating axis and adds
   * additional groups for rotation (separate groups for rotation and models allows
   * the models to have rotation offsets).
   *
   * Distal axes are added as children in a tree-structure to the proximal axes.
   *
   * Additional groups are created to allow simulation of the 4-bar linkage coupling
   * between the 2nd and 3rd axes.
   * @override
   */
  createJointGroups() {
    super.createJointGroups();
    this.linkB = new THREE.Group();
    this.linkC = new THREE.Group();
    this.linkB.add(this.linkC);
    const { couplingDefinition } = this.machineDefaults;
    this.linkC.position.set(
      couplingDefinition.x,
      couplingDefinition.y,
      couplingDefinition.z,
    );
    this[constants.part + 2].add(this.linkB);
  }

  /**
   * Searches the provided robot model file for the models associated with the
   * target axis number and adds them as children to the correct axis group.
   *
   * The default method is used for all axes except axis2, in which case a method
   * with a special implementation is invoked.
   * @param {gltf} robotModel complete robot model gltf file
   * @param {Integer} axisNumber target axis number
   * @override
   */
  addAxis(robotModel, axisNumber) {
    if (axisNumber == 2) {
      this.addAxis2(robotModel);
    } else {
      super.addAxis(robotModel, axisNumber);
    }
  }

  /**
   * Creates 3 THREE.Groups to represent different elements of the four-bar linkage
   * of axis 2. Searches the provided robot model file for the models associated with the
   * axis number 2 and adds them as children to their respective groups. The groups are
   * chosen by parsing the names of the models within the file.
   * Group A - standard axis 2 group
   * Group B - lower rotational joint of the 4-bar linkage
   * Group C - end of the rotational joint, where the long bar attaches
   * @param {gltf} robotModel complete robot model gltf file
   */
  addAxis2(robotModel) {
    const groupedMeshA = new THREE.Group();
    const groupedMeshB = new THREE.Group();
    const groupedMeshC = new THREE.Group();
    groupedMeshA.name = constants.coupledLinkA;
    groupedMeshB.name = constants.coupledLinkB;
    groupedMeshC.name = constants.coupledLinkC;
    const axisModels = this.getAxisModels(robotModel, 2);
    try {
      axisModels.forEach((axisModel) => {
        if (axisModel instanceof THREE.Mesh) {
          const mesh = this.getAxisMesh(axisModel.clone());
          const linkType = this.getCoupledLinkType(axisModel);
          if (linkType) {
            switch (linkType) {
              case 'A':
                groupedMeshA.add(mesh);
                break;
              case 'B':
                groupedMeshB.add(mesh);
                break;
              case 'C':
                groupedMeshC.add(mesh);
                break;
            }
          } else {
            groupedMeshA.add(mesh);
          }
        }
      });
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error(e);
    }
    this[constants.part + 2].add(groupedMeshA);
    this.linkB.add(groupedMeshB);
    this.linkC.add(groupedMeshC);
  }

  /**
   * Returns the type of link that the mesh represents, based on its name.
   * A - standard axis 2 group
   * B - lower rotational joint of the 4-bar linkage
   * C - end of the rotational joint, where the long bar attaches
   * @param {THREE.Mesh} axisModel
   * @returns string containing type of link, 'A', 'B' or 'C'
   */
  getCoupledLinkType(axisModel) {
    const name = axisModel.name;
    if (!name?.includes(constants.Link)) {
      return;
    }
    return name.split(constants.Link)[1].split(constants._)[0];
  }

  /**
   * Sets the angle of a specific joint of the 6DOF robot, and rotates
   * @param {*} axisNumber number (1-6) of joint to rotate
   * @param {*} angle angle in [deg] to rotate the joint
   * @override SixAxisRobot method, additional logic for joints 2 and 3 to handle coupled linkage
   */
  setAngleOfAxis(axisNumber, angle) {
    if (axisNumber != 2 && axisNumber != 3) {
      //Use default method for axes that are not 2 or 3
      return super.setAngleOfAxis(axisNumber, angle);
    }
    const pivot = this.rotationPoints[axisNumber - 1];
    if (!pivot) return;
    //If not defined, use standard KUKA rotations
    const motorDirection = this.machineDefaults.motorDirection
      ? this.machineDefaults.motorDirection
      : [-1, 1, 1, -1, 1, -1];
    angle *= motorDirection[axisNumber - 1];
    angle += this.machineDefaults.homePositionCorrections[axisNumber - 1];
    angle = (angle / 360.0) * (Math.PI * 2.0);
    // convert from deg to radians
    if (axisNumber === 2) {
      this.joint2Angle = angle;
      pivot.rotation.y = angle;
      this.linkB.rotation.y = this.joint3Angle - this.joint2Angle;
      this.linkC.rotation.y = this.joint2Angle - this.joint3Angle;
    } else if (axisNumber === 3) {
      this.joint3Angle = angle;
      pivot.rotation.y = angle - this.joint2Angle;
      this.linkB.rotation.y = this.joint3Angle - this.joint2Angle;
      this.linkC.rotation.y = this.joint2Angle - this.joint3Angle;
    } else {
      //eslint-disable-next-line no-console
      console.error(
        `setAngleOfAxis received an invalid axisNumber (${axisNumber})`,
      );
    }
  }
}

export default SixAxisRobotCoupled;
