import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import useSnackbar from '@hooks/useSnackbar';
import Snackbar from '@components/2-molecules/Snackbar';

export const SNACKBAR_CONTAINER_ID = 'snackbar-container';

const SnackbarPortal = () => {
  const {
    snackbarId,
    snackbarData,
    snackbarIsOpen,
    hideSnackbar,
    startSnackbarFadeOutTimer,
    clearSnackbarFadeOutTimer,
  } = useSnackbar();

  const snackbarPortalContainer = document.getElementById(
    SNACKBAR_CONTAINER_ID,
  );

  const onMouseEnter = useCallback(() => {
    clearSnackbarFadeOutTimer();
  }, [clearSnackbarFadeOutTimer]);

  const onMouseLeave = useCallback(() => {
    startSnackbarFadeOutTimer();
  }, [startSnackbarFadeOutTimer]);

  if (!snackbarPortalContainer || !snackbarIsOpen) return null;

  return createPortal(
    <Snackbar
      {...snackbarData}
      id={snackbarId}
      onEndingIconButtonClick={hideSnackbar}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />,
    snackbarPortalContainer,
  );
};

SnackbarPortal.propTypes = {};

export default SnackbarPortal;
