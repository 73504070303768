import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import { Wrapper, LeftColumn, RightColumn } from './SettingSwitch.styled.js';
import Switch from '@components/1-atoms/Switch';
import { SupportingText } from '@components/1-atoms/Field/Field.styled';

const SettingSwitch = ({
  switchProps = {},
  className,
  dataTestId = 'setting-switch',
  label,
  withIdentation,
  supportingText,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <Text
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </Text>
        {supportingText && <SupportingText>{supportingText}</SupportingText>}
      </LeftColumn>

      <RightColumn>
        <Switch dataTestId={`${dataTestId}__switch`} {...switchProps} />
      </RightColumn>
    </Wrapper>
  );
};

SettingSwitch.propTypes = {
  switchProps: PropTypes.shape(Switch.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  withIdentation: PropTypes.bool,
  supportingText: PropTypes.string,
};

export default SettingSwitch;
