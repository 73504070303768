import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useWorkflow from '@hooks/workflows/useWorkflow';
import usePrinter from '@hooks/printers/usePrinter';
import {
  getIsShowingPrintingBed,
  getIsShowingRobot,
  getIsShowingWorkspace,
  getIsShowingEnclosure,
  getSimulation,
} from '@selectors/conceptSelectors';
import {
  updateWorkspaceVisibility,
  updatePrintingBedVisibility,
  updateRobotVisibility,
  updateSimulationTravelLineVisibility,
  updateEnclosureVisibility,
} from '@actions/conceptActions';
import { printerConstants } from '@app/constants/printers/printerConstants';

export const PRINTER_ACTIONS = {
  ENCLOSURE: 'Enclosure',
  ROBOT: 'Robot',
  PRINTING_BED: 'Printing Bed',
  ROBOT_WORKSPACE: 'Robot Workspace',
  TRAVEL_LINES: 'Travel Lines',
};

export default function usePrinterTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    getSelectedWorkflow,
    getWorkflowPrinter,
    getIsPrinterUpdateAvailable,
  } = useWorkflow();
  const { getMachineDefinitions } = usePrinter();

  const simulation = useSelector(getSimulation());
  const isShowingWorkspace = useSelector(getIsShowingWorkspace());
  const isShowingPrintingBed = useSelector(getIsShowingPrintingBed());
  const isShowingRobot = useSelector(getIsShowingRobot());
  const isShowingEnclosure = useSelector(getIsShowingEnclosure());
  const disableTravelLinesAction = !simulation?.isActive;
  const isShowingTravelLines =
    !disableTravelLinesAction && simulation?.showTravelLine;

  const selectedWorkflow = getSelectedWorkflow();
  const isPrinterUpdateAvailable =
    getIsPrinterUpdateAvailable(selectedWorkflow);
  const machineDefinitions = getMachineDefinitions();

  const disableAllActions = isPrinterUpdateAvailable;

  const isValidEnclosure = (enclosureType) => {
    return enclosureType && enclosureType != printerConstants.NoEnclosure;
  };

  const printer =
    getWorkflowPrinter(selectedWorkflow) || selectedWorkflow?.sharedPrinterInfo;
  const disableEnclosureAction = machineDefinitions
    ? !isValidEnclosure(printer?.enclosureType)
    : false;

  const handleRobotWorkspace = useCallback(() => {
    dispatch(updateWorkspaceVisibility());
  }, [dispatch]);

  const handlePrintingBed = useCallback(() => {
    dispatch(updatePrintingBedVisibility());
  }, [dispatch]);

  const handleRobot = useCallback(() => {
    dispatch(updateRobotVisibility());
  }, [dispatch]);

  const handleTravelLines = useCallback(() => {
    dispatch(updateSimulationTravelLineVisibility());
  }, [dispatch]);

  const handleEnclosure = useCallback(() => {
    dispatch(updateEnclosureVisibility());
  }, [dispatch]);

  const actions = useMemo(
    () => [
      {
        id: PRINTER_ACTIONS.ENCLOSURE,
        disabled: disableEnclosureAction || disableAllActions,
        active: isShowingEnclosure,
        name: intl.formatMessage({
          id: 'toolbar.printer.enclosure',
          defaultMessage: PRINTER_ACTIONS.ENCLOSURE,
        }),
        iconName: 'enclosure_0',
        handler: handleEnclosure,
      },
      {
        id: PRINTER_ACTIONS.ROBOT,
        disabled: disableAllActions,
        active: isShowingRobot,
        name: intl.formatMessage({
          id: 'toolbar.printer.robot',
          defaultMessage: PRINTER_ACTIONS.ROBOT,
        }),
        iconName: 'precision_manufacturing_0',
        handler: handleRobot,
      },
      {
        id: PRINTER_ACTIONS.PRINTING_BED,
        disabled: disableAllActions,
        active: isShowingPrintingBed,
        name: intl.formatMessage({
          id: 'toolbar.printer.printing_bed',
          defaultMessage: PRINTER_ACTIONS.PRINTING_BED,
        }),
        iconName: 'hot_bed_0',
        handler: handlePrintingBed,
      },
      {
        id: PRINTER_ACTIONS.ROBOT_WORKSPACE,
        disabled: disableAllActions,
        active: isShowingWorkspace,
        name: intl.formatMessage({
          id: 'toolbar.printer.robot_workspace',
          defaultMessage: PRINTER_ACTIONS.ROBOT_WORKSPACE,
        }),
        iconName: 'area_of_work_0',
        handler: handleRobotWorkspace,
      },
      {
        id: PRINTER_ACTIONS.TRAVEL_LINES,
        disabled: disableTravelLinesAction || disableAllActions,
        active: isShowingTravelLines,
        name: intl.formatMessage({
          id: 'toolbar.printer.travel_lines',
          defaultMessage: PRINTER_ACTIONS.TRAVEL_LINES,
        }),
        iconName: 'travel_0',
        handler: handleTravelLines,
      },
    ],
    [
      intl,
      disableTravelLinesAction,
      disableEnclosureAction,
      disableAllActions,
      isShowingWorkspace,
      isShowingPrintingBed,
      isShowingRobot,
      isShowingTravelLines,
      isShowingEnclosure,
      handleRobotWorkspace,
      handlePrintingBed,
      handleRobot,
      handleTravelLines,
      handleEnclosure,
    ],
  );

  return actions;
}
