import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '@actions/modalActions';
import Dialog from './Dialog';
import { useIntl } from 'react-intl';
import { getIsShowingUpgradeConfirmation } from '../../selectors/conceptSelectors';
import { getModalData } from '@selectors/modalSelectors';
import { ModalDataTypes } from '@constants/modalDataTypes';

export const Dialogs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isShowingUpgradeConfirmation = useSelector(
    getIsShowingUpgradeConfirmation(),
  );
  const modalData = useSelector(getModalData);

  const showDeleteCustomTemplateWarning =
    modalData?.type === ModalDataTypes.DELETE_CUSTOM_TEMPLATE_WARNING;

  const showDeleteTemplateWarning =
    modalData?.type === ModalDataTypes.DELETE_TEMPLATE_WARNING;

  if (showDeleteCustomTemplateWarning) {
    return (
      <Dialog
        title={intl.formatMessage({
          id: 'quick_start.operator_selector.delete_custom_operator.title',
          defaultMessage: 'Delete Custom Operator',
        })}
        subtitle={intl.formatMessage({
          id: 'quick_start.operator_selector.delete_custom_operator.description',
          defaultMessage: 'This will permanently delete the custom operator',
        })}
        onConfirm={modalData?.onConfirm}
        onCancel={() => dispatch(closeModal())}
        confirmButtonText={intl.formatMessage({
          id: 'dialog.message.ok',
          defaultMessage: 'OK',
        })}
      />
    );
  }

  if (showDeleteTemplateWarning) {
    return (
      <Dialog
        title={intl.formatMessage({
          id: 'errordialogwrapper.title.error',
          defaultMessage: 'Error',
        })}
        subtitle={modalData?.message || ''}
        onConfirm={() => dispatch(closeModal())}
        confirmButtonText={intl.formatMessage({
          id: 'dialog.message.ok',
          defaultMessage: 'OK',
        })}
      />
    );
  }

  if (isShowingUpgradeConfirmation) {
    const operatorsDisplayNamesToUpgrade = modalData?.operatorsToUpgrade?.map(
      (operator) => {
        const isOperatorTagModified = operator.tag !== operator.name;
        const isTemplateInstance = !!operator.templateInstanceId;
        const translatedName =
          operator.translatedName ||
          intl.formatMessage({
            id: operator.nameKey,
            defaultMessage: operator.tag,
          });
        const operatorDisplayName =
          isTemplateInstance || isOperatorTagModified
            ? operator.tag
            : translatedName;

        return operatorDisplayName;
      },
    );
    const operatorNames = '\n- ' + operatorsDisplayNamesToUpgrade.join('\n- ');
    let subtitle;
    if (modalData?.operatorsToUpgrade?.length === 0) {
      subtitle = intl.formatMessage(
        {
          id: 'dialogs.subtitle.upgrade.all.operators',
          defaultMessage:
            'There is a new Aibuild update available.\n\nDo you want to update now?',
        },
        { operators: operatorNames },
      );
    } else {
      subtitle = intl.formatMessage(
        {
          id: 'dialogs.subtitle.upgrade',
          defaultMessage:
            'There is a new Aibuild update available.\nThe new version includes changes to the following operators: {}\n\nDo you want to update now?',
        },
        { operators: operatorNames },
      );
    }

    return (
      <Dialog
        title={intl.formatMessage({
          id: 'dialogs.title.upgrade',
          defaultMessage: 'Update available',
        })}
        subtitle={subtitle}
        confirmButtonText={intl.formatMessage({
          id: 'dialogs.actions.upgrade.submit',
          defaultMessage: 'Update',
        })}
        cancelButtonText={intl.formatMessage({
          id: 'dialogs.actions.upgrade.cancel',
          defaultMessage: "I'll do it later",
        })}
        onConfirm={modalData?.onConfirm}
        onCancel={modalData?.onCancel}
      />
    );
  }

  return null;
};
