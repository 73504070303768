import { MACHINE_DEMO } from '../../../constants/machineConstants';
import Machine from '../Machine';
import { Printer } from '../../Printer';

/**
 * Represents a machine which is just used to demo some static models for
 * clients.
 */
class DemoMachine extends Machine {
  constructor(robotType, machineDefinitions, printerSettings) {
    super(robotType, machineDefinitions, printerSettings);
    this.name = MACHINE_DEMO;
  }

  /**
   * Moves the machine to the home position, not implemented for demo machines.
   */
  moveHomePosition() {
    //do nothing
  }

  /**
   * Simulates the machine motion, not implemented for demo machines.
   */
  simulate() {
    //do nothing
  }

  /**
   * Loads all the demo models and adds them to the models group. This method
   * returns a promise which resolves once the models have loaded
   * @returns promise which resolves once the models have loaded
   */
  initializeMachineGeometry() {
    return new Promise((resolve) => {
      const url = `/models/Robot_${this.fileKey}.glb`;
      Printer.getModel(url).then((gltf) => {
        gltf.scene.children.forEach((child) => {
          this.add(child.clone());
        });
        resolve();
      });
    });
  }
}

export default DemoMachine;
