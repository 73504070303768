import React from 'react';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { useIntl } from 'react-intl';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const Axis6Settings = () => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();

  const settings = ['a1', 'a2', 'a3', 'd1', 'd4', 'd6'].map((field) => ({
    field,
  }));

  return settings.map((entry) => {
    return (
      <FormikField
        key={entry.field}
        component={SettingTextField}
        label={intl.formatMessage({
          id: `printers.settings.dimensions.distance.${entry.field}.label`,
        })}
        field1={{
          type: 'number',
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            `machineTypeSetting.machineProperties.${entry.field}`,
          ),
        }}
        dataTestId={`printer-components-machine-type__setting_axis6__distance-${entry.field}`}
        name={`machineTypeSetting.machineProperties.${entry.field}`}
      />
    );
  });
};
