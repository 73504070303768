import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  defaultLinearProperties,
  defaultStaticProperties,
  plinthTypeDefinitions,
} from '@constants/printers/plinthDefaultProperties';
import { LinearSetting } from '@components/Printers/GeneralPrinterSetting/PlinthSetting/LinearSetting';
import { StaticSetting } from '@components/Printers/GeneralPrinterSetting/PlinthSetting/StaticSetting';
import {
  plinthTypeLinearFiles,
  plinthTypeStaticFiles,
} from '@constants/printers/componentFiles';
import * as Yup from 'yup';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import { Text } from '../Text';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { getFormattedOption } from '@components/Printers/SettingsUtils';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { generateSingleFileUpload } from '@utils/filesUpload';

export const PlinthTypeSetting = () => {
  const intl = useIntl();

  const { values, initialValues, setFieldValue } = useFormikContext();
  const plinthTypeValues = values['plinthTypeSetting'];

  const setValue = useMemo(
    () => (field, value) => {
      setFieldValue(`plinthTypeSetting.${field}`, value);
    },
    [setFieldValue],
  );

  const changePlinthType = useCallback(
    (option) => {
      let filesData = [];
      let properties = {};
      switch (option.value) {
        case plinthTypeDefinitions.RAIL_PLINTH.value: {
          filesData = plinthTypeLinearFiles;
          properties = defaultLinearProperties;
          break;
        }
        case plinthTypeDefinitions.STATIC_PLINTH.value: {
          filesData = plinthTypeStaticFiles;
          properties = defaultStaticProperties;
          break;
        }
      }

      const filesObject = Object.fromEntries(
        filesData.map(({ value, label }) => [
          value,
          {
            label,
            file: null,
            fileName: null,
          },
        ]),
      );

      setValue('files', filesObject);
      setValue('plinthProperties', properties);
      setValue('plinthType', option);
    },
    [setValue],
  );

  const getFormattedOptions = useMemo(
    () => (definitions, changePlinthType) =>
      Object.values(definitions).map((option) =>
        getFormattedOption(option, changePlinthType),
      ),
    [],
  );

  const options = getFormattedOptions(plinthTypeDefinitions, changePlinthType);

  const renderUploadRows = () => {
    if (plinthTypeValues.files) {
      return Object.keys(plinthTypeValues.files).map((axisKey) => {
        const entry = plinthTypeValues.files[axisKey];
        return generateSingleFileUpload(
          entry,
          'printer-components-plinth-type__category-upload',
          axisKey,
          (file) => {
            setValue(`files.${axisKey}.file`, file);
            setValue(`files.${axisKey}.fileName`, file.name + new Date());
          },
          {
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `plinthTypeSetting.files.${axisKey}.fileName`,
            ),
          },
        );
      });
    }
  };

  const renderPlinthRows = (plinthTypeValue) => {
    switch (plinthTypeValue) {
      case plinthTypeDefinitions.RAIL_PLINTH.value:
        return <LinearSetting />;
      case plinthTypeDefinitions.STATIC_PLINTH.value:
        return <StaticSetting />;
    }
  };

  return (
    <>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.settings.information.label',
        })}
        dataTestId={`printer-components-plinth-type__category-information`}
        expand
        withDividerBottom={!!plinthTypeValues.plinthType}
      >
        <FormikField
          component={SettingDropDown}
          label={intl.formatMessage({
            id: 'printers.settings.type.label',
          })}
          dataTestId={`printer-components-plinth-type__category-information__setting-plinth-type`}
          name={'plinthTypeSetting.plinthType'}
          dropDownField={{
            dropDownMenuItems: options,
            fullWidthDropDownMenu: false,
            placeholder: intl.formatMessage({
              id: 'printers.settings.plinth.type.placeholder',
              defaultMessage: 'Plinth Type',
            }),
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              'plinthTypeSetting.plinthType',
            ),
          }}
        />
        {plinthTypeValues.plinthType.value ===
          plinthTypeDefinitions.RAIL_PLINTH.value && (
          <Text
            descriptionKey={'printers.settings.externalaxis.disclaimer.label'}
            fontSize={12}
          />
        )}
      </SettingsCategory>
      {plinthTypeValues.plinthType && (
        <>
          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.upload.title',
            })}
            dataTestId={`printer-components-plinth-type__category-upload`}
            expand
            withDividerBottom
          >
            <DiagramRowComponent
              src={`/img/digitalTwin/plinth/diagram_${plinthTypeValues.plinthType.value}.svg`}
              description_key={`printers.settings.plinth.diagram.${plinthTypeValues.plinthType.value}.description.label`}
              second_description_key={'printers.settings.upload.export.label'}
            />
            {renderUploadRows()}
          </SettingsCategory>

          <SettingsCategory
            title={intl.formatMessage({
              id: 'printers.settings.plinth.parameters.label',
            })}
            dataTestId={`printer-components-plinth-type__category-parameters`}
            expand
          >
            {renderPlinthRows(plinthTypeValues.plinthType.value)}
          </SettingsCategory>
        </>
      )}
    </>
  );
};

export const PlinthTypeSettingValidationSchema = Yup.object().shape({
  plinthType: Yup.object().shape({
    value: Yup.string().required('Plinth Type is required'),
  }),
  files: Yup.object().test(
    'areFilesValid',
    'All files must be provided',
    (files) => {
      return Object.values(files).every((axis) => axis.file !== null);
    },
  ),
  plinthProperties: Yup.object().when(['plinthType'], {
    is: (plinthType) => plinthType?.value,
    then: Yup.object().test(
      'arePropertiesValid',
      'All properties must be provided',
      (plinthProperties, { parent }) => {
        if (
          parent.plinthType.value === plinthTypeDefinitions.STATIC_PLINTH.value
        ) {
          return plinthProperties['height'] && plinthProperties['height'] > 0;
        }

        if (
          parent.plinthType.value === plinthTypeDefinitions.RAIL_PLINTH.value
        ) {
          return (
            plinthProperties['height'] !== '' &&
            plinthProperties['originValue'] !== '' &&
            plinthProperties['maxSpeed'] &&
            plinthProperties['maxSpeed'] > 0 &&
            plinthProperties['endValue'] !== ''
          );
        }
        return false;
      },
    ),
  }),
});

export const initDataPlinthType = (plinthDefinitionResponse, initialValues) => {
  const propertiesToExclude = ['modelUrls', 'name', 'id', 'plinthType'];

  let selectedFiles = {};
  switch (plinthDefinitionResponse.plinthType) {
    case plinthTypeDefinitions.STATIC_PLINTH.value:
      selectedFiles = plinthTypeStaticFiles;
      break;
    case plinthTypeDefinitions.RAIL_PLINTH.value:
      selectedFiles = plinthTypeLinearFiles;
      break;
  }

  return {
    ...initialValues,
    plinthType: plinthTypeDefinitions[plinthDefinitionResponse.plinthType],
    plinthProperties: Object.keys(plinthDefinitionResponse)
      .filter((key) => !propertiesToExclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = plinthDefinitionResponse[key];
        return obj;
      }, {}),
    files: Object.fromEntries(
      selectedFiles.map(({ value, label }) => [
        value,
        {
          label,
          file: new File(
            [],
            plinthDefinitionResponse.modelUrls[value].fileName,
          ),
          fileName: plinthDefinitionResponse.modelUrls[value].fileName,
        },
      ]),
    ),
  };
};
