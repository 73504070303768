import styled, { css, keyframes } from 'styled-components';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

const moveGradient = keyframes`
  25%, 100% {
    left: 100%;
  }
}`;

const shape = css`
  position: relative;
  overflow: clip;

  &:before {
    content: '';
    width: 120px;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(52, 53, 56, 0) 0%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(52, 53, 56, 0) 100%
    );
    position: absolute;
    top: 0;
    left: -120px;
    bottom: 0;
    transform: translateX(0);
    animation: ${moveGradient} 4s ease-in-out infinite;
    animation-delay: 2s;

    ${({ theme: { isLightTheme } }) => css`
      ${when(
        isLightTheme,
        `
          background: linear-gradient(
            270deg,
            rgba(227, 226, 230, 0.00) 0%,
            rgba(0, 0, 0, 0.05) 36%,
            rgba(0, 0, 0, 0.05) 51.08%,
            rgba(0, 0, 0, 0.05) 65.5%,
            rgba(227, 226, 230, 0.00) 100%
          );
        `,
      )}
    `}
  }

  ${({ borderRadius, theme }) => css`
    border-radius: ${theme.borderRadius?.[borderRadius] ||
    theme.borderRadius.extrasmall};
    background-color: ${theme.colors.surfaceContainerHighest};
  `}
`;

export const Wrapper = styled.div`
  display: grid;
  flex-shrink: 0;

  ${({
    height,
    fullHeight,
    oneLineVariant,
    withIcon,
    withLine,
    theme: { spacing },
  }) => css`
    grid-template-columns: 56px 1fr;
    gap: ${spacing.level3};

    ${when(
      oneLineVariant,
      `
        grid-template-columns: ${spacing.level6} 1fr;
      `,
    )}

    ${when(
      !withIcon || !withLine,
      `
        grid-template-columns: 1fr;
      `,
    )}

    ${when(
      height,
      `
        height: ${height}px;
      `,
    )}

    ${when(
      fullHeight,
      `
        height: 100%;
      `,
    )}
  `}
`;

export const LeftColumn = styled.div`
  ${shape}

  &:before {
    animation-delay: 3s;
  }
`;

export const RightColumn = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}
`;

export const Line = styled.div`
  ${flexCenterVertical}
  ${shape}
  flex: 1;

  &:nth-child(2) {
    width: 80%;

    &:before {
      animation-delay: 2.2s;
    }
  }
`;
