import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const organizationQueryKeys = {
  users: ['users'],
};

export default function useOrganizationQueries() {
  const usersQuery = useQuery({
    queryKey: organizationQueryKeys.users,
    queryFn: () => client.get(endpoints.users).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  return {
    usersQuery,
  };
}
