import styled, { css } from 'styled-components';
import SVG from '@app/components/1-atoms/SVG';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Header = styled.div`
  ${flexCenterVertical}
  padding: ${({ theme: { spacing } }) =>
    `${spacing?.level6} ${spacing?.level4}`};
`;

export const Logo = styled(SVG).attrs(({ theme: { isLightTheme } }) => ({
  name: isLightTheme ? 'logo-white' : 'logo',
}))`
  width: 48px;
  height: 48px;
`;

export const Form = styled.form`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  ${({ theme: { spacing } }) => css`
    padding: 0 ${spacing?.level5};
  `};
`;

export const Footer = styled.div`
  margin-top: auto;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing?.level6} ${spacing?.level5};
  `};
`;
