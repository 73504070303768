import React from 'react';
import PropTypes from 'prop-types';
import { dropDownMenuHOCProps } from '@hoc/withDropDownMenu';
import {
  Wrapper,
  Title,
  LeadingIconButton,
  ButtonDropDown,
  EndingIconButton,
} from './NavigationHeader.styled';

export const NAVIGATION_HEADER_VARIANT_DEFAULT = 'default';
export const NAVIGATION_HEADER_VARIANT_LARGE_TITLE = 'largeTitle';

const NavigationHeader = ({
  buttonDropdown,
  className,
  dataTestId = 'navigation-header',
  disabled,
  endingIconButtonDisabled,
  endingIconButtonDropDownProps = {},
  endingIconButtonIconName = 'close_0',
  endingIconButtonRef,
  endingIconButtonVariant,
  leadingIconButtonDisabled,
  leadingIconButtonIconName,
  leadingIconButtonRef,
  onEndingIconButtonClick,
  onLeadingIconButtonClick,
  title,
  variant = NAVIGATION_HEADER_VARIANT_DEFAULT,
}) => {
  const defaultVariant = variant === NAVIGATION_HEADER_VARIANT_DEFAULT;
  const largeTitleVariant = variant === NAVIGATION_HEADER_VARIANT_LARGE_TITLE;

  return (
    <Wrapper className={className} largeTitleVariant={largeTitleVariant}>
      {defaultVariant && leadingIconButtonIconName && (
        <LeadingIconButton
          ref={leadingIconButtonRef}
          dataTestId={`${dataTestId}__leading-icon-button`}
          disabled={leadingIconButtonDisabled || disabled}
          iconName={leadingIconButtonIconName}
          onClick={onLeadingIconButtonClick}
        />
      )}

      {title && !buttonDropdown && (
        <Title
          dataTestId={`${dataTestId}__title`}
          defaultVariant={defaultVariant}
          largeTitleVariant={largeTitleVariant}
        >
          {title}
        </Title>
      )}

      {buttonDropdown && (
        <ButtonDropDown
          dataTestId={`${dataTestId}__drop-down-button`}
          {...buttonDropdown}
          disabled={buttonDropdown?.disabled || disabled}
        />
      )}

      {endingIconButtonIconName && (
        <EndingIconButton
          ref={endingIconButtonRef}
          dataTestId={`${dataTestId}__ending-icon-button`}
          disabled={endingIconButtonDisabled || disabled}
          variant={endingIconButtonVariant}
          iconName={endingIconButtonIconName}
          onClick={onEndingIconButtonClick}
          {...(endingIconButtonDropDownProps || {})}
        />
      )}
    </Wrapper>
  );
};

NavigationHeader.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  buttonDropdown: PropTypes.object,
  endingIconButtonDisabled: PropTypes.bool,
  endingIconButtonIconName: PropTypes.string,
  endingIconButtonRef: PropTypes.object,
  endingIconButtonVariant: PropTypes.string,
  leadingIconButtonDisabled: PropTypes.bool,
  leadingIconButtonIconName: PropTypes.string,
  leadingIconButtonRef: PropTypes.object,
  onEndingIconButtonClick: PropTypes.func,
  onLeadingIconButtonClick: PropTypes.func,
  endingIconButtonDropDownProps: PropTypes.shape(dropDownMenuHOCProps),
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    NAVIGATION_HEADER_VARIANT_DEFAULT,
    NAVIGATION_HEADER_VARIANT_LARGE_TITLE,
  ]),
};

export default NavigationHeader;
