import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import React from 'react';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';

const PostProcessorKUKA = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();
  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.postprocessor.isToolExternalAxis.label',
        defaultMessage: 'Use Tool As External Axis',
      })}
      dataTestId={`printer-custom-post-processor__setting-isToolExternalAxis`}
      endingCheckboxChecked={Boolean(
        values[PostProcessorSettingsName.isToolExternalAxis],
      )}
      endingCheckboxDirty={checkIfFieldIsDirty(
        values,
        initialValues,
        PostProcessorSettingsName.isToolExternalAxis,
      )}
      onEndingCheckboxClick={() => {
        setFieldValue(
          PostProcessorSettingsName.isToolExternalAxis,
          !values[PostProcessorSettingsName.isToolExternalAxis],
        );
      }}
      expand={Boolean(values[PostProcessorSettingsName.isToolExternalAxis])}
    >
      <FormikField
        component={SettingTextField}
        label={intl.formatMessage({
          id: 'printers.postprocessor.toolExternalAxisId.label',
          defaultMessage: 'Tool External Axis ID',
        })}
        dataTestId={`printer-custom-post-processor__setting-toolExternalAxisId`}
        name={PostProcessorSettingsName.toolExternalAxisId}
        field1={{
          placeholder: intl.formatMessage({
            id: 'printers.postprocessor.toolExternalAxisId.placeholder',
            defaultMessage: 'Enter ID',
          }),
          type: 'number',
          disabled: isSubmitting,
          dirty: checkIfFieldIsDirty(
            values,
            initialValues,
            PostProcessorSettingsName.toolExternalAxisId,
          ),
        }}
      />
    </SettingsCategory>
  );
};

PostProcessorKUKA.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorKUKA;
