import React, { useCallback, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import DropDownMenuContext from '@contexts/DropDownMenuContext';
import useDropDownMenu from '@hooks/useDropDownMenu';
import DropDownMenu from '@components/2-molecules/DropDownMenu';

export const DROP_DOWN_MENU_CONTAINER_ID = 'drop-down-menu-container';

const DropDownMenuPortal = ({ menuId, onClose, ...dropDownMenuProps }) => {
  const { resetDropDown } = useContext(DropDownMenuContext);
  const {
    dropDownMenuId,
    dropDownMenuIsOpen,
    dropDownMenuStyle,
    hideDropDownMenu,
    calculateDropDownMenuPosition,
    handleDropDownMenuResize,
    handleDropDownKeyDown,
  } = useDropDownMenu();
  const isCurrentDropDownMenu = dropDownMenuId === menuId;

  const dropDownMenuPortalContainer = document.getElementById(
    DROP_DOWN_MENU_CONTAINER_ID,
  );

  const handleCloseDropDownMenu = useCallback(() => {
    hideDropDownMenu();
    onClose?.();
  }, [hideDropDownMenu, onClose]);

  useEffect(() => {
    if (dropDownMenuIsOpen && isCurrentDropDownMenu) {
      window.addEventListener('keydown', handleDropDownKeyDown);
      window.addEventListener('resize', handleDropDownMenuResize);
      window.addEventListener('scroll', handleDropDownMenuResize);

      return () => {
        window.removeEventListener('keydown', handleDropDownKeyDown);
        window.removeEventListener('resize', handleDropDownMenuResize);
        window.removeEventListener('scroll', handleDropDownMenuResize);
      };
    }
  }, [
    calculateDropDownMenuPosition,
    dropDownMenuIsOpen,
    handleDropDownKeyDown,
    handleDropDownMenuResize,
    hideDropDownMenu,
    isCurrentDropDownMenu,
  ]);

  useEffect(() => {
    if (dropDownMenuIsOpen && isCurrentDropDownMenu) {
      calculateDropDownMenuPosition();
    }
  }, [
    dropDownMenuProps.menuItems,
    calculateDropDownMenuPosition,
    dropDownMenuIsOpen,
    isCurrentDropDownMenu,
  ]);

  useEffect(() => {
    return () => {
      if (dropDownMenuIsOpen) {
        resetDropDown();
      }
    };
  }, [dropDownMenuIsOpen, resetDropDown]);

  if (!dropDownMenuPortalContainer || !isCurrentDropDownMenu) return null;

  return createPortal(
    <DropDownMenu
      {...dropDownMenuProps}
      show={dropDownMenuIsOpen}
      style={dropDownMenuStyle}
      closeDropDownMenu={handleCloseDropDownMenu}
    />,
    dropDownMenuPortalContainer,
  );
};

DropDownMenuPortal.propTypes = {
  menuId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default DropDownMenuPortal;
