import * as THREE from 'three';
import { Graph } from 'graphlib';
import GantryComponent from './GantryComponent';

/**
 * 
 * example format:
      direction: {
        x: 1,
        y: 0,
        z: 0,
      },
      parents: [
        'WORLD',
      ]
 * @param {*} responseKey 
 */
/**
 * Converts the response object containing the machine data into an adjacency list
 * of component key -> Component object.
 * @param {Object} machineDefinitionResponse
 */
export function convertResponseToComponents(machineDefinitionResponse) {
  const map = new Map();

  const { AXIS_1, AXIS_2, AXIS_3, BED, EXTRUDER } = machineDefinitionResponse;

  const createGantryComponent = (name, type, parents, direction) => {
    return new GantryComponent(
      name,
      type,
      parents,
      new THREE.Vector3(direction.x, direction.y, direction.z),
    );
  };

  map.set(
    'AXIS_1',
    createGantryComponent(
      'AXIS_1',
      'AXIS',
      AXIS_1.componentParents,
      AXIS_1.direction,
    ),
  );
  map.set(
    'AXIS_2',
    createGantryComponent(
      'AXIS_2',
      'AXIS',
      AXIS_2.componentParents,
      AXIS_2.direction,
    ),
  );
  map.set(
    'AXIS_3',
    createGantryComponent(
      'AXIS_3',
      'AXIS',
      AXIS_3.componentParents,
      AXIS_3.direction,
    ),
  );
  map.set(
    'EXTRUDER',
    createGantryComponent('EXTRUDER', 'STATIC', EXTRUDER, { x: 0, y: 0, z: 0 }),
  );
  map.set(
    'BED',
    createGantryComponent('BED', 'STATIC', BED, { x: 0, y: 0, z: 0 }),
  );

  return map;
}

/**
 * Converts the adjacency list of components into a graph data structure.
 * @param {*} adjacencyList adjacency list of components in the form of
 * AXIS_1 : Component,
 * AXIS_2 : Component, etc.
 * @returns
 */
export function createGraph(adjacencyList) {
  const g = new Graph();
  g.setNode('WORLD');
  adjacencyList.forEach((component) => {
    g.setNode(component.name);
    const { parents } = component;
    parents.forEach((parent) => {
      g.setEdge(parent, component.name);
    });
  });
  return g;
}

export function getTestGantryTMS() {
  return {
    AXIS_1: {
      direction: {
        x: 0,
        y: 1,
        z: 0,
      },
      parents: ['WORLD'],
    },
    AXIS_2: {
      direction: {
        x: 1,
        y: 0,
        z: 0,
      },
      parents: ['AXIS_1'],
    },
    AXIS_3: {
      direction: {
        x: 0,
        y: 0,
        z: 1,
      },
      parents: ['AXIS_2'],
    },
    EXTRUDER: {
      parents: ['AXIS_3'],
    },
    BED: {
      parents: ['WORLD'],
    },
  };
}

export function getTestGantryUltimaker() {
  return {
    AXIS_1: {
      direction: {
        x: 0,
        y: 1,
        z: 0,
      },
      parents: ['WORLD'],
    },
    AXIS_2: {
      direction: {
        x: 1,
        y: 0,
        z: 0,
      },
      parents: ['WORLD'],
    },
    AXIS_3: {
      direction: {
        x: 0,
        y: 0,
        z: 1,
      },
      parents: ['WORLD'],
    },
    EXTRUDER: {
      parents: ['AXIS_1', 'AXIS_2'],
    },
    BED: {
      parents: ['AXIS_3'],
    },
  };
}
