import * as THREE from 'three';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import PrinterComponent from '../PrinterComponent';
import { Printer } from '../Printer';

class Plinth extends PrinterComponent {
  constructor(printerSettings, machineDefaults, plinthType, plinthDefinitions) {
    super(printerSettings, machineDefaults, constants.plinth);

    this.plinthType = plinthType;
    this.plinthSettings = plinthDefinitions.find(
      (item) => item.displayName == this.plinthType,
    );
    this.height = this.getPlinthHeight();
    this.plinthSettingsType = this.getPlinthSettingsType();

    this.position.set(0, 0, -this.height);
  }

  getPlinthSettingsType() {
    return this.plinthSettings[constants.plinthSettingsType];
  }

  addAttachment() {
    //need to implement, remove the position offset.
  }

  simulate() {
    //by default, static plinth does not move
    return;
  }

  /**
   * Asynchronously loads the models needed to visualise the target plinth type and adds them
   * as children of the model property. Returns a promise which resolves on completion
   * of loading.
   * @returns promise which resolves once plinth models have finished loading
   */
  initializeModels() {
    return new Promise((resolve) => {
      if (this.plinthType === '' || !this.plinthType)
        resolve(new THREE.Group());
      else {
        if (this.plinthSettings.empty || this.plinthSettings.fileKey == '')
          return resolve();
        const url = `/models/Plinth_${this.plinthSettings.fileKey}.glb`;
        const model = Printer.getModel(url);
        model.then((model) => {
          model.children.forEach((child) => {
            this.add(child.clone());
          });
          resolve();
        });
      }
    });
  }

  getPlinthHeight() {
    let offsetZ = 0;
    if (this.machineDefaults?.offset?.z) {
      offsetZ = this.machineDefaults['offset']['z'];
    }
    if (this.plinthSettings) {
      return offsetZ + this.plinthSettings.height;
    }
    return offsetZ;
  }
}

export default Plinth;
