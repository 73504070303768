import Clipping from './Clipping';
import Canvas from './Canvas';
import Grid from './Grid';
import Light from './Light';
import Objects from './Objects';
import Camera from './Camera';
import Printer from './Printer';
import Scene from './Scene';

export default {
  Clipping,
  Canvas,
  Grid,
  Light,
  Objects,
  Camera,
  Printer,
  Scene,
};
