import { printerConstants } from '@app/constants/printers/printerConstants';
import { materialConstants } from '@app/constants/materials';

export const extrusionTypeToMaterialTypes = {
  [printerConstants.METAL_WIRE]: [
    materialConstants.MATERIAL_METAL_WIRE,
    materialConstants.MATERIAL_METAL_POWDER,
  ],
  [printerConstants.THERMOPLASTIC_FILAMENT]: [
    materialConstants.MATERIAL_FILAMENT,
  ],
  [printerConstants.THERMOPLASTIC_PELLET]: [materialConstants.MATERIAL_PELLET],
  [printerConstants.THERMOSET]: [materialConstants.MATERIAL_THERMOSET_RESIN],
};

export const materialSortFunction = (lo, hi) => {
  lo = lo.materialName;
  hi = hi.materialName;

  const loInList = materialConstants.PRIORITY_MATERIALS.includes(lo);
  const hiInList = materialConstants.PRIORITY_MATERIALS.includes(hi);

  // If both strings are in the precedence list, compare them normally
  if (loInList && hiInList) {
    return lo.localeCompare(hi);
  }

  if (loInList) {
    return -1;
  }

  if (hiInList) {
    return 1;
  }

  return lo.localeCompare(hi);
};
