import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import useDesign from '@hooks/useDesign';
import useOperator from '@hooks/operators/useOperator';
import {
  getLineData,
  getLegendMaxRange,
  getLegendMinRange,
  getSelectedOperatorOutput,
} from '@selectors/conceptSelectors';
import { updateLinetypeVisibility } from '@actions/conceptActions';
import { LineData } from '@constants/lineData';
import { FileTypes } from '@constants/fileTypes';
import { DEFAULT, SHOW_LINES_FOR_LINE_DATA } from '@constants/utilityConstants';
import { AnalysisOperatorLegends } from '@constants/AnalysisOperatorLegends';

export default function useLineDataTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const lineData = useSelector(getLineData());
  const selectedOperatorOutput = useSelector(getSelectedOperatorOutput());
  const legendMinRange = useSelector(getLegendMinRange());
  const legendMaxRange = useSelector(getLegendMaxRange());
  const { design } = useDesign();
  const { getSelectedOperator } = useOperator();
  const selectedOperator = getSelectedOperator();

  const showLineTypeForAibGcode = useMemo(
    () =>
      lineData === LineData.LINETYPE &&
      (design?.filetype === FileTypes.gcode ||
        design?.filetype === FileTypes.aib),
    [lineData, design],
  );

  const showLineDataForAib = useMemo(
    () =>
      SHOW_LINES_FOR_LINE_DATA.includes(lineData) &&
      design?.filetype === FileTypes.aib,
    [lineData, design],
  );

  const isAnalysisLegendMode = useMemo(
    () =>
      AnalysisOperatorLegends.some(
        ({ operatorName, outputName }) =>
          operatorName === selectedOperator?.name &&
          outputName === selectedOperatorOutput?.name,
      ),
    [selectedOperator, selectedOperatorOutput],
  );

  const analysisOperator = useMemo(
    () =>
      AnalysisOperatorLegends.find(
        (analysisOperator) =>
          analysisOperator.operatorName === selectedOperator?.name,
      ),
    [selectedOperator],
  );

  const analysisLegendMinRange = useMemo(
    () =>
      parseFloat(
        selectedOperator?.values.find(
          (value) => value.name === analysisOperator?.minValueName,
        )?.value,
      ),
    [selectedOperator, analysisOperator],
  );

  const analysisLegendMaxRange = useMemo(
    () =>
      parseFloat(
        selectedOperator?.values.find(
          (value) => value.name === analysisOperator?.maxValueName,
        )?.value,
      ),
    [selectedOperator, analysisOperator],
  );

  const showRangeBar = useMemo(
    () =>
      isAnalysisLegendMode ||
      (lineData !== DEFAULT &&
        SHOW_LINES_FOR_LINE_DATA.includes(lineData) &&
        design?.filetype === FileTypes.aib),
    [isAnalysisLegendMode, lineData, design],
  );

  const formattedLegendMinRange = useMemo(() => {
    if (!isAnalysisLegendMode) {
      return legendMinRange % 1 === 0
        ? legendMinRange
        : legendMinRange?.toFixed(2);
    }

    return (+analysisLegendMinRange)?.toFixed(2);
  }, [legendMinRange, isAnalysisLegendMode, analysisLegendMinRange]);

  const formattedLegendMaxRange = useMemo(() => {
    if (!isAnalysisLegendMode) {
      return legendMaxRange % 1 === 0
        ? legendMaxRange
        : legendMaxRange?.toFixed(2);
    }

    return (+analysisLegendMaxRange)?.toFixed(2);
  }, [legendMaxRange, isAnalysisLegendMode, analysisLegendMaxRange]);

  const dropDownMenuItems = useMemo(
    () => [
      {
        id: `line-data-tool-${DEFAULT}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.default',
          defaultMessage: 'Default',
        }),
        selected: lineData === DEFAULT,
        onClick: () => dispatch(updateLinetypeVisibility(DEFAULT)),
      },
      {
        id: `line-data-tool-${LineData.LINETYPE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.linetype',
          defaultMessage: 'Line type',
        }),
        selected: lineData === LineData.LINETYPE,
        onClick: () => dispatch(updateLinetypeVisibility(LineData.LINETYPE)),
      },
      {
        id: `line-data-tool-${LineData.LAYERS}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.layers',
          defaultMessage: 'Layers',
        }),
        selected: lineData === LineData.LAYERS,
        onClick: () => dispatch(updateLinetypeVisibility(LineData.LAYERS)),
      },
      {
        id: `line-data-tool-${LineData.SEQUENCE}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.sequence',
          defaultMessage: 'Sequence',
        }),
        selected: lineData === LineData.SEQUENCE,
        onClick: () => dispatch(updateLinetypeVisibility(LineData.SEQUENCE)),
      },
      {
        id: `line-data-tool-${LineData.THICKNESS}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.thickness',
          defaultMessage: 'Thickness',
        }),
        selected: lineData === LineData.THICKNESS,
        onClick: () => dispatch(updateLinetypeVisibility(LineData.THICKNESS)),
      },
      {
        id: `line-data-tool-${LineData.HEIGHT}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.layerheight',
          defaultMessage: 'Layer Height',
        }),
        selected: lineData === LineData.HEIGHT,
        onClick: () => dispatch(updateLinetypeVisibility(LineData.HEIGHT)),
      },
      {
        id: `line-data-tool-${LineData.SPEED_MULTIPLIER}-action`,
        label: intl.formatMessage({
          id: 'displaymodepanel.speedmultiplier',
          defaultMessage: 'Speed Multiplier',
        }),
        selected: lineData === LineData.SPEED_MULTIPLIER,
        onClick: () =>
          dispatch(updateLinetypeVisibility(LineData.SPEED_MULTIPLIER)),
      },
    ],
    [intl, lineData, dispatch],
  );

  const selectedDropDownMenuItem = useMemo(
    () => dropDownMenuItems.find(({ selected }) => selected),
    [dropDownMenuItems],
  );

  const getIsActionIsEnabled = useCallback(() => {
    if (isAnalysisLegendMode) {
      return !!selectedOperatorOutput;
    }

    return showLineTypeForAibGcode || showLineDataForAib;
  }, [
    isAnalysisLegendMode,
    selectedOperatorOutput,
    showLineTypeForAibGcode,
    showLineDataForAib,
  ]);

  const action = useMemo(
    () => ({
      id: 'line-data-tool-action',
      disabled: !getIsActionIsEnabled(),
      name: isAnalysisLegendMode
        ? intl.formatMessage({
            id: analysisOperator.legendTitleKey,
            defaultMessage: analysisOperator.legendTitle,
          })
        : selectedDropDownMenuItem?.label,
      dropDownMenuItems: isAnalysisLegendMode ? undefined : dropDownMenuItems,
    }),
    [
      intl,
      isAnalysisLegendMode,
      analysisOperator,
      selectedDropDownMenuItem,
      dropDownMenuItems,
      getIsActionIsEnabled,
    ],
  );

  const colorType = useMemo(() => {
    if (legendMinRange === null && legendMaxRange === null) {
      return 'default';
    } else if (lineData === LineData.LAYERS || lineData === LineData.SEQUENCE) {
      return legendMaxRange === legendMinRange ? 'layersUniform' : 'layers';
    } else if (
      lineData === LineData.THICKNESS ||
      lineData === LineData.HEIGHT ||
      lineData === LineData.SPEED_MULTIPLIER
    ) {
      return legendMaxRange === legendMinRange
        ? 'thicknessUniform'
        : 'thickness';
    }
  }, [legendMinRange, legendMaxRange, lineData]);

  const legendRange = useMemo(() => {
    if (!showRangeBar || isAnalysisLegendMode) return null;

    return {
      minRange: isNaN(legendMinRange) ? 'N/A' : formattedLegendMinRange,
      maxRange: isNaN(legendMaxRange) ? 'N/A' : formattedLegendMaxRange,
      colorType: colorType,
    };
  }, [
    showRangeBar,
    legendMinRange,
    legendMaxRange,
    formattedLegendMinRange,
    formattedLegendMaxRange,
    colorType,
    isAnalysisLegendMode,
  ]);

  const getAnalysisRange = useCallback(
    (minRange, maxRange, formattedMinRange, formattedMaxRange) => ({
      minRange: isNaN(minRange) ? 'N/A' : formattedMinRange,
      maxRange: isNaN(maxRange) ? 'N/A' : formattedMaxRange,
      colorType: minRange === maxRange ? 'analysisUniform' : 'analysis',
    }),
    [],
  );

  const range = useMemo(() => {
    if (!showRangeBar) return null;

    if (isAnalysisLegendMode) {
      return getAnalysisRange(
        analysisLegendMinRange,
        analysisLegendMaxRange,
        formattedLegendMinRange,
        formattedLegendMaxRange,
      );
    }

    return legendRange;
  }, [
    getAnalysisRange,
    legendRange,
    showRangeBar,
    formattedLegendMinRange,
    formattedLegendMaxRange,
    isAnalysisLegendMode,
    analysisLegendMinRange,
    analysisLegendMaxRange,
  ]);

  return {
    action,
    range,
  };
}
