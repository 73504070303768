import React from 'react';
import PropTypes from 'prop-types';
import { dropDownMenuHOCProps } from '@hoc/withDropDownMenu';
import Button, { BUTTON_VARIANT_PRIMARY } from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_STANDARD,
} from '@components/1-atoms/IconButton';
import {
  Wrapper,
  LeadingControls,
  Content,
  Title,
  Subtitle,
  EndingControls,
} from './PageHeader.styled';

export const PAGE_HEADER_VARIANT_EXTRA_LARGE = 'extraLarge';
export const PAGE_HEADER_VARIANT_LARGE = 'large';
export const PAGE_HEADER_VARIANT_MEDIUM = 'medium';
export const PAGE_HEADER_VARIANT_SMALL = 'small';

const PageHeader = ({
  dataTestId = 'page-header',
  disabled,
  endingButtonDisabled,
  endingButtonIconName,
  endingButtonTitle,
  endingIconButtonDisabled,
  endingButtonVariant = BUTTON_VARIANT_PRIMARY,
  endingIconButtonDropDownProps = {},
  endingIconButtonIconName,
  endingIconButtonRef,
  leadingIconButtonDisabled,
  leadingIconButtonIconName,
  endingIconButtonVariant = ICON_BUTTON_VARIANT_STANDARD,
  leadingIconButtonRef,
  onEndingButtonClick,
  onEndingIconButtonClick,
  onLeadingIconButtonClick,
  subtitle,
  title,
  variant = PAGE_HEADER_VARIANT_LARGE,
  withMarginTop,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      variant={variant}
      withMarginTop={withMarginTop}
    >
      {leadingIconButtonIconName && (
        <LeadingControls>
          <IconButton
            ref={leadingIconButtonRef}
            dataTestId={`${dataTestId}__leading-icon-button`}
            disabled={leadingIconButtonDisabled || disabled}
            variant={ICON_BUTTON_VARIANT_STANDARD}
            iconName={leadingIconButtonIconName}
            onClick={onLeadingIconButtonClick}
          />
        </LeadingControls>
      )}

      {(title || subtitle) && (
        <Content variant={variant}>
          {title && (
            <Title
              dataTestId={`${dataTestId}__title`}
              variant={variant}
              title={title}
            >
              {title}
            </Title>
          )}

          {subtitle && (
            <Subtitle dataTestId={`${dataTestId}__subtitle`} variant={variant}>
              {subtitle}
            </Subtitle>
          )}
        </Content>
      )}

      {(endingButtonTitle || endingIconButtonIconName) && (
        <EndingControls>
          {endingButtonTitle && (
            <Button
              dataTestId={`${dataTestId}__ending-button`}
              disabled={endingButtonDisabled || disabled}
              variant={endingButtonVariant}
              iconName={endingButtonIconName}
              onClick={onEndingButtonClick}
            >
              {endingButtonTitle}
            </Button>
          )}

          {endingIconButtonIconName && (
            <IconButton
              ref={endingIconButtonRef}
              dataTestId={`${dataTestId}__ending-icon-button`}
              disabled={endingIconButtonDisabled || disabled}
              variant={endingIconButtonVariant}
              iconName={endingIconButtonIconName}
              onClick={onEndingIconButtonClick}
              {...(endingIconButtonDropDownProps || {})}
            />
          )}
        </EndingControls>
      )}
    </Wrapper>
  );
};

PageHeader.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  endingIconButtonDropDownProps: PropTypes.shape(dropDownMenuHOCProps),
  endingButtonDisabled: PropTypes.bool,
  endingButtonIconName: PropTypes.string,
  endingButtonTitle: PropTypes.string,
  endingIconButtonDisabled: PropTypes.bool,
  endingButtonVariant: PropTypes.string,
  endingIconButtonIconName: PropTypes.string,
  endingIconButtonVariant: PropTypes.string,
  endingIconButtonRef: PropTypes.object,
  leadingIconButtonDisabled: PropTypes.bool,
  leadingIconButtonIconName: PropTypes.string,
  leadingIconButtonRef: PropTypes.object,
  onEndingButtonClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  onLeadingIconButtonClick: PropTypes.func,
  variant: PropTypes.oneOf([
    PAGE_HEADER_VARIANT_EXTRA_LARGE,
    PAGE_HEADER_VARIANT_LARGE,
    PAGE_HEADER_VARIANT_MEDIUM,
    PAGE_HEADER_VARIANT_SMALL,
  ]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
  withMarginTop: PropTypes.bool,
};

export default PageHeader;
