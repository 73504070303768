import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import PageLoader from '@components/1-atoms/PageLoader';

export const PAGE_LOADER_CONTAINER_ID = 'loaders-container';

const PageLoaderPortal = ({ show = true, progress, title }) => {
  const pageLoaderPortalContainer = document.getElementById(
    PAGE_LOADER_CONTAINER_ID,
  );

  if (!pageLoaderPortalContainer) return null;

  return createPortal(
    <PageLoader show={show} progress={progress} title={title} />,
    pageLoaderPortalContainer,
  );
};

PageLoaderPortal.propTypes = {
  show: PropTypes.bool,
  progress: PropTypes.number,
  title: PropTypes.string,
};

export default PageLoaderPortal;
