import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import client from '@api/client';
import { getCurrentUser } from '@selectors/loginSelectors';
import { expireToken } from '@actions/loginActions';
import { showErrorDialog } from '@actions/errorActions';
import { TOKEN_EXPIRED_REASON_CODE } from '@constants/utilityConstants';
import { matchPath, useHistory } from 'react-router-dom';
import { MANAGE_NOT_FOUND_ENDPOINTS, ROUTES } from '@constants/router';

/**
 * This component serves for adding middlewares to the Axios client.
 * We handle only common/global scenarios related to the server response.
 */

export default function ApiClientManager() {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser());
  const userRef = useRef(user);
  const history = useHistory();

  const requestHandler = useCallback(
    (config) => {
      if (config.headers.Authorization) return config;

      const token = userRef.current?.token || '';
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    [userRef],
  );

  const successHandler = useCallback((res) => res, []);
  const errorHandler = useCallback(
    (error) => {
      const errorStatus = error?.response?.status === 401;
      const tokenExpired =
        error?.response?.data?.error?.internalCode ===
        TOKEN_EXPIRED_REASON_CODE;

      if (errorStatus && tokenExpired) {
        const expiredTokenError = {
          ...error,
          tokenExpired: true,
        };

        dispatch(expireToken());

        return expiredTokenError;
      }

      const notFound = error?.response?.status === 404;

      if (notFound) {
        const isPathMatchingAnyPattern = MANAGE_NOT_FOUND_ENDPOINTS.some(
          (route) =>
            matchPath(error?.config?.url, {
              path: route,
            }),
        );

        if (isPathMatchingAnyPattern) {
          history.replace(ROUTES.NOT_FOUND);
          return Promise.reject(error);
        }
      }

      const errorResponseData = error?.response?.data || {};
      const ignoreGlobalErrorHandler = error?.config?.ignoreGlobalErrorHandler;

      if (!userRef.current?.token) {
        //meaning the user is not logged in, we don't show the error dialog
        return Promise.reject(error);
      }

      if (ignoreGlobalErrorHandler) {
        return Promise.reject(error);
      }

      if (errorResponseData.error?.message) {
        dispatch(showErrorDialog('Error', errorResponseData.error.message));
      } else if (errorResponseData.errors) {
        const errorsConcat = errorResponseData.errors.join('\n');
        dispatch(showErrorDialog('Error', errorsConcat));
      } else {
        dispatch(
          showErrorDialog(
            'Error',
            'something went wrong... please check your connection',
          ),
        );
      }

      return Promise.reject(error);
    },
    [history, userRef, dispatch],
  );

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    client.interceptors.request.use(requestHandler);
  }, [requestHandler]);

  useEffect(() => {
    client.interceptors.response.use(successHandler, errorHandler);
  }, [successHandler, errorHandler]);

  return null;
}
