import { all } from 'redux-saga/effects';
import auth from './auth';
import templates from './templates';
import settings from './settings';
import analytics from './analytics';
import printers from './printers';
import workflowAnnotations from '@sagas/workflowAnnotations';

export default function* root() {
  yield all([
    ...auth,
    ...templates,
    ...settings,
    ...analytics,
    ...printers,
    ...workflowAnnotations,
  ]);
}
