import { takeEvery, put, select } from 'redux-saga/effects';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { getCurrentUser } from '@selectors/loginSelectors';
import actionTypes from '@actions';
import {
  generateMFAConnectorKeySuccess,
  generateMFAConnectorKeyFailure,
  validateMFACodeSuccess,
  validateMFACodeFailure,
  deactivateMFASuccess,
  deactivateMFAFailure,
} from '@actions/settingsActions';
import { CURRENT_USER_KEY_LOCAL_STORAGE } from '@constants/utilityConstants';
import {
  getFromLocalStorage,
  addToLocalStorage,
  removeFromLocalStorage,
} from '@utils/localStorage';

const updateUserMFAInLocalStorage = (mfaEnabled = false) => {
  const nextLocalStorageUser = getFromLocalStorage(
    CURRENT_USER_KEY_LOCAL_STORAGE,
  );

  if (!nextLocalStorageUser) return;

  removeFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);

  nextLocalStorageUser.mfaEnabled = mfaEnabled;

  addToLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE, nextLocalStorageUser);
};

export function* generateMFAConnectorKey({ payload }) {
  const currentUser = yield select(getCurrentUser());

  try {
    const {
      data: { encryptedSecret, qrCodeUrl },
    } = yield client.post(endpoints.generateMFASecret, {
      userId: currentUser?.id,
    });

    yield put(generateMFAConnectorKeySuccess({ encryptedSecret, qrCodeUrl }));

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (error) {
    yield put(generateMFAConnectorKeyFailure());

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* validateMFACode({ payload }) {
  const { code, answerCallback, successCallback } = payload;
  const currentUser = yield select(getCurrentUser());

  try {
    const {
      data: { valid },
    } = yield client.post(endpoints.validateMFACode, {
      userId: currentUser?.id,
      code,
    });

    answerCallback?.(valid);

    if (!valid) {
      yield put(validateMFACodeFailure());

      return;
    }

    updateUserMFAInLocalStorage(true);

    yield put(validateMFACodeSuccess());
    if (successCallback) {
      successCallback();
    }
  } catch (error) {
    yield put(validateMFACodeFailure());

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* deactivateMFA() {
  const currentUser = yield select(getCurrentUser());

  try {
    yield client.post(endpoints.deactivateMFA, {
      userId: currentUser?.id,
    });

    updateUserMFAInLocalStorage(false);

    yield put(deactivateMFASuccess());
  } catch (error) {
    yield put(deactivateMFAFailure());

    // eslint-disable-next-line
    console.error(error);
  }
}

export default [
  takeEvery(
    actionTypes.GENERATE_MFA_CONNECTOR_KEY_REQUEST,
    generateMFAConnectorKey,
  ),
  takeEvery(actionTypes.VALIDATE_MFA_CODE_REQUEST, validateMFACode),
  takeEvery(actionTypes.DEACTIVATE_MFA_REQUEST, deactivateMFA),
];
