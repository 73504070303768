import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export class EventCategory {
  static engagement = new EventCategory('engagement');
  static performances = new EventCategory('performances');
  static errors = new EventCategory('errors');

  constructor(name) {
    this.name = name;
  }

  getFromString(string) {
    const category = this.getAllValues().find((item) => item.name === string);
    if (!category) {
      // eslint-disable-next-line no-console
      console.warn(
        'Skipping Google Analytics event because the event_category is unknown! Use one of the predefined ones: ',
        this.getAllValues(),
      );
    }
  }

  getAllValues() {
    return Object.keys(EventCategory);
  }
}

export class EventAction {
  static toolpathDownloaded = new EventAction('toolpathDownloaded');
  static toolpathStreamed = new EventAction('toolpathStreamed');
  static geometricError = new EventAction('geometricError');
  static operatorRecommandationAccepted = new EventAction(
    'operatorRecommandationAccepted',
  );
  static operatorAdded = new EventAction('operatorAdded');

  constructor(name) {
    this.name = name;
  }

  getFromString(string) {
    const category = this.getAllValues().find((item) => item.name === string);
    if (!category) {
      // eslint-disable-next-line no-console
      console.warn(
        'Skipping Google Analytics event because the event is unknown! Use one of the predefined ones: ',
        this.getAllValues(),
      );
    }
  }

  getAllValues() {
    return Object.keys(EventAction);
  }
}

const GaTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag || typeof window.gtag !== 'function') {
      // eslint-disable-next-line no-console
      console.debug('GA disabled, window.gtag not recognized!');
      return;
    }

    window.gtag('set', 'page_title', location.pathname?.split('/')[1]);
    window.gtag('set', 'page_path', location.pathname + location.search);

    // Feature detects Navigation Timing API support.
    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      const timeSincePageLoad = Math.round(performance.now());

      // Sends the timing event to Google Analytics.
      window.gtag('event', 'timing_complete', {
        name: 'page-load-time',
        value: timeSincePageLoad,
        event_category: EventCategory.performances.name,
      });
    }
  }, [location]);
};

export const trackError = (error, fatal = false) => {
  if (!window.gtag || typeof window.gtag !== 'function') {
    // eslint-disable-next-line no-console
    console.debug('GA disabled, window.gtag not recognized!');
    return;
  }
  window.gtag('event', 'exception', {
    description: error,
    fatal: fatal,
  });
};

export const trackGeometricError = (operatorName, messageKey) => {
  sendEvent(
    EventAction.geometricError,
    EventCategory.errors,
    messageKey ?? 'Unknown error',
    null,
    operatorName,
  );
};

export const sendEvent = (
  event,
  event_category,
  value,
  event_label = null,
  operator_name = null,
) => {
  if (
    !event ||
    !event_category ||
    !(event instanceof EventAction) ||
    !(event_category instanceof EventCategory)
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'Skipping Google Analytics event because the types are not correct!',
    );
    return;
  }

  if (!value && !operator_name) {
    // eslint-disable-next-line no-console
    console.warn(
      'Skipping Google Analytics event because value && operator_name are null!',
    );
    return;
  }

  if (!PROD_OR_TEST_ENV) {
    return;
  }

  if (!window.gtag) {
    // eslint-disable-next-line no-console
    console.debug("can't send the event, window.gtag not recognized!");
    return;
  }

  const eventObj = {
    event_category: event_category.name,
    event_label: event_label,
    value: value,
  };

  if (operator_name) {
    eventObj.operator_name = operator_name;
    eventObj.error_message = value;
  }

  window.gtag('event', event.name, eventObj);
};

export const setUser = (user) => {
  // eslint-disable-next-line no-console
  if (!window.gtag || !PROD_OR_TEST_ENV) {
    // eslint-disable-next-line no-console
    console.debug(
      "can't set the user, window.gtag not recognized or the env is not test or production!",
    );
    return;
  }
  window.gtag('set', { user_id: user.username });
  window.gtag('set', {
    user_properties: {
      organization: user.organizationName,
      role: user.role,
      username: user.username,
    },
  });
};

export default GaTracker;
