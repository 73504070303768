import React from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_VARIANT_ELEVATED } from '@components/1-atoms/Button';
import {
  Wrapper,
  Image,
  ContentWrapper,
  Content,
  Icon,
  Details,
  Title,
  Description,
  ButtonWrapper,
} from './Banner.styled';

export const BUTTON_VARIANT_DEFAULT = 'default';
export const BUTTON_VARIANT_FULL_SCREEN = 'fullScreen';

const Banner = ({
  variant = BUTTON_VARIANT_DEFAULT,
  iconName,
  title,
  imageSrc = '/img/banner-background.png',
  description,
  buttonLabel,
  buttonIconName,
  onButtonClick,
}) => {
  const isFullScreenVariant = variant === BUTTON_VARIANT_FULL_SCREEN;
  const showDetails = title || description;
  const showContent = iconName || showDetails;

  return (
    <Wrapper isFullScreenVariant={isFullScreenVariant}>
      {imageSrc && <Image src={imageSrc} />}

      <ContentWrapper>
        {showContent && (
          <Content>
            {iconName && <Icon name={iconName} />}

            {showDetails && (
              <Details>
                {title && <Title>{title}</Title>}

                {description && <Description>{description}</Description>}
              </Details>
            )}
          </Content>
        )}

        {buttonLabel && (
          <ButtonWrapper>
            <Button
              variant={BUTTON_VARIANT_ELEVATED}
              iconName={buttonIconName}
              onClick={onButtonClick}
              stretch={false}
            >
              {buttonLabel}
            </Button>
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

Banner.propTypes = {
  variant: PropTypes.oneOf([
    BUTTON_VARIANT_DEFAULT,
    BUTTON_VARIANT_FULL_SCREEN,
  ]),
  iconName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string,
  buttonIconName: PropTypes.string,
  onButtonClick: PropTypes.func,
  imageSrc: PropTypes.string,
};

export default Banner;
