import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Fields = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing.level6};
  `}
`;
