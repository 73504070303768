import styled from 'styled-components';

export const DialogPortalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;
