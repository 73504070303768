import { useContext, useCallback, useRef, useEffect } from 'react';
import { uniqueId } from 'lodash';
import DialogContext from '@contexts/DialogContext';
import { DIALOGS_COINTAINER_ID } from '@components/2-molecules/DialogPortal';

export const DIALOG_FADE_OUT_TRANSITION = 300;

const useDialog = () => {
  const {
    dialogs,
    addDialog,
    removeDialog,
    updateDialogData,
    addDialogIDToHideOnRouteChange,
  } = useContext(DialogContext);
  const dialogsRef = useRef(dialogs);

  const hideDialog = useCallback(
    (id) =>
      new Promise((resolve) => {
        updateDialogData(id, { show: false });

        setTimeout(() => {
          if (id) {
            removeDialog(id);
            resolve();

            return;
          }

          const recentDialog = dialogsRef.current?.slice(-1);

          removeDialog(recentDialog?.id);
          resolve();
        }, DIALOG_FADE_OUT_TRANSITION);
      }),
    [removeDialog, updateDialogData],
  );

  const showDialog = useCallback(
    (id = '', data = {}) => {
      const dialogId = id || uniqueId('dialog-');
      const hideOnRouteChange =
        'hideOnRouteChange' in data ? data?.hideOnRouteChange : true;

      if (hideOnRouteChange) {
        addDialogIDToHideOnRouteChange(dialogId);
      }

      addDialog({
        id: dialogId,
        data: {
          ...data,
          hideOnRouteChange,
        },
      });

      const dialogContainerElement = document.getElementById(
        DIALOGS_COINTAINER_ID,
      );
      const dialogContainerObserver = new MutationObserver(
        (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              const isDialogMounted = document.getElementById(dialogId);

              if (isDialogMounted) {
                // TODO: Find a better way to handle this
                setTimeout(
                  () => updateDialogData(dialogId, { show: true }),
                  100,
                );
                observer.disconnect();
              }
            }
          }
        },
      );

      if (dialogContainerElement) {
        dialogContainerObserver.observe(dialogContainerElement, {
          childList: true,
        });
      }
    },
    [addDialog, updateDialogData, addDialogIDToHideOnRouteChange],
  );

  const isDialogShown = useCallback(
    (id) => {
      const isShown = dialogs?.some(({ id: dialogId }) => dialogId === id);

      return isShown;
    },
    [dialogs],
  );

  const getDialogData = useCallback(
    (id) => {
      const dialog = dialogs?.find(({ id: dialogId }) => dialogId === id);

      return dialog?.data || {};
    },
    [dialogs],
  );

  useEffect(() => {
    if (dialogsRef.current !== dialogs) {
      dialogsRef.current = dialogs;
    }
  }, [dialogs]);

  return {
    dialogs,
    showDialog,
    hideDialog,
    updateDialogData,
    isDialogShown,
    getDialogData,
  };
};

export default useDialog;
