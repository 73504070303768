import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCameraTool from '@hooks/toolBar/useCameraTool';
import useWorldTool from '@hooks/toolBar/useWorldTool';
import usePrinterTool from '@hooks/toolBar/usePrinterTool';
import usePerspectiveTool from '@hooks/toolBar/usePerspectiveTool';
import { setToolpathSimulation } from '@actions/conceptActions';
import { getSimulationMaxTime } from '@selectors/conceptSelectors';
import { millisToMinutesAndSeconds } from '@utils/commonFunctions';
import { SimulationSpeedLevels } from '@constants/simulationSpeedLevels';
import SceneSimulationActionBar from '@components/2-molecules/SceneSimulationActionBar';

const FRAME_PER_EACH_FETCH_TIME = 33;
const NEW_FRAME_FETCH_TIME = 33;
const SPEED_LEVELS = [1, 5, 10, 50, 100];

let timer;

export default function WorkflowToolpathSimulation() {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [speedLevel, setSpeedLevel] = useState(0);
  const [speed, setSpeed] = useState(FRAME_PER_EACH_FETCH_TIME);
  const [loop, setLoop] = useState(false);

  const sliderValueRef = useRef(sliderValue);
  const loopRef = useRef(loop);
  const speedRef = useRef(speed);

  const printerActions = usePrinterTool();
  const worldActions = useWorldTool();
  const cameraActions = useCameraTool();
  const perspectiveActions = usePerspectiveTool();

  const maxTime = useSelector(getSimulationMaxTime());

  const resetSimulation = useCallback(() => {
    setSliderValue(0);
    dispatch(setToolpathSimulation(0));
  }, [dispatch]);

  const loopSimulation = useCallback(() => {
    setLoop(!loop);
  }, [loop]);

  const finishSimulation = useCallback((maxTime) => {
    clearInterval(timer);
    setSliderValue(maxTime);
    setIsPlaying(false);
  }, []);

  const pauseSimulation = useCallback(() => {
    clearInterval(timer);
    setIsPlaying(false);
  }, []);

  const startTimer = useCallback(() => {
    clearInterval(timer);

    return setInterval(() => {
      const currentSliderValue = sliderValueRef.current;
      const loop = loopRef.current;
      const speed = speedRef.current;
      const nextSliderValue = currentSliderValue + speed;

      if (currentSliderValue >= maxTime) {
        if (loop) {
          resetSimulation();
        } else {
          finishSimulation(maxTime);
        }

        return;
      }

      dispatch(setToolpathSimulation(nextSliderValue));
      setSliderValue(nextSliderValue);
    }, NEW_FRAME_FETCH_TIME);
  }, [dispatch, maxTime, resetSimulation, finishSimulation]);

  const playSimulation = useCallback(() => {
    setIsPlaying(true);

    timer = startTimer();
  }, [startTimer]);

  const handleSliderChange = useCallback(
    (changeEvent) => {
      clearInterval(timer);
      dispatch(setToolpathSimulation(+changeEvent));
      setSliderValue(+changeEvent);
    },
    [dispatch],
  );

  const handleSliderAfterChange = useCallback(() => {
    if (!isPlaying) return;

    playSimulation();
  }, [isPlaying, playSimulation]);

  const handleSpeedLevelChange = useCallback(
    (speed) => () => {
      setSpeed(FRAME_PER_EACH_FETCH_TIME * SPEED_LEVELS[speed]);
      setSpeedLevel(speed);
    },
    [],
  );

  const simulationSpeedDropDownMenuItems = useMemo(
    () =>
      Object.entries(SimulationSpeedLevels).map(([label, value]) => ({
        id: label,
        label,
        selected: speedLevel === value,
        onClick: handleSpeedLevelChange(value),
      })),
    [speedLevel, handleSpeedLevelChange],
  );

  const selectedSpeedLevelLabel = useMemo(
    () =>
      Object.entries(SimulationSpeedLevels)?.find(
        ([_, value]) => value === speedLevel,
      )?.[0],
    [speedLevel],
  );

  const moreOptionsDropDownMenu = useMemo(() => {
    const switchOptions = [...printerActions, ...worldActions].map(
      (action) => ({
        id: action.id,
        leadingIconName: action.iconName,
        label: action.name,
        endingButton: {
          type: 'switch',
          enabled: action.active,
          onChange: action.handler,
        },
      }),
    );

    const cameraOptions = cameraActions.map((action, i) => ({
      id: action.id,
      leadingIconName: action.iconName,
      label: action.name,
      onClick: action.handler,
      withDivider: i === 0,
    }));

    const perspectiveOptions = perspectiveActions?.[0]?.dropDownMenuItems || [];

    return [...switchOptions, ...cameraOptions, ...perspectiveOptions];
  }, [printerActions, worldActions, cameraActions, perspectiveActions]);

  useEffect(() => {
    sliderValueRef.current = sliderValue;
  }, [sliderValue]);

  useEffect(() => {
    loopRef.current = loop;
  }, [loop]);

  useEffect(() => {
    speedRef.current = speed;
  }, [speed]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <SceneSimulationActionBar
      slider={{
        defaultValue: 0,
        min: 0,
        max: maxTime,
        step: 1,
        value: sliderValue,
        minTitle: millisToMinutesAndSeconds(sliderValue),
        maxTitle: millisToMinutesAndSeconds(maxTime),
        onChange: handleSliderChange,
        onAfterChange: handleSliderAfterChange,
      }}
      speedButtonDropDown={{
        title: 'Speed',
        children: selectedSpeedLevelLabel,
        dropDownMenuItems: simulationSpeedDropDownMenuItems,
      }}
      moreIconButtonDropDownMenuItems={moreOptionsDropDownMenu}
      isPlaying={isPlaying}
      isLoop={loop}
      onLoopIconButtonClick={loopSimulation}
      onPlayIconButtonClick={isPlaying ? pauseSimulation : playSimulation}
    />
  );
}
