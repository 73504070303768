import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import getIntlProvider from '@utils/getIntlProvider';
import { stopPropagation } from '@utils/interactionEvents';
import { setApplicationScrollable } from '@stylesheets/helpers';
import EmptyStateBox, {
  EMPTY_STATE_BOX_VARIANT_COMPACT,
} from '@components/2-molecules/EmptyStateBox';
import MenuListItem, {
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH,
  MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
} from '@components/1-atoms/MenuListItem';
import {
  OverlayLayer,
  Wrapper,
  DROP_DOWN_MENU_POSITION_TOP_LEFT,
  DROP_DOWN_MENU_POSITION_TOP_CENTER,
  DROP_DOWN_MENU_POSITION_TOP_RIGHT,
  DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
  DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
  DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
} from './DropDownMenu.styled';

export const DROP_DOWN_MENU_ID = 'drop-down-menu';

const intl = getIntlProvider();

const DropDownMenu = forwardRef(
  (
    {
      className,
      closeDropDownMenu,
      dataTestId = 'drop-down-menu',
      emptyStateDescription,
      emptyStateIconName,
      emptyStateLinkButtonLabel,
      emptyStateLinkButtonPath,
      emptyStateOnLinkButtonClick,
      emptyStateTitle = intl.formatMessage({
        id: 'dropdownmenu.empty_state.title',
        defaultMessage: 'No items available',
      }),
      menuItems = [],
      menuScrollToBottom,
      onFormSelect,
      position = DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
      show,
      style,
    },
    ref,
  ) => {
    const wrapperRef = useRef(null);
    const showEmptyMenuItem = !menuItems.length;

    const combineRefs = useCallback(
      (element) => {
        wrapperRef.current = element;

        if (ref) {
          ref.current = element;
        }
      },
      [ref],
    );

    const handleClick = useCallback(
      (formFieldValue, onClick) => (e) => {
        onClick?.(e);
        onFormSelect?.(formFieldValue);
        closeDropDownMenu?.();
      },
      [onFormSelect, closeDropDownMenu],
    );

    useEffect(() => {
      if (menuScrollToBottom) {
        wrapperRef.current?.scrollTo(0, wrapperRef.current?.scrollHeight);
      }
    }, [menuScrollToBottom]);

    useEffect(() => {
      if (show) {
        wrapperRef.current?.focus();
      }
    }, [show]);

    useEffect(() => {
      if (show) {
        setApplicationScrollable(false);
      } else {
        setApplicationScrollable(true);
      }

      return () => {
        setApplicationScrollable(true);
      };
    }, [show]);

    return (
      <>
        {show && <OverlayLayer onClick={stopPropagation(closeDropDownMenu)} />}

        <Wrapper
          className={className}
          dataTestId={dataTestId}
          fixedPosition={style?.position === 'fixed'}
          id={DROP_DOWN_MENU_ID}
          position={position}
          ref={combineRefs}
          show={show}
          style={style}
          tabIndex={0}
        >
          {showEmptyMenuItem && (
            <EmptyStateBox
              variant={EMPTY_STATE_BOX_VARIANT_COMPACT}
              iconName={emptyStateIconName}
              title={emptyStateTitle}
              description={emptyStateDescription}
              linkButtonLabel={emptyStateLinkButtonLabel}
              linkButtonPath={emptyStateLinkButtonPath}
              openLinkInNewTab
              onLinkButtonClick={emptyStateOnLinkButtonClick}
            />
          )}

          {menuItems.map(
            (
              {
                color,
                description,
                disabled,
                endingButton,
                endingIconName,
                field,
                formFieldValue,
                id,
                label,
                leadingIconColor,
                leadingIconName,
                leadingIconUrl,
                navigationItem,
                navigationPath,
                onClick,
                onEndingIconButtonClick,
                selected,
                skeleton,
                tooltip,
                withBadge,
                withDivider,
              },
              index,
            ) => (
              <MenuListItem
                color={color}
                closeDropDownMenu={closeDropDownMenu}
                description={description}
                disabled={disabled}
                endingButton={endingButton}
                endingIconName={endingIconName}
                field={field}
                key={id || index}
                label={label}
                leadingIconName={leadingIconName}
                leadingIconUrl={leadingIconUrl}
                leadingIconColor={leadingIconColor}
                navigationPath={navigationPath}
                navigationItem={navigationItem}
                onClick={stopPropagation(handleClick(formFieldValue, onClick))}
                onEndingIconButtonClick={stopPropagation(
                  onEndingIconButtonClick,
                )}
                selected={selected}
                skeleton={skeleton}
                tooltip={tooltip}
                withBadge={withBadge}
                withDivider={withDivider}
              />
            ),
          )}
        </Wrapper>
      </>
    );
  },
);

DropDownMenu.propTypes = {
  className: PropTypes.string,
  closeDropDownMenu: PropTypes.func,
  dataTestId: PropTypes.string,
  emptyStateDescription: PropTypes.string,
  emptyStateIconName: PropTypes.string,
  emptyStateLinkButtonLabel: PropTypes.string,
  emptyStateLinkButtonPath: PropTypes.string,
  emptyStateOnLinkButtonClick: PropTypes.func,
  emptyStateTitle: PropTypes.string,
  show: PropTypes.bool,
  position: PropTypes.oneOf([
    DROP_DOWN_MENU_POSITION_TOP_LEFT,
    DROP_DOWN_MENU_POSITION_TOP_CENTER,
    DROP_DOWN_MENU_POSITION_TOP_RIGHT,
    DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
    DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
    DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
  ]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      formFieldValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      color: PropTypes.string,
      description: PropTypes.string,
      endingIconName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      leadingIconName: PropTypes.string,
      leadingIconColor: PropTypes.string,
      endingButton: PropTypes.shape({
        // Switch, Radio, Checkbox props
        name: PropTypes.string,
        type: PropTypes.oneOf([
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_SWITCH,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_RADIO,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_CHECKBOX,
          MENU_LIST_ITEM_ENDING_BUTTON_TYPE_ICON_BUTTON,
        ]).isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
        checked: PropTypes.bool,
        enabled: PropTypes.bool,

        // Icon Button props
        disabled: PropTypes.bool,
        iconName: PropTypes.string,
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        selected: PropTypes.bool,
        title: PropTypes.string,
        variant: PropTypes.string,
      }),
      onClick: PropTypes.func,
      onEndingIconButtonClick: PropTypes.func,
      selected: PropTypes.bool,
      skeleton: PropTypes.bool,
      tooltip: PropTypes.object,
      disabled: PropTypes.bool,
      navigationPath: PropTypes.string,
      withBadge: PropTypes.bool,
      withDivider: PropTypes.bool,
    }),
  ),
  menuScrollToBottom: PropTypes.bool,
  onFormSelect: PropTypes.func,
  style: PropTypes.shape({
    position: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
  }),
};

DropDownMenu.displayName = 'DropDownMenu';

export default DropDownMenu;
