import styled, { css } from 'styled-components';
import { flexColumn, when } from '@stylesheets/helpers';

export const StickyContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: clip;

  ${({ theme: { borderRadius } }) => css`
    border-radius: ${borderRadius.small};
  `}
`;

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Wrapper = styled.div`
  ${flexColumn}
  flex-shrink: 0;
  position: relative;

  ${({ stickyHeader, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.small};
    background-color: ${colors.surfaceContainerHigh};

    ${when(
      stickyHeader,
      `
        padding-top: 48px;
      `,
    )}
  `}
`;

export const SkeletonWrapper = styled(Wrapper)`
  height: 48px;

  ${({ theme: { spacing, colors } }) => css`
    background-color: ${colors.surfaceContainerHigh};
    padding: ${spacing.level4};
  `}
`;

export const ContentSkeletonWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level6};
  `}
`;

export const OperatorBody = styled.div`
  max-height: 0;
  ${flexColumn}
  overflow: hidden;
  transition: max-height 0.3s ease;

  ${({ expand, maxHeight, theme: { borderRadius } }) => css`
    border-radius: 0 0 ${borderRadius.small} ${borderRadius.small};

    ${when(
      expand,
      `
        max-height: ${maxHeight || 1000}px;
        transition: max-height 0.4s ease;
      `,
    )}
  `}
`;

export const BodyContent = styled.div`
  ${flexColumn}
  overflow: auto;
  flex-shrink: 0;
`;

export const Footer = styled.div`
  margin-top: auto;
`;
