import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const authQueryKeys = {
  tokenValidity: ['showLoader', 'tokenValidity'],
  authenticationConfig: (subdomain) => [
    'showLoader',
    'authenticationConfig',
    subdomain,
  ],
  currentUser: ['currentUser'],
};

export default function useAuthQueries({ token, subdomain } = {}) {
  const checkTokenValidityQuery = useQuery({
    queryKey: authQueryKeys.tokenValidity,
    queryFn: async () => {
      try {
        await client.head(
          endpoints.passwordResetTokenValidity.replace(':token', token),
        );
        return { tokenInvalid: false };
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          return { tokenInvalid: true };
        }
        throw error;
      }
    },
    retry: () => {
      return false; //no retries
    },
    enabled: false,
  });

  const getAuthenticationConfigQuery = useQuery({
    queryKey: authQueryKeys.authenticationConfig(subdomain),
    queryFn: async () =>
      await client
        .get(endpoints.getAuthenticationConfig, {
          params: {
            subdomain,
          },
        })
        .then((res) => res.data),
    enabled: !!subdomain,
    staleTime: Infinity,
  });

  return {
    checkTokenValidityQuery,
    getAuthenticationConfigQuery,
  };
}
