import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';

export default function SVG({ name, ...props }) {
  if (!name && !props.src) {
    return null;
  }

  return (
    <InlineSVG
      data-testid="svg"
      src={`/img/icons/${name}.svg`}
      uniqueHash="a1f8d1"
      cacheRequests
      uniquifyIDs
      {...props}
    />
  );
}

SVG.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  src: PropTypes.string,
};
