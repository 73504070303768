import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useOrganization from '@hooks/organization/useOrganization';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import useProject from '@hooks/projects/useProject';
import DateFormatter from '@app/lib/DateFormatter';
import { ModalDataTypes } from '@constants/modalDataTypes';
import InfoRow from '@components/1-atoms/InfoRow';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';

const MODAL_ID = ModalDataTypes.WORKFLOW_DETAILS;

const WorkflowDetailsDialog = () => {
  const intl = useIntl();
  const { getDialogData } = useDialog();
  const { getUsers } = useOrganization();
  const { getProject } = useProject();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const workflow = dialogData?.workflow;
  let project = getProject(workflow?.workspaceId);
  const users = getUsers();

  const { projectQuery } = useProjectQueries({
    projectId: project ? null : workflow?.workspaceId,
  });
  project = project || projectQuery.data;

  const author =
    users.find((user) => user.id == workflow.createdBy)?.name ||
    'External user';

  const workflowInfoRows = useMemo(() => {
    return [
      {
        id: 'workflow-details-dialog-author',
        label: intl.formatMessage({
          id: 'workflow.details.dialog.author',
          defaultMessage: 'Author',
        }),
        description: author,
      },
      {
        id: 'workflow-details-dialog-version',
        label: intl.formatMessage({
          id: 'workflow.details.dialog.version',
          defaultMessage: 'Version',
        }),
        description: workflow.version,
      },
      {
        id: 'workflow-details-dialog-date-created',
        label: intl.formatMessage({
          id: 'workflow.details.dialog.date_created',
          defaultMessage: 'Date created',
        }),
        description: DateFormatter.formatIso(workflow.createdAt) || '',
      },
      {
        id: 'workflow-details-dialog-last-edit',
        label: intl.formatMessage({
          id: 'workflow.details.dialog.last_edit',
          defaultMessage: 'Last edit',
        }),
        description: DateFormatter.formatIso(workflow.lastModifiedAt) || '',
      },
      {
        id: 'workflow-details-dialog-parent-project',
        label: intl.formatMessage({
          id: 'workflow.details.dialog.parent_project',
          defaultMessage: 'Parent Project',
        }),
        description:
          project?.name ||
          intl.formatMessage({
            id: 'general.loading',
            defaultMessage: 'Loading',
          }),
      },
    ];
  }, [author, intl, project?.name, workflow]);

  return (
    <DialogPortal
      dataTestId="workflow-details-dialog"
      dialogId={MODAL_ID}
      primaryButtonLabel={
        dialogData?.primaryButtonLabel ??
        intl.formatMessage({ id: 'general.close', defaultMessage: 'Close' })
      }
    >
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={intl.formatMessage({
          id: 'workflow.details.dialog.title',
          defaultMessage: 'Workflow details',
        })}
      />

      {workflowInfoRows.map((row) => (
        <InfoRow
          dataTestId={`workflow-details-dialog__${row.label}`}
          key={row.id}
          label={row.label}
          description={row.description}
          withDivider
        />
      ))}
    </DialogPortal>
  );
};

export default WorkflowDetailsDialog;
