import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const versionQueryKeys = {
  computationVersion: ['computationVersion'],
  version: ['version'],
};

export default function useVersionQueries() {
  const computationVersionQuery = useQuery({
    queryKey: versionQueryKeys.computationVersion,
    queryFn: () =>
      client.get(endpoints.computationVersion).then((res) => res.data),
    enabled: false,
  });

  const versionQuery = useQuery({
    queryKey: versionQueryKeys.version,
    queryFn: () => client.get(endpoints.version).then((res) => res.data),
    enabled: false,
  });

  return {
    computationVersionQuery,
    versionQuery,
  };
}
