import { useContext, useCallback, useRef, useEffect } from 'react';
import { uniqueId } from 'lodash';
import { MODALS_COINTAINER_ID } from '@components/2-molecules/ModalPortal';
import ModalContext from '@contexts/ModalContext';

export const MODAL_FADE_OUT_TRANSITION = 400;

const useModal = () => {
  const {
    modals,
    addModal,
    removeModal,
    updateModalData,
    addModalIDToHideOnRouteChange,
  } = useContext(ModalContext);
  const modalsRef = useRef(modals);

  const hideModal = useCallback(
    (id) =>
      new Promise((resolve) => {
        updateModalData(id, { show: false });

        setTimeout(() => {
          if (id) {
            removeModal(id);
            resolve();

            return;
          }

          const recentModal = modalsRef.current?.slice(-1);

          removeModal(recentModal?.id);
          resolve();
        }, MODAL_FADE_OUT_TRANSITION);
      }),
    [removeModal, updateModalData],
  );

  const showModal = useCallback(
    (id = '', data = {}) => {
      const modalId = id || uniqueId('modal-');
      const hideOnRouteChange =
        'hideOnRouteChange' in data ? data?.hideOnRouteChange : true;

      if (hideOnRouteChange) {
        addModalIDToHideOnRouteChange(modalId);
      }

      addModal({
        id: modalId,
        data: {
          ...data,
          hideOnRouteChange,
        },
      });

      const modalContainerElement =
        document.getElementById(MODALS_COINTAINER_ID);
      const modalContainerObserver = new MutationObserver(
        (mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              const isModalMounted = document.getElementById(modalId);

              if (isModalMounted) {
                // TODO: Find a better way to handle this
                setTimeout(() => updateModalData(modalId, { show: true }), 100);
                observer.disconnect();
              }
            }
          }
        },
      );

      if (modalContainerElement) {
        modalContainerObserver.observe(modalContainerElement, {
          childList: true,
          subtree: true,
        });
      }
    },
    [addModal, updateModalData, addModalIDToHideOnRouteChange],
  );

  const isModalShown = useCallback(
    (id) => {
      const isShown = modals?.some(({ id: modalId }) => modalId === id);

      return isShown;
    },
    [modals],
  );

  const getModalData = useCallback(
    (id) => {
      const modal = modals?.find(({ id: modalId }) => modalId === id);

      return modal?.data || {};
    },
    [modals],
  );

  useEffect(() => {
    if (modalsRef.current !== modals) {
      modalsRef.current = modals;
    }
  }, [modals]);

  return {
    modals,
    showModal,
    hideModal,
    updateModalData,
    isModalShown,
    getModalData,
  };
};

export default useModal;
