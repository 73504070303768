import styled, { css } from 'styled-components';

export const DiagramImage = styled.img`
  width: 100%;
  height: 158px;
`;

export const DiagramRow = styled.div`
  display: block;
  width: 100%;

  ${({ theme: { spacing } }) => css`
    padding: 0 ${spacing.level5} 2px ${spacing.level5};
  `}
`;

export const DiagramRowText = styled.div`
  width: 100%;
  text-align: left;
  color: #616161;
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;
