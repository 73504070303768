import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from '@hooks/useOnClickOutside';
import Field from '@components/1-atoms/Field';
import { Wrapper, SearchResults, SearchResultsItem } from './Search.styled';

const Search = ({
  dataTestId = 'search',
  autoComplete,
  autoFocus,
  className,
  disabled,
  id,
  name,
  onBlur,
  onChange,
  onClear,
  onFocus,
  onKeyPress,
  placeholder = 'Search',
  value = '',
  searchResults,
}) => {
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef();

  const withSearchResults = !disabled && !!searchResults?.length;
  const showSearchResults = focused && withSearchResults;
  let optionalFieldProps = {};

  if (value && onClear) {
    optionalFieldProps = {
      endingButtonIconName: 'close_0',
      onEndingButtonClick: onClear,
    };
  }

  const handleFocus = useCallback(() => {
    setFocused(true);
    onFocus?.();
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    if (focused) {
      setFocused(false);
    }

    onBlur?.();
  }, [focused, onBlur]);

  useOnClickOutside(wrapperRef, handleBlur);

  return (
    <Wrapper
      ref={wrapperRef}
      data-testid={dataTestId}
      className={className}
      withSearchResults={showSearchResults}
    >
      <Field
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={className}
        dataTestId={`${dataTestId}__field`}
        disabled={disabled}
        id={id}
        leadingIconName="search_0"
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        value={value}
        {...optionalFieldProps}
      />

      {showSearchResults && (
        <SearchResults
          data-testid={`${dataTestId}__search-results`}
          withSearchResults
        >
          {searchResults.map(({ id, title, onClick }) => (
            <SearchResultsItem
              key={id || title}
              data-testid={`${dataTestId}__search-results__item`}
              onClick={onClick}
            >
              {title}
            </SearchResultsItem>
          ))}
        </SearchResults>
      )}
    </Wrapper>
  );
};

Search.propTypes = {
  dataTestId: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ),
};

export default Search;
