import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;

  ${({ theme: { colors, spacing } }) => css`
    gap: ${spacing.level3};
    background-color: ${rgba(colors.reverse, 0.3)};
  `}
`;

export const Content = styled.div`
  flex: 1;
  display: flex;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level6};
  `}
`;

export const Details = styled.div`
  ${flexColumn}
  word-break: break-word;
`;

export const Icon = styled(SpriteSVG)`
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors.surface};
  `}
`;

export const Title = styled.h1`
  ${({ theme: { font, colors } }) => css`
    ${font.titleMedium}
    color: ${colors.surface};
  `}
`;

export const Description = styled.p`
  ${({ theme: { font, colors } }) => css`
    ${font.bodyMedium}
    color: ${colors.surface};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.level2};
  `}
`;

export const Wrapper = styled.div`
  ${flexCenterVertical}
  overflow: clip;
  position: relative;

  ${({ isFullScreenVariant, theme: { colors, spacing, borderRadius } }) => css`
    background-color: ${colors.surfaceContainerHighest};

    ${when(
      !isFullScreenVariant,
      `
        border-radius: ${borderRadius.large};
      `,
    )}

    ${ContentWrapper} {
      ${when(
        isFullScreenVariant,
        `
          padding: ${spacing.level4} ${spacing.level6};
        `,
        `
          padding: ${spacing.level6};
        `,
      )}
    }
  `}
`;
