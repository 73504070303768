import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import useProject from '@hooks/projects/useProject';
import endpoints from '@api/endpoints';
import { useCallback } from 'react';

export const workflowQueryKeys = {
  recentWorkflows: ['recentWorkflows'],
  publicWorkflows: ['showLoader', 'publicWorkflows'],
  projectWorkflows: (projectId) => ['projectWorkflows', projectId],
  workflow: (workflowId) => ['workflow', workflowId],
};

export default function useWorkflowQueries({
  projectId,
  workflowId,
  publicWorkflowsLimit,
} = {}) {
  const { getProject } = useProject();

  const recentWorkflowsQuery = useQuery({
    queryKey: workflowQueryKeys.recentWorkflows,
    queryFn: async () =>
      await client
        .get(endpoints.concepts, {
          params: {
            sort: 'lastModifiedAt',
            limit: 15,
            onlyMine: true,
          },
        })
        .then((res) => res.data),
    enabled: false,
  });

  const publicWorkflowsQuery = useQuery({
    queryKey: workflowQueryKeys.publicWorkflows,
    queryFn: async () => {
      const params = {
        sort: 'createdAt',
      };

      if (publicWorkflowsLimit) {
        params.limit = publicWorkflowsLimit;
      }

      return await client
        .get(endpoints.conceptsPublic, { params })
        .then((res) => res.data);
    },
    enabled: false,
  });

  const getProjectWorkflowsQueryOptions = useCallback(
    (_projectId = projectId, showLoader = false) => {
      let queryKey = workflowQueryKeys.projectWorkflows(_projectId);

      if (showLoader) {
        queryKey = ['showLoader', ...queryKey];
      }

      return {
        queryKey,
        queryFn: async () => {
          let project = getProject(_projectId);

          if (!project) {
            project = await client
              .get(endpoints.workspace.replace(':workspaceId', _projectId))
              .then((res) => res.data);
          }

          return await client
            .get(endpoints.concepts, {
              params: {
                workspace: _projectId,
                organization: project?.publicAccess
                  ? project?.organization
                  : undefined,
              },
            })
            .then((res) => res.data);
        },
        enabled: !!_projectId,
      };
    },
    [getProject, projectId],
  );

  const projectWorkflowsQuery = useQuery(
    getProjectWorkflowsQueryOptions(projectId),
  );

  const workflowQuery = useQuery({
    queryKey: workflowQueryKeys.workflow(workflowId),
    queryFn: () =>
      client
        .get(endpoints.concept.replace(':conceptId', workflowId))
        .then((res) => res.data),
    enabled: !!workflowId,
    cacheTime: 0,
  });

  return {
    recentWorkflowsQuery,
    publicWorkflowsQuery,
    getProjectWorkflowsQueryOptions,
    projectWorkflowsQuery,
    workflowQuery,
  };
}
