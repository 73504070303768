import {
  PLINTH_KINEMATICS_LINEAR_RAIL,
  STATIC_PLINTH,
  DEFAULT_BASE_PLINTH,
  DEFAULT_PLINTH,
} from '../../constants/machineConstants';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import PlinthCustom from './PlinthCustom';
import LinearRailCustom from './LinearRailCustom';
import { Printer } from '../Printer';
import LinearRail from './LinearRail';
import Plinth from './Plinth';
import { plinthTypeDefinitions } from '../../constants/printers/plinthDefaultProperties';

export function getPlinth(
  printerSettings,
  machineDefaults,
  mountingType,
  plinthDefinitions,
) {
  const plinthTypeId = getPlinthType(
    printerSettings,
    machineDefaults,
    mountingType,
  );

  const isCustom = isPlinthCustom(plinthTypeId);

  return isCustom
    ? getCustomPlinth(printerSettings, plinthDefinitions)
    : getNonCustomPlinth(
        plinthDefinitions,
        plinthTypeId,
        printerSettings,
        machineDefaults,
        mountingType,
      );
}

function getCustomPlinth(printerSettings, plinthDefinitions) {
  const plinthDefinitionResponse = getPlinthDefinitionResponse(printerSettings);
  const { plinthType } = plinthDefinitionResponse;

  switch (plinthType) {
    case plinthTypeDefinitions.STATIC_PLINTH.value:
      return new PlinthCustom(printerSettings, plinthDefinitions);
    case plinthTypeDefinitions.RAIL_PLINTH.value:
      return new LinearRailCustom(printerSettings, plinthDefinitions);
  }
}

export function getPlinthDefinitionResponse(printerResponse) {
  return Printer.getPrinterSettingValue(
    printerResponse,
    componentDiscriminators.PLINTH_RESPONSE_PROPERTY,
  );
}

function getNonCustomPlinth(
  plinthDefinitions,
  plinthTypeId,
  printerSettings,
  machineDefaults,
  mountingType,
) {
  const plinth = plinthDefinitions.find(
    (item) => item.displayName == plinthTypeId,
  );

  if (!plinth || plinth === '') return;
  const plinthKinematics = plinth.plinthKinematics
    ? plinth.plinthKinematics
    : STATIC_PLINTH;
  switch (plinthKinematics) {
    case PLINTH_KINEMATICS_LINEAR_RAIL:
      return new LinearRail(
        printerSettings,
        machineDefaults,
        plinthTypeId,
        mountingType,
        plinthDefinitions,
      );
    case STATIC_PLINTH:
      return new Plinth(
        printerSettings,
        machineDefaults,
        plinthTypeId,
        plinthDefinitions,
      );
    default:
      //eslint-disable-next-line
      console.error('Unsupported plinth kinematics');
      return null;
  }
}

function getPlinthType(printerSettings, machineDefaults, mountingType) {
  const plinthType = Printer.getPrinterSettingValueOrDefault(
    printerSettings,
    constants.plinthType,
    DEFAULT_PLINTH,
  );
  const basePlinthType = Printer.getPrinterSettingValueOrDefault(
    printerSettings,
    constants.basePlinthType,
    DEFAULT_BASE_PLINTH,
  );
  return mountingType === constants.ROBOT ? plinthType : basePlinthType;
}

export function isPlinthCustom(plinthTypeId) {
  return plinthTypeId.toUpperCase() === 'CUSTOM';
}

export function getPlinthHeight(printerSettings, plinthTypeId, plinthSettings) {
  if (isPlinthCustom(plinthTypeId)) {
    const plinthDefinitionResponse =
      getPlinthDefinitionResponse(printerSettings);
    return plinthDefinitionResponse.plinthHeight;
  }

  return plinthTypeId ? plinthSettings.height : 0;
}
