import getIntlProvider from '@app/utils/getIntlProvider';

export const positiveDecimalRegExp = /^(\d+(\.\d)?)(\d+)?$/;

export const positiveOptionalDecimalRegExp = /^\d*(\.)?(\d)?$/;

export const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const positiveIntegerRegExp = /^\d*?$/;

export const positiveOptionalDecimalInputPattern = '^\\d*?(\\.)?(\\d)?$';

export const positiveIntegerInputPattern = '^\\d*$';

export const decimalInputPattern = '^(-?(\\d+(\\.?(\\d+)?)?)?)$';

export const integerInputPattern = '^-?[\\d]*$';

export const stringInputPattern = '^.{0,120}$';

export const isDecimal = (input = '') => input?.match(/^(-?\d+(.?\d+)?)?$/);

export const isInteger = (input = '') => input?.match(/^(-?\d*)$/);

export const OPERATOR_VALUE_TYPE_SELECTION = 'SELECTION';

export const getInvalidInputHint = (
  operators = [],
  operator = {},
  inputName = '',
) => {
  const intl = getIntlProvider();

  try {
    const { hasInterval, hasValidRange, defaultOperatorSetting, isSelection } =
      checkInputValidity(operators, operator, inputName);

    if (hasValidRange) {
      if (hasInterval) {
        const min = parseFloat(defaultOperatorSetting.allowedValues[0]);
        const max = parseFloat(defaultOperatorSetting.allowedValues[1]);

        return intl.formatMessage(
          {
            id: 'validation.value_must_be_between',
            defaultMessage: 'Value must be between ' + min + ' and ' + max,
          },
          {
            min,
            max,
          },
        );
      }

      if (isSelection) {
        return intl.formatMessage({
          id: 'validation.item_isnt_valid',
          defaultMessage:
            'Selected item is not valid anymore, please select a valid option',
        });
      }
    }
    return '';
  } catch (exception) {
    return '';
  }
};

export const checkInputValidity = (
  operators = [],
  operator = {},
  inputName = '',
) => {
  const defaultOperator = operators.find(({ name }) => operator.name === name);

  //if the operator has been deleted from aisync-core then all the inputs will be hidden.
  if (!defaultOperator) throw new Error('Unkown operator!');

  const defaultOperatorSetting = defaultOperator.settings?.find(
    ({ name }) => inputName === name,
  );

  if (!defaultOperatorSetting) {
    return {
      defaultOperatorSetting: {},
      hasValidRange: false,
      hasInterval: false,
      isSelection: false,
    };
  }

  const isSelection =
    defaultOperatorSetting.type === OPERATOR_VALUE_TYPE_SELECTION;
  const hasInterval =
    defaultOperatorSetting.allowedValues?.length === 2 &&
    !isNaN(defaultOperatorSetting.allowedValues[0]) &&
    !isNaN(defaultOperatorSetting.allowedValues[1]);

  const hasValidRange = isSelection || hasInterval;
  return {
    defaultOperatorSetting: defaultOperatorSetting,
    hasValidRange: hasValidRange,
    hasInterval: hasInterval,
    isSelection: isSelection,
  };
};

export const isPasswordComplexEnough = (password) => {
  if (!password || password.length < 8 || password.length > 25) {
    return false;
  }

  const pattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=\S+$).{8,}$/;
  return pattern.test(password);
};
