import { PrinterCategories } from './printerCategories';
import { UserRoles } from './userRoles';
import { ValueTypes } from './valueTypes';

export const defaultGantrySettings = [
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 X-Direction',
    parentValueRestrictions: null,
    settingName: 'basis1DirectionX',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 1.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 Y-Direction',
    parentValueRestrictions: null,
    settingName: 'basis1DirectionY',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 1 Z-Direction',
    parentValueRestrictions: null,
    settingName: 'basis1DirectionZ',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 X-Direction',
    parentValueRestrictions: null,
    settingName: 'basis2DirectionX',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 Y-Direction',
    parentValueRestrictions: null,
    settingName: 'basis2DirectionY',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 1.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 2 Z-Direction',
    parentValueRestrictions: null,
    settingName: 'basis2DirectionZ',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 X-Direction',
    parentValueRestrictions: null,
    settingName: 'basis3DirectionX',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 Y-Direction',
    parentValueRestrictions: null,
    settingName: 'basis3DirectionY',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 0.0,
    valueType: ValueTypes.DECIMAL,
  },
  {
    allowedValues: null,
    category: PrinterCategories.PRINTER_ROBOT_KINEMATICS,
    children: [],
    description: 'TODO',
    displayName: 'Axis 3 Z-Direction',
    parentValueRestrictions: null,
    settingName: 'basis3DirectionZ',
    authorizedRoles: [UserRoles.SUPER_ADMIN],
    value: 1.0,
    valueType: ValueTypes.DECIMAL,
  },
];
