import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import NavigationHeader, {
  NAVIGATION_HEADER_VARIANT_DEFAULT,
  NAVIGATION_HEADER_VARIANT_LARGE_TITLE,
} from '@components/2-molecules/NavigationHeader';
import ActionFooter from '@components/2-molecules/ActionFooter';
import {
  whenEscapeButtonPressed,
  preventDefault,
  stopPropagation,
} from '@utils/interactionEvents';
import { setApplicationScrollable } from '@stylesheets/helpers';
import { OuterWrapper, InnerWrapper, Content } from './Dialog.styled';

export const DIALOG_VARIANT_DEFAULT = 'default';
export const DIALOG_VARIANT_SIDE_VIEW = 'sideView';

export const DIALOG_SIZE_VARIANT_DEFAULT = 'default';
export const DIALOG_SIZE_VARIANT_MEDIUM = 'medium';

const Dialog = ({
  children,
  className,
  closeOnClickOutside,
  closeOnEscape,
  contentId = 'dialog__content',
  dataTestId = 'dialog',
  disableContentPadding,
  disabled,
  headerEndingIconButtonDisabled,
  headerLeadingIconButtonDisabled,
  headerTitle,
  hideOuterWrapper,
  loading,
  onBackIconButtonClick,
  onClose,
  onCloseIconButtonClick,
  onPrimaryButtonClick, // This is a prop isn't executed when renderDialogAs is 'form'
  onSecondaryButtonClick,
  onSubmit, // This is a prop is executed only when renderDialogAs is 'form'
  primaryButtonDisabled,
  primaryButtonIconName,
  primaryButtonLabel,
  primaryButtonLoading,
  renderAsForm,
  secondaryButtonDisabled,
  secondaryButtonIconName,
  secondaryButtonLabel,
  show,
  size,
  variant = DIALOG_VARIANT_DEFAULT,
  withFooterDivider,
  zIndex,
}) => {
  const outerWrapperRef = useRef();
  const innerWrapperRef = useRef();
  const sideViewVariant = variant === DIALOG_VARIANT_SIDE_VIEW;

  const disableActions = disabled || loading;

  const onKeyDown = useCallback(
    (e) => {
      closeOnEscape && whenEscapeButtonPressed(onClose)(e);
    },
    [closeOnEscape, onClose],
  );

  const onClickOutside = useCallback(
    (e) => {
      const isClickOutside = e.target === outerWrapperRef.current;

      if (!closeOnClickOutside || !isClickOutside) return;

      onClose?.();
    },
    [closeOnClickOutside, onClose],
  );

  useEffect(() => {
    if (show) {
      innerWrapperRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    if (!closeOnEscape) return;

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [closeOnEscape, onKeyDown]);

  useEffect(() => {
    if (show) {
      setApplicationScrollable(false);
    } else {
      setApplicationScrollable(true);
    }

    return () => {
      setApplicationScrollable(true);
    };
  }, [show]);

  return (
    <OuterWrapper
      className={className}
      data-testid={dataTestId}
      hideOuterWrapper={hideOuterWrapper}
      onClick={stopPropagation(preventDefault(onClickOutside))}
      ref={outerWrapperRef}
      show={show}
      zIndex={zIndex}
    >
      <InnerWrapper
        as={renderAsForm ? 'form' : undefined}
        onClick={stopPropagation(preventDefault)}
        onSubmit={preventDefault(onSubmit)}
        ref={innerWrapperRef}
        show={show}
        sideViewVariant={sideViewVariant}
        mediumSizeVariant={size === DIALOG_SIZE_VARIANT_MEDIUM}
        tabIndex={0}
      >
        {(onBackIconButtonClick || onCloseIconButtonClick) && (
          <NavigationHeader
            dataTestId={`${dataTestId}__navigation-header`}
            variant={
              !onBackIconButtonClick && headerTitle
                ? NAVIGATION_HEADER_VARIANT_LARGE_TITLE
                : NAVIGATION_HEADER_VARIANT_DEFAULT
            }
            title={headerTitle}
            leadingIconButtonDisabled={
              headerLeadingIconButtonDisabled || disableActions
            }
            leadingIconButtonIconName={
              onBackIconButtonClick ? 'arrow_back_0' : undefined
            }
            endingIconButtonDisabled={
              headerEndingIconButtonDisabled || disableActions
            }
            endingIconButtonIconName={
              onCloseIconButtonClick ? 'close_0' : undefined
            }
            onLeadingIconButtonClick={onBackIconButtonClick}
            onEndingIconButtonClick={onCloseIconButtonClick}
          />
        )}

        <Content
          id={contentId}
          data-testid={`${dataTestId}__content`}
          disableContentPadding={disableContentPadding}
        >
          {children}
        </Content>

        {(primaryButtonLabel || secondaryButtonLabel) && (
          <ActionFooter
            dataTestId={`${dataTestId}__action-footer`}
            onPrimaryButtonClick={
              renderAsForm ? undefined : onPrimaryButtonClick
            }
            onSecondaryButtonClick={onSecondaryButtonClick}
            primaryButtonDisabled={primaryButtonDisabled || disabled}
            primaryButtonIconName={primaryButtonIconName}
            primaryButtonLabel={primaryButtonLabel}
            primaryButtonLoading={primaryButtonLoading || loading}
            primaryButtonType={renderAsForm ? 'submit' : undefined}
            secondaryButtonDisabled={secondaryButtonDisabled || disableActions}
            secondaryButtonIconName={secondaryButtonIconName}
            secondaryButtonLabel={secondaryButtonLabel}
            withDivider={withFooterDivider}
          />
        )}
      </InnerWrapper>
    </OuterWrapper>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  contentId: PropTypes.string,
  dataTestId: PropTypes.string,
  disableContentPadding: PropTypes.bool,
  disabled: PropTypes.bool,
  headerEndingIconButtonDisabled: PropTypes.bool,
  headerLeadingIconButtonDisabled: PropTypes.bool,
  headerTitle: PropTypes.string,
  hideOuterWrapper: PropTypes.bool,
  loading: PropTypes.bool,
  onBackIconButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  onCloseIconButtonClick: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonIconName: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  primaryButtonLoading: PropTypes.bool,
  renderAsForm: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonIconName: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  show: PropTypes.bool,
  size: PropTypes.oneOf([
    DIALOG_SIZE_VARIANT_DEFAULT,
    DIALOG_SIZE_VARIANT_MEDIUM,
  ]),
  variant: PropTypes.oneOf([DIALOG_VARIANT_DEFAULT, DIALOG_VARIANT_SIDE_VIEW]),
  withFooterDivider: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default Dialog;
