import DialogPortal from '@components/2-molecules/DialogPortal';
import usePrinter from '@hooks/printers/usePrinter';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import React, { useCallback, useMemo } from 'react';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import { useDispatch } from 'react-redux';
import { Formik, Field as FormikField } from 'formik';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { updateVisibility } from '@actions/printerActions';
import useNotifications from '@hooks/useNotifications';
import { sortByAttribute } from '@utils/commonFunctions';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';

const MODAL_ID = ModalDataTypes.MANAGE_VISIBILITY;

const ManageVisibilityDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const dispatch = useDispatch();
  const { addNotification } = useNotifications();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const { getAllPrinterDefinitions } = usePrinter();
  const {
    machineDefinitions,
    extruderDefinitions,
    printingBedDefinitions,
    plinthDefinitions,
    enclosureDefinitions,
    programTypeDefinitions,
  } = getAllPrinterDefinitions();

  const { allPrinterDefinitionsQuery } = usePrinterQueries();

  const mapDbConfigToFormInitialValues = useCallback(
    (settingName) => {
      let definitions = [];
      switch (settingName) {
        case constants.robotType:
          definitions = machineDefinitions;
          break;
        case constants.extruderType:
          definitions = extruderDefinitions;
          break;
        case constants.enclosureType:
          definitions = enclosureDefinitions;
          break;
        case constants.printingBedType:
          definitions = printingBedDefinitions;
          break;
        case constants.plinthType:
        case constants.basePlinthType:
          definitions = plinthDefinitions;
          break;
        case constants.programType:
          definitions = programTypeDefinitions;
          break;
      }

      return definitions.reduce((acc, definition) => {
        acc[definition.displayName] =
          definition.visibleToAll || definition.visibleToOrganization;
        return acc;
      }, {});
    },
    [
      enclosureDefinitions,
      programTypeDefinitions,
      plinthDefinitions,
      printingBedDefinitions,
      machineDefinitions,
      extruderDefinitions,
    ],
  );

  const formInitialValues = useMemo(() => {
    return (
      dialogData?.settingName &&
      mapDbConfigToFormInitialValues(dialogData?.settingName)
    );
  }, [mapDbConfigToFormInitialValues, dialogData?.settingName]);

  const renderContent = (settingName, values, setFieldValue) => {
    let definitions = [];
    switch (settingName) {
      case constants.robotType:
        definitions = machineDefinitions;
        break;
      case constants.extruderType:
        definitions = extruderDefinitions;
        break;
      case constants.enclosureType:
        definitions = enclosureDefinitions;
        break;
      case constants.printingBedType:
        definitions = printingBedDefinitions;
        break;
      case constants.plinthType:
      case constants.basePlinthType:
        definitions = plinthDefinitions;
        break;
      case constants.programType:
        definitions = programTypeDefinitions;
        break;
    }

    return sortByAttribute(definitions, 'displayName').map((definition) => (
      <FormikField
        component={SettingCheckbox}
        key={`visibility-${definition.displayName}`}
        dataTestId={`printer-manage-visibility__field-${definition.displayName}`}
        checkbox={{
          disabled: definition.visibleToAll,
          checked: values[definition.displayName],
          onChange: () => {
            setFieldValue(
              `['${definition.displayName}']`,
              !values[definition.displayName],
            );
          },
        }}
        name={`['${definition.displayName}']`}
        label={definition.displayName}
      />
    ));
  };

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const handleVisibilityCallback = useCallback(
    (message) => {
      addNotification({
        message: message,
        primaryButtonClickHandler: () => {},
        visibilityTimeout: 4000,
      });
      handleClose();
      allPrinterDefinitionsQuery.refetch();
    },
    [allPrinterDefinitionsQuery, handleClose, addNotification],
  );

  const onFormSubmit = useCallback(
    (values) =>
      (async () => {
        dispatch(
          updateVisibility(
            dialogData?.settingName,
            values,
            handleVisibilityCallback,
          ),
        );
      })(),
    [dispatch, dialogData, handleVisibilityCallback],
  );

  return (
    <Formik initialValues={formInitialValues} onSubmit={onFormSubmit}>
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <DialogPortal
          renderAsForm
          dataTestId="printer-manage-visibility"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.done',
            defaultMessage: 'Done',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'dialogs.title.manage.visibility',
              defaultMessage: 'Manage visibility',
            })}
            subtitle={intl.formatMessage({
              id: 'dialogs.subtitle.manage.visibility',
              defaultMessage:
                'Select the component that you wish to make public and visibile to all users within this organisation',
            })}
          />

          {renderContent(dialogData?.settingName, values, setFieldValue)}
        </DialogPortal>
      )}
    </Formik>
  );
};

ManageVisibilityDialog.propTypes = {};

export default ManageVisibilityDialog;
