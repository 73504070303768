import { printerConstants as constants } from '@constants/printers/printerConstants';
import { Printer } from '../Printer';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators';
import TwoAxisPositionerCustom from './TwoAxisPositionerCustom';
import RotaryTableCustom from './RotaryTableCustom';
import {
  DEFAULT_PRINTING_BED,
  ROTARY_BED_KINEMATICS,
  STATIC_BED_KINEMATICS,
  TWO_AXIS_POSITIONER_BED_KINEMATICS,
} from '../../constants/machineConstants';
import { TwoAxisPositioner } from './TwoAxisPositioner';
import { RotaryTable } from './RotaryTable';
import { Bed } from './Bed';
import BedCustom from './BedCustom';
import { bedTypeDefinitions } from '../../constants/printers/printingBedDefaultProperties';

/**
 * Constructs the correct type of bed dependent on the robot definitions
 * @param {*} printerSettings
 * @param {*} machineDefaults
 * @returns
 */
export default function getBed(
  printerSettings,
  machineDefaults,
  printingBedDefinitions,
  plinthDefinitions,
) {
  const bedTypeId = Printer.getPrinterSettingValueOrDefault(
    printerSettings,
    constants.printingBedType,
    DEFAULT_PRINTING_BED,
  );

  const isCustom = isBedCustom(bedTypeId);

  return isCustom
    ? getCustomBed(printerSettings, printingBedDefinitions, plinthDefinitions)
    : getNonCustomBed(
        bedTypeId,
        printerSettings,
        machineDefaults,
        printingBedDefinitions,
        plinthDefinitions,
      );
}

function getCustomBed(
  printerSettings,
  printingBedDefinitions,
  plinthDefinitions,
) {
  const bedDefinitionResponse = getBedDefinitionResponse(printerSettings);
  const { bedType } = bedDefinitionResponse;
  switch (bedType) {
    case bedTypeDefinitions.ROTARY_BED.value:
      return new RotaryTableCustom(
        printerSettings,
        printingBedDefinitions,
        plinthDefinitions,
      );
    case bedTypeDefinitions.TWO_AXIS_BED.value:
      return new TwoAxisPositionerCustom(
        printerSettings,
        printingBedDefinitions,
        plinthDefinitions,
      );
    case bedTypeDefinitions.STATIC_BED.value:
      return new BedCustom(
        printerSettings,
        printingBedDefinitions,
        plinthDefinitions,
      );
  }
}

function getNonCustomBed(
  bedTypeId,
  printerSettings,
  machineDefaults,
  printingBedDefinitions,
  plinthDefinitions,
) {
  const bedKinematics = getBedKinematicType(bedTypeId, printingBedDefinitions);
  return getBedFromKinematicType(
    bedKinematics,
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  );
}

function getBedFromKinematicType(
  bedKinematics,
  printerSettings,
  machineDefaults,
  printingBedDefinitions,
  plinthDefinitions,
) {
  switch (bedKinematics) {
    case TWO_AXIS_POSITIONER_BED_KINEMATICS:
      return new TwoAxisPositioner(
        printerSettings,
        machineDefaults,
        printingBedDefinitions,
        plinthDefinitions,
      );
    case ROTARY_BED_KINEMATICS:
      return new RotaryTable(
        printerSettings,
        machineDefaults,
        printingBedDefinitions,
        plinthDefinitions,
      );
    case STATIC_BED_KINEMATICS:
    default:
      return new Bed(
        printerSettings,
        machineDefaults,
        printingBedDefinitions,
        plinthDefinitions,
      );
  }
}

/**
 *
 * @param {*} selectedPrinter (not printer response)
 * @returns
 */
export function getBedKinematics(selectedPrinter, printingBedDefinitions) {
  const { printingBedType } = selectedPrinter;
  if (isBedCustom(printingBedType)) {
    const { bedDefinitionResponse } = selectedPrinter;
    switch (bedDefinitionResponse.bedType) {
      case bedTypeDefinitions.ROTARY_BED.value:
        return ROTARY_BED_KINEMATICS;
      case bedTypeDefinitions.TWO_AXIS_BED.value:
        return TWO_AXIS_POSITIONER_BED_KINEMATICS;
      case bedTypeDefinitions.STATIC_BED.value:
        return STATIC_BED_KINEMATICS;
    }
  } else return getBedKinematicType(printingBedType, printingBedDefinitions);
}

function getBedKinematicType(bedTypeId, printingBedDefinitions) {
  if (!bedTypeId || bedTypeId === '') return null;
  const printingBedDefaults = printingBedDefinitions.find(
    (item) => item.displayName == bedTypeId,
  );

  return printingBedDefaults?.bedKinematics;
}

/**
 * Parses the custom bed definition from the printer response.
 * @param {Object} printerResponse serialized printer response
 * @returns
 */
export function getBedDefinitionResponse(printerResponse) {
  return Printer.getPrinterSettingValue(
    printerResponse,
    componentDiscriminators.BED_RESPONSE_PROPERTY,
  );
}

export function isBedCustom(bedTypeId) {
  return bedTypeId === 'CUSTOM';
}
