import styled, { css } from 'styled-components';
import Text, {
  TEXT_VARIANT_LABEL_LARGE,
  TEXT_VARIANT_TITLE_LARGE,
} from '@components/1-atoms/Text';
import ButtonDropDownComponent from '@components/1-atoms/ButtonDropDown';
import IconButton from '@components/1-atoms/IconButton';
import { when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 72px;
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  grid-template-areas: 'leading title ending';
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;

  ${({ largeTitleVariant, theme: { spacing } }) => css`
    padding: ${spacing.level4};

    ${when(
      largeTitleVariant,
      `
        grid-template-columns: 0 1fr 48px;
        padding-left: ${spacing.level6};
      `,
    )}
  `}
`;

export const Title = styled(Text).attrs(({ largeTitleVariant }) => {
  let textVariant = TEXT_VARIANT_LABEL_LARGE;

  if (largeTitleVariant) {
    textVariant = TEXT_VARIANT_TITLE_LARGE;
  }

  return {
    variant: textVariant,
    color: 'onSurface',
  };
})`
  max-width: 100%;
  grid-area: title;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ defaultVariant }) => css`
    ${when(
      defaultVariant,
      `
        margin: 0 auto;
      `,
    )}
  `}
`;

export const LeadingIconButton = styled(IconButton)`
  grid-area: leading;
`;

export const ButtonDropDown = styled(ButtonDropDownComponent)`
  grid-area: title;
  margin: 0 auto;
`;

export const EndingIconButton = styled(IconButton)`
  grid-area: ending;
  position: relative;
  z-index: 1;
`;
