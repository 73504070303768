import styled, { css } from 'styled-components';
import { flexColumn, when } from '@app/stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  ${flexColumn};

  ${({ withPaddingBottom, theme: { spacing } }) => css`
    ${when(
      withPaddingBottom,
      `
        padding-bottom: ${spacing.level6};
      `,
    )}
  `}
`;

export const ContentWrapper = styled.div`
  overflow: auto;
`;

export const BannerWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.level9};
  `}
`;
