export const extruderSettingsTypes = {
  Default: {
    name: 'DEFAULT',
  },
  ExtruderSettingsType1: {
    name: 'AI_MAKER_V5',
  },
  ExtruderSettingsType2: {
    name: 'DYZE_TYPHOON',
  },
  ExtruderSettingsType3: {
    name: 'WEBER_AE16',
  },
  ExtruderSettingsType4: {
    name: 'WEBER_AE16_V2',
  },
  ExtruderSettingsType5: {
    name: 'WEBER_AE20_V2',
  },
  ExtruderSettingsType6: {
    name: 'WEBER_AE20_V21',
  },
  ExtruderSettingsType7: {
    name: 'WEBER_AE30',
  },
  ExtruderSettingsType8: {
    name: 'WEBER_LUDWIG',
  },
  ExtruderSettingsType9: {
    name: 'WEBER_TWIN',
  },
  ExtruderSettingsType10: {
    name: 'CEAD_E25',
  },
  ExtruderSettingsType11: {
    name: 'FHNW',
  },
  ExtruderSettingsType12: {
    name: 'MELTIO_ENGINE',
  },
  ExtruderSettingsType13: {
    name: 'REHAU',
  },
  ExtruderSettingsType15: {
    name: 'POLYPRODUCTS',
  },
  ExtruderSettingsType16: {
    name: 'ULTIMAKER_S5',
  },
  ExtruderSettingsType17: {
    name: 'SIKA_RETO',
  },
  ExtruderSettingsType18: {
    name: 'SIKA_YIZUMI',
  },
  ExtruderSettingsType19: {
    name: 'DYZE_MDT',
  },
  ExtruderSettingsType20: {
    name: 'MDT',
  },
  ExtruderSettingsType21: {
    name: 'ALPHA_ADDITIVE',
  },
  ExtruderSettingsType22: {
    name: 'ONE_OFF',
  },
  ExtruderSettingsType23: {
    name: 'OSU',
  },
  ExtruderSettingsType24: {
    name: 'ZAL',
  },
  ExtruderSettingsType25: {
    name: 'EVENTSCAPE',
  },
  ExtruderSettingsType26: {
    name: 'PATTERNS_AND_MOULDS',
  },
  ExtruderSettingsType29: {
    name: 'EATON',
  },
  ExtruderSettingsType30: {
    name: 'EVO3D',
  },
  ExtruderSettingsType32: {
    name: 'RECOZY_YIZUMI',
  },
  ExtruderSettingsType33: {
    name: 'TOTAL_MACHINING',
  },
  ExtruderSettingsType34: {
    name: 'WOHN',
  },
  ExtruderSettingsType35: {
    name: 'NIKON',
  },
  ExtruderSettingsType36: {
    name: 'AC3D',
  },
  ExtruderSettingsType37: {
    name: 'CENTRIA_STATIC',
  },
  ExtruderSettingsType38: {
    name: 'CENTRIA_POSITIONER',
  },
  ExtruderSettingsType39: {
    name: 'PROTOTYP3',
  },
  ExtruderSettingsType40: {
    name: 'TENNESSEE',
  },
  ExtruderSettingsType41: {
    name: 'FRONIUS',
  },
  ExtruderSettingsType42: {
    name: 'LSAM',
  },
  ExtruderSettingsType43: {
    name: 'DASH_CAE',
  },
  ExtruderSettingsType44: {
    name: 'DASH_CAE2',
  },
  ExtruderSettingsType45: {
    name: 'AZURE',
  },
  ExtruderSettingsType46: {
    name: 'NLR',
  },
  ExtruderSettingsType47: {
    name: 'INGERSOLL',
  },
  ExtruderSettingsType48: {
    name: 'INGERSOLL_ROTATED',
  },
  ExtruderSettingsType49: {
    name: 'FABPUB',
  },
  ExtruderSettingsType50: {
    name: 'MWO_I_THREE_JET',
  },
  ExtruderSettingsType51: {
    name: 'MWO_I_COAX',
  },
  ExtruderSettingsType52: {
    name: 'HADDY',
  },
  ExtruderSettingsType53: {
    name: 'INOVENTIVE',
  },
  ExtruderSettingsType54: {
    name: 'BELOTTI',
  },
  ExtruderSettingsType55: {
    name: 'DUROTHERM',
  },
  ExtruderSettingsType56: {
    name: 'FORMALLOY',
  },
  ExtruderSettingsType57: {
    name: 'ESI',
  },
  ExtruderSettingsType58: {
    name: 'DEEP',
  },
  ExtruderSettingsType59: {
    name: 'RADIUS',
  },
  ExtruderSettingsType60: {
    name: 'SPARK',
  },
  ExtruderSettingsType61: {
    name: 'AIMEN',
  },
  ExtruderSettingsType62: {
    name: 'AIMEN_DYZE',
  },
  ExtruderSettingsType64: {
    name: 'ADDITIVE_SPACE',
  },
  ExtruderSettingsType65: {
    name: 'TANYO',
  },
  ExtruderSettingsType66: {
    name: 'WASP',
  },
  ExtruderSettingsType67: {
    name: 'ULSAN',
  },
  ExtruderSettingsType68: {
    name: 'AIRTECH',
  },
  ExtruderSettingsType69: {
    name: 'VERTEX_DYNAMIX',
  },
  ExtruderSettingsType70: {
    name: 'ADDITEC',
  },
  ExtruderSettingsType71: {
    name: 'ARMSTRONG',
  },
  ExtruderSettingsType72: {
    name: 'TAKENAKA',
  },
  ExtruderSettingsType73: {
    name: 'LIMBER',
  },
  ExtruderSettingsType74: {
    name: 'EVO3D3',
  },
  ExtruderSettingsType75: {
    name: 'OSCAR',
  },
  ExtruderSettingsType76: {
    name: 'MSA',
  },
  ExtruderSettingsType77: {
    name: 'WEBER_AE16_V3',
  },
  ExtruderSettingsType78: {
    name: 'BLUE_ORIGIN',
  },
  ExtruderSettingsType79: {
    name: 'CEAD_DFC',
  },
  ExtruderSettingsType80: {
    name: 'BMW_ABB',
  },
  ExtruderSettingsType81: {
    name: 'XBEAM_3D',
  },
  ExtruderSettingsType82: {
    name: 'INGERSOLL_GANTRY',
  },
  ExtruderSettingsType83: {
    name: 'ANDRITZ',
  },
  ExtruderSettingsType84: {
    name: 'TMS',
  },
};
