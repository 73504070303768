export const isAnyFetchInProgess = (state) => {
  return !!state?.fetch?.inProgess?.length;
};

export const isFetchInProgess =
  (fetchItemName = '') =>
  (state) => {
    return state?.fetch?.inProgess?.includes(fetchItemName);
  };

export const isFetchCompleted =
  (fetchItemName = '') =>
  (state) => {
    return state?.fetch?.completed?.includes(fetchItemName);
  };
