export const machineConstants = {
  //NOTE: the discriminator values need to match the AiSync request objects
  typeDiscriminator: 'type',
  sixAxisRobotDiscriminator: 'SIX_AXIS_ROBOT',
  gantryDiscriminator: 'GANTRY',
  machineDefinitionResponse: 'machineDefinitionResponse',
  gantryType: 'gantryType',
  gantryType3Axis: 'GANTRY',
  gantryType5Axis: 'GANTRY_5_AXIS',
};
