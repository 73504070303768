import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import withTooltip from '@hoc/withTooltip';
import withDropDownMenu from '@hoc/withDropDownMenu';
import { whenEnterButtonPressed } from '@utils/interactionEvents';
import { Wrapper, Content, Icon } from './IconButton.styled';

export const ICON_BUTTON_VARIANT_PRIMARY = 'primary';
export const ICON_BUTTON_VARIANT_OUTLINE = 'outline';
export const ICON_BUTTON_VARIANT_STANDARD = 'standard';
export const ICON_BUTTON_VARIANT_TONAL = 'tonal';
export const ICON_BUTTON_VARIANT_PLAIN = 'plain';

const WrapperWithTooltip = withTooltip(Wrapper);

const IconButton = forwardRef(
  (
    {
      className,
      dataTestId = 'icon-button',
      disabled,
      iconColor,
      iconName,
      loading,
      onClick,
      renderAs,
      selected,
      submitType,
      tabIndex,
      title,
      tooltip = {},
      variant = ICON_BUTTON_VARIANT_STANDARD,
    },
    ref,
  ) => {
    const disableActions = disabled || loading;

    const WrapperComponent = useMemo(
      () => (isEmpty(tooltip) ? Wrapper : WrapperWithTooltip),
      [tooltip],
    );

    return (
      <WrapperComponent
        {...tooltip}
        as={renderAs}
        ref={ref}
        type={submitType ? 'submit' : 'button'}
        className={className}
        data-testid={dataTestId}
        disabled={disabled}
        $loading={loading}
        onClick={disableActions ? undefined : onClick}
        onKeyDown={renderAs ? whenEnterButtonPressed(onClick) : undefined}
        selected={selected}
        tabIndex={disableActions ? -1 : tabIndex}
        title={title}
        variant={variant}
      >
        <Content
          $loading={loading}
          disabled={disabled}
          selected={selected}
          variant={variant}
        >
          <Icon
            $loading={loading}
            disabled={disabled}
            iconColor={iconColor}
            name={loading ? 'progress_activity_0' : iconName}
            selected={selected}
            variant={variant}
          />
        </Content>
      </WrapperComponent>
    );
  },
);

IconButton.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  renderAs: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  selected: PropTypes.bool,
  submitType: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  tooltip: PropTypes.object,
  variant: PropTypes.oneOf([
    ICON_BUTTON_VARIANT_OUTLINE,
    ICON_BUTTON_VARIANT_PLAIN,
    ICON_BUTTON_VARIANT_PRIMARY,
    ICON_BUTTON_VARIANT_STANDARD,
    ICON_BUTTON_VARIANT_TONAL,
  ]),
};

IconButton.displayName = 'IconButton';

export default withDropDownMenu(IconButton);
