import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { projectQueryKeys } from '@hooks/projects/useProjectQueries';

export default function useProject() {
  const queryClient = useQueryClient();
  const { workspaceId: projectId } = useParams();

  const getProject = useCallback(
    (projectId) =>
      queryClient.getQueryData(projectQueryKeys.project(projectId)),
    [queryClient],
  );

  const getSelectedProject = useCallback(
    () => queryClient.getQueryData(projectQueryKeys.project(projectId)),
    [queryClient, projectId],
  );

  const getProjects = useCallback(
    () => queryClient.getQueryData(projectQueryKeys.projects),
    [queryClient],
  );

  return {
    getProject,
    getSelectedProject,
    getProjects,
  };
}
