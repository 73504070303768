import styled, { css, keyframes } from 'styled-components';
import Text, {
  TEXT_VARIANT_BODY_MEDIUM,
  TEXT_VARIANT_BODY_SMALL,
} from '@components/1-atoms/Text';
import { flexCenterVertical, when } from '@stylesheets/helpers';

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level6};
  `}
`;

export const Conversation = styled.div`
  height: 169px;
  ${flexColumn}
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;

  ${({ theme: { borderRadius, colors, spacing } }) => css`
    border-radius: ${borderRadius.extrasmall};
    background: ${colors.surfaceContainerLowest};
    padding: ${spacing.level6};
  `}
`;

export const Message = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurface',
})`
  word-break: break-all;
  overflow: hidden;
  position: relative;

  ${({ theme: { borderRadius, colors, spacing } }) => css`
    border-radius: ${borderRadius.full};
    background: ${colors.surfaceContainerHighest};
    padding: ${spacing.level2} ${spacing.level5};
  `}
`;

export const TypingMessage = styled.div`
  min-width: 60px;
  min-height: 28px;
  ${flexCenterVertical}
  justify-content: center;

  ${({ theme: { borderRadius, colors, spacing } }) => css`
    border-radius: ${borderRadius.full};
    background: ${colors.secondaryContainer};
    color: ${colors.onSecondaryContainer};
    padding: ${spacing.level2} ${spacing.level5};
  `}
`;

export const Time = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_SMALL,
  color: 'onSurfaceVariant',
})`
  margin-top: 6px;
  text-align: right;
`;

export const MessageBox = styled.div`
  max-width: calc(45%);
  ${flexColumn}
  margin-left: auto;

  &:first-child {
    margin-top: auto;
  }

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  ${TypingMessage} ~ ${Time} {
    text-align: left;
  }

  ${({ loader, answer, error }) => css`
    ${when(
      loader,
      css`
        margin-left: unset;
        margin-right: auto;
      `,
    )}

    ${when(
      answer,
      css`
        margin-left: unset;
        margin-right: auto;

        ${Message} {
          ${({ theme: { colors } }) => `
            background: ${colors.secondaryContainer};
            color: ${colors.onSecondaryContainer};
          `}
        }

        ${Time} {
          margin-right: auto;
        }
      `,
    )}

    ${when(
      error,
      css`
        ${Message} {
          ${({ theme: { colors } }) => `
            background: ${colors.error};
            color: ${colors.surfaceContainerLow};
          `}
        }
      `,
    )}
  `}
`;

const dotTyping = keyframes`
  0% {
    box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  }

  16.667% {
    box-shadow: 9984px -4px 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  }

  50% {
    box-shadow: 9984px 0 0 0 #fff, 9996px -4px 0 0 #fff, 10008px 0 0 0 #fff;
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px -4px 0 0 #fff;
  }

  100% {
    box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  }
`;

export const Typing = styled.div`
  position: relative;
  left: -9996px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
  color: #fff;
  box-shadow: 9984px 0 0 0 #fff, 9996px 0 0 0 #fff, 10008px 0 0 0 #fff;
  animation: ${dotTyping} 1.5s infinite linear;
`;
