import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexCenter, flexCenterVertical, when } from '@stylesheets/helpers';

export const OuterWrapper = styled.span`
  ${flexCenterVertical}
  flex-shrink: 0;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level2};
  `}
`;
export const Wrapper = styled.button`
  ${flexCenter}
  flex: 1 0 0;
  gap: 10px;
  background: none;
  border: 0;
  padding: 8px;
  cursor: pointer;

  ${({ disabled, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius?.full};

    &:hover {
      background-color: ${rgba(colors?.onSurfaceVariant, 0.08)};
    }

    &:focus:focus-visible {
      background-color: ${rgba(colors?.onSurfaceVariant, 0.12)};
    }

    ${when(
      disabled,
      `
        pointer-events: none;
        opacity: 0.38;
      `,
    )}
  `}
`;

export const LeadingIcon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors?.onSurface};
  `}
`;

export const EndingIcon = styled(SpriteSVG)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors?.onSurface};
  `}
`;
