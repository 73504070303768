import {
  FETCH_TOOLPATH_INSTRUCTIONS,
  FETCH_TOOLPATH_INSTRUCTIONS_FAILED,
  FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
  STREAM_TOOLPATH_INSTRUCTIONS,
  STREAM_TOOLPATH_INSTRUCTIONS_FAILED,
  STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
} from '@constants/actionTypes';
import { GET, POST } from '@constants/fetchMethods';
import { AuthenticatedFetch } from './AuthenticatedFetch';

export const fetchToolpathOperatorInstructions =
  (operatorId, skipError = false) =>
  (dispatch) =>
    dispatch(
      AuthenticatedFetch({
        url: `concepts/${operatorId}/instructions`,
        method: GET,
        disableErrorDialog: skipError,
        prefetchAction: {
          type: FETCH_TOOLPATH_INSTRUCTIONS,
          payload: {
            toolpathId: operatorId,
          },
        },
        successAction: (responseBody) => ({
          type: FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
          payload: {
            toolpathId: operatorId,
            instructionsURL: responseBody.instructionsURL,
          },
        }),
        failureAction: () => ({
          type: FETCH_TOOLPATH_INSTRUCTIONS_FAILED,
        }),
      }),
    );

export const streamToolpathOperatorToPrinter =
  (printerId, operatorId) => (dispatch) =>
    dispatch(
      AuthenticatedFetch({
        url: `printers/${printerId}/operators/${operatorId}`,
        method: POST,
        prefetchAction: {
          type: STREAM_TOOLPATH_INSTRUCTIONS,
        },
        successAction: () => ({
          type: STREAM_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
          payload: {
            toolpathId: operatorId,
          },
        }),
        failureAction: () => ({
          type: STREAM_TOOLPATH_INSTRUCTIONS_FAILED,
        }),
      }),
    );
