import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import PageList from '@components/3-organisms/PageList';

const PrintersPageContent = ({
  headerLeadingIconButtonIconName,
  loading,
  onHeaderLeadingIconButtonClick,
  printersListItems = [],
}) => {
  const intl = useIntl();
  const { showDialog } = useDialog();

  const showAddPrinterDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_PRINTER);
  }, [showDialog]);

  return (
    <PageList
      dataTestId="printers-page-content"
      headerTitle={intl.formatMessage({
        id: 'printerspage.printers_list.title',
        defaultMessage: 'Printers',
      })}
      listItems={printersListItems}
      headerLeadingIconButtonIconName={headerLeadingIconButtonIconName}
      onHeaderLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
      loading={loading}
      createItemMessageIconName="precision_manufacturing_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'printerspage.printers_list.add_printer_title',
        defaultMessage: 'No printer yet',
      })}
      createItemMessagDescription={intl.formatMessage({
        id: 'printerspage.printers_list.add_printer_description',
        defaultMessage: 'Manage the setup of your 3D printers',
      })}
      createItemMessageButtonLabel={intl.formatMessage({
        id: 'printerspage.printers_list.add_printer_button',
        defaultMessage: 'Add Printer',
      })}
      createItemMessageButtonIconName="add_0"
      createItemMessageOnButtonClick={showAddPrinterDialog}
      actionChips={[
        {
          id: 'printers-page-add-printer-chip',
          variant: CHIP_VARIANT_PRIMARY,
          dataTestId: 'printers-page__create-add-printer-chip',
          label: intl.formatMessage({
            id: 'printerspage.printers_list.action_chips.add_printer',
            defaultMessage: 'Add Printer',
          }),
          leadingIconName: 'add_0',
          onClick: showAddPrinterDialog,
        },
      ]}
      sortOptions={[
        {
          label: intl.formatMessage({
            id: 'general.date',
            defaultMessage: 'Date',
          }),
          value: 'createdAt',
        },
        {
          label: intl.formatMessage({
            id: 'general.name',
            defaultMessage: 'Name',
          }),
          value: 'label',
        },
      ]}
    />
  );
};

PrintersPageContent.propTypes = {
  headerLeadingIconButtonIconName: PropTypes.string,
  loading: PropTypes.bool,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  printersListItems: PropTypes.array,
};

export default PrintersPageContent;
