import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { dropDownMenuHOCProps } from '@hoc/withDropDownMenu';
import { preventDefault } from '@utils/interactionEvents';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import NavigationHeader from '@components/2-molecules/NavigationHeader';
import ActionFooter from '@components/2-molecules/ActionFooter';
import { OuterWrapper, InnerWrapper, Content } from './SettingBar.styled';

const SettingBar = forwardRef(
  (
    {
      children,
      className,
      dataTestId = 'setting-bar',
      disabled,
      footerMessage,
      footerMessageIconName,
      footerMessageVariant,
      footerPrimaryButtonDisabled,
      footerPrimaryButtonIconName,
      footerPrimaryButtonLabel,
      footerPrimaryButtonSubmitType,
      footerSecondaryButtonIconName,
      footerSecondaryButtonLabel,
      footerVariant,
      headerButtonDropdown,
      headerEndingIconButtonDisabled,
      headerEndingIconButtonDropDownProps = {},
      headerEndingIconButtonIconName = 'more_vert_0',
      headerEndingIconButtonRef,
      headerLeadingIconButtonDisabled,
      headerLeadingIconButtonIconName = 'thumbnail_bar_0',
      headerLeadingIconButtonRef,
      headerTitle,
      id,
      loading,
      onFooterPrimaryButtonClick, // This is a prop isn't executed when renderDialogAs is 'form'
      onFooterSecondaryButtonClick,
      onHeaderEndingIconButtonClick,
      onHeaderLeadingIconButtonClick,
      onSubmit, // This is a prop is executed only when renderDialogAs is 'form'
      renderAsForm,
      withFooterDivider,
    },
    ref,
  ) => {
    const disableAction = disabled || loading;

    return (
      <OuterWrapper ref={ref} data-testid={dataTestId} className={className}>
        <InnerWrapper
          id={id}
          as={renderAsForm ? 'form' : undefined}
          onSubmit={preventDefault(onSubmit)}
        >
          <NavigationHeader
            buttonDropdown={headerButtonDropdown}
            dataTestId={`${dataTestId}__navigation-header`}
            disabled={disableAction}
            endingIconButtonDisabled={headerEndingIconButtonDisabled}
            endingIconButtonDropDownProps={headerEndingIconButtonDropDownProps}
            endingIconButtonIconName={headerEndingIconButtonIconName}
            endingIconButtonRef={headerEndingIconButtonRef}
            leadingIconButtonDisabled={headerLeadingIconButtonDisabled}
            leadingIconButtonIconName={headerLeadingIconButtonIconName}
            leadingIconButtonRef={headerLeadingIconButtonRef}
            onEndingIconButtonClick={onHeaderEndingIconButtonClick}
            onLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
            title={headerTitle}
          />

          <Content data-testid={`${dataTestId}__content`}>{children}</Content>

          {(footerPrimaryButtonLabel || footerSecondaryButtonLabel) && (
            <ActionFooter
              dataTestId={`${dataTestId}__action-footer`}
              variant={footerVariant}
              message={footerMessage}
              messageLeadingIconName={footerMessageIconName}
              messageVariant={footerMessageVariant}
              onPrimaryButtonClick={
                renderAsForm ? undefined : onFooterPrimaryButtonClick
              }
              onSecondaryButtonClick={onFooterSecondaryButtonClick}
              primaryButtonDisabled={footerPrimaryButtonDisabled || disabled}
              primaryButtonIconName={footerPrimaryButtonIconName}
              primaryButtonLoading={loading}
              primaryButtonType={
                renderAsForm || footerPrimaryButtonSubmitType
                  ? 'submit'
                  : undefined
              }
              primaryButtonLabel={footerPrimaryButtonLabel}
              secondaryButtonDisabled={disableAction}
              secondaryButtonIconName={footerSecondaryButtonIconName}
              secondaryButtonLabel={footerSecondaryButtonLabel}
              withDivider={withFooterDivider}
            />
          )}
        </InnerWrapper>
      </OuterWrapper>
    );
  },
);

SettingBar.displayName = 'SettingBar';

SettingBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  footerVariant: ActionFooter.propTypes.variant,
  footerMessage: PropTypes.string,
  footerMessageIconName: ActionFooter.propTypes.messageLeadingIconName,
  footerMessageVariant: ActionFooter.propTypes.messageVariant,
  footerPrimaryButtonDisabled: PropTypes.bool,
  footerPrimaryButtonIconName: ActionFooter.propTypes.primaryButtonIconName,
  footerPrimaryButtonLabel: PropTypes.string,
  footerPrimaryButtonSubmitType: PropTypes.bool,
  footerSecondaryButtonIconName: ActionFooter.propTypes.secondaryButtonIconName,
  footerSecondaryButtonLabel: PropTypes.string,
  headerButtonDropdown: PropTypes.shape(ButtonDropDown.propTypes),
  headerEndingIconButtonIconName:
    NavigationHeader.propTypes.endingIconButtonIconName,
  headerEndingIconButtonRef: PropTypes.object,
  headerLeadingIconButtonIconName:
    NavigationHeader.propTypes.leadingIconButtonIconName,
  headerLeadingIconButtonRef: PropTypes.object,
  headerTitle: PropTypes.string,
  id: PropTypes.string,
  onFooterPrimaryButtonClick: PropTypes.func,
  onFooterSecondaryButtonClick: PropTypes.func,
  onHeaderEndingIconButtonClick: PropTypes.func,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  withFooterDivider: PropTypes.bool,
  headerEndingIconButtonDisabled: PropTypes.bool,
  headerEndingIconButtonDropDownProps: PropTypes.shape(dropDownMenuHOCProps),
  headerLeadingIconButtonDisabled: PropTypes.bool,
  renderAsForm: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SettingBar;
