import cadex from '@cadexchanger/web-toolkit';

export const ID_INPUT_SUFFIX = 'ID';
export const MODEL_INPUT_SUFFIX = 'Model';
export const BREP_OUTPUT_NAME = 'BrepOut';
export const BASE_CDXWEB_FILE_NAME = 'base.cdxweb';

export const SELECTION_MODE_NODE = 'NODE';
export const SELECTION_MODE_BODY = 'BODY';
export const SELECTION_MODE_SOLID = 'SOLID';
export const SELECTION_MODE_SHELL = 'SHELL';
export const SELECTION_MODE_FACE = 'FACE';
export const SELECTION_MODE_WIRE = 'WIRE';
export const SELECTION_MODE_EDGE = 'EDGE';
export const SELECTION_MODE_VERTEX = 'VERTEX';
export const SELECTION_MODE_POLY_SHAPE = 'POLY_SHAPE';
export const SELECTION_MODE_POLY_FACE = 'POLY_FACE';
export const SELECTION_MODE_POLY_LINE = 'POLY_LINE';
export const SELECTION_MODE_POLY_VERTEX = 'POLY_VERTEX';

export const SELECTION_MODES = {
  [SELECTION_MODE_NODE]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Node,
    translationKey: 'canvasselection.selection_type.node',
  },
  [SELECTION_MODE_BODY]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Body,
    translationKey: 'canvasselection.selection_type.body',
  },
  [SELECTION_MODE_SOLID]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Solid,
    translationKey: 'canvasselection.selection_type.solid',
  },
  [SELECTION_MODE_SHELL]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Shell,
    translationKey: 'canvasselection.selection_type.shell',
  },
  [SELECTION_MODE_FACE]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Face,
    translationKey: 'canvasselection.selection_type.face',
  },
  [SELECTION_MODE_WIRE]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Wire,
    translationKey: 'canvasselection.selection_type.wire',
  },
  [SELECTION_MODE_EDGE]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Edge,
    translationKey: 'canvasselection.selection_type.edge',
  },
  [SELECTION_MODE_VERTEX]: {
    cadexMode: cadex.ModelPrs_SelectionMode.Vertex,
    translationKey: 'canvasselection.selection_type.vertex',
  },
  [SELECTION_MODE_POLY_SHAPE]: {
    cadexMode: cadex.ModelPrs_SelectionMode.PolyShape,
    translationKey: 'canvasselection.selection_type.poly_shape',
  },
  [SELECTION_MODE_POLY_FACE]: {
    // not present in the cadex enum for some reason
    cadexMode: 512,
    translationKey: 'canvasselection.selection_type.poly_face',
  },
  [SELECTION_MODE_POLY_LINE]: {
    // not present in the cadex enum for some reason
    cadexMode: 1024,
    translationKey: 'canvasselection.selection_type.poly_line',
  },
  [SELECTION_MODE_POLY_VERTEX]: {
    cadexMode: cadex.ModelPrs_SelectionMode.PolyVertex,
    translationKey: 'canvasselection.selection_type.poly_vertex',
  },
};

export const SHAPE_TYPE_SELECTION_MODE_MAP = {
  [cadex.ModelData_ShapeType.Body]: SELECTION_MODE_BODY,
  [cadex.ModelData_ShapeType.Solid]: SELECTION_MODE_SOLID,
  [cadex.ModelData_ShapeType.Shell]: SELECTION_MODE_SHELL,
  [cadex.ModelData_ShapeType.Face]: SELECTION_MODE_FACE,
  [cadex.ModelData_ShapeType.Wire]: SELECTION_MODE_WIRE,
  [cadex.ModelData_ShapeType.Edge]: SELECTION_MODE_EDGE,
  [cadex.ModelData_ShapeType.Vertex]: SELECTION_MODE_VERTEX,
};
