import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';
import { getIsLoggedIn } from '@selectors/loginSelectors';

export default function useDeepLink() {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const deepLinkRef = useRef(currentPath);
  const isLoggedIn = useSelector(getIsLoggedIn());
  const previousIsLoggedIn = usePrevious(isLoggedIn);

  useEffect(() => {
    const deepLink = deepLinkRef.current;
    const justLoggedIn = !previousIsLoggedIn && isLoggedIn;
    const skip = !deepLink || !justLoggedIn;

    if (skip) {
      return;
    }

    history.push(deepLinkRef.current);
  }, [history, isLoggedIn, previousIsLoggedIn]);

  useEffect(() => {
    const deepLink = deepLinkRef.current;
    const justLoggedOut = previousIsLoggedIn && !isLoggedIn;
    const skip = !deepLink || !justLoggedOut;

    if (skip) {
      return;
    }

    deepLinkRef.current = null;
  }, [isLoggedIn, previousIsLoggedIn]);
}
