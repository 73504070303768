import React from 'react';
import useModal from '@hooks/useModal';
import PrinterSensor from '@containers/Modals/PrinterSensor';
import WelcomeModal from '@containers/Modals/WelcomeModal';
import { MODAL_IDS } from '@constants/modalDataTypes';
import FileBrowserModal from '@containers/Modals/FileBrowserModal';
import FilePreviewModal from '@containers/Modals/FilePreviewModal';
import VisualToolModal from '@containers/Modals/VisualToolModal/VisualToolModal';

export default function Modals() {
  const { isModalShown } = useModal();

  return (
    <>
      {isModalShown(MODAL_IDS.PRINTER_SENSOR_DATA) && <PrinterSensor />}
      {isModalShown(MODAL_IDS.WELCOME) && <WelcomeModal />}
      {isModalShown(MODAL_IDS.FILE_BROWSER) && <FileBrowserModal />}
      {isModalShown(MODAL_IDS.FILE_PREVIEW) && <FilePreviewModal />}
      {isModalShown(MODAL_IDS.VISUAL_TOOL) && <VisualToolModal />}
    </>
  );
}
