import { combineReducers } from 'redux';
import applicationReducer from '@reducers/applicationReducer';
import configReducer from '@reducers/configReducer';
import modalReducer from '@reducers/modalReducer';
import notificationsReducer from '@reducers/notificationsReducer';
import fetchReducer from '@reducers/fetchReducer';
import loginReducer from '@reducers/loginReducer';
import designsReducer from '@reducers/designsReducer';
import conceptsReducer from '@reducers/conceptsReducer';
import quickStartReducer from '@reducers/quickStartReducer';
import printersReducer from '@reducers/printersReducer';
import analyticsReducer from '@reducers/analyticsReducer';
import errorsReducer from '@reducers/errorsReducer';
import workspacesReducer from '@reducers/workspacesReducer';
import sceneReducers from '@reducers/sceneReducers';
import settingsReducer from '@reducers/settingsReducer';
import templatesReducer from '@reducers/templatesReducer';
import toolBarReducer from '@reducers/toolBarReducer';
import computationProgressReducer from '@reducers/computationProgressReducer';
import gpt from '@reducers/gpt';
import websocketReducer from '@reducers/websocketReducer';
import workflowAnnotationReducer from '@reducers/workflowAnnotationReducer';
import versionReducer from '@reducers/versionReducer';
import workflowReducer from '@reducers/workflowSlice';

const allReducers = combineReducers({
  application: applicationReducer,
  config: configReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  fetch: fetchReducer,
  login: loginReducer,
  designs: designsReducer,
  concepts: conceptsReducer,
  quickStart: quickStartReducer,
  printers: printersReducer,
  analytics: analyticsReducer,
  errors: errorsReducer,
  workspaces: workspacesReducer,
  scene: sceneReducers,
  settings: settingsReducer,
  templates: templatesReducer,
  toolBar: toolBarReducer,
  computationProgress: computationProgressReducer,
  gpt: gpt,
  websocket: websocketReducer,
  workflowAnnotations: workflowAnnotationReducer,
  version: versionReducer,
  workflow: workflowReducer,
});

const rootReducer = (state, action) => allReducers(state, action);

export default rootReducer;
