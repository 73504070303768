import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '@hoc/withTooltip';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import Field, { FIELD_SIZE_COMPACT } from '@components/1-atoms/Field';
import { Wrapper, LeftColumn, RightColumn } from './SettingTextField.styled';

const TextWithTooltip = withTooltip(Text);

const SettingTextField = ({
  className,
  dataTestId = 'setting-text-field',
  field1 = {},
  field2,
  field3,
  label,
  labelTooltip = {},
  withIdentation,
  form, // formik property
  field, // formik property
  meta, // formik property
}) => {
  const fields = [field1, field2, field3];
  const withTwoFields = fields.filter((f) => f).length === 2;
  const withThreeFields = fields.filter((f) => f).length === 3;

  return (
    <Wrapper
      dataTestId={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn
        withTwoFields={withTwoFields}
        withThreeFields={withThreeFields}
      >
        <Field
          dataTestId={`${dataTestId}__field`}
          {...field1}
          form={form}
          field={field}
          meta={meta}
          size={FIELD_SIZE_COMPACT}
        />

        {field2 && (
          <Field
            dataTestId={`${dataTestId}__field2`}
            {...field2}
            size={FIELD_SIZE_COMPACT}
          />
        )}

        {field3 && (
          <Field
            dataTestId={`${dataTestId}__field3`}
            {...field3}
            size={FIELD_SIZE_COMPACT}
          />
        )}
      </RightColumn>
    </Wrapper>
  );
};

SettingTextField.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  field1: PropTypes.shape(Field.propTypes),
  field2: PropTypes.shape(Field.propTypes),
  field3: PropTypes.shape(Field.propTypes),
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
};

export default SettingTextField;
