import * as THREE from 'three';
import {
  printerConstants as constants,
  printerConstants,
} from '../../constants/printers/printerConstants';
import { degToRad } from 'three/src/math/MathUtils';
import PrinterComponent from '../PrinterComponent';
import { Printer } from '../Printer';

/**
 * Represents the core machine object which moves during toolpath simulation, e.g.
 * robot, gantry, etc.
 */
class Machine extends PrinterComponent {
  constructor(robotType, machineDefinitions, printerSettings, name) {
    super(
      printerSettings,
      Machine.getMachineDefaults(
        machineDefinitions,
        printerSettings,
        robotType,
      ),
      name,
    );
    this.props = {};
    this.props.modelName = robotType;
    this.parts = [];
    this.rotationPoints = [];
    this.robotType = robotType;
    this.machineDefinitions = machineDefinitions;
    this.machineDefinition = this.machineDefinitions.find(
      ({ displayName }) => displayName == robotType,
    );
    this.fileKey = this.machineDefinition?.fileKey;

    this.flange = new THREE.Group();
    this.flange.name = constants.flange;

    this.flangeMounting = new THREE.Group();
    this.flangeMounting.name = constants.flangeMounting;

    this.tcp = new THREE.Group();
    this.tcp.name = constants.TCP;

    this.tcpCorrectionX = Printer.getPrinterSettingValueOrDefault(
      printerSettings,
      constants.tcpCorrectionX,
      0,
    );
    this.tcpCorrectionY = Printer.getPrinterSettingValueOrDefault(
      printerSettings,
      constants.tcpCorrectionY,
      0,
    );
    this.tcpCorrectionZ = Printer.getPrinterSettingValueOrDefault(
      printerSettings,
      constants.tcpCorrectionZ,
      0,
    );
    this.flange.add(this.tcp);
    this.flange.add(this.flangeMounting);

    this.props.brand = this.machineDefinition?.kinematicType ?? 'GENERIC';

    this.definition = {};
  }

  /**
   * Replaces the current tool which is attached to the gantry axes with the new
   * provided tool, and updates the TCP visualization
   * @param {*} tool
   * @param {*} toolCalibration
   */
  setTool(tool) {
    try {
      if (!this.flange) {
        throw Object.assign(new Error('Machine has not defined a flange'), {
          code: 402,
        });
      }
      this.flangeMounting.clear(tool);
      this.flangeMounting.add(tool);
      this.tcp.clear();
      this.tool = tool;
      const tcpVisualisation = new THREE.Group();
      let colourString = constants.redHex;

      if (this.robotType === 'Spark') return;

      Object.values(tool.calibration).forEach((calibration) => {
        const tcpGroup = new THREE.Group();
        tcpGroup.add(
          this.getTCPVisualisationGeometry([0, 0, 0], colourString, 0.2),
        );
        tcpGroup.position.set(
          calibration.toolX,
          calibration.toolY,
          calibration.toolZ,
        );
        tcpGroup.translateX(this.tcpCorrectionX);
        tcpGroup.translateY(this.tcpCorrectionY);
        tcpGroup.translateZ(this.tcpCorrectionZ);
        tcpGroup.add(new THREE.AxesHelper(20));
        tcpVisualisation.add(tcpGroup);
        const toolRotation = new THREE.Euler(
          degToRad(calibration.toolC),
          degToRad(calibration.toolB),
          degToRad(calibration.toolA),
          'ZYX',
        );
        colourString = '#0' + colourString.substring(1, 6);
        tcpGroup.setRotationFromEuler(toolRotation);
      });
      this.tcp.add(tcpVisualisation);
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error(e);
    }
  }

  static getMachineDefaults(machineDefinitions, printerSettings, robotType) {
    if (robotType === constants.CUSTOM.value) {
      const machineDefinitionResponse = Printer.getPrinterSettingValue(
        printerSettings,
        'machineDefinitionResponse',
      );
      const { brand, models, machineType } = machineDefinitionResponse;
      const { a1, a2, a3, a4, a5, a6, d1, d2, d3, d4, d5, d6 } =
        machineDefinitionResponse;
      return {
        brand,
        machineType,
        models,
        pivots: {
          d: [d1, d2, d3, d4, d5, d6],
          a: [a1, a2, a3, a4, a5, a6],
        },
        defaultHomePosition: [0, 0, 0, 0, 0, 0],
        homePositionCorrections: [0, 0, 0, 0, 0, 0],
      };
    } else {
      return machineDefinitions.find(
        ({ displayName }) => displayName === robotType,
      );
    }
  }

  /**
   * Returns a sphere object for TCP visualization
   * @param {Vector3} tcpCoordinates
   * @param {*} colourString
   * @returns
   */
  getTCPVisualisationGeometry(tcpCoordinates, colourString, size = 0.8) {
    const circle = new THREE.TextureLoader().load('/img/circle.png');
    const tcpGeometry = new THREE.BufferGeometry();
    const tcpMaterial = new THREE.PointsMaterial({
      color: colourString,
      size: size,
      sizeAttenuation: true,
      alphaTest: 0.5,
      map: circle,
    });
    tcpGeometry.setAttribute(
      constants.position,
      new THREE.Float32BufferAttribute(tcpCoordinates, 3),
    );
    return new THREE.Points(tcpGeometry, tcpMaterial);
  }

  /**
   * Returns the value stored in key "settingName" of the "machine" property of the
   * printerSettings object.
   * @param {*} printerSettings
   * @param {*} settingName
   * @param {*} defaultValue
   * @returns
   */
  static getMachineSettingValueOrDefault(
    printerSettings,
    settingName,
    defaultValue,
  ) {
    const machineSettings = printerSettings.find(
      (setting) => setting.settingName === printerConstants.machine,
    )?.value;

    return machineSettings?.[settingName] ?? defaultValue;
  }
}

export default Machine;
