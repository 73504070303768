import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 391px;
  overflow: hidden;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.surfaceContainerLow};
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: auto;
`;

export const ImageSidebar = styled.div`
  flex: 1;

  ${({ imageSrc }) => css`
    background: url(${imageSrc}) left / cover no-repeat;
  `}
`;
