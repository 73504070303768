import {
  resetIsAwaitingComputation,
  setIsAwaitingComputation,
} from '@app/reducers/workflowSlice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

let messageTimeout;
let clearMessageTimeout;

export default function useAwaitingComputationMessage() {
  const dispatch = useDispatch();

  // time in ms between clicking compute and the message showing (if no computation status received)
  const initialDelay = 2500;
  // time in ms that the message will be visible for, will be removed after this time even if no status received
  const maxDisplayTime = 120000;

  const enqueueAwaitingComputationMessage = useCallback(() => {
    clearTimeout(messageTimeout);
    messageTimeout = setTimeout(() => {
      clearTimeout(messageTimeout);
      dispatch(setIsAwaitingComputation(true));

      clearMessageTimeout = setTimeout(() => {
        clearTimeout(clearMessageTimeout);
        clearTimeout(messageTimeout);
        dispatch(resetIsAwaitingComputation());
      }, maxDisplayTime);
    }, initialDelay);
  }, [dispatch]);

  const clearAwaitingComputationMessage = useCallback(() => {
    dispatch(resetIsAwaitingComputation());
    clearTimeout(messageTimeout);
    clearTimeout(clearMessageTimeout);
  }, [dispatch]);

  return {
    enqueueAwaitingComputationMessage,
    clearAwaitingComputationMessage,
  };
}
