import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Text, {
  TEXT_VARIANT_BODY_LARGE,
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import SVG from '@components/1-atoms/SVG';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import {
  flexCenter,
  flexCenterVertical,
  flexColumn,
  when,
} from '@stylesheets/helpers';

const topLayer = css`
  position: relative;
  z-index: 1;
`;

export const Divider = styled.span`
  height: 1px;
  ${flexCenterVertical}
  flex-shrink: 0;
  margin: 8px 0;

  ${({ theme: { colors } }) => css`
    background-color: ${colors?.outlineVariant};
  `}
`;

export const SkeletonWrapper = styled.div`
  height: 56px;
  ${flexColumn}
  justify-content: center;
  flex-shrink: 0;

  ${({ theme: { spacing, colors } }) => css`
    background-color: ${colors.surfaceContainerHighest};
    padding: 8px ${spacing.level5};
  `}
`;

export const Icon = styled(SpriteSVG)`
  flex-shrink: 0;
  ${topLayer}

  ${({ navigationItem, color, theme: { colors, spacing } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
    fill: ${colors.onSurface};

    ${when(
      navigationItem,
      `
        fill: ${colors.primary};
      `,
    )}

    ${when(
      color,
      `
        fill: ${colors?.[color] || color};
      `,
    )}
  `}
`;

export const ExternalIcon = styled(SVG)`
  ${({ color, theme: { spacing, colors } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};

    path {
      fill: ${colors.onSurface};

      ${when(
        color,
        `
          fill: ${colors?.[color] || color};
        `,
      )}
    }
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  justify-content: center;
  overflow: clip;
  ${topLayer}
`;

export const Label = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_LARGE,
})`
  ${({ navigationItem, color, theme: { colors } }) => css`
    color: ${colors?.[color] || colors.onSurface};
    word-break: break-word;
    line-height: 20px;

    ${when(
      navigationItem,
      `
        color: ${colors.primary};
      `,
    )}
  `}
`;

export const Description = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
})`
  word-break: break-word;
  opacity: 0.76;

  ${({ color, theme: { colors } }) => css`
    color: ${colors?.[color] || colors.onSurfaceVariant};
  `}
`;

export const ButtonLabel = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const BadgeWrapper = styled.span`
  width: 24px;
  height: 24px;
  ${flexCenter}
  ${topLayer}
`;

export const Wrapper = styled.div`
  ${flexCenterVertical}
  flex-shrink: 0;
  position: relative;
  user-select: none;
  text-decoration: none;
  overflow: hidden;

  ${({
    autoHeight,
    selected,
    disabled,
    $noPadding,
    $clickable,
    hoverable,
    theme: { spacing, colors },
  }) => css`
    padding: 8px ${spacing.level4};
    gap: ${spacing.level4};

    ${when(
      $clickable,
      `
        cursor: pointer;
      `,
    )}

    ${when(
      !autoHeight,
      `
        min-height: 56px;
      `,
    )}

    ${when(
      $noPadding,
      `
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
      `,
    )}

    ${when(
      selected,
      `
        background-color: ${colors.secondaryContainer};
      `,
    )}

    ${when(
      disabled,
      `
        pointer-events: none;
        opacity: 0.38;
      `,
    )}

    ${when(
      hoverable,
      css`
        &:hover {
          ${({ selected, theme: { colors } }) => css`
            ${when(
              !selected,
              `
                background-color: ${rgba(colors.onSurface, 0.08)};
              `,
            )}
          `}
        }

        &:focus:focus-visible {
          ${({ selected, theme: { colors } }) => css`
            ${when(
              !selected,
              `
                background-color: ${rgba(colors.onSurface, 0.12)};
              `,
            )}
          `}
        }
      `,
    )}
  `}
`;
