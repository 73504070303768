import styled, { css } from 'styled-components';
import {
  Wrapper as Operator,
  OperatorBody,
} from '@components/2-molecules/Operator/Operator.styled';
import { Wrapper as OperatorHeader } from '@components/2-molecules/OperatorHeader/OperatorHeader.styled';
import { Wrapper as ActionFooter } from '@components/2-molecules/ActionFooter/ActionFooter.styled';
import { flexColumn, when } from '@stylesheets/helpers';

export const SLIDE_IN_COMPLETED_CLASS_NAME =
  'full-screen-operator--slide-in-animation-complete';

export const Wrapper = styled.div`
  scroll-margin-bottom: 0;

  ${({ animated }) => css`
    ${when(
      animated,
      `
        width: 100%;
        height: 48px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        overflow: hidden;
      `,
    )}
  `}

  ${Operator} {
    height: 100%;
    overflow: hidden;
  }

  ${OperatorBody} {
    max-height: unset;

    ${({ theme: { spacing } }) => css`
      padding-inline: ${spacing.level3};
    `}
  }

  &.${SLIDE_IN_COMPLETED_CLASS_NAME} {
    ${OperatorBody} {
      ${({ show }) => css`
        ${when(
          show,
          `
            overflow: auto;
          `,
        )}
      `}
    }
  }

  ${OperatorHeader} {
    ${({ theme: { spacing } }) => css`
      padding-inline: ${spacing.level6} ${spacing.level4};
    `}
  }

  ${ActionFooter} {
    background-color: unset;
  }
`;

export const OperatorContent = styled.div`
  ${flexColumn}
  min-height: 96px;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level5};
  `}
`;
