import {
  SHOW_ERROR_DIALOG,
  DISMISS_ERROR_DIALOG,
} from '../constants/actionTypes.js';

const initialState = {
  errors: [],
};

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_DIALOG:
      if (
        state.errors.some(
          (err) =>
            err.title === action.title &&
            err.message === action.message &&
            err.messageKey === action.messageKey,
        )
      ) {
        return state;
      }
      return {
        ...state,
        errors: [
          ...state.errors,
          {
            title: action.title,
            message: action.message,
            messageKey: action.messageKey,
            arguments: action.arguments,
            originalMessage: action.originalMessage,
          },
        ],
      };
    case DISMISS_ERROR_DIALOG:
      return {
        ...state,
        errors: state.errors.slice(0, state.errors.length - 1),
      };
    default:
      return state;
  }
};

export default errorsReducer;
