import actionTypes from '@actions';

export const setScreenSize = (screenSize) => ({
  type: actionTypes.SET_SCREEN_SIZE,
  payload: { screenSize },
});

export const addBanner = (banner) => ({
  type: actionTypes.ADD_BANNER,
  payload: {
    banner: banner,
  },
});

export const removeBanner = (bannerKey) => ({
  type: actionTypes.REMOVE_BANNER,
  payload: { bannerKey },
});

export const setNavigationBarHistory = (navigationBarHistory) => ({
  type: actionTypes.SET_NAVIGATION_BAR_HISTORY,
  payload: { navigationBarHistory },
});

export const resetNavigationBarHistory = () => ({
  type: actionTypes.RESET_NAVIGATION_BAR_HISTORY,
});
