import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsShowingGrid } from '@selectors/conceptSelectors';
import { VisualizationContext } from '@contexts/VisualizationContext';

/* eslint react/no-unknown-property: 0 */

export default function Grid() {
  const { printer } = useContext(VisualizationContext);
  const isShowingGrid = useSelector(getIsShowingGrid());

  const gridSize = 100;
  const scaleRatio = 0.02;
  const plinthHeight = printer?.plinth?.height || 0;
  const translation = useMemo(
    () => (plinthHeight ? -plinthHeight * scaleRatio : 0),
    [plinthHeight],
  );

  if (!isShowingGrid) return null;

  return (
    <group name="grid">
      <gridHelper
        args={[gridSize, gridSize / 2, '#222222', '#111111']}
        rotation-x={-Math.PI / 2}
        position={[0, 0, translation]}
      />
    </group>
  );
}
