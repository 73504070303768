import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FileInput } from './SingleFileUpload.styled';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@constants/modalDataTypes';
import SettingAction from '@components/2-molecules/SettingAction';
import useDialog from '@hooks/useDialog';

export default function SingleFileUpload({
  handleFile,
  label,
  placeholderLabel,
  valueFile,
  allowedExtensions,
  fileSize,
  dirty,
  dataTestId,
}) {
  const intl = useIntl();
  const hiddenFileInput = useRef();
  const { showDialog } = useDialog();
  const [selectedFileName, setSelectedFileName] = useState(
    valueFile?.name || null,
  );

  const validateFile = useMemo(() => {
    return (file) => {
      const fileSizeLimit = fileSize * 1024 * 1024;
      if (file.size > fileSizeLimit) {
        return {
          id: 'fileupload.size.warning',
          params: { fileSize: fileSize },
        };
      } else if (
        !allowedExtensions.includes(file.name.split('.').pop().toLowerCase())
      ) {
        return {
          id: 'fileupload.wrong.format.warning',
          params: { allowedExtensions: allowedExtensions.join(', ') },
        };
      }

      return null;
    };
  }, [fileSize, allowedExtensions]);

  const handleFileWithValidation = useCallback(
    (file) => {
      const warningMessage = validateFile(file);

      if (warningMessage) {
        showDialog(ModalDataTypes.PROMPT, {
          dataTestId: 'file-upload-warning-dialog',
          title: intl.formatMessage({
            id: 'dialogs.title.attention',
            defaultMessage: 'Attention',
          }),
          subtitle: intl.formatMessage(
            {
              id: warningMessage.id,
              defaultMessage: 'Something went wrong with validation...',
            },
            warningMessage.params,
          ),
          secondaryButtonLabel: '',
        });
        return; // Do not proceed with the upload
      }

      setSelectedFileName(file.name);
      handleFile(file);
    },
    [showDialog, validateFile, intl, setSelectedFileName, handleFile],
  );

  useEffect(() => {
    setSelectedFileName(valueFile?.name || null);
  }, [valueFile]);

  const handleFileChange = useCallback(
    (event) => {
      const fileUploaded = event.target.files[0];
      event.target.value = ''; // Clear the input value

      if (!fileUploaded) {
        return; // No file selected
      }

      handleFileWithValidation(fileUploaded);
    },
    [handleFileWithValidation],
  );

  const handleClick = useCallback(() => {
    hiddenFileInput.current.click();
  }, [hiddenFileInput]);

  const handleDrop = useCallback(
    (event) => {
      const fileDropped = event.dataTransfer.files[0];
      event.dataTransfer.value = ''; // Clear the input value
      if (!fileDropped) {
        return; // No file selected
      }
      handleFileWithValidation(fileDropped);
    },
    [handleFileWithValidation],
  );

  return (
    <>
      <SettingAction
        label={label}
        dataTestId={dataTestId}
        chip={{
          highlightOnDragOver: true,
          onDrop: handleDrop,
          onClick: handleClick,
          leadingIconName: 'upload_0',
          dirty: dirty,
          label:
            selectedFileName ||
            intl.formatMessage({
              id: placeholderLabel || 'fileupload.button.label',
            }),
        }}
      />
      <FileInput
        type="file"
        onChange={handleFileChange}
        ref={hiddenFileInput}
      />
    </>
  );
}

SingleFileUpload.propTypes = {
  handleFile: PropTypes.func.isRequired,
  fileSize: PropTypes.number.isRequired, //represents MB
  allowedExtensions: PropTypes.array.isRequired, // list of possible extensions
  label: PropTypes.string,
  placeholderLabel: PropTypes.string,
  valueFile: PropTypes.object,
  dirty: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
};
