import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import withDropDownMenu from '@hoc/withDropDownMenu';
import {
  OuterWrapper,
  Wrapper,
  LeadingIcon,
  EndingIcon,
} from './IconButtonDropDown.styled';

const IconButtonDropDown = forwardRef(
  (
    {
      className,
      dataTestId = 'icon-button-drop-down',
      disabled,
      leadingIconName = 'settings_0',
      endingIconName = 'arrow_drop_down_1',
      onClick,
      title,
    },
    ref,
  ) => {
    return (
      <OuterWrapper>
        <Wrapper
          ref={ref}
          className={className}
          data-testid={dataTestId}
          disabled={disabled}
          onClick={disabled ? undefined : onClick}
          tabIndex={disabled ? -1 : 0}
          title={title}
          type="button"
        >
          <LeadingIcon name={leadingIconName} />
          <EndingIcon name={endingIconName} />
        </Wrapper>
      </OuterWrapper>
    );
  },
);

IconButtonDropDown.displayName = 'IconButtonDropDown';

IconButtonDropDown.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  leadingIconName: PropTypes.string.isRequired,
  endingIconName: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default withDropDownMenu(IconButtonDropDown);
