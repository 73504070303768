import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Setting } from '@components/Printers/Editor/Setting';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@selectors/loginSelectors';

export const Category = ({
  categoryTitle,
  actionHandler,
  settingsInCategory,
  onChange,
  isOpen = false,
  errors,
  selectedPrinter,
}) => {
  const [open, setOpen] = useState(isOpen);
  const currentUser = useSelector(getCurrentUser());

  if (!settingsInCategory?.length) return null;

  return (
    <SettingsCategory
      title={categoryTitle}
      dataTestId={`printer-page-render-category__${categoryTitle}`}
      onHeaderClick={() => {
        setOpen(!open);
      }}
      expand={open}
      withDividerBottom
    >
      {settingsInCategory.map((setting) => (
        <Setting
          label={setting.displayName}
          actionHandler={actionHandler}
          dataTestId={`printer-page-render-category__${categoryTitle}__setting-${setting.settingName}`}
          key={setting.settingName || setting.displayName}
          setting={setting}
          onChange={setting.onChangeOverride ?? onChange}
          selectedPrinter={selectedPrinter}
          errors={errors}
          currentUser={currentUser}
        />
      ))}
    </SettingsCategory>
  );
};
Category.propTypes = {
  categoryTitle: PropTypes.string.isRequired,
  actionHandler: PropTypes.func,
  settingsInCategory: PropTypes.array,
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  errors: PropTypes.object,
  selectedPrinter: PropTypes.object.isRequired,
};
