import React from 'react';
import PropTypes from 'prop-types';
import ImageSideBarLayout from '@components/1-atoms/Layouts/ImageSideBarLayout';
import Text, {
  TEXT_VARIANT_DISPLAY_LARGE,
  TEXT_VARIANT_HEADLINE_SMALL,
} from '@components/1-atoms/Text';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_OUTLINE,
} from '@components/1-atoms/Button';
import getIntlProvider from '@utils/getIntlProvider';
import { Content, Logo, Footer } from './WelcomeModal.styled';

const intl = getIntlProvider();

const WelcomeModal = ({
  imageSrc = '/img/welcome-dialog-image.png',
  title = intl.formatMessage(
    {
      id: 'welcomemodal.title',
      defaultMessage: 'Welcome<br></br>to the new<br></br>Aibuild',
    },
    {
      br: () => <br />,
    },
  ),
  description = intl.formatMessage({
    id: 'welcomemodal.description',
    defaultMessage:
      "Experience the next evolution of the Aibuild® platform. We've meticulously reimagined every detail to elevate your experience and streamline your workflows. Dive in and unlock the future of additive manufacturing.",
  }),
  primaryButtonLabel = intl.formatMessage({
    id: 'welcomemodal.primary_button_label',
    defaultMessage: 'Watch the video',
  }),
  primaryButtonIconName = 'play_arrow_1',
  secondaryButtonLabel = intl.formatMessage({
    id: 'welcomemodal.secondary_button_label',
    defaultMessage: 'Close',
  }),
  secondaryButtonIconName,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  const showFooter = primaryButtonLabel || secondaryButtonLabel;

  return (
    <ImageSideBarLayout imageSrc={imageSrc}>
      <Content>
        <Logo />

        {title && <Text variant={TEXT_VARIANT_DISPLAY_LARGE}>{title}</Text>}

        {description && (
          <Text variant={TEXT_VARIANT_HEADLINE_SMALL} color="onSurfaceVariant">
            {description}
          </Text>
        )}

        {showFooter && (
          <Footer>
            {primaryButtonLabel && (
              <Button
                onClick={onPrimaryButtonClick}
                variant={BUTTON_VARIANT_PRIMARY}
                iconName={primaryButtonIconName}
                stretch={false}
              >
                {primaryButtonLabel}
              </Button>
            )}

            {secondaryButtonLabel && (
              <Button
                onClick={onSecondaryButtonClick}
                variant={BUTTON_VARIANT_OUTLINE}
                iconName={secondaryButtonIconName}
                stretch={false}
              >
                {secondaryButtonLabel}
              </Button>
            )}
          </Footer>
        )}
      </Content>
    </ImageSideBarLayout>
  );
};

WelcomeModal.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  primaryButtonLabel: PropTypes.string,
  primaryButtonIconName: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonIconName: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
};

export default WelcomeModal;
