import { useCallback, useContext, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { isEmpty, isEqual, isUndefined, pick } from 'lodash';
import usePrevious from '@hooks/usePrevious';
import { getClippingTool } from '@selectors/toolBarSelector';
import {
  setClipToolRange,
  selectClipToolOption,
} from '@actions/toolBarActions';
import { VisualizationContext } from '@contexts/VisualizationContext';

const CLIPPING_PLANE_VECTOR = {
  x: {
    min: [1, 0, 0],
    max: [-1, 0, 0],
  },
  y: {
    min: [0, 1, 0],
    max: [0, -1, 0],
  },
  z: {
    min: [0, 0, 1],
    max: [0, 0, -1],
  },
};

const SCALING_FACTOR = 0.02;
const EPS = 0.01;

export default function ClippingPlanes() {
  const dispatch = useDispatch();
  const { objects, printer } = useContext(VisualizationContext);
  const clippingTool = useSelector(getClippingTool);
  const objectsList = useMemo(() => Object.values(objects), [objects]);

  const previousObjectsList = usePrevious(objectsList);
  const previousClippingTool = usePrevious(clippingTool);

  const getPreviousRenderedObject = useCallback(
    () => previousObjectsList?.[0],
    [previousObjectsList],
  );
  const getRenderedObject = useCallback(() => objectsList?.[0], [objectsList]);

  const hasRenderedObjectChanged = useCallback(() => {
    const renderedObject = getPreviousRenderedObject();
    const previousRenderedObject = getRenderedObject();
    const hasChanged = previousRenderedObject?.uuid !== renderedObject?.uuid;

    return hasChanged;
  }, [getPreviousRenderedObject, getRenderedObject]);

  const hasRenderedObjectOriginChanged = useCallback(() => {
    const renderedObject = getPreviousRenderedObject();
    const previousRenderedObject = getRenderedObject();
    const hasChanged =
      previousRenderedObject?.userData?.designId !==
      renderedObject?.userData?.designId;

    return hasChanged;
  }, [getPreviousRenderedObject, getRenderedObject]);

  const orientationTransformQuaternion = useMemo(() => {
    const quaternion = new THREE.Quaternion();
    if (!printer) {
      return quaternion;
    }
    const { bed } = printer;
    if (!bed) {
      return quaternion;
    }
    const { baseTransformationMatrix } = bed;
    quaternion.setFromRotationMatrix(baseTransformationMatrix);
    return quaternion;
  }, [printer]);

  const getClippingPlaneProps = useCallback(
    (object, direction = 'z') => {
      if (!object) return {};

      const center = new THREE.Vector3();
      let size = new THREE.Vector3();
      const box = new THREE.Box3().setFromObject(object);

      box.getCenter(center);
      box.getSize(size);
      const rotatedSize = size
        .clone()
        .applyQuaternion(orientationTransformQuaternion);
      size = new THREE.Vector3(
        Math.abs(rotatedSize.x),
        Math.abs(rotatedSize.y),
        Math.abs(rotatedSize.z),
      );

      const directionSize = size?.[direction] || 0;
      const directionCenter = center?.[direction] || 0;
      const directionCenterRotated =
        center
          .clone()
          .applyQuaternion(orientationTransformQuaternion.clone().invert())?.[
          direction
        ] || 0;

      const minRangeConstant = directionCenter - directionSize / 2;
      const maxRangeConstant = directionSize / 2 + directionCenter;
      const range = maxRangeConstant - minRangeConstant;

      const minRangeInMillimiters = 0;
      const maxRangeInMillimiters = directionSize / SCALING_FACTOR;
      const rangeStep = range * 0.2 + EPS * 2;

      return {
        minRangeConstant,
        maxRangeConstant,
        minRangeInMillimiters,
        maxRangeInMillimiters,
        range,
        rangeStep,
        size,
        directionSize,
        directionCenterRotated,
      };
    },
    [orientationTransformQuaternion],
  );

  const createClippingPlanes = useCallback(
    (object, direction = 'z', withHelper = false) => {
      if (!object) return {};

      const { maxRangeConstant, minRangeConstant, range, directionSize } =
        getClippingPlaneProps(object, direction);

      const minVectorValues = CLIPPING_PLANE_VECTOR?.[direction]?.min || [];
      const maxVectorValues = CLIPPING_PLANE_VECTOR?.[direction]?.max || [];

      const minVector = new THREE.Vector3(...minVectorValues);
      const maxVector = new THREE.Vector3(...maxVectorValues);

      minVector.applyQuaternion(orientationTransformQuaternion).normalize();
      maxVector.applyQuaternion(orientationTransformQuaternion).normalize();

      const minRangeClippingPlane = new THREE.Plane(
        minVector,
        -minRangeConstant,
      );

      const maxRangeClippingPlane = new THREE.Plane(
        maxVector,
        maxRangeConstant,
      );

      let helpers = {};

      if (withHelper) {
        const objectScaleDirection = object.scale?.[direction] || 0;
        const planeHelperWidth = directionSize / objectScaleDirection;
        const maxRangeClippingPlaneConstantScaled =
          maxRangeConstant / objectScaleDirection;
        const minRangeClippingPlaneConstantScaled =
          minRangeConstant / objectScaleDirection;
        const scaledRange = range / objectScaleDirection;

        const minRangeClippingPlaneClone = new THREE.Plane(
          minVector,
          -minRangeClippingPlaneConstantScaled - scaledRange * 0.2,
        );
        const maxRangeClippingPlaneClone = new THREE.Plane(
          maxVector,
          maxRangeClippingPlaneConstantScaled - scaledRange * 0.5,
        );

        const minRangeClippingPlaneHelper = new THREE.PlaneHelper(
          minRangeClippingPlaneClone,
          planeHelperWidth,
          0xffff00,
        );
        const maxRangeClippingPlaneHelper = new THREE.PlaneHelper(
          maxRangeClippingPlaneClone,
          planeHelperWidth,
          0x00ff00,
        );

        helpers = {
          minRangeClippingPlaneHelper,
          maxRangeClippingPlaneHelper,
        };
      }

      return {
        maxRangeClippingPlane,
        minRangeClippingPlane,
        ...helpers,
      };
    },
    [getClippingPlaneProps, orientationTransformQuaternion],
  );

  const checkIfObjectSupportClippingPlanes = useCallback(
    (object) =>
      (object?.isMesh || object?.type === 'LineSegments') &&
      object?.userData?.clipEnabled,
    [],
  );

  const isObjectWihtClippingPlanes = useCallback(
    (object) => !isEmpty(object.material?.clippingPlanes),
    [],
  );

  const addClippingPlanesToMaterial = useCallback(
    (object) => {
      const skip = !(
        checkIfObjectSupportClippingPlanes(object) &&
        !isObjectWihtClippingPlanes(object)
      );

      if (skip) return;

      const { maxRangeClippingPlane, minRangeClippingPlane } =
        createClippingPlanes(object, clippingTool?.clipOption);

      object.material.clippingPlanes = [
        minRangeClippingPlane,
        maxRangeClippingPlane,
      ];
      object.material.clipShadows = true;
    },
    [
      createClippingPlanes,
      checkIfObjectSupportClippingPlanes,
      isObjectWihtClippingPlanes,
      clippingTool?.clipOption,
    ],
  );

  const removeClippingPlanesFromMaterial = useCallback(
    (object) => {
      if (checkIfObjectSupportClippingPlanes(object)) {
        object.material.clippingPlanes = null;
        object.material.clipShadows = null;
      }
    },
    [checkIfObjectSupportClippingPlanes],
  );

  const setClippingRange = useCallback(() => {
    const object = getRenderedObject();

    if (!object) return;

    const { rangeStep, minRangeInMillimiters, maxRangeInMillimiters } =
      getClippingPlaneProps(object, clippingTool?.clipOption);
    const nextRange = {
      clipStep: rangeStep,
      clipMinRange: minRangeInMillimiters,
      clipMaxRange: maxRangeInMillimiters,
    };

    dispatch(setClipToolRange(nextRange));
  }, [dispatch, clippingTool, getRenderedObject, getClippingPlaneProps]);

  const setClippingRangeValuesToConstants = useCallback(
    (object) => {
      if (!checkIfObjectSupportClippingPlanes(object)) return;

      const hasClippingPlanes = !isEmpty(object.material?.clippingPlanes);

      if (!hasClippingPlanes) return;

      const { directionCenterRotated, directionSize } = getClippingPlaneProps(
        object,
        clippingTool?.clipOption,
      );
      const [minRangeClippingPlane, maxRangeClippingPlane] =
        object.material.clippingPlanes;

      const minVectorValues =
        CLIPPING_PLANE_VECTOR?.[clippingTool?.clipOption]?.min || [];
      const maxVectorValues =
        CLIPPING_PLANE_VECTOR?.[clippingTool?.clipOption]?.max || [];

      const minVector = new THREE.Vector3(...minVectorValues);
      const maxVector = new THREE.Vector3(...maxVectorValues);

      minVector.applyQuaternion(orientationTransformQuaternion).normalize();
      maxVector.applyQuaternion(orientationTransformQuaternion).normalize();
      const worldTranslation = directionCenterRotated - directionSize / 2;

      const scaledClipMinRangeValue = -(
        clippingTool?.clipMinRangeValue * SCALING_FACTOR -
        EPS +
        worldTranslation
      );
      let scaledClipMaxRangeValue =
        clippingTool?.clipMaxRangeValue * SCALING_FACTOR +
        EPS +
        worldTranslation;

      if (clippingTool?.clipMaxRangeValue === 0.0) {
        scaledClipMaxRangeValue = scaledClipMaxRangeValue - 1;
      }

      const shouldUpdateMinVector = !isEqual(
        Object.values(minRangeClippingPlane.normal),
        Object.values(minVector),
      );
      const shouldUpdateMaxVector = !isEqual(
        Object.values(maxRangeClippingPlane.normal),
        Object.values(maxVector),
      );

      const shouldUpdateMinRangeConstant =
        minRangeClippingPlane.constant !== scaledClipMinRangeValue;
      const shouldUpdateMaxRangeConstant =
        maxRangeClippingPlane.constant !== scaledClipMaxRangeValue;

      if (shouldUpdateMinVector) {
        minRangeClippingPlane.normal.set(...Object.values(minVector));
      }

      if (shouldUpdateMinRangeConstant) {
        minRangeClippingPlane.constant = scaledClipMinRangeValue;
      }

      if (shouldUpdateMaxVector) {
        maxRangeClippingPlane.normal.set(...Object.values(maxVector));
      }

      if (shouldUpdateMaxRangeConstant) {
        maxRangeClippingPlane.constant = scaledClipMaxRangeValue;
      }
    },
    [
      clippingTool,
      checkIfObjectSupportClippingPlanes,
      getClippingPlaneProps,
      orientationTransformQuaternion,
    ],
  );

  const observeObjectsListUpdates = useCallback(() => {
    if (!clippingTool?.clipIsActive) return;

    objectsList.forEach((object) =>
      object.traverse((o) => addClippingPlanesToMaterial(o)),
    );
  }, [objectsList, clippingTool?.clipIsActive, addClippingPlanesToMaterial]);

  const observeClipToolChanges = useCallback(() => {
    if (!clippingTool?.clipIsActive) return;

    const clipTurnedOn =
      !previousClippingTool?.clipIsActive && clippingTool?.clipIsActive;
    const clipOptionChanged =
      !isUndefined(previousClippingTool) &&
      !isEqual(previousClippingTool?.clipOption, clippingTool?.clipOption);
    const objectChanged = hasRenderedObjectOriginChanged();
    const shouldSetClippingRange =
      clipTurnedOn || clipOptionChanged || objectChanged;

    if (!shouldSetClippingRange) return;

    setClippingRange();
  }, [
    previousClippingTool,
    clippingTool,
    hasRenderedObjectOriginChanged,
    setClippingRange,
  ]);

  const observeClipToolRangeChanges = useCallback(() => {
    if (isUndefined(previousClippingTool)) return;

    const rangeValuesKeys = [
      'clipOption',
      'clipMinRangeValue',
      'clipMaxRangeValue',
    ];
    const previousRangeValues = pick(previousClippingTool, rangeValuesKeys);
    const currentRangeValues = pick(clippingTool, rangeValuesKeys);
    const objectTransformationApplied =
      !hasRenderedObjectOriginChanged() && hasRenderedObjectChanged();
    const skip =
      !objectTransformationApplied &&
      isEqual(previousRangeValues, currentRangeValues);

    if (skip) return;

    objectsList.forEach((object) =>
      object?.traverse?.((o) => setClippingRangeValuesToConstants(o)),
    );
  }, [
    previousClippingTool,
    clippingTool,
    objectsList,
    hasRenderedObjectOriginChanged,
    hasRenderedObjectChanged,
    setClippingRangeValuesToConstants,
  ]);

  const observeDisableClippingRange = useCallback(() => {
    const clipDisabled =
      previousClippingTool?.clipIsActive && !clippingTool?.clipIsActive;
    let skip = !clipDisabled;

    if (skip) return;

    dispatch(selectClipToolOption('z'));

    const object = getRenderedObject();
    skip = !object;

    if (skip) return;

    objectsList.forEach((object) =>
      object.traverse(removeClippingPlanesFromMaterial),
    );
  }, [
    dispatch,
    objectsList,
    previousClippingTool?.clipIsActive,
    clippingTool?.clipIsActive,
    getRenderedObject,
    removeClippingPlanesFromMaterial,
  ]);

  useMemo(() => {
    objectsList?.forEach((object) => {
      if (clippingTool?.clipIsActive) {
        createClippingPlanes(object, clippingTool?.clipOption);
      }
    });
  }, [
    objectsList,
    createClippingPlanes,
    clippingTool?.clipIsActive,
    clippingTool?.clipOption,
  ]);

  useEffect(() => {
    if (hasRenderedObjectOriginChanged()) {
      dispatch(selectClipToolOption('z'));
    }
  }, [dispatch, hasRenderedObjectOriginChanged]);

  useEffect(() => {
    observeObjectsListUpdates();
  }, [observeObjectsListUpdates]);

  useEffect(() => {
    observeClipToolChanges();
  }, [observeClipToolChanges]);

  useEffect(() => {
    observeClipToolRangeChanges();
  }, [observeClipToolRangeChanges]);

  useEffect(() => {
    observeDisableClippingRange();
  }, [observeDisableClippingRange]);

  return null;
}
