import * as THREE from 'three';
import { convertResponseToComponents, createGraph } from './GantryUtils';
import { graphlib } from 'dagre';

/**
 * Represents the mechanical structure and joints of a gantry system. The system
 * consists of invididual components, e.g. axes, extruder and bed.
 * The underlying structure represents each component as a node in a Directed Acyclic
 * Graph (DAG), which is then topologically sorted. The forward kinematics of the system
 * can be computed by iterating over the sorted nodes.
 */
class GantryGraph {
  /**
   * Constructor which converts the machine definition response into the equivalent DAG
   * structure that is necessary for building the visualisation
   * @param {Object} gantryData {
   *                             AXIS_1: [WORLD],
   *                             AXIS_2: [AXIS_1],
   *                             AXIS_3: [AXIS_2],
   *                            }
   */
  constructor(machineDefinitionResponse) {
    this.componentAdjacencyList = convertResponseToComponents(
      machineDefinitionResponse,
    );

    const graph = createGraph(this.componentAdjacencyList);

    this.componentAdjacencyList.forEach((component) => {
      component.allPredecessors = this.getAllPredecessors(
        graph,
        component.name,
      );
    });

    const bedPredecessors = this.getAllPredecessors(graph, 'BED');
    bedPredecessors.forEach((bedPredecessor) => {
      if (bedPredecessor === 'WORLD') return;
      const component = this.componentAdjacencyList.get(bedPredecessor);
      component.isBedAttached = true;
    });

    this.componentAdjacencyList.get('BED').isBedAttached = true;

    this.sortedNodes = graphlib.alg.topsort(graph);
  }

  /**
   * Returns all unique predecessors in the DAG structure - this is necesssary to sum
   * up the displacements of all predecessors.
   * @param {*} graph DAG structure representation of ganry
   * @param {*} node indvidual gantry component
   * @returns {Array<string>} list of all predecessors in graph
   */
  getAllPredecessors(graph, node) {
    const predecessors = new Set();

    function dfs(currentNode) {
      const preds = graph.predecessors(currentNode);
      if (preds) {
        preds.forEach((pred) => {
          if (!predecessors.has(pred)) {
            predecessors.add(pred);
            dfs(pred);
          }
        });
      }
    }

    dfs(node);
    return Array.from(predecessors);
  }

  /**
   * Iterates through the sorted list of gantry components and updates their positions
   * based on the supplied joint positions.
   * @param {Array<number>} jointPositions [axis1, axis2, axis3] positions
   * @returns map of component name to the displacement vector of that component, measured
   * from the gantry system origin
   */
  updateGroupPositions(jointPositions) {
    const map = new Map();
    this.sortedNodes.forEach((componentName) => {
      if (componentName === 'WORLD') return;

      const component = this.componentAdjacencyList.get(componentName);

      component.updateJointPosition(jointPositions);

      map.set(componentName, this.calculateSystemPositions(component));
    });
    return map;
  }

  /**
   * Updates the displacement vector of the gantry component
   * @param {GantryComponent} component
   * @returns
   */
  calculateSystemPositions(component) {
    const sumOfParentDisplacements = component.allPredecessors.reduce(
      (acc, parentComponentName) => {
        if (parentComponentName === 'WORLD') return acc;

        const parentComponent =
          this.componentAdjacencyList.get(parentComponentName);
        const displacementVector = parentComponent.displacementVector;
        return acc.add(displacementVector);
      },
      new THREE.Vector3(0, 0, 0),
    );

    return sumOfParentDisplacements.add(component.displacementVector);
  }
}

export default GantryGraph;
