// Function to match query with pattern matching
export const matchWithPattern = (query = '', strings = []) => {
  const regex = new RegExp(query.replace(/\\/g, '').replace(/ /g, '.*'), 'i');
  return strings.filter((str) => regex.test(str));
};

// Function to calculate Levenshtein distance
export const levenshteinDistance = (a, b) => {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  // Initialize the matrix
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Calculate Levenshtein distance
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1, // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
};

// Function to match query with Levenshtein distance
export const matchWithLevenshtein = (
  query = '',
  strings = [],
  threshold = 2,
) => {
  return strings?.filter?.(
    (str) =>
      levenshteinDistance(query.toLowerCase(), str.toLowerCase()) <= threshold,
  );
};
