import { concat } from 'lodash';
import {
  FETCH_ALL_CONCEPTS,
  FETCH_ALL_CONCEPTS_SUCCEEDED,
  FETCH_ALL_CONCEPTS_FAILED,
  FETCH_CONCEPT_GEOMETRY,
  FETCH_CONCEPT_GEOMETRY_SUCCEEDED,
  FETCH_CONCEPT_GEOMETRY_FAILED,
  FETCH_DEFAULT_OPERATORS,
  FETCH_DEFAULT_OPERATORS_SUCCEEDED,
  FETCH_DEFAULT_OPERATORS_FAILED,
  SELECT_OPERATOR,
  SELECT_OPERATOR_OUTPUT,
  CREATE_CONCEPT,
  CREATE_CONCEPT_SUCCEEDED,
  CREATE_CONCEPT_FAILED,
  UPDATE_CONCEPT,
  UPDATE_CONCEPT_SUCCEEDED,
  UPDATE_CONCEPT_FAILED,
  UPDATE_CONCEPTS,
  UPDATE_WORKFLOW,
  RUN_CONCEPT,
  RUN_CONCEPT_SUCCEEDED,
  RUN_CONCEPT_FAILED,
  DELETE_CONCEPT,
  DELETE_CONCEPT_SUCCEEDED,
  DELETE_CONCEPT_FAILED,
  SHOW_DELETE_CONCEPT_DIALOG,
  DISMISS_CREATE_CONCEPT_DIALOG,
  DISMISS_DELETE_CONCEPT_DIALOG,
  OPERATOR_INPUT_MODIFIED,
  REMOVE_OPERATOR_INPUT_MODIFICATIONS,
  ADD_OPERATOR,
  ADD_OPERATOR_FAILED,
  UPGRADE_OPERATOR,
  UPGRADE_OPERATOR_SUCCEEDED,
  UPGRADE_OPERATOR_FAILED,
  REMOVE_OPERATOR,
  REMOVE_OPERATOR_SUCCEEDED,
  REMOVE_OPERATOR_FAILED,
  UPDATE_HIDDEN_INPUT_OPERATORS,
  UPDATE_HIDDEN_OUTPUT_OPERATORS,
  UPDATE_OUTER_WALL_LINE_VISIBILITY,
  UPDATE_MILLING_LINE_VISIBILITY,
  UPDATE_INNER_WALL_LINE_VISIBILITY,
  UPDATE_SUPPORT_LINE_VISIBILITY,
  UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY,
  UPDATE_BRIM_LINE_VISIBILITY,
  UPDATE_SKIN_LINE_VISIBILITY,
  UPDATE_INFILL_LINE_VISIBILITY,
  UPDATE_SEAMS_VISIBILITY,
  TOGGLE_SOLID_VISIBILITY,
  UPDATE_LEGEND_RANGE,
  UPDATE_ROTATIONS_VISIBILITY,
  UPDATE_VERTICES_VISIBILITY,
  UPDATE_DIRECTION_VISIBILITY,
  UPDATE_DIMENSION_VISIBILITY,
  UPDATE_LINETYPE_VISIBILITY,
  UPDATE_CAMERA_POSITION,
  UPDATE_DISPLAY_MODE,
  UPDATE_GRID_VISIBILITY,
  UPDATE_ROBOT_VISIBILITY,
  UPDATE_WORKSPACE_VISIBILITY,
  OPERATOR_MODIFIED,
  UPDATE_CAMERA,
  CONCEPT_NAME_MODIFIED,
  CONCEPT_PRINTER_MODIFIED,
  SHOW_UPDATE_PRINTER_DIALOG,
  DISMISS_UPDATE_PRINTER_DIALOG,
  RESET_CONCEPT_MODIFICATIONS,
  FREEZE_OPERATOR_OUTPUT,
  UNFREEZE_OPERATOR_OUTPUT,
  RESET_FROZEN_OPERATOR_OUTPUTS,
  UPDATE_INVALID_OPERATORS,
  UPDATE_MISSING_INPUT_OPERATORS,
  CHANGE_CURRENT_FOCUS_MODEL,
  ADD_NEW_OPERATORVALUE_TO_LIST_SUCCEEDED,
  REMOVE_OPERATORVALUE_FROM_LIST_SUCCEEDED,
  UPDATE_PRINTINGBED_VISIBILITY,
  UPDATE_ENCLOSURE_VISIBILITY,
  FETCH_TOOLPATH_SIMULATION,
  FETCH_TOOLPATH_SIMULATION_SUCCEEDED,
  FETCH_TOOLPATH_SIMULATION_FAILED,
  ENTER_TOOLPATH_SIMULATION,
  EXIT_TOOLPATH_SIMULATION,
  SET_TOOLPATH_SIMULATION,
  UPDATE_SIMULATION_TRAVELLINE_VISIBILITY,
  UPDATE_OPERATOR_ORDER,
  UPDATE_OPERATOR_ORDER_SUCCEEDED,
  UPDATE_OPERATOR_ORDER_FAILED,
  FETCH_WORKFLOW,
  FETCH_WORKFLOW_SUCCEEDED,
  FETCH_WORKFLOW_FAILED,
  FETCH_TOOLPATH_INSTRUCTIONS,
  FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED,
  FETCH_TOOLPATH_INSTRUCTIONS_FAILED,
  ADD_OPERATOR_SUCCEEDED,
  TOGGLE_OPERATOR_DESCRIPTORS_POPUP,
  DISMISS_OPERATOR_DESCRIPTORS_POPUP,
  TOKEN_EXPIRE_LOG_OUT_SUCCESS,
  UPDATE_LAST_ADDED_OPERATOR_ID,
  RESET_LAST_ADDED_OPERATOR_ID,
  EXPLODE_TEMPLATE_SUCCESS,
  SET_TOOLPATH_SIMULATION_VISIBILITY,
  OPRATOR_SELECTOR_DRAG_START,
  OPRATOR_SELECTOR_DRAG_END,
} from '@constants/actionTypes';
import { LineData } from '@constants/lineData';
import actionTypes from '@actions';
import { ViewModes } from '@constants/viewModes';

const initialState = {
  ui: {
    showLoadingWheel: false,
    fetchesInProgress: 0,
    designGeometryData: {},
    selectedConceptLastOperatorOrder: -1,
    activeCanvasSelectionInput: null,
    isDeletingConcept: false,
    conceptIdToDelete: null,
    selectedOperatorOutputId: null,
    selectedOperatorOutputType: null,
    selectedOutputOperatorId: null,
    selectedOperatorOutput: null,
    frozenOutputs: [],
    selectedOperators: {},
    operatorInputModifications: [],
    hiddenInputs: [],
    hiddenOutputs: [],
    operatorModifications: [],
    forceUpdateCamera: false,
    isCreatingConcept: false,
    isShowingOuterWall: true,
    isShowingInnerWall: true,
    isShowingSupport: true,
    isShowingSupportInterface: true,
    isShowingBrim: true,
    isShowingSkin: true,
    isShowingInfill: true,
    isShowingMilling: true,
    isShowingSeams: false,
    isShowingSolid: false,
    isShowingRotations: false,
    isShowingVertices: false,
    isShowingDirection: false,
    isShowingDimensions: false,
    isShowingLinetypes: true,
    isShowingGrid: false,
    isShowingRobot: true,
    isShowingWorkspace: false,
    isShowingPrintingBed: true,
    isShowingEnclosure: true,
    displayMode: ViewModes.SOLID,
    lineData: LineData.LINETYPE,
    legendMinRange: 0,
    legendMaxRange: 100,
    cameraX: 0,
    cameraY: -300,
    cameraZ: 300,
    targetX: 0,
    targetY: 0,
    targetZ: 0,
    unsavedModifications: {},
    isOkayUpdatePrinter: false,
    isOkayUpdateNozzle: false,
    isOkayUpdateMaterial: false,
    invalidOperators: [],
    missingInputOperators: [],
    focusmodel: null,
    cameraControls: null,
    lastFetchedModelId: null,
    fetchFailedModelIds: [],
    simulation: {
      isActive: false,
      url: '',
      maxStepNumber: 1,
      maxTime: 0,
      step: 0,
      time: 0,
      printingObject: null,
      printerSteps: [],
      showTravelLine: false,
      printingObjectCoordinates: [],
    },
    projectInstructionsURL: null,
    operatorDescriptorPopup: {
      isShowing: false,
      operatorDisplayName: '',
      operator: null,
    },
    lastAddedOperatorId: '',
    toolpathSimulationIsVisible: true,
    operatorsSelector: {
      draggingOperatorId: false,
    },
  },
  data: {
    concepts: [],
    defaultOperators: [],
  },
};

const conceptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOOLPATH_INSTRUCTIONS:
    case FETCH_ALL_CONCEPTS:
    case FETCH_CONCEPT_GEOMETRY:
    case FETCH_DEFAULT_OPERATORS:
    case UPDATE_CONCEPT:
    case DELETE_CONCEPT:
    case REMOVE_OPERATOR:
    case UPGRADE_OPERATOR:
    case ADD_OPERATOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case FETCH_TOOLPATH_INSTRUCTIONS_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          projectInstructionsURL: action.payload.instructionsURL,
        },
      };
    case FETCH_TOOLPATH_INSTRUCTIONS_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          projectInstructionsURL: null,
        },
      };
    case FETCH_ALL_CONCEPTS_SUCCEEDED: {
      const validOperatorIds =
        action.payload.map((concept) => concept.operators.map((op) => op.id)) ||
        [];
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          missingInputOperators: state.ui.missingInputOperators.filter(
            (op) => !validOperatorIds.includes(op.id),
          ),
          invalidOperators: state.ui.invalidOperators.filter(
            (op) => !validOperatorIds.includes(op.id),
          ),
        },
        data: {
          ...state.data,
          concepts: action.payload,
        },
      };
    }
    case FETCH_ALL_CONCEPTS_FAILED:
    case FETCH_DEFAULT_OPERATORS_FAILED:
    case UPDATE_CONCEPT_FAILED:
    case FETCH_WORKFLOW_FAILED:
    case ADD_OPERATOR_FAILED:
    case FETCH_TOOLPATH_SIMULATION_FAILED:
    case UPGRADE_OPERATOR_SUCCEEDED:
    case UPGRADE_OPERATOR_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case FETCH_CONCEPT_GEOMETRY_FAILED: {
      let failedFetches = state.ui.fetchFailedModelIds;
      if (!failedFetches.includes(action.payload)) {
        failedFetches = failedFetches.concat(action.payload);
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          lastFetchedModelId: action.payload,
          fetchFailedModelIds: failedFetches,
        },
      };
    }
    case FETCH_CONCEPT_GEOMETRY_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          lastFetchedModelId: action.payload.id,
          fetchFailedModelIds: state.ui.fetchFailedModelIds.filter(
            (id) => id !== action.payload.id,
          ),
          designGeometryData: {
            ...state.ui.designGeometryData,
            [action.payload.id]: action.payload.displayData,
          },
        },
      };
    case FETCH_DEFAULT_OPERATORS_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          defaultOperators: {
            ...state.data.defaultOperators,
            [action.payload.version]: action.payload.operators.map(
              (operator) => ({
                name: operator.name,
                nameKey: operator.nameKey,
                translatedName: operator.translatedName,
                settings: operator.settings,
                category: operator.category,
                operatorDescriptor: operator.operatorDescriptor,
                visible: operator.visible,
                templateId: operator.templateId,
              }),
            ),
          },
        },
      };
    case UPDATE_CONCEPT_SUCCEEDED: {
      const otherOperatorInputModifications =
        state.ui.operatorInputModifications.filter(
          (modification) => modification.conceptId !== action.payload.id,
        );
      const otherOperatorModifications = state.ui.operatorModifications.filter(
        (modification) => modification.conceptId !== action.payload.id,
      );
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingConcept: false,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          operatorInputModifications: otherOperatorInputModifications
            ? otherOperatorInputModifications
            : {},
          operatorModifications: otherOperatorModifications
            ? otherOperatorModifications
            : {},
          isOkayUpdatePrinter: false,
        },
        data: {
          ...state.data,
          concepts: state.data.concepts.map((concept) => {
            if (concept.id === action.payload.id) {
              return action.payload;
            }

            return concept;
          }),
        },
      };
    }

    case ADD_OPERATOR_SUCCEEDED: {
      const addedOperator = action.payload;

      if (!addedOperator) {
        return state;
      }

      const nextConceptsData = state.data.concepts.map((concept) => {
        if (concept.id !== addedOperator?.conceptId) {
          return concept;
        }

        return {
          ...concept,
          operators: concat(concept?.operators, addedOperator),
        };
      });

      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          concepts: nextConceptsData,
        },
      };
    }

    case UPDATE_CONCEPTS: {
      return {
        ...state,
        data: {
          ...state.data,
          concepts: action.payload.concepts,
        },
      };
    }

    case UPDATE_WORKFLOW: {
      const { workflow } = action.payload || {};
      const otherOperatorInputModifications =
        state.ui.operatorInputModifications.filter(
          (modification) => modification.conceptId !== workflow?.id,
        );
      const otherOperatorModifications = state.ui.operatorModifications.filter(
        (modification) => modification.conceptId !== workflow?.id,
      );
      const nextConcepts = state.data.concepts.map((concept) => {
        if (concept.id !== workflow?.id) return concept;

        return workflow;
      });

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorInputModifications: otherOperatorInputModifications
            ? otherOperatorInputModifications
            : {},
          operatorModifications: otherOperatorModifications
            ? otherOperatorModifications
            : {},
        },
        data: {
          ...state.data,
          concepts: nextConcepts,
        },
      };
    }

    case FETCH_WORKFLOW:
    case RUN_CONCEPT:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case RUN_CONCEPT_SUCCEEDED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    }
    case RUN_CONCEPT_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case FETCH_WORKFLOW_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          concepts: state.data.concepts
            .filter((concept) => concept.id !== action.payload.id)
            .concat([action.payload]),
        },
      };

    case CREATE_CONCEPT:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
          showLoadingWheel: true,
        },
      };
    case CREATE_CONCEPT_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          showLoadingWheel: false,
          isCreatingConcept: false,
          cloningConceptId: null,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        ...(action.payload.shouldAppend
          ? {
              data: {
                ...state.data,
                concepts: [...state.data.concepts, action.payload.responseBody],
              },
            }
          : {}),
      };

    case CREATE_CONCEPT_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          showLoadingWheel: false,
        },
      };
    case DELETE_CONCEPT_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          conceptIdToDelete: null,
          isDeletingConcept: false,
        },
        data: {
          ...state.data,
          concepts: state.data.concepts.filter(
            (concept) => concept.id !== action.payload.conceptId,
          ),
        },
      };
    case DELETE_CONCEPT_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingConcept: false,
          conceptIdToDelete: null,
        },
      };
    case UPDATE_CAMERA:
      return {
        ...state,
        ui: {
          ...state.ui,
          forceUpdateCamera: action.payload.doUpdate,
        },
      };
    // TODO: set selected operator id to null
    // TODO: remove oerator from state with clonedeep
    case REMOVE_OPERATOR_SUCCEEDED: {
      const operatorValuesIds = state.data.concepts.flatMap((concept) =>
        concept.operators
          .filter((operator) => operator.id === action.payload.operatorId)
          .flatMap((operator) => operator.values.map((value) => value.id)),
      );
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          missingInputOperators: state.ui.missingInputOperators.filter(
            (op) => op.id !== action.payload.operatorId,
          ),
          invalidOperators: state.ui.invalidOperators.filter(
            (op) => op.id !== action.payload.operatorId,
          ),
          operatorInputModifications:
            state.ui.operatorInputModifications.filter(
              (op) => !operatorValuesIds.includes(op.value),
            ),
        },
      };
    }
    case REMOVE_OPERATOR_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingConcept: false,
        },
      };
    case SELECT_OPERATOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedOperators: {
            ...state.ui.selectedOperators,
            [action.payload.conceptId]: {
              ...(state.ui.selectedOperators[action.payload.conceptId] || {}),
              operatorId: action.payload.operatorId,
              conceptId: action.payload.conceptId,
            },
          },
        },
      };
    case SELECT_OPERATOR_OUTPUT:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedOperatorOutputId: action.payload.id,
          selectedOperatorOutputType: action.payload.outputType,
          selectedOutputOperatorId: action.payload.operatorId,
          selectedOperatorOutput: action.payload.output,
        },
      };
    case FREEZE_OPERATOR_OUTPUT:
      return {
        ...state,
        ui: {
          ...state.ui,
          frozenOutputs: [...state.ui.frozenOutputs, action.payload.output],
        },
      };
    case UNFREEZE_OPERATOR_OUTPUT:
      return {
        ...state,
        ui: {
          ...state.ui,
          frozenOutputs: state.ui.frozenOutputs.filter(
            ({ id }) => !action.payload.ids?.includes(id),
          ),
        },
      };
    case RESET_FROZEN_OPERATOR_OUTPUTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          frozenOutputs: [],
        },
      };
    case UPDATE_INVALID_OPERATORS:
      return {
        ...state,
        ui: {
          ...state.ui,
          invalidOperators: action.payload.operators,
        },
      };
    case UPDATE_MISSING_INPUT_OPERATORS:
      return {
        ...state,
        ui: {
          ...state.ui,
          missingInputOperators: action.payload.operators,
        },
      };
    case UPDATE_HIDDEN_INPUT_OPERATORS:
      return {
        ...state,
        ui: {
          ...state.ui,
          hiddenInputs: action.payload.inputs,
        },
      };
    case UPDATE_HIDDEN_OUTPUT_OPERATORS:
      return {
        ...state,
        ui: {
          ...state.ui,
          hiddenOutputs: action.payload.outputs,
        },
      };
    case DISMISS_CREATE_CONCEPT_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCreatingConcept: false,
          cloningConceptId: null,
        },
      };
    case SHOW_DELETE_CONCEPT_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingConcept: true,
          conceptIdToDelete: action.payload.conceptId,
        },
      };
    case DISMISS_DELETE_CONCEPT_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingConcept: false,
          conceptIdToDelete: null,
        },
      };
    case SHOW_UPDATE_PRINTER_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdatePrinter: true,
        },
      };
    case DISMISS_UPDATE_PRINTER_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdatePrinter: false,
        },
      };
    case CONCEPT_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.conceptId]: {
              ...(state.ui.unsavedModifications[action.payload.conceptId] ||
                {}),
              name: action.payload.name,
            },
          },
        },
      };
    case CONCEPT_PRINTER_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.conceptId]: {
              ...(state.ui.unsavedModifications[action.payload.conceptId] ||
                {}),
              printerId: action.payload.printerId,
            },
          },
        },
      };
    case RESET_CONCEPT_MODIFICATIONS:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.conceptId]: {},
          },
        },
      };

    case UPDATE_OUTER_WALL_LINE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingOuterWall: !state.ui.isShowingOuterWall,
        },
      };
    case UPDATE_MILLING_LINE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingMilling: !state.ui.isShowingMilling,
        },
      };
    case UPDATE_INNER_WALL_LINE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingInnerWall: !state.ui.isShowingInnerWall,
        },
      };
    case UPDATE_SKIN_LINE_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingSkin: !state.ui.isShowingSkin },
      };
    case UPDATE_SUPPORT_LINE_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingSupport: !state.ui.isShowingSupport },
      };
    case UPDATE_SUPPORT_INTERFACE_LINE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingSupportInterface: !state.ui.isShowingSupportInterface,
        },
      };
    case UPDATE_BRIM_LINE_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingBrim: !state.ui.isShowingBrim },
      };
    case UPDATE_INFILL_LINE_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingInfill: !state.ui.isShowingInfill },
      };
    case UPDATE_SEAMS_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingSeams: !state.ui.isShowingSeams },
      };
    case TOGGLE_SOLID_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingSolid: !state.ui.isShowingSolid },
      };
    case UPDATE_ROTATIONS_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingRotations: !state.ui.isShowingRotations },
      };
    case UPDATE_VERTICES_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingVertices: !state.ui.isShowingVertices },
      };
    case UPDATE_DIRECTION_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingDirection: !state.ui.isShowingDirection },
      };
    case UPDATE_DIMENSION_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingDimensions: !state.ui.isShowingDimensions },
      };
    case UPDATE_LINETYPE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          lineData: action.payload.mode,
        },
      };
    case UPDATE_LEGEND_RANGE:
      return {
        ...state,
        ui: {
          ...state.ui,
          legendMinRange: action.payload.minRange,
          legendMaxRange: action.payload.maxRange,
        },
      };
    case UPDATE_GRID_VISIBILITY:
      return {
        ...state,
        ui: { ...state.ui, isShowingGrid: !state.ui.isShowingGrid },
      };
    case UPDATE_ROBOT_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingRobot:
            action.payload.enabled == undefined
              ? !state.ui.isShowingRobot
              : action.payload.enabled,
        },
      };
    case UPDATE_PRINTINGBED_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingPrintingBed:
            action.payload.enabled == undefined
              ? !state.ui.isShowingPrintingBed
              : action.payload.enabled,
        },
      };
    case UPDATE_WORKSPACE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingWorkspace:
            action.payload.enabled == undefined
              ? !state.ui.isShowingWorkspace
              : action.payload.enabled,
        },
      };
    case UPDATE_ENCLOSURE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShowingEnclosure:
            action.payload.enabled == undefined
              ? !state.ui.isShowingEnclosure
              : action.payload.enabled,
        },
      };
    case UPDATE_DISPLAY_MODE:
      return {
        ...state,
        ui: {
          ...state.ui,
          displayMode: action.payload.mode,
        },
      };
    case UPDATE_CAMERA_POSITION:
      return {
        ...state,
        ui: {
          ...state.ui,
          cameraX: action.payload.cameraX,
          cameraY: action.payload.cameraY,
          cameraZ: action.payload.cameraZ,
          forceUpdateCamera: true,
        },
      };
    case CHANGE_CURRENT_FOCUS_MODEL:
      return {
        ...state,
        ui: {
          ...state.ui,
          focusmodel: action.payload.focusmodel,
        },
      };
    case OPERATOR_INPUT_MODIFIED: {
      const otherModifiedInputs =
        state.ui.operatorInputModifications.filter(
          (modification) => modification.id !== action.payload.id,
        ) || [];

      return {
        ...state,
        ui: {
          ...state.ui,
          operatorInputModifications: otherModifiedInputs.concat([
            {
              id: action.payload.id,
              operatorId: action.payload.operatorId,
              conceptId: action.payload.conceptId,
              value: action.payload.value,
              tag: action.payload.tag,
              name: action.payload.name,
              nameKey: action.payload.nameKey,
              translatedName: action.payload.translatedName,
              type: action.payload.type,
            },
          ]),
        },
      };
    }

    case REMOVE_OPERATOR_INPUT_MODIFICATIONS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorInputModifications:
            state.ui?.operatorInputModifications?.filter(
              ({ id }) => !action?.payload?.inputIds?.includes(id),
            ),
        },
      };
    }

    case UPDATE_OPERATOR_ORDER: {
      const { conceptId, operators } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          concepts: state.data.concepts.map((concept) => {
            if (concept.id !== conceptId) return concept;

            return {
              ...concept,
              operators: operators,
            };
          }),
        },
      };
    }

    case UPDATE_OPERATOR_ORDER_SUCCEEDED:
    case UPDATE_OPERATOR_ORDER_FAILED: {
      const { conceptId, operators } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          concepts: state.data.concepts.map((concept) => {
            if (concept.id !== conceptId) return concept;

            return {
              ...concept,
              operators: operators,
            };
          }),
        },
      };
    }
    case OPERATOR_MODIFIED: {
      const otherModifiedOperators = (
        state.ui.operatorModifications || []
      ).filter((modification) => modification.id !== action.payload.id);
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorModifications: otherModifiedOperators.concat([
            {
              conceptId: action.payload.conceptId,
              id: action.payload.id,
              order: action.payload.order,
              tag: action.payload.tag,
            },
          ]),
        },
      };
    }
    case ADD_NEW_OPERATORVALUE_TO_LIST_SUCCEEDED: {
      const concept = state.data.concepts.find(
        (modification) => modification.id === action.payload.conceptId,
      );
      const otherConcepts = state.data.concepts.filter(
        (modification) => modification.id !== action.payload.conceptId,
      );
      const operator = concept.operators.find(
        (modification) =>
          modification.id === action.payload.operatorValue.operatorId,
      );
      const otherOperators = concept.operators.filter(
        (modification) =>
          modification.id !== action.payload.operatorValue.operatorId,
      );
      const listOperatorValues = operator.values.filter(
        (value) => value.type === 'LIST_GEOMETRY_CLASSIFIED_POLYLINES',
      );
      const otherOperatorValues = operator.values.filter(
        (value) => !listOperatorValues.includes(value),
      );
      return {
        ...state,
        data: {
          ...state.data,
          concepts: [
            ...otherConcepts,
            {
              ...concept,
              operators: [
                ...otherOperators,
                {
                  ...operator,
                  values: [
                    ...listOperatorValues,
                    action.payload.operatorValue,
                    ...otherOperatorValues,
                  ],
                },
              ],
            },
          ],
        },
      };
    }
    case REMOVE_OPERATORVALUE_FROM_LIST_SUCCEEDED: {
      const concept = state.data.concepts.find(
        (modification) => modification.id === action.payload.conceptId,
      );
      const otherConcepts = state.data.concepts.filter(
        (modification) => modification.id !== action.payload.conceptId,
      );
      const operator = concept.operators.find(
        (modification) => modification.id === action.payload.operatorId,
      );
      const otherOperators = concept.operators.filter(
        (modification) => modification.id !== action.payload.operatorId,
      );

      return {
        ...state,
        data: {
          ...state.data,
          concepts: [
            ...otherConcepts,
            {
              ...concept,
              operators: [
                ...otherOperators,
                {
                  ...operator,
                  computed: false,
                  values: action.payload.updatedOperatorValues,
                },
              ],
            },
          ],
        },
      };
    }
    case FETCH_TOOLPATH_SIMULATION:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
          simulation: {
            ...state.ui.simulation,
            isActive: false,
          },
        },
      };
    case FETCH_TOOLPATH_SIMULATION_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          simulation: {
            ...state.ui.simulation,
            url: action.payload,
          },
        },
      };

    case ENTER_TOOLPATH_SIMULATION:
      return {
        ...state,
        ui: {
          ...state.ui,
          simulation: {
            ...state.ui.simulation,
            isActive: true,
            printingObject: action.payload.printingObject,
            maxStepNumber: action.payload.maxStepNumber,
            maxTime: action.payload.maxTime,
            printerSteps: action.payload.printerSteps,
            printingObjectCoordinates: action.payload.printingObjectCoordinates,
            step: 0,
            time: 0,
          },
        },
      };
    // Set toolpath simulation time and step based on payload data
    case SET_TOOLPATH_SIMULATION: {
      let newTime = action.payload;
      // Check if the time data in the payload is not exceed the max time.
      newTime =
        newTime > state.ui.simulation.maxTime
          ? state.ui.simulation.maxTime - 1
          : newTime;
      // Find the step that corresponding that time
      let newStep = 0;
      for (
        newStep = 0;
        newStep < state.ui.simulation.printerSteps.length;
        newStep++
      ) {
        if (state.ui.simulation.printerSteps[newStep].time > newTime) {
          break;
        }
      }
      newStep = Math.min(newStep, state.ui.simulation.maxStepNumber);
      return {
        ...state,
        ui: {
          ...state.ui,
          simulation: {
            ...state.ui.simulation,
            step: newStep,
            time: newTime,
          },
        },
      };
    }
    case EXIT_TOOLPATH_SIMULATION:
      return {
        ...state,
        ui: {
          ...state.ui,
          simulation: {
            ...state.ui.simulation,
            isActive: false,
            printingObject: null,
            maxStepNumber: 1,
            printerSteps: [],
            step: 1,
            url: '',
          },
        },
      };
    case UPDATE_SIMULATION_TRAVELLINE_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          simulation: {
            ...state.ui.simulation,
            showTravelLine: !state.ui.simulation.showTravelLine,
          },
        },
      };
    case TOGGLE_OPERATOR_DESCRIPTORS_POPUP: {
      const isSameOperator =
        action.payload.operator === state.ui.operatorDescriptorPopup.operator;
      const isShowing = isSameOperator
        ? !state.ui.operatorDescriptorPopup.isShowing
        : true;
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorDescriptorPopup: {
            isShowing,
            operatorDisplayName: action.payload.operatorDisplayName,
            operator: action.payload.operator,
          },
        },
      };
    }
    case DISMISS_OPERATOR_DESCRIPTORS_POPUP:
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorDescriptorPopup: {
            isShowing: false,
            operatorDisplayName: '',
            operator: null,
          },
        },
      };

    case TOKEN_EXPIRE_LOG_OUT_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: initialState.ui.fetchesInProgress,
        },
      };
    }

    case UPDATE_LAST_ADDED_OPERATOR_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          lastAddedOperatorId: action.payload.operatorId,
        },
      };

    case RESET_LAST_ADDED_OPERATOR_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          lastAddedOperatorId: initialState.ui.lastAddedOperatorId,
        },
      };

    case EXPLODE_TEMPLATE_SUCCESS: {
      const { workflow } = action.payload;

      if (!workflow.id) return state;

      return {
        ...state,
        data: {
          ...state.data,
          concepts: state.data.concepts.map((currentWorkflow) =>
            currentWorkflow.id === workflow.id ? workflow : currentWorkflow,
          ),
        },
      };
    }

    case SET_TOOLPATH_SIMULATION_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          toolpathSimulationIsVisible: action.payload.visible,
        },
      };

    case OPRATOR_SELECTOR_DRAG_START:
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorsSelector: {
            ...state.ui.operatorsSelector,
            draggingOperatorId: action?.payload?.operatorId,
          },
        },
      };

    case OPRATOR_SELECTOR_DRAG_END:
      return {
        ...state,
        ui: {
          ...state.ui,
          operatorsSelector: {
            ...state.ui.operatorsSelector,
            draggingOperatorId: initialState.ui?.draggingOperatorId,
          },
        },
      };
    case actionTypes.CONCEPT_NOZZLE_MODIFIED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.conceptId]: {
              ...(state.ui.unsavedModifications[action.payload.conceptId] ||
                {}),
              nozzleId: action.payload.nozzleId,
            },
          },
        },
      };
    }
    case actionTypes.CONCEPT_MATERIAL_MODIFIED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.conceptId]: {
              ...(state.ui.unsavedModifications[action.payload.conceptId] ||
                {}),
              materialId: action.payload.materialId,
            },
          },
        },
      };
    }
    case actionTypes.SHOW_UPDATE_NOZZLE_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdateNozzle: true,
        },
      };
    }
    case actionTypes.DISMISS_UPDATE_NOZZLE_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdateNozzle: false,
        },
      };
    }

    case actionTypes.UPDATE_WORKFLOW_PRINTER_CONFIG_SUCCESS:
    case actionTypes.UPDATE_CONCEPT_WITH_MATERIAL_SUCCESS:
    case actionTypes.UPDATE_CONCEPT_WITH_NOZZLE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          concepts: state.data.concepts.map((concept) => {
            if (concept.id === action.payload.id) {
              return action.payload;
            }

            return concept;
          }),
        },
      };
    }

    case actionTypes.SHOW_UPDATE_MATERIAL_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdateMaterial: true,
        },
      };
    }

    case actionTypes.DISMISS_UPDATE_MATERIAL_DIALOG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isOkayUpdateMaterial: false,
        },
      };
    }

    default:
      return state;
  }
};

export default conceptsReducer;
