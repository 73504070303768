import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import IconButton, {
  ICON_BUTTON_VARIANT_TONAL,
} from '@components/1-atoms/IconButton';
import ButtonDropDown from '@app/components/1-atoms/ButtonDropDown';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import Slider from '@components/1-atoms/Slider';
import MenuListItem from '@components/1-atoms/MenuListItem';
import { DROP_DOWN_MENU_POSITION_TOP_RIGHT } from '@components/2-molecules/DropDownMenu';
import { Wrapper } from './SceneSimulationActionBar.styled';

const SceneSimulationActionBar = ({
  dataTestId = 'scene-simulation-action-bar',
  isPlaying,
  isLoop,
  moreIconButtonDropDownMenuItems = [],
  onLoopIconButtonClick,
  onPlayIconButtonClick,
  slider = {},
  speedButtonDropDown = {},
}) => {
  const intl = useIntl();
  const showMoreIconButton = !!moreIconButtonDropDownMenuItems.length;

  return (
    <Wrapper dataTestId={dataTestId}>
      <IconButton
        dataTestId={`${dataTestId}-play-icon-button`}
        variant={ICON_BUTTON_VARIANT_TONAL}
        iconName={isPlaying ? 'pause_1' : 'play_arrow_1'}
        title={intl.formatMessage({
          id: isPlaying ? 'general.pause' : 'general.play',
          defaultMessage: isPlaying ? 'Pause' : 'Play',
        })}
        onClick={onPlayIconButtonClick}
      />

      <Slider {...slider} />

      <IconButton
        dataTestId={`${dataTestId}-loop-icon-button`}
        title={intl.formatMessage({
          id: 'scene_simulation.action_bar.loop_button_title',
          defaultMessage: 'Loop Playback',
        })}
        iconName="laps_0"
        selected={isLoop}
        onClick={onLoopIconButtonClick}
      />

      <ButtonDropDown
        dataTestId={`${dataTestId}-speed-button-drop-down`}
        title={intl.formatMessage({
          id: 'scene_simulation.action_bar.speed_button_title',
          defaultMessage: 'Playback speed',
        })}
        {...speedButtonDropDown}
        shrink={false}
        dropDownMenuPosition={DROP_DOWN_MENU_POSITION_TOP_RIGHT}
      />

      {showMoreIconButton && (
        <>
          <VerticalDivider middleInset inlineMiddleInset />

          <IconButton
            dataTestId={`${dataTestId}-more-icon-button`}
            title={intl.formatMessage({
              id: 'general.more',
              defaultMessage: 'More',
            })}
            iconName="more_vert_0"
            dropDownMenuItems={moreIconButtonDropDownMenuItems}
            dropDownMenuPosition={DROP_DOWN_MENU_POSITION_TOP_RIGHT}
          />
        </>
      )}
    </Wrapper>
  );
};

SceneSimulationActionBar.propTypes = {
  dataTestId: PropTypes.string,
  isPlaying: PropTypes.bool,
  isLoop: PropTypes.bool,
  moreIconButtonDropDownMenuItems: PropTypes.arrayOf(
    PropTypes.shape(MenuListItem.propTypes),
  ),
  onLoopIconButtonClick: PropTypes.func,
  onPlayIconButtonClick: PropTypes.func,
  slider: PropTypes.shape(Slider.propTypes),
  speedButtonDropDown: PropTypes.shape(ButtonDropDown.propTypes),
};

export default SceneSimulationActionBar;
