import * as THREE from 'three';
import { RotaryTable } from './RotaryTable';
import { Printer } from '../Printer';
import { getBedDefinitionResponse } from './BedUtils';
import { s3Directories } from '../../constants/printers/s3Directories';
import { printerConstants } from '../../constants/printers/printerConstants';
import { bedTypeDefinitions } from '../../constants/printers/printingBedDefaultProperties';

class RotaryTableCustom extends RotaryTable {
  constructor(printerSettings, printingBedDefinitions, plinthDefinitions) {
    super(
      printerSettings,
      RotaryTableCustom.getMachineDefaults(),
      printingBedDefinitions,
      plinthDefinitions,
    );
  }

  getBedType() {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );
    return bedTypeDefinitions[bedDefinitionResponse.bedType];
  }

  static getMachineDefaults() {
    return {
      bedMovement: false,
      bedOffsets: { x: 0, y: 0, z: 0 },
    };
  }

  setBaseFrame(baseTransformationMatrix) {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );
    this.numberOfJoints = 1;
    const axis0 = new THREE.Group();
    const axis1 = new THREE.Group();
    const base = new THREE.Group();

    axis0.add(new THREE.AxesHelper(1000));

    axis1.translateZ(bedDefinitionResponse.d1);
    axis1.add(new THREE.AxesHelper(1000));

    base.translateZ(bedDefinitionResponse.d2);
    base.add(new THREE.AxesHelper(1000));

    this.axis0 = axis0;
    this.axis1 = axis1;
    this.base = base;

    this.add(axis0);
    this.axis0.add(axis1);
    this.axis1.add(base);
    this.base.add(new THREE.AxesHelper(100));

    this.position.setFromMatrixPosition(baseTransformationMatrix);
    this.setRotationFromMatrix(baseTransformationMatrix);

    // We must offset in order to position the surface at the base calibration position
    axis0.translateZ(-bedDefinitionResponse.d1);
    axis0.translateZ(-bedDefinitionResponse.d2);
  }

  /**
   * Asynchronously loads the models required for visualization of the external
   * axis and adds them to the individual axes groups.
   * @returns resolved promise on completion of loading
   */
  initializeModels() {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );
    return new Promise((resolve) => {
      if (!bedDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = bedDefinitionResponse;
      const modelPromises = []; // Array to hold all the promises for the robot models

      const baseUrl = modelUrls[s3Directories.bedBase];
      const axis1Url = modelUrls[s3Directories.bedAxis1];

      modelPromises.push(Printer.getModel(baseUrl));
      modelPromises.push(Printer.getModel(axis1Url));

      // Wait for all models to load
      Promise.all(modelPromises).then((bedModels) => {
        for (let axisNumber = 0; axisNumber <= 1; axisNumber++) {
          const model = bedModels[axisNumber];
          this.addCustomAxis(model, axisNumber, bedDefinitionResponse);
        }
        resolve();
      });
    });
  }

  addCustomAxis(model, axisNumber, bedDefinitionResponse) {
    if (axisNumber == 1) {
      model.children.forEach((child) => {
        child.position.z = -bedDefinitionResponse.d1;
      });
    }
    this[printerConstants.axis + axisNumber].add(model);
  }

  moveToHome() {
    //do nothing for now
  }

  getAxis0Scale() {
    return 1;
  }

  getPrintingBedSettingsType() {
    return 'RotaryBedSettingsDefault';
  }
}

export default RotaryTableCustom;
