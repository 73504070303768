import styled, { css } from 'styled-components';
import { BADGE_VARIANT_DOT, BADGE_VARIANT_LABEL } from './Badge';
import { flexCenter, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenter}

  ${({ variant, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.error};

    ${when(
      variant === BADGE_VARIANT_DOT,
      `
        width: 6px;
        height: 6px;
      `,
    )}

    ${when(
      variant === BADGE_VARIANT_LABEL,
      `
        padding: 0 4px;
      `,
    )}
  `}
`;
