import { takeEvery, put } from 'redux-saga/effects';
import client from '@api/client';
import endpoints from '@api/endpoints';
import {
  getPrinterCamerasSuccess,
  getPrinterCamerasFailure,
  updateCameraSensitivitySuccess,
  updateCameraSensitivityFailure,
} from '@actions/analyticsActions';
import * as actions from '@constants/actionTypes';

export function* fetchCameras({ payload }) {
  const { cameras } = payload;

  try {
    const requests = cameras?.map(async (camera) => {
      const requestUrl = endpoints.camera
        .replace(':printerId', camera?.printerId)
        .replace(':cameraId', camera?.cameraId);

      const response = await client.get(requestUrl);

      return response?.data;
    });
    const camerasData = yield Promise.all(requests);

    yield put(getPrinterCamerasSuccess(camerasData));
  } catch (error) {
    yield put(getPrinterCamerasFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* updateCameraSensitivity({ payload }) {
  const { printerId, cameraId, sensitivity } = payload;

  try {
    const requestUrl = endpoints.camera
      .replace(':printerId', printerId)
      .replace(':cameraId', cameraId);
    const response = yield client.patch(requestUrl, {
      model_sensitivity: sensitivity,
    });
    const cameraData = response?.data;
    const camera = cameraData?.camerasObj?.[0];

    yield put(updateCameraSensitivitySuccess(camera?.id, cameraData));
  } catch (error) {
    yield put(updateCameraSensitivityFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export default [
  takeEvery(actions.FETCH_PRINTER_CAMERAS_REQUEST, fetchCameras),
  takeEvery(actions.UPDATE_CAMERA_SENSITIVITY, updateCameraSensitivity),
];
