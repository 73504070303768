import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import { isEmpty } from 'lodash';
import usePrinter from '@hooks/printers/usePrinter';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import Field from '@components/1-atoms/Field';
import DropDownField from '@components/1-atoms/DropDownField';
import { DROP_DOWN_MENU_ID } from '@components/2-molecules/DropDownMenu';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import getIntlProvider from '@utils/getIntlProvider';
import { printerConstants } from '@constants/printers/printerConstants';
import { ROUTES } from '@constants/router';
import { materialSortFunction } from '@utils/material';
import { attachBlurEventToTheRelatedTarget } from '@utils/dom';
import { Fields } from './CreateWorkflow.styled';

const intl = getIntlProvider();

const CreateWorkflow = ({
  dataTestId = 'create-project',
  title = intl.formatMessage({
    id: 'createproject.workflow.header.title',
    defaultMessage: 'Workflow setup',
  }),
  subtitle = intl.formatMessage({
    id: 'createproject.workflow.header.subtitle',
    defaultMessage:
      'A workflow refers to a series of instructions or manufacturing steps',
  }),
  fieldAutoFocus,
  project,
  workflow,
}) => {
  const intl = useIntl();
  const form = useFormContext();
  const { setValue, watch, formState } = form;
  const { isSubmitting, errors, isSubmitted } = formState;
  const selectedPrinterId = watch('printer');

  const { printersQuery, allNozzlesQuery, materialsQuery } =
    usePrinterQueries();
  const { projectWorkflowsQuery } = useWorkflowQueries({
    projectId: project?.id,
  });

  const refetchPrintersQuery = printersQuery.refetch;
  const refetchAllNozzlesQuery = allNozzlesQuery.refetch;
  const refetchMaterialsQuery = materialsQuery.refetch;
  const isLoading =
    !printersQuery?.isFetched ||
    allNozzlesQuery.isFetching ||
    materialsQuery.isFetching;

  const { getExtruderDefinition, getPrinterMaterials, getPrinterNozzles } =
    usePrinter();
  const materials = useMemo(
    () => getPrinterMaterials(selectedPrinterId),
    [selectedPrinterId, getPrinterMaterials],
  );
  const nozzles = useMemo(
    () => getPrinterNozzles(selectedPrinterId),
    [selectedPrinterId, getPrinterNozzles],
  );

  const isWorkflowNameValid = !errors?.workflowName;
  const disablePrinterSettingFields =
    isSubmitting || isLoading || !isWorkflowNameValid;
  const showNozzleMaterialFields =
    selectedPrinterId && allNozzlesQuery.isFetched && materialsQuery.isFetched;

  const isNozzleSelectionEnabled = useCallback(
    (printerId) => {
      const printer = printersQuery.data?.find(
        (printer) => printer.id === printerId,
      );
      const extruderDefinition = getExtruderDefinition(printer);

      return printerConstants.METAL_WIRE !== extruderDefinition?.extrusionType;
    },
    [printersQuery.data, getExtruderDefinition],
  );

  const getPrinterDropDownMenuItems = useCallback(() => {
    return printersQuery.data
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((printer) => ({
        label: printer.name,
        value: printer.id,
      }));
  }, [printersQuery.data]);

  const getNozzleDropDownMenuItems = useCallback(() => {
    return nozzles?.map((nozzle) => ({
      label: nozzle.name,
      value: nozzle.id,
    }));
  }, [nozzles]);

  const getMaterialDropDownMenuItems = useCallback(() => {
    return materials?.sort(materialSortFunction).map((material) => ({
      label: material.materialName,
      value: material.id,
    }));
  }, [materials]);

  const setDropDownFieldValue = useCallback(
    (field, option) => () => {
      field.onChange({ target: { value: option?.value } });
    },
    [],
  );

  const getDropDownGeneralProps = useCallback(
    (field, fieldState, getDropDownMenuItems = () => []) => {
      const error = (fieldState.isTouched || isSubmitted) && fieldState.error;
      const options = getDropDownMenuItems();

      const selectedOption = options?.find(
        (option) => option?.value === field.value,
      ) || { label: '', value: '' };
      const dropDownMenuItems = options?.map((option) => ({
        ...option,
        selected: option.value === selectedOption.value,
        onClick: setDropDownFieldValue(field, option),
      }));

      return {
        dropDownMenuItems,
        error: !!error,
        supportingText: error?.message,
        value: selectedOption.label,
        onBlur: attachBlurEventToTheRelatedTarget(
          DROP_DOWN_MENU_ID,
          field.onBlur,
        ),
      };
    },
    [isSubmitted, setDropDownFieldValue],
  );

  useEffect(() => {
    if (!printersQuery?.isFetched) {
      refetchPrintersQuery();
    }

    if (!allNozzlesQuery?.isFetched) {
      refetchAllNozzlesQuery();
    }

    if (!materialsQuery?.isFetched) {
      refetchMaterialsQuery();
    }
  }, [
    printersQuery?.isFetched,
    allNozzlesQuery?.isFetched,
    materialsQuery?.isFetched,
    refetchPrintersQuery,
    refetchAllNozzlesQuery,
    refetchMaterialsQuery,
  ]);

  useEffect(() => {
    setValue('nozzle', nozzles?.[0]?.id);
  }, [setValue, nozzles]);

  useEffect(() => {
    if (!isNozzleSelectionEnabled(selectedPrinterId)) {
      setValue('nozzle', null);
    }
  }, [setValue, selectedPrinterId, isNozzleSelectionEnabled]);

  useEffect(() => {
    setValue('material', materials?.[0]?.id);
  }, [setValue, materials]);

  return (
    <>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_MEDIUM}
        title={title}
        subtitle={subtitle}
      />

      <Fields>
        <Controller
          name="workflowName"
          defaultValue="Untitled workflow"
          rules={{
            required: intl.formatMessage({
              id: 'createproject.project.workflowname.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
            minLength: {
              value: 5,
              message: intl.formatMessage({
                id: 'createproject.project.workflowname.validation.min',
                defaultMessage: 'Name must be at least 5 characters long',
              }),
            },
            maxLength: {
              value: 256,
              message: intl.formatMessage({
                id: 'createproject.project.workflowname.validation.max',
                defaultMessage: 'Name must be no longer than 256 characters',
              }),
            },
            validate: (value) => {
              if (!project) return;

              const projectWorkflows = projectWorkflowsQuery.data;
              const isNameUnique = !projectWorkflows?.some(
                (workflow) => workflow.name === value,
              );

              if (!isNameUnique) {
                return intl.formatMessage({
                  id: 'createworkflow.workflowname.validation.unique',
                  defaultMessage: 'Name must be unique',
                });
              }
            },
          }}
          render={({ field, fieldState }) => {
            const error =
              (fieldState.isTouched || isSubmitted) && fieldState.error;

            return (
              <Field
                dataTestId={`${dataTestId}-name-field`}
                autoFocus={fieldAutoFocus}
                disabled={isSubmitting}
                name="workflowName"
                label={intl.formatMessage({
                  id: 'createproject.project.workflowname.label',
                  defaultMessage: 'Name',
                })}
                placeholder={intl.formatMessage({
                  id: 'createproject.project.workflowname.placeholder',
                  defaultMessage: 'Untitled workflow',
                })}
                error={!!error}
                supportingText={error?.message}
                {...field}
              />
            );
          }}
        />

        <Controller
          name="printer"
          rules={{
            required: intl.formatMessage({
              id: 'createworkflow.printer.validation.required',
              defaultMessage: 'Please select an option',
            }),
            deps: ['nozzle', 'material'],
          }}
          render={({ field, fieldState }) => {
            const generalProps = getDropDownGeneralProps(
              field,
              fieldState,
              getPrinterDropDownMenuItems,
            );

            // Duplicate flow
            const isOriginalPrinterChanged =
              !isEmpty(workflow) &&
              field.value &&
              workflow?.printerId !== field.value;
            if (!generalProps.error && isOriginalPrinterChanged) {
              generalProps.supportingText = intl.formatMessage({
                id: 'createworkflow.printer.validation.changed',
                defaultMessage:
                  'The printer you have selected is different from the one used in the original workflow. This could result in compatibility issues.',
              });
            }

            return (
              <DropDownField
                dataTestId={`${dataTestId}-printer-dropdown-field`}
                disabled={disablePrinterSettingFields}
                label={intl.formatMessage({
                  id: 'createworkflow.printer.label',
                  defaultMessage: 'Printer',
                })}
                placeholder={intl.formatMessage({
                  id: 'createworkflow.printer.placeholder',
                  defaultMessage: 'Select Printer',
                })}
                {...generalProps}
                emptyDropDownStateIconName="precision_manufacturing_0"
                emptyDropDownStateTitle={intl.formatMessage({
                  id: 'createworkflow.printer.empty_state.title',
                  defaultMessage: 'No printer yet',
                })}
                emptyDropDownStateDescription={intl.formatMessage({
                  id: 'createworkflow.printer.empty_state.description',
                  defaultMessage: 'To continue please add a printer',
                })}
                emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                  id: 'createworkflow.printer.empty_state.link_button.label',
                  defaultMessage: 'Add Printer',
                })}
                emptyDropDownStateLinkButtonPath={ROUTES.PRINTERS}
              />
            );
          }}
        />

        {showNozzleMaterialFields && (
          <>
            {isNozzleSelectionEnabled(selectedPrinterId) && (
              <Controller
                name="nozzle"
                rules={{
                  required: intl.formatMessage({
                    id: 'createworkflow.nozzle.validation.required',
                    defaultMessage: 'Please select an option',
                  }),
                }}
                render={({ field, fieldState }) => {
                  const generalProps = getDropDownGeneralProps(
                    field,
                    fieldState,
                    getNozzleDropDownMenuItems,
                  );

                  // Duplicate flow
                  const isOriginalNozzleChanged =
                    !isEmpty(workflow) &&
                    field.value &&
                    workflow?.nozzleId !== field.value;
                  if (!generalProps.error && isOriginalNozzleChanged) {
                    generalProps.supportingText = intl.formatMessage({
                      id: 'createworkflow.nozzle.validation.changed',
                      defaultMessage:
                        'This nozzle is different from the one used in the original workflow. This could result in compatibility issues.',
                    });
                  }

                  return (
                    <DropDownField
                      dataTestId={`${dataTestId}-nozzle-dropdown-field`}
                      disabled={disablePrinterSettingFields}
                      label={intl.formatMessage({
                        id: 'createworkflow.nozzle.label',
                        defaultMessage: 'Nozzle',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'createworkflow.nozzle.placeholder',
                        defaultMessage: 'Select Nozzle',
                      })}
                      {...generalProps}
                      emptyDropDownStateIconName="precision_manufacturing_0"
                      emptyDropDownStateTitle={intl.formatMessage({
                        id: 'createworkflow.nozzle.empty_state.title',
                        defaultMessage: 'No nozzle yet',
                      })}
                      emptyDropDownStateDescription={intl.formatMessage({
                        id: 'createworkflow.nozzle.empty_state.description',
                        defaultMessage: 'To continue please add a nozzle',
                      })}
                      emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                        id: 'createworkflow.nozzle.empty_state.link_button.label',
                        defaultMessage: 'Add Nozzle',
                      })}
                      emptyDropDownStateLinkButtonPath={generatePath(
                        ROUTES.PRINTER,
                        {
                          printerId: selectedPrinterId,
                          directory: 'prints',
                        },
                      )}
                    />
                  );
                }}
              />
            )}

            <Controller
              name="material"
              rules={{
                required: intl.formatMessage({
                  id: 'createworkflow.nozzle.validation.required',
                  defaultMessage: 'Please select an option',
                }),
              }}
              render={({ field, fieldState }) => {
                const generalProps = getDropDownGeneralProps(
                  field,
                  fieldState,
                  getMaterialDropDownMenuItems,
                );

                // Duplicate flow
                const isOriginalMaterialChanged =
                  !isEmpty(workflow) &&
                  field.value &&
                  workflow?.materialId !== field.value;
                if (!generalProps.error && isOriginalMaterialChanged) {
                  generalProps.supportingText = intl.formatMessage({
                    id: 'createworkflow.material.validation.changed',
                    defaultMessage:
                      'This material is different from the one used in the original workflow. This could result in compatibility issues.',
                  });
                }

                return (
                  <DropDownField
                    dataTestId={`${dataTestId}__material-dropdown-field`}
                    disabled={disablePrinterSettingFields}
                    name="material"
                    label={intl.formatMessage({
                      id: 'createproject.project.material.label',
                      defaultMessage: 'Material',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'createproject.project.material.placeholder',
                      defaultMessage: 'Select Material',
                    })}
                    {...generalProps}
                    emptyDropDownStateIconName="precision_manufacturing_0"
                    emptyDropDownStateTitle={intl.formatMessage({
                      id: 'createproject.project.material.empty_state.title',
                      defaultMessage: 'No material yet',
                    })}
                    emptyDropDownStateDescription={intl.formatMessage({
                      id: 'createproject.project.material.empty_state.description',
                      defaultMessage: 'To continue please add a material',
                    })}
                    emptyDropDownStateLinkButtonLabel={intl.formatMessage({
                      id: 'createproject.project.material.empty_state.link_button.label',
                      defaultMessage: 'Add Material',
                    })}
                    emptyDropDownStateLinkButtonPath={generatePath(
                      ROUTES.MATERIALS,
                    )}
                  />
                );
              }}
            />
          </>
        )}
      </Fields>
    </>
  );
};

CreateWorkflow.propTypes = {
  dataTestId: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fieldAutoFocus: PropTypes.bool,
  project: PropTypes.object,
  workflow: PropTypes.object,
};

export default CreateWorkflow;
