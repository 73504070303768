import styled, { css } from 'styled-components';
import { flexColumn, flexCenterVertical } from '@stylesheets/helpers';
import Text, { TEXT_VARIANT_TITLE_MEDIUM } from '@components/1-atoms/Text';

export const Wrapper = styled.div`
  ${flexColumn}
`;

export const Diff = styled.div`
  max-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;

  ${({ theme: { spacing, borderRadius, colors } }) => css`
    border: 1px solid ${colors.outlineVariant};
    border-radius: ${borderRadius.extrasmall};
    margin-bottom: ${spacing.level6};
  `}
`;

export const DiffHeader = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: 15px ${spacing.level5} 9px;
  `}
`;

export const DiffTitle = styled(Text).attrs({
  variant: TEXT_VARIANT_TITLE_MEDIUM,
  color: 'onSurface',
})``;

export const DiffColumn = styled.div`
  width: 50%;
  ${flexColumn}
  padding-bottom: 88px;

  &:first-child {
    ${({ theme: { colors } }) => css`
      border-right: 1px solid ${colors.outlineVariant};
    `}
  }
`;

export const DiffContent = styled.div`
  ${flexColumn}
  overflow: auto;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: 0 ${spacing.level5} ${spacing.level4} ${spacing.level5};
  `}
`;

export const ActionButtons = styled.div`
  width: 100%;
  ${flexCenterVertical}
  justify-content: flex-end;
  position: absolute;
  bottom: 1px;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level6};
  `}
`;
