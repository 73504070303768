import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import {
  XYPlot,
  XAxis,
  YAxis,
  LineSeries,
  DiscreteColorLegend,
  HorizontalGridLines,
  VerticalGridLines,
} from 'react-vis';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import useModal from '@hooks/useModal';
import { getPrinterSensorValues } from '@selectors/analyticsSelectors';
import {
  PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP,
  START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET,
  END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET,
} from '@constants/utilityConstants.js';
import { colorPalette } from '@constants/colorPalette';
import { MODAL_IDS } from '@constants/modalDataTypes';
import PageLoaderPortal from '@components/2-molecules/PageLoaderPortal';
import ModalPortal from '@components/2-molecules/ModalPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import {
  ContentWrapper,
  AnalyticsGraph,
  AnalyticsLegend,
} from './PrinterSensor.styled';

const LOADING_TIMEOUT = 5000;

const MODAL_ID = MODAL_IDS.PRINTER_SENSOR_DATA;

const formatValueDataIntoPoints = (values = [], timings = []) =>
  values.map((value, idx) => ({
    x: timings[idx],
    y: value,
  }));

const getDisplayedName = (prefix, currentValue, targetValue) => {
  let displayName =
    prefix +
    START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET +
    currentValue;
  if (targetValue) {
    displayName +=
      '/' + targetValue + END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET;
  } else {
    displayName += END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET;
  }
  return displayName;
};

const PrinterSensor = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { getModalData } = useModal();

  const contentWrapperRef = useRef();
  const analyticsLegendRef = useRef();

  usePrinterSocket();

  const modalData = useMemo(() => getModalData(MODAL_ID), [getModalData]);

  const printer = modalData?.printer;
  const sensorsValues = useSelector(
    getPrinterSensorValues(printer?.serialCode),
  );
  const hasSensorValues = !isEmpty(sensorsValues);
  const sensorsTimings =
    sensorsValues[PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP] || [];
  const lastTiming =
    sensorsTimings.length > 0 ? sensorsTimings[sensorsTimings.length - 1] : 0; //it is used for the rerendering

  const setAnalyticsGraphSize = useCallback(() => {
    if (!hasSensorValues) return;

    setWidth(contentWrapperRef.current.clientWidth);
    setHeight(
      contentWrapperRef.current.clientHeight -
        analyticsLegendRef.current.clientHeight,
    );
  }, [hasSensorValues]);

  useEffect(() => {
    if (hasSensorValues) {
      setLoading(false);

      return;
    }

    setTimeout(() => setLoading(false), LOADING_TIMEOUT);
  }, [hasSensorValues]);

  useEffect(() => {
    setAnalyticsGraphSize();
  }, [setAnalyticsGraphSize, lastTiming]);

  useEffect(() => {
    window.addEventListener('resize', setAnalyticsGraphSize);

    return () => {
      window.removeEventListener('resize', setAnalyticsGraphSize);
    };
  }, [setAnalyticsGraphSize]);

  return (
    <ModalPortal dataTestId="printer-sensor-modal" modalId={MODAL_ID}>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'printersensordata.header.title',
          defaultMessage: 'Sensor data',
        })}
      />

      {loading && <PageLoaderPortal show={loading} />}

      <ContentWrapper ref={contentWrapperRef}>
        {!loading && !hasSensorValues && (
          <EmptyStateBox
            iconName="precision_manufacturing_0"
            title={intl.formatMessage({
              id: 'printersensordata.empty.title',
              defaultMessage: 'No sensor data available',
            })}
            description={intl.formatMessage({
              id: 'printersensordata.empty.description',
              defaultMessage:
                'Sensor data will be available once the printer is connected',
            })}
          />
        )}

        {hasSensorValues && (
          <>
            <AnalyticsGraph>
              <XYPlot
                width={width}
                height={height}
                xType="time"
                yDomain={[0.0, 320.0]}
              >
                {Object.entries(sensorsValues).map(
                  ([sensorName, values], index) => {
                    if (
                      sensorName === PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP
                    )
                      return null;
                    const numberValues = values.map(
                      (valueObj) => valueObj.sensorValue,
                    );

                    return (
                      <LineSeries
                        data={formatValueDataIntoPoints(
                          numberValues,
                          sensorsTimings,
                        )}
                        color={colorPalette[index % (colorPalette.length - 1)]}
                        curve="curveMonotoneX"
                        key={sensorName}
                      />
                    );
                  },
                )}

                <XAxis />
                <YAxis />
                <HorizontalGridLines />
                <VerticalGridLines />
              </XYPlot>
            </AnalyticsGraph>

            <AnalyticsLegend ref={analyticsLegendRef}>
              <DiscreteColorLegend
                width={width}
                orientation="horizontal"
                items={Object.entries(sensorsValues)
                  .filter(
                    ([sensorName, _]) =>
                      sensorName !== PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP,
                  )
                  .map(([sensorName, values], index) => {
                    const displayedName = values[0].displayName || sensorName;
                    return {
                      title: getDisplayedName(
                        displayedName,
                        values[values.length - 1].sensorValue,
                        values[values.length - 1].sensorTargetValue,
                      ),
                      color: colorPalette[index % (colorPalette.length - 1)],
                    };
                  })}
              />
            </AnalyticsLegend>
          </>
        )}
      </ContentWrapper>
    </ModalPortal>
  );
};

PrinterSensor.propTypes = {};

export default PrinterSensor;
