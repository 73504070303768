import { useState, useCallback } from 'react';

export default function useActiveItems({ defaultActiveItems = [] } = {}) {
  const [activeItems, setActiveItems] = useState(defaultActiveItems);

  const addActiveItems = useCallback(
    (addItemsList = []) => {
      if (!addItemsList?.length) return;

      const updatedActiveItems = addItemsList?.reduce(
        (acc, item) => {
          const itemExists = acc?.includes(item);

          if (itemExists) return acc;

          return [...acc, item];
        },
        [...activeItems],
      );

      setActiveItems(updatedActiveItems);
    },
    [activeItems],
  );

  const removeActiveItems = useCallback(
    (removeItemsList = []) => {
      if (!removeItemsList?.length) return;

      const updatedActiveItems = activeItems?.filter((item) => {
        const removeItem = !removeItemsList?.includes(item);

        return removeItem;
      });

      setActiveItems(updatedActiveItems);
    },
    [activeItems],
  );

  const toggleActiveItems = useCallback(
    (toggleItemsList) => {
      if (!toggleItemsList?.length) return;

      const updatedActiveItems = toggleItemsList?.reduce(
        (acc, item) => {
          const itemExists = acc?.includes(item);

          if (itemExists) {
            return acc?.filter((activeItem) => activeItem !== item);
          }

          return [...acc, item];
        },
        [...activeItems],
      );

      setActiveItems(updatedActiveItems);
    },
    [activeItems],
  );

  const resetActiveItems = useCallback(() => {
    setActiveItems([]);
  }, []);

  const isItemActive = useCallback(
    (item) => activeItems?.includes(item),
    [activeItems],
  );

  return {
    activeItems,
    addActiveItems,
    removeActiveItems,
    toggleActiveItems,
    resetActiveItems,
    isItemActive,
  };
}
