import * as actionTypes from '@constants/actionTypes';

export const fetchTemplates = () => ({
  type: actionTypes.FETCH_TEMPLATES_REQUEST,
});

export const fetchTemplatesSuccess = (templates) => ({
  type: actionTypes.FETCH_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const fetchTemplatesFailure = (error) => ({
  type: actionTypes.FETCH_TEMPLATES_FAILURE,
  payload: { error },
});

export const explodeTemplate = (conceptId, operatorId) => ({
  type: actionTypes.EXPLODE_TEMPLATE_REQUEST,
  payload: { conceptId, operatorId },
});

export const explodeTemplateSuccess = (workflow) => ({
  type: actionTypes.EXPLODE_TEMPLATE_SUCCESS,
  payload: { workflow },
});

export const explodeTemplateFailure = () => ({
  type: actionTypes.EXPLODE_TEMPLATE_FAILURE,
});

export const createTemplate = (formValues = {}, navigateTo = () => {}) => ({
  type: actionTypes.CREATE_TEMPLATE_REQUEST,
  payload: { formValues, navigateTo },
});

export const createTemplateSuccess = () => ({
  type: actionTypes.CREATE_TEMPLATE_SUCCESS,
});

export const createTemplateFailure = (error) => ({
  type: actionTypes.CREATE_TEMPLATE_FAILURE,
  payload: { error },
});

export const setCurrentStage = (name = '', data = {}) => ({
  type: actionTypes.CREATE_TEMPLATE_SET_CURRENT_STAGE,
  payload: {
    stage: {
      name,
      data,
    },
  },
});

export const setCreateTemplateCompletedStage = (name = '', data) => ({
  type: actionTypes.SET_CREATE_TEMPLATE_COMPLETED_STAGE,
  payload: {
    stage: {
      name,
      data,
    },
  },
});

export const resetCreateTemplate = () => ({
  type: actionTypes.RESET_CREATE_TEMPLATE,
});

export const deleteTemplate = (templateId) => ({
  type: actionTypes.DELETE_TEMPLATE_REQUEST,
  payload: { templateId },
});

export const deleteTemplateSuccess = () => ({
  type: actionTypes.DELETE_TEMPLATE_SUCCESS,
});

export const deleteTemplateFailure = (error) => ({
  type: actionTypes.DELETE_TEMPLATE_FAILURE,
  payload: { error },
});

export const addTemplateToFavourite = (templateId = '') => ({
  type: actionTypes.ADD_TEMPLATE_TO_FAVOURITE_REQUEST,
  payload: { templateId },
});

export const addTemplateToFavouriteSuccess = () => ({
  type: actionTypes.ADD_TEMPLATE_TO_FAVOURITE_SUCCESS,
});

export const addTemplateToFavouriteFailure = (error) => ({
  type: actionTypes.ADD_TEMPLATE_TO_FAVOURITE_FAILURE,
  payload: { error },
});

export const removeTemplateFromFavourite = (templateId = '') => ({
  type: actionTypes.REMOVE_TEMPLATE_FROM_FAVOURITE_REQUEST,
  payload: { templateId },
});

export const removeTemplateFromFavouriteSuccess = () => ({
  type: actionTypes.REMOVE_TEMPLATE_FROM_FAVOURITE_SUCCESS,
});

export const removeTemplateFromFavouriteFailure = (error) => ({
  type: actionTypes.REMOVE_TEMPLATE_FROM_FAVOURITE_FAILURE,
  payload: { error },
});
