import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Paper from '@components/1-atoms/Paper';
import ButtonDropDown from '@components/1-atoms/ButtonDropDown';
import ColorRange from '@components/1-atoms/ColorRange';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import {
  Content,
  ActionWrapper,
  IconButtons,
  IconButtonVisibility,
} from './OperatorActionBar.styled';

const SHOW_ALL_ICON_BUTTONS_LENGTH = -1;

const OperatorActionBar = ({
  className,
  dataTestId = 'operator-action-bar',
  iconButtons = [],
  lineTypeColorRange,
  lineTypeDataButtonDropdown,
  lineTypeDropDown,
  onCloseIconButtonClick,
  shadingToolButtonDropdown,
}) => {
  const [fitIconsLength, setFitIconsLength] = useState(
    SHOW_ALL_ICON_BUTTONS_LENGTH,
  );
  const paperRef = useRef();
  const contentRef = useRef();
  const iconButtonsRef = useRef();

  const showAllIconButtons = fitIconsLength === SHOW_ALL_ICON_BUTTONS_LENGTH;
  const showMoreIconButton =
    !showAllIconButtons && iconButtons.length > fitIconsLength;

  const getFitIconsLength = useCallback(() => {
    const paperElement = paperRef.current;
    const contentElement = contentRef.current;
    const iconButtonsElement = iconButtonsRef.current;

    if (!paperElement || !contentElement || !iconButtonsElement) return 0;

    const contentElementParent = paperElement.parentElement;
    const parentWidth = contentElementParent.clientWidth;
    const contentChildren = Array.from(contentElement.children);
    const iconButtons = iconButtonsElement.children;
    const iconButtonsChildrenLength = iconButtons.length;
    const iconButtonsIndex = contentChildren.indexOf(iconButtonsElement);
    const previousIconButtonsSublings = contentChildren.slice(
      0,
      iconButtonsIndex,
    );
    const nextIconButtonsSublings = contentChildren.slice(iconButtonsIndex + 1);
    const previousIconButtonsSublingsWidth = previousIconButtonsSublings.reduce(
      (acc, element) => acc + element.offsetWidth,
      0,
    );
    const nextIconButtonsSublingsWidth = nextIconButtonsSublings.reduce(
      (acc, element) => acc + element.offsetWidth,
      0,
    );
    const availableIconButtonsWidth =
      parentWidth -
      previousIconButtonsSublingsWidth -
      nextIconButtonsSublingsWidth;

    const gap = 2;
    let fitIconsLength = 0;
    let totalWidth = gap;

    for (let i = 0; i < iconButtonsChildrenLength; i++) {
      totalWidth += iconButtons[i].clientWidth + gap * 2;

      if (totalWidth > availableIconButtonsWidth) {
        break;
      }

      fitIconsLength = i + 1;
    }

    return fitIconsLength;
  }, [iconButtonsRef]);

  const getFitIconsWidth = useCallback(() => {
    const iconButtonsElement = iconButtonsRef.current;

    if (!iconButtonsElement) return 0;

    const iconButtons = iconButtonsElement.children;
    const gap = 2;

    let totalWidth = gap;

    for (let i = 0; i < getFitIconsLength(); i++) {
      totalWidth += iconButtons[i].clientWidth + gap;
    }

    return totalWidth;
  }, [iconButtonsRef, getFitIconsLength]);

  const updateIconButtonsVisibility = useCallback(() => {
    if (iconButtonsRef.current) {
      iconButtonsRef.current.style.maxWidth = `${getFitIconsWidth()}px`;
    }

    const nextFitIconsLength = getFitIconsLength();

    if (fitIconsLength !== nextFitIconsLength) {
      setFitIconsLength(getFitIconsLength());
    }
  }, [fitIconsLength, getFitIconsLength, getFitIconsWidth]);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(() => {
        updateIconButtonsVisibility();
      }),
    [updateIconButtonsVisibility],
  );

  const moreIconButtonDropDownMenuItems = useMemo(() => {
    const moreItems = iconButtons.slice(fitIconsLength);

    return moreItems.map((iconButton) => {
      const moreButton = {
        id: iconButton.id,
        dataTestId: iconButton.id,
        label: iconButton.title,
        leadingIconName: iconButton.iconName,
        selected: iconButton.selected,
      };

      if (iconButton.turnToSwitchTypeWhenInMoreMenu) {
        moreButton.endingButton = {
          type: 'switch',
          enabled: iconButton.selected,
          onChange: iconButton.onClick,
        };
      } else {
        moreButton.onClick = iconButton.onClick;
      }

      return moreButton;
    });
  }, [fitIconsLength, iconButtons]);

  useEffect(() => {
    const paperElement = paperRef.current;

    if (!paperElement) return;

    const paperEelementParent = paperElement.parentElement;

    resizeObserver.observe(paperEelementParent);

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserver]);

  useEffect(() => {
    updateIconButtonsVisibility();
  }, [
    iconButtons,
    updateIconButtonsVisibility,
    lineTypeDataButtonDropdown,
    lineTypeDropDown,
    lineTypeColorRange,
  ]);

  return (
    <Paper ref={paperRef} dataTestId={dataTestId} className={className}>
      <Content ref={contentRef}>
        {!isEmpty(shadingToolButtonDropdown) && (
          <ActionWrapper>
            <ButtonDropDown {...shadingToolButtonDropdown} minWidth={100} />
          </ActionWrapper>
        )}

        {!isEmpty(lineTypeDataButtonDropdown) && (
          <ActionWrapper>
            <ButtonDropDown {...lineTypeDataButtonDropdown} minWidth={100} />
          </ActionWrapper>
        )}

        {!isEmpty(lineTypeDropDown) && (
          <ActionWrapper>
            <ButtonDropDown {...lineTypeDropDown} />
          </ActionWrapper>
        )}

        {!isEmpty(lineTypeColorRange) && <ColorRange {...lineTypeColorRange} />}

        {!isEmpty(iconButtons) && (
          <>
            {!isEmpty(lineTypeDataButtonDropdown) && (
              <VerticalDivider middleInset inlineMiddleInset />
            )}

            <IconButtons ref={iconButtonsRef}>
              {iconButtons.map((iconButton, index) => (
                <IconButtonVisibility
                  key={iconButton?.id}
                  visible={showAllIconButtons || index <= fitIconsLength}
                >
                  <IconButton
                    dataTestId={`${dataTestId}__icon-button-${index} ${dataTestId}__icon-button-${index}--${iconButton?.title}`}
                    disabled={iconButton?.disabled}
                    variant={iconButton?.variant}
                    iconColor={iconButton?.iconColor}
                    iconName={iconButton?.iconName}
                    onClick={iconButton?.onClick}
                    ref={iconButton?.ref}
                    title={iconButton?.title}
                    selected={iconButton.selected}
                  />
                </IconButtonVisibility>
              ))}
            </IconButtons>

            {showMoreIconButton && (
              <IconButton
                dataTestId={`${dataTestId}__more-icon-button`}
                iconName="more_vert_0"
                dropDownMenuItems={moreIconButtonDropDownMenuItems}
              />
            )}
          </>
        )}

        {onCloseIconButtonClick && (
          <IconButton
            variant={ICON_BUTTON_VARIANT_PLAIN}
            iconName="close_0"
            onClick={onCloseIconButtonClick}
          />
        )}
      </Content>
    </Paper>
  );
};

OperatorActionBar.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  lineTypeColorRange: PropTypes.shape(ColorRange.propTypes),
  lineTypeDataButtonDropdown: PropTypes.shape(ButtonDropDown.propTypes),
  lineTypeDropDown: PropTypes.shape(ButtonDropDown.propTypes),
  onCloseIconButtonClick: PropTypes.func,
  shadingToolButtonDropdown: PropTypes.shape(ButtonDropDown.propTypes),
  iconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      dragData: PropTypes.object,
      draggableId: PropTypes.string,
      variant: PropTypes.string,
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      title: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
};

export default OperatorActionBar;
