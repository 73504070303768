import React from 'react';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { useIntl } from 'react-intl';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { Field as FormikField, useFormikContext } from 'formik';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const StaticSetting = () => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.settings.bed.type.parameters.label',
      })}
      dataTestId={`printer-components-bed-type__category-static`}
      expand
    >
      {['length', 'width'].map((field) => (
        <FormikField
          key={field}
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.settings.bed.type.parameters.${field}.label`,
          })}
          field1={{
            type: 'number',
            dirty: checkIfFieldIsDirty(
              values,
              initialValues,
              `bedTypeSetting.bedProperties.${field}`,
            ),
          }}
          dataTestId={`printer-components-bed-type__category-static__setting-${field}`}
          name={`bedTypeSetting.bedProperties.${field}`}
        />
      ))}
    </SettingsCategory>
  );
};
