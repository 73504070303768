import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content, ImageSidebar } from './ImageSideBarLayout.styled';

const ImageSideBarLayout = ({ children, imageSrc }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>

      <ImageSidebar imageSrc={imageSrc} />
    </Wrapper>
  );
};

ImageSideBarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  imageSrc: PropTypes.string,
};

export default ImageSideBarLayout;
