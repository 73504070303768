import styled, { css } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { FIELD_SIZE_COMPACT } from './Field';
import {
  Wrapper as IconButtonWrapper,
  Content as IconButtonContent,
} from '@app/components/1-atoms/IconButton/IconButton.styled';
import { flexCenterVertical, flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: 100%;
  ${flexColumn}

  ${({ disabled, transparentWhenDisabled }) => css`
    ${when(
      disabled && transparentWhenDisabled,
      `
        opacity: 0.38;
      `,
    )}
  `}
`;

export const Label = styled.label`
  margin: 0;

  ${({ theme: { colors, spacing, font } }) => css`
    ${font?.labelLarge}
    color: ${colors?.onSurfaceVariant};
    padding: ${`0 ${spacing?.level5} ${spacing?.level2} 0`};
  `}
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 0;

  ${({ theme: { colors, borderRadius } }) => css`
    border-radius: ${borderRadius?.small};
    background-color: ${colors?.surfaceContainerHighest};
  `}

  &:before {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;

    ${({ theme: { colors } }) => css`
      background-color: ${colors?.onSurface};
      border-color: ${colors?.onSurface};
    `}
  }

  ${({ disabled = '' }) =>
    !disabled &&
    `
    &:hover:before {
      opacity: 0.08;
      visibility: visible;
    }
  `}
`;

const iconContainerStyles = css`
  ${flexCenterVertical}
  flex-shrink: 0;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${({ theme: { spacing } }) => css`
    width: ${spacing?.level9};
  `}
`;

const iconStyles = css`
  width: 24px;
  height: 24px;

  ${({ theme: { colors } }) => css`
    fill: ${colors?.onSurfaceVariant};
  `}
`;

export const LeadingIconContainer = styled.div`
  ${iconContainerStyles}
  left: 0;

  ${({ size, theme: { spacing } }) => css`
    ${when(
      size === FIELD_SIZE_COMPACT,
      `
        width: 18px;
        height: 18px;
        left: ${spacing.level3}
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  ${iconStyles}
`;

export const EndingIconContainer = styled.div`
  ${iconContainerStyles}
  right: 0;

  ${({ size, theme: { spacing } }) => css`
    ${when(
      size === FIELD_SIZE_COMPACT,
      `
        width: 18px;
        height: 18px;
        right: ${spacing.level3}
      `,
    )}
  `}
`;

export const EndingButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;

  ${({ size, theme: { spacing } }) => css`
    ${when(
      size === FIELD_SIZE_COMPACT,
      `
        right: ${spacing?.level3};

        ${IconButtonWrapper} {
          width: 18px;
          height: 18px;
          padding: 0;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        ${IconButtonContent} {
          padding: 0;
        }
      `,
    )}
  `}
`;

export const Input = styled.input`
  width: 100%;
  min-height: 56px;
  min-width: 0;
  border: 2px solid transparent;
  border-radius: inherit;
  background-color: transparent;
  outline: none;
  position: relative;
  z-index: 1;
  opacity: 1;

  ${({ theme: { font, colors, spacing } }) => css`
    ${font?.bodyLarge}
    color: ${colors?.onSurface};
    padding: ${`${spacing?.level2} ${spacing?.level5}`};
  `}

  &::placeholder {
    color: ${({ theme: { colors } }) => colors?.onSurfaceVariant};
  }

  &:focus {
    border-color: ${({ theme: { colors } }) => colors?.primary};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    border-color: transparent;

    ${({ theme: { colors } }) => css`
      -webkit-text-fill-color: ${colors?.onSurface};
      caret-color: ${colors?.onSurface};
    `}
  }

  ${({
    disabled,
    dirty,
    error,
    diffRemoved,
    diffAdded,
    diffModified,
    size,
    withLeadingIcon,
    withEndingIcon,
    withOverlay,
    transparentWhenDisabled,
    theme: { font, colors, spacing },
  }) => css`
    ${when(
      size === FIELD_SIZE_COMPACT,
      css`
        min-height: 32px;
        ${font?.bodyMedium}
      `,
    )}

    ${when(
      withLeadingIcon,
      `
        padding-left: ${spacing?.level9};

        ${when(
          size === FIELD_SIZE_COMPACT,
          `
            padding-left: ${spacing?.level7};
          `,
        )}
      `,
    )}

    ${when(
      withEndingIcon,
      `
        padding-right: ${spacing?.level9};

        ${when(
          size === FIELD_SIZE_COMPACT,
          `
            padding-right: ${spacing?.level7};
          `,
        )}
      `,
    )}

    ${when(
      dirty,
      `
        border-color: ${colors?.caution};
      `,
    )}

    ${when(
      error,
      `
        border-color: ${colors?.error};
      `,
    )}

    ${when(
      withOverlay,
      `
        user-select: none;
        pointer-events: none;
        text-overflow: ellipsis;
      `,
    )}

    ${when(
      disabled && !withOverlay && transparentWhenDisabled,
      `
        border-color: transparent;
        opacity: 0.4;
      `,
    )}

    ${when(
      diffRemoved,
      `
        border-color: #9c3e47;
      `,
    )}

    ${when(
      diffAdded,
      `
        border-color: #007bff;
      `,
    )}

    ${when(
      diffModified,
      `
        border-color: #E93EA4;
      `,
    )}
  `}

  &::-webkit-credentials-auto-fill-button,
  &::-webkit-caps-lock-indicator {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const SupportingText = styled.span`
  ${({ error, dirty, theme: { font, colors, spacing } }) => css`
    ${font?.bodySmall}
    color: ${colors?.onSurfaceVariant};
    padding: ${spacing?.level2} 0;

    ${when(
      error,
      `
        color: ${colors?.error};
      `,
    )}

    ${when(
      dirty,
      `
        color: ${colors?.caution};
      `,
    )}
  `}
`;

export const InputOverlay = styled.label`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;

  ${({
    dirty,
    error,
    diffRemoved,
    diffAdded,
    diffModified,
    theme: { colors },
  }) => css`
    border: 2px solid ${colors.outline};

    ${when(
      dirty,
      `
        border-color: ${colors?.caution};
      `,
    )}

    ${when(
      error,
      `
        border-color: ${colors?.error};
      `,
    )}

    ${when(
      diffRemoved,
      `
        border-color: #9c3e47;
      `,
    )}

    ${when(
      diffAdded,
      `
        border-color: #007bff;
      `,
    )}

    ${when(
      diffModified,
      `
        border-color: #E93EA4;
      `,
    )}
  `}
`;

export const OverlayHiddenInput = styled.input`
  width: 0;
  max-width: 0;
  height: 0;
  max-height: 0;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
`;
