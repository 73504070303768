import { Printer } from '../Printer';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import EnclosureCustom from './EnclosureCustom';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators';
import Enclosure from './Enclosure';
import { DEFAULT_ENCLOSURE } from '../../constants/machineConstants';

export default function getEnclosure(
  printerSettings,
  machineDefaults,
  enclosureDefinitions,
) {
  const defaultEnclosure =
    machineDefaults?.defaultEnclosureName || DEFAULT_ENCLOSURE;
  const enclosureTypeId = Printer.getPrinterSettingValueOrDefault(
    printerSettings,
    constants.enclosureType,
    defaultEnclosure,
  );

  const isCustom = isEnclosureCustom(enclosureTypeId);

  return isCustom
    ? getCustomEnclosure(
        printerSettings,
        machineDefaults,
        enclosureTypeId,
        enclosureDefinitions,
      )
    : getNonCustomEnclosure(
        printerSettings,
        machineDefaults,
        enclosureTypeId,
        enclosureDefinitions,
      );
}

function getCustomEnclosure(
  printerSettings,
  machineDefaults,
  enclosureTypeId,
  enclosureDefinitions,
) {
  return new EnclosureCustom(
    printerSettings,
    machineDefaults,
    enclosureTypeId,
    enclosureDefinitions,
  );
}

export function getEnclosureDefinitionResponse(printerResponse) {
  return Printer.getPrinterSettingValue(
    printerResponse,
    componentDiscriminators.ENCLOSURE_RESPONSE_PROPERTY,
  );
}

function getNonCustomEnclosure(
  printerSettings,
  machineDefaults,
  enclosureTypeId,
  enclosureDefinitions,
) {
  if (!enclosureTypeId || enclosureTypeId === '') return;

  return new Enclosure(
    printerSettings,
    machineDefaults,
    enclosureTypeId,
    enclosureDefinitions,
  );
}

export function isEnclosureCustom(enclosureTypeId) {
  return enclosureTypeId === 'CUSTOM';
}
