import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import Chip from '@components/1-atoms/Chip';
import IconButton from '@components/1-atoms/IconButton';
import {
  Wrapper,
  LeftColumn,
  RightColumn,
} from './SettingActionWithIconButton.styled';

const SettingActionWithIconButton = ({
  chip = {},
  className,
  dataTestId = 'setting-action-with-icon-button',
  iconButton = {},
  label,
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <Text
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </Text>
      </LeftColumn>

      <RightColumn>
        <Chip dataTestId={`${dataTestId}__chip`} {...chip} />

        {iconButton && iconButton.iconName && (
          <IconButton
            dataTestId={`${dataTestId}__icon-button`}
            {...iconButton}
          />
        )}
      </RightColumn>
    </Wrapper>
  );
};

SettingActionWithIconButton.propTypes = {
  chip: PropTypes.shape(Chip.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  iconButton: PropTypes.shape(IconButton.propTypes),
  label: PropTypes.string.isRequired,
  withIdentation: PropTypes.bool,
};

export default SettingActionWithIconButton;
