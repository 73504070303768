import styled, { css } from 'styled-components';
import { flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: clip;
  transition-delay: 0.4s;
  z-index: 2;

  ${({ show }) => css`
    ${when(
      show,
      `
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
      `,
    )}
  `}

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    ${({ show, theme: { colors } }) => css`
      background-color: ${colors.scrim};

      ${when(
        show,
        `
          transition: opacity 0.5s ease;
          opacity: 0.5;
          visibility: visible;
        `,
      )}
    `}
  }
`;

export const LoaderWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s ease 0.3s, transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  ${({ show }) => css`
    transform: translateY(15px);

    ${when(
      show,
      `
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;
      `,
    )}
  `}
`;
