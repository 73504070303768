import React, { forwardRef, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  preventDefault,
  stopPropagation,
  whenEnterButtonPressed,
} from '@utils/interactionEvents';
import withDropDownMenu from '@hoc/withDropDownMenu';
import { Icon, Label, StateLayer, Wrapper } from './Chip.styled';

export const CHIP_VARIANT_STANDARD = 'standard';
export const CHIP_VARIANT_PRIMARY = 'primary';

const Chip = forwardRef(
  (
    {
      dataTestId = 'chip',
      disabled,
      endingIconName,
      highlightOnDragOver,
      label,
      error,
      dirty,
      leadingIconName,
      onClick,
      onDragEnter,
      onDragLeave,
      onDragOver,
      onDrop,
      selected,
      selectedEndingIconName,
      selectedLeadingIconName = 'done_0',
      variant = CHIP_VARIANT_STANDARD,
    },
    ref,
  ) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const wrapperRef = useRef(null);

    const withLeadingIcon = !!leadingIconName;
    const withEndingIcon = !!endingIconName;

    const combineRefs = useCallback(
      (el) => {
        wrapperRef.current = el;

        if (typeof ref === 'function') {
          ref(el);
        } else if (ref) {
          ref.current = el;
        }
      },
      [ref],
    );

    const handleDragEnter = useCallback(
      (e) => {
        const isDraggingOverWrapper = !wrapperRef.current.contains(
          e.relatedTarget,
        );

        if (isDraggingOver) {
          return;
        }

        if (isDraggingOverWrapper && highlightOnDragOver) {
          setIsDraggingOver(true);
        }

        onDragEnter?.(e);
      },
      [isDraggingOver, highlightOnDragOver, onDragEnter],
    );

    const handleDragLeave = useCallback(
      (e) => {
        const isLeftWrapper = wrapperRef.current.contains(e.relatedTarget);

        if (isLeftWrapper) return;

        if (isDraggingOver) {
          setIsDraggingOver(false);
        }

        onDragLeave?.(e);
      },
      [isDraggingOver, onDragLeave],
    );

    const handleDrop = useCallback(
      (e) => {
        if (isDraggingOver) {
          setIsDraggingOver(false);
        }

        onDrop?.(e);
      },
      [isDraggingOver, onDrop],
    );

    return (
      <Wrapper
        data-testid={dataTestId}
        disabled={disabled}
        error={error}
        isDraggingOver={isDraggingOver}
        onClick={onClick}
        dirty={dirty}
        onDragEnter={preventDefault(stopPropagation(handleDragEnter))}
        onDragLeave={preventDefault(stopPropagation(handleDragLeave))}
        onDragOver={preventDefault(stopPropagation(onDragOver))}
        onDrop={preventDefault(stopPropagation(handleDrop))}
        onKeyDown={whenEnterButtonPressed(onClick)}
        ref={combineRefs}
        selected={selected}
        tabIndex={disabled ? -1 : 0}
        variant={variant}
        withEndingIcon={withEndingIcon}
        withLeadingIcon={withLeadingIcon}
      >
        <StateLayer disabled={disabled} variant={variant} selected={selected} />

        {leadingIconName && (
          <Icon
            disabled={disabled}
            name={selected ? selectedLeadingIconName : leadingIconName}
            variant={variant}
            selected={selected}
          />
        )}

        <Label
          dataTestId={`${dataTestId}__label`}
          disabled={disabled}
          variant={variant}
          selected={selected}
        >
          {label}
        </Label>

        {endingIconName && (
          <Icon
            disabled={disabled}
            name={
              selected
                ? selectedEndingIconName ?? endingIconName
                : endingIconName
            }
            variant={variant}
            selected={selected}
          />
        )}
      </Wrapper>
    );
  },
);

Chip.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  dirty: PropTypes.bool,
  selected: PropTypes.bool,
  selectedEndingIconName: PropTypes.string,
  endingIconName: PropTypes.string,
  highlightOnDragOver: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectedLeadingIconName: PropTypes.string,
  leadingIconName: PropTypes.string,
  onClick: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  onDragEnter: PropTypes.func,
  variant: PropTypes.oneOf([CHIP_VARIANT_PRIMARY, CHIP_VARIANT_STANDARD]),
};

Chip.displayName = 'Chip';

export default withDropDownMenu(Chip);
