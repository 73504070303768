import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isAnyFetchInProgess } from '@selectors/fetchSelector';
import {
  fetchAllPrinters,
  fetchPostProcessorConfigsForPrinter,
  selectPrinter,
} from '@actions/printerActions';
import { resetNavigationBarHistory } from '@actions/applicationActions';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import LoadingEditorLayout from '@containers/LoadingEditorLayout';

const PrinterLoader = ({ children }) => {
  const { printerId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(isAnyFetchInProgess);
  const { printerQuery, nozzlesQuery } = usePrinterQueries({
    nozzlePrinterId: printerId,
    printerId: printerId,
  });

  const pageDataIsFetched =
    !isFetching && printerQuery.isFetched && nozzlesQuery.isFetched;

  // TODO: Refactor this to use useQuery to fetch data
  useEffect(() => {
    dispatch(fetchAllPrinters());
    dispatch(fetchPostProcessorConfigsForPrinter(printerId));
  }, [dispatch, printerId]);

  useEffect(() => {
    dispatch(
      selectPrinter(printerQuery?.data?.id, printerQuery?.data?.machine),
    );
  }, [dispatch, printerQuery?.data?.id, printerQuery?.data?.machine]);

  useEffect(() => {
    return () => {
      dispatch(resetNavigationBarHistory());
    };
  }, [dispatch]);

  if (!pageDataIsFetched) {
    return <LoadingEditorLayout />;
  }

  return children;
};

PrinterLoader.propTypes = {
  children: PropTypes.node,
};

export default PrinterLoader;
