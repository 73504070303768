import React, { useMemo } from 'react';
import { extrusionTypeDefinitions } from '@constants/printers/extrusionTypeDefinitions';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { useIntl } from 'react-intl';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const ExtruderSettings = () => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const options = getFormattedOptions(extrusionTypeDefinitions);

  return (
    <FormikField
      component={SettingDropDown}
      label={intl.formatMessage({
        id: 'printers.settings.type.label',
      })}
      dataTestId={`printer-components-tool-type__category-parameters__extrusionType}`}
      name={'toolTypeSetting.toolProperties.extrusionType'}
      dropDownField={{
        dropDownMenuItems: options,
        fullWidthDropDownMenu: false,
        dirty: checkIfFieldIsDirty(
          values,
          initialValues,
          'toolTypeSetting.toolProperties.extrusionType',
        ),
      }}
    />
  );
};
