import React, { useMemo, useCallback } from 'react';
import { Formik, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import Dialog from '@components/Workspaces/Dialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import Field from '@components/1-atoms/Field';

const MODAL_ID = ModalDataTypes.EDIT_WORKFLOW;

const EditWorkflowDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { updateWorkflowMetadataMutation } = useWorkflowMutations();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const formInitialValues = useMemo(
    () => ({
      name: dialogData?.workflowName,
    }),
    [dialogData?.workflowName],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(
            5,
            intl.formatMessage({
              id: 'editworkflow.dialog.workflowname.validation.min',
              defaultMessage: 'Name must be at least 5 characters long',
            }),
          )
          .max(
            256,
            intl.formatMessage({
              id: 'editworkflow.dialog.workflowname.validation.max',
              defaultMessage: 'Name must be no longer than 256 characters',
            }),
          )
          .required(
            intl.formatMessage({
              id: 'editworkflow.dialog.workflowname.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          ),
      }),
    [intl],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) => {
      return updateWorkflowMetadataMutation
        .mutateAsync({
          id: dialogData?.workflowId,
          name: values.name,
          public: dialogData?.workflowIsPublic,
          projectId: dialogData?.projectId,
        })
        .then(handleClose);
    },
    [
      dialogData?.workflowId,
      dialogData?.workflowIsPublic,
      dialogData?.projectId,
      updateWorkflowMetadataMutation,
      handleClose,
    ],
  );

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog
          dataTestId="edit-workflow-dialog"
          dialogId={MODAL_ID}
          title={intl.formatMessage({
            id: 'editworkflow.dialog.title',
            defaultMessage: 'Rename Workflow',
          })}
          confirmButtonText={intl.formatMessage({
            id: 'general.save',
            defaultMessage: 'Save',
          })}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          loading={isSubmitting}
        >
          <FormikField
            autoFocus
            component={Field}
            dataTestId="edit-workflow-dialog__name-field"
            disabled={isSubmitting}
            name="name"
            placeholder={intl.formatMessage({
              id: 'editworkflow.dialog.form.field.name.placeholder',
              defaultMessage: 'Name',
            })}
          />
        </Dialog>
      )}
    </Formik>
  );
};

export default EditWorkflowDialog;
