import styled, { css } from 'styled-components';
import FieldComponent from '@components/1-atoms/Field';
import {
  InputContainer,
  Input,
  InputOverlay,
} from '@components/1-atoms/Field/Field.styled';
import { when } from '@stylesheets/helpers';

export const Field = styled(FieldComponent)`
  ${InputContainer} {
    background-color: transparent;
  }

  ${Input} {
    ${({
      disabled,
      diffAdded,
      diffModified,
      diffRemoved,
      theme: { colors },
    }) => css`
      ${when(
        disabled,
        `
          border-color: ${colors.outline};
        `,
      )}

      ${when(
        diffRemoved,
        `
          border-color: #9c3e47;
        `,
      )}
  
      ${when(
        diffAdded,
        `
          border-color: #007bff;
        `,
      )}
  
      ${when(
        diffModified,
        `
          border-color: #E93EA4;
        `,
      )}
    `}
  }

  ${InputOverlay} {
    ${({ size }) => css`
      ${when(
        size,
        `
          border-width: 1px;
        `,
      )}
    `}

    &:focus:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;
