import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import usePrintingStatus from '@hooks/printers/usePrintingStatus';
import usePrinterSocket from '@hooks/websocket/usePrinterSocket';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import { getProjectInstructionsURL } from '@selectors/conceptSelectors';
import Paper from '@components/1-atoms/Paper';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_TEXT,
  BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import Loader, {
  LOADER_ICON_ANIMATION_VARIANT_PULSE,
} from '@components/2-molecules/Loader';
import { Content, LoaderWrapper } from './WorkflowToolpathBar.styled';
import useDialog from '@app/hooks/useDialog';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useFeatureFlags from '@app/hooks/featureflags/useFeatureFlags';
import { DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE } from '@app/constants/featureFlagConstants';
import { useFormContext } from 'react-hook-form';
import useDownloadStreamToolpath from '@app/hooks/operators/useDownloadStreamToolpath';

// this will be refactored once Toolpath categories are implemented, we can then find all Safety Check inputs from their category
const SAFETY_CHECK_VALUES = [
  'Check Axis Limits',
  'Check Workspace Limits',
  'Check Reach Limits',
  'Check Wrist Singularities',
  'Enable Collision Detection',
];
const WorkflowToolpathBar = () => {
  const [showPrintingProgress, setShowPrintingProgress] = useState(false);
  const projectInstructionsURL = useSelector(getProjectInstructionsURL);
  const {
    getSelectedWorkflow,
    getWorkflowPrinter,
    getIsWorkflowEditable,
    updateAndComputeWorkflow,
  } = useWorkflow();
  const {
    getSelectedOperator,
    unselectSelectedOperator,
    getOperatorVisibleInputs,
  } = useOperator();
  const { showDialog } = useDialog();
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const { setValue, handleSubmit } = useFormContext();

  const workflow = getSelectedWorkflow();
  const printer = getWorkflowPrinter(workflow);
  const printerId = printer?.id;
  const selectedOperator = getSelectedOperator();
  const selectedOperatorId = selectedOperator?.id;
  const isWorkflowEditable = getIsWorkflowEditable();
  const {
    isPrinting,
    printerStatus,
    formattedPrinterStatusName,
    printingProgress,
    stopPrint,
  } = usePrintingStatus(printerId);
  const operatorInputs = getOperatorVisibleInputs(selectedOperator);
  const { downloadToolpath, streamToolpath } = useDownloadStreamToolpath();

  usePrinterSocket();

  const enableSafetyChecksAndCompute = useCallback(
    (intentStreaming) => {
      const formFields = operatorInputs
        .filter((i) => SAFETY_CHECK_VALUES.includes(i.name))
        .map((i) => `${i.operatorId}.${i.id}`);
      formFields.forEach((f) =>
        setValue(f, 'true', { shouldTouch: true, shouldDirty: true }),
      );
      handleSubmit((formValues) =>
        updateAndComputeWorkflow(
          formValues,
          { safetyCheck: true, intentStreaming },
          selectedOperatorId,
        ),
      )();
    },
    [
      operatorInputs,
      handleSubmit,
      setValue,
      updateAndComputeWorkflow,
      selectedOperatorId,
    ],
  );

  const onDownloadClick = useCallback(() => {
    if (isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: false,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(false)
            : downloadToolpath(selectedOperatorId),
      });
    } else {
      downloadToolpath(selectedOperatorId);
    }
  }, [
    downloadToolpath,
    showDialog,
    isFeatureFlagEnabled,
    enableSafetyChecksAndCompute,
    selectedOperatorId,
  ]);

  const isComputationStreamable = useCallback(() => {
    if (!printerStatus) return false;

    const isIdle = printerStatus.status === 'IDLE';
    const currentDate = new Date();
    // TODO store the cutoff interval somewhere else
    const cutoffDate = new Date(currentDate.getTime() - 5000);
    const isStreamable = isIdle && new Date(printerStatus.time) > cutoffDate;

    return isStreamable;
  }, [printerStatus]);

  const onStreamClick = useCallback(async () => {
    if (isFeatureFlagEnabled(DOWNLOAD_STREAM_TOOLPATH_SAFETY_CHECK_FEATURE)) {
      showDialog(ModalDataTypes.DOWNLOAD_STREAM_TOOLPATH, {
        isStream: true,
        onConfirm: (runSafetyChecks) =>
          runSafetyChecks
            ? enableSafetyChecksAndCompute(true)
            : streamToolpath(selectedOperatorId),
      });
    } else {
      streamToolpath(selectedOperatorId);
    }
  }, [
    isFeatureFlagEnabled,
    showDialog,
    streamToolpath,
    enableSafetyChecksAndCompute,
    selectedOperatorId,
  ]);

  const togglePrintingProgress = useCallback(() => {
    setShowPrintingProgress((prev) => !prev);
  }, []);

  const stopPrinting = useCallback(() => {
    stopPrint();
    togglePrintingProgress();
  }, [stopPrint, togglePrintingProgress]);

  if (showPrintingProgress) {
    return (
      <LoaderWrapper>
        <Loader
          endingIconButtonIconName="stop_player_1"
          endingIconButtonTitle="Stop Printing"
          iconColor="success"
          iconName="Dot_1"
          iconAnimationVariant={LOADER_ICON_ANIMATION_VARIANT_PULSE}
          leadingIconButtonIconName="chevron_left_0"
          onEndingIconButtonClick={stopPrinting}
          onLeadingIconButtonClick={togglePrintingProgress}
          progress={printingProgress}
          title={formattedPrinterStatusName}
        />
      </LoaderWrapper>
    );
  }

  return (
    <Paper dataTestId="workflow-toolpath-bar">
      <Content withContent={!!projectInstructionsURL}>
        {projectInstructionsURL && (
          <>
            <Button
              disabled={!isWorkflowEditable}
              variant={BUTTON_VARIANT_TEXT}
              iconName="download_0"
              stretch={false}
              onClick={onDownloadClick}
            >
              <FormattedMessage
                id="operator.progress.download_toolpath"
                defaultMessage="Download Toolpath"
              />
            </Button>

            {isComputationStreamable() && (
              <Button
                variant={BUTTON_VARIANT_PRIMARY}
                stretch={false}
                onClick={onStreamClick}
              >
                <FormattedMessage id="general.stream" defaultMessage="Stream" />
              </Button>
            )}

            {isPrinting && (
              <Button
                variant={BUTTON_VARIANT_PLAIN}
                iconName="Dot_1"
                iconColor="success"
                stretch={false}
                onClick={togglePrintingProgress}
              >
                {formattedPrinterStatusName}
              </Button>
            )}
          </>
        )}

        <IconButton
          variant={ICON_BUTTON_VARIANT_PLAIN}
          iconName="close_0"
          onClick={unselectSelectedOperator}
        />
      </Content>
    </Paper>
  );
};

WorkflowToolpathBar.propTypes = {};

export default WorkflowToolpathBar;
