import * as THREE from 'three';
import { Bed } from './Bed';
import { getBedDefinitionResponse } from './BedUtils';
import { Printer } from '../Printer';
import { s3Directories } from '../../constants/printers/s3Directories';

class BedCustom extends Bed {
  constructor(printerSettings, printingBedDefinitions, plinthDefinitions) {
    super(printerSettings, null, printingBedDefinitions, plinthDefinitions);
  }

  getPrintingBedSettingsType() {
    return '';
  }

  initializeModels() {
    const bedDefinitionResponse = getBedDefinitionResponse(
      this.printerSettings,
    );
    return new Promise((resolve) => {
      if (!bedDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = bedDefinitionResponse;

      const baseUrl = modelUrls[s3Directories.bedBase];

      Promise.resolve(Printer.getModel(baseUrl)).then((model) => {
        this.models.add(model);
        resolve();
      });
    });
  }
}

export default BedCustom;
