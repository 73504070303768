import * as THREE from 'three';
import { Printer } from './Printer';
import { printerConstants as constants } from '../constants/printers/printerConstants';

class Workspace extends THREE.Group {
  constructor(printerSettings) {
    super();
    this.printerSettings = printerSettings;
  }

  /**
   * Returns a box geometry showing the outline of the workspace, with the geometry
   * positioned according to the supplied printer settings.
   * @returns
   */
  initializeModels() {
    const workspaceX1 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceX1,
    );
    const workspaceX2 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceX2,
    );
    const workspaceY1 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceY1,
    );
    const workspaceY2 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceY2,
    );
    const workspaceZ1 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceZ1,
    );
    const workspaceZ2 = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceZ2,
    );

    const workspaceOriginX = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceOriginX,
    );
    const workspaceOriginY = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceOriginY,
    );
    const workspaceOriginZ = Printer.getPrinterSettingValue(
      this.printerSettings,
      constants.workspaceOriginZ,
    );

    const workspaceSizeX = workspaceX2 + workspaceX1;
    const workspaceSizeY = workspaceY2 + workspaceY1;
    const workspaceSizeZ = workspaceZ2 + workspaceZ1;

    const workspacePosX = (workspaceX2 - workspaceX1) * 0.5 + workspaceOriginX;
    const workspacePosY = (workspaceY2 - workspaceY1) * 0.5 + workspaceOriginY;
    const workspacePosZ = (workspaceZ2 - workspaceZ1) * 0.5 + workspaceOriginZ;

    const workspaceGeometry = new THREE.EdgesGeometry(
      new THREE.BoxGeometry(workspaceSizeX, workspaceSizeY, workspaceSizeZ),
    );

    const workspaceMaterial = new THREE.LineBasicMaterial({
      color: '#FFFFFF',
      opacity: 0.15,
      transparent: true,
      linewidth: 1,
    });

    const workspace = new THREE.LineSegments(
      workspaceGeometry,
      workspaceMaterial,
    );

    workspace.position.x = workspacePosX;
    workspace.position.y = workspacePosY;
    workspace.position.z = workspacePosZ;

    this.add(workspace);
  }
}

export default Workspace;
