import { isUndefined } from 'lodash';

const parseCondition = (condition = '') => {
  const parsedCondition = condition.split(':');
  const conditionInputName = parsedCondition?.[0];
  let conditionValue = parsedCondition?.[1];
  let compareValueExtension = false;

  if (conditionValue?.includes('*.')) {
    compareValueExtension = true;
    conditionValue = conditionValue.replace('*.', '');
  }

  return { conditionInputName, conditionValue, compareValueExtension };
};

const matchCondition = (condition, inputNameConditions, files) => {
  const { conditionInputName, conditionValue, compareValueExtension } =
    parseCondition(condition);
  const fieldValue = inputNameConditions?.[conditionInputName];

  if (compareValueExtension) {
    const file = files.find((file) => file.id === fieldValue);

    return file?.filetype === conditionValue.toLowerCase();
  }

  return !isUndefined(conditionValue) && fieldValue === conditionValue;
};

const checkSomeCondition = (condition, inputNameConditions, files) => {
  const parsedConditions = condition.split('|').filter((c) => !!c);

  return parsedConditions.some((part) =>
    matchCondition(part, inputNameConditions, files),
  );
};

const checkMultiCondition = (condition, inputNameConditions, files) => {
  const multiCondition = condition.split('&').filter((c) => !!c);

  return multiCondition.every((condition) => {
    if (condition.includes('|')) {
      return checkSomeCondition(condition, inputNameConditions, files);
    }

    return matchCondition(condition, inputNameConditions, files);
  });
};

export const doesMeetTheConditions = (
  conditions,
  inputNameConditions,
  files,
) => {
  return conditions.every((condition) => {
    const isMultiCondition = condition.includes('&');

    if (isMultiCondition) {
      const parsedMultiCondition = condition.split('|').filter((c) => !!c);

      return parsedMultiCondition.some(checkMultiCondition);
    }

    return checkSomeCondition(condition, inputNameConditions, files);
  });
};
