import React from 'react';

/* eslint react/no-unknown-property: 0 */

export default function Light() {
  const d = 50;

  return (
    <>
      <hemisphereLight args={[0xffffbb, 0x080820, 0.2]} position={[0, 0, 1]} />

      <spotLight args={[0xeadcdc, 0.5]} position={[-50, 50, 150]} castShadow />

      <directionalLight
        args={[0xffffff, 0.9]}
        position={[0.75, 0.75, 0.75]}
        color={[1, 1, 0.95]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-bias={-0.0001}
        shadow-camera-left={-d}
        shadow-camera-right={d}
        shadow-camera-top={d}
        shadow-camera-bottom={-d}
        shadow-camera-far={3500}
        castShadow
      />

      <directionalLight
        args={[0xffffff, 0.6]}
        position={[-0.75, -0.75, 0.75]}
        color={[1, 1, 0.95]}
      />
    </>
  );
}
