import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const LineTypeIcons = styled.div`
  ${flexCenterVertical}
  flex-shrink: 0;
`;

export const LineTypeIcon = styled.span`
  display: flex;

  ${({ color, theme: { spacing, colors, borderRadius } }) => css`
    width: ${spacing.level4};
    height: ${spacing.level4};
    border-radius: ${borderRadius.full};
    background-color: ${colors.onSurface};

    ${when(
      color,
      `
        background-color: ${colors?.[color] || color};
      `,
    )}

    &:not(:first-child) {
      margin-left: calc(-${spacing.level4} / 2);
    }
  `}
`;
