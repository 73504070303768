import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { whenEnterButtonPressed } from '@utils/interactionEvents';
import {
  Wrapper,
  Content,
  StateLayer,
  RadioInput,
  Icon,
} from './RadioButton.styled';

export default function RadioButton({
  checked,
  className,
  dataTestId = 'radio-button',
  diffAdded,
  diffModified,
  diffRemoved,
  dirty,
  disabled,
  id,
  name = '',
  onChange,
  value,
}) {
  const handleOnChange = useCallback(
    ({ enterButtonPressed } = {}) => onChange?.(value, enterButtonPressed),
    [value, onChange],
  );

  const handleOnKeyPress = useCallback(() => {
    handleOnChange({ enterButtonPressed: true });
  }, [handleOnChange]);

  return (
    <Wrapper data-testid={dataTestId} disabled={disabled} className={className}>
      <Content>
        <RadioInput
          checked={checked}
          data-testid={`${dataTestId}__input-radio`}
          disabled={disabled}
          id={id}
          name={name}
          value={value}
          onChange={handleOnChange}
          onKeyDown={whenEnterButtonPressed(handleOnKeyPress)}
        />

        <Icon
          disabled={disabled}
          checked={checked}
          diffAdded={diffAdded}
          diffModified={diffModified}
          diffRemoved={diffRemoved}
          dirty={dirty}
          name={checked ? 'radio_button_checked_0' : 'radio_button_unchecked_0'}
        />

        <StateLayer disabled={disabled} />
      </Content>
    </Wrapper>
  );
}

RadioButton.propTypes = {
  dataTestId: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
