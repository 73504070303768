import { omit } from 'lodash';
import dimensions from './dimensions';
import breakpoints from './breakpoints';
import {
  colorsSource,
  legacyColors,
  lightThemeColors,
  darkThemeColors,
} from './colors';
import { spacing, borderRadius } from './spacing';
import font from './font';

export const THEME_NAME_DARK = 'dark';
export const THEME_NAME_LIGHT = 'light';

const colors = {
  ...legacyColors,
  colorsSource,
  themeColors: {
    lightThemeColors,
    darkThemeColors,
  },
};

export const theme = {
  borderRadius,
  breakpoints,
  colors,
  dimensions,
  font,
  spacing,
};

export const darkTheme = {
  themeName: THEME_NAME_DARK,
  isDarkTheme: true,
  ...omit(theme, ['themeColors']),
  colors: {
    ...legacyColors,
    ...darkThemeColors,
    colorsSource,
  },
};

export const lightTheme = {
  themeName: THEME_NAME_LIGHT,
  isLightTheme: true,
  ...omit(theme, ['themeColors']),
  colors: {
    ...legacyColors,
    ...lightThemeColors,
    colorsSource,
  },
};
