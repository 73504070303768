import * as actionTypes from '@constants/actionTypes';

export const quickStartProject = (
  isCreateNewProjectFlow,
  extruderDefinitions,
  formValues = {},
  navigateTo = () => {},
) => ({
  type: actionTypes.QUICK_START_PROJECT_REQUEST,
  payload: {
    isCreateNewProjectFlow,
    formValues,
    navigateTo,
    extruderDefinitions,
  },
});

export const quickStartProjectSuccess = () => ({
  type: actionTypes.QUICK_START_PROJECT_SUCCESS,
});

export const quickStartProjectFailure = (error, errorMessage = null) => ({
  type: actionTypes.QUICK_START_PROJECT_FAILURE,
  payload: { error, errorMessage },
});

export const setCurrentStage = (name = '', data = {}) => ({
  type: actionTypes.QUICK_START_SET_CURRENT_STAGE,
  payload: {
    stage: {
      name,
      data,
    },
  },
});

export const setQuickStartCompletedStage = (name = '', data) => ({
  type: actionTypes.SET_QUICK_START_COMPLETED_STAGE,
  payload: {
    stage: {
      name,
      data,
    },
  },
});

export const resetQuickStart = () => ({
  type: actionTypes.RESET_QUICK_START,
});

export const quickStartRedirectionCompleted = () => ({
  type: actionTypes.QUICK_START_REDIRECTION_COMPLETED,
});
