import styled, { css } from 'styled-components';
import SVG from '@components/1-atoms/SVG';
import { flexCenterVertical, flexColumn } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level4};
    padding: ${spacing.level10};
  `}
`;

export const Logo = styled(SVG).attrs({ name: 'logo-large' })`
  flex-shrink: 0;

  ${({ theme: { spacing, colors } }) => css`
    width: ${spacing.level10};
    height: ${spacing.level10};
    margin-bottom: ${spacing.level6};

    g path {
      fill: ${colors.onSurface};
    }
  `}
`;

export const Footer = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level4};
    padding-top: ${spacing.level6};
  `}
`;
