import * as actionTypes from '@constants/actionTypes';

export const setCameraConfig = (config = '') => ({
  type: actionTypes.SET_SCENE_CAMERA_CONFIG,
  payload: { config },
});

export const focusCameraOnObject = (focus = false) => ({
  type: actionTypes.FOCUS_CAMERA_ON_OBJECT,
  payload: { focus },
});
