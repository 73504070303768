import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import Skeleton, {
  SKELETON_VARIANT_DEFAULT,
  SKELETON_VARIANT_ONE_LINE,
} from '@components/1-atoms/Skeleton';
import { Wrapper } from './SkeletonList.styled';

const SkeletonList = ({
  className,
  dataTestId = 'skeleton-list',
  borderRadius,
  length = 3,
  variant,
  height,
  withIcon,
  withLine,
}) => {
  const [uuid] = useState(uniqueId('skeleton-list'));
  const skeletonList = Array.from(
    { length },
    (_, index) => `${uuid}-skeleton-${index}`,
  );

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {skeletonList.map((id) => (
        <Skeleton
          borderRadius={borderRadius}
          key={id}
          variant={variant}
          height={height}
          withIcon={withIcon}
          withLine={withLine}
        />
      ))}
    </Wrapper>
  );
};

SkeletonList.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  borderRadius: PropTypes.string,
  length: PropTypes.number.isRequired,
  variant: PropTypes.oneOf([
    SKELETON_VARIANT_DEFAULT,
    SKELETON_VARIANT_ONE_LINE,
  ]),
  height: PropTypes.number,
  withIcon: PropTypes.bool,
  withLine: PropTypes.bool,
};

export default SkeletonList;
