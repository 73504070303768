import DialogPortal from '@components/2-molecules/DialogPortal';
import { Formik } from 'formik';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { MFA_CODE_LENGTH } from '@components/3-organisms/Login2FACode';
import { useDispatch, useSelector } from 'react-redux';
import { getMFA } from '@selectors/settingsSelectors';
import AccountMFA from '@components/AccountMFA';
import { validateMFACode } from '@actions/settingsActions';

const MODAL_ID = ModalDataTypes.CONNECT_MFA;

export const AddMFADialog = () => {
  const intl = useIntl();
  const { hideDialog } = useDialog();
  const mfa = useSelector(getMFA);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const mfaCodeFieldErrorMessage = useMemo(
    () => (
      <>
        <FormattedMessage
          id="loginpage.2fa.invalid_2fa_code"
          defaultMessage="Invalid two-factor authentication code."
        />
        <br />
        <FormattedMessage
          id="loginpage.2fa.please_try_again"
          defaultMessage="Please try again."
        />
      </>
    ),
    [],
  );

  const handleValidateMFACodeAnswer = useCallback(
    (setFieldError, setSubmitting) => (valid) => {
      if (!valid) {
        setFieldError('mfaCode', mfaCodeFieldErrorMessage);
      }
      setSubmitting(false);
    },
    [mfaCodeFieldErrorMessage],
  );

  const handleValidateMFACodeSuccess = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onFormSubmit = useCallback(
    async ({ mfaCode }, { setSubmitting, setErrors, setFieldError }) => {
      setErrors({});

      dispatch(
        validateMFACode(
          mfaCode,
          handleValidateMFACodeAnswer(setFieldError, setSubmitting),
          handleValidateMFACodeSuccess,
        ),
      );
    },
    [dispatch, handleValidateMFACodeSuccess, handleValidateMFACodeAnswer],
  );

  const formInitialValues = useMemo(
    () => ({
      mfaCode: '',
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    return Yup.object({
      mfaCode: Yup.string()
        .required(
          intl.formatMessage({
            id: 'loginpage.2fa.code_cannot_be_empty',
            defaultMessage: 'Two-factor authentication code cannot be empty',
          }),
        )
        .length(
          MFA_CODE_LENGTH,
          intl.formatMessage({
            id: 'loginpage.2fa.minimum_6_characters',
            defaultMessage:
              'Minimum 6 characters required for two-factor authentication code',
          }),
        ),
    });
  }, [intl]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({
        isSubmitting,
        handleSubmit,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
      }) => (
        <DialogPortal
          renderAsForm
          dataTestId="printer-manage-visibility"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'loginpage.2fa_connector.set_up_mfa.label',
            defaultMessage: 'Set up',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <AccountMFA
            className="login-step"
            handleSubmit={handleSubmit}
            mfaSetupCode={mfa?.encryptedSecret}
            handleFieldBlur={handleBlur}
            handleFieldChange={handleChange}
            qrCodeUrl={mfa?.qrCodeUrl}
            mfaCode={values?.mfaCode}
            mfaCodeError={!!errors?.mfaCode}
            mfaCodeErrorMessage={errors?.mfaCode}
            mfaCodeTouched={touched?.mfaCode}
          />
        </DialogPortal>
      )}
    </Formik>
  );
};

AddMFADialog.propTypes = {};

export default AddMFADialog;
