import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import usePrevious from '@hooks/usePrevious';
import { Canvas as ThreeCanvas, useFrame, useThree } from '@react-three/fiber';
import {
  PerspectiveCamera as ThreePerspectiveCamera,
  OrbitControls,
} from '@react-three/drei';
import useFileQueries from '@hooks/files/useFileQueries';
import { getCameraPosition } from '@selectors/conceptSelectors';
import VisualizationLight from '@containers/Visualization/Light';
import {
  isGLBModel,
  isObject3DModel,
  getObjectDimensions,
} from '@utils/camera';
import { updateModel } from '@utils/model';

/* eslint react/no-unknown-property: 0 */

export const FileObject = ({ object, getScreenshot }) => {
  const { gl } = useThree();
  const objectIsRenderedRef = useRef();
  const screenshotIsTakenRef = useRef();

  useFrame(() => {
    // Check if the object is rendered
    if (!objectIsRenderedRef.current) {
      objectIsRenderedRef.current = true;
    }
  }, -1);

  useFrame(() => {
    const skip =
      screenshotIsTakenRef.current ||
      !objectIsRenderedRef.current ||
      !getScreenshot;

    if (skip) return;

    screenshotIsTakenRef.current = true;

    gl.domElement.toBlob((blob) => {
      getScreenshot(blob);
    });
  }, -2);

  return <primitive object={object} />;
};

FileObject.propTypes = {
  object: PropTypes.object,
  getScreenshot: PropTypes.func,
};

export default function FilePreview({ file, offset = 0, getScreenshot }) {
  const [object, setObject] = useState(null);
  const [target, setTarget] = useState([0, 0, 0]);
  const [isFocused, setIsFocused] = useState(false);
  const cameraPosition = useSelector(getCameraPosition);
  const theme = useTheme();
  const cameraRef = useRef();
  const camera = cameraRef.current;
  const shouldRenderObject = isFocused && object;

  const { designQuery } = useFileQueries({
    designId: file?.id,
  });
  const isDesignQueryFetched = designQuery.isFetched;
  const previousIsDesignQueryFetched = usePrevious(isDesignQueryFetched);

  const focuOnObject = useCallback(
    async (object) => {
      const skip = !camera || !object;

      if (skip) return;

      const { center, size } = await getObjectDimensions(object);

      // figure out how to fit the box in the view:
      // 1. figure out horizontal FOV (on non-1.0 aspects)
      // 2. figure out distance from the object in X and Y planes
      // 3. select the max distance (to fit both sides in)
      //
      // The reason is as follows:
      //
      // Imagine a bounding box (BB) is centered at (0,0,0).
      // Camera has vertical FOV (camera.fov) and horizontal FOV
      // (camera.fov scaled by aspect, see fovh below)
      //
      // Therefore if you want to put the entire object into the field of view,
      // you have to compute the distance as: z/2 (half of Z size of the BB
      // protruding towards us) plus for both X and Y size of BB you have to
      // figure out the distance created by the appropriate FOV.
      //
      // The FOV is always a triangle:
      //
      //  (size/2)
      // +--------+
      // |       /
      // |      /
      // |     /
      // | F° /
      // |   /
      // |  /
      // | /
      // |/
      //
      // F° is half of respective FOV, so to compute the distance (the length
      // of the straight line) one has to: `size/2 / Math.tan(F)`.
      //
      // FTR, from https://threejs.org/docs/#api/en/cameras/PerspectiveCamera
      // the camera.fov is the vertical FOV.
      const fov = camera.fov * (Math.PI / 180);
      const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
      const dx = size.z / 2 + Math.abs(size.x / 2 / Math.tan(fovh / 2));
      const dy = size.z / 2 + Math.abs(size.y / 2 / Math.tan(fov / 2));
      let cameraZ = Math.max(Math.max(dx, dy), center.z);

      // offset the camera, if desired (to avoid filling the whole canvas)
      if (offset !== 0) cameraZ *= offset;

      const distanceXY = Math.sqrt(cameraZ * cameraZ) / Math.sqrt(2);

      const cameraPosition = [
        center.x + distanceXY,
        center.y + distanceXY,
        cameraZ,
      ];

      setTarget?.(center);
      camera?.position?.set(...cameraPosition);
      setIsFocused(true);
    },
    [camera, offset, setTarget],
  );

  useEffect(() => {
    focuOnObject(object);
  }, [focuOnObject, object]);

  useEffect(() => {
    if (isDesignQueryFetched && !previousIsDesignQueryFetched) {
      const model = updateModel(file, designQuery.data?.displayData, {});

      if (isObject3DModel(model)) {
        setObject(model);

        return;
      }

      if (isGLBModel(model)) {
        model.getLoadedModelPromise().then((gltfObject) => {
          setObject(gltfObject.scene);
        });

        return;
      }

      setObject(null);
    }
  }, [
    file,
    designQuery.data,
    isDesignQueryFetched,
    previousIsDesignQueryFetched,
  ]);

  // eslint-disable-next-line no-undef
  const aspect = (window?.innerWidth || 0) / (window?.innerHeight || 0);
  const position = useMemo(
    () => [
      cameraPosition?.cameraX,
      cameraPosition?.cameraY,
      cameraPosition?.cameraZ,
    ],
    [cameraPosition],
  );

  return (
    <ThreeCanvas
      gl={{
        'shadowMap.enabled': true,
        alpha: true,
        preserveDrawingBuffer: true,
      }}
      style={{
        backgroundColor: theme?.colors?.surfaceDim,
      }}
      shadows
    >
      <VisualizationLight />
      <ThreePerspectiveCamera
        ref={cameraRef}
        makeDefault
        position={position}
        up={[0, 0, 1]}
        fov={15}
        aspect={aspect}
        near={1}
        far={10000}
        visible={false}
      />

      <OrbitControls
        target={target}
        zoomSpeed={3}
        maxDistance={1000}
        minDistance={0.1}
        enableDamping={false}
      />

      {shouldRenderObject && (
        <FileObject object={object} getScreenshot={getScreenshot} />
      )}
    </ThreeCanvas>
  );
}

FilePreview.propTypes = {
  file: PropTypes.object,
  offset: PropTypes.number,
  getScreenshot: PropTypes.func,
};
