import styled, { css } from 'styled-components';
import { flexColumn, when } from '@stylesheets/helpers';

export const DROP_DOWN_MENU_POSITION_TOP_LEFT = 'top left';
export const DROP_DOWN_MENU_POSITION_TOP_CENTER = 'top center';
export const DROP_DOWN_MENU_POSITION_TOP_RIGHT = 'top right';
export const DROP_DOWN_MENU_POSITION_BOTTOM_LEFT = 'bottom left';
export const DROP_DOWN_MENU_POSITION_BOTTOM_CENTER = 'bottom center';
export const DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT = 'bottom right';

export const OverlayLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Wrapper = styled.div`
  display: none;
  width: 240px;
  min-height: 60px;
  max-height: 400px;
  ${flexColumn}
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  outline: none;

  ${({
    style,
    fixedPosition,
    position,
    show,
    theme: { borderRadius, spacing, colors },
  }) => css`
    border-radius: ${borderRadius.small};
    background-color: ${colors.surfaceContainerHigh};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: ${spacing.level3} 0;

    ${when(
      !style?.width,
      `
        max-width: 280px;
      `,
    )}

    ${when(
      !fixedPosition,
      `
        position: absolute;

        ${when(
          [
            DROP_DOWN_MENU_POSITION_TOP_LEFT,
            DROP_DOWN_MENU_POSITION_TOP_CENTER,
            DROP_DOWN_MENU_POSITION_TOP_RIGHT,
          ].includes(position),
          `
            bottom: calc(100% + 10px);
            transform: translate(0, -10px);
          `,
        )}

        ${when(
          [
            DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
            DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
            DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
          ].includes(position),
          `
            top: calc(100% + 10px);
            transform: translate(0, 10px);
          `,
        )}

        ${when(
          [
            DROP_DOWN_MENU_POSITION_TOP_LEFT,
            DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
          ].includes(position),
          `
            left: 0;
          `,
        )}

        ${when(
          [
            DROP_DOWN_MENU_POSITION_TOP_CENTER,
            DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
          ].includes(position),
          `
            left: 50%;
          `,
        )}

        ${when(
          DROP_DOWN_MENU_POSITION_TOP_CENTER === position,
          `
            left: 50%;
            transform: translate(-50%, -10px);
          `,
        )}

        ${when(
          DROP_DOWN_MENU_POSITION_BOTTOM_CENTER === position,
          `
            left: 50%;
            transform: translate(-50%, 10px);
          `,
        )}

        ${when(
          [
            DROP_DOWN_MENU_POSITION_TOP_RIGHT,
            DROP_DOWN_MENU_POSITION_BOTTOM_RIGHT,
          ].includes(position),
          `
            right: 0;
          `,
        )}

        ${when(
          show,
          `
            transform: translate(0, 0);

            ${when(
              [
                DROP_DOWN_MENU_POSITION_TOP_CENTER,
                DROP_DOWN_MENU_POSITION_BOTTOM_CENTER,
              ].includes(position),
              `
                transform: translate(-50%, 0);
              `,
            )}
          `,
        )}
      `,
    )}

    ${when(
      show,
      `
        display: flex;
        opacity: 1;
        visibility: visible;
      `,
    )}
  `}
`;
