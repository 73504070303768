import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { useIntl } from 'react-intl';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { DiagramRowComponent } from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const BedTypeComponent = ({
  titleId,
  settings,
  label,
  specialCondition,
  noBorderBottom,
  withDiagram,
}) => {
  const intl = useIntl();
  const { values, initialValues, setFieldValue } = useFormikContext();
  const a1Value = values['bedTypeSetting']['bedProperties'].a1;

  useEffect(() => {
    if (specialCondition) {
      setFieldValue(`bedTypeSetting.bedProperties.d2`, -a1Value);
    }
  }, [a1Value, setFieldValue, specialCondition]);

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: titleId,
      })}
      dataTestId={`printer-components-bed-type__category-${label}`}
      expand
      withDividerBottom={!noBorderBottom}
    >
      {withDiagram && (
        <DiagramRowComponent
          src={`/img/digitalTwin/bed/diagram_${withDiagram}parameters.svg`}
          description_key={`printers.settings.bed.diagram.parameters.${withDiagram}.description.label`}
        />
      )}
      {settings.map((entry) => {
        return entry.checkbox ? (
          <SettingCheckbox
            key={entry.field}
            label={intl.formatMessage({
              id: `printers.settings.${label}.${entry.field}.label`,
            })}
            dataTestId={`printer-components-bed-type__category-${label}__setting-${entry.field}`}
            checkbox={{
              checked: values.bedTypeSetting.bedProperties[entry.field],
              onChange: () => {
                setFieldValue(
                  `bedTypeSetting.bedProperties.${entry.field}`,
                  !values.bedTypeSetting.bedProperties[entry.field],
                );
              },
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `bedTypeSetting.bedProperties.${entry.field}`,
              ),
            }}
          />
        ) : (
          <FormikField
            key={entry.field}
            component={SettingTextField}
            label={intl.formatMessage({
              id: `printers.settings.${label}.${entry.field}.label`,
            })}
            field1={{
              type: 'number',
              dirty: checkIfFieldIsDirty(
                values,
                initialValues,
                `bedTypeSetting.bedProperties.${entry.field}`,
              ),
            }}
            dataTestId={`printer-components-bed-type__category-${label}__setting-${entry.field}`}
            name={`bedTypeSetting.bedProperties.${entry.field}`}
          />
        );
      })}
    </SettingsCategory>
  );
};

BedTypeComponent.propTypes = {
  titleId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  settings: PropTypes.array.isRequired,
  specialCondition: PropTypes.bool,
  withDiagram: PropTypes.string,
  noBorderBottom: PropTypes.bool,
};
