import getIntlProvider from '@utils/getIntlProvider';

const intl = getIntlProvider();

export const CAMERA_POSITION_PERSPECTIVE = 'perspective';
export const CAMERA_POSITION_TOP = 'top';
export const CAMERA_POSITION_BOTTOM = 'bottom';
export const CAMERA_POSITION_FRONT = 'front';
export const CAMERA_POSITION_BACK = 'back';
export const CAMERA_POSITION_LEFT = 'left';
export const CAMERA_POSITION_RIGHT = 'right';

export const CAMERA_MODE_PERSPECTIVE = 'perspective';
export const CAMERA_MODE_ORTHOGRAPHIC = 'orthographic';

export const CAMERA_FOCUS_ENABLE_TIMEOUT = 100;

export const ORTHOGRAPHIC_CAMERA_MAX_ZOOM = 4000;
export const ORTHOGRAPHIC_CAMERA_MIN_ZOOM = 5;

export const orthographicCameraPositions = [
  CAMERA_POSITION_TOP,
  CAMERA_POSITION_BOTTOM,
  CAMERA_POSITION_FRONT,
  CAMERA_POSITION_BACK,
  CAMERA_POSITION_LEFT,
  CAMERA_POSITION_RIGHT,
];

export const PERSPECTIVE_CAMERA_DEFAULT_POSITION = [0, -300, 300];

export const ORTHOGRAPHIC_CAMERA_UP = [0, 0, 1];
export const ORTHOGRAPHIC_CAMERA_DEFAULT_POSITION = [
  0,
  0,
  ORTHOGRAPHIC_CAMERA_MAX_ZOOM,
];

export const orthographicCameraUp = {
  default: [0, 0, 0],
  [CAMERA_POSITION_LEFT]: [0, 0, 1],
  [CAMERA_POSITION_RIGHT]: [0, 0, 1],
};

export const orthographicCameraDirections = {
  [CAMERA_POSITION_TOP]: [0, 0, 1],
  [CAMERA_POSITION_BOTTOM]: [0, 0, -1],
  [CAMERA_POSITION_FRONT]: [0, -1, 0],
  [CAMERA_POSITION_BACK]: [0, 1, 0],
  [CAMERA_POSITION_LEFT]: [1, 0, 0],
  [CAMERA_POSITION_RIGHT]: [-1, 0, 0],
};

export const cameraPositionNames = {
  [CAMERA_POSITION_PERSPECTIVE]: intl.formatMessage({
    id: 'viewport_selection.option.perspective',
    defaultMessage: 'Perspective',
  }),
  [CAMERA_POSITION_TOP]: intl.formatMessage({
    id: 'viewport_selection.option.top',
    defaultMessage: 'Top',
  }),
  [CAMERA_POSITION_BOTTOM]: intl.formatMessage({
    id: 'viewport_selection.option.bottom',
    defaultMessage: 'Bottom',
  }),
  [CAMERA_POSITION_FRONT]: intl.formatMessage({
    id: 'viewport_selection.option.front',
    defaultMessage: 'Front',
  }),
  [CAMERA_POSITION_BACK]: intl.formatMessage({
    id: 'viewport_selection.option.back',
    defaultMessage: 'Back',
  }),
  [CAMERA_POSITION_LEFT]: intl.formatMessage({
    id: 'viewport_selection.option.left',
    defaultMessage: 'Left',
  }),
  [CAMERA_POSITION_RIGHT]: intl.formatMessage({
    id: 'viewport_selection.option.right',
    defaultMessage: 'Right',
  }),
};

export const cameraPositionConfigs = [
  {
    id: 'perspective-camera-position',
    iconName: 'mesh_0',
    title: cameraPositionNames[CAMERA_POSITION_PERSPECTIVE],
    position: CAMERA_POSITION_PERSPECTIVE,
    mode: CAMERA_MODE_PERSPECTIVE,
  },
  {
    id: 'top-camera-position',
    iconName: 'front_view_0',
    title: cameraPositionNames[CAMERA_POSITION_FRONT],
    position: CAMERA_POSITION_FRONT,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
  {
    id: 'front-camera-position',
    iconName: 'top_view_0',
    title: cameraPositionNames[CAMERA_POSITION_TOP],
    position: CAMERA_POSITION_TOP,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
  {
    id: 'left-camera-position',
    iconName: 'left_view_0',
    title: cameraPositionNames[CAMERA_POSITION_LEFT],
    position: CAMERA_POSITION_LEFT,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
  {
    id: 'right-camera-position',
    iconName: 'right_view_0',
    title: cameraPositionNames[CAMERA_POSITION_RIGHT],
    position: CAMERA_POSITION_RIGHT,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
  {
    id: 'back-camera-position',
    iconName: 'back_view_0',
    title: cameraPositionNames[CAMERA_POSITION_BACK],
    position: CAMERA_POSITION_BACK,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
  {
    id: 'bottom-camera-position',
    iconName: 'bottom_view_0',
    title: cameraPositionNames[CAMERA_POSITION_BOTTOM],
    position: CAMERA_POSITION_BOTTOM,
    mode: CAMERA_MODE_ORTHOGRAPHIC,
  },
];

export const DEFAULT_CAMERA_CONFIG = cameraPositionConfigs[0];
