import endpoints from '@api/endpoints';
import client from '@api/client';
import { useMutation } from '@tanstack/react-query';
import {
  formatOperatorsWithDiffProps,
  generateStaticErrorAnswer,
} from '@utils/gptHelper';
import useWorkflow from '@hooks/workflows/useWorkflow';

export const gptMutationKeys = {
  sendConversation: ['sendConversation'],
  applyChanges: ['applyChanges'],
};
export default function useGptMutations() {
  const { getSelectedWorkflow } = useWorkflow();
  const workflow = getSelectedWorkflow();

  const sendMessageMutation = useMutation({
    mutationKey: gptMutationKeys.sendConversation,
    mutationFn: async ({ message }) => {
      const workflowId = workflow?.id;
      const response = await client
        .post(
          endpoints.generativeWorkflow.replace(':workflowId', workflowId),
          {
            prompt: message,
          },
          {
            ignoreGlobalErrorHandler: true,
          },
        )
        .then((res) => res.data)
        .catch(() => {
          return generateStaticErrorAnswer();
        });

      const { new_actions, proposed_workflow, answer_text, error } =
        response || {};

      if (error || !new_actions || !proposed_workflow || !answer_text) {
        return {
          answerMessage: answer_text ?? 'Error',
          error: true,
        };
      }

      const { originalWorkflowDiff, proposedWorkflowDiff } =
        formatOperatorsWithDiffProps(workflow, proposed_workflow, new_actions);

      /**
       * Store actions, when extending allowing to allow multiple prompts
       * const actionsMessage = {type: 'actions', data: new_actions};
       */

      return {
        newActions: new_actions,
        proposedWorkflow: proposed_workflow,
        originalWorkflowDiff,
        proposedWorkflowDiff,
        answerMessage: answer_text,
        error,
      };
    },
  });

  const applyChangesMutation = useMutation({
    mutationKey: gptMutationKeys.applyChanges,
    mutationFn: async ({ proposedWorkflow }) => {
      const workflowId = workflow?.id;
      const requestUrl = endpoints.generativeWorkflow.replace(
        ':workflowId',
        workflowId,
      );
      return await client
        .put(requestUrl, { operators: proposedWorkflow.operators })
        .then(async (res) => {
          return res.data;
        })
        .catch(() => {
          return generateStaticErrorAnswer();
        });
    },
  });

  return {
    sendMessageMutation,
    applyChangesMutation,
  };
}
