import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  overflow: hidden;
`;

export const Content = styled.div`
  display: grid;
  flex: 1;
  overflow: hidden;

  ${({ theme: { screenSize, colors, dimensions } }) => css`
    grid-template-columns: ${dimensions.baseLayoutSideBarWidth?.[screenSize]} 1fr;
    background-color: ${colors.surface};
  `}
`;

export const Sidebar = styled.div`
  overflow: hidden;
`;

export const Main = styled.div`
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level6} ${spacing.level6} 0 ${spacing.level6};
  `}
`;

export const BannerWrapper = styled.div``;
