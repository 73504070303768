import styled, { css } from 'styled-components';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: min(1200px, 100%);
  ${flexCenterVertical}

  ${({ theme: { spacing, borderRadius, colors } }) => css`
    gap: ${spacing.level1};
    border-radius: ${borderRadius.full};
    background-color: ${colors.surfaceContainerLow};
    padding: ${spacing.level1};
  `}
`;
