import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import {
  ICON_BUTTON_VARIANT_PRIMARY,
  ICON_BUTTON_VARIANT_TONAL,
  ICON_BUTTON_VARIANT_STANDARD,
  ICON_BUTTON_VARIANT_PLAIN,
} from './IconButton';
import { flexCenter, when } from '@stylesheets/helpers';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Content = styled.span`
  ${flexCenter}
  position: relative;

  ${({
    variant = '',
    disabled,
    selected,
    theme: { spacing, borderRadius, colors },
  }) => css`
    border-radius: ${borderRadius?.full};
    padding: ${spacing?.level3};

    ${when(
      variant === ICON_BUTTON_VARIANT_PRIMARY && !disabled,
      `
        background-color: ${colors?.primary};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_TONAL && !disabled,
      `
        background-color: ${colors?.secondaryContainer};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_PLAIN,
      `
        background-color: ${rgba(colors?.inverseSurface, 0.12)};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_STANDARD && selected && !disabled,
      `
        background-color: ${colors?.secondaryContainer};
      `,
    )}

    ${when(
      ![ICON_BUTTON_VARIANT_STANDARD].includes(variant) && disabled,
      `
        background-color: ${rgba(colors?.onSurface, 0.12)};
      `,
    )}

    ${when(
      [ICON_BUTTON_VARIANT_PLAIN].includes(variant) && disabled,
      `
        background-color: unset;
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;

  ${({
    variant = '',
    iconColor,
    disabled,
    selected,
    $loading,
    theme: { colors },
  }) => css`
    ${variant === ICON_BUTTON_VARIANT_PRIMARY &&
    `
      fill: ${colors?.onPrimary};
    `}

    ${when(
      variant === ICON_BUTTON_VARIANT_TONAL,
      `
        fill: ${colors?.onSecondaryContainer};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_STANDARD,
      `
        fill: ${colors?.onSurface};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_STANDARD && selected,
      `
        fill: ${colors?.onSecondaryContainer};
      `,
    )}

    ${when(
      variant === ICON_BUTTON_VARIANT_PLAIN,
      `
        fill: ${colors?.onSurfaceVariant};
      `,
    )}

    ${when(
      iconColor && colors?.[iconColor],
      `
        fill: ${colors?.[iconColor]};
      `,
    )}

    ${when(
      disabled,
      `
        fill: ${colors?.onSurface};
        opacity: 0.38;
      `,
    )}

    ${when(
      $loading,
      css`
        animation: ${spin} 2s linear infinite;
      `,
    )}
  `}
`;

export const Wrapper = styled.button`
  ${flexCenter}
  flex-shrink: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;

  ${({ theme: { spacing, borderRadius } }) => css`
    border-radius: ${borderRadius?.full};
    padding: ${spacing?.level2};
  `}

  &:hover {
    ${Content} {
      ${({ variant = '', selected, theme: { colors } }) => css`
        ${when(
          variant === ICON_BUTTON_VARIANT_PRIMARY,
          `
            background-color: ${rgba(colors?.primary, 0.92)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_STANDARD,
          `
            background-color: ${rgba(colors?.onSurfaceVariant, 0.08)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_PLAIN,
          `
            background-color: ${rgba(colors?.inverseSurface, 0.16)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_TONAL,
          `
            background-color: ${rgba(colors?.onSecondaryContainer, 0.08)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_STANDARD && selected,
          `
            background-color: ${rgba(colors?.secondaryContainer, 0.92)};
          `,
        )}
      `}
    }
  }

  &:focus:focus-visible {
    ${Content} {
      outline: -webkit-focus-ring-color auto 1px;

      ${({ variant = '', selected, theme: { colors } }) => css`
        ${when(
          variant === ICON_BUTTON_VARIANT_PRIMARY,
          `
            background-color: ${rgba(colors?.primary, 0.88)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_STANDARD,
          `
            background-color: ${rgba(colors?.onSurfaceVariant, 0.12)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_TONAL,
          `
            background-color: ${rgba(colors?.onSecondaryContainer, 0.12)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_PLAIN,
          `
            background-color: ${rgba(colors?.inverseSurface, 0.16)};
          `,
        )}

        ${when(
          variant === ICON_BUTTON_VARIANT_STANDARD && selected,
          `
            background-color: ${colors?.secondaryContainer};
          `,
        )}
      `}
    }
  }

  ${({ disabled = '', $loading }) =>
    (disabled || $loading) &&
    css`
      pointer-events: none;
    `}
`;
