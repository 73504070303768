import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Rule,
  RuleIcon,
  RuleDescription,
} from './RulesMatcher.styled';

const RulesMatcher = ({
  dataTestId = 'rules-matcher',
  rules,
  successMessages,
}) => {
  const allRulesMatch = useMemo(
    () => rules.every(({ matchCondition }) => matchCondition),
    [rules],
  );

  const withSuccessMessages = useMemo(
    () => !!successMessages?.length,
    [successMessages],
  );

  return (
    <Wrapper data-testid={dataTestId}>
      {(!allRulesMatch || !withSuccessMessages) &&
        rules.map(({ id, description, matchCondition }) => (
          <Rule key={id || description}>
            <RuleIcon
              data-testid={`${dataTestId}__icon`}
              matchCondition={matchCondition}
            />
            <RuleDescription
              data-testid={`${dataTestId}__description`}
              matchCondition={matchCondition}
            >
              {description}
            </RuleDescription>
          </Rule>
        ))}

      {allRulesMatch &&
        withSuccessMessages &&
        successMessages?.map?.((message) => (
          <Rule key="all-rules-match">
            <RuleIcon
              data-testid={`${dataTestId}__success-icon`}
              matchCondition
            />

            <RuleDescription
              data-testid={`${dataTestId}__success-message`}
              matchCondition
            >
              {message}
            </RuleDescription>
          </Rule>
        ))}
    </Wrapper>
  );
};

RulesMatcher.propTypes = {
  dataTestId: PropTypes.string,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      matchCondition: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  successMessages: PropTypes.arrayOf(PropTypes.string),
};

export default RulesMatcher;
