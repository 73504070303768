import React from 'react';
import Visualization from '@containers/Visualization';

export default function WorkspaceVisualization() {
  return (
    <Visualization.Canvas>
      <Visualization.Light />
      <Visualization.Camera />
      <Visualization.Scene />
      <Visualization.Grid />
      <Visualization.Printer />
      <Visualization.Objects />
      <Visualization.Clipping />
    </Visualization.Canvas>
  );
}
