import { FileTypes } from '../constants/fileTypes';
import { Printer } from '../lib/Printer';
import VisualizationUtils from '../lib/VisualizationUtils';

/**
 * It represents a .glb file
 */
export default class GlbModel {
  constructor(originalDesign, displayMode = 'SOLID', showDimension = false) {
    if (
      !originalDesign.fileGlbTwinURL ||
      !(
        (originalDesign.fileGlbTwinURL instanceof String ||
          typeof originalDesign.fileGlbTwinURL === 'string') &&
        originalDesign.fileGlbTwinURL.startsWith('http')
      )
    ) {
      throw 'Invaid Glb desing received!';
    }
    this.design = originalDesign;
    this.displayMode = displayMode;
    this.showDimension = showDimension;

    this.loadedModelPromise = this.loadGltfModel(this);
  }

  equals(other) {
    if (!(other instanceof GlbModel)) {
      return false;
    }
    if (other.getUrl() !== this.getUrl()) {
      return false;
    }
    return true;
  }

  getUrl() {
    return this.design.fileGlbTwinURL;
  }

  getDisplayMode() {
    return this.displayMode;
  }

  isShowingDimensions() {
    return this.showDimension;
  }

  getHashCode() {
    return (
      Math.abs(GlbModel.hashCode(this.design.fileGlbTwinURL)) +
      Math.abs(GlbModel.hashCode(this.displayMode)) * (this.showDimension + 1)
    );
  }

  getLoadedModelPromise() {
    return this.loadedModelPromise;
  }

  /**
   * Returns a hash code for a string.
   * (Compatible to Java's String.hashCode())
   *
   * The hash code for a string object is computed as
   *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
   * using number arithmetic, where s[i] is the i th character
   * of the given string, n is the length of the string,
   * and ^ indicates exponentiation.
   * (The hash value of the empty string is zero.)
   *
   * @param {string} s a string
   * @return {number} a hash code value for the given string.
   */
  static hashCode(s) {
    let h = 0;
    const l = s.length;
    let i = 0;
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
  }

  loadGltfModel = () => {
    const gltfUrl = this.getUrl();
    return new Promise((resolve) => {
      Printer.gltfLoader.load(gltfUrl, (gltf) => {
        if (this.design?.filetype?.toLowerCase() === FileTypes.gltf) {
          gltf.scene.position.set(0, 0, 0);
          gltf.scene.scale.set(0.02, -0.02, 0.02);
          gltf.scene.rotation.z = -Math.PI;
        } else {
          gltf.scene.children[0] = VisualizationUtils.convertGlbToObject3D(
            gltf,
            this.design?.filetype?.toLowerCase() !== FileTypes.stl,
          );
        }
        resolve(gltf);
      });
    });
  };
}
