import React, { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useDialog from '@hooks/useDialog';
import useSnackbar from '@hooks/useSnackbar';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import useProjectMutations from '@hooks/projects/useProjectMutations';
import DialogPortal from '@components/2-molecules/DialogPortal';
import CreateProject from '@containers/Create/CreateProject';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { ROUTES } from '@constants/router';

const MODAL_ID = ModalDataTypes.ADD_PROJECT;

const AddProjectDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { getDialogData, hideDialog } = useDialog();
  const { projectsQuery } = useProjectQueries();
  const { importProjectMutation } = useProjectMutations();
  const { refetch: refetchProjectsQuery } = projectsQuery;

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const {
    dataTestId = 'add-project',
    shareFlow,
    projectName,
    sentDataId,
    handleErrorResponse,
  } = dialogData;

  const form = useForm({
    defaultValues: {
      projectName,
    },
  });

  const { isSubmitting } = form.formState;

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    async ({ projectName }) => {
      try {
        if (shareFlow) {
          await importProjectMutation.mutateAsync({
            projectName,
            sentDataId,
          });

          handleClose();

          history.push(ROUTES.PROJECTS);

          showSnackbar({
            text: intl.formatMessage(
              {
                id: 'create_flow_dialog.snackbar.imported',
                defaultMessage:
                  'Your request to add a {name} has been submitted. You will receive a confirmation email shortly',
              },
              {
                name: projectName,
              },
            ),
          });
        }
      } catch (error) {
        handleErrorResponse?.(error?.response?.status);
      }
    },
    [
      intl,
      history,
      importProjectMutation,
      handleClose,
      sentDataId,
      shareFlow,
      showSnackbar,
      handleErrorResponse,
    ],
  );

  useEffect(() => {
    refetchProjectsQuery();

    return () => {
      handleClose();
    };
  }, [refetchProjectsQuery, handleClose]);

  return (
    <form onSubmit={form.handleSubmit(onFormSubmit)}>
      <DialogPortal
        renderAsForm
        dataTestId={dataTestId}
        dialogId={MODAL_ID}
        loading={isSubmitting || projectsQuery.isLoading}
        onClose={handleClose}
        primaryButtonLabel={intl.formatMessage({
          id: 'general.add',
          defaultMessage: 'Add',
        })}
        secondaryButtonLabel={intl.formatMessage({
          id: 'general.cancel',
          defaultMessage: 'Cancel',
        })}
      >
        <FormProvider {...form}>
          <CreateProject
            title={intl.formatMessage({
              id: 'addproject.project.header.title',
              defaultMessage: 'Add Project',
            })}
            subtitle={null}
          />
        </FormProvider>
      </DialogPortal>
    </form>
  );
};

AddProjectDialog.propTypes = {};

export default AddProjectDialog;
