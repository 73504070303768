import styled, { css } from 'styled-components';
import { when, flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${({
    backgroundColor,
    borderRadius,
    direction,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    theme: { colors, spacing, borderRadius: themeBorderRadius },
  }) => css`
    ${flexColumn}
    max-width: 100%;
    background-color: ${colors[backgroundColor]};

    ${when(direction, `flex-direction: ${direction};`)}
    ${when(
      borderRadius,
      `border-radius: ${themeBorderRadius[borderRadius] || borderRadius};`,
    )}
    ${when(padding, `padding: ${spacing[padding] || padding};`)}
    ${when(
      paddingLeft,
      `padding-left: ${spacing[paddingLeft] || paddingLeft};`,
    )}
    ${when(
      paddingRight,
      `padding-right: ${spacing[paddingRight] || paddingRight};`,
    )}
    ${when(paddingTop, `padding-top: ${spacing[paddingTop] || paddingTop};`)}
    ${when(
      paddingBottom,
      `padding-bottom: ${spacing[paddingBottom] || paddingBottom};`,
    )}
  `}
`;
