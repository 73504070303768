import PageList from '@components/3-organisms/PageList';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Wrapper } from '@pages/Materials/Materials.styled';
import useMaterialQueries from '@hooks/materials/useMaterialQueries';
import { useIntl } from 'react-intl';
import {
  materialConstants,
  materialIcons,
  materialTypeLabel,
} from '@constants/materials';
import useMaterialList from '@hooks/materials/useMaterialList';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import { DROP_DOWN_MENU_POSITION_BOTTOM_LEFT } from '@components/2-molecules/DropDownMenu';

export const Materials = () => {
  const intl = useIntl();
  const history = useHistory();
  const { materialsQuery } = useMaterialQueries();
  const { refetch: refetchMaterialsQuery } = materialsQuery;
  const { getMaterialDropDownMenuActions } = useMaterialList();
  const [filterByCategoryName, setFilterByCategoryName] = useState(
    materialConstants.ALL,
  );

  const { showDialog } = useDialog();

  useEffect(() => {
    refetchMaterialsQuery();
  }, [refetchMaterialsQuery]);

  const handleClick = useCallback(
    (material) => {
      history.push(
        generatePath(ROUTES.MATERIAL, {
          materialId: material?.id,
        }),
      );
    },
    [history],
  );

  const materialsListItems = useMemo(() => {
    const materialsList = materialsQuery.data;

    const filteredList = materialsList?.filter((material) => {
      if (filterByCategoryName === materialConstants.ALL) {
        return true;
      }

      return material.type === filterByCategoryName;
    });

    return filteredList?.map((material) => ({
      id: material.id,
      label: material.materialName,
      description: materialTypeLabel(material.type),
      createdAt: material.createdAt,
      leadingIconName: materialIcons[material.type],
      endingIconButtonIconName: 'more_vert_0',
      endingIconButtonDropDownProps: {
        dropDownMenuItems: getMaterialDropDownMenuActions(material),
      },
      onClick: () => handleClick(material),
    }));
  }, [
    filterByCategoryName,
    handleClick,
    getMaterialDropDownMenuActions,
    materialsQuery.data,
  ]);

  const showAddMaterialDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_MATERIAL);
  }, [showDialog]);

  const actionChips = useMemo(() => {
    const chips = [];
    const materialsList = materialsQuery.data;
    const uniqueTypes = new Set(
      materialsList?.map((material) => material.type),
    );
    const allCategories = [materialConstants.ALL, ...uniqueTypes];

    chips.push({
      id: 'materials-page-filter-material-chip',
      dataTestId: 'materials__filter-operators-chip',
      label: materialTypeLabel(filterByCategoryName),
      leadingIconName: 'filter_list_0',
      endingIconName: 'arrow_drop_down_1',
      dropDownMenuItems: allCategories?.map((category, i) => ({
        id: category,
        label: materialTypeLabel(category),
        withDivider: i === 1,
        selected: filterByCategoryName === category,
        onClick: () => setFilterByCategoryName(category),
      })),
      dropDownMenuPosition: DROP_DOWN_MENU_POSITION_BOTTOM_LEFT,
    });

    chips.push({
      id: 'materials-page-add-material-chip',
      variant: CHIP_VARIANT_PRIMARY,
      dataTestId: 'materials-page__create-add-material-chip',
      label: intl.formatMessage({
        id: 'materials.materials_list.add_material.button',
        defaultMessage: 'Add Material',
      }),
      leadingIconName: 'add_0',
      onClick: showAddMaterialDialog,
    });

    return chips;
  }, [materialsQuery.data, filterByCategoryName, showAddMaterialDialog, intl]);

  return (
    <Wrapper>
      <PageList
        dataTestId={'materials-list'}
        listItems={materialsListItems}
        loading={!materialsQuery.isFetched || materialsQuery.isLoading}
        createItemMessageIconName="person_0"
        createItemMessageTitle={intl.formatMessage({
          id: 'materials.materials_list.add_material.title',
          defaultMessage: 'No Materials yet',
        })}
        headerTitle={intl.formatMessage({
          id: 'navigation.link.materials',
          defaultMessage: 'Materials',
        })}
        createItemMessagDescription={intl.formatMessage({
          id: 'materials.materials_list.add_material.description',
          defaultMessage: 'Add a Material to get started',
        })}
        createItemMessageButtonLabel={intl.formatMessage({
          id: 'materials.materials_list.add_material.button',
          defaultMessage: 'Add Material',
        })}
        createItemMessageButtonIconName="add_0"
        createItemMessageOnButtonClick={showAddMaterialDialog}
        actionChips={actionChips}
        sortOptions={[
          {
            label: intl.formatMessage({
              id: 'general.date',
              defaultMessage: 'Date',
            }),
            value: 'createdAt',
          },
          {
            label: intl.formatMessage({
              id: 'general.name',
              defaultMessage: 'Name',
            }),
            value: 'label',
          },
        ]}
      />
    </Wrapper>
  );
};

Materials.propTypes = {};

export default Materials;
