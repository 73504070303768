import React, { useContext, useEffect, useMemo } from 'react';
import useDesign from '@hooks/useDesign';
import { useDispatch, useSelector } from 'react-redux';
import { getClippingTool } from '@selectors/toolBarSelector';
import { isFeatureEnabled } from '@selectors/configSelectors';
import {
  setClipToolMode,
  setClipToolAvailability,
} from '@actions/toolBarActions';
import { VisualizationContext } from '@contexts/VisualizationContext';
import ClippingPlanes from '@containers/Visualization/ClippingPlanes';
import FilterPolylines from '@containers/Visualization/FilterPolylines';
import { FileTypes } from '@constants/fileTypes';
import { CLIPPING_TOOL_FEATURE } from '@constants/features';

export default function Clipping() {
  const dispatch = useDispatch();
  const { objects } = useContext(VisualizationContext);
  const clippingTool = useSelector(getClippingTool);
  const { design } = useDesign();
  const clippingFeatureAvailable = useSelector(
    isFeatureEnabled(CLIPPING_TOOL_FEATURE),
  );

  const objectsList = useMemo(() => Object.values(objects), [objects]);

  const designObject = useMemo(
    () =>
      objectsList?.find(({ userData }) => userData?.designId === design?.id),
    [objectsList, design],
  );

  const designObjectRendered = useMemo(() => !!designObject, [designObject]);

  const isPolylineTypeObject = useMemo(
    () => !!designObject?.userData?.isPolylineClippable,
    [designObject?.userData?.isPolylineClippable],
  );

  const isAibDesign = design?.filetype.toLowerCase() === FileTypes.aib;
  const isGltfDesign = design?.filetype.toLowerCase() === FileTypes.gltf;
  const isPlaneClippingMode = clippingTool?.clipMode === 'axis';
  const isPolylineFilterMode = clippingTool?.clipMode === 'layers';

  const isObjectClippableWithPlanes = useMemo(
    () => !isAibDesign && !isGltfDesign && !isPolylineTypeObject,
    [isAibDesign, isGltfDesign, isPolylineTypeObject],
  );

  const isObjectClippableByFilter = useMemo(
    () => isAibDesign && isPolylineTypeObject,
    [isAibDesign, isPolylineTypeObject],
  );

  const isObjectClippable = useMemo(
    () => isObjectClippableWithPlanes || isObjectClippableByFilter,
    [isObjectClippableWithPlanes, isObjectClippableByFilter],
  );

  useEffect(() => {
    if (isObjectClippableByFilter) {
      dispatch(setClipToolMode('layers', 'layer'));
    } else if (isObjectClippableWithPlanes) {
      dispatch(setClipToolMode('axis', 'z'));
    }
  }, [dispatch, isObjectClippableWithPlanes, isObjectClippableByFilter]);

  useEffect(() => {
    if (clippingTool?.clipAvailable !== isObjectClippable) {
      dispatch(setClipToolAvailability(isObjectClippable));
    }
  }, [dispatch, clippingTool?.clipAvailable, isObjectClippable]);

  if (!clippingFeatureAvailable || !designObjectRendered) return null;

  return (
    <>
      {isPlaneClippingMode && <ClippingPlanes />}
      {isPolylineFilterMode && <FilterPolylines />}
    </>
  );
}
