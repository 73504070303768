import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@components/1-atoms/IconButton';
import VerticalDivider from '@components/1-atoms/VerticalDivider';
import IconButtonDropDown from '@components/1-atoms/IconButtonDropDown';
import { Wrapper } from './SceneActionBar.styled';

const SceneActionBar = ({
  dataTestId = 'scene-action-bar',
  iconButtons = [],
  iconButtonDropDown,
}) => {
  return (
    <Wrapper dataTestId={dataTestId}>
      {iconButtons.map((iconButton) => (
        <React.Fragment key={`scene-action-bar-icon-button-${iconButton.id}`}>
          {iconButton.withDivider && <VerticalDivider middleInset />}

          <IconButton
            dataTestId={`${dataTestId}-icon-button-${iconButton.id}`}
            {...iconButton}
          />
        </React.Fragment>
      ))}

      {iconButtonDropDown && (
        <IconButtonDropDown
          dataTestId={`${dataTestId}-button-drop-down`}
          {...iconButtonDropDown}
          endingIconName="arrow_drop_down_1"
        />
      )}
    </Wrapper>
  );
};

SceneActionBar.propTypes = {
  dataTestId: PropTypes.string,
  iconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      iconColor: PropTypes.string,
      iconName: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      onClick: PropTypes.func,
      ref: PropTypes.object,
      selected: PropTypes.bool,
      title: PropTypes.string,
      withDivider: PropTypes.bool,
    }),
  ),
  iconButtonDropDown: PropTypes.shape(IconButtonDropDown.propTypes),
};

export default SceneActionBar;
