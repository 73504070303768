import * as THREE from 'three';
import { Printer } from '../Printer';
import { printerConstants as constants } from '../../constants/printers/printerConstants';
import PrinterComponent from '../PrinterComponent';
import { s3Directories } from '../../constants/printers/s3Directories';
import { DEFAULT_EXTRUDER } from '../../constants/machineConstants';
import { getDefaultToolCalibrations } from './ExtruderUtils';

/**
 * Represents an individual extruder object. An extruder cannot have moving
 * axes, there is only a single group containing all meshes required for
 * the extruder visualization
 */
class Extruder extends PrinterComponent {
  constructor(
    printerSettings,
    machineDefaults,
    extruderDefinitions,
    machineBrand,
  ) {
    super(printerSettings, machineDefaults, constants.EXTRUDER);

    this.extruderType = this.getExtruderType(printerSettings, machineDefaults);
    if (!this.extruderType && this.extruderType === '') {
      // eslint-disable-next-line no-console
      console.error(
        `Printer settings are not containing a valid extruder type definition`,
      );
      return;
    }
    this.extruderDefaults = extruderDefinitions.find(
      (item) => item.displayName == this.extruderType,
    );
    this.extruderSettingsType = this.extruderDefaults
      ? this.extruderDefaults[constants.extruderSettingsType]
      : 'Default';
    this.calibration = this.setToolCalibration(
      printerSettings,
      extruderDefinitions,
      machineBrand,
    );
  }

  /**
   * Asynchronously loads the models required for visualization of the extruder
   * and adds them to the instance group.
   * @returns resolved promise on completion of loading
   */
  initializeModels() {
    return new Promise((resolve) => {
      if (this.extruderType === '' || !this.extruderType)
        resolve(new THREE.Group());
      else {
        let url;
        if (this.extruderType === constants.CUSTOM.value) {
          const extruderDefinition = Printer.getPrinterSettingValue(
            this.printerSettings,
            'extruderDefinitionResponse',
          );
          url = extruderDefinition?.modelUrls[s3Directories.extruderModel];
        } else {
          const { fileKey } = this.extruderDefaults;
          url = `/models/Extruder_${fileKey}.glb`;
        }
        if (url != null) {
          const model = Printer.getModel(url);
          model.then((model) => {
            model.children.forEach((child) => {
              this.add(child.clone());
            });
            resolve();
          });
        }
        resolve();
      }
    });
  }

  /**
   * Returns the extruder type belonging to this printer, based on the printer
   * settings belonging to this printer. If the printer settings object is empty
   * or does not contain information about the extruder type, then the default
   * extruder type for this machine is returned.
   * @param {*} printerSettings object containing all printer settings for this printer
   * @param {*} machineDefaults
   * @returns type of extruder for this printer
   */
  getExtruderType(printerSettings, machineDefaults) {
    const defaultExtruder =
      machineDefaults?.defaultExtruderName || DEFAULT_EXTRUDER;
    const selectedExtruder = printerSettings.find(
      (x) => x.settingName === constants.extruderType,
    ) || { value: defaultExtruder };
    return selectedExtruder.value;
  }

  /**
   * Stores the tool calibration values as properties of the extruder object.
   * If not values are supplied in the printer settings, defaults will be used
   * from the extruder definition.
   */
  setToolCalibration(printerSettings, extruderDefinitions, machineBrand) {
    const defaultToolCalibration = getDefaultToolCalibrations(
      extruderDefinitions,
      this.extruderType,
      machineBrand,
    );
    if (printerSettings.length === 0) return defaultToolCalibration;
    const calibration = [];
    for (let toolId = 0; toolId < defaultToolCalibration.length; toolId++) {
      if (printerSettings.length != 0) {
        if (toolId === 0) {
          //use printer props
          calibration.push({
            toolX: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolX,
            ),
            toolY: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolY,
            ),
            toolZ: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolZ,
            ),
            toolA: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolA,
            ),
            toolB: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolB,
            ),
            toolC: Printer.getPrinterSettingValue(
              printerSettings,
              constants.toolC,
            ),
          });
        } else if (toolId === 1) {
          //use secondary tool printer settings
          calibration.push({
            toolX: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.SECONDARY_TOOL_X,
              ),
            ),
            toolY: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.SECONDARY_TOOL_Y,
              ),
            ),
            toolZ: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.SECONDARY_TOOL_Z,
              ),
            ),
            toolA: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.SECONDARY_TOOL_A,
                0,
              ),
            ),
            toolB: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.SECONDARY_TOOL_B,
                0,
              ),
            ),
            toolC: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.SECONDARY_TOOL_C,
                0,
              ),
            ),
          });
        } else if (toolId === 2) {
          //currently unsupported, need to implement a tool database
          calibration.push({
            toolX: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.TERTIARY_TOOL_X,
              ),
            ),
            toolY: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.TERTIARY_TOOL_Y,
              ),
            ),
            toolZ: parseFloat(
              Printer.getSettingValueFromType(
                printerSettings,
                constants.TERTIARY_TOOL_Z,
              ),
            ),
            toolA: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.TERTIARY_TOOL_A,
                0,
              ),
            ),
            toolB: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.TERTIARY_TOOL_B,
                0,
              ),
            ),
            toolC: parseFloat(
              Printer.getSettingValueFromTypeOrDefault(
                printerSettings,
                constants.TERTIARY_TOOL_C,
                0,
              ),
            ),
          });
        }
      } else {
        //new printer, use defaults
        return defaultToolCalibration;
      }
    }
    return calibration;
  }
}

export default Extruder;
