import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';
import InlineSVG from 'react-inlinesvg';

export const PageWrapper = styled.div`
  height: 100%;
  ${flexColumn};

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing?.level6};
  `}
`;

export const Wrapper = styled.div`
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  ${({ theme: { dimensions } }) => css`
    width: min(${dimensions.pageContentWidth}, 100%);
  `}
`;

export const HeatZonesDiagram = styled(InlineSVG).attrs((props) => ({
  src: `/img/heatZones/diagram_materials_page_heat_zones_${props.$materialType}.svg`,
  'data-testid': 'svg',
  uniqueHash: 'a1f8d1',
}))`
  max-height: 100%;
  transition: all 0.3s ease;
`;

export const Frame = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { colors, borderRadius, spacing } }) => css`
    border-radius: ${borderRadius?.large};
    background-color: ${colors?.surfaceContainer};
    padding: ${spacing?.level4};
  `}
`;
