export const getCompletedStages = ({ quickStart } = {}) =>
  quickStart?.completedStages || [];

export const getCurrentStage = ({ quickStart } = {}) =>
  quickStart?.currentStage;

export const getStageData =
  (stageName = '') =>
  ({ quickStart } = {}) =>
    (quickStart?.completedStages?.find(({ name }) => name === stageName) || {})
      ?.data || {};

export const getMultiStageData =
  (stageName = '') =>
  ({ quickStart } = {}) =>
    (
      quickStart?.completedStages?.filter(({ name }) => name === stageName) ||
      []
    )
      ?.map(({ data }) => data)
      ?.flat();

export const getQuickStartCompleted = ({ quickStart } = {}) =>
  quickStart?.completed;

export const getQuickStartError = ({ quickStart } = {}) => quickStart?.error;

export const getQuickStartErrorMessage = ({ quickStart } = {}) =>
  quickStart?.errorMessage;

export const getQuickStartRedirectingToWorkflow = ({ quickStart } = {}) =>
  quickStart?.redirectingToWorkflow;
