import styled, { css } from 'styled-components';

export const TextRow = styled.div`
  width: 100%;
  text-align: left;
  color: ${(props) => props.color || '#616161'};
  font-size: ${(props) => props.fontSize || '16px'};
  margin-top: 10px;

  ${({ theme: { spacing } }) => css`
    padding: 0 ${spacing.level5} ${spacing.level3} ${spacing.level5};
  `}
`;
