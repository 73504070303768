import {
  CHIP_VARIANT_PRIMARY,
  CHIP_VARIANT_STANDARD,
} from '@components/1-atoms/Chip/Chip';
import React, { useCallback, useMemo } from 'react';
import { MODAL_IDS } from '@constants/modalDataTypes';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useModal from '@hooks/useModal';
import useProjectFiles from '@hooks/files/useProjectFiles';
import useWorkflow from '@hooks/workflows/useWorkflow';
import SettingAction from '@components/2-molecules/SettingAction';
import { fileTypesByInputType } from '@utils/model';

const MODAL_ID = MODAL_IDS.FILE_BROWSER;
const SettingsFileButton = ({
  fieldInput,
  onChange,
  dataTestId = 'settings-file-button',
  value: fieldValue,
  disabled,
  error,
}) => {
  const { showModal, hideModal } = useModal();
  const intl = useIntl();
  const { getSelectedWorkflow } = useWorkflow();
  const workflow = getSelectedWorkflow();
  const projectId = workflow?.workspaceId;

  const placeholder = intl.formatMessage({
    id: 'editorpanel.selector.select_file',
    defaultMessage: 'Select file',
  });
  const fieldName = fieldInput?.name || '';
  const fieldType = fieldInput?.type || '';

  const allowedFileTypes = useMemo(
    () => fileTypesByInputType?.[fieldType] || [],
    [fieldType],
  );

  const selectFile = useCallback(
    (targetFile) => {
      onChange({ target: { value: targetFile?.id } });
      hideModal(MODAL_ID);
    },
    [hideModal, onChange],
  );
  const { fileListItems } = useProjectFiles({
    projectId,
  });

  const selectedFile = useMemo(() => {
    const currentFieldValue = fieldValue;
    return fileListItems.find((file) => file.id === currentFieldValue);
  }, [fieldValue, fileListItems]);

  const showFileBrowser = useCallback(() => {
    showModal(MODAL_ID, {
      projectId,
      selectFile,
      allowedFileTypes,
    });
  }, [showModal, projectId, selectFile, allowedFileTypes]);

  return (
    <SettingAction
      dataTestId={dataTestId}
      label={fieldName}
      chip={{
        onClick: showFileBrowser,
        error,
        label: selectedFile?.label ?? placeholder,
        disabled,
        endingIconName: 'arrow_drop_down_1',
        variant: selectedFile ? CHIP_VARIANT_PRIMARY : CHIP_VARIANT_STANDARD,
      }}
    />
  );
};

SettingsFileButton.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fieldInput: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SettingsFileButton;
