export const defaultQuaternionSettings = [
  {
    allowedValues: null,
    category: 'BASE_QUATERNION',
    children: [],
    description: 'TODO',
    displayName: 'Quaternion (w)',
    parentValueRestrictions: null,
    settingName: 'qReal',
    value: 0,
    valueType: 'DECIMAL',
  },
  {
    allowedValues: null,
    category: 'BASE_QUATERNION',
    children: [],
    description: 'TODO',
    displayName: 'Quaternion (x)',
    parentValueRestrictions: null,
    settingName: 'qX',
    value: 0,
    valueType: 'DECIMAL',
  },
  {
    allowedValues: null,
    category: 'BASE_QUATERNION',
    children: [],
    description: 'TODO',
    displayName: 'Quaternion (y)',
    parentValueRestrictions: null,
    settingName: 'qY',
    value: 0,
    valueType: 'DECIMAL',
  },
  {
    allowedValues: null,
    category: 'BASE_QUATERNION',
    children: [],
    description: 'TODO',
    displayName: 'Quaternion (z)',
    parentValueRestrictions: null,
    settingName: 'qZ',
    value: 0,
    valueType: 'DECIMAL',
  },
];
