import React, { useEffect, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useDialog from '@hooks/useDialog';
import Dialog from '@components/2-molecules/Dialog';
import { DialogPortalWrapper } from './DialogPortal.styled';

export const DIALOGS_COINTAINER_ID = 'dialogs-container';

const DialogPortal = ({
  autoShow,
  children,
  dialogData: dialogDataProp = {},
  dialogId,
  errorType,
  onClose,
  ...dialogComponentProps
}) => {
  const { showDialog, hideDialog, isDialogShown, getDialogData } = useDialog();

  const dialogsPortalContainerElement = document.getElementById(
    DIALOGS_COINTAINER_ID,
  );

  const dialogIsShown = useMemo(
    () => isDialogShown(dialogId),
    [dialogId, isDialogShown],
  );

  const dialogData = useMemo(
    () => getDialogData(dialogId),
    [dialogId, getDialogData],
  );

  const handleClose = useCallback(async () => {
    await hideDialog(dialogId);

    onClose?.();
  }, [dialogId, hideDialog, onClose]);

  useEffect(() => {
    if (!autoShow || !dialogId || !!dialogIsShown) return;

    showDialog(dialogId, dialogDataProp);
  }, [autoShow, dialogIsShown, dialogDataProp, showDialog, dialogId]);

  if (!dialogsPortalContainerElement || !dialogIsShown) return null;

  return createPortal(
    <DialogPortalWrapper id={dialogId} zIndex={errorType ? 4 : undefined}>
      <Dialog
        onCloseIconButtonClick={handleClose}
        onPrimaryButtonClick={handleClose}
        onSecondaryButtonClick={handleClose}
        primaryButtonLabel="OK"
        onClose={handleClose}
        {...(dialogData || {})}
        {...dialogComponentProps}
      >
        {children}
      </Dialog>
    </DialogPortalWrapper>,
    dialogsPortalContainerElement,
  );
};

DialogPortal.propTypes = {
  autoShow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dialogData: PropTypes.object,
  dialogId: PropTypes.string.isRequired,
  errorType: PropTypes.bool,
  onClose: PropTypes.func,
  ...Dialog.propTypes,
};

export default DialogPortal;
