import React from 'react';
import YouTube from 'react-youtube';
import useDialog from '@hooks/useDialog';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { PlayerWrapper } from './YoutubeVideoDialog.styled';

const MODAL_ID = ModalDataTypes.YOUTUBE_VIDEO;

const YoutubeVideoDialog = () => {
  const { getDialogData } = useDialog();

  const dialogData = getDialogData(MODAL_ID);

  const { title, subtitle, videoId, autoPlay = true } = dialogData;

  return (
    <DialogPortal
      dataTestId="youtube-video-dialog"
      dialogId={MODAL_ID}
      primaryButtonLabel={null}
    >
      {(title || subtitle) && (
        <PageHeader
          variant={PAGE_HEADER_VARIANT_LARGE}
          title={title}
          subtitle={subtitle}
        />
      )}

      <PlayerWrapper>
        <YouTube
          videoId={videoId}
          opts={{
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: autoPlay ? 1 : 0,
              rel: 0,
            },
          }}
        />
      </PlayerWrapper>
    </DialogPortal>
  );
};

YoutubeVideoDialog.propTypes = {};

export default YoutubeVideoDialog;
