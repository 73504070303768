import * as THREE from 'three';
import { Printer } from '../../Printer';
import { machineConstants } from '../../../constants/printers/machineConstants';
import Gantry from './Gantry';
import { s3Directories } from '../../../constants/printers/s3Directories';
import GantryGraph from './GantryGraph';
import { getMachineDefinitionResponse } from '../MachineUtils';

/**
 * Represents a gantry object, which is composed of a tree of THREE.js components that are
 * necessary for visualisation of the movement of the machine.
 */
class GantryCustom extends Gantry {
  constructor(robotType, printerSettings, bed) {
    super(robotType, [], printerSettings, bed);
  }
  /**
   * Creates the part groups for each axes and the flange to mount the extruder
   */
  /**
   * Unlike for serial robotic 6R systems, no parent-child relationship exists
   * between the THREE.js groups. The relationship is "simulated" - this is because
   * gantry systems can be composed of 2DOF+ joints which cannot be constructed
   * with single parent-child links.
   * @return {THREE.Group}
   */
  createJointGroups() {
    const machineDefinitionResponse = getMachineDefinitionResponse(
      this.printerSettings,
    );
    this.axis0 = new THREE.Group();
    this.axis1 = new THREE.Group();
    this.axis2 = new THREE.Group();
    this.axis3 = new THREE.Group();
    this.gantryGraph = new GantryGraph(machineDefinitionResponse);
    this.add(this.axis0);
    this.add(this.axis1);
    this.add(this.axis2);
    this.add(this.axis3);
    this.add(this.flange);
  }

  /**
   * Converts an XYZ vector defined relative to the bed's coordinate system
   * to an XYZ vector defined relative to the gantry's coordinate system.
   *
   * Assumes the gantry coordinate system is identical to the global coordinate
   * system.
   * @param {THREE.Vector3} baseVector
   */
  convertBedToWorldCoordinateSystem(baseVector) {
    const matrix = new THREE.Matrix4().copy(this.bed.baseTransformationMatrix);
    return baseVector.applyMatrix4(matrix);
  }

  convertBedToGantryCoordinateSystem(baseVector) {
    const gantryMatrix = new THREE.Matrix4().makeBasis(
      this.gantryGraph.componentAdjacencyList.get('AXIS_1').direction,
      this.gantryGraph.componentAdjacencyList.get('AXIS_2').direction,
      this.gantryGraph.componentAdjacencyList.get('AXIS_3').direction,
    );

    const baseCalibration = new THREE.Matrix4().copy(
      this.bed.baseTransformationMatrix,
    );
    const invertedGantry = gantryMatrix.clone().invert();
    const apply = invertedGantry.multiply(baseCalibration);
    return baseVector.applyMatrix4(apply);
  }

  convertWorldToGantryCoordinateSystem(worldVector) {
    const gantryMatrix = new THREE.Matrix4().makeBasis(
      this.gantryGraph.componentAdjacencyList.get('AXIS_1').direction,
      this.gantryGraph.componentAdjacencyList.get('AXIS_2').direction,
      this.gantryGraph.componentAdjacencyList.get('AXIS_3').direction,
    );

    const invertedGantry = gantryMatrix.clone().invert();
    return worldVector.applyMatrix4(invertedGantry);
  }

  /**
   * Moves the gantry axes to their home positions, as defined in the robot
   * definitions page.
   */
  moveHomePosition() {
    const axisPositions = this.convertBedToGantryCoordinateSystem(
      new THREE.Vector3(
        this.props.axis1Home,
        this.props.axis2Home,
        this.props.axis3Home,
      ),
    );
    this.moveAxes(
      {
        axis1: axisPositions.x,
        axis2: axisPositions.y,
        axis3: axisPositions.z,
      },
      0,
    );
  }

  /**
   * Move the gantry axes to the target positions.
   * @param {Object} target {
   *  axis1: number,
   *  axis2: number,
   *  axis3: number,
   * }
   * @param {*} toolId
   */
  moveAxes(target) {
    const { axis1, axis2, axis3 } = target;
    const calibration = new THREE.Vector3(
      this.tool.calibration[0].toolX,
      this.tool.calibration[0].toolY,
      this.tool.calibration[0].toolZ,
    );
    const calib = this.convertWorldToGantryCoordinateSystem(calibration);
    const axisVector = new THREE.Vector3(axis1, axis2, axis3);
    axisVector.sub(calib);

    const groupPositions = this.gantryGraph.updateGroupPositions([
      axisVector.x,
      axisVector.y,
      axisVector.z,
    ]);

    const axis1Vector = groupPositions.get('AXIS_1');
    const axis2Vector = groupPositions.get('AXIS_2');
    const axis3Vector = groupPositions.get('AXIS_3');
    const bedVector = groupPositions.get('BED');
    const extruderVector = groupPositions.get('EXTRUDER');
    this.axis1.position.set(axis1Vector.x, axis1Vector.y, axis1Vector.z);
    this.axis2.position.set(axis2Vector.x, axis2Vector.y, axis2Vector.z);
    this.axis3.position.set(axis3Vector.x, axis3Vector.y, axis3Vector.z);

    //BED cycle needs to apply negative - it moves in the inverse direction
    const bedVec = new THREE.Vector3()
      .setFromMatrixPosition(this.bed.baseTransformationMatrix)
      .add(bedVector);
    this.bed.position.set(bedVec.x, bedVec.y, bedVec.z);
    this.flange.position.set(
      extruderVector.x,
      extruderVector.y,
      extruderVector.z,
    );
  }

  /**
   * Moves the gantry axes based on the simulation data that has been provided.
   * The simulation data is always relative to the base, so vectors need to be
   * converted from the base coordinate system to the gantry coordinate system
   * so that the axes know which positions they need to move to.
   * @param {*} simulationData
   */
  simulate(simulationData) {
    const axis1 =
      simulationData.previousStep['jointAngle1'] +
      (simulationData.currentStep['jointAngle1'] -
        simulationData.previousStep['jointAngle1']) *
        simulationData.stepRatio;
    const axis2 =
      simulationData.previousStep['jointAngle2'] +
      (simulationData.currentStep['jointAngle2'] -
        simulationData.previousStep['jointAngle2']) *
        simulationData.stepRatio;
    const axis3 =
      simulationData.previousStep['jointAngle3'] +
      (simulationData.currentStep['jointAngle3'] -
        simulationData.previousStep['jointAngle3']) *
        simulationData.stepRatio;

    this.moveAxes({ axis1, axis2, axis3 }, simulationData.toolId);
  }

  /**
   * Loads the gantry gltf model, then iterates through the children to create property
   * references based on the name of the child, e.g. if the child name is Axis1, and there is
   * no this.Axis1 property, then a new THREE.Group() is created and this.Axis1 is assigned to this
   * group. The child, and all children with a name beginning with Axis1 are added to this group.
   */
  initializeMachineGeometry() {
    return new Promise((resolve) => {
      const machineDefinition = Printer.getPrinterSettingValue(
        this.printerSettings,
        machineConstants.machineDefinitionResponse,
      );
      const { modelUrls } = machineDefinition;
      const modelPromises = []; // Array to hold all the promises for the robot models

      for (let axisNumber = 0; axisNumber <= 3; axisNumber++) {
        const robotModelPromise = Printer.getModel(
          modelUrls[s3Directories.machineAxis + axisNumber],
        );
        modelPromises.push(robotModelPromise); // Add promise to the array
      }

      Promise.all(modelPromises).then((loadedGantryModels) => {
        for (let axisNumber = 0; axisNumber <= 3; axisNumber++) {
          const model = loadedGantryModels[axisNumber];
          this.addCustomAxis(model, axisNumber);
        }
        resolve();
      });
    });
  }

  addCustomAxis(model, axisNumber) {
    const axisKey = 'axis' + axisNumber;
    this[axisKey].add(model.clone());
  }
}

export default GantryCustom;
