import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { whenMatchPattern } from '@utils/interactionEvents';
import { integerInputPattern } from '@utils/validation';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import Field from '@components/1-atoms/Field';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Wrapper } from './Login2FACode.styled';

export const MFA_CODE_LENGTH = 6;

const Login2FACode = ({
  className,
  dataTestId = 'login-page-2fa-code',
  handleFieldBlur,
  handleFieldChange,
  handleGoBack,
  isSubmitting,
  mfaCode = '',
  mfaCodeError,
  mfaCodeErrorMessage,
  mfaCodeLength = MFA_CODE_LENGTH,
  mfaCodeTouched,
}) => {
  const intl = useIntl();
  const showMfaCodeErrorMessage = mfaCodeTouched && mfaCodeError;

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.2fa.title',
          defaultMessage: 'Enter verification code',
        })}
        subtitle={intl.formatMessage({
          id: 'loginpage.2fa.subtitle',
          defaultMessage:
            'To continue please enter the six-digit code from your authenticator app.',
        })}
      />

      <Field
        dataTestId={`${dataTestId}__mfa-code-field`}
        name="mfaCode"
        maxLength={mfaCodeLength}
        placeholder={intl.formatMessage({
          id: 'loginpage.2fa.verification_code',
          defaultMessage: 'Verification code',
        })}
        value={mfaCode}
        onChange={whenMatchPattern(integerInputPattern, handleFieldChange)}
        onBlur={handleFieldBlur}
        error={showMfaCodeErrorMessage}
        supportingText={showMfaCodeErrorMessage && mfaCodeErrorMessage}
      />

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        type="submit"
        loading={isSubmitting}
      >
        <FormattedMessage id="general.login" defaultMessage="Log in" />
      </Button>

      <Button
        dataTestId={`${dataTestId}__go-back-button`}
        disabled={isSubmitting}
        variant={BUTTON_VARIANT_TEXT}
        onClick={handleGoBack}
      >
        <FormattedMessage id="loginpage.2fa.go_back" defaultMessage="Go back" />
      </Button>
    </Wrapper>
  );
};

Login2FACode.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  handleFieldBlur: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  mfaCode: PropTypes.string,
  mfaCodeError: PropTypes.bool,
  mfaCodeErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mfaCodeLength: PropTypes.number,
  mfaCodeTouched: PropTypes.bool,
};

export default Login2FACode;
