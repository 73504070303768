import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isUndefined } from 'lodash';
import { compare } from 'compare-versions';
import usePrevious from '@hooks/usePrevious';
import useFile from '@hooks/files/useFile';
import useFileQueries from '@hooks/files/useFileQueries';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperator from '@hooks/operators/useOperator';
import {
  getSelectedOperatorOutput,
  getFrozenOutputs,
} from '@selectors/conceptSelectors';
import { unfreezeOperatorOutput } from '@actions/conceptActions';

export default function useSelectedOutputDesigns() {
  const dispatch = useDispatch();
  const selectedOperatorOutput = useSelector(getSelectedOperatorOutput());
  const frozenOutputs = useSelector(getFrozenOutputs());
  const { getSelectedWorkflow, getWorkflowOperators, getIsWorkflowComputing } =
    useWorkflow();
  const { unselectSelectedOperator } = useOperator();
  const {
    removeNotExistingGeometriesQuery,
    getSelectedDesignIds,
    getCachedGeometries,
  } = useFile();

  const workflow = getSelectedWorkflow();
  const workflowVersion = workflow.version;
  const previousWorkflowVersion = usePrevious(workflowVersion);
  const isWorkflowComputing = getIsWorkflowComputing();
  const previousIsWorkflowComputing = usePrevious(isWorkflowComputing);
  const workflowOperators = getWorkflowOperators(workflow);

  const selectedDesigns = getSelectedDesignIds(workflowOperators);
  const selectedDesignIds = selectedDesigns.designIds;
  const cachedGeometries = getCachedGeometries(
    selectedDesignIds,
    workflow?.printerId,
  );
  const geometryIdToFetch = selectedDesignIds.find(
    (designId) => !cachedGeometries?.[designId],
  );

  const notExistingSelectedOutputIds = useMemo(() => {
    const workflowHasBeenUpgraded =
      !isUndefined(previousWorkflowVersion) &&
      compare(workflowVersion, previousWorkflowVersion, '>');
    const skip =
      !workflowHasBeenUpgraded &&
      !(previousIsWorkflowComputing && !isWorkflowComputing);

    if (skip) return [];

    const outputIdsToUnselect = frozenOutputs.reduce((acc, output) => {
      const isOperatorComputed = workflowOperators.find(
        (operator) => operator.id === output.operatorId,
      )?.computed;

      if (isOperatorComputed) {
        return acc;
      }

      return [...acc, output.id];
    }, []);

    if (selectedOperatorOutput) {
      const selectedOperator = workflowOperators.find(
        (operator) => operator.id === selectedOperatorOutput.operatorId,
      );

      if (!selectedOperator?.computed) {
        outputIdsToUnselect.push(selectedOperatorOutput?.id);
      }
    }

    return outputIdsToUnselect;
  }, [
    isWorkflowComputing,
    previousIsWorkflowComputing,
    workflowOperators,
    frozenOutputs,
    selectedOperatorOutput,
    previousWorkflowVersion,
    workflowVersion,
  ]);

  useFileQueries({
    geometryId: geometryIdToFetch,
    printerId: workflow?.printerId,
  });

  useEffect(() => {
    removeNotExistingGeometriesQuery(workflowOperators);
  }, [workflowOperators, removeNotExistingGeometriesQuery]);

  useEffect(() => {
    const skip = isEmpty(notExistingSelectedOutputIds);

    if (skip) {
      return;
    }

    const shouldUnselectOperator = notExistingSelectedOutputIds?.includes(
      selectedOperatorOutput?.id,
    );

    dispatch(unfreezeOperatorOutput(notExistingSelectedOutputIds));

    if (shouldUnselectOperator) {
      unselectSelectedOperator();
    }
  }, [
    dispatch,
    notExistingSelectedOutputIds,
    unselectSelectedOperator,
    selectedOperatorOutput?.id,
  ]);
}
