import * as actionTypes from '@constants/actionTypes';

const initialState = {
  templates: [],
  createTemplate: {
    currentStage: {},
    completedStages: [],
    completed: false,
    error: false,
  },
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: action.payload.templates,
      };
    }

    case actionTypes.CREATE_TEMPLATE_REQUEST: {
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          completed: initialState.completed,
          error: initialState.error,
        },
      };
    }

    case actionTypes.CREATE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          completed: true,
        },
      };
    }

    case actionTypes.CREATE_TEMPLATE_FAILURE: {
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          error: true,
        },
      };
    }

    case actionTypes.CREATE_TEMPLATE_SET_CURRENT_STAGE: {
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          currentStage: action.payload?.stage,
        },
      };
    }

    case actionTypes.SET_CREATE_TEMPLATE_COMPLETED_STAGE: {
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          completedStages: [
            ...state.createTemplate.completedStages,
            action.payload?.stage,
          ],
        },
      };
    }

    case actionTypes.RESET_CREATE_TEMPLATE: {
      return {
        ...state,
        createTemplate: initialState.createTemplate,
      };
    }

    default:
      return state;
  }
};

export default templateReducer;
