import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const printerQueryKeys = {
  printers: ['printers'],
  printer: (printerId) => ['showLoader', 'printer', printerId],
  printerInUseWorkflows: (printerId) => [
    'showLoader',
    'printerInUseWorkflows',
    printerId,
  ],
  nozzleInUseWorkflows: (nozzleId) => [
    'showLoader',
    'nozzleInUseWorkflows',
    nozzleId,
  ],
  allNozzles: ['allNozzles'],
  nozzles: (nozzlePrinterId) => ['nozzleLibrary', nozzlePrinterId],
  materials: ['materials'],
  machineDefinitions: ['showLoader', 'machineDefinitions'],
  extruderDefinitions: ['showLoader', 'extruderDefinitions'],
  printingBedDefinitions: ['showLoader', 'printingBedDefinitions'],
  programTypeDefinitions: ['showLoader', 'programTypeDefinitions'],
  plinthDefinitions: ['showLoader', 'plinthDefinitions'],
  enclosureDefinitionsQuery: ['showLoader', 'enclosureDefinitionsQuery'],
  allPrinterDefinitions: [
    'showLoader',
    'machineDefinitions',
    'extruderDefinitions',
    'printingBedDefinitions',
    'programTypeDefinitions',
    'plinthDefinitions',
    'enclosureDefinitions',
  ],
};

export default function usePrinterQueries({
  nozzlePrinterId,
  printerId,
  nozzleId,
} = {}) {
  const getPrintersQueryOptions = useCallback((showLoader) => {
    let queryKey = printerQueryKeys.printers;

    if (showLoader) {
      queryKey = ['showLoader', ...queryKey];
    }

    return {
      queryKey,
      queryFn: () => client.get(endpoints.printers).then((res) => res.data),
      initialData: [],
      enabled: false,
    };
  }, []);

  const printersQuery = useQuery(getPrintersQueryOptions());

  const printerQuery = useQuery({
    queryKey: printerQueryKeys.printer(printerId),
    queryFn: () =>
      client
        .get(endpoints.printer.replace(':printerId', printerId))
        .then((res) => res.data),
    enabled: !!printerId,
  });

  const printerInUseWorkflows = useQuery({
    queryKey: printerQueryKeys.printerInUseWorkflows(printerId),
    queryFn: () =>
      client
        .get(endpoints.printerWorkflows.replace(':printerId', printerId))
        .then((res) => res.data),
    enabled: false,
  });

  const nozzleInUseWorkflows = useQuery({
    queryKey: printerQueryKeys.nozzleInUseWorkflows(nozzleId),
    queryFn: () =>
      client
        .get(endpoints.printerNozzleWorkflows.replace(':nozzleId', nozzleId))
        .then((res) => res.data),
    enabled: false,
  });

  const allNozzlesQuery = useQuery({
    queryKey: printerQueryKeys.allNozzles,
    queryFn: () =>
      client.get(endpoints.printersNozzles).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  const nozzlesQuery = useQuery({
    queryKey: printerQueryKeys.nozzles(nozzlePrinterId),
    queryFn: () =>
      client
        .get(endpoints.printerNozzles.replace(':printerId', nozzlePrinterId))
        .then((res) => res.data),
    initialData: [],
    enabled: !!nozzlePrinterId,
  });

  const materialsQuery = useQuery({
    queryKey: printerQueryKeys.materials,
    queryFn: () => client.get(endpoints.materials).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  const machineDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.machineDefinitions,
    queryFn: () =>
      client.get(endpoints.machineDefinitions).then((res) => res.data),
    enabled: false,
  });

  const extruderDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.extruderDefinitions,
    queryFn: () =>
      client.get(endpoints.extruderDefinitions).then((res) => res.data),
    enabled: false,
  });

  const printingBedDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.printingBedDefinitions,
    queryFn: () =>
      client.get(endpoints.printingBedDefinitions).then((res) => res.data),
    enabled: false,
  });

  const programTypeDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.programTypeDefinitions,
    queryFn: () =>
      client.get(endpoints.programTypeDefinitions).then((res) => res.data),
    enabled: false,
  });

  const plinthDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.plinthDefinitions,
    queryFn: () =>
      client.get(endpoints.plinthDefinitions).then((res) => res.data),
    enabled: false,
  });

  const enclosureDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.enclosureDefinitions,
    queryFn: () =>
      client.get(endpoints.enclosureDefinitions).then((res) => res.data),
    enabled: false,
  });

  const allPrinterDefinitionsQuery = useQuery({
    queryKey: printerQueryKeys.allPrinterDefinitions,
    queryFn: async () => {
      const [
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        programTypeDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
      ] = await Promise.all([
        client.get(endpoints.machineDefinitions).then((res) => res.data),
        client.get(endpoints.extruderDefinitions).then((res) => res.data),
        client.get(endpoints.printingBedDefinitions).then((res) => res.data),
        client.get(endpoints.programTypeDefinitions).then((res) => res.data),
        client.get(endpoints.plinthDefinitions).then((res) => res.data),
        client.get(endpoints.enclosureDefinitions).then((res) => res.data),
      ]);

      return {
        machineDefinitions,
        extruderDefinitions,
        printingBedDefinitions,
        programTypeDefinitions,
        plinthDefinitions,
        enclosureDefinitions,
      };
    },
    initialData: {
      machineDefinitions: [],
      extruderDefinitions: [],
      printingBedDefinitions: [],
      programTypeDefinitions: [],
      plinthDefinitions: [],
      enclosureDefinitions: [],
    },
    enabled: false,
  });

  return {
    getPrintersQueryOptions,
    printersQuery,
    printerQuery,
    allNozzlesQuery,
    nozzlesQuery,
    printerInUseWorkflows,
    nozzleInUseWorkflows,
    materialsQuery,
    machineDefinitionsQuery,
    extruderDefinitionsQuery,
    printingBedDefinitionsQuery,
    programTypeDefinitionsQuery,
    plinthDefinitionsQuery,
    enclosureDefinitionsQuery,
    allPrinterDefinitionsQuery,
  };
}
