import styled, { css } from 'styled-components';
import Text, {
  TEXT_VARIANT_BODY_LARGE,
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { flexColumn, when } from '@stylesheets/helpers';

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ theme: { colors, spacing } }) => css`
    fill: ${colors.onSurface};
    margin-top: ${spacing.level2};
  `}
`;

export const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level5};
    padding: ${spacing.level3} ${spacing.level4} ${spacing.level3} 0;
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1 0 0;
  word-break: break-word;
  justify-content: center;

  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.level2};
  `}
`;

export const Label = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_LARGE,
  color: 'onSurface',
})``;

export const Description = styled(Text).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'onSurfaceVariant',
})`
  opacity: 0.76;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  position: relative;
  user-select: none;

  ${({
    disabled,
    noPadding,
    withDivider,
    withEndingIconButton,
    theme: { spacing, colors },
  }) => css`
    gap: ${spacing.level5};

    ${when(
      !noPadding,
      `
        min-height: 56px;
        padding: ${spacing.level3} 0;
      `,
    )}

    ${when(
      withDivider,
      `
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: ${colors.outlineVariant};
          position: absolute;
          bottom: 0;
          left: 0;
        }
      `,
    )}

    ${when(
      withEndingIconButton,
      `
        padding-right: ${spacing.level2};
      `,
    )}

    ${when(
      disabled,
      `
        opacity: 0.38;
        pointer-events: none;
      `,
    )}
  `}
`;
