import styled, { css } from 'styled-components';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexCenterVertical}
  padding: 0px 10px;

  ${({ theme: { colors } }) => css`
    color: ${colors.onSurface};
  `}
`;

export const ActionWrapper = styled.div`
  overflow: hidden;
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level2};
  `}
`;
