import {
  FETCH_ALL_PRINTERS,
  FETCH_ALL_PRINTERS_SUCCEEDED,
  FETCH_ALL_PRINTERS_FAILED,
  SELECT_PRINTER,
  UPDATE_PRINTER,
  UPDATE_PRINTER_SUCCEEDED,
  UPDATE_PRINTER_FAILED,
  DELETE_PRINTER,
  DELETE_PRINTER_SUCCEEDED,
  DELETE_PRINTER_FAILED,
  PRINTER_ROBOT_TYPE_MODIFIED,
  PRINTER_SETTING_MODIFIED,
  CANCEL_PRINT,
  CANCEL_PRINT_FAILED,
  CANCEL_PRINT_SUCCEEDED,
  SELECT_PRINTER_WIDGET,
} from '@constants/actionTypes';
import actionTypes from '@actions';
import { AuthenticatedFetch } from './AuthenticatedFetch.js';
import { DELETE, GET, PUT } from '@constants/fetchMethods';

export const fetchAllPrinters = () => (dispatch) =>
  dispatch(
    AuthenticatedFetch({
      url: 'printers',
      method: GET,
      prefetchAction: {
        type: FETCH_ALL_PRINTERS,
      },
      successAction: (responseBody) => ({
        type: FETCH_ALL_PRINTERS_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: FETCH_ALL_PRINTERS_FAILED,
      }),
    }),
  );

export const changeSelectedPrinter = (printer) => ({
  type: SELECT_PRINTER_WIDGET,
  payload: printer,
});

export const updatePrinter =
  (printerId, printer, successCallback) => (dispatch) =>
    dispatch(
      AuthenticatedFetch({
        url: `printers/${printerId}`,
        body: JSON.stringify(printer),
        method: PUT,
        prefetchAction: {
          type: UPDATE_PRINTER,
        },
        successCallback: successCallback,
        successAction: (responseBody) => ({
          type: UPDATE_PRINTER_SUCCEEDED,
          payload: responseBody,
        }),
        failureAction: () => ({
          type: UPDATE_PRINTER_FAILED,
        }),
      }),
    );

export const deletePrinter = (printerId, dismissPrinter) => (dispatch) =>
  dispatch(
    AuthenticatedFetch({
      url: `printers/${printerId}`,
      method: DELETE,
      prefetchAction: {
        type: DELETE_PRINTER,
      },
      successAction: () => {
        return {
          type: DELETE_PRINTER_SUCCEEDED,
          payload: {
            printerId: printerId,
            dismissPrinter: dismissPrinter,
          },
        };
      },
      failureAction: () => ({
        type: DELETE_PRINTER_FAILED,
      }),
    }),
  );

export const cancelPrint = (printerId, printId) => (dispatch) =>
  dispatch(
    AuthenticatedFetch({
      url: `printers/${printerId}/${printId}`,
      method: DELETE,
      prefetchAction: {
        type: CANCEL_PRINT,
      },
      successAction: (responseBody) => ({
        type: CANCEL_PRINT_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: CANCEL_PRINT_FAILED,
      }),
    }),
  );

export const selectPrinter = (id, machine) => ({
  type: SELECT_PRINTER,
  payload: { id, machine },
});

export const changePrinterSetting = (printerId, setting, value) => ({
  type: PRINTER_SETTING_MODIFIED,
  payload: {
    printerId: printerId,
    setting: setting,
    value: value,
  },
});

export const deletePrinterSetting = (printerId, setting) => ({
  type: actionTypes.PRINTER_SETTINGS_DELETED,
  payload: {
    printerId: printerId,
    setting: setting,
  },
});

export const changePrinterSettings = (printerId, printerSettings) => ({
  type: actionTypes.PRINTER_SETTINGS_MODIFIED,
  payload: {
    printerId: printerId,
    settings: printerSettings,
  },
});

export const changePrinterRobotType = (value) => ({
  type: PRINTER_ROBOT_TYPE_MODIFIED,
  payload: {
    robotType: value,
  },
});

export const fetchPrinterById = (printerId) => ({
  type: actionTypes.FETCH_PRINTER_REQUEST,
  payload: { printerId },
});

export const fetchPrinterByIdSuccess = (printer) => ({
  type: actionTypes.FETCH_PRINTER_SUCCESS,
  payload: printer,
});

export const fetchPrinterByIdFailure = (error) => ({
  type: actionTypes.FETCH_PRINTER_FAILURE,
  payload: { error },
});

export const updatePostProcessorConfig = (
  showDialog,
  printerId,
  postProcessorConfigs,
  successCallback,
) => ({
  type: actionTypes.UPDATE_POST_PROCESSOR_CONFIG_REQUEST,
  payload: { printerId, postProcessorConfigs, showDialog, successCallback },
});

export const updatePostProcessorConfigSuccess = () => ({
  type: actionTypes.UPDATE_POST_PROCESSOR_CONFIG_SUCCESS,
});

export const updatePostProcessorConfigFailure = (error) => ({
  type: actionTypes.UPDATE_POST_PROCESSOR_CONFIG_FAILURE,
  payload: { error },
});

export const updateVisibility = (componentType, visibilityMap, callback) => ({
  type: actionTypes.UPDATE_VISIBILITY_REQUEST,
  payload: { componentType, visibilityMap, callback },
});

export const updateVisibilitySuccess = () => ({
  type: actionTypes.UPDATE_VISIBILITY_SUCCESS,
});

export const updateVisibilityFailure = (error) => ({
  type: actionTypes.UPDATE_VISIBILITY_FAILURE,
  payload: { error },
});

export const fetchPostProcessorConfigsForPrinter = (printerId) => ({
  type: actionTypes.FETCH_POST_PROCESSOR_CONFIG_REQUEST,
  payload: { printerId },
});

export const fetchPostProcessorConfigsForPrinterSuccess = (
  postProcessorConfigs,
) => ({
  type: actionTypes.FETCH_POST_PROCESSOR_CONFIG_SUCCESS,
  payload: { postProcessorConfigs },
});

export const fetchPostProcessorConfigsForPrinterFailure = (error) => ({
  type: actionTypes.FETCH_POST_PROCESSOR_CONFIG_FAILURE,
  payload: { error },
});

export const showPostProcessorConfig = () => ({
  type: actionTypes.SHOW_POST_PROCESSOR_CONFIG,
});

export const hidePostProcessorConfig = () => ({
  type: actionTypes.HIDE_POST_PROCESSOR_CONFIG,
});

export const showNozzleConfig = (nozzle) => ({
  type: actionTypes.SHOW_NOZZLE_CONFIG,
  payload: { nozzle },
});

export const hideNozzleConfig = () => ({
  type: actionTypes.HIDE_NOZZLE_CONFIG,
});

export const showGeneralPrinterSetting = (setting, displayName) => ({
  type: actionTypes.SHOW_GENERAL_PRINTER_SETTING,
  payload: {
    setting: setting,
    displayName: displayName,
  },
});

export const hideGeneralPrinterSetting = () => ({
  type: actionTypes.HIDE_GENERAL_PRINTER_SETTING,
});

export const getPrints = (printerId, successCallbackParam) =>
  AuthenticatedFetch({
    url: `printers/${printerId}/prints`,
    method: GET,
    successCallback: (result) => successCallbackParam(result),
  });

export const changeMachineSetting = (printerId, setting, value) => ({
  type: actionTypes.MACHINE_SETTING_MODIFIED,
  payload: {
    printerId: printerId,
    setting: setting,
    value: value,
  },
});

export const changeMachineModel = (printerId, machineDefaults) => ({
  type: actionTypes.CHANGE_MACHINE_MODEL,
  payload: {
    printerId,
    machineDefaults,
  },
});

/**
 *
 * @param {*} printerId id of the printer to which the component definition belongs
 * @param {*} componentData json request object containing parameters
 * @param {*} fileDefinitions map of component key : 3d model file
 * @param {*} callback callback function
 * @returns
 */
export const uploadPrinterComponentModels = (
  printerId,
  componentData,
  fileDefinitions,
  callback,
) => ({
  type: actionTypes.UPLOAD_PRINTER_COMPONENT_REQUEST,
  payload: {
    printerId: printerId,
    componentData: componentData,
    fileDefinitions: fileDefinitions,
    callback: callback,
  },
});

export const uploadPrinterComponentSuccess = () => ({
  type: actionTypes.UPLOAD_PRINTER_COMPONENT_SUCCESS,
});

export const uploadPrinterComponentFailure = () => ({
  type: actionTypes.UPLOAD_PRINTER_COMPONENT_FAILURE,
});
