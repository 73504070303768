import React, { useCallback } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import { ROUTES } from '@constants/router';
import { Wrapper } from './Workflows.styled';
import WorkflowsPageContent from '@app/components/WorkflowsPageContent';

const Workflows = () => {
  const intl = useIntl();
  const history = useHistory();
  const { workspaceId: projectId } = useParams();
  const { getWorkflowsList } = useWorkflowList();
  const { projectQuery } = useProjectQueries({ projectId });
  const { projectWorkflowsQuery } = useWorkflowQueries({ projectId });

  const project = projectQuery.data;
  const workflows = projectWorkflowsQuery.data;

  const workflowListItems = getWorkflowsList(workflows);

  const handleGoBack = useCallback(() => {
    history.push(ROUTES.PROJECTS);
  }, [history]);

  const handleTabClick = useCallback(
    (tab) => {
      const isFileTab = tab?.title === 'Files';

      if (!isFileTab) return;

      history.push(
        generatePath(ROUTES.FILES, {
          workspaceId: projectId,
        }),
      );
    },
    [history, projectId],
  );

  return (
    <Wrapper data-testid="workflows-page">
      <WorkflowsPageContent
        handleTabClick={handleTabClick}
        headerLeadingIconButtonIconName="arrow_back_0"
        headerTitle={project?.name || ''}
        loading={!projectWorkflowsQuery?.isFetched}
        onHeaderLeadingIconButtonClick={handleGoBack}
        project={project}
        workflowListItems={workflowListItems}
        tabs={[
          {
            id: 'workflows-tab-1',
            title: intl.formatMessage({
              id: 'workflowspage.workflows_list.tab_title.workflows',
              defaultMessage: 'Workflows',
            }),
          },
          {
            id: 'workflows-tab-2',
            title: intl.formatMessage({
              id: 'workflowspage.workflows_list.tab_title.files',
              defaultMessage: 'Files',
            }),
          },
        ]}
      />
    </Wrapper>
  );
};

Workflows.propTypes = {};

export default Workflows;
