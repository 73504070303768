import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useDialog from '@hooks/useDialog';
import { isFeatureEnabled } from '@selectors/configSelectors';
import { updateConcept } from '@actions/conceptActions';
import { openModal } from '@actions/modalActions';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorList from '@hooks/operators/useOperatorList';
import usePrinter from '@hooks/printers/usePrinter';
import { QUICK_START_FEATURE } from '@constants/features';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import ListItem from '@components/1-atoms/ListItem';
import PageList from '@components/3-organisms/PageList';
import { WORKFLOW_OPERATORS_DROPPABLE_ID } from '@containers/WorkflowOperators';
import { DraggableElementWrapper } from './OperatorsLibrary.styled';

const OperatorsLibrary = ({ settingBarCollapsed, handleOperatorSelection }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [filterByCategoryName, setFilterByCategoryName] = useState(
    intl.formatMessage({
      id: 'general.all',
      defaultMessage: 'All',
    }),
  );
  const { hideDialog } = useDialog();
  const {
    getCategories,
    getCategoriesWithOperators,
    getWorkflowRecommendedOperators,
    getCustomOperatorDropDownMenuActions,
  } = useOperatorList();
  const { getIsWorkflowPublic, getSelectedWorkflow } = useWorkflow();
  const { getPrinters } = usePrinter();

  const workflow = getSelectedWorkflow();
  const availablePrinters = getPrinters();

  const isQuickStartFeatureEnabled = useSelector(
    isFeatureEnabled(QUICK_START_FEATURE),
  );

  const quickStartAvailable = useMemo(
    () => !!availablePrinters?.length && isQuickStartFeatureEnabled,
    [availablePrinters, isQuickStartFeatureEnabled],
  );

  const showCreateCustomOperatorButton = useMemo(
    () =>
      // TODO: Remove the hardcoded false value once the feature is enabled
      quickStartAvailable &&
      !!workflow &&
      !getIsWorkflowPublic(workflow) &&
      false,
    [quickStartAvailable, workflow, getIsWorkflowPublic],
  );

  const operatorsListItems = useMemo(() => {
    const operatorsByCategory = getCategoriesWithOperators();
    const workflowRecommendedOperators =
      getWorkflowRecommendedOperators(workflow);
    const categoryOperators = Object.values(operatorsByCategory)?.flat();
    const operatorsList = [
      ...workflowRecommendedOperators,
      ...categoryOperators,
    ];

    const filteredList = operatorsList.filter((operator) => {
      if (filterByCategoryName === 'All') {
        return true;
      }

      return operator.category === filterByCategoryName;
    });

    return filteredList.map((operator) => {
      const optionalProps = {};

      if (operator?.isTemplate) {
        optionalProps.endingIconButtonIconName = 'more_vert_0';
        optionalProps.endingIconButtonDropDownProps = {
          dropDownMenuItems: getCustomOperatorDropDownMenuActions(operator),
        };
      }

      return {
        ...optionalProps,
        id: operator.id,
        draggableId: `draggableId:{${operator?.name}} templateId:{${
          operator?.templateId || ''
        }}`,
        label: operator.label,
        leadingIconName: operator.iconName,
        group: operator.category,
        onClick: () => {
          handleOperatorSelection(operator)();
          hideDialog(ModalDataTypes.OPERATORS_LIBRARY);
        },
        dragData: {
          iconName: operator.iconName,
          label: operator.label,
        },
      };
    });
  }, [
    workflow,
    filterByCategoryName,
    getWorkflowRecommendedOperators,
    getCategoriesWithOperators,
    getCustomOperatorDropDownMenuActions,
    handleOperatorSelection,
    hideDialog,
  ]);

  const showCreateCustomOperatorDialog = useCallback(async () => {
    if (!workflow?.id) return;

    const updatedWorfklow = await dispatch(
      updateConcept(workflow?.id, { workflow }),
    );

    if (!updatedWorfklow?.id) return;

    dispatch(openModal({ type: 'createTemplate' }));
  }, [dispatch, workflow]);

  const actionChips = useMemo(() => {
    const chips = [];

    if (workflow) {
      const operatorCategories = getCategories();
      const workflowRecommendedOperators =
        getWorkflowRecommendedOperators(workflow);

      let allCategories = [];

      if (!isEmpty(workflowRecommendedOperators)) {
        allCategories.push({
          id: 'Ai Recommends',
          name: intl.formatMessage({
            id: 'recommendations.operator.title',
            defaultMessage: 'Ai Recommends',
          }),
          iconName: 'ai_on_1',
        });
      }

      allCategories.unshift({
        id: 'All',
        name: intl.formatMessage({
          id: 'general.all',
          defaultMessage: 'All',
        }),
        iconName: 'format_list_bulleted_0',
      });

      allCategories = [...allCategories, ...operatorCategories];

      chips.push({
        id: 'operators-library-filter-operators-chip',
        dataTestId: 'operators-library__filter-operators-chip',
        label: filterByCategoryName,
        leadingIconName: 'filter_list_0',
        endingIconName: 'arrow_drop_down_1',
        dropDownMenuItems: allCategories?.map((category, i) => ({
          id: category?.id,
          label: category?.name,
          leadingIconName: category?.iconName,
          withDivider: i === 1,
          selected: filterByCategoryName === category?.name,
          onClick: () => setFilterByCategoryName(category?.name),
        })),
        dropDOwnMenuPosition: 'bottom-left',
      });
    }

    if (showCreateCustomOperatorButton) {
      chips.push({
        id: 'operators-library-create-custom-operator-chip',
        variant: CHIP_VARIANT_PRIMARY,
        dataTestId: 'operators-library__create-custom-operator-chip',
        label: intl.formatMessage({
          id: 'operatorslibrary.operators_list.action_chips.create_custom_operator',
          defaultMessage: 'Custom operator',
        }),
        leadingIconName: 'add_0',
        onClick: showCreateCustomOperatorDialog,
      });
    }

    return chips;
  }, [
    intl,
    filterByCategoryName,
    showCreateCustomOperatorButton,
    showCreateCustomOperatorDialog,
    workflow,
    getCategories,
    getWorkflowRecommendedOperators,
  ]);

  const renderDraggableElement = useCallback(
    (data = {}, isDraggingOverTargetDroppable = false) => (
      <DraggableElementWrapper
        isDraggingOverTargetDroppable={isDraggingOverTargetDroppable}
      >
        <ListItem label={data.label} leadingIconName={data.iconName} dragged />
      </DraggableElementWrapper>
    ),
    [],
  );

  return (
    <PageList
      listItems={operatorsListItems}
      actionChips={actionChips}
      drag={{
        droppableId: WORKFLOW_OPERATORS_DROPPABLE_ID,
        isDragDisabled: settingBarCollapsed,
        draggableElement: renderDraggableElement,
      }}
      searchAutoFocus
    />
  );
};

OperatorsLibrary.propTypes = {
  settingBarCollapsed: PropTypes.bool.isRequired,
  handleOperatorSelection: PropTypes.func.isRequired,
};

export default OperatorsLibrary;
