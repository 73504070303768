import React, { useCallback, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { get, isFunction, uniqueId } from 'lodash';
import {
  stopPropagation,
  preventDefault,
  whenEnterButtonPressed,
} from '@utils/interactionEvents';
import { hasParentWithId } from '@utils/dom';
import {
  Wrapper,
  Label,
  InputContainer,
  InputOverlay,
  OverlayHiddenInput,
  LeadingIconContainer,
  Icon,
  EndingIconContainer,
  EndingButtonContainer,
  Input,
  SupportingText,
} from './Field.styled';
import IconButton from '@components/1-atoms/IconButton';
import { ICON_BUTTON_VARIANT_STANDARD } from '@components/1-atoms/IconButton/IconButton';
import { DIALOGS_COINTAINER_ID } from '@components/2-molecules/DialogPortal';
import { DROP_DOWN_MENU_ID } from '@components/2-molecules/DropDownMenu';

export const FIELD_SIZE_STANDARD = 'standard';
export const FIELD_SIZE_COMPACT = 'compact';

const Field = forwardRef(
  (
    {
      autoComplete = 'off',
      autoFocus,
      className,
      dataTestId = 'field',
      defaultValue,
      diffAdded,
      diffModified,
      diffRemoved,
      dirty,
      disabled,
      endingButtonColor,
      endingButtonIconName,
      endingIconName,
      error,
      field: formikField = {}, // Formik field props. Is available when component is used within a Formik.Field component
      form: formikForm = {}, // Formik field props. Is available when component is used within a Formik.Field component
      id,
      ignoreFormikError, // Formik field props. Is available when component is used within a Formik.Field component
      label,
      lang,
      leadingIconName,
      max,
      maxLength,
      min,
      name = '',
      onBlur,
      onChange,
      onEndingButtonClick,
      onFocus,
      onKeyDown,
      onKeyPress,
      onOverlayClick,
      passFieldValueOnChange = false, // TODO: remove this prop once all fields are updated to use onChange(e) instead of onChange(value)
      placeholder = '',
      renderAsTextArea, // Can be used to render a textarea instead of an input
      rows, // TextArea specific props
      size = FIELD_SIZE_STANDARD,
      step = '',
      supportingText,
      transparentWhenDisabled = true,
      type = 'text',
      value = '',
    },
    ref,
  ) => {
    const inputRef = useRef(null);
    const inputOverlayRef = useRef(null);
    const overlayHiddenInputId = useRef(
      onOverlayClick ? uniqueId('overlay-hidden-input-') : null,
    );

    const withOverlay = onOverlayClick && !disabled;
    const formikFieldErrorMessage =
      get(formikForm.errors, formikField.name) || '';
    const formikFieldInvalid =
      get(formikForm?.touched, formikField?.name) &&
      !!formikFieldErrorMessage &&
      !ignoreFormikError;

    const assignInputRef = useCallback(
      (element) => {
        inputRef.current = element;

        if (ref) {
          if (isFunction(ref)) {
            ref(element);
          } else {
            ref.current = element;
          }
        }
      },
      [ref],
    );

    const handleChange = useCallback(
      (e) => onChange?.(e.target.value),
      [onChange],
    );

    useEffect(() => {
      if (!autoFocus) return;

      inputRef?.current?.focus?.();

      if (hasParentWithId(inputRef?.current, DIALOGS_COINTAINER_ID)) {
        setTimeout(() => {
          inputRef?.current?.focus?.();
          // TODO: remove the timeout. The timeout is added because the
          // focus is not working in the dialog without the timeout as there is a delay in rendering the dialog content
        }, 300);

        return;
      }

      if (hasParentWithId(inputRef?.current, DROP_DOWN_MENU_ID)) {
        setTimeout(() => {
          inputRef?.current?.focus?.();
          // TODO: remove the timeout. The timeout is added because the
          // focus is not working in the dropdown menu without the timeout as there is a delay in rendering
        }, 50);
      }
    }, [autoFocus]);

    return (
      <Wrapper
        data-testid={dataTestId}
        className={className}
        disabled={disabled}
        transparentWhenDisabled={transparentWhenDisabled}
      >
        {label && <Label>{label}</Label>}

        <InputContainer disabled={disabled}>
          {leadingIconName && (
            <LeadingIconContainer size={size}>
              <Icon name={leadingIconName} />
            </LeadingIconContainer>
          )}

          <Input
            as={renderAsTextArea ? 'textarea' : undefined}
            autoComplete={autoComplete}
            data-testid={`${dataTestId}__input`}
            defaultValue={defaultValue}
            diffAdded={diffAdded}
            diffModified={diffModified}
            diffRemoved={diffRemoved}
            dirty={dirty}
            disabled={disabled || withOverlay}
            error={error || formikFieldInvalid}
            id={id}
            lang={lang}
            max={max}
            maxLength={maxLength}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={passFieldValueOnChange ? handleChange : onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            ref={assignInputRef}
            rows={rows}
            size={size}
            step={step}
            transparentWhenDisabled={transparentWhenDisabled}
            type={type}
            value={value}
            withEndingIcon={endingIconName || endingButtonIconName}
            withLeadingIcon={leadingIconName}
            withOverlay={withOverlay}
            {...formikField}
          />

          {endingIconName && (
            <EndingIconContainer size={size}>
              <Icon name={endingIconName} />
            </EndingIconContainer>
          )}

          {endingButtonIconName && (
            <EndingButtonContainer size={size}>
              <IconButton
                tabIndex={withOverlay ? -1 : 0}
                disabled={disabled}
                dataTestId={`${dataTestId}__icon-button`}
                iconName={endingButtonIconName}
                iconColor={endingButtonColor}
                onClick={stopPropagation(preventDefault(onEndingButtonClick))}
                variant={ICON_BUTTON_VARIANT_STANDARD}
              />
            </EndingButtonContainer>
          )}

          {withOverlay && (
            <>
              <InputOverlay
                readonly="readonly"
                htmlFor={overlayHiddenInputId.current}
                diffAdded={diffAdded}
                diffModified={diffModified}
                diffRemoved={diffRemoved}
                dirty={dirty}
                error={error || formikFieldInvalid}
                onClick={onOverlayClick}
                onKeyDown={whenEnterButtonPressed(onOverlayClick)}
                ref={inputOverlayRef}
                tabIndex={0}
              />

              <OverlayHiddenInput
                id={overlayHiddenInputId.current}
                onBlur={onBlur}
                name={name}
                {...formikField}
              />
            </>
          )}
        </InputContainer>

        {(supportingText || formikFieldInvalid) && (
          <SupportingText error={error || formikFieldInvalid} dirty={dirty}>
            {supportingText || formikFieldErrorMessage}
          </SupportingText>
        )}
      </Wrapper>
    );
  },
);

Field.displayName = 'Field';

export const fieldPropTypes = {
  dataTestId: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  endingButtonColor: PropTypes.string,
  endingButtonIconName: PropTypes.string,
  endingIconName: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  lang: PropTypes.string,
  leadingIconName: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEndingButtonClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onOverlayClick: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  renderAsTextArea: PropTypes.bool,
  size: PropTypes.oneOf([FIELD_SIZE_STANDARD, FIELD_SIZE_COMPACT]),
  step: PropTypes.string,
  supportingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  transparentWhenDisabled: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  passFieldValueOnChange: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    error: PropTypes.object,
    touched: PropTypes.object,
  }),
  meta: PropTypes.object,
  ignoreFormikError: PropTypes.bool,
};

Field.propTypes = {
  ...fieldPropTypes,
};

export default Field;
