import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import HorizontalDivider from '@components/1-atoms/HorizontalDivider';
import {
  Wrapper,
  Header,
  RouterLink,
  Logo,
  Navigation,
  NavigationItemWrapper,
  NavigationItem,
  NavigationItemStateLayer,
  NavigationItemIcon,
  NavigationItemLabel,
  CustomNavigationItem,
} from './NavigationDrawer.styled';

const DrawerNavigationItem = ({ dataTestId, item }) => {
  if (item.renderAs) {
    return (
      <CustomNavigationItem
        as={item.renderAs}
        data-testid={`${dataTestId}__navigation_item`}
        onClick={item.onClick}
        className={item.className}
      >
        <NavigationItemStateLayer />

        <NavigationItemIcon name={item?.iconName} />

        <NavigationItemLabel>{item?.label}</NavigationItemLabel>
      </CustomNavigationItem>
    );
  }

  return (
    <NavigationItem
      as={item?.external ? 'a' : RouterLink}
      data-testid={`${dataTestId}__navigation_item`}
      to={item?.to || ''}
      href={item?.to || ''}
      exact={item?.exact}
      target={item?.external ? '_blank' : undefined}
    >
      <NavigationItemStateLayer />

      <NavigationItemIcon name={item?.iconName} />

      <NavigationItemLabel>{item?.label}</NavigationItemLabel>
    </NavigationItem>
  );
};

DrawerNavigationItem.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    exact: PropTypes.bool,
    onClick: PropTypes.func,
    external: PropTypes.bool,
    renderAs: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
};

const NavigationDrawer = ({
  className,
  dataTestId = 'navigation-drawer',
  items,
  withLogo = true,
}) => {
  const isNavigationGroup = useCallback((item) => {
    return !!item.items;
  }, []);

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {withLogo && (
        <Header>
          <RouterLink
            data-testid={`${dataTestId}__logo-link`}
            to="/"
            style={{ height: '48px' }}
          >
            <Logo />
          </RouterLink>
        </Header>
      )}

      <Navigation>
        {items.map((item) => (
          <NavigationItemWrapper
            key={`navigation-item-${item?.id || item?.label}`}
          >
            {isNavigationGroup(item) && (
              <>
                <HorizontalDivider middleInset />

                {item?.items?.map((subItem) => (
                  <DrawerNavigationItem
                    dataTestId={dataTestId}
                    key={`navigation-item-${item?.id || item?.label}-sub-item-${
                      subItem?.id || subItem?.label
                    }`}
                    item={subItem}
                  />
                ))}
              </>
            )}

            {!isNavigationGroup(item) && (
              <DrawerNavigationItem dataTestId={dataTestId} item={item} />
            )}
          </NavigationItemWrapper>
        ))}
      </Navigation>
    </Wrapper>
  );
};

NavigationDrawer.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        iconName: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        to: PropTypes.string,
        exact: PropTypes.bool,
        renderAs: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            iconName: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            to: PropTypes.string,
            exact: PropTypes.bool,
            renderAs: PropTypes.string,
            onClick: PropTypes.func,
            className: PropTypes.string,
          }),
        ),
      }),
    ),
  ]).isRequired,
  withLogo: PropTypes.bool,
};

export default NavigationDrawer;
