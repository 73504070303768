import React from 'react';
import PropTypes from 'prop-types';
import getIntlProvider from '@utils/getIntlProvider';
import Button, {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_OUTLINE,
} from '@components/1-atoms/Button';
import { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import {
  Wrapper,
  MessageContainer,
  Icon,
  Message,
  Actions,
} from './ActionFooter.styled';

export const ACTION_FOOTER_VARIANT_DEFAULT = 'default';
export const ACTION_FOOTER_VARIANT_FULL_WIDTH = 'fullWidth';
export const ACTION_FOOTER_MESSAGE_VARIANT_INFO = 'info';
export const ACTION_FOOTER_MESSAGE_VARIANT_CAUTION = 'caution';

const intl = getIntlProvider();

const ActionFooter = ({
  className,
  dataTestId,
  disabled,
  message,
  messageVariant = ACTION_FOOTER_MESSAGE_VARIANT_INFO,
  messageLeadingIconName = 'info_0',
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  primaryButtonLabel = intl.formatMessage({
    id: 'general.save',
    defaultMessage: 'Save',
  }),
  primaryButtonLoading,
  primaryButtonType,
  primaryButtonIconName,
  secondaryButtonDisabled,
  secondaryButtonLabel,
  secondaryButtonIconName,
  variant = ACTION_FOOTER_VARIANT_DEFAULT,
  withDivider,
}) => {
  const showInfoMessage = variant === ACTION_FOOTER_VARIANT_DEFAULT && message;

  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      variant={variant}
      withDivider={withDivider}
    >
      {showInfoMessage && (
        <MessageContainer>
          <Icon messageVariant={messageVariant} name={messageLeadingIconName} />

          <Message
            dataTestId={`${dataTestId}__message`}
            variant={TEXT_VARIANT_BODY_MEDIUM}
            color={
              messageVariant === ACTION_FOOTER_MESSAGE_VARIANT_CAUTION
                ? 'caution'
                : 'secondary'
            }
          >
            {message}
          </Message>
        </MessageContainer>
      )}

      {(secondaryButtonLabel || primaryButtonLabel) && (
        <Actions>
          {secondaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__secondary-button`}
              variant={BUTTON_VARIANT_OUTLINE}
              disabled={secondaryButtonDisabled || disabled}
              iconName={secondaryButtonIconName}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonLabel}
            </Button>
          )}

          {primaryButtonLabel && (
            <Button
              dataTestId={`${dataTestId}__primary-button`}
              type={primaryButtonType}
              disabled={primaryButtonDisabled || disabled}
              loading={primaryButtonLoading}
              variant={BUTTON_VARIANT_PRIMARY}
              iconName={primaryButtonIconName}
              onClick={onPrimaryButtonClick}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </Actions>
      )}
    </Wrapper>
  );
};

ActionFooter.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  message: PropTypes.string,
  messageVariant: PropTypes.oneOf([
    ACTION_FOOTER_MESSAGE_VARIANT_INFO,
    ACTION_FOOTER_MESSAGE_VARIANT_CAUTION,
  ]),
  messageLeadingIconName: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonLabel: PropTypes.string.isRequired,
  primaryButtonLoading: PropTypes.bool,
  primaryButtonType: PropTypes.string,
  primaryButtonIconName: PropTypes.string,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonIconName: PropTypes.string,
  variant: PropTypes.oneOf([
    ACTION_FOOTER_VARIANT_DEFAULT,
    ACTION_FOOTER_VARIANT_FULL_WIDTH,
  ]),
  withDivider: PropTypes.bool,
};

export default ActionFooter;
