export const gantryComponentTypeDefinitions = {
  AXIS_1: {
    value: 'AXIS_1',
    label: 'Axis 1',
  },
  AXIS_2: {
    value: 'AXIS_2',
    label: 'Axis 2',
  },
  AXIS_3: {
    value: 'AXIS_3',
    label: 'Axis 3',
  },
  EXTRUDER: {
    value: 'EXTRUDER',
    label: 'Extruder',
  },
  BED: {
    value: 'BED',
    label: 'Bed',
  },
};
