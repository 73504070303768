export const extrusionTypeDefinitions = {
  THERMOPLASTIC_FILAMENT: {
    value: 'THERMOPLASTIC_FILAMENT',
    label: 'Thermoplastic_Filament',
  },
  THERMOPLASTIC_PELLET: {
    value: 'THERMOPLASTIC_PELLET',
    label: 'Thermoplastic_Pellet',
  },
  THERMOSET: {
    value: 'THERMOSET',
    label: 'Thermoset',
  },
  METAL_WIRE: {
    value: 'METAL_WIRE',
    label: 'Metal_Wire',
  },
};
