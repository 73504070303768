import { SCREEN_SIZE } from './screen';

export const pageContentWidth = '800px';

export const loginSideBarWidth = {
  [SCREEN_SIZE.SMALL]: '100%',
  [SCREEN_SIZE.MEDIUM]: '360px',
  [SCREEN_SIZE.LARGE]: '360px',
  [SCREEN_SIZE.XLARGE]: '360px',
};

export const baseLayoutSideBarWidth = {
  [SCREEN_SIZE.SMALL]: '292px',
  [SCREEN_SIZE.MEDIUM]: '292px',
  [SCREEN_SIZE.LARGE]: '292px',
  [SCREEN_SIZE.XLARGE]: '292px',
};

export const editorLayoutCollapsedSideBarWidth = {
  [SCREEN_SIZE.SMALL]: '96px',
  [SCREEN_SIZE.MEDIUM]: '96px',
  [SCREEN_SIZE.LARGE]: '96px',
  [SCREEN_SIZE.XLARGE]: '96px',
};

export const editorLayoutFullSideBarWidth = {
  [SCREEN_SIZE.SMALL]: '96px',
  [SCREEN_SIZE.MEDIUM]: '96px',
  [SCREEN_SIZE.LARGE]: '96px',
  [SCREEN_SIZE.XLARGE]: '96px',
};

export const editorLayoutCollapsedEditorWidth = {
  [SCREEN_SIZE.SMALL]: '96px',
  [SCREEN_SIZE.MEDIUM]: '96px',
  [SCREEN_SIZE.LARGE]: '96px',
  [SCREEN_SIZE.XLARGE]: '96px',
};

export const editorLayoutFullEditorWidth = {
  [SCREEN_SIZE.SMALL]: '524px',
  [SCREEN_SIZE.MEDIUM]: '524px',
  [SCREEN_SIZE.LARGE]: '524px',
  [SCREEN_SIZE.XLARGE]: '524px',
};

export default {
  loginSideBarWidth,
  pageContentWidth,
  baseLayoutSideBarWidth,
  editorLayoutCollapsedSideBarWidth,
  editorLayoutFullSideBarWidth,
  editorLayoutCollapsedEditorWidth,
  editorLayoutFullEditorWidth,
};
