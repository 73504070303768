import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useDesign from '@hooks/useDesign';
import { getViewIconsAllowedExtensions } from '@utils/model';
import { getDisplayMode } from '@selectors/conceptSelectors';
import { updateDisplayMode } from '@actions/conceptActions';
import { ViewModes } from '@constants/viewModes';

export default function useShadingTool() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const displayMode = useSelector(getDisplayMode());
  const { design } = useDesign();
  const disableActions = !getViewIconsAllowedExtensions().includes(
    design?.filetype?.toLowerCase(),
  );

  const handleShadedWireframe = useCallback(() => {
    dispatch(updateDisplayMode(ViewModes.SHADED_WIREFRAME));
  }, [dispatch]);

  const handleSolid = useCallback(() => {
    dispatch(updateDisplayMode(ViewModes.SOLID));
  }, [dispatch]);

  const handleGhosted = useCallback(() => {
    dispatch(updateDisplayMode(ViewModes.GHOSTED));
  }, [dispatch]);

  const handleWireframe = useCallback(() => {
    dispatch(updateDisplayMode(ViewModes.WIREFRAME));
  }, [dispatch]);

  const dropDownMenuItems = useMemo(
    () => [
      {
        id: `line-data-tool-${ViewModes.SHADED_WIREFRAME}-action`,
        label: intl.formatMessage({
          id: 'toolbar.shading.edge_faces',
          defaultMessage: 'Edged Faces',
        }),
        selected: displayMode === ViewModes.SHADED_WIREFRAME,
        onClick: handleShadedWireframe,
      },
      {
        id: `line-data-tool-${ViewModes.SOLID}-action`,
        label: intl.formatMessage({
          id: 'toolbar.shading.default_shading',
          defaultMessage: 'Default Shading',
        }),
        selected: displayMode === ViewModes.SOLID,
        onClick: handleSolid,
      },
      {
        id: `line-data-tool-${ViewModes.GHOSTED}-action`,
        label: intl.formatMessage({
          id: 'toolbar.shading.ghosted',
          defaultMessage: 'Ghosted',
        }),
        selected: displayMode === ViewModes.GHOSTED,
        onClick: handleGhosted,
      },
      {
        id: `line-data-tool-${ViewModes.WIREFRAME}-action`,
        label: intl.formatMessage({
          id: 'toolbar.shading.wireframe',
          defaultMessage: 'Wireframe',
        }),
        selected: displayMode === ViewModes.WIREFRAME,
        onClick: handleWireframe,
      },
    ],
    [
      displayMode,
      handleShadedWireframe,
      handleSolid,
      handleGhosted,
      handleWireframe,
      intl,
    ],
  );

  const selectedDropDownMenuItem = useMemo(
    () => dropDownMenuItems.find(({ selected }) => selected),
    [dropDownMenuItems],
  );

  const action = useMemo(
    () => ({
      id: 'shading-tool-action',
      disabled: disableActions,
      name: selectedDropDownMenuItem?.label,
      dropDownMenuItems,
    }),
    [disableActions, selectedDropDownMenuItem, dropDownMenuItems],
  );

  return {
    action,
  };
}
