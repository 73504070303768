import HttpStatusCodes from '@app/utils/httpStatusCodes';

export function extractErrorMessage(error) {
  const status = error?.response.status;
  const data = error?.response.data;

  if (
    status &&
    status == HttpStatusCodes.UNPROCESSABLE_ENTITY &&
    data.errors.length > 0
  ) {
    return data.errors[0];
  }
  if (
    status &&
    status >= HttpStatusCodes.BAD_REQUEST &&
    status < HttpStatusCodes.INTERNAL_SERVER_ERROR &&
    data.error.message
  ) {
    return data.error.message;
  }

  return undefined;
}
