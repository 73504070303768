import styled from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  ${flexColumn};
`;

export const UploadField = styled.input.attrs({ type: 'file' })`
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
`;
