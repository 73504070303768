import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
} from '@components/2-molecules/MessageBox';
import Button from '@components/1-atoms/Button';
import { Wrapper } from './LoginSingleSignOn.styled';

const LoginSingleSignOn = ({
  dataTestId = 'login-page-sso',
  className,
  isSubmitting,
  loginFailed,
}) => {
  const intl = useIntl();

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'general.login',
          defaultMessage: 'Log in',
        })}
      />

      {loginFailed && (
        <MessageBox
          dataTestId={`${dataTestId}__login-failed-message-box`}
          variant={MESSAGE_BOX_VARIANT_ERROR}
          leadingIconName="error_0"
        >
          <>
            <FormattedMessage
              id="loginpage.login_failed.sso"
              defaultMessage="Something went wrong trying to log you in."
            />
            <br />
            <FormattedMessage
              id="loginpage.login_failed.please_try_again_support"
              defaultMessage="Please try again or contact support."
            />
          </>
        </MessageBox>
      )}

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        type="submit"
        loading={isSubmitting}
        iconName="key_0"
      >
        <FormattedMessage
          id="loginpage.login_sso"
          defaultMessage="Log in with SSO"
        />
      </Button>
    </Wrapper>
  );
};

LoginSingleSignOn.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  isSubmitting: PropTypes.bool,
  loginFailed: PropTypes.bool,
};

export default LoginSingleSignOn;
