import * as actionTypes from '@constants/actionTypes';

const initialState = {
  originalConversation: [],
  formattedConversation: [],
  newActions: [],
  proposedWorkflow: {},
  originalWorkflowDiff: {},
  proposedWorkflowDiff: {},
};

const modalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GPT_ADD_MESSAGES_TO_CONVERSATION: {
      let nextState = { ...state };

      if (payload.originalConversation) {
        nextState = {
          ...nextState,
          originalConversation: [
            ...state.originalConversation,
            ...payload.originalConversation,
          ],
        };
      }

      if (payload.formattedConversation) {
        nextState = {
          ...nextState,
          formattedConversation: [
            ...state.formattedConversation,
            ...payload.formattedConversation,
          ],
        };
      }

      return nextState;
    }

    case actionTypes.GPT_UPDATE_CONVERSATION: {
      let nextState = { ...state };

      if (payload.originalConversation) {
        nextState = {
          ...nextState,
          originalConversation: payload.originalConversation,
        };
      }

      if (payload.formattedConversation) {
        nextState = {
          ...nextState,
          formattedConversation: payload.formattedConversation,
        };
      }

      return nextState;
    }

    case actionTypes.GPT_CONVERSATION_SUCCESS: {
      return {
        ...state,
        newActions: payload.newActions,
        proposedWorkflow: payload.proposedWorkflow,
        originalWorkflowDiff: payload.originalWorkflowDiff,
        proposedWorkflowDiff: payload.proposedWorkflowDiff,
      };
    }

    case actionTypes.GPT_APPLY_CHANGES_SUCCESS: {
      return {
        ...state,
        newActions: initialState.newActions,
        proposedWorkflow: initialState.proposedWorkflow,
        originalWorkflowDiff: initialState.originalWorkflowDiff,
        proposedWorkflowDiff: initialState.proposedWorkflowDiff,
      };
    }

    case actionTypes.GPT_RESET: {
      return initialState;
    }

    default:
      return state;
  }
};

export default modalReducer;
