import * as THREE from 'three';
import GlbModel from '@utils/GlbModel';
import {
  CAMERA_MODE_PERSPECTIVE,
  CAMERA_MODE_ORTHOGRAPHIC,
  CAMERA_POSITION_TOP,
  CAMERA_POSITION_BOTTOM,
  CAMERA_POSITION_FRONT,
  CAMERA_POSITION_BACK,
  CAMERA_POSITION_LEFT,
  CAMERA_POSITION_RIGHT,
  orthographicCameraPositions,
} from '@constants/camera';

export const getCameraMode = (cameraPosition = CAMERA_MODE_PERSPECTIVE) =>
  orthographicCameraPositions?.[cameraPosition]
    ? CAMERA_MODE_ORTHOGRAPHIC
    : CAMERA_MODE_PERSPECTIVE;

export const isOrthographicCameraMode = (mode = '') =>
  mode === CAMERA_MODE_ORTHOGRAPHIC;

export const isGLBModel = (model) => model instanceof GlbModel;

export const isObject3DModel = (model) => model instanceof THREE.Object3D;

export const getObjectDimensions = async (object = {}) => {
  let nextObject = object;
  const center = new THREE.Vector3();
  const size = new THREE.Vector3();

  if (isGLBModel(object)) {
    const gltfObject = await object.getLoadedModelPromise();
    nextObject = gltfObject.scene;
  }

  const box = new THREE.Box3().setFromObject(nextObject);

  box.getCenter(center);
  box.getSize(size);

  return {
    box,
    center,
    size,
  };
};

export const getObjectZoomInNumber = (
  cameraDirection,
  size = {},
  isSimulationObject,
) => {
  const directions = {
    default: [innerHeight / size.x, innerWidth / size.z],
    [CAMERA_POSITION_TOP]: [innerHeight / size.y, innerWidth / size.z],
    [CAMERA_POSITION_BOTTOM]: [innerHeight / size.y, innerWidth / size.z],
    [CAMERA_POSITION_FRONT]: [innerHeight / size.z, innerWidth / size.x],
    [CAMERA_POSITION_BACK]: [innerHeight / size.z, innerWidth / size.x],
    [CAMERA_POSITION_LEFT]: [innerHeight / size.z, innerWidth / size.x],
    [CAMERA_POSITION_RIGHT]: [innerHeight / size.z, innerWidth / size.x],
  };
  let zoom = Math.min(...(directions?.[cameraDirection] || directions.default));

  if (isSimulationObject) {
    zoom = (zoom / 100) * 6;
  }

  return zoom;
};
