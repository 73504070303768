import styled, { css, keyframes } from 'styled-components';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import { flexCenterVertical, when } from '@stylesheets/helpers';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
  ${flexCenterVertical}
  flex-shrink: 0;
  user-select: none;

  ${({
    withLeadingIconButton,
    withEndingIconButton,
    theme: { spacing, borderRadius, colors },
  }) => css`
    border-radius: ${borderRadius.full};
    background: ${colors.surfaceContainerLow};
    gap: ${spacing.level3};
    padding: 10px ${spacing.level6} 10px ${spacing.level5};

    ${when(
      withEndingIconButton,
      `
        padding: ${spacing.level1} ${spacing.level1} ${spacing.level1} ${spacing.level5};
      `,
    )}

    ${when(
      withLeadingIconButton,
      `
        padding: ${spacing.level1};
      `,
    )}
  `}
`;

export const Title = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
  color: 'onSurface',
})`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Icon = styled(SpriteSVG)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ pulseAnimation, iconColor, large, theme: { colors } }) => css`
    ${when(
      pulseAnimation,
      css`
        animation: ${pulse} 2s linear infinite;
      `,
      css`
        animation: ${spin} 2s linear infinite;
      `,
    )}

    ${when(
      iconColor,
      `
        fill: ${colors?.[iconColor] || iconColor};
      `,
      `
        fill: ${colors?.onSurface};
      `,
    )}

    ${when(
      large,
      `
        width: 24px;
        height: 24px;
      `,
    )}
  `}
`;

export const ProgressBar = styled.div`
  flex: 1;
  width: 76px;
  height: 4px;
  position: relative;
  overflow: hidden;

  ${({ theme: { colors, borderRadius, spacing } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.surfaceContainerHighest};
    margin-left: ${spacing.level4};
    margin-right: ${spacing.level2};
  `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: width 0.3s ease-in-out;

    ${({ progress, theme: { colors } }) => css`
      width: ${progress}%;
      background-color: ${colors.primary};
    `}
  }
`;

export const ProgressText = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
  color: 'onSurface',
})`
  min-width: 38px;
  text-align: right;
`;
