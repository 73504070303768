import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useModal from '@hooks/useModal';
import { ModalDataTypes, MODAL_IDS } from '@app/constants/modalDataTypes';

export default function usePrinterList() {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { showModal } = useModal();

  const getPrinterDropDownMenuActions = useCallback(
    (printer = {}) => {
      const actions = [
        {
          leadingIconName: 'query_stats_0',
          label: intl.formatMessage({
            id: 'printeractions.sensor_data.label',
            defaultMessage: 'Sensor data',
          }),
          onClick: () => {
            showModal(MODAL_IDS.PRINTER_SENSOR_DATA, { printer });
          },
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.DELETE_PRINTER, {
              printerId: printer.id,
              printerName: printer.name,
            });
          },
        },
      ];

      return actions;
    },
    [showDialog, showModal, intl],
  );

  return {
    getPrinterDropDownMenuActions,
  };
}
