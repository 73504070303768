import dagre from 'dagre';
import Graph from 'graph-js';
import {
  OPERATOR_NODE_HEIGHT,
  OPERATOR_NODE_WIDTH,
} from '@components/OperatorNode/OperatorNode.styled';

export const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const dagreGraph = new dagre.graphlib.Graph();
  const isHorizontal = direction === 'LR';

  const nodeWidth = OPERATOR_NODE_WIDTH;
  const nodeHeight = OPERATOR_NODE_HEIGHT;
  const offset = 80;
  const width = nodeWidth + offset;
  const initialHeight = nodeHeight + offset;

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    const height = node?.height ? node?.height + offset : initialHeight;

    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export const getDependentNodes = (graph, targetNodeId) => {
  const nodes = graph.getNodes();
  let dependentNodes = [];
  let currentNode = targetNodeId;
  while (dependentNodes.length < nodes.length) {
    const successiveNodes = graph.getSuccessors(currentNode);
    if (!successiveNodes.length) {
      break;
    }
    dependentNodes = dependentNodes.concat(successiveNodes);
    currentNode = successiveNodes[0].getId();
  }

  return dependentNodes;
};

export const buildGraph = (workflow, workflowOperators) => {
  const graph = new Graph();

  workflowOperators?.forEach((operator) => {
    graph.addNode(operator, operator.id);
  });

  workflow?.operatorDependencies?.forEach((operatorDependency) => {
    const operator = workflowOperators.find(
      (o) => o.id === operatorDependency.id.operatorId,
    );

    const dependedOperator = workflowOperators?.find(
      (o) => o.id === operatorDependency.id.dependedOperatorId,
    );

    if (!operator || !dependedOperator) return;

    graph.addEdge(
      operator.id,
      dependedOperator.id,
      operator.id + '-' + dependedOperator.id,
    );
  });

  return graph;
};
