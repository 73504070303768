import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexColumn, flexCenter, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 18px;
  border-radius: 18px;
  overflow: auto;

  ${({ isCompactVariant, theme: { spacing, colors } }) => css`
    background-color: ${colors.surfaceContainer};
    padding: ${spacing.level10};

    ${when(
      isCompactVariant,
      `
        background-color: unset;
        gap: ${spacing.level2};
        padding: ${spacing.level3};
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  flex-shrink: 0;
  place-self: center;

  ${({ isCompactVariant, theme: { colors, spacing } }) => css`
    width: ${spacing.level9};
    height: ${spacing.level9};
    fill: ${colors.primary};

    ${when(
      isCompactVariant,
      `
        width: 24px;
        height: 24px;
        fill: ${colors.secondary};
      `,
    )}
  `}
`;

export const Actions = styled.div`
  ${flexCenter}
  flex-wrap: wrap;

  & > * {
    flex: unset;
  }

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}
`;

const linkButtonStyles = css`
  ${({ disabled, theme: { colors, font } }) => css`
    ${font.labelMedium}
    color: ${colors.primary};
    cursor: pointer;
    text-decoration: none;
    user-select: none;

    &:hover {
      opacity: 0.8;
    }

    ${when(
      disabled,
      `
        color: ${colors.onSurfaceVariant};
        pointer-events: none;
        opacity: 0.37;
      `,
    )}
  `}
`;

export const LinkButton = styled(NavLink)`
  ${linkButtonStyles}
`;

export const RedirectLinkButton = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${linkButtonStyles}
`;
