import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useTooltip from '@hooks/useTooltip';
import Tooltip from '@components/2-molecules/Tooltip';

export const TOOLTIP_CONTAINER_ID = 'tooltip-container';

const TooltipPortal = ({ id, ...tooltipProps }) => {
  const {
    tooltipId,
    tooltipIsOpen,
    tooltipStyle,
    hideTooltip,
    calculateTooltipPosition,
    handleTooltipResize,
  } = useTooltip();
  const isCurrentTooltip = tooltipId === id;

  const tooltipPortalContainer = document.getElementById(TOOLTIP_CONTAINER_ID);

  useEffect(() => {
    if (tooltipIsOpen && isCurrentTooltip) {
      window.addEventListener('resize', handleTooltipResize);
      window.addEventListener('scroll', handleTooltipResize);

      return () => {
        window.removeEventListener('resize', handleTooltipResize);
        window.removeEventListener('scroll', handleTooltipResize);
      };
    }
  }, [
    calculateTooltipPosition,
    tooltipIsOpen,
    handleTooltipResize,
    hideTooltip,
    isCurrentTooltip,
  ]);

  useEffect(() => {
    if (tooltipIsOpen && isCurrentTooltip) {
      calculateTooltipPosition();
    }
  }, [
    tooltipProps.menuItems,
    calculateTooltipPosition,
    tooltipIsOpen,
    isCurrentTooltip,
  ]);

  if (!tooltipPortalContainer || !isCurrentTooltip) return null;

  return createPortal(
    <Tooltip {...tooltipProps} style={tooltipStyle} />,
    tooltipPortalContainer,
  );
};

TooltipPortal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TooltipPortal;
