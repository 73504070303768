import client from '@api/client';
import endpoints from '@api/endpoints';

import { takeEvery, put, call } from 'redux-saga/effects';
import {
  fetchPostProcessorConfigsForPrinterFailure,
  fetchPostProcessorConfigsForPrinterSuccess,
  updatePostProcessorConfigFailure,
  updatePostProcessorConfigSuccess,
  fetchPrinterByIdSuccess,
  fetchPrinterByIdFailure,
  uploadPrinterComponentSuccess,
  fetchPrinterById,
  uploadPrinterComponentFailure,
  selectPrinter,
  updateVisibilitySuccess,
  updateVisibilityFailure,
  hidePostProcessorConfig,
  fetchPostProcessorConfigsForPrinter,
} from '@actions/printerActions';
import actionTypes from '@app/actions';
import { doUpload } from '@utils/filesUpload';
import { getConfig } from '@actions/AuthenticatedFetch';
import { showErrorDialog } from '@actions/errorActions';
import { ModalDataTypes } from '@constants/modalDataTypes';
import getIntlProvider from '@utils/getIntlProvider';
import { printerConstants } from '@constants/printers/printerConstants';
import { componentDiscriminators } from '@constants/printers/componentDiscriminators';

const intl = getIntlProvider();

export function* fetchPostProcessorConfigs({ payload }) {
  const requestUrl = endpoints.postProcessorConfig.replace(
    ':printerId',
    payload?.printerId,
  );

  try {
    const response = yield client.get(requestUrl);
    const postProcessorConfigs = response?.data || {};

    yield put(fetchPostProcessorConfigsForPrinterSuccess(postProcessorConfigs));
  } catch (error) {
    yield put(fetchPostProcessorConfigsForPrinterFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* updatePostProcessorConfig({ payload }) {
  try {
    const requestUrl = endpoints.postProcessorConfig.replace(
      ':printerId',
      payload?.printerId,
    );

    yield client.put(requestUrl, {
      postProcessorConfigs: payload.postProcessorConfigs,
    });
    yield put(updatePostProcessorConfigSuccess());
    yield put(hidePostProcessorConfig());
    yield put(fetchPostProcessorConfigsForPrinter(payload?.printerId));
    payload?.successCallback?.();
    payload.showDialog(ModalDataTypes.PROMPT, {
      dataTestId: 'post-processor-custom-warning-dialog',
      title: intl.formatMessage({
        id: 'dialogs.title.attention',
        defaultMessage: 'Attention',
      }),
      subtitle: intl.formatMessage({
        id: 'printers.postprocessor.update.warning',
        defaultMessage:
          'Disclaimer: Note that any instructions you insert here will be injected into the robot program. Please be aware that you can insert instructions that are dangerous and can damage your machine.',
      }),
      secondaryButtonLabel: '',
    });
  } catch (error) {
    yield put(updatePostProcessorConfigFailure(error));
  }
}

export function* fetchPrinterByIdImpl({ payload }) {
  const requestUrl = endpoints.printer.replace(
    ':printerId',
    payload?.printerId,
  );

  try {
    const response = yield client.get(requestUrl);
    const printer = response?.data || {};

    yield put(fetchPrinterByIdSuccess(printer));
    yield put(selectPrinter(payload.printerId));
  } catch (error) {
    yield put(fetchPrinterByIdFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* updateVisibilityImpl({ payload }) {
  try {
    const requestUrl = endpoints.printerComponentVisibility;

    let componentType;
    switch (payload?.componentType) {
      case printerConstants.enclosureType:
        componentType = componentDiscriminators.ENCLOSURE;
        break;
      case printerConstants.robotType:
        componentType = componentDiscriminators.MACHINE;
        break;
      case printerConstants.printingBedType:
        componentType = componentDiscriminators.BED;
        break;
      case printerConstants.extruderType:
        componentType = componentDiscriminators.EXTRUDER;
        break;
      case printerConstants.plinthType:
      case printerConstants.basePlinthType:
        componentType = componentDiscriminators.PLINTH;
        break;
      case printerConstants.programType:
        componentType = componentDiscriminators.PROGRAM_TYPE;
        break;
    }

    yield client.post(requestUrl, {
      componentType: componentType,
      visibilityMap: payload?.visibilityMap,
    });

    yield put(updateVisibilitySuccess());
    payload?.callback(`Visibilities has been updated`);
  } catch (error) {
    yield put(updateVisibilityFailure(error));
  }
}

export function* uploadPrinterComponentModelsImpl({ payload }) {
  const formData = new FormData();

  const componentBlob = new Blob([JSON.stringify(payload.componentData)], {
    type: 'application/json',
  });
  formData.append('component', componentBlob);

  Object.entries(payload.fileDefinitions).forEach(([key, fileDefinition]) => {
    const { file } = fileDefinition;
    const fileName = key + '-' + file.name;
    formData.append('models', file, fileName);
  });

  const response = yield call(
    doUpload,
    formData,
    getConfig(),
    'printers/' + payload.printerId + '/models',
  );

  if (response.status === 201 || response.status === 200) {
    yield put(fetchPrinterById(payload.printerId));
    yield put(uploadPrinterComponentSuccess());
    if (payload?.callback) {
      payload?.callback();
    }
  } else {
    yield put(uploadPrinterComponentFailure());
    yield put(
      showErrorDialog(
        'Uploading model files failed',
        'Something went wrong...',
      ),
    );
  }
}

export default [
  takeEvery(
    actionTypes.FETCH_POST_PROCESSOR_CONFIG_REQUEST,
    fetchPostProcessorConfigs,
  ),
  takeEvery(
    actionTypes.UPDATE_POST_PROCESSOR_CONFIG_REQUEST,
    updatePostProcessorConfig,
  ),
  takeEvery(actionTypes.UPDATE_VISIBILITY_REQUEST, updateVisibilityImpl),
  takeEvery(actionTypes.FETCH_PRINTER_REQUEST, fetchPrinterByIdImpl),
  takeEvery(
    actionTypes.UPLOAD_PRINTER_COMPONENT_REQUEST,
    uploadPrinterComponentModelsImpl,
  ),
];
