import styled, { css } from 'styled-components';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.ul`
  ${flexColumn}
  align-self: stretch;
  gap: 10px;
  padding: 10px;
  margin: 0;

  ${({ theme: { borderRadius, colors } }) => css`
    background-color: ${colors.surfaceContainer};
    border-radius: ${borderRadius.small};
  `}
`;

export const Rule = styled.li`
  ${flexCenterVertical}
  gap: 5px;
`;

export const RuleIcon = styled.span`
  width: 12px;
  height: 12px;
  flex-shrink: 0;

  ${({ matchCondition, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.onSurface};

    ${when(
      matchCondition,
      `
        background-color: ${colors.colorsSource.success[80]};
      `,
    )}
  `}
`;

export const RuleDescription = styled.span`
  ${({ matchCondition, theme: { font, colors } }) => css`
    ${font.bodySmall}
    color: ${colors.onSurface};

    ${when(
      matchCondition,
      `
        color: ${colors.colorsSource.success[80]};
      `,
    )}
  `}
`;
