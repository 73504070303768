import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  DiagramImage,
  DiagramRow,
  DiagramRowText,
} from '@components/Printers/GeneralPrinterSetting/DiagramRowComponent.styled';

export const DiagramRowComponent = ({
  src,
  description_key,
  second_description_key,
}) => (
  <DiagramRow>
    <DiagramImage src={src} />
    <DiagramRowText>
      <FormattedMessage id={description_key} defaultMessage={description_key} />
    </DiagramRowText>
    {second_description_key && (
      <DiagramRowText italic>
        <FormattedMessage
          id={second_description_key}
          defaultMessage={'Please export all models in mm units'}
        />
      </DiagramRowText>
    )}
  </DiagramRow>
);

DiagramRowComponent.propTypes = {
  src: PropTypes.string.isRequired,
  description_key: PropTypes.string.isRequired,
  second_description_key: PropTypes.string,
};
