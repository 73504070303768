import styled, { css, keyframes } from 'styled-components';
import { rgba } from 'polished';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import Text from '@components/1-atoms/Text';
import IconButton from '@components/1-atoms/IconButton';
import { flexCenterVertical, when } from '@stylesheets/helpers';

const iconStyles = css`
  flex-shrink: 0;

  ${({ theme: { spacing, colors } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
    fill: ${colors.onSurface};
  `}
`;

export const OperatorDetails = styled.div`
  ${flexCenterVertical}
  flex: 1;
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level4};
  `}
`;

export const OperatorIcon = styled(SpriteSVG)`
  ${iconStyles}
`;

export const OperatorTitle = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`;

export const MoreButton = styled(IconButton)`
  ${({ lock }) => css`
    ${when(
      !lock,
      `
        display: none;
      `,
    )}
  `}
`;

export const Wrapper = styled.div`
  ${flexCenterVertical}

  ${({
    readOnly,
    disableActions,
    selectable,
    selected,
    dragged,
    theme: { colors, spacing },
  }) => css`
    background-color: ${colors.surfaceContainerHigh};

    ${when(
      readOnly,
      `
        padding: ${spacing.level4} ${spacing.level5};
      `,
    )}

    ${when(
      !disableActions && selectable,
      `
        cursor: pointer !important;
      `,
    )}

    ${when(
      !disableActions && !dragged,
      `
        &:hover {
          box-shadow: inset 0px 0px 0px 30px ${rgba(colors.onSurface, 0.08)};

          ${when(
            selected,
            `
              box-shadow: inset 0px 0px 0px 30px ${rgba(
                colors.secondary,
                0.08,
              )};
            `,
          )}

          ${MoreButton} {
            display: block;
          }
        }

        &:focus-visible {
          box-shadow: inset 0px 0px 0px 30px ${rgba(colors.onSurface, 0.12)};

          ${when(
            selected,
            `
              box-shadow: inset 0px 0px 0px 30px ${rgba(
                colors.secondary,
                0.12,
              )};
            `,
          )}
        }
      `,
    )}
  `}

    ${({
    diffRemoved,
    diffAdded,
    diffModified,
    disabled,
    dragged,
    selected,
    highlighted,
    theme: { colors },
  }) => css`
    ${when(
      highlighted,
      `
        background-color: ${colors.outlineVariant};
      `,
    )}

    ${when(
      selected,
      `
        background-color: ${colors.secondaryContainer};
      `,
    )}

    ${when(
      dragged,
      `
        box-shadow: inset 0px 0px 0px 30px ${rgba(
          colors.onSurface,
          0.16,
        )}, 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);

        ${when(
          selected,
          `
            box-shadow: inset 0px 0px 0px 30px ${rgba(
              colors.secondary,
              0.16,
            )}, 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
          `,
        )}
      `,
    )}

        ${when(
      diffRemoved,
      `
        background-color: #9c3e47;
      `,
    )}

        ${when(
      diffAdded,
      `
        background-color: #007bff;
      `,
    )}

        ${when(
      diffModified,
      `
        background-color: #E93EA4;
      `,
    )}

        ${when(
      disabled,
      `
        opacity: 0.38;
        pointer-events: none;

        & > * {
          pointer-events: none;
        }
      `,
    )}
  `}
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = styled(SpriteSVG).attrs({ name: 'progress_activity_0' })`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  animation: ${spin} 2s linear infinite;

  ${({ theme: { colors, spacing } }) => css`
    fill: ${colors?.onSurface};
    margin: ${spacing?.level4};
  `}
`;
