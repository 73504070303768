import styled, { css } from 'styled-components';
import { when, flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  min-height: 100%;
  ${flexCenterVertical}
  place-self: stretch;

  ${({
    inset,
    middleInset,
    inlineInset,
    inlineMiddleInset,
    theme: { spacing },
  }) => css`
    ${when(
      inset,
      `
        padding-top: ${spacing.level5};
      `,
    )}

    ${when(
      middleInset,
      `
        padding-block: ${spacing.level5};
      `,
    )}

    ${when(
      inlineInset,
      `
        padding-left: ${spacing.level2};
      `,
    )}

    ${when(
      inlineMiddleInset,
      `
        padding-inline: ${spacing.level2};
      `,
    )}
  `}
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.outlineVariant};
  `}
`;
