import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import usePrinter from '@hooks/printers/usePrinter';
import Dialog from '../Workspaces/Dialog';
import {
  deletePrint,
  dismissDeletePrintDialog,
} from '../../actions/printActions';
import { useIntl } from 'react-intl';

import {
  getIsDeletingPrint,
  getPrintIdToDelete,
} from '../../selectors/printSelectors';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const Dialogs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { getSelectedPrinter } = usePrinter();

  const selectedPrinter = getSelectedPrinter();
  const selectedPrinterId = selectedPrinter?.id;

  const isDeletingPrint = useSelector(getIsDeletingPrint());
  const printIdToDelete = useSelector(getPrintIdToDelete());

  if (isDeletingPrint) {
    return (
      <Dialog
        title={intl.formatMessage({
          id: 'dialogs.title.areyousure',
          defaultMessage: 'Are you sure?',
        })}
        subtitle={intl.formatMessage({
          id: 'dialogs.subtitle.deleteprint',
          defaultMessage: 'This will permanently delete the selected print',
        })}
        confirmButtonText={intl.formatMessage({
          id: 'general.delete',
          defaultMessage: 'Delete',
        })}
        onConfirm={() => {
          dispatch(deletePrint(printIdToDelete)).then(() => {
            if (params?.printId === printIdToDelete) {
              history.push(`/printers/${selectedPrinterId}/prints`);
            }
          });
        }}
        onCancel={() => dispatch(dismissDeletePrintDialog())}
      />
    );
  }

  return null;
};
