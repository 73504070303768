import { useCallback, useEffect, useContext, useRef } from 'react';
import TooltipContext from '@contexts/TooltipContext';
import {
  TOOLTIP_ID,
  TOOLTIP_POSITION_TOP_LEFT,
  TOOLTIP_POSITION_TOP_CENTER,
  TOOLTIP_POSITION_TOP_RIGHT,
  TOOLTIP_POSITION_BOTTOM_LEFT,
  TOOLTIP_POSITION_BOTTOM_CENTER,
  TOOLTIP_POSITION_BOTTOM_RIGHT,
} from '@components/2-molecules/Tooltip';

export default function useTooltip() {
  const {
    anchorElement,
    tooltipData,
    tooltipId,
    tooltipIsOpen,
    tooltipStyle,
    resetTooltip,
    setAnchorElement,
    setTooltipData,
    setTooltipId,
    setTooltipIsOpen,
    setTooltipStyle,
    updateTooltipData,
    tooltipPosition,
    setTooltipPosition,
  } = useContext(TooltipContext);
  const anchorElementRef = useRef(anchorElement);

  const hideTooltip = useCallback(() => {
    if (!tooltipIsOpen) return;

    resetTooltip();
  }, [tooltipIsOpen, resetTooltip]);

  const showTooltip = useCallback(
    (id, anchorElementTarget, data = {}) => {
      if (!id || !anchorElementTarget) return;

      const isSameTooltip = tooltipId === id;

      if (tooltipIsOpen && isSameTooltip) {
        hideTooltip();
        return;
      }

      setTooltipId(id);
      setAnchorElement(anchorElementTarget);
      setTooltipData(data);

      if (data?.position) {
        setTooltipPosition(data.position);
      }

      setTooltipIsOpen(true);
    },
    [
      tooltipIsOpen,
      tooltipId,
      hideTooltip,
      setAnchorElement,
      setTooltipData,
      setTooltipId,
      setTooltipIsOpen,
      setTooltipPosition,
    ],
  );

  const calculateTooltipPosition = useCallback(() => {
    const tooltipElement = document.getElementById(TOOLTIP_ID);

    if (!anchorElement || !tooltipElement) {
      return;
    }

    const anchorElementPosition = anchorElement.getBoundingClientRect();
    const anchorElementWidth = anchorElementPosition.width;
    const anchorElementTopEdge = anchorElementPosition.top;
    const anchorElementBottomEdge =
      anchorElementTopEdge + anchorElementPosition.height;
    const anchorElementLeftEdge = anchorElementPosition.left;
    const anchorElementRightEdge = anchorElementLeftEdge + anchorElementWidth;
    const tooltipWidth = tooltipElement.offsetWidth;
    const tooltipHeight = tooltipElement.offsetHeight;

    const topOrientation = [
      TOOLTIP_POSITION_TOP_LEFT,
      TOOLTIP_POSITION_TOP_CENTER,
      TOOLTIP_POSITION_TOP_RIGHT,
    ].includes(tooltipPosition);

    const bottomOrientation = [
      TOOLTIP_POSITION_BOTTOM_LEFT,
      TOOLTIP_POSITION_BOTTOM_CENTER,
      TOOLTIP_POSITION_BOTTOM_RIGHT,
    ].includes(tooltipPosition);

    const leftOrientation = [
      TOOLTIP_POSITION_TOP_LEFT,
      TOOLTIP_POSITION_BOTTOM_LEFT,
    ].includes(tooltipPosition);

    const rightOrientation = [
      TOOLTIP_POSITION_TOP_RIGHT,
      TOOLTIP_POSITION_BOTTOM_RIGHT,
    ].includes(tooltipPosition);

    const centerOrientation = [
      TOOLTIP_POSITION_TOP_CENTER,
      TOOLTIP_POSITION_BOTTOM_CENTER,
    ].includes(tooltipPosition);

    let width;
    let topPosition;
    let leftPosition;
    const screenHorizontalOffset = 10;
    const screenVerticalOffset = 10;

    if (topOrientation) {
      topPosition = anchorElementBottomEdge - tooltipHeight;
      const isTooltipTopEdgeOutOfScreen = topPosition < screenVerticalOffset;

      if (isTooltipTopEdgeOutOfScreen) {
        topPosition = screenVerticalOffset;
      }
    }

    if (bottomOrientation) {
      topPosition = anchorElementTopEdge;
      const tooltipBottomEdgePosition = topPosition + tooltipHeight;
      const isTooltipBottomEdgeOutOfScreen =
        tooltipBottomEdgePosition + screenVerticalOffset > window.innerHeight;

      if (isTooltipBottomEdgeOutOfScreen) {
        topPosition = window.innerHeight - screenVerticalOffset - tooltipHeight;
      }
    }

    if (leftOrientation) {
      leftPosition = anchorElementLeftEdge - tooltipWidth;
      const isTooltipLeftEdgeOutOfScreen =
        leftPosition < screenHorizontalOffset;

      if (isTooltipLeftEdgeOutOfScreen) {
        leftPosition = screenHorizontalOffset;
      }
    }

    if (rightOrientation) {
      leftPosition = anchorElementRightEdge;
      const tooltipRightEdgePosition = leftPosition + tooltipWidth;
      const isTooltipRightEdgeOutOfScreen =
        tooltipRightEdgePosition + screenHorizontalOffset > window.innerWidth;

      if (isTooltipRightEdgeOutOfScreen) {
        leftPosition =
          window.innerWidth - screenHorizontalOffset - tooltipWidth;
      }
    }

    if (centerOrientation) {
      leftPosition =
        anchorElementLeftEdge + anchorElementWidth / 2 - tooltipWidth / 2;

      if (topOrientation) {
        topPosition = anchorElementTopEdge - tooltipHeight;
        const isTooltipTopEdgeOutOfScreen =
          topPosition - screenVerticalOffset <= 0;

        if (isTooltipTopEdgeOutOfScreen) {
          topPosition = screenVerticalOffset;
        }
      } else {
        topPosition = anchorElementBottomEdge;
        const isTooltipBottomEdgeOutOfScreen =
          topPosition + tooltipHeight + screenVerticalOffset >=
          window.innerHeight;

        if (isTooltipBottomEdgeOutOfScreen) {
          topPosition =
            window.innerHeight - screenVerticalOffset - tooltipHeight;
        }
      }
    }

    setTooltipStyle({
      opacity: 1,
      position: 'fixed',
      width,
      top: topPosition,
      left: leftPosition,
    });
  }, [anchorElement, tooltipPosition, setTooltipStyle]);

  const handleTooltipResize = useCallback(() => {
    if (!tooltipIsOpen) return;

    const anchorElementStillInDOM = document.body.contains(
      anchorElementRef.current,
    );

    if (!anchorElementStillInDOM) {
      hideTooltip();

      return;
    }

    calculateTooltipPosition();
  }, [tooltipIsOpen, calculateTooltipPosition, hideTooltip]);

  useEffect(() => {
    anchorElementRef.current = anchorElement;
  }, [anchorElement]);

  return {
    calculateTooltipPosition,
    tooltipData,
    tooltipId,
    tooltipIsOpen,
    tooltipStyle,
    handleTooltipResize,
    hideTooltip,
    showTooltip,
    updateTooltipData,
  };
}
