import styled, { css } from 'styled-components';
import { when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  ${({ bridge, theme: { borderRadius } }) => css`
    border-radius: ${borderRadius.large};

    ${when(
      bridge,
      `
        &:before {
          content: '';
          position: absolute;
        }
      `,
    )}
  `}

  ${({
    width,
    maxWidth,
    height,
    maxHeight,
    offsetTop,
    offsetRight,
    offsetBottom,
    offsetLeft,
  }) => css`
    ${when(
      width,
      `
        width: ${width}px;
      `,
    )}

    ${when(
      maxWidth,
      `
        max-width: ${maxWidth}px;
      `,
    )}

    ${when(
      height,
      `
        height: ${height}px;
      `,
    )}

    ${when(
      maxHeight,
      `
        max-height: ${maxHeight}px;
      `,
    )}

    ${when(
      offsetTop || offsetBottom,
      `
        &:before {
          left: 0;
          right: 0;
        }
      `,
    )}

    ${when(
      offsetTop,
      `
        &:before {
          height: calc(${offsetTop}px + 5px);
          bottom: 100%;
        }
      `,
    )}

    ${when(
      offsetBottom,
      `
        &:before {
          height: calc(${offsetBottom}px + 5px);
          top: 100%;
        }
      `,
    )}

    ${when(
      offsetRight || offsetLeft,
      `
        &:before {
          height: 100%;
        }
      `,
    )}

    ${when(
      offsetRight,
      `
        &:before {
          width: calc(${offsetRight}px + 5px);
          left: 100%;
        }
      `,
    )}

    ${when(
      offsetLeft,
      `
        &:before {
          width: calc(${offsetLeft}px + 5px);
          right: 100%;
        }
      `,
    )}
  `}
`;
