import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const history = useHistory();
  const [dialogs, setDialogs] = useState([]);
  const [dialogIDSToHideOnRouteChange, setDialogIDSToHideOnRouteChange] =
    useState([]);

  const addDialogIDToHideOnRouteChange = useCallback((id) => {
    setDialogIDSToHideOnRouteChange((prev) => [...prev, id]);
  }, []);

  const removeDialogIDToHideOnRouteChange = useCallback((id) => {
    setDialogIDSToHideOnRouteChange((prev) =>
      prev.filter((dialogID) => dialogID !== id),
    );
  }, []);

  const addDialog = useCallback(
    (dialog) => {
      setDialogs((prevDialogs) => [...prevDialogs, dialog]);
    },
    [setDialogs],
  );

  const removeDialog = useCallback(
    (dialogId) => {
      setDialogs((prevDialogs) =>
        prevDialogs.filter((dialog) => dialog.id !== dialogId),
      );
    },
    [setDialogs],
  );

  const updateDialogData = useCallback(
    (dialogId = '', dialogData = {}) => {
      if (!dialogId) return;

      setDialogs((prevDialogs) =>
        prevDialogs.map((dialog) => {
          if (dialog.id === dialogId) {
            return {
              ...dialog,
              data: {
                ...(dialog.data || {}),
                ...(dialogData || {}),
              },
            };
          }

          return dialog;
        }),
      );
    },
    [setDialogs],
  );

  const handleRouteChange = useCallback(() => {
    dialogIDSToHideOnRouteChange.forEach((id) => {
      removeDialog(id);
      removeDialogIDToHideOnRouteChange(id);
    });
  }, [
    dialogIDSToHideOnRouteChange,
    removeDialog,
    removeDialogIDToHideOnRouteChange,
  ]);

  useEffect(() => {
    const unlisten = history.listen(handleRouteChange);

    return () => {
      unlisten();
    };
  }, [history, handleRouteChange]);

  const value = useMemo(
    () => ({
      dialogs,
      addDialog,
      removeDialog,
      updateDialogData,
      addDialogIDToHideOnRouteChange,
    }),
    [
      dialogs,
      addDialog,
      removeDialog,
      updateDialogData,
      addDialogIDToHideOnRouteChange,
    ],
  );

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.any,
};

export default DialogContext;
