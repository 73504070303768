import styled from 'styled-components';
import { Handle as ReactFlowHandle } from '@xyflow/react';

export const OPERATOR_NODE_WIDTH = 400;
export const OPERATOR_NODE_HEIGHT = 48;

export const Wrapper = styled.div`
  width: ${OPERATOR_NODE_WIDTH}px;
  position: relative;
`;

export const Handle = styled(ReactFlowHandle)`
  top: 24px;
  bottom: unset;
  z-index: 1;

  ${({ orientation }) =>
    orientation === 'vertical' &&
    `
    top: 35px;
  `}
`;
