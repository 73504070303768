export const getComputationProgressHandler = ({ computationProgress }) =>
  computationProgress?.handler || {};

export const getComputingItemId = ({ computationProgress }) =>
  computationProgress?.computingItemId || '';

export const getComputedItemIds = ({ computationProgress }) =>
  computationProgress?.computedItemIds || [];

export const getIsWorkflowComputing = ({ computationProgress }) =>
  computationProgress?.computing;

export const getComputationProgress = ({ computationProgress }) => {
  const workflowIsComputing = computationProgress?.computing;
  const taskProgress = computationProgress?.handler?.progress;
  const computingWithProgress = workflowIsComputing && taskProgress;

  if (!computingWithProgress) return 0;

  const parsedProgress = parseInt((taskProgress || 0.0) * 100.0, 10);

  return parsedProgress;
};
