import * as actionTypes from '@constants/actionTypes';

const initialState = {
  notifications: [],
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action?.payload?.notifications,
      };
    }

    default:
      return state;
  }
};

export default notificationsReducer;
