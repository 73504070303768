import React, { useCallback, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from '@components/1-atoms/Field';
import { Field } from './TextArea.styled';

const TextArea = forwardRef(
  ({ rows, resizible, autoHeight, ...fieldProps }, ref) => {
    const textAreaRef = useRef();

    const calculateHight = useCallback(() => {
      const textAreaElement = textAreaRef.current;
      const textAreaStyle = textAreaElement.style;
      const fieldOutset = 2;

      textAreaStyle.height = 0;
      textAreaStyle.height = `${textAreaElement.scrollHeight + fieldOutset}px`;
    }, [textAreaRef]);

    useEffect(() => {
      if (autoHeight) calculateHight();
    }, [autoHeight, fieldProps?.value, calculateHight]);

    useEffect(() => {
      if (!ref) return;

      ref.current = textAreaRef.current;
    }, [ref, textAreaRef]);

    return (
      <Field
        ref={textAreaRef}
        {...fieldProps}
        rows={rows}
        resizible={resizible}
        renderAsTextArea
      />
    );
  },
);

TextArea.propTypes = {
  rows: PropTypes.number,
  autoHeight: PropTypes.bool,
  resizible: PropTypes.bool,
  ...fieldPropTypes,
};

TextArea.displayName = 'TextArea';

export default TextArea;
