import styled, { css } from 'styled-components';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { when } from '@stylesheets/helpers';
import {
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
} from '@utils/operatorStatus';

const iconStyles = css`
  flex-shrink: 0;

  ${({ theme: { spacing, colors } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
    fill: ${colors.onSurface};
  `}
`;

export const StatusIcon = styled(SpriteSVG)`
  ${iconStyles}

  ${({ status, theme: { spacing, colors } }) => css`
    margin: ${spacing.level4};

    ${when(
      status === OPERATOR_HEADER_STATUS_HIGHLIGHT,
      `
        fill: ${colors.success};
      `,
    )}

    ${when(
      status === OPERATOR_HEADER_STATUS_ALERT,
      `
        fill: ${colors.caution};
      `,
    )}

        ${when(
      status === OPERATOR_HEADER_STATUS_ALERT_MISS,
      `
        fill: ${colors.error};
      `,
    )}
  `}
`;
