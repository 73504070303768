import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Field as FormikField, useFormikContext } from 'formik';
import PostProcessorSetting from '@components/Printers/PostProcessorConfig/PostProcessorSetting';
import React from 'react';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';
import { PostProcessorSettingsName } from '@components/Printers/PostProcessorConfig/PostProcessorConstants';

const PostProcessorRetraction = ({ isSubmitting }) => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  const intl = useIntl();

  return (
    <SettingsCategory
      title={intl.formatMessage({
        id: 'printers.postprocessor.isRetractionEnabled.label',
        defaultMessage: 'Enable Retraction',
      })}
      dataTestId={`printer-custom-post-processor__setting-isRetractionEnabled`}
      withDividerBottom
      endingCheckboxChecked={Boolean(
        values[PostProcessorSettingsName.isRetractionEnabled],
      )}
      endingCheckboxDirty={checkIfFieldIsDirty(
        values,
        initialValues,
        PostProcessorSettingsName.isRetractionEnabled,
      )}
      onEndingCheckboxClick={() => {
        setFieldValue(
          PostProcessorSettingsName.isRetractionEnabled,
          !values[PostProcessorSettingsName.isRetractionEnabled],
        );
      }}
      expand={Boolean(values[PostProcessorSettingsName.isRetractionEnabled])}
    >
      <SettingsCategory
        title={intl.formatMessage({
          id: 'printers.postprocessor.useRetractionCustom.label',
          defaultMessage: 'Use Custom Instructions',
        })}
        dataTestId={`printer-custom-post-processor__setting-useRetractionCustom`}
        endingCheckboxChecked={Boolean(
          values[PostProcessorSettingsName.useRetractionCustom],
        )}
        endingCheckboxDirty={checkIfFieldIsDirty(
          values,
          initialValues,
          PostProcessorSettingsName.useRetractionCustom,
        )}
        onEndingCheckboxClick={() => {
          setFieldValue(
            PostProcessorSettingsName.useRetractionCustom,
            !values[PostProcessorSettingsName.useRetractionCustom],
          );
        }}
        expand={Boolean(values[PostProcessorSettingsName.useRetractionCustom])}
      >
        <FormikField
          label={intl.formatMessage({
            id: 'printers.postprocessor.customRetractionInstructions.label',
          })}
          component={PostProcessorSetting}
          dataTestId={`printer-custom-post-processor__setting-customRetractionInstructions`}
          name={PostProcessorSettingsName.customRetractionInstructions}
          disabled={isSubmitting}
          renderAsTextArea
          descriptionLabel={intl.formatMessage({
            id: 'printers.postprocessor.customRetractionInstructions.description',
          })}
        />
        <FormikField
          label={intl.formatMessage({
            id: 'printers.postprocessor.customProtractionInstructions.label',
          })}
          component={PostProcessorSetting}
          dataTestId={`printer-custom-post-processor__setting-customProtractionInstructions`}
          name={PostProcessorSettingsName.customProtractionInstructions}
          disabled={isSubmitting}
          renderAsTextArea
          descriptionLabel={intl.formatMessage({
            id: 'printers.postprocessor.customProtractionInstructions.description',
          })}
        />
      </SettingsCategory>
    </SettingsCategory>
  );
};

PostProcessorRetraction.propTypes = {
  isSubmitting: PropTypes.bool,
};

export default PostProcessorRetraction;
