import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import useMaterialMutations from '@hooks/materials/useMaterialMutations';
import React, { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { Fields } from '@containers/Dialogs/CloneMaterialDialog/CloneMaterialDialog.styled';
import { Field as FormikField, Formik } from 'formik';
import Field from '@components/1-atoms/Field';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';

const MODAL_ID = ModalDataTypes.CLONE_MATERIAL;

export const CloneMaterialDialog = () => {
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const history = useHistory();
  const { cloneMaterialMutation } = useMaterialMutations();

  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);

  const formInitialValues = useMemo(
    () => ({
      name: `${dialogData.material.materialName}_copy`,
    }),
    [dialogData],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .required(
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.required',
              defaultMessage: 'Name cannot be empty',
            }),
          )
          .min(
            2,
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.min',
              defaultMessage: 'Name must be at least 2 characters long',
            }),
          )
          .max(
            128,
            intl.formatMessage({
              id: 'materials.create_material_dialog.material_name.validation.max',
              defaultMessage: 'Name must be no longer than 128 characters',
            }),
          ),
      }),
    [intl],
  );

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const onFormSubmit = useCallback(
    (values) =>
      (async () => {
        const { name } = values;
        let material;
        try {
          material = await cloneMaterialMutation.mutateAsync({
            material: {
              materialName: name,
              type: dialogData.material.type,
            },
            id: dialogData.material.id,
          });
        } catch (_) {
          //error
        }
        hideDialog(MODAL_ID);

        const navigationPath = generatePath(ROUTES.MATERIAL, {
          materialId: material?.id,
        });

        history.push(navigationPath);
      })(),
    [history, hideDialog, cloneMaterialMutation, dialogData],
  );

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <DialogPortal
          renderAsForm
          dataTestId="clone-material"
          dialogId={MODAL_ID}
          loading={isSubmitting}
          onClose={handleClose}
          onSubmit={handleSubmit}
          primaryButtonLabel={intl.formatMessage({
            id: 'general.confirm',
            defaultMessage: 'Confirm',
          })}
          secondaryButtonLabel={intl.formatMessage({
            id: 'general.cancel',
            defaultMessage: 'Cancel',
          })}
        >
          <PageHeader
            variant={PAGE_HEADER_VARIANT_MEDIUM}
            title={intl.formatMessage({
              id: 'materials.clone_material_dialog.header.title',
              defaultMessage: 'Clone Material',
            })}
          />

          <Fields>
            <FormikField
              autoFocus
              component={Field}
              dataTestId="clone-material__name-field"
              disabled={isSubmitting}
              name="name"
              label={intl.formatMessage({
                id: 'materials.clone_material_dialog.material_name.input.label',
                defaultMessage: 'Display Name',
              })}
              placeholder={intl.formatMessage({
                id: 'materials.create_material_dialog.material_name.input.placeholder',
                defaultMessage: 'Untitled material',
              })}
            />
          </Fields>
        </DialogPortal>
      )}
    </Formik>
  );
};

CloneMaterialDialog.propTypes = {};

export default CloneMaterialDialog;
