import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import moment from 'moment';
import useDialog from '@hooks/useDialog';
import useWorkflowList from '@hooks/workflows/useWorkflowList';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import useProjectQueries from '@hooks/projects/useProjectQueries';
import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import ListContainer from '@components/3-organisms/ListContainer';
import { ModalDataTypes } from '@constants/modalDataTypes';

const RecentWorkflows = () => {
  const intl = useIntl();

  const { showDialog } = useDialog();
  const { getWorkflowsList } = useWorkflowList();
  const { recentWorkflowsQuery } = useWorkflowQueries();
  const { projectsQuery } = useProjectQueries();
  const { refetch: refetchProjectsQuery } = projectsQuery;
  const { refetch: refetchRecentWorkflowsQuery } = recentWorkflowsQuery;

  const workflows = useMemo(
    () => recentWorkflowsQuery.data || [],
    [recentWorkflowsQuery.data],
  );
  const sortedWorkflows = useMemo(
    () =>
      workflows.sort((leftWorkflow, rightWorkflow) =>
        moment(rightWorkflow.lastModifiedAt).diff(
          moment(leftWorkflow.lastModifiedAt),
        ),
      ),
    [workflows],
  );

  const recentWorkflows = useMemo(
    () => sortedWorkflows.slice(0, 4),
    [sortedWorkflows],
  );

  const recentWorkflowsListItems = getWorkflowsList(recentWorkflows, {
    withProjectName: true,
  });

  const isLoading =
    recentWorkflowsQuery.isLoading || refetchProjectsQuery.isLoading;
  const showEmptyState =
    !recentWorkflowsQuery.isLoading &&
    recentWorkflowsQuery.isSuccess &&
    isEmpty(recentWorkflowsListItems);
  const showRecentWorkflows =
    recentWorkflowsQuery.isLoading || !isEmpty(recentWorkflowsListItems);

  useEffect(() => {
    refetchRecentWorkflowsQuery();
    refetchProjectsQuery();
  }, [refetchRecentWorkflowsQuery, refetchProjectsQuery]);

  return (
    <>
      {showEmptyState && (
        <EmptyStateBox
          title={intl.formatMessage({
            id: 'homepage.empty_state.title',
            defaultMessage: 'Get started',
          })}
          description={intl.formatMessage({
            id: 'homepage.empty_state.description',
            defaultMessage:
              'Create a Project to to start managing manufacturing workflows',
          })}
          primaryButtonIconName="add_0"
          primaryButtonLabel={intl.formatMessage({
            id: 'homepage.empty_state.create_project',
            defaultMessage: 'Create project',
          })}
          onPrimaryButtonClick={() => showDialog(ModalDataTypes.CREATE_FLOW)}
        />
      )}

      {showRecentWorkflows && (
        <ListContainer
          loading={isLoading}
          headerTitle={intl.formatMessage({
            id: 'homepage.recent_workflows.title',
            defaultMessage: 'Recent Workflows',
          })}
          listItems={recentWorkflowsListItems}
          skeletonLength={4}
          scrollable={false}
        />
      )}
    </>
  );
};

RecentWorkflows.propTypes = {};

export default RecentWorkflows;
