import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import IconButton, {
  ICON_BUTTON_VARIANT_STANDARD,
} from '@components/1-atoms/IconButton';
import {
  Wrapper,
  StateLayer,
  Content,
  Icon,
  Label,
  EndingControls,
} from './MessageBox.styled';

export const MESSAGE_BOX_VARIANT_ERROR = 'error';
export const MESSAGE_BOX_VARIANT_MESSAGE = 'message';
export const MESSAGE_BOX_VARIANT_SNACKBAR = 'snackbar';

const MessageBox = ({
  children,
  dataTestId = 'message-box',
  endingButtonDisabled,
  endingButtonIconName,
  endingButtonTitle,
  endingIconButtonIconName,
  leadingIconName,
  noBorderRadius,
  onEndingButtonClick,
  onEndingIconButtonClick,
  variant = MESSAGE_BOX_VARIANT_MESSAGE,
}) => {
  const isSnackbarVariant = variant === MESSAGE_BOX_VARIANT_SNACKBAR;

  const variantIconName = useMemo(() => {
    let iconName = 'info_0';

    if (variant === MESSAGE_BOX_VARIANT_ERROR) {
      iconName = 'error_0';
    }

    if (isSnackbarVariant) {
      iconName = leadingIconName;
    }

    return leadingIconName ?? iconName;
  }, [isSnackbarVariant, leadingIconName, variant]);

  return (
    <Wrapper
      data-testid={dataTestId}
      variant={variant}
      withEndingIconButton={!!endingIconButtonIconName}
      withBorderRadius={!noBorderRadius}
    >
      <StateLayer variant={variant} />

      <Content withEndingIconButton={!!endingIconButtonIconName}>
        {variantIconName && <Icon variant={variant} name={variantIconName} />}

        {children && (
          <Label dataTestId={`${dataTestId}__label`} variant={variant}>
            {children}
          </Label>
        )}
      </Content>

      {(endingButtonTitle || endingIconButtonIconName) && (
        <EndingControls>
          {endingButtonTitle && (
            <Button
              dataTestId={`${dataTestId}__ending-button`}
              disabled={endingButtonDisabled}
              variant={BUTTON_VARIANT_TEXT}
              iconName={endingButtonIconName}
              onClick={onEndingButtonClick}
            >
              {endingButtonTitle}
            </Button>
          )}

          {endingIconButtonIconName && (
            <IconButton
              dataTestId={`${dataTestId}__ending-icon-button`}
              variant={ICON_BUTTON_VARIANT_STANDARD}
              iconName={endingIconButtonIconName}
              onClick={onEndingIconButtonClick}
            />
          )}
        </EndingControls>
      )}
    </Wrapper>
  );
};

MessageBox.propTypes = {
  dataTestId: PropTypes.string,
  endingButtonDisabled: PropTypes.bool,
  endingButtonIconName: PropTypes.string,
  endingButtonTitle: PropTypes.string,
  endingIconButtonIconName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  leadingIconName: PropTypes.string,
  noBorderRadius: PropTypes.bool,
  onEndingButtonClick: PropTypes.func,
  onEndingIconButtonClick: PropTypes.func,
  variant: PropTypes.oneOf([
    MESSAGE_BOX_VARIANT_ERROR,
    MESSAGE_BOX_VARIANT_MESSAGE,
    MESSAGE_BOX_VARIANT_SNACKBAR,
  ]),
};

export default MessageBox;
