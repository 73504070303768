class DateFormatter {
  static formatIso(isoDate) {
    const date = new Date(isoDate);
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }
}

export default DateFormatter;
