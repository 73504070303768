import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import { isObject } from 'lodash';
import useSteps from '@hooks/useSteps';
import useSnackbar from '@hooks/useSnackbar';
import useDialog from '@hooks/useDialog';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import useProject from '@hooks/projects/useProject';
import useProjectMutations from '@hooks/projects/useProjectMutations';
import useWorkflowMutations from '@hooks/workflows/useWorkflowMutations';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { Directories } from '@constants/directories';
import { ROUTES } from '@constants/router';
import DialogPortal from '@components/2-molecules/DialogPortal';
import SelectProject from '@containers/Create/SelectProject';
import CreateProject from '@containers/Create/CreateProject';
import CreateWorkflow from '@containers/Create/CreateWorkflow';

const SELECT_PROJECT_STEP = 'selectProject';
const CREATE_PROJECT_STEP = 'project';
const CREATE_WORKFLOW_STEP = 'workflow';

const CREATE_PROJECT_STEPS = [
  SELECT_PROJECT_STEP,
  CREATE_PROJECT_STEP,
  CREATE_WORKFLOW_STEP,
];

const MODAL_ID = ModalDataTypes.CREATE_FLOW;

const CreateFlowDialog = () => {
  const intl = useIntl();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { hideDialog, getDialogData } = useDialog();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const {
    startFromSelectWorkflow,
    startFromCreateWorkflow,
    selectedProjectId,
    selectedWorkflow,
    duplicateFlow,
    shareFlow,
    shareFlowData = {},
    handleErrorResponse,
  } = dialogData;
  const { printersQuery, allNozzlesQuery, materialsQuery } =
    usePrinterQueries();
  const { getProject, getProjects } = useProject();
  const { createProjectMutation } = useProjectMutations();
  const {
    createWorkflowMutation,
    duplicateWorkflowMutation,
    importWorkflowMutation,
  } = useWorkflowMutations();

  const isLoading =
    printersQuery?.isFetching ||
    allNozzlesQuery.isFetching ||
    materialsQuery.isFetching;

  const stepsConfigs = useMemo(() => {
    if (startFromCreateWorkflow) {
      const createWorkflowSteps = [CREATE_WORKFLOW_STEP];

      return {
        steps: createWorkflowSteps,
        initialStepNumber: createWorkflowSteps.indexOf(CREATE_WORKFLOW_STEP),
      };
    }

    return {
      steps: CREATE_PROJECT_STEPS,
      initialStepNumber: CREATE_PROJECT_STEPS.indexOf(
        startFromSelectWorkflow ? SELECT_PROJECT_STEP : CREATE_PROJECT_STEP,
      ),
    };
  }, [startFromSelectWorkflow, startFromCreateWorkflow]);

  const { goTo, goPrev, goNext, isStepActive, currentStepName, isLastStep } =
    useSteps(stepsConfigs);

  const lastStepIsActive = useMemo(() => isLastStep(), [isLastStep]);

  const primaryButtonLabel = useMemo(() => {
    if (lastStepIsActive) {
      return intl.formatMessage({
        id: 'general.confirm',
        defaultMessage: 'Confirm',
      });
    }

    if (isStepActive(CREATE_PROJECT_STEP)) {
      return intl.formatMessage({
        id: 'general.create',
        defaultMessage: 'Create',
      });
    }

    return intl.formatMessage({
      id: 'general.next',
      defaultMessage: 'Next',
    });
  }, [lastStepIsActive, isStepActive, intl]);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const showBackButton = useMemo(() => {
    if (startFromCreateWorkflow) {
      return false;
    }

    const stepsWithoutBackButton = [SELECT_PROJECT_STEP, CREATE_PROJECT_STEP];

    return !stepsWithoutBackButton.includes(currentStepName);
  }, [startFromCreateWorkflow, currentStepName]);

  const defaultProjectValue = useMemo(() => {
    if (!selectedProjectId) {
      return null;
    }

    let project = getProject(selectedProjectId);

    if (!project) {
      project = getProjects()?.find(
        (project) => project.id === selectedProjectId,
      );
    }

    return project;
  }, [selectedProjectId, getProject, getProjects]);

  const defaultValues = useMemo(() => {
    if (selectedWorkflow) {
      return {
        project: defaultProjectValue,
        workflowName: `${selectedWorkflow?.name}_copy`,
        printer: selectedWorkflow?.printerId,
        nozzle: selectedWorkflow?.nozzleId,
        material: selectedWorkflow?.materialId,
      };
    }

    return {
      project: defaultProjectValue,
    };
  }, [defaultProjectValue, selectedWorkflow]);

  const form = useForm({
    mode: 'all',
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
      keepTouched: true,
    },
  });
  const { setValue, watch, formState, trigger, reset, getValues } = form;
  const selectedProject = watch('project');

  const handleGoBack = useCallback(() => {
    if (isStepActive(CREATE_WORKFLOW_STEP) && startFromSelectWorkflow) {
      goTo(SELECT_PROJECT_STEP);
      return;
    }

    goPrev();
  }, [startFromSelectWorkflow, isStepActive, goPrev, goTo]);

  const handleGoForward = useCallback(() => {
    if (startFromSelectWorkflow && isStepActive(SELECT_PROJECT_STEP)) {
      goTo(CREATE_WORKFLOW_STEP);

      return;
    }

    goNext();
  }, [startFromSelectWorkflow, isStepActive, goNext, goTo]);

  const saveFlow = useCallback(
    async (values) => {
      const { projectName, project, workflowName, printer, nozzle, material } =
        values;

      let selectedProject = null;

      if (isObject(project)) {
        selectedProject = project;
      } else {
        selectedProject = await createProjectMutation.mutateAsync(projectName);

        setValue('project', selectedProject);
      }

      let workflow;

      if (duplicateFlow) {
        workflow = await duplicateWorkflowMutation.mutateAsync({
          name: workflowName,
          projectId: selectedProject?.id,
          id: selectedWorkflow?.id,
          printerId: printer,
          nozzleId: nozzle,
          materialId: material,
        });
      } else if (shareFlow) {
        await importWorkflowMutation.mutateAsync({
          sentDataId: shareFlowData?.sentDataId,
          name: workflowName,
          projectId: selectedProject?.id,
          printerId: printer,
          nozzleId: nozzle,
          materialId: material,
        });

        handleClose();

        const navigationPath = generatePath(ROUTES.WORKFLOWS, {
          workspaceId: selectedProject?.id,
          directory: Directories.workflows,
        });

        history.push(navigationPath);

        showSnackbar({
          text: intl.formatMessage(
            {
              id: 'create_flow_dialog.snackbar.imported',
              defaultMessage:
                'Your request to add a {name} has been submitted. You will receive a confirmation email shortly',
            },
            {
              name: workflowName,
            },
          ),
        });

        return;
      } else {
        workflow = await createWorkflowMutation.mutateAsync({
          name: workflowName,
          printerId: printer,
          operators: [],
          workspaceId: selectedProject.id,
          nozzleId: nozzle,
          materialId: material,
        });
      }

      const navigationPath = generatePath(ROUTES.WORKFLOW, {
        workspaceId: selectedProject.id,
        directory: Directories.workflows,
        itemId: workflow?.id,
      });

      history.push(navigationPath);
    },
    [
      intl,
      createProjectMutation,
      createWorkflowMutation,
      duplicateWorkflowMutation,
      importWorkflowMutation,
      history,
      setValue,
      selectedWorkflow?.id,
      shareFlow,
      shareFlowData?.sentDataId,
      duplicateFlow,
      showSnackbar,
      handleClose,
    ],
  );

  const onFormSubmit = useCallback(
    async (values) => {
      if (lastStepIsActive) {
        try {
          await saveFlow(values);
        } catch (error) {
          handleErrorResponse?.(error?.response?.status);
        }

        return;
      }

      const areFetchSteps = [SELECT_PROJECT_STEP, CREATE_PROJECT_STEP].includes(
        currentStepName,
      );

      if (!areFetchSteps) {
        handleGoForward();
        return;
      }

      if (!printersQuery.isFetched) {
        await printersQuery.refetch();
      }

      if (!allNozzlesQuery?.isFetched) {
        await allNozzlesQuery.refetch();
      }

      if (!materialsQuery?.isFetched) {
        await materialsQuery.refetch();
      }

      handleGoForward();
    },
    [
      lastStepIsActive,
      saveFlow,
      currentStepName,
      handleGoForward,
      printersQuery,
      allNozzlesQuery,
      materialsQuery,
      handleErrorResponse,
    ],
  );

  const handleCreateNewProject = useCallback(() => {
    setValue('project', null);

    goTo(CREATE_PROJECT_STEP);
  }, [goTo, setValue]);

  useEffect(() => {
    trigger();
  }, [currentStepName, trigger]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(getValues(), {
        keepDirtyValues: true,
        keepErrors: true,
        keepTouched: true,
      });
    }
  }, [formState.isSubmitSuccessful, reset, getValues]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <form onSubmit={form.handleSubmit(onFormSubmit)}>
      <DialogPortal
        renderAsForm
        dataTestId="create-flow"
        dialogId={MODAL_ID}
        loading={formState?.isSubmitting || isLoading}
        onBackIconButtonClick={showBackButton ? handleGoBack : undefined}
        onClose={handleClose}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={intl.formatMessage({
          id: 'general.cancel',
          defaultMessage: 'Cancel',
        })}
      >
        <FormProvider {...form}>
          {isStepActive(SELECT_PROJECT_STEP) && (
            <SelectProject handleCreateNewProject={handleCreateNewProject} />
          )}

          {isStepActive(CREATE_PROJECT_STEP) && <CreateProject />}

          {isStepActive(CREATE_WORKFLOW_STEP) && (
            <CreateWorkflow
              fieldAutoFocus={isStepActive(CREATE_WORKFLOW_STEP)}
              workflow={selectedWorkflow}
              project={selectedProject}
            />
          )}
        </FormProvider>
      </DialogPortal>
    </form>
  );
};

CreateFlowDialog.propTypes = {};

export default CreateFlowDialog;
