import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import useDialog from '@hooks/useDialog';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';
import useProjectMutations from '@hooks/projects/useProjectMutations';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import { getCurrentUser } from '@selectors/loginSelectors';
import { ModalDataTypes } from '@app/constants/modalDataTypes';
import useSnackbar from '@hooks/useSnackbar';
import { SEND_WORKFLOW_SENDER_FLOW_FEATURE } from '@constants/featureFlagConstants';

export default function useProjectList() {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const user = useSelector(getCurrentUser());
  const { getProjectWorkflowsQueryOptions } = useWorkflowQueries();
  const { updateProjectMutation, deleteProjectMutation } =
    useProjectMutations();
  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const isWorkflowSenderFlowEnabled = useFeatureFlagValue(
    SEND_WORKFLOW_SENDER_FLOW_FEATURE,
  );

  const getProjectDropDownMenuActions = useCallback(
    (project = {}) => {
      const isUserInOrganization =
        project?.organization === user?.organizationId;
      const isPublic = project?.publicAccess;

      if (!isUserInOrganization) return [];

      let actions = [
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_PROJECT, {
              projectId: project.id,
              projectName: project.name,
              projectIsPublic: isPublic,
              userOrganization: user?.organizationId,
            }),
        },
        {
          leadingIconName: 'language_0',
          label: intl.formatMessage({
            id: 'general.public',
            defaultMessage: 'Public',
          }),
          endingButton: {
            type: 'switch',
            enabled: isPublic,
            onChange: () => {
              updateProjectMutation.mutate({
                id: project.id,
                name: project.name,
                organization: user?.organizationId,
                public: !isPublic,
              });
            },
          },
        },
      ];

      if (isWorkflowSenderFlowEnabled) {
        actions.push({
          leadingIconName: 'send_0',
          label: intl.formatMessage({
            id: 'workflow.more_actions.send_copy.label',
            defaultMessage: 'Send copy',
          }),
          onClick: async () => {
            const allowedWorkflowsLength = 5;
            const projectWorkflows = await queryClient.fetchQuery(
              getProjectWorkflowsQueryOptions(project?.id, true),
            );
            const isWorkflowLimitExceeded =
              projectWorkflows?.length > allowedWorkflowsLength;

            if (isWorkflowLimitExceeded) {
              showDialog(ModalDataTypes.PROMPT, {
                dataTestId: 'unable-to-send-copy-dialog',
                title: intl.formatMessage({
                  id: 'sendcopy.dialog.export_limit_exceeded.error_dialog.title',
                  defaultMessage: 'Export Limit Exceeded',
                }),
                subtitle: intl.formatMessage(
                  {
                    id: 'sendcopy.dialog.export_limit_exceeded.error_dialog.subtitle',
                    defaultMessage:
                      "We're unable to proceed with the export as this project exceeds the maximum allowed number of workflows. To continue, please ensure your project contains no more than {allowedWorkflowsLength} workflows.",
                  },
                  {
                    allowedWorkflowsLength,
                  },
                ),
                secondaryButtonLabel: '',
                primaryButtonLabel: intl.formatMessage({
                  id: 'general.ok',
                  defaultMessage: 'OK',
                }),
              });

              return;
            }

            showDialog(ModalDataTypes.SEND_COPY, { project });
          },
        });
      }

      actions = [
        ...actions,
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              title: intl.formatMessage({
                id: 'deleteproject.dialog.title',
                defaultMessage: 'Delete Project',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'deleteproject.dialog.subtitle',
                  defaultMessage:
                    '<b>{projectName}</b> including its workflows and files will be deleted permanently. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  projectName: project?.name,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: async () => {
                try {
                  await deleteProjectMutation.mutateAsync(project?.id);
                  showSnackbar({
                    text: intl.formatMessage({
                      id: 'deleteproject.snackbar.label',
                      defaultMessage: ' Project successfully deleted',
                    }),
                  });
                } catch (_) {
                  //error
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [
      showSnackbar,
      intl,
      user?.organizationId,
      showDialog,
      updateProjectMutation,
      deleteProjectMutation,
      queryClient,
      getProjectWorkflowsQueryOptions,
      isWorkflowSenderFlowEnabled,
    ],
  );

  return {
    getProjectDropDownMenuActions,
  };
}
