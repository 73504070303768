import styled, { css } from 'styled-components';
import { when } from '@stylesheets/helpers';

export const DraggableElementWrapper = styled.div`
  width: 352px;

  & > * {
    height: 48px;
  }

  ${({ isDraggingOverTargetDroppable }) => css`
    ${when(
      isDraggingOverTargetDroppable,
      `
        width: 476px;
      `,
    )}
  `}
`;
