import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexCenter, when } from '@stylesheets/helpers';

export const Wrapper = styled.button`
  max-width: 100%;
  ${flexCenter}
  gap: 8px;
  background: none;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;

  ${({
    minWidth,
    shrink,
    error,
    dirty,
    disabled,
    theme: { borderRadius, spacing, colors },
  }) => css`
    border-radius: ${borderRadius?.full};
    padding: 10px ${spacing.level3} 10px ${spacing.level4};

    ${when(
      minWidth,
      `
        min-width: ${minWidth}px;
      `,
    )}

    ${when(
      !shrink,
      `
        flex-shrink: 0;
      `,
    )}

    &:hover {
      background-color: ${rgba(colors?.onSurfaceVariant, 0.08)};
    }

    &:focus:focus-visible {
      background-color: ${rgba(colors?.onSurfaceVariant, 0.12)};
    }

    ${when(
      dirty,
      `
        border-color: ${colors?.caution};
      `,
    )}

    ${when(
      error,
      `
        border-color: ${colors?.error};
      `,
    )}
  
    ${when(
      disabled,
      `
        pointer-events: none;
        opacity: 0.38;
      `,
    )}
  `}
`;

export const Label = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
  color: 'onSurface',
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  flex-shrink: 1;
`;

export const Icon = styled(SpriteSVG)`
  min-width: fit-content;
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors?.onSurface};
  `}
`;
