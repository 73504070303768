import { CURRENT_USER_KEY_LOCAL_STORAGE } from '@constants/utilityConstants';
import SingleFileUpload from '@components/Printers/GeneralPrinterSetting/SingleFileUpload';
import React from 'react';

export const generateSingleFileUpload = (
  entry,
  dataTestIdPrefix,
  axisKey,
  handleFile,
  { dirty = false, allowedExtensions = ['glb', 'gltf', 'obj'], fileSize = 20 },
) => {
  return (
    <SingleFileUpload
      dataTestId={`${dataTestIdPrefix}__file-${axisKey}`}
      allowedExtensions={allowedExtensions}
      fileSize={fileSize}
      key={axisKey}
      label={entry.label}
      valueFile={entry.file}
      dirty={dirty}
      handleFile={(file) => handleFile(file)}
    />
  );
};

export function doUpload(data, config, url, progressActionType, dispatch) {
  return new Promise(function (resolve, reject) {
    const currentUser = JSON.parse(
      localStorage.getItem(CURRENT_USER_KEY_LOCAL_STORAGE),
    );
    if (!currentUser) {
      reject(new Error('User not found, login again'));
      return; // Added return to avoid further execution
    }
    const token = currentUser.token;

    const request = new XMLHttpRequest();

    const baseUrl = config.baseUrl || 'http://localhost/api/';
    request.open('POST', baseUrl + url);
    request.setRequestHeader('Authorization', `Bearer ${token}`);

    if (progressActionType) {
      // upload progress event
      request.upload.addEventListener('progress', function (e) {
        const percent_completed = convertRange(
          (e.loaded / e.total) * 100,
          [0, 100],
          [0, 50],
        );
        //avoid to flood with redux state updates
        if (percent_completed >= 45 || Math.random() > 0.3) {
          dispatch({
            type: progressActionType,
            payload: { progress: percent_completed },
          });
        }
      });
    }
    // request finished event
    request.addEventListener('load', function () {
      resolve(request);
    });

    // send POST request to server
    request.send(data);
  });
}

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}
