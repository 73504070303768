import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import { Wrapper, ColorLine } from './ColorRange.styled';

const ColorRange = ({ minRangeLabel, maxRangeLabel, color, colorType }) => {
  return (
    <Wrapper>
      {!isUndefined(minRangeLabel) && (
        <Text variant={TEXT_VARIANT_LABEL_LARGE} color="onSurface">
          {minRangeLabel}
        </Text>
      )}

      <ColorLine color={color} colorType={colorType} />

      {!isUndefined(maxRangeLabel) && (
        <Text variant={TEXT_VARIANT_LABEL_LARGE} color="onSurface">
          {maxRangeLabel}
        </Text>
      )}
    </Wrapper>
  );
};

ColorRange.propTypes = {
  minRangeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRangeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  colorType: PropTypes.oneOf([
    'default',
    'layers',
    'thickness',
    'analysis',
    'layersUniform',
    'thicknessUniform',
    'analysisUniform',
  ]),
};

export default ColorRange;
