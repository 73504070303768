import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import MessageBox, {
  MESSAGE_BOX_VARIANT_ERROR,
} from '@components/2-molecules/MessageBox';
import Field from '@components/1-atoms/Field';
import Button from '@components/1-atoms/Button';
import { Wrapper } from './LoginUserName.styled';

const LoginUserName = ({
  dataTestId = 'login-page-user-name',
  className,
  handleFieldBlur,
  handleFieldChange,
  isSubmitting,
  loginFailed,
  loginTokenExpired,
  password,
  passwordError,
  passwordErrorMessage,
  passwordTouched,
  username,
  usernameError,
  usernameErrorMessage,
  usernameTouched,
}) => {
  const intl = useIntl();
  const [revealPassword, setRevealPassword] = useState(false);

  const showLoginErrorMessage = loginFailed || loginTokenExpired;
  const showUsernameErrorMessage = usernameTouched && usernameError;
  const showPasswordErrorMessage = passwordTouched && passwordError;

  const onPasswordRevealClick = useCallback(() => {
    setRevealPassword(!revealPassword);
  }, [revealPassword]);

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'general.login',
          defaultMessage: 'Log in',
        })}
      />

      {showLoginErrorMessage && (
        <MessageBox
          dataTestId={`${dataTestId}__login-failed-message-box`}
          variant={MESSAGE_BOX_VARIANT_ERROR}
          leadingIconName="error_0"
        >
          {loginFailed && (
            <>
              <FormattedMessage
                id="loginpage.login_failed.invalid_username_or_password"
                defaultMessage="Invalid username or password."
              />
              <br />
              <FormattedMessage
                id="loginpage.login_failed.please_try_again"
                defaultMessage="Please try again."
              />
            </>
          )}

          {loginTokenExpired && (
            <>
              <FormattedMessage
                id="loginpage.login_failed.session_expired"
                defaultMessage="Your session has expired."
              />
              <br />
              <FormattedMessage
                id="loginpage.login_failed.please_log_in_again"
                defaultMessage="Please log in again."
              />
            </>
          )}
        </MessageBox>
      )}

      <Field
        name="username"
        dataTestId={`${dataTestId}__username-field`}
        placeholder={intl.formatMessage({
          id: 'loginpage.login_username',
          defaultMessage: 'Username',
        })}
        value={username}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        error={showUsernameErrorMessage}
        supportingText={showUsernameErrorMessage && usernameErrorMessage}
      />

      <Field
        type={revealPassword ? 'text' : 'password'}
        name="password"
        dataTestId={`${dataTestId}__password-field`}
        placeholder={intl.formatMessage({
          id: 'loginpage.login_password',
          defaultMessage: 'Password',
        })}
        value={password}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        endingButtonIconName={
          revealPassword ? 'visibility_on_1' : 'visibility_off_0'
        }
        onEndingButtonClick={onPasswordRevealClick}
        error={showPasswordErrorMessage}
        supportingText={showPasswordErrorMessage && passwordErrorMessage}
      />

      <Button
        dataTestId={`${dataTestId}__submit-button`}
        type="submit"
        loading={isSubmitting}
      >
        <FormattedMessage id="general.login" defaultMessage="Log in" />
      </Button>
    </Wrapper>
  );
};

LoginUserName.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  handleFieldBlur: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  loginFailed: PropTypes.bool,
  loginTokenExpired: PropTypes.bool,
  password: PropTypes.string,
  passwordError: PropTypes.bool,
  passwordErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  passwordTouched: PropTypes.bool,
  username: PropTypes.string,
  usernameError: PropTypes.bool,
  usernameErrorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  usernameTouched: PropTypes.bool,
};

export default LoginUserName;
