import styled from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${flexColumn}
  background: ${({ theme }) => theme.colors.surface};
  position: fixed;
  top: 0;
  left: 0;
`;
