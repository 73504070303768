export const addToLocalStorage = (name = 'aisync', data = {}) => {
  const stringifiedData = JSON.stringify(data);

  localStorage.setItem(name, stringifiedData);
};

export const removeFromLocalStorage = (name = 'aisync') => {
  localStorage.removeItem(name);
};

export const getFromLocalStorage = (name = 'aisync') => {
  const data = localStorage.getItem(name);

  if (!data) return;

  try {
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (e) {
    return data;
  }
};
