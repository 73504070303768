import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import SVG from '@components/1-atoms/SVG';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import Text, {
  TEXT_VARIANT_LABEL_LARGE,
  TEXT_VARIANT_TITLE_SMALL,
} from '@components/1-atoms/Text';
import { flexColumn, flexCenterVertical, when } from '@stylesheets/helpers';

export const RouterLink = styled(NavLink)`
  ${flexCenterVertical}
  text-decoration: none;
`;

export const Wrapper = styled.div`
  ${flexColumn}
  height: 100%;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level4};
  `}
`;

export const Header = styled.div`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level4};
  `}
`;

export const Logo = styled(SVG).attrs(({ theme: { isLightTheme } }) => ({
  name: isLightTheme ? 'logo-white' : 'logo',
}))`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`;

export const Navigation = styled.nav`
  ${flexColumn}
  gap: 8px;
  overflow: auto;
  scrollbar-gutter: stable;
`;

export const NavigationGroupName = styled(Text).attrs({
  variant: TEXT_VARIANT_TITLE_SMALL,
  color: 'onSurfaceVariant',
})`
  ${flexCenterVertical}

  ${({ theme: { spacing } }) => css`
    padding: calc(${spacing.level5} + 2px) ${spacing.level5};
  `}
`;

export const NavigationItemStateLayer = styled.span`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
`;

export const NavigationItemIcon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${({ theme: { colors } }) => css`
    fill: ${colors.onSurface};
  `}
`;

export const NavigationItemLabel = styled(Text).attrs({
  variant: TEXT_VARIANT_LABEL_LARGE,
})`
  ${({ theme: { colors } }) => css`
    color: ${colors.onSurfaceVariant};
  `}
`;

export const NavigationItemWrapper = styled.div`
  ${flexColumn}
  gap: 8px;
`;

const navigationItemStyle = css`
  ${flexCenterVertical}
  flex: 1 0 0;
  align-self: stretch;
  position: relative;

  ${({ theme: { borderRadius, spacing } }) => css`
    border-radius: ${borderRadius.full};
    gap: ${spacing.level4};
    padding: ${spacing.level5} ${spacing.level6} ${spacing.level5}
      ${spacing.level5};
  `}
  &:hover {
    ${NavigationItemStateLayer} {
      visibility: visible;

      ${({ theme: { colors } }) => css`
        background-color: ${colors.onSurface};
        opacity: 0.08;
      `}
    }
  }

  &:focus {
    ${NavigationItemStateLayer} {
      visibility: visible;

      ${({ theme: { colors } }) => css`
        background-color: ${colors.onSurface};
        opacity: 0.12;
      `}
    }
  }

  &.active {
    background-color: transparent;

    ${({ theme: { colors } }) => css`
      background-color: ${colors.secondaryContainer};
    `}
    ${NavigationItemStateLayer} {
      visibility: visible;

      ${({ theme: { colors } }) => css`
        background-color: ${colors.onSecondaryContainer};
        opacity: 0.12;
      `}
    }

    ${NavigationItemIcon} {
      ${({ theme: { colors } }) => css`
        fill: ${colors.onSecondaryContainer};
      `}
    }

    ${NavigationItemLabel} {
      ${({ theme: { colors } }) => css`
        color: ${colors.onSecondaryContainer};
      `}
    }
  }
`;
export const CustomNavigationItem = styled.div`
  ${navigationItemStyle}

  ${({ onClick }) => css`
    ${when(
      onClick,
      `
      cursor: pointer;
      `,
    )}
  `}
`;

export const NavigationItem = styled(RouterLink)`
  ${navigationItemStyle}
`;
