export const colorPalette = [
  '#d3004f',
  '#5a64a9',
  '#ffdacd',
  '#c8004c',
  '#8a91a4',
  '#9aa0a2',
  '#ffc7c3',
  '#ffedd7',
  '#3843ad',
  '#727aa6',
  '#ff89a4',
  '#ff7198',
  '#babf9e',
  '#fff6db',
  '#ff4481',
  '#ff94aa',
  '#b2b79f',
  '#d8ffff',
  '#7a82a5',
  '#ff9faf',
  '#ff6491',
  '#de0053',
  '#ffbdbf',
  '#ffa9b4',
  '#f4005a',
  '#a2a8a1',
  '#e3e59a',
  '#eced99',
  '#ffe3d2',
  '#ff0360',
  '#ff2e75',
  '#828aa4',
  '#ffb4ba',
  '#ff558a',
  '#ff7e9e',
  '#aaafa0',
  '#d3d69c',
  '#525caa',
  '#414cac',
  '#c2c69d',
  '#5e5e5e',
  '#dbde9b',
  '#6a73a7',
  '#4954ab',
  '#f4f598',
  '#e90057',
  '#cbce9c',
  '#626ba8',
  '#9299a3',
  '#ffd1c8',
];
