import * as THREE from 'three';
import { printerConstants as constants } from '../../constants/printers/printerConstants';
import { Printer } from '../Printer';
import Enclosure from './Enclosure';
import { s3Directories } from '../../constants/printers/s3Directories';
import { getEnclosureDefinitionResponse } from './EnclosureUtils';

class EnclosureCustom extends Enclosure {
  constructor(
    printerSettings,
    machineDefaults,
    enclosureType,
    enclosureDefinitions,
  ) {
    super(printerSettings, machineDefaults, constants.enclosure);

    this.enclosureType = enclosureType;
    this.enclosureSettings = enclosureDefinitions.find(
      (item) => item.displayName == this.enclosureType,
    );
  }

  initializeModels() {
    const enclosureDefinitionResponse = getEnclosureDefinitionResponse(
      this.printerSettings,
    );
    return new Promise((resolve) => {
      if (!enclosureDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = enclosureDefinitionResponse;

      const enclosureURL = modelUrls[s3Directories.enclosureModel];

      Promise.resolve(Printer.getModel(enclosureURL)).then((model) => {
        this.add(model);
        resolve();
      });
    });
  }
}

export default EnclosureCustom;
