import React from 'react';
import useModal from '@hooks/useModal';
import ModalPortal from '@components/2-molecules/ModalPortal';
import { MODAL_IDS } from '@constants/modalDataTypes';
import VisualTool from '@containers/WorkflowVisualTool/VisualTool';

const MODAL_ID = MODAL_IDS.VISUAL_TOOL;

export default function VisualToolModal() {
  const { getModalData } = useModal();
  const modalData = getModalData(MODAL_ID);
  const { form } = modalData;

  return (
    <ModalPortal
      dataTestId="visual-tool-modal"
      modalId={MODAL_ID}
      floatingHeader
      disableContentPadding
    >
      <VisualTool form={form} />
    </ModalPortal>
  );
}
