import { SCREEN_SIZE } from './screen';

const breakpoints = {
  [SCREEN_SIZE.SMALL]: 0,
  [SCREEN_SIZE.MEDIUM]: 840,
  [SCREEN_SIZE.LARGE]: 1024,
  [SCREEN_SIZE.XLARGE]: 1280,
};

export default breakpoints;
