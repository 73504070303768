import React, { useContext } from 'react';
import { VisualizationContext } from '@contexts/VisualizationContext';

/* eslint react/no-unknown-property: 0 */

export default function Printer() {
  const { printer } = useContext(VisualizationContext);

  if (!printer) return null;

  return (
    <mesh>
      <primitive object={printer} />
    </mesh>
  );
}
