import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useQueryClient,
  useIsFetching,
  useIsMutating,
} from '@tanstack/react-query';
import { getIsFetchesInProgress } from '@selectors/generalSelectors';
import { getIsAnalyticsLoading } from '@selectors/analyticsSelectors';

export default function useApplication() {
  const queryClient = useQueryClient();
  const isFetching = useSelector(getIsFetchesInProgress());
  const isQueryFetching = useIsFetching({
    queryKey: ['showLoader'],
    exact: false,
  });
  const isMutating = useIsMutating({
    mutationKey: ['showLoader'],
    exact: false,
  });
  const isAnalyticsLoading = useSelector(getIsAnalyticsLoading());

  const getIsApplicationLoading = useCallback(
    () =>
      [
        isFetching,
        isAnalyticsLoading,
        !!isQueryFetching,
        !!isMutating,
      ].includes(true),
    [isFetching, isAnalyticsLoading, isQueryFetching, isMutating],
  );

  const cleanQueryClient = useCallback(() => {
    queryClient.clear();
  }, [queryClient]);

  return {
    getIsApplicationLoading,
    cleanQueryClient,
  };
}
