export const plinthTypeDefinitions = {
  STATIC_PLINTH: {
    value: 'STATIC_PLINTH',
    label: 'Static',
  },
  RAIL_PLINTH: {
    value: 'RAIL_PLINTH',
    label: 'Linear',
  },
};

export const defaultStaticProperties = {
  height: '',
};

export const defaultLinearProperties = {
  height: '',
  originValue: '',
  maxSpeed: '',
  endValue: '',
};
