import React, { createContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION_TOP_LEFT } from '@components/2-molecules/Tooltip';

const TooltipContext = createContext();

const initialAnchorElement = null;
const initialTooltipIsOpen = false;
const initialTooltipId = null;
const initialTooltipData = {};
const initialTooltipStyle = {
  position: 'fixed',
  opacity: 0,
  top: -500,
};
const initialTooltipPosition = TOOLTIP_POSITION_TOP_LEFT;

export const TooltipProvider = ({ children }) => {
  const [anchorElement, setAnchorElement] = useState(initialAnchorElement);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(initialTooltipIsOpen);
  const [tooltipId, setTooltipId] = useState(initialTooltipId);
  const [tooltipData, setTooltipData] = useState(initialTooltipData);
  const [tooltipStyle, setTooltipStyle] = useState(initialTooltipStyle);
  const [tooltipPosition, setTooltipPosition] = useState(
    initialTooltipPosition,
  );

  const resetTooltipData = useCallback(
    () => setTooltipData(initialTooltipData),
    [],
  );

  const updateTooltipData = useCallback(
    (data = {}) => {
      if (!tooltipData) return;

      setTooltipData((prevTooltipData) => ({
        ...prevTooltipData,
        ...(data || {}),
      }));
    },
    [tooltipData],
  );

  const resetTooltip = useCallback(() => {
    setAnchorElement(initialAnchorElement);
    setTooltipIsOpen(initialTooltipIsOpen);
    setTooltipId(initialTooltipId);
    setTooltipData(initialTooltipData);
    setTooltipStyle(initialTooltipStyle);
    setTooltipPosition(initialTooltipPosition);
  }, [
    setTooltipData,
    setTooltipId,
    setTooltipIsOpen,
    setTooltipStyle,
    setTooltipPosition,
  ]);

  const value = useMemo(
    () => ({
      anchorElement,
      tooltipData,
      tooltipId,
      tooltipIsOpen,
      tooltipPosition,
      tooltipStyle,
      resetTooltip,
      resetTooltipData,
      setAnchorElement,
      setTooltipData,
      setTooltipId,
      setTooltipIsOpen,
      setTooltipPosition,
      setTooltipStyle,
      updateTooltipData,
    }),
    [
      anchorElement,
      tooltipData,
      tooltipId,
      tooltipIsOpen,
      tooltipPosition,
      tooltipStyle,
      resetTooltip,
      resetTooltipData,
      setAnchorElement,
      setTooltipData,
      setTooltipId,
      setTooltipIsOpen,
      setTooltipPosition,
      setTooltipStyle,
      updateTooltipData,
    ],
  );

  return (
    <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>
  );
};

TooltipProvider.propTypes = {
  children: PropTypes.any,
};

export default TooltipContext;
