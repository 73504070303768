import React, { useCallback } from 'react';
import useModal from '@hooks/useModal';
import useDialog from '@hooks/useDialog';
import WelcomeModalContent from '@components/3-organisms/WelcomeModal';
import ModalPortal from '@components/2-molecules/ModalPortal';
import { ICON_BUTTON_VARIANT_PLAIN } from '@components/1-atoms/IconButton';
import { MODAL_IDS, ModalDataTypes } from '@constants/modalDataTypes';
import { addToLocalStorage } from '@utils/localStorage';
import { HIDE_WELCOME_MODAL_KEY } from '@constants/utilityConstants';

const MODAL_ID = MODAL_IDS.WELCOME;

const WelcomeModal = () => {
  const { showDialog } = useDialog();
  const { hideModal } = useModal();

  const handleClose = useCallback(() => {
    hideModal(MODAL_ID);
    addToLocalStorage(HIDE_WELCOME_MODAL_KEY, true);
  }, [hideModal]);

  const onPrimaryButtonClick = useCallback(() => {
    showDialog(ModalDataTypes.YOUTUBE_VIDEO, {
      videoId: '10TrMvIdCfA',
    });
  }, [showDialog]);

  return (
    <ModalPortal
      dataTestId="welcome-modal"
      modalId={MODAL_ID}
      headerEndingIconButtonVariant={ICON_BUTTON_VARIANT_PLAIN}
      floatingHeader
      floatingFooter
      noFooterBackground
      disableContentPadding
      onClose={handleClose}
    >
      <WelcomeModalContent
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={handleClose}
      />
    </ModalPortal>
  );
};

WelcomeModal.propTypes = {};

export default WelcomeModal;
