import React from 'react';
import PropTypes from 'prop-types';
import { StyledText } from './Text.styled';

export const TEXT_VARIANT_DISPLAY_LARGE = 'displayLarge';
export const TEXT_VARIANT_DISPLAY_MEDIUM = 'displayMedium';
export const TEXT_VARIANT_DISPLAY_SMALL = 'displaySmall';
export const TEXT_VARIANT_HEADLINE_LARGE = 'headlineLarge';
export const TEXT_VARIANT_HEADLINE_MEDIUM = 'headlineMedium';
export const TEXT_VARIANT_HEADLINE_SMALL = 'headlineSmall';
export const TEXT_VARIANT_TITLE_LARGE = 'titleLarge';
export const TEXT_VARIANT_TITLE_MEDIUM = 'titleMedium';
export const TEXT_VARIANT_TITLE_SMALL = 'titleSmall';
export const TEXT_VARIANT_LABEL_LARGE = 'labelLarge';
export const TEXT_VARIANT_LABEL_MEDIUM = 'labelMedium';
export const TEXT_VARIANT_LABEL_SMALL = 'labelSmall';
export const TEXT_VARIANT_BODY_LARGE = 'bodyLarge';
export const TEXT_VARIANT_BODY_MEDIUM = 'bodyMedium';
export const TEXT_VARIANT_BODY_SMALL = 'bodySmall';

const tagsSchema = {
  [TEXT_VARIANT_DISPLAY_LARGE]: 'h4',
  [TEXT_VARIANT_DISPLAY_MEDIUM]: 'h2',
  [TEXT_VARIANT_DISPLAY_SMALL]: 'h3',
  [TEXT_VARIANT_HEADLINE_LARGE]: 'h4',
  [TEXT_VARIANT_HEADLINE_MEDIUM]: 'h5',
  [TEXT_VARIANT_HEADLINE_SMALL]: 'h6',
  [TEXT_VARIANT_TITLE_LARGE]: 'h6',
  [TEXT_VARIANT_TITLE_MEDIUM]: 'h6',
  [TEXT_VARIANT_TITLE_SMALL]: 'h6',
  [TEXT_VARIANT_LABEL_LARGE]: 'span',
  [TEXT_VARIANT_LABEL_MEDIUM]: 'span',
  [TEXT_VARIANT_LABEL_SMALL]: 'span',
  [TEXT_VARIANT_BODY_LARGE]: 'p',
  [TEXT_VARIANT_BODY_MEDIUM]: 'p',
  [TEXT_VARIANT_BODY_SMALL]: 'p',
};

const Text = ({
  children,
  className,
  color,
  dataTestId = 'text',
  id,
  title,
  variant = TEXT_VARIANT_BODY_MEDIUM,
}) => {
  return (
    <StyledText
      as={tagsSchema[variant] || tagsSchema.TEXT_VARIANT_LABEL_MEDIUM}
      className={className}
      color={color}
      data-testid={dataTestId}
      id={id}
      title={title}
      variant={variant}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    TEXT_VARIANT_DISPLAY_LARGE,
    TEXT_VARIANT_DISPLAY_MEDIUM,
    TEXT_VARIANT_DISPLAY_SMALL,
    TEXT_VARIANT_HEADLINE_LARGE,
    TEXT_VARIANT_HEADLINE_MEDIUM,
    TEXT_VARIANT_HEADLINE_SMALL,
    TEXT_VARIANT_TITLE_LARGE,
    TEXT_VARIANT_TITLE_MEDIUM,
    TEXT_VARIANT_TITLE_SMALL,
    TEXT_VARIANT_LABEL_LARGE,
    TEXT_VARIANT_LABEL_MEDIUM,
    TEXT_VARIANT_LABEL_SMALL,
    TEXT_VARIANT_BODY_LARGE,
    TEXT_VARIANT_BODY_MEDIUM,
    TEXT_VARIANT_BODY_SMALL,
  ]),
};

export default Text;
