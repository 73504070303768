import styled, { css } from 'styled-components';
import { when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  max-width: 600px;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.2s 0.15s,
    visibility 0.2s 0.15s;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  ${({
    bottomCenter,
    bottomLeft,
    bottomRight,
    show,
    topCenter,
    topLeft,
    topRight,
  }) => css`
    ${({ theme: { borderRadius, spacing } }) => `
      border-radius: ${borderRadius.small};
      margin: ${spacing.level6};
    `}

    ${when(
      show,
      `
        opacity: 1;
        visibility: visible;
        transition: transform 0.3s ease-in-out;
      `,
    )}

    ${when(
      topLeft || topRight,
      css`
        top: 0;
        transform: translateY(-100%);

        ${when(
          show,
          `
            transform: translateY(0);
          `,
        )}
      `,
    )}

    ${when(
      bottomLeft || bottomRight,
      css`
        bottom: 0;
        transform: translateY(100%);

        ${when(
          show,
          `
            transform: translateY(0);
          `,
        )}
      `,
    )}

    ${when(
      topCenter,
      css`
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);

        ${when(
          show,
          `
            transform: translate(-50%, 0);
          `,
        )}
      `,
    )}

    ${when(
      bottomCenter,
      css`
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);

        ${when(
          show,
          `
            transform: translate(-50%, 0);
          `,
        )}
      `,
    )}

    ${when(
      topLeft || bottomLeft,
      css`
        left: 0;
      `,
    )}

    ${when(
      topRight || bottomRight,
      css`
        right: 0;
      `,
    )}
  `}
`;
