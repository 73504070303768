import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '@hoc/withTooltip';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  Icon,
  InfoText,
} from './SettingInfo.styled';

const TextWithTooltip = withTooltip(Text);

const SettingInfo = ({
  className,
  dataTestId = 'setting-info',
  infoIconName,
  infoText = '',
  label,
  labelTooltip = {},
  withIdentation,
}) => {
  return (
    <Wrapper
      dataTestId={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn>
        {infoIconName && <Icon name={infoIconName} />}

        <InfoText dataTestId={`${dataTestId}__text`}>{infoText}</InfoText>
      </RightColumn>
    </Wrapper>
  );
};

SettingInfo.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  infoIconName: PropTypes.string,
  infoText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
};

export default SettingInfo;
