import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

import { Wrapper } from '@components/2-molecules/SettingDropDown/SettingDropDown.styled';

export const PostProcessorSettingWrapper = styled(Wrapper)`
  ${({ noPaddingBottom, theme: { spacing } }) => css`
    ${when(
      noPaddingBottom,
      `
        padding: ${spacing.level3} ${spacing.level5} 0 ${spacing.level5};
      `,
    )}
  `}
  ${({ noPaddingTop, theme: { spacing } }) => css`
    ${when(
      noPaddingTop,
      `
        padding: 0 ${spacing.level5} ${spacing.level3} ${spacing.level5};
      `,
    )}
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  opacity: 76%;

  ${({ withIdentation, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: 0 ${spacing.level5} 0 ${spacing.level5};

    ${when(
      withIdentation,
      `
        padding-left: ${spacing.level7};
      `,
    )}
  `}
`;

export const TextLeftColumn = styled.div`
  ${flexCenterVertical}
  flex: 1;
`;
