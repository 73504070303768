import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '@hoc/withTooltip';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import RadioButton from '@components/1-atoms/RadioButton';
import IconButton from '@components/1-atoms/IconButton';
import { Wrapper, LeftColumn, RightColumn } from './SettingOutput.styled';
import { isEmpty } from 'lodash';

const TextWithTooltip = withTooltip(Text);

const SettingOutput = ({
  className,
  dataTestId = 'setting-output',
  endingIconButton = {},
  label,
  labelTooltip = {},
  preEndingIconButton = {},
  radioButton = {},
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <RadioButton
          dataTestId={`${dataTestId}__radio-button`}
          {...radioButton}
        />

        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn>
        {!isEmpty(preEndingIconButton) && (
          <IconButton
            dataTestId={`${dataTestId}__pre-ending-button`}
            {...preEndingIconButton}
          />
        )}

        {!isEmpty(endingIconButton) && (
          <IconButton
            dataTestId={`${dataTestId}__ending-icon-button`}
            {...endingIconButton}
          />
        )}
      </RightColumn>
    </Wrapper>
  );
};

SettingOutput.propTypes = {
  radioButton: PropTypes.shape(RadioButton.propTypes),
  preEndingIconButton: PropTypes.shape(IconButton.propTypes),
  endingIconButton: PropTypes.shape(IconButton.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
};

export default SettingOutput;
