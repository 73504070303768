import actionTypes from '@actions';
import { asyncActionStages } from '@utils/redux';
import { isEmpty, pick } from 'lodash';

export const setFetchProgressItem = (fetchState = {}, progressName = '') => {
  const inProgess = fetchState?.inProgess || [];
  const completed = fetchState?.completed || [];

  const nextInProgress = inProgess.includes(progressName)
    ? inProgess
    : [...inProgess, progressName];
  const nextCompleted = completed.filter(
    (completedItemName) => completedItemName !== progressName,
  );

  return {
    ...fetchState,
    inProgess: nextInProgress,
    completed: nextCompleted,
  };
};

export const setFetchCompletedItem = (fetchState = {}, completedName = '') => {
  const inProgess = fetchState?.inProgess || [];
  const completed = fetchState?.completed || [];

  const nextInProgress = inProgess.filter(
    (completedItemName) => completedItemName !== completedName,
  );
  const nextCompleted = completed.includes(completedName)
    ? completed
    : [...completed, completedName];

  return {
    ...fetchState,
    inProgess: nextInProgress,
    completed: nextCompleted,
  };
};

/**
 * TODO: This function will be used in the fetchReducer.js
 * to prevent the cases when fetch end action types were
 * not added which causing the issue when isAnyFetchInProgess selector
 * always returns true
 * @param {string} asyncBaseActionName
 * @param {string} fetchName
 * @param {object} state
 * @returns {object} updatedState
 */
export const setFetchByAsyncBaseActionName = (
  asyncBaseActionName,
  fetchName,
  state,
) => {
  const isAsyncBaseActionNameValid = Object.keys(actionTypes)?.some(
    (actionName) => actionName?.includes(asyncBaseActionName),
  );

  if (!isAsyncBaseActionNameValid) {
    return state;
  }

  const buidActionName = (actionStage = '') =>
    `${asyncBaseActionName}_${actionStage}`;
  const fetchInitiatorActionNames = [buidActionName(asyncActionStages.REQUEST)];
  const fetchEndActionNames = [
    buidActionName(asyncActionStages.SUCCESS),
    buidActionName(asyncActionStages.FAILURE),
  ];
  const existingInitiatorActions = pick(actionTypes, fetchInitiatorActionNames);
  const existingEndActions = pick(actionTypes, fetchEndActionNames);
  let nextState = state;

  if (!isEmpty(existingInitiatorActions)) {
    nextState = setFetchProgressItem(nextState, fetchName);
  }

  if (!isEmpty(existingEndActions)) {
    nextState = setFetchProgressItem(nextState, fetchName);
  }

  return nextState;
};
