import React, {
  useContext,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { TransformControls } from '@react-three/drei';
import { useFormContext } from 'react-hook-form';
import { isEmpty, isEqual, isUndefined, pick } from 'lodash';
import usePrevious from '@hooks/usePrevious';
import useOperator from '@hooks/operators/useOperator';
import { VisualizationContext } from '@contexts/VisualizationContext';
import VisualizationUtils from '@app/lib/VisualizationUtils';

const SCALING_FACTOR = 0.02;
const ROTATION_SNAP_STEP = THREE.MathUtils.degToRad(5);
const ALLOWED_TO_TRANSFORM_OPERATOR_NAMES = ['Transform'];
const TRANSFORM_MODE_FIELD_NAME = 'GizmoMode';
const TRANSFORM_ROTATION_ORDER_FIELD_NAME = 'RotationOrder';
const TRANSFORM_MODES_NAMES_SCHEMA = {
  MOVE: 'translate',
  ROTATE: 'rotate',
};
const ALLOWED_TRANSFROM_VALIDATION_SCHEMA = {
  [TRANSFORM_MODE_FIELD_NAME]: Object.keys(TRANSFORM_MODES_NAMES_SCHEMA),
  Mode: ['RELATIVE'],
  PivotPoint: ['OBJECT_CENTER'],
  Operation: ['INTERACTIVE_MODE'],
};
const DEFAULT_TRANSFORM_AXIS = 'ZYX';

const TRANSFORM_MODE_OPERATOR_DEFAULT_FIELD_VALUES = {
  MoveX: '0.0',
  MoveY: '0.0',
  MoveZ: '0.0',
  RotateX: '0.0',
  RotateY: '0.0',
  RotateZ: '0.0',
  RotationOrder: 'ZYX',
  ScalingFactor: '1.0',
  ScaleX: '1.0',
  ScaleY: '1.0',
  ScaleZ: '1.0',
};

const TRANSFORM_MODE_DEFAULT_FIELD_VALUES_SHEMA = {
  [TRANSFORM_MODES_NAMES_SCHEMA.MOVE]:
    TRANSFORM_MODE_OPERATOR_DEFAULT_FIELD_VALUES,
  [TRANSFORM_MODES_NAMES_SCHEMA.ROTATE]:
    TRANSFORM_MODE_OPERATOR_DEFAULT_FIELD_VALUES,
  [TRANSFORM_MODES_NAMES_SCHEMA.SCALE]:
    TRANSFORM_MODE_OPERATOR_DEFAULT_FIELD_VALUES,
};

/* eslint react/no-unknown-property: 0 */

const Transform = ({
  object,
  isWorkflowFormValid,
  getFormFieldValue = () => {},
  setWorkflowFieldValue = () => {},
}) => {
  const {
    controls,
    selectedOperator,
    printer,
    isFetching,
    workflowIsComputing,
  } = useContext(VisualizationContext);
  const { camera } = useThree();
  const [isDragging, setIsDragging] = useState(false);

  const transformRef = useRef();
  const transformObjectRef = useRef();

  const previousTransformObjectPosition = useRef();
  const previousObject = usePrevious(object);

  const disableTransformControls = isFetching || workflowIsComputing;
  const selectedOperatorId = selectedOperator?.id;
  const selectedOperatorValues = selectedOperator?.values;

  const isObjectModified = useMemo(
    () =>
      object?.uuid !== previousObject?.uuid &&
      object?.userData?.designId === previousObject?.userData?.designId,
    [object, previousObject],
  );

  const getDOMElementById = useCallback(
    (element) => document.getElementById(element) || {},
    [],
  );

  const setDOMInputValue = useCallback(
    (idSelector, value) => (getDOMElementById(idSelector).value = value),
    [getDOMElementById],
  );

  const transformMode = useMemo(() => {
    const inputData = selectedOperatorValues?.find(
      ({ name }) => name === TRANSFORM_MODE_FIELD_NAME,
    );
    const selectedMode = getFormFieldValue(selectedOperatorId, inputData?.id);

    return (
      TRANSFORM_MODES_NAMES_SCHEMA?.[selectedMode] ||
      TRANSFORM_MODES_NAMES_SCHEMA.MOVE
    );
  }, [selectedOperatorId, selectedOperatorValues, getFormFieldValue]);

  const transformEnabled = Object.values(TRANSFORM_MODES_NAMES_SCHEMA).includes(
    transformMode,
  );
  const isMoveMode = transformMode === TRANSFORM_MODES_NAMES_SCHEMA.MOVE;
  const isRotateMode = transformMode === TRANSFORM_MODES_NAMES_SCHEMA.ROTATE;
  const isScaleMode = transformMode === TRANSFORM_MODES_NAMES_SCHEMA.SCALE;

  const operatorTransformInputs = useMemo(() => {
    if (isEmpty(selectedOperatorValues)) return {};

    const normalizedInputs = selectedOperatorValues.reduce(
      (acc, input) => ({
        ...acc,
        [input?.name]: input,
      }),
      {},
    );

    return normalizedInputs;
  }, [selectedOperatorValues]);

  const operatorFormValues = useMemo(
    () =>
      Object.values(operatorTransformInputs)?.reduce(
        (acc, input) => ({
          ...acc,
          [input?.name]: getFormFieldValue(selectedOperatorId, input?.id),
        }),
        {},
      ),
    [operatorTransformInputs, selectedOperatorId, getFormFieldValue],
  );

  const previousOperatorFormValues = usePrevious(operatorFormValues);

  const transformAxis = useMemo(() => {
    const isRotationMode =
      transformMode === TRANSFORM_MODES_NAMES_SCHEMA.ROTATE;
    const selectedAxis =
      operatorFormValues?.[TRANSFORM_ROTATION_ORDER_FIELD_NAME];

    if (!isRotationMode || !selectedAxis) {
      return DEFAULT_TRANSFORM_AXIS;
    }

    return selectedAxis;
  }, [transformMode, operatorFormValues]);

  const invertedTransformAxis = useMemo(() => {
    let updatedTransformAxis = 'XYZ';

    if (transformAxis === 'XYZ') {
      updatedTransformAxis = 'ZYX';
    }

    return updatedTransformAxis;
  }, [transformAxis]);

  const invertedFormAxis = useMemo(() => {
    const computedAxis = operatorFormValues?.RotationOrder;
    let invertedAxis = 'XYZ';

    if (computedAxis === 'XYZ') {
      invertedAxis = 'ZYX';
    }

    return invertedAxis;
  }, [operatorFormValues?.RotationOrder]);

  const getObjectCenterPosition = useCallback((object) => {
    if (!object) return new THREE.Vector3();

    object.updateMatrix();
    const center = new THREE.Vector3();
    let box = new THREE.Box3().setFromObject(object);

    if (object?.userData?.isSolidView) {
      box = VisualizationUtils.getBoxFromObject(object, 'LINES');
    }
    box.getCenter(center);
    return center;
  }, []);

  const orientationTransformQuaternion = useMemo(() => {
    const quaternion = new THREE.Quaternion();

    if (!printer) return quaternion;

    const { bed } = printer;
    if (!bed) return quaternion;

    const { baseTransformationMatrix } = bed;

    const matrix = baseTransformationMatrix.clone();
    matrix.setPosition(0, 0, 0);

    quaternion.setFromRotationMatrix(matrix);
    return quaternion;
  }, [printer]);

  const orientationTransformInvertedQuaternion = useMemo(
    () => orientationTransformQuaternion.clone().invert(),
    [orientationTransformQuaternion],
  );

  const targetObject = useMemo(() => object.clone(), [object]);

  const transformationObject = useMemo(() => {
    const originalObjectPosition = getObjectCenterPosition(object);
    const negatedObjectPosition = originalObjectPosition.clone().negate();
    const groupWrapperPosition =
      previousTransformObjectPosition.current || originalObjectPosition;

    return (
      <group
        ref={transformObjectRef}
        position={groupWrapperPosition}
        quaternion={orientationTransformQuaternion}
      >
        <group quaternion={orientationTransformInvertedQuaternion}>
          <group position={negatedObjectPosition}>
            <primitive object={targetObject} />
          </group>
        </group>
      </group>
    );
  }, [
    object,
    targetObject,
    orientationTransformQuaternion,
    orientationTransformInvertedQuaternion,
    getObjectCenterPosition,
  ]);

  const shadowObject = useMemo(() => {
    const transformControls = transformRef.current;
    const skip = !transformControls?.object || !isMoveMode || !isDragging;

    if (skip) {
      return null;
    }

    const ghostedObject = transformControls.object.clone();
    const isLinesType = object?.userData?.isLinesType;

    ghostedObject.traverse((childObject) => {
      if (childObject?.material) {
        const clonedMaterial = childObject.material?.clone();
        clonedMaterial.opacity = 0.2;

        if (!isLinesType) {
          clonedMaterial.side = THREE.DoubleSide;
          clonedMaterial.color = new THREE.Color();
          clonedMaterial.wireframe = false;
          clonedMaterial.roughness = 1.0;
          clonedMaterial.metalness = 0.0;
          clonedMaterial.transparent = true;
          clonedMaterial.depthFunc = THREE.NeverDepth;
          clonedMaterial.depthWrite = false;
        }

        childObject.material = clonedMaterial;
      }
    });
    return ghostedObject;
  }, [object, isMoveMode, isDragging]);

  const setFormFieldValue = useCallback(
    (fieldInput, fieldValue) => {
      setWorkflowFieldValue(selectedOperator, fieldInput, fieldValue);
    },
    [selectedOperator, setWorkflowFieldValue],
  );

  const handleMoveTransformation = useCallback(
    (transformControls) => {
      const transformControlsPositionStart =
        transformControls.positionStart.clone();
      transformControlsPositionStart.applyQuaternion(
        orientationTransformInvertedQuaternion,
      );

      const targetObjectPosition = new THREE.Vector3(
        transformControlsPositionStart?.x -
          operatorFormValues.MoveX * SCALING_FACTOR,
        transformControlsPositionStart?.y -
          operatorFormValues.MoveY * SCALING_FACTOR,
        transformControlsPositionStart?.z -
          operatorFormValues.MoveZ * SCALING_FACTOR,
      );

      const objectPosition = transformControls.object.position.clone();
      objectPosition.applyQuaternion(orientationTransformInvertedQuaternion);

      const adjustedPosition = new THREE.Vector3();
      adjustedPosition.subVectors(objectPosition, targetObjectPosition);

      const formattedPositions = {
        x: (adjustedPosition.x / SCALING_FACTOR)?.toFixed(1),
        y: (adjustedPosition.y / SCALING_FACTOR)?.toFixed(1),
        z: (adjustedPosition.z / SCALING_FACTOR)?.toFixed(1),
      };

      transformControls.userData.movePosition = formattedPositions;

      setDOMInputValue(
        operatorTransformInputs?.MoveX?.id,
        formattedPositions.x,
      );
      setDOMInputValue(
        operatorTransformInputs?.MoveY?.id,
        formattedPositions.y,
      );
      setDOMInputValue(
        operatorTransformInputs?.MoveZ?.id,
        formattedPositions.z,
      );
    },
    [
      operatorTransformInputs,
      operatorFormValues,
      setDOMInputValue,
      orientationTransformInvertedQuaternion,
    ],
  );

  const applyMoveTransformation = useCallback(
    (transformControls) => {
      const { movePosition } = transformControls.userData;

      if (!movePosition) return;

      setFormFieldValue(operatorTransformInputs?.MoveX, movePosition?.x);
      setFormFieldValue(operatorTransformInputs?.MoveY, movePosition?.y);
      setFormFieldValue(operatorTransformInputs?.MoveZ, movePosition?.z);
    },
    [operatorTransformInputs, setFormFieldValue],
  );

  const handleScaleTransformation = useCallback(
    (transformControls) => {
      const computedScalingFactor = operatorTransformInputs.ScalingFactor.value;

      const transformObjectScaleX = transformControls.object.scale.x;
      const transformObjectScaleY = transformControls.object.scale.y;
      const transformObjectScaleZ = transformControls.object.scale.z;

      const formattedScale = {
        x: (computedScalingFactor * transformObjectScaleX)?.toFixed(2),
        y: (computedScalingFactor * transformObjectScaleY)?.toFixed(2),
        z: (computedScalingFactor * transformObjectScaleZ)?.toFixed(2),
      };

      transformControls.userData.scaleToApply = formattedScale;

      setDOMInputValue(
        operatorTransformInputs?.ScalingFactor?.id,
        formattedScale.x,
      );
      setDOMInputValue(
        operatorTransformInputs?.ScalingFactor?.id,
        formattedScale.y,
      );
      setDOMInputValue(
        operatorTransformInputs?.ScalingFactor?.id,
        formattedScale.z,
      );
    },
    [operatorTransformInputs, setDOMInputValue],
  );

  const applyScaleTransformation = useCallback(
    (transformControls) => {
      const { scaleToApply } = transformControls.userData;

      if (!scaleToApply) return;

      setFormFieldValue(
        operatorTransformInputs?.ScalingFactor,
        scaleToApply?.x,
      );
      setFormFieldValue(
        operatorTransformInputs?.ScalingFactor,
        scaleToApply?.y,
      );
      setFormFieldValue(
        operatorTransformInputs?.ScalingFactor,
        scaleToApply?.z,
      );
    },
    [operatorTransformInputs, setFormFieldValue],
  );

  const handleRotateTransformation = useCallback(
    (transformControls) => {
      const { object } = transformControls;

      const operatorFormTranslation = new THREE.Vector3(
        Number(operatorFormValues.MoveX * SCALING_FACTOR),
        Number(operatorFormValues.MoveY * SCALING_FACTOR),
        Number(operatorFormValues.MoveZ * SCALING_FACTOR),
      );
      operatorFormTranslation.applyQuaternion(orientationTransformQuaternion);

      const objectQuaternion = object.quaternion.clone();

      const operatorTransformInputsRotationOrder =
        operatorTransformInputs.RotationOrder.value;
      const formRotationOrder = operatorFormValues?.RotationOrder;
      let updatedAxis = invertedTransformAxis;
      if (formRotationOrder !== operatorTransformInputsRotationOrder) {
        updatedAxis = transformAxis;
      }

      const computedEuler = new THREE.Euler(
        THREE.MathUtils.degToRad(Number(operatorTransformInputs.RotateX.value)),
        THREE.MathUtils.degToRad(Number(operatorTransformInputs.RotateY.value)),
        THREE.MathUtils.degToRad(Number(operatorTransformInputs.RotateZ.value)),
        updatedAxis,
      );

      const computedQuaternion = new THREE.Quaternion();
      computedQuaternion.setFromEuler(computedEuler);

      const finalQuaternion = new THREE.Quaternion();
      finalQuaternion.multiply(orientationTransformInvertedQuaternion);
      finalQuaternion.multiply(objectQuaternion);
      finalQuaternion.multiply(computedQuaternion);

      const finalEuler = new THREE.Euler();
      finalEuler.setFromQuaternion(finalQuaternion, invertedTransformAxis);

      const finalDegrees = finalEuler
        .toVector3()
        .multiplyScalar(THREE.Math.RAD2DEG);

      const formattedRotate = {
        x: finalDegrees.x?.toFixed(2),
        y: finalDegrees.y?.toFixed(2),
        z: finalDegrees.z?.toFixed(2),
      };

      transformControls.userData.rotationToApply = formattedRotate;
      setDOMInputValue(operatorTransformInputs?.RotateX?.id, formattedRotate.x);
      setDOMInputValue(operatorTransformInputs?.RotateY?.id, formattedRotate.y);
      setDOMInputValue(operatorTransformInputs?.RotateZ?.id, formattedRotate.z);
    },
    [
      invertedTransformAxis,
      transformAxis,
      operatorTransformInputs,
      setDOMInputValue,
      orientationTransformInvertedQuaternion,
      operatorFormValues,
      orientationTransformQuaternion,
    ],
  );

  const applyRotateTransformation = useCallback(
    (transformControls) => {
      const { rotationToApply } = transformControls.userData;

      if (!rotationToApply) return;

      setFormFieldValue(operatorTransformInputs?.RotateX, rotationToApply?.x);
      setFormFieldValue(operatorTransformInputs?.RotateY, rotationToApply?.y);
      setFormFieldValue(operatorTransformInputs?.RotateZ, rotationToApply?.z);
    },
    [operatorTransformInputs, setFormFieldValue],
  );

  const handleObjectTransformation = useCallback(
    ({ target }) => {
      if (isMoveMode) {
        handleMoveTransformation(target);
        return;
      }

      if (isRotateMode) {
        handleRotateTransformation(target);
        return;
      }

      if (isScaleMode) {
        handleScaleTransformation(target);
        return;
      }
    },
    [
      isMoveMode,
      isRotateMode,
      isScaleMode,
      handleMoveTransformation,
      handleRotateTransformation,
      handleScaleTransformation,
    ],
  );

  const applyObjectTransformation = useCallback(
    ({ target }) => {
      applyMoveTransformation(target);
      applyScaleTransformation(target);
      applyRotateTransformation(target);
      return;
    },
    [
      applyMoveTransformation,
      applyRotateTransformation,
      applyScaleTransformation,
    ],
  );

  const handleDragChange = useCallback(
    (event) => {
      const dragging = !!event.value;

      setIsDragging(dragging);

      controls.current.enabled = !dragging;
    },
    [controls, setIsDragging],
  );

  const observeFormValuesUpdates = useCallback(() => {
    const transformControls = transformRef.current;
    const formFieldsNameToObserve = Object.keys(
      TRANSFORM_MODE_DEFAULT_FIELD_VALUES_SHEMA?.[transformMode] || {},
    );
    const previousObservableFields = pick(
      previousOperatorFormValues,
      formFieldsNameToObserve,
    );
    const observableFields = pick(operatorFormValues, formFieldsNameToObserve);
    const skip =
      !transformControls ||
      !isWorkflowFormValid ||
      isUndefined(previousOperatorFormValues) ||
      isEqual(previousObservableFields, observableFields);

    if (skip) return;

    const transformObject = transformControls.object;

    // update translations
    const objectStartPosition = getObjectCenterPosition(object);

    const computedPosition = new THREE.Vector3(
      operatorTransformInputs.MoveX.value * SCALING_FACTOR,
      operatorTransformInputs.MoveY.value * SCALING_FACTOR,
      operatorTransformInputs.MoveZ.value * SCALING_FACTOR,
    );
    computedPosition.applyQuaternion(orientationTransformQuaternion);

    const objectModifiedPosition = new THREE.Vector3(
      operatorFormValues.MoveX * SCALING_FACTOR,
      operatorFormValues.MoveY * SCALING_FACTOR,
      operatorFormValues.MoveZ * SCALING_FACTOR,
    );

    objectModifiedPosition.applyQuaternion(orientationTransformQuaternion);

    const finalPosition = new THREE.Vector3(
      objectStartPosition.x + objectModifiedPosition.x - computedPosition.x,
      objectStartPosition.y + objectModifiedPosition.y - computedPosition.y,
      objectStartPosition.z + objectModifiedPosition.z - computedPosition.z,
    );

    transformObject.position.copy(finalPosition);

    // update rotations
    const formRotationOrder = operatorFormValues?.RotationOrder;
    const operatorTransformInputsRotationOrder =
      operatorTransformInputs.RotationOrder.value;
    if (formRotationOrder !== operatorTransformInputsRotationOrder) {
      const computedEuler = new THREE.Euler(
        THREE.MathUtils.degToRad(operatorFormValues.RotateX),
        THREE.MathUtils.degToRad(operatorFormValues.RotateY),
        THREE.MathUtils.degToRad(operatorFormValues.RotateZ),
        invertedTransformAxis,
      );
      const computedQuaternion = new THREE.Quaternion()
        .setFromEuler(computedEuler)
        .invert();
      transformObject.quaternion.multiply(computedQuaternion);
    }

    const currentQuaternion = transformObject.quaternion.clone().invert();
    currentQuaternion.multiply(orientationTransformQuaternion);

    const computedRotationOrder = operatorTransformInputs.RotationOrder.value;
    let invertedComputedRotationOrder = 'XYZ';
    if (computedRotationOrder === 'XYZ') {
      invertedComputedRotationOrder = 'ZYX';
    }

    const computedRotation = new THREE.Euler(
      THREE.MathUtils.degToRad(operatorTransformInputs.RotateX.value),
      THREE.MathUtils.degToRad(operatorTransformInputs.RotateY.value),
      THREE.MathUtils.degToRad(operatorTransformInputs.RotateZ.value),
      invertedComputedRotationOrder,
    );
    const computedQuaternion = new THREE.Quaternion()
      .setFromEuler(computedRotation)
      .invert();

    const objectRotation = new THREE.Euler(
      THREE.MathUtils.degToRad(operatorFormValues.RotateX),
      THREE.MathUtils.degToRad(operatorFormValues.RotateY),
      THREE.MathUtils.degToRad(operatorFormValues.RotateZ),
      invertedFormAxis,
    );
    const objectQuaternion = new THREE.Quaternion().setFromEuler(
      objectRotation,
    );

    const finalQuaternion = new THREE.Quaternion();
    finalQuaternion.multiply(currentQuaternion);
    finalQuaternion.multiply(objectQuaternion);
    finalQuaternion.multiply(computedQuaternion);
    transformObject.quaternion.multiply(finalQuaternion);

    // update scaling
    const computedScalingFactor = operatorTransformInputs.ScalingFactor.value;

    transformObject.scale.set(
      operatorFormValues.ScalingFactor / computedScalingFactor,
      operatorFormValues.ScalingFactor / computedScalingFactor,
      operatorFormValues.ScalingFactor / computedScalingFactor,
    );
  }, [
    object,
    isWorkflowFormValid,
    operatorTransformInputs,
    previousOperatorFormValues,
    operatorFormValues,
    invertedFormAxis,
    transformMode,
    invertedTransformAxis,
    orientationTransformQuaternion,
    getObjectCenterPosition,
  ]);

  const observeObjectModifications = useCallback(() => {
    const transformControls = transformRef.current;

    if (!transformControls) return;

    if (!previousTransformObjectPosition.current || isObjectModified) {
      previousTransformObjectPosition.current =
        transformationObject.props?.position;
    }
  }, [isObjectModified, transformationObject]);

  useEffect(() => {
    observeFormValuesUpdates();
  }, [observeFormValuesUpdates]);

  useEffect(() => {
    observeObjectModifications();
  }, [observeObjectModifications]);

  useEffect(() => {
    controls?.current?.update?.();
  }, [controls]);

  useEffect(() => {
    const transformControls = transformRef.current;
    const skip = !transformControls;

    if (skip) return;

    transformControls.addEventListener('dragging-changed', handleDragChange);

    return () => {
      transformControls.removeEventListener(
        'dragging-changed',
        handleDragChange,
      );
    };
  }, [handleDragChange]);

  return (
    <group visible={transformEnabled}>
      {transformationObject}

      <group visible={!!transformObjectRef.current}>
        <TransformControls
          ref={transformRef}
          enabled={!disableTransformControls}
          camera={camera}
          mode={transformMode}
          axis={transformAxis}
          space={'world'}
          object={transformObjectRef.current}
          onObjectChange={handleObjectTransformation}
          onMouseUp={applyObjectTransformation}
          rotationSnap={ROTATION_SNAP_STEP}
          showX
          showY
          showZ
        />
      </group>

      {shadowObject && <primitive object={shadowObject} />}
    </group>
  );
};

Transform.propTypes = {
  object: PropTypes.object.isRequired,
  isWorkflowFormValid: PropTypes.bool,
  getFormFieldValue: PropTypes.func,
  setWorkflowFieldValue: PropTypes.func,
};

export default function TransformContainer({ object }) {
  const {
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext();
  const { selectedOperator } = useContext(VisualizationContext);
  const { getIsOperatorInputHidden } = useOperator();

  const selectedOperatorId = selectedOperator?.id;
  const selectedOperatorValues = selectedOperator?.values;

  const workflowFormValues = watch();

  const getFormFieldValue = useCallback(
    (operatorId, fieldId) => workflowFormValues?.[operatorId]?.[fieldId],
    [workflowFormValues],
  );

  const setWorkflowFieldValue = useCallback(
    (operator, inputField, fieldValue) => {
      const formFieldPath = `${operator?.id}.${inputField?.id}`;

      setValue(formFieldPath, fieldValue, {
        shouldTouch: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const operatorTransformInputs = useMemo(() => {
    if (isEmpty(selectedOperatorValues)) return {};

    const normalizedInputs = selectedOperatorValues.reduce(
      (acc, input) => ({
        ...acc,
        [input?.name]: {
          ...input,
          value: getIsOperatorInputHidden(selectedOperatorId, input)
            ? input?.value
            : getFormFieldValue(input?.operatorId, input?.id),
        },
      }),
      {},
    );

    return normalizedInputs;
  }, [
    selectedOperatorValues,
    selectedOperatorId,
    getIsOperatorInputHidden,
    getFormFieldValue,
  ]);

  const operatorFormValues = useMemo(
    () =>
      Object.values(operatorTransformInputs)?.reduce(
        (acc, input) => ({
          ...acc,
          [input?.name]: getIsOperatorInputHidden(selectedOperatorId, input)
            ? input.value
            : getFormFieldValue(selectedOperatorId, input?.id),
        }),
        {},
      ),
    [
      operatorTransformInputs,
      selectedOperatorId,
      getIsOperatorInputHidden,
      getFormFieldValue,
    ],
  );

  const objectBelongsToSelectedOperator = useMemo(
    () =>
      selectedOperator?.values?.some(
        ({ value }) => value === object?.userData?.designId,
      ),
    [selectedOperator?.values, object?.userData?.designId],
  );

  const isAllowedTransformOperator =
    ALLOWED_TO_TRANSFORM_OPERATOR_NAMES?.includes(selectedOperator?.name);

  const operatorIsAllowedToTransform = useMemo(
    () =>
      isAllowedTransformOperator &&
      Object.keys(ALLOWED_TRANSFROM_VALIDATION_SCHEMA)?.every((fieldName) => {
        const allowedValues = ALLOWED_TRANSFROM_VALIDATION_SCHEMA[fieldName];

        return allowedValues?.includes(operatorFormValues?.[fieldName]);
      }),
    [isAllowedTransformOperator, operatorFormValues],
  );

  const transformEnabled = useMemo(
    () => objectBelongsToSelectedOperator && operatorIsAllowedToTransform,
    [objectBelongsToSelectedOperator, operatorIsAllowedToTransform],
  );

  return (
    <>
      {transformEnabled && (
        <group>
          <Transform
            object={object}
            isWorkflowFormValid={isValid}
            getFormFieldValue={getFormFieldValue}
            setWorkflowFieldValue={setWorkflowFieldValue}
          />
        </group>
      )}

      <primitive object={object} visible={!transformEnabled} />
    </>
  );
}

TransformContainer.propTypes = {
  object: PropTypes.object.isRequired,
};
