import { FeatureFlagContext } from '@app/containers/Providers/FeatureFlagProvider';
import { useMemo, useContext } from 'react';

/**
 * A hook that, given a feature flag name and optional default value, returns
 * the value of the feature flag for the current user.
 *
 * Note: Use of this hook will cause the flag to be evaluated immediately
 * on mount. Use useFeatureFlags to control the evaluation time more specifically.
 * @param {string} featureName the feature flag name
 * @param {boolean} defaultValue the default value to return if the client is not initialised
 * @returns the flag value: true or false
 */
export default function useFeatureFlagValue(featureName, defaultValue = false) {
  const client = useContext(FeatureFlagContext);

  return useMemo(() => {
    if (!client) return defaultValue;
    return client.checkGate(featureName);
  }, [client, featureName, defaultValue]);
}
