import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorList from '@hooks/operators/useOperatorList';
import useOperator from '@hooks/operators/useOperator';
import { ICON_BUTTON_VARIANT_PRIMARY } from '@components/1-atoms/IconButton';
import { TOOLTIP_POSITION_BOTTOM_LEFT } from '@components/2-molecules/Tooltip';
import Toolbar from '@components/2-molecules/Toolbar';

const commonTooltipProps = {
  tooltipPosition: TOOLTIP_POSITION_BOTTOM_LEFT,
  tooltipOffsetRight: 10,
};

const WorkflowSettingToolbar = ({
  handleToolbarCollapse,
  hideBodyIconButtons,
  hideFooterIconButtons,
  isWorkflowComputing,
  isComputationAvailable,
}) => {
  const intl = useIntl();
  const { getSelectedWorkflowOperators } = useWorkflow();
  const { getDefaultOperator } = useOperatorList();
  const { selectFirstOperatorOutput, getSelectedOperator } = useOperator();

  const operators = getSelectedWorkflowOperators();
  const selectedOperator = getSelectedOperator();

  const toolbarOperatorIconButtons = useMemo(
    () =>
      operators.map((operator) => {
        return {
          id: operator.id,
          disabled: isWorkflowComputing || !operator.computed,
          iconName:
            getDefaultOperator(operator.name)?.iconName || 'network_node_0',
          onClick: () => selectFirstOperatorOutput(operator),
          selected: selectedOperator?.id === operator.id,
          tooltip: {
            ...commonTooltipProps,
            tooltipInfoRow: {
              label: operator.tag || operator.name,
              noPadding: true,
            },
          },
        };
      }),
    [
      isWorkflowComputing,
      operators,
      selectedOperator?.id,
      getDefaultOperator,
      selectFirstOperatorOutput,
    ],
  );

  return (
    <Toolbar
      headerIconButtons={[
        {
          id: 0,
          iconName: 'thumbnail_bar_0',
          onClick: handleToolbarCollapse,
        },
      ]}
      bodyIconButtons={
        hideBodyIconButtons ? undefined : toolbarOperatorIconButtons
      }
      footerIconButtons={
        hideFooterIconButtons
          ? undefined
          : [
              {
                id: 'workflowSettingToolbarRecomputeIconButton',
                disabled: !isComputationAvailable,
                iconName: 'sync_0',
                variant: ICON_BUTTON_VARIANT_PRIMARY,
                tooltip: {
                  ...commonTooltipProps,
                  tooltipInfoRow: {
                    label: intl.formatMessage({
                      id: 'general.compute_all',
                      defaultMessage: 'Compute all',
                    }),
                    noPadding: true,
                  },
                },
                submitType: true,
              },
            ]
      }
    />
  );
};

WorkflowSettingToolbar.propTypes = {
  handleToolbarCollapse: PropTypes.func.isRequired,
  hideBodyIconButtons: PropTypes.bool,
  hideFooterIconButtons: PropTypes.bool,
  isWorkflowComputing: PropTypes.bool,
  isComputationAvailable: PropTypes.bool,
};

export default WorkflowSettingToolbar;
