import React from 'react';
import PropTypes from 'prop-types';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import Chip from '@components/1-atoms/Chip';
import { Wrapper, LeftColumn, RightColumn } from './SettingAction.styled';

const SettingAction = ({
  chip = {},
  className,
  dataTestId = 'setting-action',
  label,
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <Text
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </Text>
      </LeftColumn>

      <RightColumn>
        <Chip dataTestId={`${dataTestId}__chip`} {...chip} />
      </RightColumn>
    </Wrapper>
  );
};

SettingAction.propTypes = {
  chip: PropTypes.shape(Chip.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  withIdentation: PropTypes.bool,
};

export default SettingAction;
