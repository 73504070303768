import { useSelector } from 'react-redux';
import useSystemTheme from '@hooks/useSystemTheme';
import {
  getIsLoggedIn,
  getUserAccountPreference,
} from '@selectors/loginSelectors';
import { addToLocalStorage, getFromLocalStorage } from '@utils/localStorage';
import { useEffect, useMemo, useState } from 'react';
import { THEME_PREFERENCE_KEY } from '@constants/utilityConstants';
import { themeTypes } from '@constants/accountSettings';

export default function useApplicationTheme() {
  const isUserLoggedIn = useSelector(getIsLoggedIn());
  const userThemePreference = useSelector(
    getUserAccountPreference('USER_ACCOUNT_THEME'),
  );
  const systemTheme = useSystemTheme();
  const localStorageTheme = getFromLocalStorage(THEME_PREFERENCE_KEY);
  const [chosenTheme, setChosenTheme] = useState(systemTheme);

  const selectedTheme = useMemo(() => {
    if (isUserLoggedIn && userThemePreference) {
      return userThemePreference;
    }

    if (
      [
        themeTypes.SYSTEM.value,
        themeTypes.DARK.value,
        themeTypes.LIGHT.value,
      ].includes(localStorageTheme)
    ) {
      return localStorageTheme;
    }

    return systemTheme;
  }, [systemTheme, isUserLoggedIn, userThemePreference, localStorageTheme]);

  const systemThemeIsSelected = useMemo(() => {
    if (isUserLoggedIn) {
      return selectedTheme === themeTypes.SYSTEM.value;
    }

    return localStorageTheme === themeTypes.SYSTEM.value;
  }, [isUserLoggedIn, selectedTheme, localStorageTheme]);

  useEffect(() => {
    setChosenTheme(systemThemeIsSelected ? systemTheme : selectedTheme);
  }, [systemThemeIsSelected, systemTheme, selectedTheme]);

  useEffect(() => {
    if (isUserLoggedIn && userThemePreference) {
      addToLocalStorage(THEME_PREFERENCE_KEY, userThemePreference);
    }
  }, [isUserLoggedIn, userThemePreference]);

  return chosenTheme;
}
