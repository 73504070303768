import * as THREE from 'three';

class GantryComponent {
  /**
   *
   * @param {*} name name and identifying key of the component
   * @param {*} componentType type of component [AXIS, EXTRUDER, BED]
   * @param {*} parents parent components which drive the motion of this component
   * @param {*} direction direction of the component motion, if it is an AXIS
   */
  constructor(name, componentType, parents, direction) {
    this.name = name;
    this.componentType = componentType;
    this.parents = parents;
    this.direction = direction;
    this.allPredecessors = [];
    /**
     * This will be updated via a DFS traversal of the graph - if this axis forms
     * part of the bed subgraph, this will be updated to true. If true, all motion will
     * be inverted.
     */
    this.isBedAttached = false;

    /**
     * Defines the joint position of this component if it is an AXIS type.
     */
    this.jointPosition = 0;
    /**
     * Defines the displacement vector of this component relative to its parents, defined in the
     * global coordinate system.
     */
    this.displacementVector = new THREE.Vector3(0, 0, 0);
  }

  /**
   *
   * @param {Array<number>} jointPositions [joint1, joint2, joint3]
   * @returns
   */
  updateJointPosition(jointPositions) {
    if (this.componentType != 'AXIS') return;

    const axisNumber = this.name.split('_')[1];
    const jointPosition = jointPositions[axisNumber - 1];
    this.jointPosition = this.isBedAttached ? -jointPosition : jointPosition;
    this.displacementVector = this.direction
      .normalize()
      .clone()
      .multiplyScalar(this.jointPosition);
  }
}

export default GantryComponent;
