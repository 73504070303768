import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import SettingCheckbox from '@components/2-molecules/SettingCheckbox';
import SettingTextField from '@components/2-molecules/SettingTextField';
import { ValueTypes } from '@constants/valueTypes';
import { useSelector } from 'react-redux';
import { getUnsavedPrinterModifications } from '@selectors/printerSelectors';
import { getSettingValue, getShouldShowSetting } from '@app/lib/Project';
import { stringToBoolean } from '@utils/commonFunctions';
import SettingDropDown from '@components/2-molecules/SettingDropDown';
import { CURRENT_USER_KEY_LOCAL_STORAGE } from '@constants/utilityConstants';
import { isObject } from 'lodash';
import SettingDropDownWithIconButton from '@components/2-molecules/SettingDropDownWithIconButton';
import { defaultPrinterSettings } from '@constants/defaultPrinterSettings';
import { printerConstants } from '@constants/printers/printerConstants';
import { UserRoles } from '@constants/userRoles';

const useGetSettingValue = (setting, selectedPrinter) => {
  const modifications = useSelector(
    getUnsavedPrinterModifications(selectedPrinter.id),
  );

  return useMemo(
    () => getSettingValue(setting, selectedPrinter, modifications),
    [setting, selectedPrinter, modifications],
  );
};

function mapAllowedValues(allowedValues, condition) {
  const values = [];
  const user = JSON.parse(localStorage.getItem(CURRENT_USER_KEY_LOCAL_STORAGE));
  for (const allowedValue in allowedValues) {
    const value = allowedValues[allowedValue];
    if (value) {
      const entry = {
        value: allowedValue,
        label: allowedValue,
        ...value,
      };
      if (
        value.authorizedRoles?.length > 0 &&
        !value.authorizedRoles?.includes(user?.role)
      ) {
        continue; //do not include the allowed values if there is an authorizedRoles defined and current user doesn't have
      }
      if (!condition || (value.brand && value.brand === condition)) {
        values.push(entry);
      }
    }
  }
  return values;
}

function isObjectAndNotList(value) {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}

function getTitle(setting, settingValue, values) {
  let tempValues = [];
  if (!values) {
    tempValues = getValues(setting);
  } else {
    tempValues = values;
  }
  const selectedOptionFromAllowedValues = tempValues?.find(
    (option) => option.value === settingValue,
  );
  return (
    (selectedOptionFromAllowedValues &&
      selectedOptionFromAllowedValues.label) ||
    settingValue
  );
}

function getValues(setting, robotBrand) {
  let values = [];
  if (isObjectAndNotList(setting.allowedValues)) {
    values = mapAllowedValues(setting.allowedValues, robotBrand);
  } else {
    values = setting.allowedValues;
  }
  return values;
}

const renderDropDown = ({
  setting,
  label,
  onChange,
  dataTestId,
  settingValue,
  actionHandler,
  currentUser,
  dirty,
}) => {
  const values = getValues(setting);
  const title = getTitle(setting, settingValue, values);
  let hasOptions;
  const options = values.map((option) => {
    const optionIsObject = isObject(option);
    let label;
    let value;
    if (optionIsObject) {
      label = option.label;
      value = option.value;
      hasOptions = option.actions;
    } else {
      label = option;
      value = option;
    }

    return {
      label: label,
      selected: value === settingValue,
      description: option?.brand,
      onClick: () => onChange(setting, value),
      ...(optionIsObject &&
        value === printerConstants.CUSTOM.value && {
          withDivider: true,
        }),
    };
  });

  if (currentUser?.role === UserRoles.SUPER_ADMIN && setting.manageVisibility) {
    options.push({
      label: 'Manage visibility',
      withDivider: true,
      endingIconName: 'chevron_right_0',
      onClick: () => {
        actionHandler(setting.settingName, setting.displayName, true);
      },
    });
  }

  if (hasOptions && settingValue === printerConstants.CUSTOM.value) {
    return (
      <SettingDropDownWithIconButton
        dataTestId={dataTestId}
        label={label}
        iconButton={{
          iconName: 'discover_tune_0',
          onClick: () => {
            actionHandler(setting.settingName, setting.displayName);
          },
        }}
        dropDownField={{
          dropDownMenuItems: options,
          fullWidthDropDownMenu: false,
          value: title,
          dirty: dirty,
        }}
      />
    );
  }
  return (
    <SettingDropDown
      dataTestId={dataTestId}
      label={label}
      dropDownField={{
        dropDownMenuItems: options,
        fullWidthDropDownMenu: false,
        value: title,
        dirty: dirty,
      }}
    />
  );
};

export const Setting = ({
  label,
  setting,
  selectedPrinter,
  dataTestId,
  onChange,
  actionHandler,
  errors,
  currentUser,
}) => {
  const settingValue = useGetSettingValue(setting, selectedPrinter);
  const modifications = useSelector(
    getUnsavedPrinterModifications(selectedPrinter.id),
  );
  const error = !!(errors && errors[setting.settingName]);

  const shouldShowSetting = useMemo(
    () =>
      getShouldShowSetting(
        setting,
        selectedPrinter,
        modifications,
        defaultPrinterSettings,
      ),
    [modifications, selectedPrinter, setting],
  );

  const handleFieldChange = useCallback(
    (value) => {
      onChange(setting, value);
    },
    [onChange, setting],
  );

  const getIsFieldDirty = useCallback(
    (setting) => {
      return !!modifications?.settings[setting.settingName || setting.type];
    },
    [modifications],
  );

  if (!shouldShowSetting) return null;

  switch (setting.valueType || setting.dataType) {
    case ValueTypes.SELECTION:
    case ValueTypes.SELECTION_WIDE:
      return renderDropDown({
        setting,
        label,
        onChange,
        dataTestId,
        settingValue,
        actionHandler,
        currentUser,
        dirty: getIsFieldDirty(setting),
      });
    case ValueTypes.BOOLEAN:
      return (
        <SettingCheckbox
          label={label}
          dataTestId={dataTestId}
          checkbox={{
            checked: stringToBoolean(settingValue),
            onChange: () => handleFieldChange(!settingValue),
            error: error,
            supportingText: errors && errors[setting.settingName],
            dirty: !error && getIsFieldDirty(setting),
          }}
        />
      );
    case ValueTypes.INTEGER:
    case ValueTypes.DECIMAL:
      return (
        <SettingTextField
          label={label}
          dataTestId={dataTestId}
          field1={{
            type: 'number',
            value: String(settingValue),
            onChange: (e) => handleFieldChange(e.target.value),
            error: error,
            supportingText: errors && errors[setting.settingName],
            placeholder: setting.placeholder,
            dirty: !error && getIsFieldDirty(setting),
          }}
        />
      );
    case ValueTypes.STRING:
    case ValueTypes.STRING_WIDE:
      return (
        <SettingTextField
          dataTestId={dataTestId}
          label={label}
          field1={{
            value: settingValue,
            onChange: (e) => handleFieldChange(e.target.value),
            error: error,
            supportingText: errors && errors[setting.settingName],
            placeholder: setting.placeholder,
            dirty: !error && getIsFieldDirty(setting),
          }}
        />
      );
    default:
      // eslint-disable-next-line no-console
      console.error(`unrecognised setting value type ${setting.valueType}`);
      return null;
  }
};

Setting.propTypes = {
  setting: PropTypes.object.isRequired,
  actionHandler: PropTypes.func,
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedPrinter: PropTypes.object.isRequired,
  errors: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
};
