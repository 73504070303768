import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useOperator from '@hooks/operators/useOperator';

const addOperatorPosition = (operator) => ({
  ...operator,
  position: operator?.position || { x: 0, y: 0 },
});

export default function useVisualToolOperators({
  workflow = {},
  operators = [],
  form = {},
}) {
  const activeItemsRef = useRef([]);
  const { getOperatorStatus } = useOperator();

  const {
    formState: { touchedFields, errors, defaultValues },
    getValues,
  } = form;

  const visualToolOperators = useMemo(
    () => operators?.map(addOperatorPosition),
    [operators],
  );

  const [expandedOperatorIds, setExpandedOperatorIds] = useState([]);

  const toggleExpand = useCallback(
    (operator) => () => {
      const isOperatorExpanded = activeItemsRef.current.includes(operator?.id);

      if (isOperatorExpanded) {
        const filterExpanded = (id) => id !== operator?.id;

        setExpandedOperatorIds((previousExpanded) =>
          previousExpanded.filter(filterExpanded),
        );

        return;
      }

      setExpandedOperatorIds((previousExpanded) => [
        ...previousExpanded,
        operator?.id,
      ]);
    },
    [],
  );

  useEffect(() => {
    activeItemsRef.current = expandedOperatorIds;
  }, [expandedOperatorIds]);

  const edges = useMemo(
    () =>
      workflow.operatorDependencies.map(
        ({ id: { operatorId, dependedOperatorId } }) => ({
          id: `${operatorId}->${dependedOperatorId}`,
          source: dependedOperatorId,
          target: operatorId,
        }),
      ),
    [workflow.operatorDependencies],
  );

  const nodes = useMemo(
    () =>
      visualToolOperators.map((operator) => {
        const isExpanded = expandedOperatorIds.includes(operator?.id);

        return {
          id: operator.id,
          type: 'operator',
          position: operator?.position,
          zIndex: isExpanded ? 1 : undefined,
          data: {
            expanded: isExpanded,
            toggleExpand: toggleExpand(operator),
            operator,
            status: getOperatorStatus(operator, {
              formState: {
                touchedFields,
                errors,
                defaultValues,
              },
              getValues,
            }),
            sourcetHandle: !!edges.find(({ source }) => source === operator.id),
            targetHandle: !!edges.find(({ target }) => target === operator.id),
          },
        };
      }),
    [
      toggleExpand,
      expandedOperatorIds,
      visualToolOperators,
      edges,
      touchedFields,
      errors,
      defaultValues,
      getValues,
      getOperatorStatus,
    ],
  );

  return { nodes, edges };
}
