import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 auto;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level6};
    width: min(800px, 100%);
  `}
`;
