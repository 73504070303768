import PropTypes from 'prop-types';
import {
  QRContainer,
  Wrapper,
} from '@components/3-organisms/Login2FAConnector/Login2FAConnector.styled';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import { QRCodeSVG } from 'qrcode.react';
import InfoRow from '@components/1-atoms/InfoRow';
import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import Field from '@components/1-atoms/Field';
import { whenMatchPattern } from '@utils/interactionEvents';
import { integerInputPattern } from '@utils/validation';
import { MFA_CODE_LENGTH } from '@components/3-organisms/Login2FACode';
import useSnackbar from '@hooks/useSnackbar';

const AccountMFA = ({
  className,
  dataTestId = 'login-page-2fa-connector',
  mfaSetupCode,
  qrCodeUrl,
  mfaCode,
  handleFieldBlur,
  handleFieldChange,
  mfaCodeError,
  mfaCodeErrorMessage,
  mfaCodeTouched,
  mfaCodeLength = MFA_CODE_LENGTH,
}) => {
  const intl = useIntl();
  const { showSnackbar } = useSnackbar();
  const showMfaCodeErrorMessage = mfaCodeTouched && mfaCodeError;

  const handleCopySetupCode = useCallback(() => {
    navigator.clipboard.writeText(mfaSetupCode);
    showSnackbar({
      text: intl.formatMessage({
        id: 'loginpage.2fa_connector.snackbar.copied.to.clipboard.label',
        defaultMessage: 'Code copied to clipboard',
      }),
    });
  }, [intl, showSnackbar, mfaSetupCode]);

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.2fa_connector.set_up_mfa',
          defaultMessage: 'Set up multi-factor authentication',
        })}
        subtitle={intl.formatMessage({
          id: 'loginpage.2fa_connector.scan_qr_code',
          defaultMessage:
            'Scan the QR code using an authenticator app or use the code below for manual setup.',
        })}
      />

      <QRContainer>
        <QRCodeSVG value={qrCodeUrl} size={140} includeMargin />
      </QRContainer>

      <InfoRow
        dataTestId={`${dataTestId}__mfa-setup-code`}
        label={mfaSetupCode}
        endingIconButtonIconName="content_copy_0"
        onEndingIconButtonClick={handleCopySetupCode}
      />

      <Field
        dataTestId={`${dataTestId}__mfa-code-field`}
        name="mfaCode"
        maxLength={mfaCodeLength}
        label={intl.formatMessage({
          id: 'settings.account.security.mfa.code.label',
          defaultMessage: 'Code',
        })}
        placeholder={intl.formatMessage({
          id: 'loginpage.2fa.verification_code',
          defaultMessage: 'Verification code',
        })}
        value={mfaCode}
        onChange={whenMatchPattern(integerInputPattern, handleFieldChange)}
        onBlur={handleFieldBlur}
        error={showMfaCodeErrorMessage}
        supportingText={
          (showMfaCodeErrorMessage && mfaCodeErrorMessage) ||
          intl.formatMessage({
            id: 'settings.mfa_modal.confirm_code.description',
            defaultMessage:
              'The 6 digit code generated by your Authenticator App',
          })
        }
      />
    </Wrapper>
  );
};

AccountMFA.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  mfaSetupCode: PropTypes.string.isRequired,
  qrCodeUrl: PropTypes.string.isRequired,
  mfaCodeLength: PropTypes.number,
  mfaCode: PropTypes.string,
  mfaCodeError: PropTypes.bool,
  mfaCodeTouched: PropTypes.bool,
  handleFieldBlur: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  mfaCodeErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default AccountMFA;
