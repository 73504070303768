import { PrinterCategories } from '../printerCategories';
import { printerConstants as constants } from '@constants/printers/printerConstants';
import { UserRoles } from '../userRoles';
import { ValueTypes } from '../valueTypes';
import { sortByAttribute } from '@utils/commonFunctions';
import {
  getDefaultToolCalibrations,
  updateDefaultToolCalibration,
} from '../../lib/extruders/ExtruderUtils';

export const hardwareSelectionSettings = [
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Tool Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.extruderType,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: 'Aibuild AiMaker V5',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Printing Bed Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.printingBedType,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: 'Parametric',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Plinth Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.plinthType,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: 'NoPlinth',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Base Plinth Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.basePlinthType,
    authorizedRoles: ['WrongValueToNotBeVisible'], // at the moment the core algorithms in ai-sync-core are not supporting the base plinth, so we should hide it for now
    value: 'NoBasePlinth',
    valueType: ValueTypes.SELECTION_WIDE,
  },
  {
    allowedValues: [],
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Enclosure Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.enclosureType,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: 'NoEnclosure',
    valueType: ValueTypes.SELECTION_WIDE,
  },
];

export const getHardwareSelectionSettings = (
  extruderDefinitions,
  printingBedDefinitions,
  plinthDefinitions,
  enclosureDefinitions,
  onChangeOverride,
  dispatch,
  printer,
  machineBrand,
  currentUser,
) => {
  const updateSettings = (settingName, definitions) => {
    if (definitions) {
      const updatedValues = sortByAttribute(definitions, 'displayName');
      return updatedValues?.map(({ displayName }) => displayName);
    }
    return hardwareSelectionSettings.find(
      (setting) => setting.settingName === settingName,
    )?.allowedValues;
  };

  const updatedExtruderSettings = updateSettings(
    constants.extruderType,
    extruderDefinitions.filter(
      ({ visibleToAll, visibleToOrganization }) =>
        (currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );
  const updatedPrintingBedSettings = updateSettings(
    constants.printingBedType,
    printingBedDefinitions.filter(
      ({ visibleToAll, visibleToOrganization }) =>
        (currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );
  const updatedPlinthSettings = updateSettings(
    constants.plinthType,
    plinthDefinitions.filter(
      ({ mounting, visibleToAll, visibleToOrganization }) =>
        (mounting == constants.ROBOT &&
          currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );
  const updatedBasePlinthSettings = updateSettings(
    constants.basePlinthType,
    plinthDefinitions.filter(
      ({ mounting, visibleToAll, visibleToOrganization }) =>
        (mounting == constants.BASE &&
          currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );
  const updatedEnclosureSettings = updateSettings(
    constants.enclosureType,
    enclosureDefinitions.filter(
      ({ visibleToAll, visibleToOrganization }) =>
        (currentUser?.role === UserRoles.SUPER_ADMIN &&
          (visibleToAll || visibleToOrganization)) ||
        currentUser?.role !== UserRoles.SUPER_ADMIN,
    ),
  );

  const updateExtruder = (changedSetting, valueString) => {
    if (valueString !== constants.CUSTOM.value) {
      const toolCalibration = getDefaultToolCalibrations(
        extruderDefinitions,
        valueString,
        machineBrand,
      )[0];
      updateDefaultToolCalibration(dispatch, printer, { ...toolCalibration });
    }

    onChangeOverride(changedSetting, valueString);
  };

  return hardwareSelectionSettings.map((setting) => {
    if (setting.settingName === constants.extruderType) {
      // Add the additional value to the allowedValues array
      updatedExtruderSettings.push(constants.CUSTOM);
      return {
        ...setting,
        allowedValues: updatedExtruderSettings,
        onChangeOverride: updateExtruder,
      };
    }
    if (setting.settingName === constants.printingBedType) {
      // Add the additional value to the allowedValues array
      updatedPrintingBedSettings.push(constants.CUSTOM);
      return {
        ...setting,
        allowedValues: updatedPrintingBedSettings,
        onChangeOverride,
      };
    }
    if (setting.settingName === constants.plinthType) {
      // Add the additional value to the allowedValues array
      updatedPlinthSettings.push(constants.CUSTOM);
      return {
        ...setting,
        allowedValues: updatedPlinthSettings,
        onChangeOverride,
      };
    }
    if (setting.settingName === constants.basePlinthType) {
      // Add the additional value to the allowedValues array
      updatedBasePlinthSettings.push(constants.CUSTOM);
      return {
        ...setting,
        allowedValues: updatedBasePlinthSettings,
        onChangeOverride,
      };
    }
    if (setting.settingName === constants.enclosureType) {
      // Add the additional value to the allowedValues array
      updatedEnclosureSettings.push(constants.CUSTOM);
      return {
        ...setting,
        allowedValues: updatedEnclosureSettings,
        onChangeOverride,
      };
    }
    return setting;
  });
};
