import { takeEvery, call, put, select, delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { openModal, closeModal } from '@actions/modalActions';
import { getSelectedWorkflow } from '@selectors/workspaceSelectors';
import { getHiddenInputs } from '@selectors/conceptSelectors';
import {
  fetchTemplatesSuccess,
  fetchTemplatesFailure,
  explodeTemplateSuccess,
  explodeTemplateFailure,
  setCurrentStage,
  setCreateTemplateCompletedStage,
  createTemplateSuccess,
  createTemplateFailure,
  resetCreateTemplate,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  addTemplateToFavouriteSuccess,
  addTemplateToFavouriteFailure,
  removeTemplateFromFavouriteSuccess,
  removeTemplateFromFavouriteFailure,
} from '@actions/templatesActions';
import { getCompletedStages } from '@selectors/templatesSelector';
import * as actionTypes from '@constants/actionTypes';

export const CREATE_TEMPLATE_STAGES = {
  CREATE_TEMPLATE: 'creatingTemplate',
  UPDATE_WORKFLOW: 'updatingWorkflow',
};

const VISUAL_DELAY = 800;
const FINAL_NAVIGATION_DELAY = 1300;

export function* fetchTemplates() {
  try {
    const response = yield client.get(endpoints.templates);
    const templates = response?.data || [];

    yield put(fetchTemplatesSuccess(templates));
  } catch (error) {
    yield put(fetchTemplatesFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* explodeTemplate({ payload }) {
  const { conceptId, operatorId } = payload;
  try {
    const url = endpoints.explodeTemplate
      .replace(':conceptId', conceptId)
      .replace(':operatorId', operatorId);
    const response = yield client.post(url);
    const workflow = response?.data || {};

    yield put(explodeTemplateSuccess(workflow));
  } catch (error) {
    yield put(explodeTemplateFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* createTemplate({ payload }) {
  const { formValues } = payload;
  const selectedWorkflow = yield select(getSelectedWorkflow());
  const hiddenInputs = yield select(getHiddenInputs());
  const completedStages = yield select(getCompletedStages);
  const uploadedFile = formValues?.logo?.file;

  const isStageCompleted = (stageName = '') => {
    const stage = completedStages?.find(({ name }) => name === stageName);

    return !!stage;
  };

  try {
    // Create template stage
    if (!isStageCompleted(CREATE_TEMPLATE_STAGES.CREATE_TEMPLATE)) {
      yield put(setCurrentStage(CREATE_TEMPLATE_STAGES.CREATE_TEMPLATE));

      const formData = new FormData();

      formValues?.exposedInputIds.forEach((exposedInput) => {
        formData.append('exposed_values', exposedInput);
      });
      if (
        !formValues?.exposedInputIds?.length ||
        formValues?.exposedInputIds?.length == 0
      ) {
        formData.append('exposed_values', []);
      }

      if (uploadedFile) {
        formData.append('logo', uploadedFile);
      }

      formData.append('template_name', formValues?.name);
      formData.append('base_workflow_id', selectedWorkflow?.id);
      hiddenInputs.forEach((hiddenInput) => {
        formData.append('hidden_inputs', hiddenInput.id);
      });

      const response = yield client.post(endpoints.templates, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      yield delay(VISUAL_DELAY);

      yield put(
        setCreateTemplateCompletedStage(
          CREATE_TEMPLATE_STAGES.CREATE_TEMPLATE,
          response.data,
        ),
      );

      yield call(fetchTemplates);
    }

    // Create template stage
    if (!isStageCompleted(CREATE_TEMPLATE_STAGES.UPDATE_WORKFLOW)) {
      yield put(setCurrentStage(CREATE_TEMPLATE_STAGES.UPDATE_WORKFLOW));

      yield put({ type: actionTypes.FETCH_WORKFLOW });

      const url = endpoints.concept.replace(':conceptId', selectedWorkflow?.id);
      const workflowResponse = yield client.get(url);
      const workflow = workflowResponse.data;

      if (!isEmpty(workflow)) {
        // yield put(
        //   updateHiddenInputsAndOutputs(workflow?.operators, workflow?.version),
        // );

        yield put({
          type: actionTypes.FETCH_WORKFLOW_SUCCEEDED,
          payload: workflow,
        });
      }

      yield put(
        setCreateTemplateCompletedStage(
          CREATE_TEMPLATE_STAGES.UPDATE_WORKFLOW,
          workflow,
        ),
      );

      yield call(fetchDefaultOperators, workflow);
    }
  } catch (error) {
    yield delay(VISUAL_DELAY);

    yield put(createTemplateFailure(error));

    return;
  }

  yield put(createTemplateSuccess());

  yield delay(FINAL_NAVIGATION_DELAY);

  yield put(closeModal());
  yield put(resetCreateTemplate());
}

export function* deleteTemplate({ payload }) {
  const { templateId } = payload;
  try {
    const url = endpoints.template.replace(':templateId', templateId);
    yield client.delete(url);

    const workflow = yield select(getSelectedWorkflow());

    yield put(deleteTemplateSuccess(workflow));

    yield call(fetchDefaultOperators, workflow);

    yield call(fetchTemplates);
  } catch (error) {
    const {
      response: { data },
    } = error;
    const errorMessage = data?.error?.message;

    yield put(deleteTemplateFailure(error));

    if (errorMessage) {
      yield put(
        openModal({
          type: 'deleteTemplateWarning',
          message: errorMessage,
        }),
      );

      return;
    }

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* addTemplateToFavourite({ payload }) {
  const { templateId } = payload;

  try {
    const url = endpoints.template.replace(':templateId', templateId);

    yield client.patch(url, { favourite: true });
    yield put(addTemplateToFavouriteSuccess());
    yield call(fetchTemplates);
  } catch (error) {
    yield put(addTemplateToFavouriteFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* removeTemplateFromFavourite({ payload }) {
  const { templateId } = payload;

  try {
    const url = endpoints.template.replace(':templateId', templateId);

    yield client.patch(url, { favourite: false });
    yield put(removeTemplateFromFavouriteSuccess());
    yield call(fetchTemplates);
  } catch (error) {
    yield put(removeTemplateFromFavouriteFailure(error));

    // eslint-disable-next-line
    console.error(error);
  }
}

function* fetchDefaultOperators(workflow) {
  yield put({ type: actionTypes.FETCH_DEFAULT_OPERATORS });

  const defaultOperatorsResponse = yield client.get(
    endpoints.conceptsDefaultOperators,
    {
      params: {
        workflowId: workflow?.id,
      },
    },
  );
  const defaultOperators = defaultOperatorsResponse.data;

  const version = workflow?.version;

  yield put({
    type: actionTypes.FETCH_DEFAULT_OPERATORS_SUCCEEDED,
    payload: {
      version,
      operators: defaultOperators,
    },
  });
}

export default [
  takeEvery(actionTypes.FETCH_TEMPLATES_REQUEST, fetchTemplates),
  takeEvery(actionTypes.EXPLODE_TEMPLATE_REQUEST, explodeTemplate),
  takeEvery(actionTypes.CREATE_TEMPLATE_REQUEST, createTemplate),
  takeEvery(actionTypes.DELETE_TEMPLATE_REQUEST, deleteTemplate),
  takeEvery(
    actionTypes.ADD_TEMPLATE_TO_FAVOURITE_REQUEST,
    addTemplateToFavourite,
  ),
  takeEvery(
    actionTypes.REMOVE_TEMPLATE_FROM_FAVOURITE_REQUEST,
    removeTemplateFromFavourite,
  ),
];
