import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSelector, useDispatch } from 'react-redux';
import { getConfig } from '@actions/AuthenticatedFetch';
import { getCurrentUser } from '@selectors/loginSelectors';
import {
  CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
  CONNECT_TO_TOOLPATH_SOCKET,
  EVENTS_WEBSOCKET_CREATED,
  PRINTER_HEARTBEAT,
  PRINT_STATUS,
  ALL_SENSORS_DATA,
} from '@constants/actionTypes';
import { tokenAuthentication } from '@actions/loginActions';
import { PrinterStatuses } from '@constants/printerStatuses';
import { WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT } from '@constants/websocket';
import { useCallback, useEffect, useMemo } from 'react';

const WS_MESSAGE_TYPE = {
  ALL_SENSORS_DATA: 'ALL_SENSORS_DATA',
  PRINTER_HEARTBEAT: 'PRINTER_HEARTBEAT',
  PRINT_STATUS: 'PRINT_STATUS',
};

export default function usePrinterSocket() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());

  const Config = getConfig();
  const baseUrl = Config.baseUrlws || 'ws://localhost/';

  const urlProvider = useCallback(async () => {
    const token = currentUser?.token;

    return baseUrl + 'ws/events' + '?token=' + encodeURIComponent(token);
  }, [baseUrl, currentUser?.token]);

  const wsOptions = useMemo(
    () => ({
      connectionTimeout: 2000,
      minReconnectionDelay: 500,
      maxReconnectionDelay: 3000,
    }),
    [],
  );

  const ws = useMemo(
    () => new ReconnectingWebSocket(urlProvider, [], wsOptions),
    [urlProvider, wsOptions],
  );

  /* 
    Shape: { [printerId]: timeoutFunction };

    When a 'PRINTER_HEARTBEAT' event is received, the printer status gets
    updated. It usually fires every ~5 seconds. To know if the printer has
    stopped sending updates, we set timeouts for each printer that receives
    events. We give a 10 second window after an event is received to receive
    another event for the printer. In that case the timer is reset, and 10
    seconds are counted from the new event. This goes until in the 10 seconds
    no new event is received, then we consider the printer offline, and
    dispatch a status for it.
  */
  const timeoutOfPrinters = useMemo(() => ({}), []);

  const handlePrintersReceivedMessage = useCallback(
    (message) => {
      const event = JSON.parse(message.data);
      // TODO for all of these, instead of blindy assigning the data, we should
      // decompose it explicitly into properties as a means of documenting
      // here the expected data properties

      // IMPORTANT NOTE:
      // all of the actions dispatched below will be catched by
      // not only toolpathReducer, but analyticsReducer too

      switch (event.type) {
        case WS_MESSAGE_TYPE.ALL_SENSORS_DATA:
          dispatch({
            type: ALL_SENSORS_DATA,
            payload: {
              printerId: event.data.printerId,
              sensorsData: event.data.payload,
              time: event.data.time,
            },
          });
          break;

        case WS_MESSAGE_TYPE.PRINTER_HEARTBEAT: {
          const printerId = event?.data?.printerId;
          if (timeoutOfPrinters[printerId]) {
            clearTimeout(timeoutOfPrinters[printerId]);
          }
          timeoutOfPrinters[printerId] = setTimeout(() => {
            timeoutOfPrinters[printerId] = null;
            dispatch({
              type: PRINTER_HEARTBEAT,
              payload: {
                printerId,
                status: PrinterStatuses.IDLE,
                time: 0,
              },
            });
          }, 10000);
          dispatch({
            type: PRINTER_HEARTBEAT,
            payload: event.data,
          });
          break;
        }

        case WS_MESSAGE_TYPE.PRINT_STATUS:
          dispatch({
            type: PRINT_STATUS,
            payload: {
              printerId: event.data.printerId,
              printId: event.data.printId,
              instructionsComplete: event.data.instructionsComplete,
              instructionsRemaining: event.data.instructionsRemaining,
              time: event.data.time,
            },
          });
          break;
      }
    },
    [dispatch, timeoutOfPrinters],
  );

  useEffect(() => {
    ws.addEventListener('open', () => {
      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket open');
      }

      dispatch({
        type: CONNECT_TO_TOOLPATH_SOCKET,
      });
    });

    dispatch({
      type: EVENTS_WEBSOCKET_CREATED,
      payload: ws,
    });
  }, [dispatch, ws]);

  useEffect(() => {
    ws.addEventListener('close', (closeEvent) => {
      const retryCount = closeEvent?.target?._retryCount || 0;
      const shouldDispatchCloseAction = retryCount <= 1;
      const shouldValidateToken =
        retryCount === WEBSOCKET_VALIDATE_TOKEN_RECONNECTION_LIMIT;

      if (DEVELOPMENT_ENV) {
        // eslint-disable-next-line no-console
        console.log('WebSocket closed');
      }

      if (shouldDispatchCloseAction) {
        dispatch({
          type: CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
        });
      }

      if (shouldValidateToken) {
        dispatch(dispatch(tokenAuthentication()));
      }
    });
  }, [dispatch, ws]);

  // Printers and sensors data messages handler
  useEffect(() => {
    Object.values(timeoutOfPrinters)?.forEach?.(clearTimeout);
    ws.removeEventListener('message', handlePrintersReceivedMessage);
    ws.addEventListener('message', handlePrintersReceivedMessage);

    return () => {
      Object.values(timeoutOfPrinters)?.forEach?.(clearTimeout);
      ws.removeEventListener('message', handlePrintersReceivedMessage);
    };
  }, [handlePrintersReceivedMessage, ws, timeoutOfPrinters]);

  useEffect(() => {
    return () => {
      ws.close();
    };
  }, [ws]);

  return ws;
}
