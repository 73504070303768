import styled, { css } from 'styled-components';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexCenterVertical}
`;

export const SelectorWrapper = styled.div`
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level2};
  `}
`;
