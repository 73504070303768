import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenterVertical}
  align-items: center;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding-inline: ${spacing.level4};
  `}
`;

export const ColorLine = styled.div`
  min-width: 65px;
  flex: 1;
  height: 4px;

  ${({ color, colorType, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.surfaceContainerHighest};

    ${when(
      colors?.[color],
      `
        background-color: ${colors[color]};
      `,
    )}

    ${when(
      ['layers', 'thickness', 'analysis'].includes(colorType),
      `
        background: linear-gradient(
          -90deg,
          hsl(0,100%,50%) 0%,
          hsl(60,100%,50%) 25%,
          hsl(120,100%,50%) 50%,
          hsl(180,100%,50%) 75%,
          hsl(240,100%,50%) 100%
          );
      `,
    )}

    ${when(
      colorType === 'layersUniform',
      `
        background: rgba(0,60,200,1);
      `,
    )}

    ${when(
      colorType === 'thicknessUniform',
      `
        background: rgba(255,180,0,1);
      `,
    )}

    ${when(
      colorType === 'analysisUniform',
      `
        background: hsl(180,100%,50%);
      `,
    )}
  `}
`;
