import { useEffect, useState } from 'react';
import { themeTypes } from '@constants/accountSettings';

export default function useSystemTheme() {
  const darkSystemTheme =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const defaultTheme = darkSystemTheme
    ? themeTypes.DARK.value
    : themeTypes.LIGHT.value;
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    window
      ?.matchMedia?.('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        const nextTheme = event.matches
          ? themeTypes.DARK.value
          : themeTypes.LIGHT.value;

        setTheme(nextTheme);
      });
  }, []);

  return theme;
}
