import {
  CONNECT_TO_TOOLPATH_SOCKET,
  CLOSED_CONNECTION_TO_TOOLPATH_SOCKET,
  EVENTS_WEBSOCKET_CREATED,
  LOG_OUT_SUCCESS,
  TOKEN_EXPIRE_LOG_OUT_SUCCESS,
} from '@constants/actionTypes';

const initialState = {
  isConnectedToSocket: false,
  eventsWs: null,
};

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_TO_TOOLPATH_SOCKET:
      return {
        ...state,
        isConnectedToSocket: true,
      };
    case CLOSED_CONNECTION_TO_TOOLPATH_SOCKET:
      return {
        ...state,
        isConnectedToSocket: false,
      };
    case EVENTS_WEBSOCKET_CREATED:
      return {
        ...state,
        eventsWs: action.payload,
      };
    case LOG_OUT_SUCCESS:
    case TOKEN_EXPIRE_LOG_OUT_SUCCESS:
      return {
        ...state,
        isConnectedToSocket: false,
        eventsWs: null,
      };

    default:
      return state;
  }
};

export default websocketReducer;
