import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import useMaterialMutations from '@hooks/materials/useMaterialMutations';
import { materialConstants, materialTypeLabel } from '@constants/materials';

export default function useMaterialList() {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const { deleteMaterialMutation } = useMaterialMutations();

  const getMaterialDropDownMenuActions = useCallback(
    (material = {}) => {
      const actions = [
        {
          leadingIconName: 'content_copy_0',
          label: intl.formatMessage({
            id: 'general.duplicate',
            defaultMessage: 'Duplicate',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.CLONE_MATERIAL, { material }),
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-material-dialog',
              title: intl.formatMessage({
                id: 'materials.delete_material_dialog.header.title',
                defaultMessage: 'Delete Material',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'materials.delete_material_dialog.subtitle',
                  defaultMessage:
                    'The Material named <b>{materialName}</b> will be permanently deleted and all its configurations will be lost. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  materialName: material.materialName,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: () => {
                deleteMaterialMutation.mutateAsync({
                  id: material.id,
                });
              },
            });
          },
        },
      ];

      return actions;
    },
    [deleteMaterialMutation, showDialog, intl],
  );

  const getMaterialTypeDropDownMenuItems = useCallback(
    (values) =>
      [
        materialConstants.MATERIAL_METAL_WIRE,
        materialConstants.MATERIAL_METAL_POWDER,
        materialConstants.MATERIAL_FILAMENT,
        materialConstants.MATERIAL_PELLET,
        materialConstants.MATERIAL_THERMOSET_RESIN,
      ]
        .reduce((acc, category) => {
          return [
            ...acc,
            {
              label: materialTypeLabel(category),
              selected: values?.type?.value === category,
              formFieldValue: {
                label: materialTypeLabel(category),
                value: category,
              },
            },
          ];
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label)),
    [],
  );

  return {
    getMaterialDropDownMenuActions,
    getMaterialTypeDropDownMenuItems,
  };
}
