import React from 'react';
import { BedTypeComponent } from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/BedTypeComponent';

export const TwoAxisSetting = () => {
  const dimensionsSettings = ['a1', 'a2', 'd1', 'd3'].map((field) => ({
    field,
  }));

  const parametersSettings = [
    { field: 'joint1MaxSpeed' },
    { field: 'joint2MaxSpeed' },
    { field: 'joint1IsInverted', checkbox: true },
    { field: 'joint2IsInverted', checkbox: true },
  ];

  return (
    <>
      <BedTypeComponent
        titleId={'printers.settings.bed.type.dimensions.label'}
        label={'dimensions.distance'}
        settings={dimensionsSettings}
        specialCondition
        withDiagram={'TWO_AXIS_BED'}
      />
      <BedTypeComponent
        noBorderBottom
        titleId={'printers.settings.bed.type.parameters.label'}
        label={'bed.type.parameters'}
        settings={parametersSettings}
      />
    </>
  );
};
