import {
  LeftColumn,
  RightColumn,
  Wrapper,
} from '@components/2-molecules/SettingDropDown/SettingDropDown.styled';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import DropDownField, {
  DROP_DOWN_FIELD_SIZE_COMPACT,
} from '@components/1-atoms/DropDownField';
import React from 'react';
import PropTypes from 'prop-types';
import Field from '@components/1-atoms/Field';
import { useIntl } from 'react-intl';
import {
  PostProcessorSettingWrapper,
  TextLeftColumn,
  TextWrapper,
} from '@components/Printers/PostProcessorConfig/PostProcessorSetting.styled';
import TextArea from '@components/1-atoms/TextArea';
import { useFormikContext } from 'formik';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

const PostProcessorSetting = ({
  dataTestId = 'post-processor-setting',
  label,
  descriptionLabel,
  renderAsTextArea,
  withIdentation,
  noPaddingBottom,
  noPaddingTop,
  ...fieldProps
}) => {
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();
  const dirty = checkIfFieldIsDirty(
    values,
    initialValues,
    !renderAsTextArea
      ? `${fieldProps.field.name}.value`
      : fieldProps.field.name,
  );
  const showDropDownField = !renderAsTextArea;

  return (
    <>
      {label && (
        <>
          <PostProcessorSettingWrapper
            dataTestId={`${dataTestId}__wrapper`}
            withIdentation={withIdentation}
            noPaddingBottom={descriptionLabel || noPaddingBottom}
            noPaddingTop={noPaddingTop}
          >
            <LeftColumn>
              <Text
                dataTestId={`${dataTestId}__label`}
                variant={TEXT_VARIANT_BODY_MEDIUM}
                color="onSurface"
              >
                {label}
              </Text>
            </LeftColumn>

            {!renderAsTextArea && (
              <RightColumn>
                {showDropDownField && (
                  <DropDownField
                    dataTestId={`${dataTestId}__drop-down-field`}
                    {...fieldProps}
                    dirty={dirty}
                    size={DROP_DOWN_FIELD_SIZE_COMPACT}
                  />
                )}
              </RightColumn>
            )}
          </PostProcessorSettingWrapper>
          {descriptionLabel && (
            <TextWrapper
              dataTestId={`${dataTestId}__text-wrapper-field`}
              withIdentation={withIdentation}
            >
              <TextLeftColumn>
                <Text
                  dataTestId={`${dataTestId}__description_label`}
                  variant={TEXT_VARIANT_BODY_MEDIUM}
                  color="onSurfaceVariant"
                >
                  {descriptionLabel}
                </Text>
              </TextLeftColumn>
            </TextWrapper>
          )}
        </>
      )}
      {renderAsTextArea && (
        <Wrapper
          dataTestId={`${dataTestId}__text-area-wrapper`}
          withIdentation={withIdentation}
          noPaddingBottom
        >
          <TextArea
            dataTestId={`${dataTestId}__text-area-field`}
            rows={5}
            resizible
            placeholder={intl.formatMessage({
              id: 'printers.postprocessor.textarea.placeholder',
            })}
            supportingText={intl.formatMessage({
              id: 'printers.postprocessor.textarea.maximum',
            })}
            dirty={dirty}
            {...fieldProps}
          />
        </Wrapper>
      )}
    </>
  );
};

PostProcessorSetting.propTypes = {
  dataTestId: PropTypes.string,
  fieldProps:
    PropTypes.shape(Field.propTypes) ||
    PropTypes.shape(DropDownField.propTypes),
  label: PropTypes.string,
  descriptionLabel: PropTypes.string,
  withIdentation: PropTypes.bool,
  renderAsTextArea: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
};

export default PostProcessorSetting;
