import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '@hoc/withTooltip';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import DropDownField, {
  DROP_DOWN_FIELD_SIZE_COMPACT,
} from '@components/1-atoms/DropDownField';
import IconButton from '@components/1-atoms/IconButton';
import {
  Wrapper,
  LeftColumn,
  RightColumn,
  DropDownContainer,
} from './SettingDropDownWithIconButton.styled';

const TextWithTooltip = withTooltip(Text);

const SettingDropDownWithIconButton = ({
  className,
  dataTestId = 'setting-drop-down-with-icon-button',
  dropDownField = {},
  iconButton = {},
  label,
  labelTooltip = {},
  withIdentation,
  form, // formik property
  field, // formik property
}) => {
  const withIconButton = !!iconButton?.iconName;

  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
      withIconButton={withIconButton}
    >
      <LeftColumn>
        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn>
        <DropDownContainer>
          <DropDownField
            dataTestId={`${dataTestId}__drop-down-field`}
            {...dropDownField}
            size={DROP_DOWN_FIELD_SIZE_COMPACT}
            field={field}
            form={form}
          />
        </DropDownContainer>

        {withIconButton && (
          <IconButton
            dataTestId={`${dataTestId}__icon-button`}
            {...iconButton}
          />
        )}
      </RightColumn>
    </Wrapper>
  );
};

SettingDropDownWithIconButton.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dropDownField: PropTypes.shape(DropDownField.propTypes),
  iconButton: PropTypes.shape(IconButton.propTypes),
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.object,
};

export default SettingDropDownWithIconButton;
