import { ExternalAxis } from '../ExternalAxis';
import { Printer } from '../Printer';
import { printerConstants as constants } from '../../constants/printers/printerConstants';
import { degToRad } from 'three/src/math/MathUtils';

export class RotaryTable extends ExternalAxis {
  constructor(
    printerSettings,
    machineDefaults,
    printingBedDefinitions,
    plinthDefinitions,
  ) {
    super(
      printerSettings,
      machineDefaults,
      printingBedDefinitions,
      plinthDefinitions,
    );

    this.homePosition = parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        printerSettings,
        constants.ROTARY_HOME_ANGLE,
        0,
      ),
    );
    this.zeroAngle = parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        printerSettings,
        constants.ROTARY_ZERO_ANGLE,
        0,
      ),
    );
    this.moveToHome();
  }

  getOffsetParameter() {
    return parseFloat(
      Printer.getSettingValueFromTypeOrDefault(
        this.printerSettings,
        constants.ROTARY_ZERO_ANGLE,
        0,
      ),
    );
  }

  moveToHome() {
    this[constants.axis + 1].rotation.set(0, 0, degToRad(this.homePosition));
  }
}
