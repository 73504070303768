import React, { forwardRef, useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { useIntl } from 'react-intl';
import DropDownMenuContext from '@contexts/DropDownMenuContext';
import IconButton from '@components/1-atoms/IconButton';
import { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import { preventDefault, stopPropagation } from '@utils/interactionEvents';
import {
  Wrapper,
  OperatorDetails,
  OperatorIcon,
  OperatorTitle,
  MoreButton,
  Loader,
} from './OperatorHeader.styled';
import {
  OPERATOR_HEADER_STATUS_ALERT,
  OPERATOR_HEADER_STATUS_ALERT_MISS,
  OPERATOR_HEADER_STATUS_HIGHLIGHT,
} from '@utils/operatorStatus';
import OperatorStatus from '@components/2-molecules/OperatorStatus';

const CLICK_EVENT_TIMEOUT = 200;

const OperatorHeader = forwardRef(
  (
    {
      className,
      dataTestId = 'operator-header',
      diffAdded,
      diffModified,
      diffRemoved,
      disableActions,
      disabled,
      disableDrag,
      disableExpand,
      dragged,
      dragHandleProps = {},
      expanded,
      highlighted,
      iconName,
      loading,
      moreButtonDropDownMenuItems,
      moreButtonDropDownMenuItemsGetter,
      onComputeButtonClick,
      onDoubleClick,
      onExpandButtonClick,
      onSelect,
      readOnly,
      selectable,
      selected,
      status,
      statusOnly,
      title,
    },
    ref,
  ) => {
    const intl = useIntl();
    const { dropDownMenuId } = useContext(DropDownMenuContext);
    const moreButtonDropDownMenuId = useRef(
      uniqueId('operator-more-button-drop-down-menu-'),
    ).current;
    const clickTimeoutRef = useRef();
    const disableOperatorActions =
      loading || readOnly || statusOnly || disableActions || disabled;
    const lockMoreButton = dropDownMenuId === moreButtonDropDownMenuId;
    const showCollapseButton = !readOnly && !statusOnly;
    const showStatusIcon = !loading && (!readOnly || statusOnly);

    const handleClick = useCallback(
      (event) => {
        if (clickTimeoutRef.current) {
          clearTimeout(clickTimeoutRef.current);
        }

        clickTimeoutRef.current = setTimeout(() => {
          onSelect(event);
        }, CLICK_EVENT_TIMEOUT);
      },
      [onSelect],
    );

    const handleDoubleClick = useCallback(
      (event) => {
        if (clickTimeoutRef.current) {
          clearTimeout(clickTimeoutRef.current);
        }

        onDoubleClick(event);
      },
      [onDoubleClick],
    );

    return (
      <Wrapper
        ref={ref}
        dataTestId={dataTestId}
        diffAdded={diffAdded}
        diffModified={diffModified}
        diffRemoved={diffRemoved}
        className={className}
        disabled={disabled}
        disableActions={disableOperatorActions}
        disableDrag={disableDrag}
        dragged={dragged}
        onClick={
          !selectable || disableOperatorActions
            ? undefined
            : preventDefault(stopPropagation(handleClick))
        }
        onDoubleClick={
          disableOperatorActions || !onDoubleClick
            ? undefined
            : preventDefault(stopPropagation(handleDoubleClick))
        }
        readOnly={readOnly || statusOnly}
        selectable={selectable}
        selected={selected}
        highlighted={highlighted}
        tabIndex={disableOperatorActions ? -1 : 0}
        {...(dragHandleProps || {})}
        // eslint-disable-next-line no-console
      >
        {showCollapseButton && (
          <IconButton
            disabled={disableExpand}
            title={intl.formatMessage({
              id: expanded ? 'general.collapse' : 'general.expand',
              defaultMessage: expanded ? 'Collapse' : 'Expand',
            })}
            iconName={expanded ? 'expand_less_0' : 'expand_more_0'}
            onClick={
              disabled
                ? undefined
                : preventDefault(stopPropagation(onExpandButtonClick))
            }
          />
        )}

        <OperatorDetails>
          <OperatorIcon dataTestId={`${dataTestId}__icon`} name={iconName} />

          <OperatorTitle
            dataTestId={`${dataTestId}__title`}
            variant={TEXT_VARIANT_LABEL_LARGE}
            color="onSurface"
          >
            {title}
          </OperatorTitle>
        </OperatorDetails>

        {!readOnly && (
          <MoreButton
            dataTestId={`${dataTestId}__more-button`}
            title={intl.formatMessage({
              id: 'general.more',
              defaultMessage: 'More',
            })}
            dropDownMenuId={moreButtonDropDownMenuId}
            dropDownMenuItems={moreButtonDropDownMenuItems}
            getDropDownMenuItems={moreButtonDropDownMenuItemsGetter}
            iconName="more_vert_0"
            lock={lockMoreButton}
            onClick={
              disableOperatorActions
                ? undefined
                : preventDefault(stopPropagation())
            }
          />
        )}

        {showStatusIcon && (
          <OperatorStatus
            status={status}
            disabled={loading || readOnly || disableActions || disabled}
            onComputeButtonClick={onComputeButtonClick}
          />
        )}

        {loading && <Loader />}
      </Wrapper>
    );
  },
);

OperatorHeader.displayName = 'OperatorHeader';

OperatorHeader.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  diffAdded: PropTypes.bool,
  diffModified: PropTypes.bool,
  diffRemoved: PropTypes.bool,
  disableActions: PropTypes.bool,
  disabled: PropTypes.bool,
  disableDrag: PropTypes.bool,
  disableExpand: PropTypes.bool,
  dragged: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  expanded: PropTypes.bool,
  highlighted: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  moreButtonDropDownMenuItems: PropTypes.array,
  moreButtonDropDownMenuItemsGetter: PropTypes.func,
  onExpandButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  readOnly: PropTypes.bool,
  onComputeButtonClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  status: PropTypes.oneOf([
    OPERATOR_HEADER_STATUS_HIGHLIGHT,
    OPERATOR_HEADER_STATUS_ALERT,
    OPERATOR_HEADER_STATUS_ALERT_MISS,
  ]),
  statusOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default OperatorHeader;
