import * as actionTypes from '@constants/actionTypes';
import { DEFAULT_CAMERA_CONFIG } from '@constants/camera';

const initialState = {
  data: {},
  ui: {
    cameraConfig: DEFAULT_CAMERA_CONFIG,
    focusCameraOnObject: false,
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCENE_CAMERA_CONFIG: {
      return {
        ...state,
        ui: {
          ...state.ui,
          cameraConfig: action?.payload?.config,
        },
      };
    }

    case actionTypes.FOCUS_CAMERA_ON_OBJECT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          focusCameraOnObject: action?.payload?.focus,
        },
      };
    }

    default:
      return state;
  }
};

export default modalReducer;
