import { extrusionTypeDefinitions } from '@constants/printers/extrusionTypeDefinitions';

export const toolTypeDefinitions = {
  EXTRUDER: {
    value: 'EXTRUDER',
    label: 'Extruder',
  },
};

export const defaultExtruderProperties = {
  extrusionType: extrusionTypeDefinitions.THERMOPLASTIC_FILAMENT,
};
