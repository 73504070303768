export const defaultPrinterProps = {
  extruderType: 'Aibuild AiMaker V5',
  extrusionControlDynamic: false,
  extrusionControlSynchronous: false,
  scanSettingsListVisible: true,
  printCoolingSettingsListVisible: true,
  extruderSettingsType: 'Default',
  printingBedSettingsType: 'Default',
  plinthSettingsType: 'Default',
  printingBedType: 'Parametric',
  plinthType: 'NoPlinth',
  basePlinthType: 'NoBasePlinth',
  enclosureType: 'NoEnclosure',
  programType: 'GCODE',
  postProcessor: 'DEFAULT',
  toolpathReferenceFrame: 'ROBOT',
  serialCode: '',
  maxSpeedMultiplier: 100.0,
  baseZOffset: 0.0,
  baseRef1X: 1000.0,
  baseRef1Y: 1200.0,
  baseRef1Z: 300.0,
  baseRef2X: 2200.0,
  baseRef2Y: 1200.0,
  baseRef2Z: 300.0,
  baseRef3X: 1000.0,
  baseRef3Y: -1200.0,
  baseRef3Z: 300.0,
  toolMountingAngle: 0.0,
  toolX: 360.0,
  toolY: 0.0,
  toolZ: 69.0,
  toolA: -90.0,
  toolB: 0.0,
  toolC: 90.0,
  defaultBaseId: 0,
  defaultToolId: 0,
  tcpCorrectionX: 0.0,
  tcpCorrectionY: 0.0,
  tcpCorrectionZ: 0.0,
  workspaceOriginX: 0.0,
  workspaceOriginY: 0.0,
  workspaceOriginZ: 0.0,
  workspaceX1: 1000.0,
  workspaceX2: 2400.0,
  workspaceY1: 2400.0,
  workspaceY2: 2400.0,
  workspaceZ1: 0.0,
  workspaceZ2: 2500.0,
  forceUpdateDuringPrint: false,
  updateSettingsOnSendPrint: true,
  gcodeEIsRelative: false,
};
