import React, { useCallback, useEffect } from 'react';
import { ROUTES } from '@app/constants/router';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logInFailure, setCurrentUser } from '@app/actions/loginActions';
import client from '@app/api/client';
import endpoints from '@app/api/endpoints';
import PageLoaderPortal from '@app/components/2-molecules/PageLoaderPortal';
import useQuery from '@app/hooks/useQuery';

export default function SSOTokenCaptor() {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();

  const loginFailed = useCallback(() => {
    dispatch(logInFailure());
    history.push(ROUTES.LOGIN);
  }, [dispatch, history]);

  useEffect(() => {
    const getUser = async () => {
      const token = query?.get('token');

      if (!token) {
        return loginFailed();
      }

      try {
        const user = await client
          .get(endpoints.getCurrentUser, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data);
        if (!user) {
          return loginFailed();
        }
        dispatch(setCurrentUser({ ...user, token: token }));
      } catch (e) {
        return loginFailed();
      }
      history.push(ROUTES.HOME);
    };
    getUser();
  }, [query, dispatch, history, loginFailed]);

  return <PageLoaderPortal show />;
}
