import actionTypes from '@actions';

export const expireToken = () => ({
  type: actionTypes.TOKEN_EXPIRED,
});

export const login = (username, password, mfaCode, params = {}) => ({
  type: actionTypes.LOG_IN_REQUEST,
  payload: { username, password, mfaCode, params },
});

export const logInFailure = () => ({
  type: actionTypes.LOG_IN_FAILURE,
});

export const logInSuccess = (user = {}) => ({
  type: actionTypes.LOG_IN_SUCCESS,
  payload: { user },
});

export const resetLoginFailure = () => ({
  type: actionTypes.RESET_LOG_IN_FAILURE,
});

export const setCurrentUser = (user = {}) => ({
  type: actionTypes.SET_CURRENT_USER,
  payload: { user },
});

export const setCurrentUserPreference = (preferenceName, preferenceValue) => ({
  type: actionTypes.SET_CURRENT_USER_PREFERENCE,
  payload: {
    preferenceName,
    preferenceValue,
  },
});

export const logOut = ({ onSuccessCallback } = {}) => ({
  type: actionTypes.LOG_OUT_REQUEST,
  payload: { onSuccessCallback },
});

export const logOutFailure = () => ({
  type: actionTypes.LOG_OUT_FAILURE,
});

export const logOutSuccess = () => ({
  type: actionTypes.LOG_OUT_SUCCESS,
});

export const tokenExpireLogoutSuccess = () => ({
  type: actionTypes.TOKEN_EXPIRE_LOG_OUT_SUCCESS,
});

export const updateUserPreference = (
  preferenceName = '',
  preferenceValue = false,
) => ({
  type: actionTypes.UPDATE_USER_PREFERENCE,
  payload: {
    preferenceName,
    preferenceValue,
  },
});

export const updateUserSessionPreference = (
  preferenceName = '',
  preferenceValue = false,
) => ({
  type: actionTypes.UPDATE_USER_SESSION_PREFERENCE,
  payload: {
    preferenceName,
    preferenceValue,
  },
});

export const tokenAuthentication = (proceedWithNavigation) => ({
  type: actionTypes.TOKEN_AUTHENTIFICATION_REQUEST,
  payload: { proceedWithNavigation },
});

export const tokenAuthenticationFailure = () => ({
  type: actionTypes.TOKEN_AUTHENTIFICATION_FAILURE,
});

export const tokenAuthenticationSuccess = () => ({
  type: actionTypes.TOKEN_AUTHENTIFICATION_SUCCESS,
});

export const createResetPasswordToken = (username, callbacks) => ({
  type: actionTypes.CREATE_RESET_PASSWORD_TOKEN_REQUEST,
  payload: { username, callbacks },
});

export const createResetPasswordTokenSuccess = () => ({
  type: actionTypes.CREATE_RESET_PASSWORD_TOKEN_SUCCESS,
});

export const createResetPasswordTokenFailure = () => ({
  type: actionTypes.CREATE_RESET_PASSWORD_TOKEN_FAILURE,
});

export const resetPassword = (
  token,
  newPassword,
  confirmNewPassword,
  callbacks,
) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  payload: { token, newPassword, confirmNewPassword, callbacks },
});

export const resetPasswordSuccess = () => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = () => ({
  type: actionTypes.RESET_PASSWORD_FAILURE,
});
