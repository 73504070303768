import actionTypes from '@actions/index';

export const createWorkflowAnnotation = (
  createAnnotation,
  successCallback,
) => ({
  type: actionTypes.CREATE_WORKFLOW_ANNOTATION_REQUEST,
  payload: { createAnnotation, successCallback },
});
export const setCreateWorkflowAnnotationSuccess = (workflowAnnotation) => ({
  type: actionTypes.CREATE_WORKFLOW_ANNOTATION_SUCCESS,
  payload: workflowAnnotation,
});

export const setCreateWorkflowAnnotationFailure = () => ({
  type: actionTypes.CREATE_WORKFLOW_ANNOTATION_FAILURE,
});

export const fetchWorkflowAnnotations = (workflowId) => ({
  type: actionTypes.FETCH_WORKFLOW_ANNOTATIONS_REQUEST,
  payload: workflowId,
});

export const setFetchWorkflowAnnotationsSuccess = (workflowAnnotations) => ({
  type: actionTypes.FETCH_WORKFLOW_ANNOTATIONS_SUCCESS,
  payload: workflowAnnotations,
});

export const setFetchWorkflowAnnotationsFailure = () => ({
  type: actionTypes.FETCH_WORKFLOW_ANNOTATIONS_FAILURE,
});

export const deleteWorkflowAnnotation = (id, workflowId) => ({
  type: actionTypes.DELETE_WORKFLOW_ANNOTATION_REQUEST,
  payload: {
    id,
    workflowId,
  },
});
export const setDeleteWorkflowAnnotationSuccess = (id, workflowId) => ({
  type: actionTypes.DELETE_WORKFLOW_ANNOTATION_SUCCESS,
  payload: {
    id,
    workflowId,
  },
});

export const setDeleteWorkflowAnnotationFailure = () => ({
  type: actionTypes.DELETE_WORKFLOW_ANNOTATION_FAILURE,
});

export const updateWorkflowAnnotation = (id, update, successCallback) => ({
  type: actionTypes.UPDATE_WORKFLOW_ANNOTATION_REQUEST,
  payload: {
    id,
    update,
    successCallback,
  },
});
export const setUpdateWorkflowAnnotationSuccess = (id, update) => ({
  type: actionTypes.UPDATE_WORKFLOW_ANNOTATION_SUCCESS,
  payload: {
    id,
    update,
  },
});

export const setUpdateWorkflowAnnotationFailure = () => ({
  type: actionTypes.UPDATE_WORKFLOW_ANNOTATION_FAILURE,
});
