import styled, { css } from 'styled-components';
import { flexCenterVertical } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexCenterVertical}
  flex-wrap: wrap;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level6} 0;
  `}
`;
