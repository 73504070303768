import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const materialsQueryKeys = {
  materials: ['materials'],
};

export default function useMaterialQueries() {
  const materialsQuery = useQuery({
    queryKey: materialsQueryKeys.materials,
    queryFn: () => client.get(endpoints.materials).then((res) => res.data),
    initialData: [],
    enabled: false,
  });

  return {
    materialsQuery,
  };
}
