import { gantryComponentTypeDefinitions } from '@constants/printers/gantryComponentTypeDefinitions';

export const robotTypeDefinitions = {
  SIX_AXIS_ROBOT: {
    value: 'SIX_AXIS_ROBOT',
    label: '6-Axis Robot',
    number: 6,
  },
  GANTRY: {
    value: 'GANTRY',
    label: '3-Axis Gantry',
    number: 3,
  },
};

export const defaultSixAxisRobotProperties = {
  a1: '0',
  a2: '0',
  a3: '0',
  a4: '0',
  a5: '0',
  a6: '0',
  d1: '0',
  d2: '0',
  d3: '0',
  d4: '0',
  d5: '0',
  d6: '0',
};

export const defaultGantryProperties = {
  basisVector1X: '0',
  basisVector2X: '0',
  basisVector3X: '0',
  basisVector1Y: '0',
  basisVector2Y: '0',
  basisVector3Y: '0',
  basisVector1Z: '0',
  basisVector2Z: '0',
  basisVector3Z: '0',
  axis1Attachment: gantryComponentTypeDefinitions.AXIS_2,
  axis2Attachment: gantryComponentTypeDefinitions.AXIS_3,
  axis3Attachment: gantryComponentTypeDefinitions.EXTRUDER,
};
