import { useMutation } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const applicationMutationsKeys = {
  knowledgeCentre: ['showLoader', 'knowledgeCentre'],
};

export default function useApplicationMutations() {
  const generateKnowledgeCentreUrlMutation = useMutation({
    mutationKey: applicationMutationsKeys.knowledgeCentre,
    mutationFn: () =>
      client.get(endpoints.knowledgeCentre).then((res) => res.data),
  });

  return {
    generateKnowledgeCentreUrlMutation,
  };
}
