import React from 'react';
import { MODAL_IDS } from '@constants/modalDataTypes';
import ModalPortal from '@components/2-molecules/ModalPortal';
import { ICON_BUTTON_VARIANT_PLAIN } from '@components/1-atoms/IconButton';
import { Wrapper } from '@containers/Modals/FileBrowserModal/FileBrowser.styled';
import FilePageContent from '@components/File/FilePageContent';
import useModal from '@hooks/useModal';
import useProjectFiles from '@hooks/files/useProjectFiles';

const MODAL_ID = MODAL_IDS.FILE_BROWSER;
const FileBrowserModal = () => {
  const { getModalData } = useModal();
  const { projectId, selectFile, allowedFileTypes } = getModalData(MODAL_ID);

  const { fileListItems } = useProjectFiles({
    projectId,
    useStale: true,
    action: selectFile,
    allowedFileTypes,
  });

  return (
    <ModalPortal
      dataTestId="file-browser-modal"
      modalId={MODAL_ID}
      headerEndingIconButtonVariant={ICON_BUTTON_VARIANT_PLAIN}
      floatingHeader
      floatingFooter
      noFooterBackground
      disableContentPadding
    >
      <Wrapper data-testid="files-browswer">
        <FilePageContent projectId={projectId} fileListItems={fileListItems} />
      </Wrapper>
    </ModalPortal>
  );
};

export default FileBrowserModal;
