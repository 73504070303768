import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useDialog from '@hooks/useDialog';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { CHIP_VARIANT_PRIMARY } from '@components/1-atoms/Chip';
import PageList from '@components/3-organisms/PageList';

const ProjectsPageContent = ({
  headerLeadingIconButtonIconName,
  loading,
  onHeaderLeadingIconButtonClick,
  projectsListItems = [],
}) => {
  const intl = useIntl();
  const { showDialog } = useDialog();

  const showCreateProjectDialog = useCallback(() => {
    showDialog(ModalDataTypes.CREATE_FLOW);
  }, [showDialog]);

  return (
    <PageList
      dataTestId="projects-page-content"
      headerTitle={intl.formatMessage({
        id: 'projectspage.projects_list.title',
        defaultMessage: 'Projects',
      })}
      listItems={projectsListItems}
      headerLeadingIconButtonIconName={headerLeadingIconButtonIconName}
      onHeaderLeadingIconButtonClick={onHeaderLeadingIconButtonClick}
      loading={loading}
      createItemMessageIconName="category_0"
      createItemMessageTitle={intl.formatMessage({
        id: 'projectspage.projects_list.create_project_title',
        defaultMessage: 'No project yet',
      })}
      createItemMessagDescription={intl.formatMessage({
        id: 'projectspage.projects_list.create_project_description',
        defaultMessage: 'Create projects to organise manufacturing workflows',
      })}
      createItemMessageButtonLabel={intl.formatMessage({
        id: 'projectspage.projects_list.create_project_button',
        defaultMessage: 'Create Project',
      })}
      createItemMessageButtonIconName="add_0"
      createItemMessageOnButtonClick={showCreateProjectDialog}
      actionChips={[
        {
          id: 'projects-page-new-project-chip',
          variant: CHIP_VARIANT_PRIMARY,
          dataTestId: 'projects-page__create-new-project-chip',
          label: intl.formatMessage({
            id: 'projectspage.projects_list.action_chips.new_project',
            defaultMessage: 'New Project',
          }),
          leadingIconName: 'add_0',
          onClick: showCreateProjectDialog,
        },
      ]}
      sortOptions={[
        {
          label: intl.formatMessage({
            id: 'general.date',
            defaultMessage: 'Date',
          }),
          value: 'createdAt',
        },
        {
          label: intl.formatMessage({
            id: 'general.name',
            defaultMessage: 'Name',
          }),
          value: 'label',
        },
      ]}
    />
  );
};

ProjectsPageContent.propTypes = {
  headerLeadingIconButtonIconName: PropTypes.string,
  loading: PropTypes.bool,
  onHeaderLeadingIconButtonClick: PropTypes.func,
  projectsListItems: PropTypes.array,
};

export default ProjectsPageContent;
