import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useDialog from '@hooks/useDialog';
import useWorkflow from '@hooks/workflows/useWorkflow';
import useOperatorList from '@hooks/operators/useOperatorList';
import useOperatorQueries from '@hooks/operators/useOperatorQueries';
import useOperatorMutations from '@hooks/operators/useOperatorMutations';
import {
  selectActiveCanvasSelectionInput,
  selectFullScreenOperatorVisibility,
} from '@reducers/workflowSlice';
import { ModalDataTypes } from '@constants/modalDataTypes';
import { EventAction, EventCategory, sendEvent } from '@utils/GaTracker';
import ListItem from '@components/1-atoms/ListItem';
import Toolbar from '@components/2-molecules/Toolbar';
import DialogPortal from '@components/2-molecules/DialogPortal';
import { DIALOG_VARIANT_SIDE_VIEW } from '@components/2-molecules/Dialog';
import { TOOLTIP_POSITION_BOTTOM_RIGHT } from '@components/2-molecules/Tooltip';
import WorkflowDynamicNavigationBar from '@app/containers/WorkflowDynamicNavigationBar';
import OperatorsLibrary from '@containers/OperatorsLibrary';
import { WORKFLOW_OPERATORS_DROPPABLE_ID } from '@containers/WorkflowOperators';
import { DraggableElementWrapper } from './WorkflowToolbar.styled';

const commonTooltipProps = {
  tooltipPosition: TOOLTIP_POSITION_BOTTOM_RIGHT,
  tooltipOffsetLeft: 10,
};

const WorkflowToolbar = ({
  isWorkflowFormNotDirtyAndValid,
  settingBarCollapsed,
  setSettingBarCollapsed,
}) => {
  const intl = useIntl();
  const { showDialog } = useDialog();
  const showFullScreenOperator = useSelector(
    selectFullScreenOperatorVisibility,
  );
  const activeCanvasSelectionInput = useSelector(
    selectActiveCanvasSelectionInput,
  );

  const { getSelectedWorkflow, getIsWorkflowEditable, getIsWorkflowPublic } =
    useWorkflow();
  const { getCategoryOperators } = useOperatorList();

  const workflow = getSelectedWorkflow();
  const isWorkflowEditable = getIsWorkflowEditable(workflow);
  const isWorkflowPublic = getIsWorkflowPublic(workflow);
  const disableActions =
    !isWorkflowEditable ||
    showFullScreenOperator ||
    !!activeCanvasSelectionInput;

  const { defaultOperatorsQuery, templatesQuery } = useOperatorQueries({
    workflowId: workflow?.id,
  });
  const { addOperatorMutation } = useOperatorMutations();

  const coreCategoryOperators = getCategoryOperators('core');

  const isLoading = useMemo(
    () =>
      !defaultOperatorsQuery.isFetched ||
      defaultOperatorsQuery.isLoading ||
      !templatesQuery.isFetched ||
      templatesQuery.isLoading,
    [defaultOperatorsQuery, templatesQuery],
  );

  const handleOperatorSelection = useCallback(
    (operator = {}) =>
      () => {
        sendEvent(
          EventAction.operatorAdded,
          EventCategory.engagement,
          null,
          null,
          operator.name,
        );

        setSettingBarCollapsed(false);

        addOperatorMutation.mutate({
          workflowId: workflow?.id,
          operatorName: operator.name,
          templateId: operator.templateId,
        });
      },
    [addOperatorMutation, workflow?.id, setSettingBarCollapsed],
  );

  const toolbarOperatorIconButtons = useMemo(
    () =>
      coreCategoryOperators.map((operator) => ({
        disabled: disableActions,
        id: operator.id,
        draggableId: `draggableId:{${operator.name}} templateId:{${
          operator.templateId || ''
        }}`,
        iconName: operator.iconName,
        onClick: handleOperatorSelection(operator),
        dragData: {
          iconName: operator.iconName,
          label: operator.label,
        },
        tooltip: {
          ...commonTooltipProps,
          tooltipInfoRow: {
            label: operator.label,
            noPadding: true,
          },
        },
      })),
    [coreCategoryOperators, handleOperatorSelection, disableActions],
  );

  const handleToolbarLogoClick = useCallback(() => {
    showDialog(ModalDataTypes.NAVIGATION);
  }, [showDialog]);

  const renderDraggableElement = useCallback(
    (data = {}, isDraggingOverTargetDroppable = false) => (
      <DraggableElementWrapper
        isDraggingOverTargetDroppable={isDraggingOverTargetDroppable}
      >
        <ListItem label={data.label} leadingIconName={data.iconName} dragged />
      </DraggableElementWrapper>
    ),
    [],
  );

  return (
    <>
      <Toolbar
        logoButton={{
          onClick: handleToolbarLogoClick,
        }}
        bodyIconButtons={
          isLoading || isWorkflowPublic ? [] : toolbarOperatorIconButtons
        }
        preFooterIconButtons={
          isLoading || isWorkflowPublic
            ? []
            : [
                {
                  disabled: disableActions,
                  id: 'toolbarOperatorsLibraryIconButton',
                  iconName: 'more_horiz_0',
                  tooltip: {
                    ...commonTooltipProps,
                    tooltipInfoRow: {
                      label: intl.formatMessage({
                        id: 'operatorslibrary.title',
                        defaultMessage: 'Operators',
                      }),
                      noPadding: true,
                    },
                  },
                  onClick: () => showDialog(ModalDataTypes.OPERATORS_LIBRARY),
                },
              ]
        }
        footerIconButtons={
          !isWorkflowPublic
            ? [
                {
                  id: 'toolbarAiIconButton',
                  disabled: disableActions,
                  iconName: 'ai_on_1',
                  iconColor: 'primary',
                  tooltip: {
                    ...commonTooltipProps,
                    tooltipInfoRow: {
                      label: intl.formatMessage({
                        id: 'gpt.chatbox.title',
                        defaultMessage: 'Talk to Ai',
                      }),
                      noPadding: true,
                    },
                  },
                  onClick: () =>
                    showDialog(ModalDataTypes.TALK_TO_AI, {
                      isWorkflowFormNotDirtyAndValid,
                    }),
                },
              ]
            : []
        }
        drag={{
          droppableId: WORKFLOW_OPERATORS_DROPPABLE_ID,
          isDragDisabled: disableActions || settingBarCollapsed,
          draggableElement: renderDraggableElement,
        }}
      />

      <DialogPortal
        dialogId={ModalDataTypes.NAVIGATION}
        variant={DIALOG_VARIANT_SIDE_VIEW}
        onCloseIconButtonClick={undefined}
        primaryButtonLabel={undefined}
        disableContentPadding
        closeOnClickOutside
        closeOnEscape
      >
        <WorkflowDynamicNavigationBar />
      </DialogPortal>

      <DialogPortal
        dialogId={ModalDataTypes.OPERATORS_LIBRARY}
        variant={DIALOG_VARIANT_SIDE_VIEW}
        headerTitle={intl.formatMessage({
          id: 'operatorslibrary.title',
          defaultMessage: 'Operators',
        })}
        primaryButtonLabel={undefined}
        closeOnClickOutside
        closeOnEscape
      >
        <OperatorsLibrary
          settingBarCollapsed={settingBarCollapsed}
          handleOperatorSelection={handleOperatorSelection}
        />
      </DialogPortal>
    </>
  );
};

WorkflowToolbar.propTypes = {
  isWorkflowFormNotDirtyAndValid: PropTypes.bool,
  settingBarCollapsed: PropTypes.bool,
  setSettingBarCollapsed: PropTypes.func,
};

export default WorkflowToolbar;
