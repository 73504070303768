import styled, { css } from 'styled-components';
import SVG from '@components/1-atoms/SVG';
import { flexCenter } from '@stylesheets/helpers';

const reactFlowStyles = css`
  svg {
    max-width: unset;
  }

  .react-flow__handle-right {
    left: 100%;
    right: unset;
    transform: translate(-50%, -50%);
  }

  .react-flow__handle-left {
    left: unset;
    right: 100%;
    transform: translate(50%, -50%);
  }

  .react-flow__attribution {
    display: none;
  }

  .react-flow__controls {
    flex-direction: row;
    overflow: hidden;

    ${({ theme: { colors, spacing, borderRadius } }) => css`
      background-color: ${colors.surfaceContainer};
      border-radius: ${borderRadius.full};
      padding: ${spacing.level1};
      margin: ${spacing.level6} 0;
      gap: ${spacing.level1};
    `}

    &-button {
      border-radius: 50%;
      border: 0;
      background: unset;

      &:hover {
        opacity: 0.8;
      }
    }

    &-button svg {
      width: 24px;

      path {
        ${({ theme: { colors } }) => css`
          fill: ${colors.onSurface};
        `}
      }
    }

    &-zoomout svg {
      width: 16px;
    }
  }

  .react-flow__minimap {
    background-color: #c3c8cc;
    margin-right: 30px;
  }

  .react-flow__controls-button {
    width: 48px;
    height: 48px;
    ${flexCenter}
    padding: 0;
    cursor: pointer;
  }

  .react-flow__handle-left,
  .react-flow__handle-right {
    width: 8px;
    height: 8px;
    top: 24px;
    border-radius: 50%;

    ${({ theme: { colors } }) => css`
      background: ${colors.onSurface};
      border-color: ${colors.onSurface};
    `}
  }
`;

export const Wrapper = styled.div`
  ${reactFlowStyles}
  width: 100%;
  height: 100%;

  ${({ theme: { colors } }) => css`
    background-color: ${colors.surfaceContainerLow};
  `}
`;

export const EdgeGradient = styled(SVG).attrs({
  name: 'visual-tool-edge-gradient',
  uniquifyIDs: false,
})`
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
`;
