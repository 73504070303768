import SettingBar from '@components/2-molecules/SettingBar';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import { Field as FormikField, useFormikContext } from 'formik';
import SettingTextField from '@components/2-molecules/SettingTextField';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { hideNozzleConfig } from '@actions/printerActions';
import { useDispatch } from 'react-redux';
import useNozzleList from '@hooks/nozzle/useNozzleList';
import { checkIfFieldIsDirty } from '@utils/commonFunctions';

export const NozzleSettings = ({ handleSubmit, dirty, nozzle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { values, initialValues } = useFormikContext();
  const { getNozzleDropDownMenuActions } = useNozzleList();

  const getIsFieldDirty = useCallback(
    (fieldName) => {
      return checkIfFieldIsDirty(values, initialValues, fieldName);
    },
    [values, initialValues],
  );

  const hideNozzle = () => dispatch(hideNozzleConfig());

  return (
    <SettingBar
      headerTitle={values.name}
      footerPrimaryButtonDisabled={!dirty}
      footerPrimaryButtonLabel={intl.formatMessage({
        id: 'general.save',
        defaultMessage: 'Save',
      })}
      footerSecondaryButtonLabel={intl.formatMessage({
        id: 'general.cancel',
        defaultMessage: 'Cancel',
      })}
      headerLeadingIconButtonIconName={'close_0'}
      onHeaderLeadingIconButtonClick={hideNozzle}
      onFooterSecondaryButtonClick={hideNozzle}
      headerEndingIconButtonIconName={nozzle && nozzle.id ? 'more_vert_0' : ''}
      headerEndingIconButtonDropDownProps={{
        dropDownMenuItems: getNozzleDropDownMenuActions(nozzle),
      }}
      onSubmit={handleSubmit}
      withFooterDivider
      renderAsForm
    >
      <SettingsCategory
        title={intl.formatMessage({ id: 'printers.category.settings' })}
        dataTestId={`printer-nozzle__category-settings`}
        expand
        withDividerBottom
      >
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.name`,
          })}
          dataTestId={`printer-nozzle__category-settings__setting-name`}
          name={'name'}
          field1={{
            dirty: getIsFieldDirty('name'),
          }}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.nozzleDiameter`,
          })}
          name={'nozzleDiameter'}
          dataTestId={`printer-nozzle__category-settings__setting-nozzleDiameter`}
          field1={{
            dirty: getIsFieldDirty('nozzleDiameter'),
            type: 'number',
          }}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.calibrationXCoefficient`,
          })}
          dataTestId={`printer-nozzle__category-settings__setting-calibrationXCoefficient`}
          name={'calibrationXCoefficient'}
          field1={{
            dirty: getIsFieldDirty('calibrationXCoefficient'),
            type: 'number',
          }}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.calibrationX2Coefficient`,
          })}
          dataTestId={`printer-nozzle__category-settings__setting-calibrationX2Coefficient`}
          name={'calibrationX2Coefficient'}
          field1={{
            dirty: getIsFieldDirty('calibrationX2Coefficient'),
            type: 'number',
          }}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.calibrationX3Coefficient`,
          })}
          dataTestId={`printer-nozzle__category-settings__setting-calibrationX3Coefficient`}
          name={'calibrationX3Coefficient'}
          field1={{
            dirty: getIsFieldDirty('calibrationX3Coefficient'),
            type: 'number',
          }}
        />
        <FormikField
          component={SettingTextField}
          label={intl.formatMessage({
            id: `printers.category.nozzle-library.property.calibrationYIntercept`,
          })}
          dataTestId={`printer-nozzle__category-settings__setting-calibrationYIntercept`}
          name={'calibrationYIntercept'}
          field1={{
            dirty: getIsFieldDirty('calibrationYIntercept'),
            type: 'number',
          }}
        />
      </SettingsCategory>
    </SettingBar>
  );
};

NozzleSettings.propTypes = {
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  nozzle: PropTypes.object,
};
