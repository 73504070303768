import styled, { css } from 'styled-components';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    padding-bottom: ${spacing.level6};
  `}
`;

export const AiIcon = styled(SpriteSVG).attrs({
  name: 'ai_on_1',
  color: 'primary',
})`
  ${({ theme: { spacing } }) => css`
    width: ${spacing.level6};
    height: ${spacing.level6};
  `}
`;
