import styled, { css } from 'styled-components';
import { flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}

  ${({ scrollable }) => css`
    ${when(
      scrollable,
      `
        overflow: clip;
        max-height: 100%;
      `,
    )}
  `}
`;

export const ScrollableList = styled.div`
  ${flexColumn}

  ${({ scrollable, theme: { spacing } }) => css`
    ${when(
      scrollable,
      `
        overflow-x: clip;
        overflow-y: auto;
        padding-bottom: ${spacing.level6};
      `,
    )}
  `}
`;

export const List = styled.div`
  ${flexColumn}

  ${({ theme: { borderRadius, colors } }) => css`
    background: ${colors.surfaceContainer};
    border-radius: ${borderRadius.large};

    & > * {
      overflow: clip;

      &:first-child {
        border-radius: ${borderRadius.large} ${borderRadius.large} 0 0;
      }

      &:last-child {
        border-radius: 0 0 ${borderRadius.large} ${borderRadius.large};
      }

      &:only-child {
        border-radius: ${borderRadius.large};
      }

      &[data-dragging='true'] {
        border-radius: initial;
        overflow: initial;
      }
    }
  `}
`;

export const DraggableWrapper = styled.div``;

export const DraggableWrapperClone = styled.div`
  transform: none !important;
  pointer-events: none;

  & ~ div {
    transform: none !important;
  }
`;

export const DndPlaceholder = styled.div`
  display: none;
`;
