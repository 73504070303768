import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';
import { printerQueryKeys } from '@hooks/printers/usePrinterQueries';

export const nozzleMutationKeys = {
  deleteNozzle: ['showLoader', 'deleteNozzle'],
  updateNozzle: ['showLoader', 'updateNozzle'],
  createNozzle: ['showLoader', 'createNozzle'],
};

export default function useNozzleMutations() {
  const queryClient = useQueryClient();

  const createNozzleMutation = useMutation({
    mutationKey: nozzleMutationKeys.createNozzle,
    mutationFn: ({ nozzle, printerId } = {}) =>
      client
        .post(`${endpoints.nozzles}`, { ...nozzle, printerId })
        .then((res) => res.data),
    onSuccess: (addedNozzle, { printerId }) => {
      const updater = (data) => {
        return data ? [...data, addedNozzle] : [addedNozzle];
      };
      queryClient.setQueryData(printerQueryKeys.nozzles(printerId), updater);
    },
  });

  const updateNozzleMutation = useMutation({
    mutationKey: nozzleMutationKeys.updateNozzle,
    mutationFn: ({ nozzle } = {}) =>
      client
        .put(`${endpoints.nozzles}/${nozzle.id}`, nozzle)
        .then((res) => res.data),
    onSuccess: (_, { nozzle }) => {
      const updater = (data) => {
        return data
          ? data.map((alreadyPresentNozzle) =>
              alreadyPresentNozzle.id === nozzle.id
                ? {
                    ...alreadyPresentNozzle,
                    ...nozzle,
                  }
                : alreadyPresentNozzle,
            )
          : undefined;
      };
      queryClient.setQueryData(
        printerQueryKeys.nozzles(nozzle?.printerId),
        updater,
      );
    },
  });

  const deleteNozzleMutation = useMutation({
    mutationKey: nozzleMutationKeys.deleteNozzle,
    mutationFn: ({ nozzle } = {}) =>
      client
        .delete(`${endpoints.nozzles}/${nozzle.id}`)
        .then((res) => res.data),
    onSuccess: (_, { nozzle }) => {
      const updater = (data) => {
        return data
          ? data.filter(
              (alreadyPresentNozzle) => alreadyPresentNozzle.id !== nozzle.id,
            )
          : [];
      };
      queryClient.setQueryData(
        printerQueryKeys.nozzles(nozzle.printerId),
        updater,
      );
    },
  });

  return {
    createNozzleMutation,
    updateNozzleMutation,
    deleteNozzleMutation,
  };
}
