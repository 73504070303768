import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ROUTES } from '@constants/router';
import NavigationDrawer from '@components/2-molecules/NavigationDrawer';
import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '@selectors/configSelectors';
import { getCurrentUser } from '@selectors/loginSelectors';
import { OverlayWrapper } from '@components/NavigationBar/NavigationBar.styled';
import useLeanUpon from '@hooks/learnUpon/useLeanUpon';
import { LIBRARY_EXAMPLES } from '@constants/featureFlagConstants';
import useFeatureFlagValue from '@hooks/featureflags/useFeatureFlagValue';

const NavigationBar = ({
  onProjectsItemClick,
  onPrintersItemClick,
  withLogo,
}) => {
  const intl = useIntl();
  const isLibraryExamplesEnabled = useFeatureFlagValue(LIBRARY_EXAMPLES);

  const showKnowledgeCentre = useSelector(isFeatureEnabled('knowledgeCentre'));
  const { onLearnUponLinkClick } = useLeanUpon();

  const [helpVisibility, setHelpVisibility] = useState(false);
  const user = useSelector(getCurrentUser());

  const onHelpClick = useCallback(() => {
    if (!helpVisibility) zE.activate();
    if (helpVisibility) zE.hide();

    setHelpVisibility(!helpVisibility);
  }, [helpVisibility]);

  useEffect(() => {
    if (!user) return;

    zE('webWidget', 'prefill', {
      name: {
        value: user?.name,
        readOnly: true,
      },
      email: {
        value: user?.username,
        readOnly: false,
      },
    });
  }, [user]);

  useEffect(() => {
    zE('webWidget:on', 'close', () => setHelpVisibility(false));
  }, []);

  const itemList = [
    {
      id: '1',
      iconName: 'home_0',
      label: intl.formatMessage({
        id: 'navigation.link.home',
        defaultMessage: 'Home',
      }),
      to: ROUTES.HOME,
      exact: true,
      className: helpVisibility ? 'active' : '',
    },
    {
      id: '2',
      iconName: 'category_0',
      label: intl.formatMessage({
        id: 'navigation.link.project',
        defaultMessage: 'Projects',
      }),
      to: ROUTES.PROJECTS,
      renderAs: onProjectsItemClick ? 'span' : undefined,
      onClick: onProjectsItemClick,
    },
    ...(isLibraryExamplesEnabled
      ? [
          {
            id: '6',
            iconName: 'space_dashboard_0',
            label: intl.formatMessage({
              id: 'navigation.link.library',
              defaultMessage: 'Library',
            }),
            to: ROUTES.LIBRARY,
          },
        ]
      : []),
    {
      id: '7',
      label: intl.formatMessage({
        id: 'navigation.link.setup',
        defaultMessage: 'Setup',
      }),
      items: [
        {
          id: '7.1',
          iconName: 'precision_manufacturing_0',
          label: intl.formatMessage({
            id: 'navigation.link.printers',
            defaultMessage: 'Printers',
          }),
          to: ROUTES.PRINTERS,
          renderAs: onPrintersItemClick ? 'span' : undefined,
          onClick: onPrintersItemClick,
        },
        {
          id: '7.2',
          iconName: 'spool_0',
          label: intl.formatMessage({
            id: 'navigation.link.materials',
            defaultMessage: 'Materials',
          }),
          to: ROUTES.MATERIALS,
        },
      ],
    },
    {
      id: '8',
      label: intl.formatMessage({
        id: 'navigation.link.account',
        defaultMessage: 'Account',
      }),
      items: [
        {
          id: '8.1',
          iconName: 'settings_0',
          label: intl.formatMessage({
            id: 'navigation.link.settings',
            defaultMessage: 'Settings',
          }),
          to: ROUTES.SETTINGS,
        },
        ...(showKnowledgeCentre
          ? [
              {
                id: '8.2',
                iconName: 'school_0',
                label: intl.formatMessage({
                  id: 'navigation.link.knowledge_center',
                  defaultMessage: 'Knowledge Center',
                }),
                renderAs: 'span',
                onClick: onLearnUponLinkClick,
              },
            ]
          : []),
        {
          id: '8.3',
          iconName: 'help_0',
          label: intl.formatMessage({
            id: 'navigation.link.help_and_support',
            defaultMessage: 'Help and Support',
          }),
          renderAs: 'span',
          onClick: onHelpClick,
          className: helpVisibility ? 'active' : '',
        },
      ],
    },
  ];

  return (
    <>
      <NavigationDrawer items={itemList} withLogo={withLogo} />
      {helpVisibility && <OverlayWrapper onClick={onHelpClick} />}
    </>
  );
};

NavigationBar.propTypes = {
  onProjectsItemClick: PropTypes.func,
  onPrintersItemClick: PropTypes.func,
  withLogo: PropTypes.bool,
};

export default NavigationBar;
