import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}
`;
