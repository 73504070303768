import React, { useEffect, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useModal from '@hooks/useModal';
import Modal from '@components/2-molecules/Modal';
import { ModalPortalWrapper } from './ModalPortal.styled';

export const MODALS_COINTAINER_ID = 'modals-container';

const ModalPortal = ({
  autoShow,
  children,
  modalData: modalDataProp = {},
  modalId,
  onClose,
  ...modalComponentProps
}) => {
  const { showModal, hideModal, isModalShown, getModalData } = useModal();

  const modalsPortalContainerElement =
    document.getElementById(MODALS_COINTAINER_ID);

  const modalIsShown = useMemo(
    () => isModalShown(modalId),
    [modalId, isModalShown],
  );

  const modalData = useMemo(
    () => getModalData(modalId),
    [modalId, getModalData],
  );

  const handleClose = useCallback(async () => {
    await hideModal(modalId);

    onClose?.();
  }, [modalId, hideModal, onClose]);

  useEffect(() => {
    if (!autoShow || !modalId || !!modalIsShown) return;

    showModal(modalId, modalDataProp);
  }, [autoShow, modalIsShown, modalDataProp, showModal, modalId]);

  if (!modalsPortalContainerElement || !modalIsShown) return null;

  return createPortal(
    <ModalPortalWrapper>
      <Modal
        onCloseIconButtonClick={handleClose}
        onClose={handleClose}
        {...(modalData || {})}
        {...modalComponentProps}
        id={modalId}
      >
        {children}
      </Modal>
    </ModalPortalWrapper>,
    modalsPortalContainerElement,
  );
};

ModalPortal.propTypes = {
  autoShow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  modalData: PropTypes.object,
  errorType: PropTypes.bool,
  onClose: PropTypes.func,
  ...Modal.propTypes,
};

export default ModalPortal;
