import {
  MACHINE_GANTRY,
  MACHINE_GANTRY_5_AXIS,
  MACHINE_SIX_AXIS_ROBOT,
  MACHINE_SIX_AXIS_ROBOT_COUPLED,
} from '@constants/machineConstants.js';
import { UserRoles } from '@constants/userRoles.js';
import { ValueTypes } from '@constants/valueTypes.js';
import { machineConstants } from '@constants/printers/machineConstants';
import {
  printerConstants,
  printerConstants as constants,
} from '@constants/printers/printerConstants';
import { componentDiscriminators } from '../../constants/printers/componentDiscriminators.js';
import { showGeneralPrinterSetting } from '@actions/printerActions';
import { showErrorDialog } from '@actions/errorActions';
import { isMachineCustom } from '../../lib/machines/MachineUtils.js';
import { PrinterCategories } from '@constants/printerCategories';
import { robotBrandDefinitions } from '../../constants/printers/robotBrandDefinitions.js';

/**
 * Returns the default settings for this machine.
 * @param {*} id machine_definition table id for that machine
 * @param {*} machineDefinitions all standard machine definitions
 * @param {*} printerResponse machine definition for a particular machine
 * @param {*} modelName model name of the machine, e.g. KR240
 */
export function getDefaultMachineValues(
  id,
  machineDefinitions,
  printerResponse,
  modelName,
) {
  const isCustom = isMachineCustom(modelName);
  const machineDefinition = isCustom
    ? getCustomMachineDefinition(printerResponse)
    : machineDefinitions.find(({ displayName }) => displayName === modelName);

  let defaults;
  switch (machineDefinition.machineType) {
    //There is a problem here, SixAxis is being used, although ideally we need to unify them
    //We need to deprecate machine constants
    case componentDiscriminators.SIX_AXIS_ROBOT:
    case MACHINE_SIX_AXIS_ROBOT:
    case MACHINE_SIX_AXIS_ROBOT_COUPLED:
      defaults = getDefaultSixAxisRobotValues(machineDefinition);
      break;
    case MACHINE_GANTRY_5_AXIS:
      defaults = getDefaultGantryValues(
        machineDefinition,
        machineConstants.gantryType5Axis,
      );
      break;
    case componentDiscriminators.GANTRY:
    case MACHINE_GANTRY:
      defaults = getDefaultGantryValues(
        machineDefinition,
        machineConstants.gantryType3Axis,
      );
      break;
  }
  return {
    ...defaults,
    brand: machineDefinition.brand,
    modelName,
    id,
  };
}

/**
 * Returns the machine definition, i.e. the frontend representation of the machine entity.
 * @param {String} modelName model name of the machine in use by the printer
 */
function getCustomMachineDefinition(printerResponse) {
  const { machineDefinitionResponse } = printerResponse;
  const { brand, machineType } = machineDefinitionResponse;

  let machineDefinition;

  if (machineType === componentDiscriminators.GANTRY) {
    const { AXIS_1, AXIS_2, AXIS_3 } = machineDefinitionResponse;
    machineDefinition = {
      basis1DirectionX: AXIS_1.direction.x,
      basis1DirectionY: AXIS_1.direction.y,
      basis1DirectionZ: AXIS_1.direction.z,
      basis2DirectionX: AXIS_2.direction.x,
      basis2DirectionY: AXIS_2.direction.y,
      basis2DirectionZ: AXIS_2.direction.z,
      basis3DirectionX: AXIS_3.direction.x,
      basis3DirectionY: AXIS_3.direction.y,
      basis3DirectionZ: AXIS_3.direction.z,
    };
  } else if (machineType === componentDiscriminators.SIX_AXIS_ROBOT) {
    const { a1, a2, a3, a4, a5, a6, d1, d2, d3, d4, d5, d6, models } =
      machineDefinitionResponse;
    machineDefinition = {
      models,
      pivots: {
        d: [d1, d2, d3, d4, d5, d6],
        a: [a1, a2, a3, a4, a5, a6],
      },
      defaultHomePosition: getDefaultHomePosition(brand),
      homePositionCorrections: [0, 0, 0, 0, 0, 0],
    };
  }
  machineDefinition.brand = brand;
  machineDefinition.machineType = machineType;
  return machineDefinition;
}

function getDefaultHomePosition(brand) {
  const { defaultHomePosition } = robotBrandDefinitions[brand];
  return {
    homeA: defaultHomePosition[0],
    homeB: defaultHomePosition[1],
    homeC: defaultHomePosition[2],
    homeD: defaultHomePosition[3],
    homeE: defaultHomePosition[4],
    homeF: defaultHomePosition[5],
  };
}

/**
 * Returns the an object containing the default values for a six-axis robot
 * with the specified machine definition.
 * @param {Object} machineDefinition
 * @returns
 */
export function getDefaultSixAxisRobotValues(machineDefinition) {
  return {
    type: machineConstants.sixAxisRobotDiscriminator,
    axisA1: machineDefinition.pivots.a[0],
    axisA2: machineDefinition.pivots.a[1],
    axisA3: machineDefinition.pivots.a[2],
    axisA4: machineDefinition.pivots.a[3],
    axisA5: machineDefinition.pivots.a[4],
    axisA6: machineDefinition.pivots.a[5],
    axisD1: machineDefinition.pivots.d[0],
    axisD2: machineDefinition.pivots.d[1],
    axisD3: machineDefinition.pivots.d[2],
    axisD4: machineDefinition.pivots.d[3],
    axisD5: machineDefinition.pivots.d[4],
    axisD6: machineDefinition.pivots.d[5],
    axisMin1: -360,
    axisMin2: -360,
    axisMin3: -360,
    axisMin4: -360,
    axisMin5: -360,
    axisMin6: -360,
    axisMax1: 360,
    axisMax2: 360,
    axisMax3: 360,
    axisMax4: 360,
    axisMax5: 360,
    axisMax6: 360,
    ...getDefaultHomePosition(machineDefinition.brand),
  };
}

export function getDefaultGantryValues(machineDefinition, gantryType) {
  return {
    [machineConstants.typeDiscriminator]: machineConstants.gantryDiscriminator,
    [machineConstants.gantryType]: gantryType,
    basis1DirectionX: machineDefinition.basis1DirectionX || 0,
    basis1DirectionY: machineDefinition.basis1DirectionY || 0,
    basis1DirectionZ: machineDefinition.basis1DirectionZ || 0,
    basis2DirectionX: machineDefinition.basis2DirectionX || 0,
    basis2DirectionY: machineDefinition.basis2DirectionY || 0,
    basis2DirectionZ: machineDefinition.basis2DirectionZ || 0,
    basis3DirectionX: machineDefinition.basis3DirectionX || 0,
    basis3DirectionY: machineDefinition.basis3DirectionY || 0,
    basis3DirectionZ: machineDefinition.basis3DirectionZ || 0,
    axis1Home: 0,
    axis2Home: 0,
    axis3Home: 0,
  };
}

export function removeUnderscores(str) {
  return str.replace(/_/g, ' ');
}

/**
 * Uses the frontend definition of how to render the setting with the provided name
 * and replaces the default value with the value stored in the machine property of the
 * printer object that has been returned from the backend.
 * @param {*} settingName
 * @param {*} robotSettings
 * @returns
 */
export function getSetting(defaultMachineSettings, settingName, value) {
  const settingDefinition = defaultMachineSettings.filter(
    (setting) => setting.settingName === settingName,
  )[0];
  if (!settingDefinition) return;
  return {
    ...settingDefinition,
    value,
  };
}

export function getAllowedRobotDefinitions(machineDefinitions) {
  const filteredRobots = {};
  if (machineDefinitions) {
    const allowedRobotNames = machineDefinitions.map(
      (item) => item.displayName,
    );
    machineDefinitions
      .sort((a, b) => {
        const brandComparison = a?.brand
          ?.toLowerCase()
          .localeCompare(b?.brand?.toLowerCase());
        if (brandComparison !== 0) {
          return brandComparison;
        }
        return a?.displayName
          ?.toLowerCase()
          .localeCompare(b?.displayName?.toLowerCase());
      })
      .forEach((definition) => {
        if (
          allowedRobotNames.includes(definition.displayName) &&
          (definition.visibleToAll || definition.visibleToOrganization)
        ) {
          filteredRobots[definition.displayName] = definition;
        }
      });
    filteredRobots.CUSTOM = printerConstants.CUSTOM;
  }
  return filteredRobots;
}

export function getAllowedRobotDefinitionsByBrand(machineDefinitions, brand) {
  const allowedRobotDefinitions =
    getAllowedRobotDefinitions(machineDefinitions);
  const allowedRobotDefinitionsByBrand = [];
  Object.keys(allowedRobotDefinitions).forEach((displayName) => {
    if (allowedRobotDefinitions[displayName]?.brand == brand) {
      allowedRobotDefinitionsByBrand.push(allowedRobotDefinitions[displayName]);
    }
  });
  return allowedRobotDefinitionsByBrand;
}

/**
 *
 * @param {MachineDefinitions[]} machineDefinitions API response about allowed machines to user
 * @param {String} currentModelName actively selected model name
 * @returns
 */
export function getMachineSelectionSetting(
  machineDefinitions,
  currentModelName,
) {
  return {
    allowedValues: getAllowedRobotDefinitions(machineDefinitions),
    category: PrinterCategories.PRINTER_GENERAL,
    children: [],
    description: '',
    displayName: 'Machine Type',
    manageVisibility: true,
    parentValueRestrictions: null,
    settingName: constants.robotType,
    authorizedRoles: [UserRoles.SUPER_ADMIN, UserRoles.ADMIN, UserRoles.BASIC],
    value: currentModelName,
    valueType: ValueTypes.SELECTION_WIDE,
  };
}

export function checkIfComponentIsNotVisible(
  value,
  setting,
  dispatch,
  intl,
  currentUser,
  machine,
) {
  if (currentUser && currentUser.role !== UserRoles.SUPER_ADMIN) {
    return false;
  }
  let settingValue;
  if (machine) {
    settingValue = setting?.allowedValues?.[value];
  } else {
    settingValue = setting?.allowedValues?.find((item) => item.value === value);
  }
  if (
    value !== constants.CUSTOM.value &&
    settingValue &&
    settingValue.checkboxValue !== undefined &&
    !settingValue.checkboxValue
  ) {
    const errorMessageId = `printers.settings.visibility.error`;
    const errorMessage = intl.formatMessage({
      id: errorMessageId,
      defaultMessage: errorMessageId,
    });

    dispatch(showErrorDialog('Error', errorMessage));
    return true;
  }
  return false;
}

export function checkCustomOption(value, setting, dispatch, printer) {
  if (value === constants.CUSTOM.value) {
    let generalPrinterSetting = false;
    let condition;

    switch (setting.settingName) {
      case constants.robotType:
        generalPrinterSetting = true;
        condition = printer.machineDefinitionResponse;
        break;

      case constants.extruderType:
        generalPrinterSetting = true;
        condition = printer.extruderDefinitionResponse;
        break;

      case constants.printingBedType:
        generalPrinterSetting = true;
        condition = printer.bedDefinitionResponse;
        break;

      case constants.plinthType:
        generalPrinterSetting = true;
        condition = printer.plinthDefinitionResponse;
        break;

      case constants.enclosureType:
        generalPrinterSetting = true;
        condition = printer.enclosureDefinitionResponse;
        break;

      default:
      // Handle other cases if needed
    }

    if (generalPrinterSetting) {
      dispatch(
        showGeneralPrinterSetting(setting.settingName, setting.displayName),
      );
      if (!condition) {
        return true; // in case there is no definition available do not let selection of 'CUSTOM' option
      }
    }
  }

  return false;
}

export const getFormattedOption = (option, changeFunction) => ({
  label: option.label,
  leadingIconName: option.leadingIconName,
  formFieldValue: {
    label: option.label,
    value: option.value,
  },
  onClick: () => changeFunction(option),
});
