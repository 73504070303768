import cadex from '@cadexchanger/web-toolkit';

/**
 * Visit all parts of the Brep to find the Brep shape ID of the provided entity.
 * Calls the provided callback with the shape ID if found
 */
export class BrepShapeIdFinder extends cadex.ModelData_SceneGraphElementVoidVisitor {
  constructor(entity, callback) {
    super();
    this.entity = entity;
    this.callback = callback;
  }

  visitPart = (part) => {
    const brep = part.brepRepresentation();
    if (brep) {
      const shapeid = brep.shapeId(this.entity);
      if (shapeid > -1) {
        this.callback?.(shapeid);
      }
    }
  };
}

/**
 * Visit all parts of the Brep to find the Brep shape entity for the provided shape ID.
 * Calls the provided callback with the shape entity if found
 */
export class BrepShapeFinder extends cadex.ModelData_SceneGraphElementVoidVisitor {
  constructor(shapeId, callback) {
    super();
    this.shapeId = shapeId;
    this.callback = callback;
  }

  visitPart = (part) => {
    const brep = part.brepRepresentation();
    if (brep) {
      const shape = brep.shape(this.shapeId);
      if (shape) {
        this.callback?.(shape);
      }
    }
  };
}
