import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useWorkflow from '@hooks/workflows/useWorkflow';
import withTooltip from '@hoc/withTooltip';
import { getComputationProgressHandler } from '@selectors/computationProgressSelectors';
import { TOOLTIP_POSITION_BOTTOM_CENTER } from '@components/2-molecules/Tooltip';
import Loader from '@components/2-molecules/Loader';
import { Wrapper } from './WorkflowProgressBar.styled';

const TooltipLoader = withTooltip(Loader);

const WorkflowProgressBar = () => {
  const intl = useIntl();
  const taskProgress = useSelector(getComputationProgressHandler);
  const {
    getIsWorkflowComputing,
    cancelWorkflowComputation,
    getSelectedWorkflowOperators,
  } = useWorkflow();

  const workflowIsComputing = getIsWorkflowComputing();
  const workflowOperators = getSelectedWorkflowOperators();
  const currentTaskProgress = taskProgress?.progress;
  const computingWithProgress = workflowIsComputing && currentTaskProgress;

  const taskOperator = useMemo(
    () => workflowOperators?.find(({ id }) => id === taskProgress?.id),
    [workflowOperators, taskProgress?.id],
  );

  const formattedProgress = useMemo(() => {
    if (!computingWithProgress) return 0;

    const parsedProgress = parseInt((currentTaskProgress || 0.0) * 100.0, 10);

    return parsedProgress;
  }, [computingWithProgress, currentTaskProgress]);

  const operatorName = useMemo(
    () => taskOperator?.tag || taskOperator?.name || '',
    [taskOperator],
  );

  const tooltipTitle = useMemo(() => {
    const suffix = operatorName ? `: ${operatorName}` : '';
    const prefix = intl.formatMessage({
      id: 'general.loading',
      defaultMessage: 'Loading',
    });
    return `${prefix}${suffix}`;
  }, [intl, operatorName]);

  return (
    <Wrapper>
      <TooltipLoader
        progress={formattedProgress}
        title={intl.formatMessage({
          id: 'general.loading...',
          defaultMessage: 'Loading...',
        })}
        endingIconButtonTitle={intl.formatMessage({
          id: 'general.stop',
          defaultMessage: 'Stop',
        })}
        endingIconButtonIconName="stop_player_1"
        onEndingIconButtonClick={cancelWorkflowComputation}
        tooltipPosition={TOOLTIP_POSITION_BOTTOM_CENTER}
        tooltipOffsetTop={5}
        tooltipInfoRow={{
          label: tooltipTitle,
          noPadding: true,
        }}
      />
    </Wrapper>
  );
};

WorkflowProgressBar.propTypes = {};

export default WorkflowProgressBar;
