import styled, { css, keyframes } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import {
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_OUTLINE,
  BUTTON_VARIANT_ELEVATED,
  BUTTON_VARIANT_TEXT,
  BUTTON_VARIANT_PLAIN,
  BUTTON_VARIANT_TONAL,
} from './Button';
import { flexCenter, when } from '@stylesheets/helpers';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const Content = styled.span`
  width: 100%;
  ${flexCenter}
  position: relative;

  ${({
    variant,
    disabled,
    $loading,
    withIcon,
    theme: { borderRadius, spacing, colors },
  }) => css`
    border-radius: ${borderRadius?.full};
    padding: 10px ${spacing?.level6};
    gap: ${spacing?.level3};

    ${when(
      withIcon && !$loading,
      `
        padding-left: ${spacing?.level5};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_PRIMARY && !disabled,
      `
        background-color: ${colors?.primary};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_OUTLINE && !disabled,
      `
        border: 1px solid ${colors?.outline};
        padding: 9px ${spacing?.level5};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_OUTLINE && disabled,
      `
        border: 1px solid transparent;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TEXT,
      `
        padding: 10px ${spacing?.level4};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_ELEVATED && !disabled,
      `
        background-color: ${colors?.surfaceContainerLow};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TONAL && !disabled,
      `
        background-color: colors?.secondaryContainer};
      `,
    )}
  `}
`;

export const StateLayer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ variant, disabled, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius?.full};

    ${when(
      variant === BUTTON_VARIANT_OUTLINE && disabled,
      `
        width: unset;
        height: unset;
        border: 1px solid ${colors?.onSurface};
        opacity: 0.12;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_PLAIN,
      `
        background-color: ${colors?.inverseSurface};
        opacity: 0.12;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TONAL && !disabled,
      `
        background-color: ${colors?.secondaryContainer};
      `,
    )}

    ${when(
      ![BUTTON_VARIANT_TEXT, BUTTON_VARIANT_OUTLINE].includes(variant) &&
        disabled,
      `
        background-color: ${colors?.onSurface};
        opacity: 0.12;
      `,
    )}
  `}
`;

export const Icon = styled(SpriteSVG)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  position: relative;

  ${({ variant, color, $loading, disabled, theme: { colors } }) => css`
    ${when(
      variant === BUTTON_VARIANT_PRIMARY && !disabled,
      `
        fill: ${colors?.onPrimary};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_PRIMARY && disabled,
      `
        fill: ${colors?.onSurface};
        opacity: 0.38;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TONAL && !disabled,
      `
        fill: ${colors?.onSecondaryContainer};
      `,
    )}

    ${when(
      [
        BUTTON_VARIANT_OUTLINE,
        BUTTON_VARIANT_TEXT,
        BUTTON_VARIANT_ELEVATED,
        BUTTON_VARIANT_PLAIN,
      ].includes(variant) && !disabled,
      `
        fill: ${colors?.primary};
      `,
    )}

    ${when(
      [
        BUTTON_VARIANT_OUTLINE,
        BUTTON_VARIANT_TEXT,
        BUTTON_VARIANT_ELEVATED,
        BUTTON_VARIANT_TONAL,
        BUTTON_VARIANT_PLAIN,
      ].includes(variant) && disabled,
      `
        fill: ${colors?.onSurface};
        opacity: 0.38;
      `,
    )}

    ${when(
      color,
      `
        fill: ${colors?.[color] || color};
      `,
    )}

    ${when(
      $loading,
      css`
        animation: ${spin} 2s linear infinite;
      `,
    )}
  `}
`;

export const Text = styled.span`
  position: relative;
  flex-shrink: 0;

  ${({ variant, disabled, theme: { colors, font } }) => css`
    ${font?.labelLarge}

    ${when(
      variant === BUTTON_VARIANT_PRIMARY && !disabled,
      `
        color: ${colors?.onPrimary};
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_PRIMARY && disabled,
      `
        color: ${colors?.onSurface};
        opacity: 0.38;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TONAL && !disabled,
      `
        color: ${colors?.onSecondaryContainer};
      `,
    )}

    ${when(
      [
        BUTTON_VARIANT_OUTLINE,
        BUTTON_VARIANT_TEXT,
        BUTTON_VARIANT_ELEVATED,
        BUTTON_VARIANT_PLAIN,
      ].includes(variant) && !disabled,
      `
        color: ${colors?.primary};
      `,
    )}

    ${when(
      [
        BUTTON_VARIANT_OUTLINE,
        BUTTON_VARIANT_TEXT,
        BUTTON_VARIANT_ELEVATED,
        BUTTON_VARIANT_TONAL,
        BUTTON_VARIANT_PLAIN,
      ].includes(variant) && disabled,
      `
        color: ${colors?.onSurface};
        opacity: 0.38;
      `,
    )}
  `}
`;

export const Wrapper = styled.button`
  height: fit-content;
  ${flexCenter}
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;

  ${({ stretch, variant, disabled, theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius?.full};

    ${when(
      stretch,
      `
        flex: 1 0 0;
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_ELEVATED && !disabled,
      `
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      `,
    )}

    ${when(
      variant === BUTTON_VARIANT_TONAL && !disabled,
      `
        background-color: ${colors?.secondaryContainer};
      `,
    )}
  `}

  &:hover {
    ${({ variant }) => css`
      ${when(
        variant === BUTTON_VARIANT_ELEVATED,
        `
          box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
        `,
      )}
    `}

    ${StateLayer} {
      opacity: 0.08;

      ${({ variant, theme: { colors } }) => css`
        ${when(
          variant === BUTTON_VARIANT_PRIMARY,
          `
            background-color: ${colors?.onPrimary};
          `,
        )}

        ${when(
          variant === BUTTON_VARIANT_PLAIN,
          `
            background-color: ${colors?.primary};
            opacity: 0.16;
          `,
        )}

        ${when(
          variant === BUTTON_VARIANT_TONAL,
          `
            background-color: ${colors?.onSecondaryContainer};
            opacity: 0.08;
          `,
        )}

        ${when(
          [
            BUTTON_VARIANT_OUTLINE,
            BUTTON_VARIANT_TEXT,
            BUTTON_VARIANT_ELEVATED,
          ].includes(variant),
          `
            background-color: ${colors?.primary};
            opacity: 0.08;
          `,
        )}
      `}
    }
  }

  &:focus:focus-visible {
    ${StateLayer} {
      opacity: 0.12;

      ${({ variant, theme: { colors } }) => css`
        ${when(
          variant === BUTTON_VARIANT_PRIMARY,
          `
            background-color: ${colors?.onPrimary};
          `,
        )}

        ${when(
          variant === BUTTON_VARIANT_TONAL,
          `
            background-color: ${colors?.onSecondaryContainer};
          `,
        )}

        ${when(
          variant === BUTTON_VARIANT_PLAIN,
          `
            background-color: ${colors?.primary};
            opacity: 0.16;
          `,
        )}

        ${when(
          variant === BUTTON_VARIANT_TONAL,
          `
            background-color: ${colors?.onSecondaryContainer};
            opacity: 0.12;
          `,
        )}

        ${when(
          [
            BUTTON_VARIANT_OUTLINE,
            BUTTON_VARIANT_TEXT,
            BUTTON_VARIANT_ELEVATED,
          ].includes(variant),
          `
            background-color: ${colors?.primary};
            opacity: 0.12;
          `,
        )}
      `}
    }
  }

  ${({ disabled = '', $loading }) =>
    (disabled || $loading) &&
    css`
      pointer-events: none;
    `}
`;
