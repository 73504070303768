import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { organizationQueryKeys } from '@hooks/organization/useOrganizationQueries';
import useProject from '@hooks/projects/useProject';
import { getCurrentUser } from '@selectors/loginSelectors';

export default function useOrganization() {
  const queryClient = useQueryClient();
  const user = useSelector(getCurrentUser());
  const { getSelectedProject } = useProject();

  const getUsers = useCallback(
    () => queryClient.getQueryData(organizationQueryKeys.users),
    [queryClient],
  );

  const getIsOrganizationIdOverride = useCallback(() => {
    const selectedProjectOrganization = getSelectedProject()?.organization;

    return selectedProjectOrganization != user?.organizationId
      ? selectedProjectOrganization
      : null;
  }, [getSelectedProject, user?.organizationId]);

  return {
    getUsers,
    getIsOrganizationIdOverride,
  };
}
