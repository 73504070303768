import styled, { css } from 'styled-components';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import TextComponent, {
  TEXT_VARIANT_BODY_MEDIUM,
} from '@components/1-atoms/Text';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  ${({ withIdentation, theme: { spacing } }) => css`
    gap: ${spacing.level3};
    padding: ${spacing.level4} ${spacing.level5};

    ${when(
      withIdentation,
      `
        padding-left: ${spacing.level7};
      `,
    )}
  `}
`;

export const LeftColumn = styled.div`
  max-width: 50%;
  ${flexCenterVertical}
  flex: 1;

  ${({ theme: { spacing } }) => css`
    padding-top: ${spacing.level1};
  `}
`;

export const RightColumn = styled.div`
  max-width: 50%;
  ${flexCenterVertical}
  gap: 5px;
  margin-left: auto;
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  place-self: flex-start;

  ${({ theme: { colors } }) => css`
    fill: ${colors.secondary};
  `}
`;

export const InfoText = styled(TextComponent).attrs({
  variant: TEXT_VARIANT_BODY_MEDIUM,
  color: 'secondary',
})``;
