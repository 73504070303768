import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const INNER_WRAPPER_FADE_OUT_MAX_WIDTH = 500;

export const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${flexColumn}
  overflow: clip;
  position: relative;

  ${({ theme: { colors, borderRadius } }) => css`
    background-color: ${colors.surfaceContainerLow};
    border-radius: ${borderRadius.large};
  `}
`;

export const InnerWrapper = styled(OuterWrapper)`
  width: max(100%, ${INNER_WRAPPER_FADE_OUT_MAX_WIDTH}px);
  flex: 1;
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: auto;

  ${({ theme: { spacing, colors } }) => css`
    color: ${colors.onSurface};
    padding: 0 ${spacing.level4};
  `}
`;
