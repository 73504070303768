import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexCenterVertical}

  ${({ withContent, theme: { spacing } }) => css`
    gap: ${spacing.level1};

    ${when(
      withContent,
      `
        padding-left: ${spacing.level2};
      `,
    )}
  `}
`;

export const LoaderWrapper = styled.div`
  width: min(450px, 100%);
`;
