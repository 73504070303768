import actionTypes from '@actions';

export const generateMFAConnectorKey = (onSuccess) => ({
  type: actionTypes.GENERATE_MFA_CONNECTOR_KEY_REQUEST,
  payload: { onSuccess },
});

export const generateMFAConnectorKeySuccess = ({
  encryptedSecret,
  qrCodeUrl,
} = {}) => ({
  type: actionTypes.GENERATE_MFA_CONNECTOR_KEY_SUCCESS,
  payload: {
    encryptedSecret,
    qrCodeUrl,
  },
});

export const generateMFAConnectorKeyFailure = (error) => ({
  type: actionTypes.GENERATE_MFA_CONNECTOR_KEY_FAILURE,
  payload: { error },
});

export const validateMFACode = (code, answerCallback, successCallback) => ({
  type: actionTypes.VALIDATE_MFA_CODE_REQUEST,
  payload: { code, answerCallback, successCallback },
});

export const validateMFACodeSuccess = () => ({
  type: actionTypes.VALIDATE_MFA_CODE_SUCCESS,
});

export const validateMFACodeFailure = (error) => ({
  type: actionTypes.VALIDATE_MFA_CODE_FAILURE,
  payload: { error },
});

export const deactivateMFA = (code = '') => ({
  type: actionTypes.DEACTIVATE_MFA_REQUEST,
  payload: { code },
});

export const deactivateMFASuccess = () => ({
  type: actionTypes.DEACTIVATE_MFA_SUCCESS,
});

export const deactivateMFAFailure = (error) => ({
  type: actionTypes.DEACTIVATE_MFA_FAILURE,
  payload: { error },
});
