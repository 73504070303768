import styled, { css } from 'styled-components';
import { Wrapper as NavigationHeader } from '@components/2-molecules/NavigationHeader/NavigationHeader.styled';
import { Wrapper as ActionFooter } from '@components/2-molecules/ActionFooter/ActionFooter.styled';
import { flexColumn, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn}
  width: 100%;
  height: 100%;
  position: fixed;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
  transition: visibility 0.4s ease-in-out, transform 0.4s ease-in-out,
    opacity 0.1s ease-in-out 0.4s;
  outline: none;

  ${({
    floatingFooter,
    floatingHeader,
    headerZIndex,
    noFooterBackground,
    show,
    theme: { colors },
  }) => css`
    background-color: ${colors.surfaceContainerLow};

    ${when(
      show,
      `
        opacity: 1;
        visibility: visible;
        transform: translateY(-100%);
        transition: visibility 0.4s ease-in-out, transform 0.4s ease-in-out, opacity 0.1s ease-in-out;
      `,
    )}

    ${when(
      floatingHeader,
      `
        ${NavigationHeader} {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      `,
    )}

    ${when(
      headerZIndex,
      `
        ${NavigationHeader} {
          z-index: ${headerZIndex};
        }
      `,
    )}

    ${when(
      floatingFooter,
      `
        ${ActionFooter} {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      `,
    )}

    ${when(
      noFooterBackground,
      `
        ${ActionFooter} {
          background-color: unset;
        }
      `,
    )}
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  flex-shrink: 0;
  overflow: auto;

  ${({ disableContentPadding, theme: { spacing, colors } }) => css`
    color: ${colors.onSurface};

    ${when(
      !disableContentPadding,
      `
        padding: 0 ${spacing.level6};
      `,
    )}
  `}
`;
