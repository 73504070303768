import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumn};
  position: relative;
`;

export const DayPickerWrapper = styled.div`
  position: absolute;
  top: 81px;
  right: 0;
  z-index: 1;

  .rdp {
    --rdp-cell-size: 40px; /* Size of the day cells. */
    --rdp-caption-font-size: 22px; /* Font size for the caption labels. */
  }

  ${({ theme: { colors, spacing, borderRadius, font } }) => css`
    .rdp {
      --rdp-accent-color: ${colors.primary}; /* Accent color for the background of selected days. */
      --rdp-background-color: ${colors.primary}; /* Background color for the hovered/focused elements. */
      --rdp-selected-color: ${colors.onPrimary}; /* Color of selected day text */
      margin: 0;
    }

    .rdp-month {
      border-radius: ${borderRadius?.extralarge};
      background: ${colors?.surfaceContainerHigh};
      padding: ${spacing?.level4};
    }

    .rdp-caption_label {
      ${font.titleLarge}
    }

    .rdp-caption {
      padding-left: 4px;
      margin-bottom: 20px;
    }

    .rdp-nav_button svg {
      width: 12px;
      height: 12px;
    }

    .rdp-head {
      //height: 60px;
    }

    .rdp-head_cell {
      ${font.titleMedium}
      font-size: 0.75em;
    }

    .rdp-day {
      ${font.bodyMedium}
    }

    .rdp-day:hover {
      color: ${colors?.onPrimary};
    }

    .rdp-nav_button:hover {
      color: ${colors?.onPrimary};
    }

    .rdp-day_today {
      font-weight: normal;
      border-radius: ${borderRadius?.full};
      border: 1px solid ${colors.primary};
      color: ${colors?.onPrimary};
    }

    .rdp-day_today:not(.rdp-day_selected) {
      color: ${colors?.primary};
    }

    .rdp-day_today:not(.rdp-day_outside):hover {
      color: ${colors.onPrimary};
    }
  `}
`;
