import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './Paper.styled';

const Paper = forwardRef(
  (
    {
      backgroundColor = 'surfaceContainerLow',
      borderRadius = 'full',
      children,
      className,
      dataTestId = 'paper',
      direction = 'column',
      padding = 'level1',
      paddingBottom = '',
      paddingLeft = '',
      paddingRight = '',
      paddingTop = '',
    },
    ref,
  ) => {
    return (
      <Wrapper
        ref={ref}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        className={className}
        data-testid={dataTestId}
        direction={direction}
        padding={padding}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
      >
        {children}
      </Wrapper>
    );
  },
);

Paper.displayName = 'Paper';

Paper.propTypes = {
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  direction: PropTypes.oneOf(['column', 'row']),
  padding: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
};

export default Paper;
