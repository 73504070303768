import { isEqual, omit } from 'lodash';
import moment from 'moment/moment';
import getIntlProvider from '@utils/getIntlProvider';

const intl = getIntlProvider();
export const formatOperatorsWithDiffProps = (
  workflow = {},
  proposedWorkflow = {},
  new_actions = [],
) => {
  workflow.operators.sort((a, b) => a.order - b.order);
  proposedWorkflow.operators.sort((a, b) => a.order - b.order);

  // Omitting the 'triggerComputation' field from each op_value in the values array
  workflow.operators = workflow.operators.map((operator) => {
    return {
      ...operator,
      values: operator.values.map((value) => omit(value, 'triggerComputation')),
    };
  });

  proposedWorkflow.operators = proposedWorkflow.operators.map((operator) => {
    return {
      ...operator,
      values: operator.values.map((value) => omit(value, 'triggerComputation')),
    };
  });

  const serializedOriginalWorkflow = {
    workflow: omit(workflow, ['operators']),

    //ignore the computed field in the comparison as frontend always has computed as true
    // but proposedworkflow always has computed as false
    operators: workflow.operators.reduce(
      (acc, operator) => ({
        ...acc,
        [operator.id]: omit(operator, ['values', 'computed']),
      }),
      {},
    ),
    values: workflow.operators.reduce(
      (acc, operator) => ({
        ...acc,
        ...operator.values.reduce(
          (acc, value) => ({
            ...acc,
            [value.id]: value,
          }),
          {},
        ),
      }),
      {},
    ),
  };

  const serializedProposedWorkflow = {
    workflow: omit(workflow, ['operators']),
    //ignore the computed field in the comparison as frontend always has computed as true
    // but proposedworkflow always has computed as false
    operators: proposedWorkflow.operators.reduce(
      (acc, operator) => ({
        ...acc,
        [operator.id]: omit(operator, ['values', 'computed']),
      }),
      {},
    ),
    values: proposedWorkflow.operators.reduce(
      (acc, operator) => ({
        ...acc,
        ...operator.values.reduce(
          (acc, value) => ({
            ...acc,
            [value.id]: value,
          }),
          {},
        ),
      }),
      {},
    ),
  };

  // original workflow should not show any differences except
  // highlighted field changes
  const originalWorkflowDiff = {
    ...workflow,
    diffModified: !isEqual(
      serializedOriginalWorkflow,
      serializedProposedWorkflow,
    ),
    operators: workflow.operators.map((operator) => {
      const filteredOperator = omit(operator, ['computed']);
      const nextOperator = { ...filteredOperator, diffOperator: true };
      const proposedOperator =
        serializedProposedWorkflow.operators[operator.id];
      const removed = !proposedOperator;

      if (removed) {
        return {
          ...nextOperator,
          diffRemoved: true,
        };
      }

      nextOperator.values = operator.values.map((value) => {
        const nextValue = { ...value, diffValue: true };
        const proposedValue = serializedProposedWorkflow.values[value.id];
        const removed = !proposedValue;

        if (removed) {
          return {
            ...nextValue,
            diffRemoved: true,
          };
        }

        const modified = !isEqual(
          value,
          serializedProposedWorkflow.values[value.id],
        );

        if (modified) {
          return {
            ...nextValue,
            diffRemoved: true,
          };
        }

        return nextValue;
      });

      return nextOperator;
    }),
  };

  const proposedWorkflowDiff = {
    ...workflow,
    diffModified: !isEqual(
      serializedOriginalWorkflow,
      serializedProposedWorkflow,
    ),
    operators: proposedWorkflow.operators.map((operator) => {
      const filteredOperator = omit(operator, ['computed']);
      const nextOperator = { ...filteredOperator, diffOperator: true };
      const originalOperator =
        serializedOriginalWorkflow.operators[operator.id];

      const added = !originalOperator;

      if (added) {
        const insertedActions = new_actions.filter(
          (action) =>
            action.type === 'insert' && action.data.tag === operator.tag,
        );

        // Create a set of all settings keys from insertedActions
        const allInsertedSettingsKeys = new Set(
          insertedActions.flatMap((action) =>
            Object.keys(action.data.settings),
          ),
        );

        nextOperator.values = operator.values.map((opValue) => {
          // check if operator value has changed
          const settingModified = allInsertedSettingsKeys.has(opValue.name);
          return {
            ...opValue,
            diffValue: true,
            diffModified: settingModified,
          };
        });
        return {
          ...nextOperator,
          diffAdded: true,
        };
      }

      nextOperator.values = operator.values.map((value) => {
        const nextValue = { ...value, diffValue: true };
        const originalValue = serializedOriginalWorkflow.values[value.id];
        const added = !originalValue;

        if (added) {
          return {
            ...nextValue,
            diffAdded: true,
          };
        }

        const modified = !isEqual(
          value,
          serializedOriginalWorkflow.values[value.id],
        );

        if (modified) {
          return {
            ...nextValue,
            diffModified: true,
          };
        }

        return nextValue;
      });

      //ignore ordering
      const originalOperators = omit(
        serializedOriginalWorkflow.operators[operator.id],
        ['order'],
      );
      const proposedOperators = omit(
        serializedProposedWorkflow.operators[operator.id],
        ['order'],
      );
      const areOperatorsChanged = !isEqual(
        originalOperators,
        proposedOperators,
      );

      const areValuesModified = nextOperator.values.some(
        ({ diffAdded, diffModified }) => diffAdded || diffModified,
      );
      const modified = areOperatorsChanged || areValuesModified;
      if (modified) {
        return {
          ...nextOperator,
          diffModified: true,
        };
      }

      return nextOperator;
    }),
  };

  return { originalWorkflowDiff, proposedWorkflowDiff };
};

export const generateStaticAnswer = ({
  intId = '',
  fallbackMessage = '',
  error,
}) => ({
  type: 'answer',
  data: intl.formatMessage({
    id: intId,
    defaultMessage: fallbackMessage,
  }),
  time: moment(),
  error,
});

export const generateErrorAnswer = (message) => {
  const answer = generateStaticAnswer({
    intId: 'gpt.diff.answer.something_went_wrong',
    fallbackMessage: 'Oops! Something went wrong. Please try again',
    error: true,
  });

  if (message) answer.data = message;

  return answer;
};

export const generateStaticErrorAnswer = (message) => {
  return {
    answer_text:
      message ??
      intl.formatMessage({
        id: 'gpt.diff.answer.something_went_wrong',
        fallbackMessage: 'Oops! Something went wrong. Please try again',
      }),
    error: true,
  };
};
