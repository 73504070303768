/**
 * This file is used to define the redux asynchronous action types.
 * You just need to add an action base name.
 * There is no need to define three action stages(_REQUEST, _SUCCESS, _FAILURE)
 * as they would be added automatically.
 *
 * For example:
 * FETCH_SOME_DATA
 * would become
 * FETCH_SOME_DATA_REQUEST
 * FETCH_SOME_DATA_SUCCESS
 * FETCH_SOME_DATA_FAILURE
 */

const asyncActions = [
  // mfa
  'GENERATE_MFA_CONNECTOR_KEY',
  'VALIDATE_MFA_CODE',
  'DEACTIVATE_MFA',
  // post processors
  'FETCH_POST_PROCESSOR_CONFIG',
  'UPDATE_POST_PROCESSOR_CONFIG',
  //workflow annotations
  'CREATE_WORKFLOW_ANNOTATION',
  'FETCH_WORKFLOW_ANNOTATIONS',
  'UPDATE_WORKFLOW_ANNOTATION',
  'DELETE_WORKFLOW_ANNOTATION',
  // concepts
  'UPDATE_CONCEPT_WITH_NOZZLE',
  'UPDATE_CONCEPT_WITH_MATERIAL',
  'UPDATE_WORKFLOW_PRINTER_CONFIG',
  // printers
  'FETCH_PRINTER',
  'UPLOAD_PRINTER_COMPONENT',
  'UPDATE_VISIBILITY',
  //operators
  'UPDATE_OPERATOR_TAG',
  // reset password
  'CREATE_RESET_PASSWORD_TOKEN',
  'RESET_PASSWORD',
  // version
  'FETCH_VERSION',
];

export default asyncActions;
