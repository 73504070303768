import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ModalDataTypes } from '@constants/modalDataTypes';
import useDialog from '@hooks/useDialog';
import usePrinterQueries from '@hooks/printers/usePrinterQueries';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import useSnackbar from '@hooks/useSnackbar';
import { b, br } from '@utils/render';
import useNozzleMutations from '@hooks/nozzle/useNozzleMutations';
import { hideNozzleConfig } from '@actions/printerActions';

const MODAL_ID = ModalDataTypes.DELETE_NOZZLE;

const DeleteNozzleDialog = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { getDialogData, hideDialog } = useDialog();
  const { deleteNozzleMutation } = useNozzleMutations();
  const { showSnackbar } = useSnackbar();
  const dialogData = useMemo(() => getDialogData(MODAL_ID), [getDialogData]);
  const { nozzleInUseWorkflows } = usePrinterQueries({
    nozzleId: dialogData?.nozzle.id,
  });
  const { refetch: workflowsQueryRefetch } = nozzleInUseWorkflows;
  const workflows = nozzleInUseWorkflows?.data;

  useEffect(() => {
    workflowsQueryRefetch();
  }, [workflowsQueryRefetch]);

  const handleClose = useCallback(() => {
    hideDialog(MODAL_ID);
  }, [hideDialog]);

  const formattedWorkflows = workflows?.map((workflow) => (
    <li key={workflow}>{workflow}</li>
  ));

  const inUseDialog = workflows?.length > 0;
  const dialogTitle = intl.formatMessage({
    id: `delete_nozzle_dialog.${inUseDialog ? 'in.use.' : ''}title`,
  });
  const primaryButtonText = intl.formatMessage({
    id: inUseDialog ? 'dialog.message.ok' : 'general.confirm',
  });
  const secondaryButtonLabel = inUseDialog
    ? ''
    : intl.formatMessage({
        id: 'general.cancel',
      });
  const dialogText = intl.formatMessage(
    {
      id: `delete_nozzle_dialog.${inUseDialog ? 'in.use.' : ''}text`,
    },
    {
      name: dialogData.nozzle.name,
      workflows: formattedWorkflows,
      b,
      br,
    },
  );

  const handleNozzleDeleted = useCallback(() => {
    showSnackbar({
      text: intl.formatMessage({
        id: 'delete_nozzle_dialog.snackbar.label',
        defaultMessage: 'Nozzle successfully deleted',
      }),
    });
    dispatch(hideNozzleConfig());
    handleClose();
  }, [dispatch, intl, showSnackbar, handleClose]);

  const handleConfirm = useCallback(() => {
    if (workflows?.length === 0) {
      return deleteNozzleMutation
        .mutateAsync({ nozzle: dialogData.nozzle })
        .then(handleNozzleDeleted);
    } else {
      return handleClose();
    }
  }, [
    deleteNozzleMutation,
    handleNozzleDeleted,
    handleClose,
    workflows,
    dialogData.nozzle,
  ]);

  return (
    !nozzleInUseWorkflows.isFetching && (
      <DialogPortal
        dataTestId="delete-nozzle-dialog"
        dialogId={MODAL_ID}
        primaryButtonLabel={primaryButtonText}
        secondaryButtonLabel={secondaryButtonLabel}
        onPrimaryButtonClick={handleConfirm}
        onClose={handleClose}
      >
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={dialogTitle}
          subtitle={dialogText}
        />
      </DialogPortal>
    )
  );
};

DeleteNozzleDialog.propTypes = {};

export default DeleteNozzleDialog;
