import React from 'react';
import { BedTypeComponent } from '@components/Printers/GeneralPrinterSetting/BedTypeSetting/BedTypeComponent';

export const RotarySetting = () => {
  const dimensionsSettings = ['d1', 'd2'].map((field) => ({
    field,
  }));

  const parametersSettings = [{ field: 'maxSpeed' }];

  return (
    <>
      <BedTypeComponent
        titleId={'printers.settings.bed.type.dimensions.label'}
        label={'dimensions.distance'}
        settings={dimensionsSettings}
        withDiagram={'ROTARY_BED'}
      />
      <BedTypeComponent
        noBorderBottom
        titleId={'printers.settings.bed.type.parameters.label'}
        label={'bed.type.parameters'}
        settings={parametersSettings}
      />
    </>
  );
};
