import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from '@hoc/withTooltip';
import Text, { TEXT_VARIANT_BODY_MEDIUM } from '@components/1-atoms/Text';
import Checkbox from '@components/1-atoms/Checkbox';
import { Wrapper, LeftColumn, RightColumn } from './SettingCheckbox.styled';

const TextWithTooltip = withTooltip(Text);

const SettingCheckbox = ({
  checkbox = {},
  className,
  dataTestId = 'setting-checkbox',
  label,
  labelTooltip = {},
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <LeftColumn>
        <TextWithTooltip
          {...labelTooltip}
          dataTestId={`${dataTestId}__label`}
          variant={TEXT_VARIANT_BODY_MEDIUM}
          color="onSurface"
        >
          {label}
        </TextWithTooltip>
      </LeftColumn>

      <RightColumn>
        <Checkbox dataTestId={`${dataTestId}__checkbox`} {...checkbox} />
      </RightColumn>
    </Wrapper>
  );
};

SettingCheckbox.propTypes = {
  checkbox: PropTypes.shape(Checkbox.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.object,
  withIdentation: PropTypes.bool,
};

export default SettingCheckbox;
