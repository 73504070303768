import styled, { css } from 'styled-components';
import SpriteSVG from '@app/components/1-atoms/SpriteSVG';
import { flexColumnCenter, when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  ${flexColumnCenter}
  flex-shrink: 0;
  cursor: pointer;

  ${({ disabled, theme: { spacing } }) => css`
    width: ${spacing.level9};
    height: ${spacing.level9};

    ${when(
      disabled,
      `
        opacity: 0.38;
        pointer-events: none;
      `,
    )}
  `}
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  ${flexColumnCenter}
  padding: 11px;
  position: relative;
`;

export const Icon = styled(SpriteSVG)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({
    checked,
    dirty,
    diffRemoved,
    diffAdded,
    diffModified,
    disabled,
    theme: { colors },
  }) => css`
    fill: ${colors.onSurfaceVariant};

    ${when(
      checked,
      `
        fill: ${colors.primary};
      `,
    )}

    ${when(
      dirty,
      `
        fill: ${colors?.caution};
      `,
    )}

    ${when(
      diffRemoved,
      `
        fill: #9c3e47;
      `,
    )}

    ${when(
      diffAdded,
      `
        fill: #007bff;
      `,
    )}

    ${when(
      diffModified,
      `
        fill: #E93EA4;
      `,
    )}

    ${when(
      disabled,
      `
        fill: ${colors.onSurface};
      `,
    )}
  `}
`;

export const StateLayer = styled.span`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ disabled, theme: { colors, borderRadius } }) => css`
    border-radius: ${borderRadius.full};
    background-color: ${colors.onSurface};
    opacity: 0.08;

    ${when(
      disabled,
      `
        display: none;
      `,
    )}
  `}
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  margin: 0;

  &:hover {
    & ~ ${StateLayer} {
      visibility: visible;
    }
  }

  &:focus:focus-visible {
    & ~ ${StateLayer} {
      visibility: visible;
      opacity: 0.12;
    }

    & ~ ${Icon} {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  ${({ disabled }) => css`
    ${when(
      disabled,
      `
        pointer-events: none;
      `,
    )}
  `}
`;
