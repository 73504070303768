import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import IconButton, {
  ICON_BUTTON_VARIANT_TONAL,
  ICON_BUTTON_VARIANT_PLAIN,
} from '@components/1-atoms/IconButton';
import getIntlProvider from '@utils/getIntlProvider';
import {
  Wrapper,
  Icon,
  Title,
  ProgressBar,
  ProgressText,
} from './Loader.styled';

export const LOADER_ICON_ANIMATION_VARIANT_SPIN = 'spin';
export const LOADER_ICON_ANIMATION_VARIANT_PULSE = 'pulse';

const intl = getIntlProvider();

const Loader = ({
  dataTestId = 'loader',
  iconName = 'progress_activity_0',
  iconAnimationVariant = LOADER_ICON_ANIMATION_VARIANT_SPIN,
  iconColor,
  title = intl.formatMessage({
    id: 'pageloader.loading',
    defaultMessage: 'Loading',
  }),
  progress,
  leadingIconButtonIconName,
  leadingIconButtonTitle,
  endingIconButtonIconName,
  endingIconButtonTitle,
  onEndingIconButtonClick,
  onLeadingIconButtonClick,
}) => {
  const isPulseAnimation =
    iconAnimationVariant === LOADER_ICON_ANIMATION_VARIANT_PULSE;
  const withLeadingIconButton = !!leadingIconButtonIconName;
  const withEndingIconButton = !!endingIconButtonIconName;
  const showProgressBar = !isUndefined(progress);

  return (
    <Wrapper
      data-testid={dataTestId}
      withLeadingIconButton={withLeadingIconButton}
      withEndingIconButton={withEndingIconButton}
    >
      {leadingIconButtonIconName && (
        <IconButton
          dataTestId={`${dataTestId}__leading-icon-button`}
          title={leadingIconButtonTitle}
          variant={ICON_BUTTON_VARIANT_PLAIN}
          iconName={leadingIconButtonIconName}
          onClick={onLeadingIconButtonClick}
        />
      )}

      <Icon
        dataTestId={`${dataTestId}__icon`}
        name={iconName}
        iconColor={iconColor}
        pulseAnimation={isPulseAnimation}
        large={withEndingIconButton}
      />

      {title && <Title dataTestId={`${dataTestId}__title`}>{title}</Title>}

      {showProgressBar && (
        <>
          <ProgressBar
            dataTestId={`${dataTestId}__progress-bar`}
            progress={progress}
          />

          <ProgressText
            dataTestId={`${dataTestId}__progress-title`}
            id="loader-progress-text"
          >
            {progress}%
          </ProgressText>
        </>
      )}

      {withEndingIconButton && (
        <IconButton
          variant={ICON_BUTTON_VARIANT_TONAL}
          iconName={endingIconButtonIconName}
          title={endingIconButtonTitle}
          onClick={onEndingIconButtonClick}
        />
      )}
    </Wrapper>
  );
};

Loader.propTypes = {
  dataTestId: PropTypes.string,
  endingIconButtonIconName: PropTypes.string,
  endingIconButtonTitle: PropTypes.string,
  iconColor: PropTypes.string,
  iconAnimationVariant: PropTypes.oneOfType([
    PropTypes.oneOf([LOADER_ICON_ANIMATION_VARIANT_SPIN]),
    PropTypes.oneOf([LOADER_ICON_ANIMATION_VARIANT_PULSE]),
  ]),
  iconName: PropTypes.string,
  leadingIconButtonIconName: PropTypes.string,
  leadingIconButtonTitle: PropTypes.string,
  onEndingIconButtonClick: PropTypes.func,
  onLeadingIconButtonClick: PropTypes.func,
  progress: PropTypes.number,
  title: PropTypes.string,
};

export default Loader;
