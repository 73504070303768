import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, LeftColumn, RightColumn, Line } from './Skeleton.styled';

export const SKELETON_VARIANT_DEFAULT = 'default';
export const SKELETON_VARIANT_ONE_LINE = 'oneLine';

const Skeleton = ({
  dataTestId = 'skeleton',
  borderRadius = 'extrasmall',
  fullHeight,
  height = 56,
  variant = SKELETON_VARIANT_DEFAULT,
  withIcon = true,
  withLine = true,
}) => {
  const oneLineVariant = variant === SKELETON_VARIANT_ONE_LINE;

  return (
    <Wrapper
      data-testid={dataTestId}
      height={height}
      fullHeight={fullHeight}
      oneLineVariant={oneLineVariant}
      withIcon={withIcon}
      withLine={withLine}
    >
      {withIcon && <LeftColumn borderRadius={borderRadius} />}

      {withLine && (
        <RightColumn borderRadius={borderRadius}>
          <Line />

          {!oneLineVariant && <Line />}
        </RightColumn>
      )}
    </Wrapper>
  );
};

Skeleton.propTypes = {
  dataTestId: PropTypes.string,
  borderRadius: PropTypes.string,
  variant: PropTypes.oneOf([
    SKELETON_VARIANT_DEFAULT,
    SKELETON_VARIANT_ONE_LINE,
  ]),
  height: PropTypes.number,
  fullHeight: PropTypes.bool,
  withIcon: PropTypes.bool,
  withLine: PropTypes.bool,
};

export default Skeleton;
