import React from 'react';
import PropTypes from 'prop-types';
import { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { Wrapper, Button } from './SettingActionRow.styled';

const SettingActionRow = ({
  button = {},
  className,
  dataTestId = 'setting-action-row',
  withIdentation,
}) => {
  return (
    <Wrapper
      data-testid={dataTestId}
      className={className}
      withIdentation={withIdentation}
    >
      <Button
        dataTestId={`${dataTestId}__button`}
        {...button}
        variant={BUTTON_VARIANT_TEXT}
      />
    </Wrapper>
  );
};

SettingActionRow.propTypes = {
  button: PropTypes.shape(Button.propTypes),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  withIdentation: PropTypes.bool,
};

export default SettingActionRow;
