import { changePrinterSettings } from '../../actions/printerActions';
import { robotBrandDefinitions } from '../../constants/printers/robotBrandDefinitions';

export const getDefaultToolCalibrations = (
  extruderDefinitions,
  displayName,
  machineBrand,
) => {
  let extruderDefinition = extruderDefinitions.find(
    (extruder) => extruder.displayName === displayName,
  );

  if (!extruderDefinition) {
    extruderDefinition = {
      tools: [
        {
          toolX: 0,
          toolY: 0,
          toolZ: 0,
          toolA: 0,
          toolB: 0,
          toolC: 0,
        },
      ],
    };
  }

  const toolCalibrations = extruderDefinition.tools;
  const doInvert =
    robotBrandDefinitions[machineBrand]?.isFlangeInverted || false;

  if (doInvert) {
    for (let i = 0; i < toolCalibrations.length; i++) {
      toolCalibrations[i].toolX *= -1;
      toolCalibrations[i].toolY *= -1;
      toolCalibrations[i].toolA *= -1;
    }
  }
  return toolCalibrations;
};

export const updateDefaultToolCalibration = (
  dispatch,
  printer,
  { toolX, toolY, toolZ, toolA, toolB, toolC },
) => {
  dispatch(
    changePrinterSettings(printer.id, [
      {
        settingName: 'toolX',
        value: toolX,
      },
      {
        settingName: 'toolY',
        value: toolY,
      },
      {
        settingName: 'toolZ',
        value: toolZ,
      },
      {
        settingName: 'toolA',
        value: toolA,
      },
      {
        settingName: 'toolB',
        value: toolB,
      },
      {
        settingName: 'toolC',
        value: toolC,
      },
    ]),
  );
};

function clampAngle(angle) {
  angle = angle % 360;
  if (angle > 180) {
    angle -= 360;
  } else if (angle < -180) {
    angle += 360;
  }
  return angle;
}

export const flipToolCalibration = ({
  toolX,
  toolY,
  toolZ,
  toolA,
  toolB,
  toolC,
}) => {
  return {
    toolX: toolX * -1,
    toolY: toolY * -1,
    toolZ,
    toolA: clampAngle(toolA + 180),
    toolB,
    toolC,
  };
};
