import { uniqBy } from 'lodash';
import {
  SELECT_ANALYTICS_PRINTER,
  FETCH_ALL_DASHBOARDS,
  DASHBOARDS_LOADED,
  FETCH_ALL_DASHBOARDS_FAILED,
  FETCH_ALL_WIDGET_TEMPLATES,
  WIDGET_TEMPLATES_LOADED,
  FETCH_ALL_WIDGET_TEMPLATES_FAILED,
  SHOW_CREATE_DASHBOARD_DIALOG,
  CREATE_DASHBOARD,
  CREATE_DASHBOARD_FAILED,
  CREATE_DASHBOARD_SUCCEEDED,
  DISMISS_CREATE_DASHBOARD_DIALOG,
  NEW_DASHBOARD_NAME_MODIFIED,
  SHOW_DELETE_DASHBOARD_DIALOG,
  DISMISS_DELETE_DASHBOARD_DIALOG,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_SUCCEEDED,
  DELETE_DASHBOARD_FAILED,
  SHOW_UPDATE_DASHBOARD_DIALOG,
  DISMISS_UPDATE_DASHBOARD_DIALOG,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCEEDED,
  UPDATE_DASHBOARD_FAILED,
  UPDATE_DASHBOARD_NAME_MODIFIED,
  DASHBOARD_SELECTED,
  RESET_SELECTED_DASHBOARD,
  CREATE_WIDGET,
  CREATE_WIDGET_SUCCEEDED,
  CREATE_WIDGET_FAILED,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCEEDED,
  UPDATE_WIDGET_FAILED,
  SHOW_CREATE_WIDGET_DIALOG,
  DISMISS_CREATE_WIDGET_DIALOG,
  TOOLPATH_WORKSPACE_SELECTED,
  TOOLPATH_FETCH_ALL_CONCEPTS,
  TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED,
  TOOLPATH_FETCH_ALL_CONCEPTS_FAILED,
  TOOLPATH_WORKFLOW_SELECTED,
  TOOLPATH_SELECTED,
  TOOLPATH_REMOVED,
  FETCH_ALL_DASHBOARD_WIDGETS,
  FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED,
  FETCH_ALL_DASHBOARD_WIDGETS_FAILED,
  NEW_COMPONENT_NAME_CHANGED,
  DELETE_WIDGET,
  DELETE_WIDGET_SUCCEEDED,
  DELETE_WIDGET_FAILED,
  SHOW_EDIT_WIDGET_DIALOG,
  FETCH_WIDGET_DATA,
  FETCH_WIDGET_DATA_SUCCEEDED,
  FETCH_WIDGET_DATA_FAILED,
  FETCH_PRINTER_SENSORS_DATA,
  FETCH_PRINTER_SENSORS_DATA_SUCCEEDED,
  FETCH_PRINTER_SENSORS_DATA_FAILED,
  FETCH_PRINTER_IMAGES,
  FETCH_PRINTER_IMAGES_SUCCEEDED,
  FETCH_PRINTER_IMAGES_FAILED,
  FETCH_TOOLPATH_ANALYSIS_DATA,
  FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED,
  FETCH_TOOLPATH_ANALYSIS_DATA_FAILED,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED,
  FETCH_PRINTER_CAMERAS_REQUEST,
  FETCH_PRINTER_CAMERAS_SUCCESS,
  FETCH_PRINTER_CAMERAS_FAILURE,
  UPDATE_CAMERA_SENSITIVITY,
  UPDATE_CAMERA_SENSITIVITY_SUCCESS,
  UPDATE_CAMERA_SENSITIVITY_FAILURE,
} from '../constants/actionTypes.js';

import {
  TOOLPATH_GENERATOR_OPERATOR_NAME,
  MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME,
} from '../constants/operatorsConstants.js';

import { AuthenticatedFetch } from './AuthenticatedFetch.js';
import { CURRENT_USER_KEY_LOCAL_STORAGE } from '../constants/utilityConstants.js';
import { DELETE, GET, POST, PUT } from '../constants/fetchMethods.js';
import moment from 'moment';

export const selectAnalyticsPrinter = (id) => ({
  type: SELECT_ANALYTICS_PRINTER,
  payload: {
    printerId: id,
  },
});

export const onWorkflowSelected = (workflow) => (dispatch, getState) => {
  const {
    analytics: {
      toolpathSelector: { workflowSelected },
    },
  } = getState();

  if (!workflowSelected || workflowSelected.id !== workflow.id) {
    return dispatch({
      type: TOOLPATH_WORKFLOW_SELECTED,
      payload: {
        workflow: workflow,
        toolpaths: workflow.operators.filter(function (operator) {
          return (
            operator.name
              .toUpperCase()
              .includes(TOOLPATH_GENERATOR_OPERATOR_NAME.toUpperCase()) ||
            operator.name
              .toUpperCase()
              .includes(
                MELTIO_TOOLPATH_GENERATOR_OPERATOR_NAME.toUpperCase(),
              ) ||
            operator.name
              .toUpperCase()
              .includes('GcodeToolpathConverter'.toUpperCase())
          );
        }),
      },
    });
  }
  return null;
};

export const changeNewComponentName = (name) => ({
  type: NEW_COMPONENT_NAME_CHANGED,
  name: name,
});

export const onToolpathSelected = (toolpath) => (dispatch) => {
  return dispatch({
    type: TOOLPATH_SELECTED,
    payload: {
      toolpath: toolpath,
    },
  });
};

export const removeSelection = (selection) => (dispatch) => {
  return dispatch({
    type: TOOLPATH_REMOVED,
    payload: {
      selection: selection,
    },
  });
};

export const onWorkspaceSelected = (workspace) => (dispatch, getState) => {
  const {
    analytics: {
      toolpathSelector: { workspaceSelected },
    },
  } = getState();

  if (!workspaceSelected || workspaceSelected.id !== workspace.id) {
    dispatch({
      type: TOOLPATH_WORKSPACE_SELECTED,
      payload: {
        workspace: workspace,
      },
    });
    return dispatch(
      AuthenticatedFetch({
        url: 'concepts',
        method: GET,
        queryParams: {
          workspace: workspace.id,
          organization: workspace.publicAccess ? workspace.organization : '',
        },
        prefetchAction: {
          type: TOOLPATH_FETCH_ALL_CONCEPTS,
        },
        successAction: (responseBody) => ({
          type: TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED,
          payload: responseBody,
        }),
        failureAction: () => ({
          type: TOOLPATH_FETCH_ALL_CONCEPTS_FAILED,
        }),
      }),
    );
  }
  return null;
};

export const fetchAllDashboards = (currentUser) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'dashboards',
      method: GET,
      queryParams: {
        username: currentUser.username, //TODO: every user will see his dashboards only, is it fine?
      },
      prefetchAction: {
        type: FETCH_ALL_DASHBOARDS,
      },
      successAction: (responseBody) => ({
        type: DASHBOARDS_LOADED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: FETCH_ALL_DASHBOARDS_FAILED,
      }),
    }),
  );
};

export const fetchAllWidgetTemplates = () => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'component-templates',
      method: GET,
      prefetchAction: {
        type: FETCH_ALL_WIDGET_TEMPLATES,
      },
      successAction: (responseBody) => ({
        type: WIDGET_TEMPLATES_LOADED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: FETCH_ALL_WIDGET_TEMPLATES_FAILED,
      }),
    }),
  );
};

export const fetchAllComponents = (dashboardId) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'components',
      method: GET,
      queryParams: {
        dashboardId: dashboardId, //TODO: every user will see his dashboards only, is it fine?
      },
      prefetchAction: {
        type: FETCH_ALL_DASHBOARD_WIDGETS,
      },
      successAction: (responseBody) => ({
        type: FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: FETCH_ALL_DASHBOARD_WIDGETS_FAILED,
      }),
    }),
  );
};

export const selectDashboard = (dashboard) => (dispatch) => {
  dispatch({
    type: DASHBOARD_SELECTED,
    dashboard: dashboard,
  });
  dispatch(fetchAllComponents(dashboard.id)).then((components) => {
    const cameras = components?.map((component) => {
      const printerId = component?.settings?.find(
        (settings) => settings?.settingTemplate?.name === 'printer-id',
      )?.value;
      const cameraId = component?.settings?.find(
        (settings) => settings?.settingTemplate?.name === 'camera-id',
      )?.value;

      return {
        printerId,
        cameraId,
      };
    });

    // Filter out objects with undefined or null cameraId
    const filteredCameras = cameras.filter(
      (camera) => camera.cameraId !== undefined && camera.cameraId !== null,
    );

    const uniqueCameras = uniqBy(filteredCameras, 'cameraId');

    dispatch(getPrinterCameras(uniqueCameras));
  });
};

export const selectDashboardById = (dashboardId) => (dispatch, getState) => {
  const {
    analytics: {
      data: { dashboards },
    },
  } = getState();

  const dashboard = dashboards.find((d) => d.id === dashboardId);
  dashboard && dispatch(selectDashboard(dashboard));
};

export const dismissCreateDashboardDialog = () => ({
  type: DISMISS_CREATE_DASHBOARD_DIALOG,
});

export const createComponent = (newComponent) => (dispatch, getState) => {
  const {
    analytics: {
      data: { selectedDashboard },
    },
  } = getState();
  const component = {
    name: newComponent.name,
    dashboard: selectedDashboard.id,
    template: newComponent.template.id,
    positionX: 0,
    positionY: 0,
    width: 10,
    height: 4,
    settings: newComponent.settings,
  };
  return dispatch(
    AuthenticatedFetch({
      url: 'components',
      body: JSON.stringify(component),
      method: POST,
      prefetchAction: {
        type: CREATE_WIDGET,
      },
      successAction: (responseBody) => ({
        type: CREATE_WIDGET_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: CREATE_WIDGET_FAILED,
      }),
    }),
  ).then(() => {
    return dispatch(fetchAllComponents(selectedDashboard.id));
  });
};

export const updateComponentsSizeAndPosition = (request) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'components',
      body: JSON.stringify(request),
      method: PUT,
      prefetchAction: {
        type: UPDATE_WIDGET,
      },
      successAction: (responseBody) => ({
        type: UPDATE_WIDGET_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: UPDATE_WIDGET_FAILED,
      }),
    }),
  );
};

export const updateComponent = (newComponent) => (dispatch, getState) => {
  const {
    analytics: {
      data: { selectedDashboard },
    },
  } = getState();
  const component = {
    name: newComponent.name,
    dashboard: selectedDashboard.id,
    template: newComponent.template.id,
    positionX: newComponent.positionX,
    positionY: newComponent.positionY,
    width: newComponent.width,
    height: newComponent.height,
    settings: newComponent.settings,
  };
  return dispatch(
    AuthenticatedFetch({
      url: 'components/' + newComponent.id,
      body: JSON.stringify(component),
      method: PUT,
      prefetchAction: {
        type: UPDATE_WIDGET,
      },
      successAction: (responseBody) => ({
        type: UPDATE_WIDGET_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: UPDATE_WIDGET_FAILED,
      }),
    }),
  ).then(() => {
    return dispatch(fetchAllComponents(selectedDashboard.id));
  });
};

const getOperatorsList = (data) => {
  const result = [];
  data.forEach((d) => result.push(d.operatorId));
  return result;
};

export const getPrinterSensorData = (component) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'components/' + component.id + '/data',
      method: GET,
      queryParams: {
        'printer-id': component.settings[0]?.value,
      },
      prefetchAction: {
        type: FETCH_PRINTER_SENSORS_DATA,
      },
      successAction: (responseBody) => ({
        type: FETCH_PRINTER_SENSORS_DATA_SUCCEEDED,
        payload: responseBody.data,
      }),
      failureAction: () => ({
        type: FETCH_PRINTER_SENSORS_DATA_FAILED,
      }),
    }),
  );
};

export const getPrinterSensorHistoricalData =
  (component, startTimestamp) => (dispatch) => {
    if (
      typeof startTimestamp === 'string' ||
      startTimestamp instanceof String
    ) {
      startTimestamp = new Date(startTimestamp);
    }
    return dispatch(
      AuthenticatedFetch({
        url: 'components/' + component.id + '/data',
        method: GET,
        queryParams: {
          'print-id': component.settings.find(
            (set) => set.settingTemplate.name === 'print-id',
          )?.value,
          'printer-id': component.settings.find(
            (set) => set.settingTemplate.name === 'printer-id',
          )?.value,
          'start-timestamp': timestampToFormattedString(startTimestamp),
        },
        prefetchAction: {
          type: FETCH_PRINTER_SENSORS_HISTORICAL_DATA,
        },
        successAction: () => ({
          type: FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED,
        }),
        failureAction: () => ({
          type: FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED,
        }),
      }),
    );
  };

/**
 * It transforms a timestamp as Date type into another string formatted in this
 * way: yyyy-MM-dd HH:mm:ss.SSSSSSSSS
 * @param {Date} dateObj
 * @returns
 */
const timestampToFormattedString = (dateObj) => {
  return moment(dateObj).utc().format('yyyy-MM-DD HH:mm:ss.SSS') + '000000';
};

export const getPrinterLastImages = (component, serialCode) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'components/' + component.id + '/data',
      method: GET,
      queryParams: {
        'printer-id': component.settings[0]?.value,
      },
      prefetchAction: {
        type: FETCH_PRINTER_IMAGES,
      },
      successAction: (responseBody) => ({
        type: FETCH_PRINTER_IMAGES_SUCCEEDED,
        payload: responseBody.data,
        serialCode: serialCode,
      }),
      failureAction: () => ({
        type: FETCH_PRINTER_IMAGES_FAILED,
      }),
    }),
  );
};

export const getToolpathComparatorData = (component) => (dispatch) => {
  return dispatch(
    AuthenticatedFetch({
      url: 'components/' + component.id + '/data',
      method: GET,
      queryParams: {
        operators: component.settings[0]?.value,
      },
      prefetchAction: {
        type: FETCH_WIDGET_DATA,
      },
      successAction: (responseBody) => ({
        type: FETCH_WIDGET_DATA_SUCCEEDED,
        payload: responseBody.data,
        operators: getOperatorsList(responseBody.data),
      }),
      failureAction: () => ({
        type: FETCH_WIDGET_DATA_FAILED,
      }),
    }),
  );
};

export const getToolpathAnalyzerData =
  (component, startCommandIdx) => (dispatch) => {
    return dispatch(
      AuthenticatedFetch({
        url: 'components/' + component.id + '/data',
        method: GET,
        queryParams: {
          'operator-id': component.settings.find(
            (setting) => setting.settingTemplate.name === 'toolpath-id',
          )?.value,
          orgId: component.settings.find(
            (setting) => setting.settingTemplate.name === 'org-id',
          )?.value,
          'start-command-idx': startCommandIdx,
        },
        prefetchAction: {
          type: FETCH_TOOLPATH_ANALYSIS_DATA,
        },
        successAction: (responseBody) => ({
          type: FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED,
          payload: responseBody.data,
          toolpathId: component.settings.find(
            (setting) => setting.settingTemplate.name === 'toolpath-id',
          )?.value,
        }),
        failureAction: () => ({
          type: FETCH_TOOLPATH_ANALYSIS_DATA_FAILED,
        }),
      }),
    );
  };

export const deleteComponent = (component) => (dispatch, getState) => {
  const {
    analytics: {
      data: { selectedDashboard },
    },
  } = getState();

  return dispatch(
    AuthenticatedFetch({
      url: 'components/' + component.id,
      method: DELETE,
      prefetchAction: {
        type: DELETE_WIDGET,
      },
      successAction: (responseBody) => ({
        type: DELETE_WIDGET_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: DELETE_WIDGET_FAILED,
      }),
    }),
  ).then(() => {
    return dispatch(fetchAllComponents(selectedDashboard.id));
  });
};

export const createDashboard = (newDashboard) => (dispatch) => {
  const currentUser = JSON.parse(
    localStorage.getItem(CURRENT_USER_KEY_LOCAL_STORAGE),
  );
  const dashboard = {
    name: newDashboard.name,
    organization: currentUser.organizationId,
    active: true,
    example: false,
  };
  return dispatch(
    AuthenticatedFetch({
      url: 'dashboards',
      body: JSON.stringify(dashboard),
      method: POST,
      prefetchAction: {
        type: CREATE_DASHBOARD,
      },
      successAction: (responseBody) => ({
        type: CREATE_DASHBOARD_SUCCEEDED,
        payload: responseBody,
      }),
      failureAction: () => ({
        type: CREATE_DASHBOARD_FAILED,
      }),
    }),
  );
};

export const changeNewDashboardName = (value) => ({
  type: NEW_DASHBOARD_NAME_MODIFIED,
  payload: {
    name: value,
  },
});

export const changeUpdatedDashboardName = (value) => ({
  type: UPDATE_DASHBOARD_NAME_MODIFIED,
  payload: {
    name: value,
  },
});

export const showCreateWidgetDialog = (params) => ({
  type: SHOW_CREATE_WIDGET_DIALOG,
  payload: {
    type: params.type,
    template: params.template,
  },
});

export const showEditDialog = (
  widget,
  forceArray = false,
  settingTemplateKey = undefined,
  customData = undefined,
) => {
  const value = settingTemplateKey
    ? widget.settings.find(
        (setting) => setting.settingTemplate.name === settingTemplateKey,
      )?.jsonValue
    : widget.settings[0]?.jsonValue;
  return {
    type: SHOW_EDIT_WIDGET_DIALOG,
    payload: {
      widget: widget,
      selections: forceArray ? [value] : value,
      customData: customData, //used for any customization of the widget
    },
  };
};

export const dismissCreateWidgetDialog = () => ({
  type: DISMISS_CREATE_WIDGET_DIALOG,
});

export const showCreateDashboardDialog = () => ({
  type: SHOW_CREATE_DASHBOARD_DIALOG,
});

export const showDeleteDashboardDialog = (dashboardId) => ({
  type: SHOW_DELETE_DASHBOARD_DIALOG,
  dashboardIdToDelete: dashboardId,
});

export const showUpdateDashboardDialog = (dashboardId, dashboardName) => ({
  type: SHOW_UPDATE_DASHBOARD_DIALOG,
  dashboardIdToUpdate: dashboardId,
  dashboardCurrentName: dashboardName,
});

export const dismissDeleteDashboardDialog = () => ({
  type: DISMISS_DELETE_DASHBOARD_DIALOG,
});

export const dismissUpdateDashboardDialog = () => ({
  type: DISMISS_UPDATE_DASHBOARD_DIALOG,
});

export const deleteDashboard = (dashboardId) => (dispatch) =>
  dispatch(
    AuthenticatedFetch({
      url: `dashboards/${dashboardId}`,
      method: DELETE,
      prefetchAction: {
        type: DELETE_DASHBOARD,
      },
      successAction: () => ({
        type: DELETE_DASHBOARD_SUCCEEDED,
        payload: {
          dashboardId: dashboardId,
        },
      }),
      failureAction: () => ({
        type: DELETE_DASHBOARD_FAILED,
      }),
    }),
  );

export const updateDashboard = () => (dispatch, getState) => {
  const {
    analytics: {
      ui: { dashboardIdToUpdate, updatedDashboardName },
    },
  } = getState();

  const dashboard = {
    id: dashboardIdToUpdate,
    name: updatedDashboardName,
    active: true,
    example: false,
  };

  return dispatch(
    AuthenticatedFetch({
      url: `dashboards`,
      method: PUT,
      body: JSON.stringify(dashboard),
      prefetchAction: {
        type: UPDATE_DASHBOARD,
      },
      successAction: () => ({
        type: UPDATE_DASHBOARD_SUCCEEDED,
        payload: {
          dashboardId: dashboardIdToUpdate,
          name: updatedDashboardName,
        },
      }),
      failureAction: () => ({
        type: UPDATE_DASHBOARD_FAILED,
      }),
    }),
  );
};

export const resetSelectedDashboard = () => ({
  type: RESET_SELECTED_DASHBOARD,
});

export const getPrinterCameras = (cameras = []) => ({
  type: FETCH_PRINTER_CAMERAS_REQUEST,
  payload: { cameras },
});

export const getPrinterCamerasSuccess = (cameras = []) => ({
  type: FETCH_PRINTER_CAMERAS_SUCCESS,
  payload: { cameras },
});

export const getPrinterCamerasFailure = (error) => ({
  type: FETCH_PRINTER_CAMERAS_FAILURE,
  payload: { error },
});

export const updateCameraSensitivity = (
  printerId = '',
  cameraId = '',
  sensitivity = 0,
) => ({
  type: UPDATE_CAMERA_SENSITIVITY,
  payload: { printerId, cameraId, sensitivity },
});

export const updateCameraSensitivitySuccess = (cameraId = '', camera = {}) => ({
  type: UPDATE_CAMERA_SENSITIVITY_SUCCESS,
  payload: { cameraId, camera },
});

export const updateCameraSensitivityFailure = (error) => ({
  type: UPDATE_CAMERA_SENSITIVITY_FAILURE,
  payload: { error },
});
