import styled, { css } from 'styled-components';
import { flexColumn, when } from '@stylesheets/helpers';

export const OuterWrapper = styled.div`
  ${flexColumn}
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  overflow: clip;
  transition-delay: 0.4s;

  ${({ zIndex, show }) => css`
    ${when(
      show,
      `
        transition-delay: 0s;
        opacity: 1;
        visibility: visible;
      `,
    )}

    ${when(
      zIndex,
      `
        z-index: ${zIndex};
      `,
    )}
  `}

  &:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    ${({ show, hideOuterWrapper, theme: { colors } }) => css`
      background-color: ${colors.scrim};

      ${when(
        show && !hideOuterWrapper,
        `
          transition: opacity 0.5s ease;
          opacity: 0.5;
          visibility: visible;
        `,
      )}
    `}
  }
`;

export const InnerWrapper = styled.div`
  ${flexColumn}
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s ease 0.3s, transform 0.3s ease-in-out,
    opacity 0.2s ease-in-out 0.1s;
  outline: none;

  ${({
    sideViewVariant,
    show,
    mediumSizeVariant,
    theme: { colors, borderRadius, spacing },
  }) => css`
    background-color: ${colors.surfaceContainerLow};
    border-radius: ${borderRadius.large};

    ${when(
      sideViewVariant,
      `
        width: calc(min(100%, 400px) - ${spacing.level4} * 2);
        height: calc(100% - ${spacing.level4} * 2);
        top: ${spacing.level4};
        left: ${spacing.level4};
        transform: translateX(-100%);
        overflow-y: clip;

        ${when(
          show,
          `
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;
          `,
        )}
      `,
      `
        width: min(100%, ${mediumSizeVariant ? '1000px' : '600px'});
        max-height: calc(100% - 48px * 2);
        transform: translate(-50%, calc(-50% + (100svh / 2)));
        top: 50%;
        left: 50%;
        overflow: hidden;

        ${when(
          show,
          `
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);
            transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.1s;
          `,
        )}
      `,
    )}
  `}
`;

export const Content = styled.div`
  ${flexColumn}
  flex: 1;
  overflow: auto;

  ${({ disableContentPadding, theme: { spacing, colors } }) => css`
    color: ${colors.onSurface};

    ${when(
      !disableContentPadding,
      `
        padding: 0 ${spacing.level6};
      `,
    )}
  `}
`;
