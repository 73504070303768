import {
  SELECT_ANALYTICS_PRINTER,
  ALL_SENSORS_DATA,
  FETCH_ALL_DASHBOARDS,
  DASHBOARDS_LOADED,
  FETCH_ALL_DASHBOARDS_FAILED,
  FETCH_ALL_WIDGET_TEMPLATES,
  WIDGET_TEMPLATES_LOADED,
  FETCH_ALL_WIDGET_TEMPLATES_FAILED,
  DASHBOARD_SELECTED,
  RESET_SELECTED_DASHBOARD,
  SHOW_CREATE_DASHBOARD_DIALOG,
  CREATE_DASHBOARD,
  CREATE_DASHBOARD_FAILED,
  CREATE_DASHBOARD_SUCCEEDED,
  DISMISS_CREATE_DASHBOARD_DIALOG,
  DASHBOARD_NAME_MODIFIED,
  NEW_DASHBOARD_NAME_MODIFIED,
  SHOW_DELETE_DASHBOARD_DIALOG,
  DISMISS_DELETE_DASHBOARD_DIALOG,
  DELETE_DASHBOARD,
  DELETE_DASHBOARD_SUCCEEDED,
  DELETE_DASHBOARD_FAILED,
  SHOW_UPDATE_DASHBOARD_DIALOG,
  DISMISS_UPDATE_DASHBOARD_DIALOG,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCEEDED,
  UPDATE_DASHBOARD_FAILED,
  UPDATE_DASHBOARD_NAME_MODIFIED,
  DISMISS_CREATE_WIDGET_DIALOG,
  SHOW_CREATE_WIDGET_DIALOG,
  FETCH_ALL_WORKSPACES_SUCCEEDED,
  TOOLPATH_WORKSPACE_SELECTED,
  TOOLPATH_FETCH_ALL_CONCEPTS,
  TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED,
  TOOLPATH_FETCH_ALL_CONCEPTS_FAILED,
  TOOLPATH_WORKFLOW_SELECTED,
  TOOLPATH_SELECTED,
  TOOLPATH_REMOVED,
  FETCH_ALL_DASHBOARD_WIDGETS,
  FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED,
  FETCH_ALL_DASHBOARD_WIDGETS_FAILED,
  CREATE_WIDGET,
  CREATE_WIDGET_SUCCEEDED,
  CREATE_WIDGET_FAILED,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCEEDED,
  UPDATE_WIDGET_FAILED,
  NEW_COMPONENT_NAME_CHANGED,
  DELETE_WIDGET,
  DELETE_WIDGET_SUCCEEDED,
  DELETE_WIDGET_FAILED,
  SHOW_EDIT_WIDGET_DIALOG,
  FETCH_WIDGET_DATA,
  FETCH_WIDGET_DATA_SUCCEEDED,
  FETCH_WIDGET_DATA_FAILED,
  FETCH_PRINTER_SENSORS_DATA_SUCCEEDED,
  FETCH_PRINTER_SENSORS_DATA,
  FETCH_PRINTER_SENSORS_DATA_FAILED,
  FETCH_PRINTER_IMAGES,
  FETCH_PRINTER_IMAGES_SUCCEEDED,
  FETCH_PRINTER_IMAGES_FAILED,
  FETCH_CAMERA_VIDEO_FEED_URL,
  FETCH_CAMERA_VIDEO_FEED_URL_SUCCEEDED,
  FETCH_CAMERA_VIDEO_FEED_URL_FAILED,
  SELECT_PRINTER_WIDGET,
  FETCH_TOOLPATH_ANALYSIS_DATA_FAILED,
  FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED,
  FETCH_TOOLPATH_ANALYSIS_DATA,
  SELECTED_PRINT_VIDEO_FEED,
  FETCH_ALL_PRINT_DASHBOARDS,
  FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED,
  FETCH_ALL_PRINT_DASHBOARDS_FAILED,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED,
  FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED,
  FETCH_PRINTER_CAMERAS_SUCCESS,
  FETCH_PRINTER_CAMERAS_REQUEST,
  FETCH_PRINTER_CAMERAS_FAILURE,
  UPDATE_CAMERA_SENSITIVITY_SUCCESS,
} from '../constants/actionTypes.js';

import { PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP } from '../constants/utilityConstants.js';

const initialState = {
  ui: {
    selectedPrinter: null,
    fetchesInProgress: 0,
    newDashboard: {
      isCreatingDashboard: false,
      name: '',
    },
    newComponent: null,
    unsavedModifications: {},
    isDeletingDashboard: false,
    dashboardIdToDelete: null,
    isUpdatingDashboard: false,
    dashboardIdToUpdate: null,
    updatedDashboardName: null,
    widgetsPanelOpened: true,
    dashboardsPanelOpened: true,
    selectedPrint: {}, //widgetId:printId
  },
  data: {
    //printerSensorValues = {printerId:{value1:[value1-1,value1-2,....value1-n]}}
    printerSensorValues: {},
    dashboards: [],
    selectedDashboard: null,
    areDashboardsLoaded: false,
    widgetTemplates: [],
    dashboardWidgets: [],
    widgets_data: [],
    printerImagesUrl: [],
    toolpathAnalyzerData: {},
    dashboardCameras: [],
  },
  toolpathSelector: {
    selections: [], //array of objects {workspace, workflow, toolpath}
    workspaceSelected: null,
    workflowSelected: null,
    fetchedWorkspaces: [], //array of workspaces objects
    fetchedWorkflows: [], //array of workflows objects
    fetchedToolpaths: [], //array of toolpaths objects
  },
};

const MAX_DATA_COUNT = 50;

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_SENSORS_DATA: {
      const { sensorsData, printerId } = action.payload;
      const { printerSensorValues } = state.data;
      const oldPrinterSensorValuesOfPrinter =
        printerSensorValues[printerId] || {};
      const oldGenerationTimestampArray =
        oldPrinterSensorValuesOfPrinter[
          PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP
        ] || [];

      const updatedPrinterSensorValuesOfPrinter = {
        ...oldPrinterSensorValuesOfPrinter,
        [PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP]: [
          ...oldGenerationTimestampArray,
          sensorsData.generationTimestamp,
        ].slice(-MAX_DATA_COUNT),
      };

      sensorsData.measurements.forEach((measure) => {
        const { sensorName } = measure;
        const sensorArray =
          updatedPrinterSensorValuesOfPrinter[sensorName] || [];

        updatedPrinterSensorValuesOfPrinter[sensorName] = [
          ...sensorArray,
          measure,
        ].slice(-MAX_DATA_COUNT);
      });

      return {
        ...state,
        data: {
          ...state.data,
          printerSensorValues: {
            ...printerSensorValues,
            [printerId]: updatedPrinterSensorValuesOfPrinter,
          },
        },
      };
    }

    case SELECT_ANALYTICS_PRINTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedPrinter: action.payload.printerId,
        },
      };
    case FETCH_ALL_DASHBOARD_WIDGETS_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          dashboardWidgets: action.payload.map((widget) => ({
            ...widget,
            settings: widget.settings.map((settings) => ({
              ...settings,
              ...(settings.jsonValue
                ? { jsonValue: JSON.parse(settings.jsonValue) }
                : {}),
            })),
          })),
        },
      };
    case UPDATE_WIDGET_SUCCEEDED:
    case CREATE_WIDGET_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          newComponent: null,
        },
        toolpathSelector: {
          ...state.toolpathSelector,
          workspaceSelected: null,
          workflowSelected: null,
          fetchedWorkflows: [],
          fetchedToolpaths: [],
          fetchedWorkspaces: [],
          selections: [],
        },
      };
    case FETCH_ALL_WORKSPACES_SUCCEEDED:
      return {
        ...state,
        toolpathSelector: {
          ...state.toolpathSelector,
          fetchedWorkspaces: action.payload,
        },
      };
    case TOOLPATH_SELECTED:
      return {
        ...state,
        toolpathSelector: {
          ...state.toolpathSelector,
          selections: [
            ...state.toolpathSelector.selections,
            {
              workspace: state.toolpathSelector.workspaceSelected,
              workflow: state.toolpathSelector.workflowSelected,
              toolpath: action.payload.toolpath,
            },
          ],
        },
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            selectedToolpaths: [
              ...state.toolpathSelector.selections,
              {
                workspace: state.toolpathSelector.workspaceSelected,
                workflow: state.toolpathSelector.workflowSelected,
                toolpath: action.payload.toolpath,
              },
            ],
          },
        },
      };
    case TOOLPATH_REMOVED:
      return {
        ...state,
        toolpathSelector: {
          ...state.toolpathSelector,
          selections: state.toolpathSelector.selections.filter(
            (item) =>
              item.workspace.id !== action.payload.selection.workspace.id ||
              item.workflow.id !== action.payload.selection.workflow.id ||
              item.toolpath.id !== action.payload.selection.toolpath.id,
          ),
        },
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            selectedToolpaths: state.toolpathSelector.selections.filter(
              (item) =>
                item.workspace.id !== action.payload.selection.workspace.id ||
                item.workflow.id !== action.payload.selection.workflow.id ||
                item.toolpath.id !== action.payload.selection.toolpath.id,
            ),
          },
        },
      };
    case TOOLPATH_WORKSPACE_SELECTED:
      return {
        ...state,
        toolpathSelector: {
          ...state.toolpathSelector,
          workspaceSelected: action.payload.workspace,
          workflowSelected: null,
          fetchedWorkflows: [],
          fetchedToolpaths: [],
        },
      };
    case TOOLPATH_WORKFLOW_SELECTED:
      return {
        ...state,
        toolpathSelector: {
          ...state.toolpathSelector,
          workflowSelected: action.payload.workflow,
          fetchedToolpaths: action.payload.toolpaths,
        },
      };
    case TOOLPATH_FETCH_ALL_CONCEPTS:
    case FETCH_ALL_DASHBOARD_WIDGETS:
    case DELETE_WIDGET:
    case CREATE_WIDGET:
    case UPDATE_WIDGET:
    case FETCH_WIDGET_DATA:
    case FETCH_TOOLPATH_ANALYSIS_DATA:
    case FETCH_PRINTER_SENSORS_DATA:
    case FETCH_PRINTER_IMAGES:
    case FETCH_CAMERA_VIDEO_FEED_URL:
    case FETCH_PRINTER_SENSORS_HISTORICAL_DATA:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case FETCH_ALL_DASHBOARDS:
    case FETCH_ALL_PRINT_DASHBOARDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
        data: {
          ...state.data,
          dashboardWidgets: [],
          widgets_data: [],
          areDashboardsLoaded: false,
        },
      };
    case TOOLPATH_FETCH_ALL_CONCEPTS_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        toolpathSelector: {
          ...state.toolpathSelector,
          fetchedWorkflows: action.payload,
        },
      };
    case FETCH_WIDGET_DATA_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          widgets_data: state.data.widgets_data
            .filter((item) => !action.operators?.includes(item.operatorId))
            .concat(action.payload),
        },
      };
    case FETCH_TOOLPATH_ANALYSIS_DATA_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          toolpathAnalyzerData: {
            ...state.data.toolpathAnalyzerData,
            [action.toolpathId]: action.payload,
          },
        },
      };
    case FETCH_PRINTER_IMAGES_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          printerImagesUrl: {
            ...state.data.printerImagesUrl,
            [action.serialCode]: action.payload,
          },
        },
      };
    case DASHBOARDS_LOADED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          dashboards: action.payload,
          areDashboardsLoaded: true,
        },
      };
    case FETCH_ALL_PRINT_DASHBOARDS_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          dashboards: action.payload.printDashboards.map(
            (dashb) => dashb.dashboard,
          ),
          areDashboardsLoaded: true,
        },
      };
    case TOOLPATH_FETCH_ALL_CONCEPTS_FAILED:
    case FETCH_ALL_DASHBOARD_WIDGETS_FAILED:
    case CREATE_WIDGET_FAILED:
    case UPDATE_WIDGET_FAILED:
    case FETCH_ALL_DASHBOARDS_FAILED:
    case FETCH_ALL_PRINT_DASHBOARDS_FAILED:
    case DELETE_WIDGET_SUCCEEDED:
    case DELETE_WIDGET_FAILED:
    case FETCH_WIDGET_DATA_FAILED:
    case FETCH_PRINTER_SENSORS_DATA_FAILED:
    case FETCH_PRINTER_IMAGES_FAILED:
    case FETCH_TOOLPATH_ANALYSIS_DATA_FAILED:
    case FETCH_CAMERA_VIDEO_FEED_URL_FAILED:
    case FETCH_CAMERA_VIDEO_FEED_URL_SUCCEEDED:
    case FETCH_PRINTER_SENSORS_DATA_SUCCEEDED:
    case FETCH_PRINTER_SENSORS_HISTORICAL_DATA_FAILED:
    case FETCH_PRINTER_SENSORS_HISTORICAL_DATA_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          areDashboardsLoaded: true,
        },
      };
    case FETCH_ALL_WIDGET_TEMPLATES:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
          widgetsPanelOpened: true,
        },
      };
    case WIDGET_TEMPLATES_LOADED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          widgetTemplates: action.payload,
        },
      };
    case FETCH_ALL_WIDGET_TEMPLATES_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case NEW_COMPONENT_NAME_CHANGED:
      return {
        ...state,
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            name: action.name,
          },
        },
      };
    case DASHBOARD_SELECTED:
      return {
        ...state,
        data: {
          ...state.data,
          selectedDashboard: action.dashboard,
        },
      };
    case RESET_SELECTED_DASHBOARD:
      return {
        ...state,
        data: {
          ...state.data,
          selectedDashboard: initialState.data.selectedDashboard,
        },
      };
    case SHOW_CREATE_WIDGET_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            name: '',
            isCreatingComponent: true,
            type: action.payload.type,
            template: action.payload.template,
            printerId: '',
          },
        },
      };
    case SELECT_PRINTER_WIDGET:
      return {
        ...state,
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            printerId: action?.payload?.id,
          },
        },
      };
    case SHOW_EDIT_WIDGET_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          newComponent: {
            ...state.ui.newComponent,
            id: action.payload.widget.id,
            positionX: action.payload.widget.positionX,
            positionY: action.payload.widget.positionY,
            width: action.payload.widget.width,
            height: action.payload.widget.height,
            name: action.payload.widget.name,
            isCreatingComponent: false,
            isUpdatingComponent: true,
            type: action.payload.widget.template.name,
            template: action.payload.widget.template,
            selectedToolpaths: action.payload.selections, //TODO: it should be moved
            printerId: action.payload.widget.settings?.find(
              (set) => set.settingTemplate?.name === 'printer-id',
            )?.value, //TODO: it should be moved
            customData: action.payload.customData,
          },
        },
        toolpathSelector: {
          ...state.toolpathSelector,
          selections: action.payload.selections,
        },
      };
    case SHOW_CREATE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          newDashboard: {
            ...state.ui.newDashboard,
            isCreatingDashboard: true,
            name: '',
          },
        },
      };
    case UPDATE_DASHBOARD_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatedDashboardName: action.payload.name,
        },
      };
    case NEW_DASHBOARD_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          newDashboard: {
            ...state.ui.newDashboard,
            name: action.payload.name,
          },
        },
      };
    case DISMISS_CREATE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          newDashboard: {
            ...state.ui.newDashboard,
            isCreatingDashboard: false,
            name: '',
          },
        },
      };
    case DISMISS_CREATE_WIDGET_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          newComponent: null,
        },
        toolpathSelector: {
          ...state.toolpathSelector,
          workspaceSelected: null,
          workflowSelected: null,
          fetchedWorkflows: [],
          fetchedToolpaths: [],
          fetchedWorkspaces: [],
          selections: [],
        },
      };
    case CREATE_DASHBOARD:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case CREATE_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          newDashboard: {
            ...state.ui.newDashboard,
            isCreatingDashboard: false,
          },
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
        data: {
          ...state.data,
          dashboards: [...state.data.dashboards, action.payload],
        },
      };

    case CREATE_DASHBOARD_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
        },
      };
    case DASHBOARD_NAME_MODIFIED:
      return {
        ...state,
        ui: {
          ...state.ui,
          unsavedModifications: {
            ...state.ui.unsavedModifications,
            [action.payload.dashboardId]: {
              ...(state.ui.unsavedModifications[action.payload.dashboardId] ||
                {}),
              name: action.payload.name,
            },
          },
        },
      };
    case SHOW_UPDATE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingDashboard: true,
          dashboardIdToUpdate: action.dashboardIdToUpdate,
          updatedDashboardName: action.dashboardCurrentName,
        },
      };
    case DISMISS_UPDATE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingDashboard: false,
          dashboardIdToUpdate: null,
          updatedDashboardName: null,
        },
      };
    case UPDATE_DASHBOARD:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case UPDATE_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isUpdatingDashboard: false,
          dashboardIdToUpdate: null,
          updatedDashboardName: null,
        },
        data: {
          ...state.data,
          dashboards: state.data.dashboards.map((wrs) => {
            if (wrs.id === action.payload.dashboardId) {
              wrs.name = action.payload.name;
              return wrs;
            }
            return wrs;
          }),
        },
      };
    case UPDATE_DASHBOARD_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isUpdatingDashboard: false,
          dashboardIdToUpdate: null,
          updatedDashboardName: null,
        },
      };
    case SHOW_DELETE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingDashboard: true,
          dashboardIdToDelete: action.dashboardIdToDelete,
        },
      };
    case DISMISS_DELETE_DASHBOARD_DIALOG:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingDashboard: false,
          dashboardIdToDelete: null,
        },
      };
    case DELETE_DASHBOARD:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress + 1,
        },
      };
    case DELETE_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingDashboard: false,
          dashboardIdToDelete: null,
        },
        data: {
          ...state.data,
          dashboards: state.data.dashboards.filter(
            (dashboard) => dashboard.id !== action.payload.dashboardId,
          ),
          selectedDashboard: null,
          dashboardWidgets: [],
          widgets_data: [],
        },
      };
    case DELETE_DASHBOARD_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          fetchesInProgress: state.ui.fetchesInProgress - 1,
          isDeletingDashboard: false,
          dashboardIdToDelete: null,
        },
      };
    case SELECTED_PRINT_VIDEO_FEED:
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedPrint: {
            ...state.ui.selectedPrint,
            [action.widgetId]: action.print,
          },
        },
      };

    case FETCH_PRINTER_CAMERAS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dashboardCameras: action?.payload?.cameras,
        },
      };

    case FETCH_PRINTER_CAMERAS_REQUEST:
    case FETCH_PRINTER_CAMERAS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          dashboardCameras: initialState.data.dashboardCameras,
        },
      };

    case UPDATE_CAMERA_SENSITIVITY_SUCCESS: {
      const { cameraId, camera } = action.payload;
      const nextDashboardCameras = state.data.dashboardCameras?.map(
        (dashboardCamera) => {
          if (dashboardCamera?.camerasObj?.[0]?.id !== cameraId)
            return dashboardCamera;

          return camera;
        },
      );

      return {
        ...state,
        data: {
          ...state.data,
          dashboardCameras: nextDashboardCameras,
        },
      };
    }

    default:
      return state;
  }
};

export default analyticsReducer;
