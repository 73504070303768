import * as THREE from 'three';
import { Printer } from '../Printer';
import Plinth from './Plinth';
import { getPlinthDefinitionResponse } from './PlinthUtils';
import { s3Directories } from '../../constants/printers/s3Directories';

class PlinthCustom extends Plinth {
  constructor(printerSettings, plinthDefinitions) {
    super(
      printerSettings,
      PlinthCustom.getMachineDefaults(),
      'CUSTOM',
      plinthDefinitions,
    );
  }

  static getMachineDefaults() {
    return {
      offset: {
        x: 0,
        y: 0,
        z: 0,
      },
    };
  }

  getPlinthSettingsType() {
    return '';
  }

  initializeModels() {
    const plinthDefinitionResponse = getPlinthDefinitionResponse(
      this.printerSettings,
    );

    return new Promise((resolve) => {
      if (!plinthDefinitionResponse) resolve(new THREE.Group());

      const { modelUrls } = plinthDefinitionResponse;

      const plinthUrl = modelUrls[s3Directories.plinthBase];

      Promise.resolve(Printer.getModel(plinthUrl)).then((model) => {
        this.add(model);
        resolve();
      });
    });
  }

  getPlinthHeight() {
    const plinthDefinitionResponse = getPlinthDefinitionResponse(
      this.printerSettings,
    );

    return plinthDefinitionResponse.height;
  }
}

export default PlinthCustom;
