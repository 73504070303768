import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Canvas as ThreeCanvas } from '@react-three/fiber';
import { useContextBridge } from '@react-three/drei';
import { VisualizationContext } from '@contexts/VisualizationContext';

export default function Canvas({ children }) {
  const ContextBridge = useContextBridge(VisualizationContext);
  const { setThreeState } = useContext(VisualizationContext);

  const initializeScene = useCallback(
    (state) => {
      setThreeState(state);
    },
    [setThreeState],
  );

  const glConfigs = {
    'shadowMap.enabled': true,
    localClippingEnabled: true,
    alpha: true,
  };

  return (
    <ThreeCanvas
      className="threejs"
      onCreated={initializeScene}
      gl={glConfigs}
      shadows
    >
      <ContextBridge>{children}</ContextBridge>
    </ThreeCanvas>
  );
}

Canvas.propTypes = {
  children: PropTypes.node,
};
