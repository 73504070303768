import PageList from '@components/3-organisms/PageList';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Wrapper } from '@pages/Library/Library.styled';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import useWorkflowQueries from '@hooks/workflows/useWorkflowQueries';
import {
  EXAMPLE_WORKFLOWS,
  EXAMPLE_WORKFLOWS_NAMES,
} from '@containers/WorkflowExamples/WorkflowExamples';
import { LIST_ITEM_VARIANT_THUMBNAIL } from '@components/1-atoms/ListItem';

export const Library = () => {
  const intl = useIntl();
  const history = useHistory();
  const { publicWorkflowsQuery } = useWorkflowQueries();
  const { refetch: refetchPublicWorkflowsQuery } = publicWorkflowsQuery;
  const publicWorkflows = publicWorkflowsQuery.data;

  useEffect(() => {
    refetchPublicWorkflowsQuery();
  }, [refetchPublicWorkflowsQuery]);

  const publicWorkflowsListItems = useMemo(() => {
    if (!publicWorkflows) return [];

    const filteredWorkflows = publicWorkflows
      .filter(({ name }) => EXAMPLE_WORKFLOWS_NAMES.includes(name?.trim()))
      .sort((leftWorkflow, rightWorkflow) => {
        return (
          EXAMPLE_WORKFLOWS_NAMES.indexOf(leftWorkflow?.name) -
          EXAMPLE_WORKFLOWS_NAMES.indexOf(rightWorkflow?.name)
        );
      });

    return filteredWorkflows?.map((workflow) => {
      return {
        id: workflow?.id,
        label: workflow?.name,
        leadingIconName: 'space_dashboard_0',
        thumbnailUrl: EXAMPLE_WORKFLOWS?.[workflow?.name]?.thumbnailUrl,
        createdAt: workflow?.createdAt,
        onClick: () =>
          history.push(
            generatePath(ROUTES.WORKFLOW, {
              workspaceId: workflow?.workspaceId,
              itemId: workflow?.id,
            }),
          ),
      };
    });
  }, [history, publicWorkflows]);

  const handleButtonPress = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);

  return (
    <Wrapper>
      <PageList
        dataTestId={'library-list'}
        listItems={publicWorkflowsListItems}
        listItemVariant={LIST_ITEM_VARIANT_THUMBNAIL}
        loading={
          !publicWorkflowsQuery.isFetched || publicWorkflowsQuery.isLoading
        }
        headerTitle={intl.formatMessage({
          id: 'navigation.link.library',
          defaultMessage: 'Library',
        })}
        createItemMessageIconName="space_dashboard_0"
        createItemMessageTitle={intl.formatMessage({
          id: 'library.no.examples.yet.title',
          defaultMessage: 'No examples yet',
        })}
        createItemMessageButtonLabel={intl.formatMessage({
          id: 'library.no.examples.yet.button',
          defaultMessage: 'Go to home',
        })}
        createItemMessageButtonIconName="link_off_0"
        createItemMessageOnButtonClick={handleButtonPress}
        sortOptions={[
          {
            label: intl.formatMessage({
              id: 'general.date',
              defaultMessage: 'Date',
            }),
            value: 'createdAt',
          },
          {
            label: intl.formatMessage({
              id: 'general.name',
              defaultMessage: 'Name',
            }),
            value: 'label',
          },
        ]}
      />
    </Wrapper>
  );
};

Library.propTypes = {};

export default Library;
