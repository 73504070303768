import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useOperatorList from '@hooks/operators/useOperatorList';
import WorkflowReadOnlyOperatorFields from '@containers/WorkflowReadOnlyOperatorFields';
import Operator from '@components/2-molecules/Operator';
import SettingsCategory from '@components/2-molecules/SettingsCategory';
import WorkflowOperatorOutputs from '@containers/WorkflowOperatorOutputs';
import { Wrapper, Handle } from './OperatorNode.styled';

function OperatorNode({ id, data = {}, targetPosition, sourcePosition }) {
  const intl = useIntl();
  const {
    status,
    operator,
    sourcetHandle,
    targetHandle,
    expanded,
    toggleExpand,
  } = data;
  const { getDefaultOperator } = useOperatorList();

  const operatorIcon = getDefaultOperator(operator.name)?.iconName;

  const getOperatorName = useCallback((operator) => {
    let name = operator?.tag || operator?.name;

    if (operator?.name === 'Load' && operator?.name !== operator?.tag) {
      name = `${operator?.name}: ${operator?.tag}`;
    }

    return name;
  }, []);

  return (
    <Wrapper key={id}>
      {targetHandle && (
        <Handle
          type="target"
          orientation="horizontal"
          position={targetPosition}
          isConnectable={false}
        />
      )}

      {sourcetHandle && (
        <Handle
          type="source"
          orientation="horizontal"
          position={sourcePosition}
          isConnectable={false}
        />
      )}

      <Operator
        dataTestId={`workflow-full-screen-operator-${operator.id}`}
        operatorIcon={operatorIcon}
        operatorName={getOperatorName(operator)}
        disableActions
        expanded={expanded}
        status={status}
        onExpandButtonClick={toggleExpand}
      >
        <SettingsCategory
          title={intl.formatMessage({
            id: 'general.settings',
            defaultMessage: 'Settings',
          })}
          expand
        >
          <WorkflowReadOnlyOperatorFields operator={operator} />
        </SettingsCategory>

        <WorkflowOperatorOutputs disabled operator={operator} />
      </Operator>
    </Wrapper>
  );
}

OperatorNode.propTypes = {
  id: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  data: PropTypes.shape({
    operator: PropTypes.object,
    sourcetHandle: PropTypes.bool,
    targetHandle: PropTypes.bool,
  }).isRequired,
};

export default memo(OperatorNode);
