import {
  take,
  takeEvery,
  takeLeading,
  put,
  delay,
  select,
} from 'redux-saga/effects';
import client from '@api/client';
import endpoints from '@api/endpoints';
import * as loginActions from '@actions/loginActions';
import { generateMFAConnectorKey } from '@actions/settingsActions';
import actionTypes from '@actions';
import { getCurrentUser } from '@selectors/loginSelectors';
import {
  CURRENT_USER_KEY_LOCAL_STORAGE,
  USER_SESSION_PREFERENCES_KEY,
} from '@constants/utilityConstants';
import { setUser } from '@utils/GaTracker.js';
import { addToLocalStorage, removeFromLocalStorage } from '@utils/localStorage';
import { getEventsWebSocket } from '@selectors/websocketSelectors';
import { removeFromSessionStorage } from '@utils/sessionStorage';

export function* logIn({ payload }) {
  const { username, password, mfaCode, params = {} } = payload;
  const mfaCodeStep = !!mfaCode;

  try {
    const res = yield client.post(endpoints.authentication, {
      username,
      password,
      mfaCode,
    });
    const currentUser = res.data || {};

    yield delay(300);

    const mfaCodeStepRequired = !currentUser?.token && currentUser?.mfaEnabled;
    const mfaEnforcedStepRequired =
      currentUser?.mfaEnforced && !currentUser?.mfaEnabled;

    if (mfaEnforcedStepRequired) {
      yield put(loginActions.setCurrentUser(currentUser));
      yield put(generateMFAConnectorKey());

      yield take((action) =>
        [
          actionTypes.GENERATE_MFA_CONNECTOR_KEY_SUCCESS,
          actionTypes.GENERATE_MFA_CONNECTOR_KEY_FAILURE,
        ].includes(action.type),
      );

      params?.switchToMFAConnectorStepCallback?.();

      return;
    }

    if (mfaCodeStepRequired) {
      params?.switchToMFACodeStepCallback?.();

      return;
    }

    addToLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE, currentUser);
    setUser(currentUser);

    yield put(loginActions.logInSuccess(currentUser));
  } catch (error) {
    yield delay(300);

    if (mfaCodeStep) {
      params?.mfaCodeStepFailureCallback?.();
    } else {
      params?.failureCallback?.();
    }

    yield put(loginActions.logInFailure(error?.response?.status));
  }
}

export function* logOut({ payload }) {
  const { onSuccessCallback } = payload;
  const eventsWs = yield select(getEventsWebSocket());
  const currentUser = yield select(getCurrentUser());

  try {
    yield client.post(endpoints.logout);

    onSuccessCallback?.();

    yield put(loginActions.logOutSuccess());
  } catch (error) {
    yield put(loginActions.logOutFailure(error?.response?.status));
  } finally {
    removeFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);
    removeFromSessionStorage(
      `${USER_SESSION_PREFERENCES_KEY}.${currentUser.id}`,
    );

    eventsWs?.close();
  }
}

export function* authenticateToken({ payload = {} }) {
  const { proceedWithNavigation } = payload;

  try {
    const { tokenExpired } = yield client.get(endpoints.validateToken);

    if (tokenExpired) {
      yield put(loginActions.tokenAuthenticationFailure());

      return;
    }

    yield put(loginActions.tokenAuthenticationSuccess());

    proceedWithNavigation?.();
  } catch (error) {
    yield put(loginActions.logOut());

    // eslint-disable-next-line
    console.error(error);
  }
}

export function* tokenExpired() {
  const eventsWs = yield select(getEventsWebSocket());
  removeFromLocalStorage(CURRENT_USER_KEY_LOCAL_STORAGE);
  eventsWs?.close();
  yield put(loginActions.tokenExpireLogoutSuccess());
}

export function* createResetPasswordToken({ payload }) {
  const { username, callbacks } = payload;

  try {
    yield client.post(
      endpoints.passwordResetToken.replace(':username', username),
    );

    callbacks?.successCallback?.();

    yield put(loginActions.createResetPasswordTokenSuccess());
  } catch (error) {
    yield put(loginActions.createResetPasswordTokenFailure());

    callbacks?.failureCallback?.();
  } finally {
    callbacks?.finallyCallback?.();
  }
}

export function* resetPassword({ payload }) {
  const { token, newPassword, confirmNewPassword, callbacks } = payload;

  try {
    yield client.post(endpoints.passwordReset, {
      token,
      newPassword,
      confirmNewPassword,
    });
    callbacks?.successCallback?.();
    yield put(loginActions.resetPasswordSuccess());
  } catch (error) {
    yield put(loginActions.resetPasswordFailure());
  } finally {
    callbacks?.finallyCallback?.();
  }
}

export default [
  takeEvery(actionTypes.LOG_IN_REQUEST, logIn),
  takeEvery(actionTypes.LOG_OUT_REQUEST, logOut),
  takeLeading(actionTypes.TOKEN_AUTHENTIFICATION_REQUEST, authenticateToken),
  takeLeading(actionTypes.TOKEN_EXPIRED, tokenExpired),
  takeEvery(
    actionTypes.CREATE_RESET_PASSWORD_TOKEN_REQUEST,
    createResetPasswordToken,
  ),
  takeEvery(actionTypes.RESET_PASSWORD_REQUEST, resetPassword),
];
