import React, { useContext } from 'react';
import { VisualizationContext } from '@contexts/VisualizationContext';
import OrthographicCamera from '@containers/Visualization/OrthographicCamera';
import PerspectiveCamera from '@containers/Visualization/PerspectiveCamera';

export default function Camera() {
  const { orthographicCameraMode } = useContext(VisualizationContext);

  if (orthographicCameraMode) {
    return <OrthographicCamera />;
  }

  return <PerspectiveCamera />;
}

Camera.propTypes = {};
