import styled, { css } from 'styled-components';
import ButtonComponent from '@components/1-atoms/Button';
import { when } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2px;

  ${({ withIdentation, theme: { spacing } }) => css`
    padding: ${spacing.level2} ${spacing.level4} ${spacing.level2}
      ${spacing.level2};

    ${when(
      withIdentation,
      `
        padding-left: ${spacing.level7};
      `,
    )}
  `}
`;

export const Button = styled(ButtonComponent)`
  flex: unset;
`;
