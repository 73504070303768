import EmptyStateBox from '@components/2-molecules/EmptyStateBox';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '@constants/router';
import { Wrapper } from '@pages/NotFound/NotFound.styled';

const NotFound = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const handleButtonPress = useCallback(() => {
    history.push(ROUTES.HOME);
  }, [history]);

  const {
    icon = 'link_off_0',
    title = intl.formatMessage({
      id: 'notfoundpage.title',
      defaultMessage: 'Page not found',
    }),
    description = intl.formatMessage({
      id: 'notfoundpage.description',
      defaultMessage:
        'The page you are looking for might have been removed or is temporarily unavailable',
    }),
    primaryButtonLabel = intl.formatMessage({
      id: 'notfoundpage.back.to.home.button',
      defaultMessage: 'Go back to home',
    }),
    onPrimaryButtonClick = handleButtonPress,
  } = location.state || {};

  return (
    <Wrapper>
      <EmptyStateBox
        iconName={icon}
        title={title}
        description={description}
        primaryButtonLabel={primaryButtonLabel}
        onPrimaryButtonClick={onPrimaryButtonClick}
      />
    </Wrapper>
  );
};

NotFound.propTypes = {};

export default NotFound;
