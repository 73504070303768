import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import { postProcessorActionTypes } from '@constants/printers/postProcessorSettingsDefinitions';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';
import PostProcessorSetting from './PostProcessorSetting';

const PostProcessorInput = ({
  label,
  descriptionLabel,
  optionName,
  textName,
  isSubmitting,
}) => {
  const intl = useIntl();

  const getFormattedOptions = useMemo(
    () => (definitions) =>
      Object.values(definitions).map((option) => ({
        label: option.label,
        formFieldValue: {
          label: option.label,
          value: option.value,
        },
      })),
    [],
  );

  const options = getFormattedOptions(postProcessorActionTypes);

  return (
    <>
      <FormikField
        component={PostProcessorSetting}
        label={intl.formatMessage({
          id: label,
        })}
        dataTestId={`printer-custom-post-processor__setting-${optionName}`}
        name={optionName}
        descriptionLabel={intl.formatMessage({
          id: descriptionLabel,
        })}
        dropDownMenuItems={options}
        disabled={isSubmitting}
      />
      <FormikField
        component={PostProcessorSetting}
        disabled={isSubmitting}
        dataTestId={`printer-custom-post-processor__setting-text-area-${optionName}`}
        name={textName}
        renderAsTextArea
      />
    </>
  );
};

PostProcessorInput.propTypes = {
  label: PropTypes.string.isRequired,
  descriptionLabel: PropTypes.string.isRequired,
  optionName: PropTypes.string.isRequired,
  textName: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
};

export default PostProcessorInput;
