import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import SpriteSVG from '@components/1-atoms/SpriteSVG';
import Text, { TEXT_VARIANT_LABEL_LARGE } from '@components/1-atoms/Text';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Icon = styled(SpriteSVG)`
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  ${({ errorVariant, theme: { colors } }) => css`
    fill: ${colors.primary};

    ${when(
      errorVariant,
      `
        fill: ${colors.error};
      `,
    )}
  `}
`;

export const Label = styled(Text).attrs(({ errorVariant }) => {
  const labelVariant = TEXT_VARIANT_LABEL_LARGE;
  let color = 'primary';

  if (errorVariant) {
    color = 'error';
  }

  return { variant: labelVariant, color };
})``;

export const Wrapper = styled.div`
  ${flexCenterVertical}
  text-decoration: none;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level3};
  `}

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  ${({ disabled, theme: { colors } }) => css`
    ${when(
      disabled,
      `
        color: ${colors.outline};
        pointer-events: none;

        ${Icon} {
          fill: ${colors.outline};
        }

        ${Label} {
          color: ${colors.outline};
        }
      `,
    )}
  `}
`;

export const RouterLink = styled(NavLink)`
  ${flexCenterVertical}
  text-decoration: none;
`;
