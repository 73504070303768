import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import useModal from '@hooks/useModal';
import useDialog from '@hooks/useDialog';
import useFileMutations from '@hooks/files/useFileMutations';
import { downloadDesign } from '@actions/designActions';
import { MODAL_IDS, ModalDataTypes } from '@app/constants/modalDataTypes';
import useSnackbar from '@hooks/useSnackbar';

export default function useFileList(displayedInModal = false) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useDialog();
  const { showModal } = useModal();
  const { deleteFileMutation } = useFileMutations();
  const { showSnackbar } = useSnackbar();

  const getFileDropDownMenuActions = useCallback(
    (file = {}) => {
      const actions = [
        {
          leadingIconName: 'visibility_on_1',
          label: intl.formatMessage({
            id: 'general.preview',
            defaultMessage: 'Preview',
          }),
          onClick: () => {
            if (displayedInModal) {
              hideDialog(ModalDataTypes.NAVIGATION);
            }
            showModal(MODAL_IDS.FILE_PREVIEW, { file });
          },
        },
        {
          leadingIconName: 'edit_0',
          label: intl.formatMessage({
            id: 'general.rename',
            defaultMessage: 'Rename',
          }),
          onClick: () =>
            showDialog(ModalDataTypes.EDIT_FILE, {
              fileId: file.id,
              fileName: file.filename,
              projectId: file.workspaceId,
            }),
        },
        {
          leadingIconName: 'download_0',
          label: intl.formatMessage({
            id: 'general.download',
            defaultMessage: 'Download',
          }),
          onClick: () =>
            dispatch(downloadDesign(file.fileURLOriginal, file.filename)),
        },
        {
          leadingIconName: 'delete_0',
          label: intl.formatMessage({
            id: 'general.delete',
            defaultMessage: 'Delete',
          }),
          color: 'error',
          onClick: () => {
            showDialog(ModalDataTypes.PROMPT, {
              dataTestId: 'delete-file-dialog',
              title: intl.formatMessage({
                id: 'deletefile.dialog.title',
                defaultMessage: 'Delete File',
              }),
              subtitle: intl.formatMessage(
                {
                  id: 'deletefile.dialog.subtitle',
                  defaultMessage:
                    '<b>{fileName}</b> will be permanently deleted. <br></br><br></br> Are you sure you want to proceed?',
                },
                {
                  fileName: file.filename,
                  b: (str) => <b>{str}</b>,
                  br: () => <br />,
                },
              ),
              onPrimaryButtonClick: async () => {
                try {
                  await deleteFileMutation.mutateAsync({
                    id: file.id,
                    projectId: file.workspaceId,
                  });
                  showSnackbar({
                    text: intl.formatMessage({
                      id: 'deletefile.snackbar.label',
                      defaultMessage: 'File successfully deleted',
                    }),
                  });
                } catch (_) {
                  //error
                }
              },
            });
          },
        },
      ];

      return actions;
    },
    [
      displayedInModal,
      showSnackbar,
      dispatch,
      intl,
      showDialog,
      hideDialog,
      showModal,
      deleteFileMutation,
    ],
  );

  return {
    getFileDropDownMenuActions,
  };
}
