import styled, { css } from 'styled-components';

export const StyledText = styled.div`
  margin: 0;

  ${({ variant, color, theme: { font, colors } }) => css`
    ${font?.[variant]}
    color: ${colors?.[color] || colors.onSurface};
  `}
`;
