import { Wrapper } from '@components/3-organisms/LoginResetPasswordExpiredLink/LoginResetPasswordExpiredLink.styled';
import PageHeader, {
  PAGE_HEADER_VARIANT_LARGE,
} from '@components/2-molecules/PageHeader';
import Button, { BUTTON_VARIANT_TEXT } from '@components/1-atoms/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

const LoginResetPasswordExpiredLink = ({
  className,
  dataTestId = 'login-page-reset-password-expired-link',
  handleGoBack,
  handleGoToResetPassword,
}) => {
  const intl = useIntl();

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      <PageHeader
        dataTestId={`${dataTestId}__page-header`}
        variant={PAGE_HEADER_VARIANT_LARGE}
        title={intl.formatMessage({
          id: 'loginpage.resetpassword.link.expired.title',
          defaultMessage: 'The link has expired',
        })}
        subtitle={intl.formatMessage({
          id: 'loginpage.resetpassword.link.expired.label',
          defaultMessage:
            'This password reset link expires for security reasons. If you still need to reset your password, you can request a new reset.',
        })}
      />

      <Button
        dataTestId={`${dataTestId}__reset-password-button`}
        onClick={handleGoToResetPassword}
      >
        <FormattedMessage
          id="loginpage.resetpassword_newpassword.reset_password"
          defaultMessage="Reset Password"
        />
      </Button>

      <Button
        dataTestId={`${dataTestId}__back-button`}
        onClick={handleGoBack}
        variant={BUTTON_VARIANT_TEXT}
      >
        <FormattedMessage
          id="loginpage.resetpassword.back_to_login"
          defaultMessage="Back to Log in"
        />
      </Button>
    </Wrapper>
  );
};

LoginResetPasswordExpiredLink.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  handleGoBack: PropTypes.func.isRequired,
  handleGoToResetPassword: PropTypes.func.isRequired,
};

export default LoginResetPasswordExpiredLink;
