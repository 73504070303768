import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import sagaMiddleware from '@store/middlewares/sagaMiddleware';
import rootReducer from '@reducers/rootReducer';

/* eslint import/no-dynamic-require: 0, global-require: 0 */
export default function configureStore(initialState = {}) {
  const middlewares = [thunk, sagaMiddleware];
  let composeEnhancers = compose;

  if (DEVELOPMENT_ENV) {
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    if (devTools) {
      composeEnhancers = devTools;
    }
  }

  const enhancers = [applyMiddleware(...middlewares)];
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers),
  );

  if (module && module.hot) {
    module.hot.accept('@reducers/rootReducer', () => {
      const nextRootReducer = require('@reducers/rootReducer');

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
