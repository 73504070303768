import styled, { css } from 'styled-components';
import { flexCenterVertical, when } from '@stylesheets/helpers';

export const Content = styled.div`
  ${flexCenterVertical}
`;

export const ActionWrapper = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.level1} ${spacing.level2};
  `}
`;

export const IconButtons = styled.div`
  ${flexCenterVertical}
  overflow: hidden;

  ${({ theme: { spacing } }) => css`
    gap: ${spacing.level1};
    padding-inline: ${spacing.level1};
  `}
`;

export const IconButtonVisibility = styled.div`
  ${flexCenterVertical}
  flex-shrink: 0;

  ${({ visible }) => css`
    ${when(
      !visible,
      `
        opacity: 0;
        visibility: hidden;
      `,
    )}

    ${when(
      visible,
      `
        opacity: 1;
        visibility: visible;
        position: aboslute;
      `,
    )}
  `}
`;
