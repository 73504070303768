import { useQuery } from '@tanstack/react-query';
import client from '@api/client';
import endpoints from '@api/endpoints';

export const projectQueryKeys = {
  projects: ['projects'],
  project: (projectId) => ['project', projectId],
};

export default function useProjectQueries({ projectId } = {}) {
  const projectsQuery = useQuery({
    queryKey: projectQueryKeys.projects,
    queryFn: () => client.get(endpoints.workspaces).then((res) => res.data),
    enabled: false,
  });

  const projectQuery = useQuery({
    queryKey: projectQueryKeys.project(projectId),
    queryFn: () =>
      client
        .get(endpoints.workspace.replace(':workspaceId', projectId))
        .then((res) => res.data),
    enabled: !!projectId,
  });

  return {
    projectsQuery,
    projectQuery,
  };
}
