import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import useDropDownMenu from '@hooks/useDropDownMenu';
import DropDownMenuPortal from '@components/2-molecules/DropDownMenuPortal';

export const dropDownMenuHOCProps = {
  dropDownMenuId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getDropDownMenuItems: PropTypes.func,
  dropDownMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      formFieldValue: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      color: PropTypes.string,
      description: PropTypes.string,
      endingIconName: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      leadingIconColor: PropTypes.string,
      leadingIconName: PropTypes.string,
      leadingIconUrl: PropTypes.string,
      endingButton: PropTypes.shape({
        type: PropTypes.oneOf(['switch', 'radio', 'checkbox', 'iconButton']),
        checked: PropTypes.bool,
        onChange: PropTypes.func,
      }),
      onClick: PropTypes.func,
      onEndingIconButtonClick: PropTypes.func,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
      navigationPath: PropTypes.string,
      navigationItem: PropTypes.bool,
      withBadge: PropTypes.bool,
      withDivider: PropTypes.bool,
      field: PropTypes.object,
    }),
  ),
  dropDownMenuPosition: PropTypes.oneOf([
    'top left',
    'top center',
    'top right',
    'bottom left',
    'bottom center',
    'bottom right',
  ]),
  dropDownMenuScrolledToBottom: PropTypes.bool,
  emptyDropDownStateIconName: PropTypes.string,
  emptyDropDownStateLinkButtonLabel: PropTypes.string,
  emptyDropDownStateLinkButtonPath: PropTypes.string,
  emptyDropDownStateOnLinkButtonClick: PropTypes.func,
  emptyDropDownStateTitle: PropTypes.string,
  emptyDropDownStateDescription: PropTypes.string,
};

export default function withDropDownMenu(Component) {
  /* eslint-disable react-hooks/rules-of-hooks */
  const wrapped = ({
    dropDownMenuId,
    dropDownMenuItems,
    dropDownMenuPosition = 'bottom right',
    dropDownMenuScrolledToBottom,
    emptyDropDownStateDescription,
    emptyDropDownStateIconName,
    emptyDropDownStateLinkButtonLabel,
    emptyDropDownStateLinkButtonPath,
    emptyDropDownStateOnLinkButtonClick,
    emptyDropDownStateTitle,
    getDropDownMenuItems,
    ...props
  }) => {
    const [loadedDropDownMenuItems, setLoadedDropDownMenuItems] = useState([
      { skeleton: true },
    ]);
    const hasDropDownMenuItems = !!dropDownMenuItems || !!getDropDownMenuItems;
    const generateDropDownMenuId = useRef(
      dropDownMenuId || uniqueId('drop-down-menu-'),
    );
    const { showDropDownMenu } = useDropDownMenu();

    const handleClick = useCallback(
      async (e) => {
        props?.onClick?.(e);

        if (hasDropDownMenuItems) {
          showDropDownMenu?.(generateDropDownMenuId.current, e.currentTarget, {
            position: dropDownMenuPosition,
          });

          if (getDropDownMenuItems) {
            const loadedItems = await getDropDownMenuItems();

            setLoadedDropDownMenuItems(loadedItems);
          }
        }
      },
      [
        hasDropDownMenuItems,
        getDropDownMenuItems,
        dropDownMenuPosition,
        showDropDownMenu,
        props,
      ],
    );

    return (
      <>
        <Component {...props} onClick={handleClick} />

        {hasDropDownMenuItems && (
          <DropDownMenuPortal
            menuId={generateDropDownMenuId.current}
            menuItems={
              getDropDownMenuItems ? loadedDropDownMenuItems : dropDownMenuItems
            }
            menuScrollToBottom={dropDownMenuScrolledToBottom}
            emptyStateIconName={emptyDropDownStateIconName}
            emptyStateLinkButtonLabel={emptyDropDownStateLinkButtonLabel}
            emptyStateLinkButtonPath={emptyDropDownStateLinkButtonPath}
            emptyStateOnLinkButtonClick={emptyDropDownStateOnLinkButtonClick}
            emptyStateTitle={emptyDropDownStateTitle}
            emptyStateDescription={emptyDropDownStateDescription}
          />
        )}
      </>
    );
  };

  wrapped.propTypes = {
    onClick: PropTypes.func,
  };

  return wrapped;
}
