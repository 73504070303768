import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import Card from '@components/2-molecules/Card';
import InfoRow from '@components/1-atoms/InfoRow';
import { Wrapper } from './Tooltip.styled';

export const TOOLTIP_ID = 'tooltip';
export const TOOLTIP_POSITION_TOP_LEFT = 'top left';
export const TOOLTIP_POSITION_TOP_CENTER = 'top center';
export const TOOLTIP_POSITION_TOP_RIGHT = 'top right';
export const TOOLTIP_POSITION_BOTTOM_LEFT = 'bottom left';
export const TOOLTIP_POSITION_BOTTOM_CENTER = 'bottom center';
export const TOOLTIP_POSITION_BOTTOM_RIGHT = 'bottom right';

const Tooltip = ({
  animated = true,
  bridge = true,
  dataTestId = 'tooltip',
  disabled,
  height,
  infoRow = {},
  maxHeight,
  maxWidth,
  mediaAlt = '',
  mediaHeight,
  mediaPadding,
  mediaSrc,
  offsetBottom = 0,
  offsetLeft = 0,
  offsetRight = 0,
  offsetTop = 0,
  style = {},
  width,
}) => {
  const wrapperRef = useRef();

  const wrapperStyle = {
    ...style,
    opacity: animated ? 0 : 1,
    top: `calc(${style.top || 0}px + ${offsetTop}px - ${offsetBottom}px)`,
    left: `calc(${style.left || 0}px + ${offsetLeft}px - ${offsetRight}px)`,
  };

  useGSAP(() => {
    if (!animated) return;

    gsap.fromTo(wrapperRef.current, 0.15, { opacity: 0 }, { opacity: 1 });
  });

  return (
    <Wrapper
      ref={wrapperRef}
      bridge={bridge}
      height={height}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      style={wrapperStyle}
      width={width}
      offsetTop={offsetTop}
      offsetRight={offsetRight}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
    >
      <Card
        dataTestId={dataTestId}
        disabled={disabled}
        id={TOOLTIP_ID}
        infoRow={infoRow}
        mediaAlt={mediaAlt}
        mediaHeight={mediaHeight}
        mediaPadding={mediaPadding}
        mediaSrc={mediaSrc}
      />
    </Wrapper>
  );
};

Tooltip.propTypes = {
  animated: PropTypes.bool,
  bridge: PropTypes.bool,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  infoRow: PropTypes.shape(InfoRow.propTypes),
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  mediaAlt: PropTypes.string,
  mediaHeight: PropTypes.number,
  mediaPadding: PropTypes.string,
  mediaSrc: PropTypes.string,
  offsetBottom: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetRight: PropTypes.number,
  offsetTop: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.number,
};

export default Tooltip;
