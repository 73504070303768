import styled, { css } from 'styled-components';
import { flexColumn } from '@stylesheets/helpers';

export const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  ${flexColumn};

  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing?.level6};
  `}
`;

export const UserRole = styled.span`
  opacity: 0.76;
`;
