import {
  PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP,
  START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET,
  END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET,
} from '../constants/utilityConstants.js';
import { getIsWorkflowComputing } from '@selectors/computationProgressSelectors';
import { getAllAllowedExtensions } from '@utils/model';

/**
 * Checks if the input string contains only one pair of curly braces.
 * This function allows the input string to span multiple lines.
 *
 * @param {string} str - The input string to check.
 * @returns {boolean} `true` if the input string contains only one pair of curly braces, `false` otherwise.
 */
function hasOnePairOfCurlyBraces(str) {
  const regex = /^([^{]*\{[^{}]*\}[^}]*)$/;
  return regex.test(str);
}

/**
 *
 * @param {string} value
 * @param {array} params
 * @returns the original value replacing positionally each {} with the values in the params list
 */
export const formatStringParams = (value, params = []) => {
  if (!params || !value || !Array.isArray(params) || params.length === 0) {
    return value;
  }
  // If params is an array and template string has only one {}, we should show all array with one string.
  if (hasOnePairOfCurlyBraces(value)) {
    return value.replace(/{}/g, params.join(', '));
  }
  return value.replace(/{}/g, () => params.shift() || '');
};

export const checkIfFetchIsInProgress = (
  concepts,
  designs,
  printers,
  state,
) => {
  const hasRunningProgressHandler = getIsWorkflowComputing(state);

  return !!(
    hasRunningProgressHandler ||
    designs?.fetchesInProgress ||
    concepts?.ui.fetchesInProgress ||
    printers?.ui.fetchesInProgress
  );
};

export const getPrinterSensorChartOptions = (
  clickLegendHandler,
  subtitle,
  animationDuration,
  enableZoomAndPan = false,
  animationCallback,
) => {
  return {
    plugins: {
      tooltip: {
        backgroundColor: '#616161',
        callbacks: {
          label: (context) => {
            return context.dataset.label.trim() + ': ' + context.parsed.y;
          },
        },
      },
      legend: {
        position: 'bottom',
        onClick: clickLegendHandler,
        align: 'start',
        labels: {
          align: 'start',
        },
      },
      subtitle: {
        display: true,
        text: subtitle,
        padding: 20,
      },
      zoom: {
        pan: {
          enabled: enableZoomAndPan,
        },
        zoom: {
          mode: 'x',
          wheel: {
            enabled: enableZoomAndPan,
          },
          pinch: {
            enabled: enableZoomAndPan,
          },
        },
      },
    },
    maintainAspectRatio: false,
    animation: {
      duration: animationDuration,
      onComplete: () => {
        animationCallback();
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          beginAtZero: true,
        },
        grid: {
          borderColor: 'white',
          color: 'white',
          lineWidth: 0.2,
        },
      },
      x: {
        grid: {
          borderColor: 'white',
          color: 'white',
          lineWidth: 0.2,
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
    },
  };
};

const getDisplayedName = (prefix, currentValue, targetValue) => {
  let displayName =
    prefix +
    START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET +
    currentValue;
  if (targetValue) {
    displayName +=
      '/' + targetValue + END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET;
  } else {
    displayName += END_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET;
  }
  return displayName;
};

export const buildDatasetsForSensorViewer = (
  currentData,
  randomColorsArray,
  hiddenDatasets,
) => {
  const datasets = [];
  Object.entries(currentData)
    .sort(([sensorNameA], [sensorNameB]) =>
      sensorNameA.localeCompare(sensorNameB),
    )
    .forEach(([sensorName, values], index) => {
      if (sensorName !== PRINTER_SENSOR_DATA_MESSAGE_TIMESTAMP_PROP) {
        const numberValues = values.map((valueObj) => valueObj.sensorValue);
        let displayedName = values[0].displayName;
        displayedName = getDisplayedName(
          displayedName,
          values[values.length - 1].sensorValue,
          values[values.length - 1].sensorTargetValue,
        );
        datasets.push({
          type: 'line',
          label: displayedName.padEnd(50),
          borderWidth: 2,
          fill: false,
          data: numberValues,
          borderColor: randomColorsArray[index],
          hidden: hiddenDatasets
            .map((hd) =>
              hd.text.substr(
                0,
                hd.text.indexOf(
                  START_VARIABLE_PART_SEPARATOR_PRINTER_SENSORS_WIDGET,
                ),
              ),
            )
            .includes(values[0].displayName),
          datasetIndex: index,
        });
      }
    });
  return datasets;
};
/**
 *
 * @param {integer} millis
 * @returns the time as minutes:seconds
 */
export const millisToMinutesAndSeconds = (millis) => {
  const seconds = millis / 1000;
  const h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  m = m < 10 ? '0' + m : m;
  let s = Math.floor((seconds % 3600) % 60);
  s = s < 10 ? '0' + s : s;
  return h + ':' + m + ':' + s;
};

export const getRandomColor = () => {
  return 'hsla(' + ~~(360 * Math.random()) + ',' + '70%,' + '80%,1)';
};

/**
 * Returns a string of allowed file extensions with a leading dot.
 * @returns {string} A string of allowed file extensions separated by commas.
 */
export const getAllowedExtensionsString = () => {
  return getAllAllowedExtensions()
    .map((ext) => `.${ext}`)
    .join();
};

/**
 * Converts a string to a boolean value.
 * @param {string} str - The string to convert.
 * @returns {boolean} The boolean value.
 */
export const stringToBoolean = (str) => {
  return /^(true|1)$/i.test(str);
};

export const sortByAttribute = (array, attribute) => {
  return array
    ?.slice() // Clone the array to avoid mutating the original
    .sort((a, b) => {
      const aNum = parseInt(a[attribute].match(/\d+/)); // Extract numeric part of a's attribute
      const bNum = parseInt(b[attribute].match(/\d+/)); // Extract numeric part of b's attribute

      if (aNum && bNum) {
        // If both a and b have numeric parts, compare numerically
        return aNum - bNum;
      } else if (aNum) {
        // If only a has a numeric part, a should come before b
        return -1;
      } else if (bNum) {
        // If only b has a numeric part, a should come after b
        return 1;
      } else {
        // If neither a nor b have numeric parts, compare alphabetically
        return a[attribute].localeCompare(b[attribute]);
      }
    });
};

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const checkIfFieldIsDirty = (values, initialValues, fieldName) => {
  const initialValue = getNestedValue(initialValues, fieldName);
  const currentValue = getNestedValue(values, fieldName);
  return !!(currentValue && currentValue !== initialValue);
};
