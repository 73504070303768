import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import useDialog from '@hooks/useDialog';
import getIntlProvider from '@utils/getIntlProvider';
import DialogPortal from '@components/2-molecules/DialogPortal';
import PageHeader, {
  PAGE_HEADER_VARIANT_MEDIUM,
} from '@components/2-molecules/PageHeader';
import { Content } from './Dialog.styled';

const intl = getIntlProvider();

export default function Dialog({
  children,
  dialogId: dialogIdProp,
  disabled,
  loading,
  onCancel,
  onConfirm,
  onSubmit,
  secondaryButtonLabel,
  confirmButtonText = intl.formatMessage({
    id: 'dialog.confirm',
    defaultMessage: 'Confirm',
  }),
  subtitle,
  title,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dialogId = useRef(dialogIdProp || uniqueId('dialog-')).current;
  const { hideDialog } = useDialog();

  const handleConfirm = useCallback(async () => {
    setConfirmLoading(true);

    try {
      await onConfirm();
      await hideDialog(dialogId);
    } finally {
      onCancel?.();
      setConfirmLoading(false);
    }
  }, [dialogId, hideDialog, onConfirm, onCancel]);

  return (
    <DialogPortal
      autoShow={!dialogIdProp}
      dialogId={dialogId}
      loading={loading || confirmLoading}
      onClose={onCancel}
      onSubmit={onSubmit || handleConfirm}
      primaryButtonDisabled={disabled}
      primaryButtonLabel={confirmButtonText}
      secondaryButtonLabel={secondaryButtonLabel}
      renderAsForm
    >
      {(title || subtitle) && (
        <PageHeader
          variant={PAGE_HEADER_VARIANT_MEDIUM}
          title={title}
          subtitle={subtitle}
        />
      )}

      {children && <Content>{children}</Content>}
    </DialogPortal>
  );
}

Dialog.propTypes = {
  secondaryButtonLabel: PropTypes.string,
  children: PropTypes.any,
  confirmButtonText: PropTypes.string,
  dialogId: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onSubmit: PropTypes.func,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired,
};
