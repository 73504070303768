import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import useOperatorList from '@hooks/operators/useOperatorList';
import useActiveItems from '@hooks/useActiveItems';
import Button, { BUTTON_VARIANT_OUTLINE } from '@components/1-atoms/Button';
import Operator from '@components/2-molecules/Operator';
import WorkflowReadOnlyOperatorFields from '@containers/WorkflowReadOnlyOperatorFields';
import {
  ActionButtons,
  Diff,
  DiffColumn,
  DiffContent,
  DiffHeader,
  DiffTitle,
  Wrapper,
} from './TalkToAiComparison.styled';

const TalkToAiComparison = ({
  className,
  currentOperators = [],
  dataTestId = 'talk-to-ai',
  defaultExpandedOperatorIds = [],
  disableApplyButton,
  disabled,
  disabledOperatorIds = [],
  loading,
  onApplyButtonClick,
  onCancelButtonClick,
  recommendedOperators = [],
}) => {
  const disableActions = disabled || loading;
  const { getDefaultOperator } = useOperatorList();

  const defaultActiveItems = useMemo(
    () =>
      defaultExpandedOperatorIds.reduce(
        (acc, id) => [...acc, id, `${id}-recommended`],
        [],
      ),
    [defaultExpandedOperatorIds],
  );

  const { addActiveItems, removeActiveItems, isItemActive } = useActiveItems({
    defaultActiveItems,
  });

  const toggleOperatorExpansion = useCallback(
    (operatorId) => () => {
      if (isItemActive(operatorId)) {
        removeActiveItems([operatorId]);

        return;
      }

      addActiveItems([operatorId]);
    },
    [isItemActive, removeActiveItems, addActiveItems],
  );

  const showDiff = !isEmpty(currentOperators) || !isEmpty(recommendedOperators);

  const renderOperators = useCallback(
    (operators, recommended = false) =>
      operators.map((operator) => {
        const operatorId = recommended
          ? `${operator.id}-recommended`
          : operator.id;
        const isOperatorDisabled =
          disabled || disabledOperatorIds.includes(operator.id);

        return (
          <Operator
            key={operator.id}
            disableExpand={isOperatorDisabled}
            dataTestId={`${dataTestId}__operator`}
            operatorName={operator.tag || operator.name}
            expanded={isItemActive(operatorId)}
            operatorIcon={getDefaultOperator(name)?.iconName}
            onExpandButtonClick={toggleOperatorExpansion(operatorId)}
            disableActions={!disabled}
            diffAdded={operator.diffAdded}
            diffModified={operator.diffModified}
            diffRemoved={operator.diffRemoved}
          >
            <WorkflowReadOnlyOperatorFields operator={operator} />
          </Operator>
        );
      }),
    [
      disabled,
      disabledOperatorIds,
      dataTestId,
      isItemActive,
      toggleOperatorExpansion,
      getDefaultOperator,
    ],
  );

  return (
    <Wrapper data-testid={dataTestId} className={className}>
      {showDiff && (
        <Diff data-testid={`${dataTestId}__diff`}>
          <DiffColumn
            data-testid={`${dataTestId}__diff__column ${dataTestId}__diff__column--current`}
          >
            <DiffHeader>
              <DiffTitle data-testid={`${dataTestId}__diff__column__title`}>
                <FormattedMessage
                  id="gpt.diff.current"
                  defaultMessage="Current Operators"
                />
              </DiffTitle>
            </DiffHeader>

            <DiffContent data-testid={`${dataTestId}__diff__column__content`}>
              {renderOperators(currentOperators)}
            </DiffContent>
          </DiffColumn>

          <DiffColumn
            data-testid={`${dataTestId}__diff__column ${dataTestId}__diff__column--recommended`}
          >
            <DiffHeader>
              <DiffTitle data-testid={`${dataTestId}__diff__column__title`}>
                <FormattedMessage
                  id="gpt.diff.ai_recommends"
                  defaultMessage="Ai Recommends"
                />
              </DiffTitle>
            </DiffHeader>

            <DiffContent data-testid={`${dataTestId}__diff__column__content`}>
              {renderOperators(recommendedOperators, true)}
            </DiffContent>
          </DiffColumn>

          {(onCancelButtonClick || onApplyButtonClick) && (
            <ActionButtons data-testid={`${dataTestId}__diff__action-buttons`}>
              {onCancelButtonClick && (
                <Button
                  dataTestId={`${dataTestId}__diff__action-buttons__cancel-button`}
                  variant={BUTTON_VARIANT_OUTLINE}
                  disabled={disableActions}
                  stretch={false}
                  onClick={onCancelButtonClick}
                >
                  <FormattedMessage
                    id="gpt.diff.actions.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              )}

              {onApplyButtonClick && (
                <Button
                  dataTestId={`${dataTestId}__diff__action-buttons__apply-button`}
                  disabled={
                    loading ? undefined : disableActions || disableApplyButton
                  }
                  loading={loading}
                  stretch={false}
                  onClick={onApplyButtonClick}
                >
                  <FormattedMessage
                    id="gpt.diff.actions.apply"
                    defaultMessage="Apply"
                  />
                </Button>
              )}
            </ActionButtons>
          )}
        </Diff>
      )}
    </Wrapper>
  );
};

TalkToAiComparison.propTypes = {
  className: PropTypes.string,
  currentOperators: PropTypes.arrayOf(PropTypes.object),
  dataTestId: PropTypes.string,
  defaultExpandedOperatorIds: PropTypes.arrayOf(PropTypes.string),
  disableApplyButton: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledOperatorIds: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  onApplyButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  recommendedOperators: PropTypes.arrayOf(PropTypes.object),
};

export default TalkToAiComparison;
